import {CsvEndpoint} from "../../../../../../../enums/csvEndpoint";
import {client} from "../../../../../../../services/api-servise";
import {formatRangeDate} from "../../../../../../../helpers/dateFormatters";
import {toastr} from "react-redux-toastr";
import {IDateData} from "../../../../../OperatorView/ConcurrencyInspector/ConcurrencyTable/ConcurrencyTable";
import {AxiosResponse} from "axios";
import {DateTime} from "luxon";

export const handleDownloadCSV = async (
    buttonName: string,
    searchValue: string | undefined,
    pageNumber: number,
    itemsPerPage: number,
    from: IDateData,
    to: IDateData,
    ingestLabel: string
) => {
    let url = '';
    let response = {} as AxiosResponse<Blob>;

    try {
        if (buttonName === "ingest-details") {
            if (ingestLabel === "Unassigned" || ingestLabel === "All") {
                return toastr.warning("IGame", "Select ingest type please")
            }

            url = CsvEndpoint[ingestLabel.replace(/\s+/g, '') as keyof typeof CsvEndpoint];
            response = await client.post(url, {
                searchValue,
                pageNumber,
                itemsPerPage,
                sorting: {
                    property: '',
                    isAscending: false
                },
                dateFrom: formatRangeDate(from),
                dateTo: formatRangeDate(to)
            });

        } else if (buttonName === 'booking-sheet' && isValidCSVRangeDate(from, to)) {
            url = `/api/reports/booking-sheet/download?startDate=${formatRangeDate(from)}&endDate=${formatRangeDate(to)}`;
            response = await client.get(url);
        }

        const blob = new Blob([response.data], {type: 'text/csv'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        if (response.headers['content-disposition']) {
            const contentDisposition = response.headers['content-disposition'];
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);

            if (matches != null && matches[1]) {
                const filename = matches[1].replace(/['"]/g, '');
                link.download = `${filename}`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
        await response;
    } catch (error) {
        console.error('Error', error);
    }
};


const isValidCSVRangeDate = (from: IDateData, to: IDateData) => {
    const fromDate = DateTime.fromObject(from);
    const toDate = DateTime.fromObject(to);

    const dateRange = toDate.diff(fromDate, "days").days + 1;

    if (dateRange > 31) {
        toastr.error("Invalid Date Range!", "Maximum date range exceeded.\n" +
            "The maximum date range for download is 1 month.");
        return false
    }
    return true
}
