import {IStepOneFormData, IStepThreeFormData, IStepTwoFormData} from "../UserManagementHooks/useOperatorFormData";
import {ICreateOperatorPayload} from "@store/userManagement/userManagementSlice";

export const createOperatorPayload = (
    stepOneFD: IStepOneFormData,
    stepTwoFD: IStepTwoFormData,
    stepThreeFD: IStepThreeFormData,
) => {

    const operatorPayload: ICreateOperatorPayload = {
        customerId: +stepOneFD.customerId!.value,
        name: stepOneFD.name,
        adminEmail: stepOneFD.adminEmail,
        commercialEmail: stepOneFD.commercialEmail,
        scheduleContactEmail: stepOneFD.scheduleContactEmail,
        liveIussesContactEmail: stepOneFD.liveIussesContactEmail,
        territoryIdes: stepOneFD.territoryIdes ? stepOneFD.territoryIdes.map(territory => territory!.id) : [],
        rolePages: stepTwoFD.rolePages.length ? stepTwoFD.rolePages : [],
    }

    if (stepOneFD.clientAdmin) {
        operatorPayload.clientAdmin = stepOneFD.clientAdmin;
    }

    if (stepOneFD.commercialContact) {
        operatorPayload.commercialContact = stepOneFD.commercialContact;
    }

    if (stepOneFD.scheduleContact) {
        operatorPayload.scheduleContact = stepOneFD.scheduleContact;
    }

    if (stepOneFD.liveIssuesContact) {
        operatorPayload.liveIssuesContact = stepOneFD.liveIssuesContact;
    }

    if (stepTwoFD.rightHolders) {
        operatorPayload.rightHolders = stepTwoFD.rightHolders.map(rightHolder => rightHolder!.id);
    }

    if (stepThreeFD.logo) {
        operatorPayload.logo = stepThreeFD.logo;
    }

    return operatorPayload
}
