import {DateTime} from "luxon";
import {formatDatePicker, ICalendarDate} from "../../../../../helpers/dateFormatters";
import {toastr} from "react-redux-toastr";


export const validateDateRange = (startDate: ICalendarDate, endDate: ICalendarDate) => {
    const start = DateTime.fromISO(formatDatePicker(startDate));
    const end = DateTime.fromISO(formatDatePicker(endDate));
    const diff = Math.abs(start.diff(end, "days").days);

    if (diff <= 62) {
        return true;
    } else {
        toastr.error(
            "Invalid Date Range!",
            "Exceeds maximum date range - maximum range is 2 months"
        );
        return false;
    }
};