import {AnyAction, createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {toastr} from "react-redux-toastr";
import {client} from "../../services/api-servise";
import {AxiosError} from "axios";

interface IPropertyTableItem {
    property: string,
    availableFixtures: number,
    purchasedFixtures: number,
    remainingFixtures: number
}

export interface ISportTableItem {
    sport: string,
    properties: IPropertyTableItem[]
}

interface IPortfolioTableItem {
    provider: string,
    sports: ISportTableItem[]
}

interface IPortfolioItem {
    rightHolderName: string,
    sport: string,
    competitionName: string,
    competitionId: string,
    availableFixtures: number,
    purchasedFixtures: number,
    assignedAllFixtures: true,
    rightHolderId: string,
    remainingFixtures: number
}

export interface IOperatorPortfolioState {
    portfolio: IPortfolioTableItem[],
    portfolioCSV: string,
    portfolioCSVName: string,
    isLoading: boolean;
    error: string;
}

const initialState: IOperatorPortfolioState = {
    portfolio: [],
    portfolioCSV: '',
    portfolioCSVName: '',
    isLoading: false,
    error: '',
}

export const getPortfolio = createAsyncThunk<IPortfolioItem[], string | null, { rejectValue: string }>(
    "operator-portfolio/get-portfolio",
    async (operatorId, {rejectWithValue}) => {
        try {
            let url = 'api/Operators/portfolio';
            if (operatorId) {
                url += `?operatorId=${operatorId}`;
            }

            const {data} = await client.get(url);
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error("IGame", `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getPortfolioCSV = createAsyncThunk<string, string | null, { rejectValue: string }>(
    "operator-portfolio/get-portfolio-csv",
    async (operatorId, {rejectWithValue, dispatch}) => {
        try {
            let url = 'api/Operators/portfolio/csv';
            if (operatorId) {
                url += `?operatorId=${operatorId}`;
            }

            const {data, headers} = await client.get(url);
            if (headers['content-disposition']) {
                const disposition = headers['content-disposition'];
                const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = regex.exec(disposition);
                const portfolioFilename = matches ? matches[1].replace(/['"]/g, '') : null;
                if (portfolioFilename) {
                    dispatch(setCSVFileName(portfolioFilename))
                }
            }
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error("IGame", `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)


export const operatorPortfolioSlice = createSlice({
    name: 'operator-portfolio',
    initialState,
    reducers: {
        setCSVFileName: (state, action: PayloadAction<string>) => {
            state.portfolioCSVName = action.payload
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(getPortfolio.pending, (state) => {
                state.error = ""
                state.isLoading = true
            })
            .addCase(getPortfolio.fulfilled, (state, action: PayloadAction<IPortfolioItem[]>) => {
                state.isLoading = false

                action.payload.forEach(item => {
                    let provider = state.portfolio.find(provider => provider.provider === item.rightHolderName);
                    if (!provider) {
                        provider = {
                            provider: item.rightHolderName,
                            sports: []
                        };
                        state.portfolio.push(provider);
                    }

                    let sport = provider.sports.find(sport => sport.sport === item.sport);
                    if (!sport) {
                        sport = {
                            sport: item.sport,
                            properties: []
                        };
                        provider.sports.push(sport);
                    }

                    const existingProperty = sport.properties.find(property => property.property === item.competitionName);
                    if (!existingProperty) {
                        sport.properties.push({
                            property: item.competitionName,
                            availableFixtures: item.availableFixtures,
                            purchasedFixtures: item.purchasedFixtures,
                            remainingFixtures: item.remainingFixtures
                        });
                    }
                });
            })
            .addCase(getPortfolioCSV.fulfilled, (state, action: PayloadAction<string>) => {
                state.portfolioCSV = action.payload
            })
            .addMatcher(isError, (state, action: PayloadAction<string>) => {
                state.error = action.payload;
            })
    },
})

export const {setCSVFileName} = operatorPortfolioSlice.actions
export default operatorPortfolioSlice.reducer

function isError(action: AnyAction) {
    return action.type.endsWith("rejected")
}
