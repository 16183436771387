import {useState} from "react";
import {useAppDispatch} from "../../../../../hooks/hooks";
import {AppThunk} from "../../../../../store";
import {useDebounce} from "../../../../../helpers/useDebounce";
import {ISorting} from "../../../../../components/OperatorDefaultTable/OperatorDefaultTable";
import {IUpcomingFixturesPayload} from "@store/propertyDetail/propertyDetail";

export const useSorting = (
    propertyId: string | null,
    itemsPerPage: number,
    operatorId: string | null,
    request: (payload: any) => AppThunk) => {
    const [sorting, setSorting] = useState<ISorting>({
        property: "",
        isAscending: true
    });

    const dispatch = useAppDispatch();

    const getSortedItems = () => {
        if(propertyId){
            const payload: IUpcomingFixturesPayload = {
                propertyId,
                pageNumber: 1,
                itemsPerPage,
                sorting
            }

            if (operatorId) {
                payload.operatorId = operatorId;
            }

            dispatch(request({payload}));
        }

    };

    useDebounce(getSortedItems, [sorting], 500);

    return {setSorting, sorting};
};
