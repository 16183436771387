import React, {useEffect, useRef, useState} from "react";
import styles from "./index.module.scss";
import {IconContext} from "react-icons";
import {BsGraphUp} from "react-icons/bs";
import SelectInput from "../../../SelectInput/SelectInput";
import MainChart from "../MainChart/MainChart";
import {
    IDropdownOptions,
    IMinorFilters
} from "../../types";
import MainChartLegend from "../MainChartLegend/MainChartLegend";
import throttle from "lodash/throttle";
import {Point} from "rumble-charts";

export interface IMainChartSectionProps {
    setTrendVisibility: (isVisible: boolean) => void;
    trendVisibility: boolean;
    setMinorFilters: (filters: IMinorFilters) => void;
    minorFilters: IMinorFilters;
    dropdownOptions: IDropdownOptions;
    mainChartData: any;
    fullWidthMainChart: boolean;
    colors: string[];
    loading: boolean;
    setStartPointIndex: (value: number | undefined) => void;
    setEndPointIndex: (value: number | undefined) => void;
    zoomModeOn: boolean;
    setZoomModeOn: (value: boolean) => void;
    exitZoomMode: () => void;
    setInitTrendState: (value: boolean) => void;
    viewType: string
}

const MainChartSection: React.FunctionComponent<IMainChartSectionProps> = (
    {
        setTrendVisibility,
        trendVisibility,
        setMinorFilters,
        minorFilters,
        dropdownOptions,
        mainChartData,
        fullWidthMainChart,
        colors,
        loading,
        setStartPointIndex,
        setEndPointIndex,
        zoomModeOn,
        exitZoomMode,
        setInitTrendState,
        viewType
    }) => {

    const [isLabelVisible, setIsLabelVisible] = useState(false as boolean);
    const [labelData, setLabelData] = useState(null as any);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [innerRectHeight, setInnerRectHeight] = useState<string | null>(null);
    const [overlayStartX, setOverlayStartX] = useState<number>(0);
    const [overlayEndX, setOverlayEndX] = useState<number>(0);
    const [topOverlayOffset, setTopOverlayOffset] = useState<number>(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const rectRef = useRef<HTMLDivElement>(null);
    const timeoutRef = useRef<number | null>(null);

    useEffect(() => {
        exitZoomMode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [minorFilters.mainChartDateViewType, minorFilters.mainChartType])

    useEffect(() => {
        return () => {
            if (timeoutRef.current !== null) {
                clearTimeout(timeoutRef.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateRectPosition = throttle(() => {
        if (rectRef.current) {
            const rect = rectRef.current.querySelector("rect");
            if (rect) {
                const rectPosition = rect.getBoundingClientRect();
                setTopOverlayOffset(rectPosition.top);
                setInnerRectHeight(rect.getAttribute("height"));
            }
        }
    }, 500);

    useEffect(() => {
        if (rectRef.current) {
            updateRectPosition();
        }
        window.addEventListener("scroll", updateRectPosition, true);

        return () => {
            window.removeEventListener("scroll", updateRectPosition, true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rectRef.current, windowWidth, fullWidthMainChart]);

    const getDatePointIndex = (pointSelected: number) => {
        const rectWidth = rectRef.current?.querySelector("rect")?.getAttribute("width");
        const dataArrLength = mainChartData[0].data.length;
        if (rectWidth) {
            const distanceBetweenDatePoints = +rectWidth / (dataArrLength - 1);
            const indexRelatedPosition = Math.round(pointSelected / distanceBetweenDatePoints);

            return indexRelatedPosition;
        }
    }

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        if (rectRef.current) {
            const innerRect = rectRef.current.querySelector("rect")?.getBoundingClientRect();
            if (innerRect) {
                const startX = e.clientX - innerRect.left;
                if (startX > 0) {
                    setIsDragging(true);
                    setOverlayStartX(e.clientX);
                    setOverlayEndX(e.clientX);
                    setStartPointIndex(getDatePointIndex(startX));
                } else {
                    return;
                }
            }
        }
    };

    const handleMouseMove = throttle((e: React.MouseEvent<HTMLDivElement>) => {
        if (isDragging) {
            if (rectRef.current) {
                const innerRect = rectRef.current.querySelector("rect")?.getBoundingClientRect();
                if (innerRect) {
                    if (e.clientX > innerRect.right || e.clientX < innerRect.left) {
                        timeoutRef.current = window.setTimeout(() => {
                            setIsDragging(false);
                        }, 300)
                    } else {
                        setOverlayEndX(e.clientX);
                    }
                }
            }
        }
    }, 20);

    const handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsDragging(false);

        if (rectRef.current) {
            const innerRect = rectRef.current.querySelector("rect")?.getBoundingClientRect();
            if (innerRect) {
                if (e.clientX > innerRect.right) {
                    return;
                } else if (e.clientX < innerRect.left) {
                    return;
                } else {
                    const currentX = e.clientX - innerRect.left;
                    setEndPointIndex(getDatePointIndex(currentX));
                }
            }
        }
    };

    const getOverlayStyle = (): React.CSSProperties => {
        const minX = Math.min(overlayStartX, overlayEndX);
        const maxX = Math.max(overlayStartX, overlayEndX);
        return {
            left: `${minX}px`,
            width: `${maxX - minX}px`,
            height: `${innerRectHeight}px`,
            borderLeft: "1px solid #FF3466",
            borderRight: "1px solid #FF3466",
            backgroundColor: "rgba(173,173,173,0.31)",
            position: "fixed",
            top: `${topOverlayOffset}px`,
            opacity: 0.8,
            pointerEvents: "none"
        };
    };

    const cancelTrendVisibility = () => {
        setTrendVisibility(!trendVisibility);
        setInitTrendState(false);
    }

    const handleDotHover = throttle((pointData: Point) => {
        if (minorFilters.mainChartType === "Total") {
            setLabelData(pointData);
            return;
        }

        let multiData: string[] = [];

        mainChartData.forEach((line: any) => {
            if (pointData.x && line.data[pointData.x].y === pointData.y) {
                multiData = [...multiData, line.data[pointData.x].categoryItem];
            }
        })

        setLabelData({...pointData, categoryItem: multiData});
    }, 500)

    return (
        <div className={styles.mainChart}>
            <MainChartLegend
                data={mainChartData}
                trendLinesVisible={trendVisibility}
            />
            <div className={styles.mainChartFilters}>
                <div>
                    <h3 className={styles.yaxisLabel}>
                        {viewType.replace(/([A-Z])/g, ' $1').trim()}
                    </h3>
                </div>
                <div className={styles.filters}>
                    {
                        zoomModeOn &&
                        <button className={styles.zoomResetButton} onClick={exitZoomMode}>
                            Reset Zoom
                        </button>
                    }
                    <div className={styles.optionsDropdown}>
                        <SelectInput
                            options={dropdownOptions.mainChartTypes}
                            onChange={(x) => setMinorFilters({
                                ...minorFilters,
                                mainChartType: x!.label
                            })}
                            placeholder="Filter"
                            defaultValue={{value: minorFilters.mainChartType, label: minorFilters.mainChartType}}
                        />
                    </div>
                    <div className={styles.optionsDropdown}>
                        <SelectInput
                            options={dropdownOptions.mainChartDateViewTypes}
                            onChange={(x) => setMinorFilters({
                                ...minorFilters,
                                mainChartDateViewType: x!.label
                            })}
                            placeholder="View by"
                            defaultValue={{
                                value: minorFilters.mainChartDateViewType,
                                label: minorFilters.mainChartDateViewType
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.trendSection}>
                <IconContext.Provider value={{
                    className: trendVisibility
                        ? `${styles.trendButton} ${styles.trendButtonActive}`
                        : styles.trendButton
                }}>
                    <div onClick={cancelTrendVisibility}>
                        <BsGraphUp/>
                    </div>
                </IconContext.Provider>
            </div>
            <div className={styles.chartBody}>
                <MainChart
                    chartData={mainChartData}
                    fullWidthMainChart={fullWidthMainChart}
                    colors={colors}
                    trendLinesVisible={trendVisibility}
                    loading={loading}
                    dropdownOptions={dropdownOptions}
                    rectRef={rectRef}
                    handleMouseDown={handleMouseDown}
                    isLabelVisible={isLabelVisible}
                    labelData={labelData}
                    setIsLabelVisible={setIsLabelVisible}
                    setLabelData={setLabelData}
                    isDragging={isDragging}
                    setIsDragging={setIsDragging}
                    getOverlayStyle={getOverlayStyle}
                    windowWidth={windowWidth}
                    setWindowWidth={setWindowWidth}
                    handleMouseUp={handleMouseUp}
                    handleMouseMove={handleMouseMove}
                    handleDotHover={handleDotHover}
                />
            </div>
        </div>
    )
}
MainChartSection.displayName = "MainChartSection"
export default MainChartSection;
