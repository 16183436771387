import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {client} from "../../services/api-servise";
import {AxiosError} from "axios";
import {toastr} from "react-redux-toastr";
import {IFilterControl} from "../../pages/Cms/OperatorView/AvailableContent/helpers/renderFilterControls";
import {ISorting} from "../../pages/Cms/RightsHolderView/ScheduleIngest/SheduleIngestHooks/useSortingAndSearch";
import {getPickerDateFormat, ICalendarDate} from "../../helpers/dateFormatters";
import {IFixturesReportPayload, IFixturesState, IGetFixturesReport, IReportFixture} from "../reportingFixtures/types";
import {DateTime} from "luxon";
import {appendDataHelper, prependDataHelper} from "../../helpers/reducerHelpers";
import {formatSelectOptions} from "../../helpers/formatSelectOptions";
import {ingestTypes} from "@pages/Cms/IGameView/Settings/Reporting/ReportingTable/helpers/ingestTypes";



interface RHReportingState {
    fixturesReports: any[];
    pageNumber: number;
    itemsPerPage: number;
    totalCount: number;
    isLoading: boolean;
    hasMoreScroll: boolean
    maxItemsInTheTable: number
    controls: IFilterControl[]
    sorting: ISorting
    RHfilters: {
        startDate: ICalendarDate,
        endDate: ICalendarDate,
        ingestType: { value: string, label: string }
    }
}

const initialState: RHReportingState = {
    fixturesReports: [],
    pageNumber: 1,
    itemsPerPage: 10,
    totalCount: 0,
    isLoading: false,
    hasMoreScroll: true,
    maxItemsInTheTable: 30,
    sorting: {
        criteria: 'Date',
        isAscending: true
    },
    RHfilters: {
        startDate: getPickerDateFormat(DateTime.now().minus({days: 3}).toISO()),
        endDate: getPickerDateFormat(DateTime.now().toISO()),
        ingestType: {value: "4", label: "All"}
    },
    controls: [
        {
            datePicker: false,
            label: "Ingest Type",
            initValue: null,
            valueName: "ingestType",
            options: formatSelectOptions(ingestTypes),
            isMulti: false,
            separator: true

        },
        {
            datePicker: true,
            label: "Start Date",
            initValue: null,
            valueName: "startDate",
            options: null,
            withTime: false
        },
        {
            datePicker: true,
            label: "End Date",
            initValue: null,
            valueName: "endDate",
            options: null,
            withTime: false
        },
    ]
}

export const getReports = createAsyncThunk<IGetFixturesReport, IFixturesReportPayload, { rejectValue: string }>(
    'get-rh-report',
    async ({payload, scrollPosition}, {rejectWithValue, dispatch}) => {
        try {
            const {data, headers} = await client.post(`/api/Reports/Fixture-details/search`, payload);
            const totalCount = headers['x-total-count']

            if (totalCount) {
                dispatch(setRHTotalCount(Number(totalCount)))

            }
            if (scrollPosition === "down") {
                dispatch(appendRHData({data: data.records}))
                return
            }
            if (scrollPosition === "up") {
                dispatch(prependRHData({data: data.records}))
                return
            }
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)
export const RHReportingSlice = createSlice({
    name: 'rh-reporting',
    initialState,
    reducers: {
        setRHPageNumber: (state, action) => {
            state.pageNumber = action.payload
        },
        setRHTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload
        },
        setHasMoreForScroll: (state, action) => {
            state.hasMoreScroll = action.payload
        },
        setRHFilter: (state, action: PayloadAction<{ value: any; name: keyof IFixturesState["filters"] }>) => {
            state.RHfilters[action.payload.name] = action.payload.value;
        },
        resetFilter: (state) => {
            state.RHfilters = initialState.RHfilters
        },
        setRHSorting: (state, action: PayloadAction<ISorting>) => {
            state.sorting = action.payload
        },
        appendRHData(state, action: PayloadAction<{ data: IReportFixture[] }>) {
            appendDataHelper(state, action.payload.data, 'fixturesReports');
        },
        prependRHData(state, action: PayloadAction<{ data: IReportFixture[] }>) {
            prependDataHelper(state, action.payload.data, 'fixturesReports');
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getReports.fulfilled, (state, action) => {
                if (action.payload) {
                    state.fixturesReports = action.payload.records
                }
            })
    },
})
export const {
    setRHTotalCount,
    setHasMoreForScroll,
    setRHPageNumber,
    setRHFilter,
    resetFilter,
    setRHSorting,
    appendRHData,
    prependRHData
} = RHReportingSlice.actions
export default RHReportingSlice.reducer
