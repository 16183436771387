export const ingestTypes = [
    {
        id: "0",
        name: "Unassigned"
    },
    {
        id: "1",
        name: "BT Tower"
    },
    {
        id: "2",
        name: "Satellite"
    },
    {
        id: "3",
        name: "IP"
    },
    {
        id: '4',
        name: "All"
    }
]
