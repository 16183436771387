import {Route} from "react-router";
import ProtectedRoute from "../../../../components/ProtectedRoute/ProtectedRoute";
import {Group} from "../../../../enums/group";
import Reporting from "../../IGameView/Settings/Reporting/Reporting";
import {
    IGameSidebarData,
    ISidebarItem,
    operatorSidebarData,
    rightsHolderSidebarData
} from "../../CmsSideBarData/CmsSideBarData";
import BTBookings from "../../IGameView/Settings/BTBookings/BTBookings";
import ReportingIssues from "../../IGameView/Settings/ReportingIssues/ReportingIssues";
import IngestResources from "../../IGameView/Settings/IngestResources/IngestResources";
import RightAssignment from "../../IGameView/RightAssignment/RightAssignment";
import RightHolderBilling from "../../IGameView/Billing/RightHolderBilling/RightHolderBilling";
import OperatorBilling from "../../IGameView/Billing/OperatorBilling/OperatorBilling";
import Publishing from "../../IGameView/Settings/Publishing/Publishing";
import UserManagement from "../../IGameView/Settings/UserManagement/UserManagement";
import RightsHoldersTiles from "../../OperatorView/RightsHoldersPortfolio/RightsHoldersTiles/RightsHoldersTiles";
import RightsHolderPortfolio from "../../OperatorView/RightsHoldersPortfolio/RightsHolderPortfolio";
import OperatorPage from "../../IGameView/OperatorPage/OperatorPage";
import RightHolderPage from "../../IGameView/RightHolderPage/RightHolderPage";
import AnalyticsPage from "../../IGameView/AnalyticsPage/AnalyticsPage";
import AuditTrailsIngestResources
    from "../../../../components/AuditTrails/AuditTrailsIngestResources/AuditTrailsIngestResources";
import AuditTrailPricing from "../../../../components/AuditTrails/AuiditTrailsPricing/AuditTrailPricing";
import AuditTrailsRightsAssignment
    from "../../../../components/AuditTrails/AuditTrailsRightsAssignment/AuditTrailsRightsAssignment";
import AuditTrailsOperatorAccess
    from "../../../../components/AuditTrails/AuditTrailsOperatorAccess/AuditTrailsOperatorAccess";
import AuditTrailsBookings from "../../../../components/AuditTrails/AuditTrailsBookings/AuditTrailsBookings";
import AuditTrailsRightsRestrictions
    from "../../../../components/AuditTrails/AuditTrailsRightsRestrictions/AuditTrailsRightsRestrictions";
import AuditTrailsUserManagement
    from "../../../../components/AuditTrails/AuditTrailsUserManagement/AuditTrailsUserManagement";
import BookFixtures from "../../RightsHolderView/ScheduleIngest/BookFixtures/BookFixtures";
import UpcomingBookedFixtures from "../../RightsHolderView/ScheduleIngest/UpcomingBookedFixtures/UpcomingBookFixtures";
import ContentAccess from "../../RightsHolderView/ContentAccess/ContentAccess";
import Pricing from "../../RightsHolderView/Pricing/Pricing";
import UserManagementRHOP from "../../RightsHolderView/Settings/UserManagementRHOP";
import AvailableContent from "../../OperatorView/AvailableContent/AvailableContent";
import FavouriteContent from "../../OperatorView/FavouriteContent/FavouriteContent";
import Billing from "../../OperatorView/Billing/Billing";
import {Navigate} from "react-router-dom";
import React from "react";
import {IUserItem} from "../../../../store/iGameInit/iGameInitSlice";
import {IGameRouteProps} from "./routeHandler";
import PropertyRestrictions from "../../RightsHolderView/GeoRestrictions/PropertyRestrictions/PropertyRestrictions";
import OperatorGeoRestrictions
    from "../../RightsHolderView/GeoRestrictions/OperatorGeoRestrictions/OperatorGeoRestrictions";
import Cart from "../../../../components/Carts/Cart/Cart";
import UserTypesTiles from "../../IGameView/UserTypesTiles/UserTypesTiles";
import OperatorCart from "../../../../components/Carts/OperatorCart/OperatorCart";
import BookedContent from "../../OperatorView/BookContent/BookedContent";
import PropertyDetail from "../../OperatorView/PropertyDetail/PropertyDetail";
import UsersTiles from "../../IGameView/Billing/UsersTiles/UsersTiles";
import AuditTrailsIssues from "../../../../components/AuditTrails/AuditTrailsIssues/AuditTrailsIssues";
import OperatorPortfolio from "../../OperatorView/OperatorPortfolio/OperatorPortfolio";

export const generateIGameRoutes = (props: IGameRouteProps | Group) => {
    let dropdownData: ISidebarItem[] = [];
    let currentUserId = '';
    let users: IUserItem[] = [];

    if (typeof props !== 'string') {
        const {dropdownDataSet, rightsHolderId, rightsHolderUsers, operatorUsers, operatorId} = props;

        if (dropdownDataSet) {
            dropdownData = dropdownDataSet;
        }
        if (rightsHolderId) {
            currentUserId = rightsHolderId;
        } else if (operatorId) {
            currentUserId = operatorId
        }
        if (rightsHolderUsers) {
            users = rightsHolderUsers;
        } else if (operatorUsers) {
            users = operatorUsers
        }
    }

    return [
        {
            name: "Reporting",
            route:
                <Route
                    path="cms/reporting"
                    element={
                        <ProtectedRoute forGroup={Group.IGame}>
                            <Reporting menuStructure={IGameSidebarData[4]}/>
                        </ProtectedRoute>
                    }>
                </Route>
        },
        {
            name: "Reporting",
            route:
                <Route
                    path="cms/bt-bookings"
                    element={
                        <ProtectedRoute forGroup={Group.IGame}>
                            <BTBookings menuStructure={IGameSidebarData[4]}/>
                        </ProtectedRoute>
                    }>
                </Route>
        },
        {
            name: "Reporting",
            route:
                <Route
                    path="/cms/reporting-issues"
                    element={
                        <ProtectedRoute forGroup={Group.IGame}>
                            <ReportingIssues menuStructure={IGameSidebarData[4]}/>
                        </ProtectedRoute>
                    }>
                </Route>
        },
        {
            name: "IngestResource",
            route:
                <Route
                    path="/cms/ingest-resources"
                    element={
                        <ProtectedRoute forGroup={Group.IGame}>
                            <IngestResources menuStructure={IGameSidebarData[4]}/>
                        </ProtectedRoute>
                    }>
                </Route>
        },
        {
            name: "rights-assignment",
            route:
                <Route path="cms/rights-assignment"
                       element={
                           <ProtectedRoute forGroup={Group.RightHolder}>
                               <RightAssignment menuStructure={IGameSidebarData[4]}/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "igame-billing",
            route:
                <Route path="/cms/igame-billing/*"
                       element={
                           <ProtectedRoute forGroup={Group.IGame}>
                               <UsersTiles menuStructure={IGameSidebarData[3]}/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: null,
            route:
                <Route path="cms/igame-billing/rightHolders/:id/*"
                       element={
                           <ProtectedRoute forGroup={Group.IGame}>
                               <RightHolderBilling menuStructure={IGameSidebarData[3]}/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: null,
            route:
                <Route path="cms/igame-billing/operators/:id/*"
                       element={
                           <ProtectedRoute forGroup={Group.IGame}>
                               <OperatorBilling menuStructure={IGameSidebarData[3]}/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "publishing",
            route:
                <Route path="/cms/publishing"
                       element={
                           <ProtectedRoute forGroup={Group.IGame}>
                               <Publishing menuStructure={IGameSidebarData[4]}/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "user-management",
            route:
                <Route path="/cms/user-management/*"
                       element={
                           <ProtectedRoute forGroup={Group.IGame}>
                               <UserManagement menuStructure={IGameSidebarData[4]}/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "igame-operators",
            route:
                <Route path="/cms/igame-operators"
                       element={
                           <ProtectedRoute forGroup={Group.IGame}>
                               <UserTypesTiles menuStructure={IGameSidebarData[1]}/>
                           </ProtectedRoute>
                       }>
                </Route>
        },


        {
            name: "operator-rights-holders",
            route:
                <Route path="operator-rights-holders"
                       element={
                           <ProtectedRoute forGroup={Group.Operator}>
                               <RightsHoldersTiles
                                   operatorId={currentUserId}
                                   users={users}
                                   key={currentUserId}
                                   menuStructure={dropdownData[0]}/>
                           </ProtectedRoute>
                       }>
                </Route>
        },


        {
            name: "operator-rights-holders-portfolio",
            route:
                <Route path="igame-operators/:id/operator-rights-holders/operator-rights-holders-portfolio"
                       element={
                           <ProtectedRoute forGroup={Group.Operator}>
                               <RightsHolderPortfolio
                                   operatorId={currentUserId}
                                   users={users}
                                   key={currentUserId}
                                   menuStructure={dropdownData[0]}/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "igame-rightHolders",
            route:
                <Route path="/cms/igame-rightHolders"
                       element={
                           <ProtectedRoute forGroup={Group.IGame}>
                               <UserTypesTiles menuStructure={IGameSidebarData[0]}/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: null,
            route:
                <Route path="/cms/igame-operators/:id/*"
                       element={
                           <ProtectedRoute forGroup={Group.IGame}>
                               <OperatorPage/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: null,
            route:
                <Route path="/cms/igame-rightHolders/:id/*"
                       element={
                           <ProtectedRoute forGroup={Group.IGame}>
                               <RightHolderPage/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "rightHolder-book-fixtures",
            route: <Route
                path="rightHolder-book-fixtures"
                element={
                    <ProtectedRoute forGroup={Group.IGame}>
                        <BookFixtures
                            users={users}
                            key={currentUserId}
                            menuStructure={dropdownData[0]}
                            rightHolderId={currentUserId}/>
                    </ProtectedRoute>
                }
            />
        },
        {
            name: "igame-analytics",
            route:
                <Route path="cms/igame-analytics"
                       element={
                           <ProtectedRoute forGroup={Group.IGame}>
                               <AnalyticsPage menuStructure={IGameSidebarData[2]}/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "audit-trails",
            route:
                <Route path="/cms/audit-trails-ingest"
                       element={
                           <ProtectedRoute forGroup={Group.IGame}>
                               <AuditTrailsIngestResources menuStructure={IGameSidebarData[4]}/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "audit-trails",
            route:
                <Route path="/cms/audit-trails-pricing"
                       element={
                           <ProtectedRoute forGroup={Group.IGame}>
                               <AuditTrailPricing menuStructure={IGameSidebarData[4]}/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "audit-trails",
            route:
                <Route path="/cms/audit-trails-rights-assignment"
                       element={
                           <ProtectedRoute forGroup={Group.IGame}>
                               <AuditTrailsRightsAssignment menuStructure={IGameSidebarData[4]}/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "audit-trails",
            route:
                <Route path="/cms/audit-trails-operator-access"
                       element={
                           <ProtectedRoute forGroup={Group.IGame}>
                               <AuditTrailsOperatorAccess menuStructure={IGameSidebarData[4]}/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "audit-trails",
            route:
                <Route path="/cms/audit-trails-bookings"
                       element={
                           <ProtectedRoute forGroup={Group.IGame}>
                               <AuditTrailsBookings menuStructure={IGameSidebarData[4]}/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "audit-trails",
            route:
                <Route path="/cms/audit-trails-rights-restrictions"
                       element={
                           <ProtectedRoute forGroup={Group.IGame}>
                               <AuditTrailsRightsRestrictions menuStructure={IGameSidebarData[4]}/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "audit-trails",
            route:
                <Route path="/cms/audit-trails-user-management"
                       element={
                           <ProtectedRoute forGroup={Group.IGame}>
                               <AuditTrailsUserManagement menuStructure={IGameSidebarData[4]}/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "audit-trails",
            route:
                <Route path="/cms/audit-trails-issues"
                       element={
                           <ProtectedRoute forGroup={Group.IGame}>
                               <AuditTrailsIssues menuStructure={IGameSidebarData[4]}/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "rightHolder-upcoming-fixtures",
            route: <Route
                path="rightHolder-upcoming-fixtures"
                element={
                    <ProtectedRoute forGroup={Group.IGame}>
                        <UpcomingBookedFixtures
                            users={users}
                            key={currentUserId}
                            menuStructure={dropdownData[0]}
                            rightHolderId={currentUserId}/>
                    </ProtectedRoute>
                }
            />
        },
        {
            name: "rightHolder-operator-access",
            route: <Route
                path="rightHolder-operator-access"
                element={
                    <ProtectedRoute forGroup={Group.IGame}>
                        <ContentAccess
                            users={users}
                            key={currentUserId}
                            menuStructure={dropdownData[0]}
                            rightHolderId={currentUserId}/>
                    </ProtectedRoute>
                }
            />
        },
        {
            name: "right-holder-geo-blocked",
            route: <Route
                path="right-holder-geo-blocked"
                element={
                    <ProtectedRoute forGroup={Group.IGame}>
                        <PropertyRestrictions
                            users={users}
                            key={currentUserId}
                            menuStructure={dropdownData[0]}
                            rightHolderId={currentUserId}/>
                    </ProtectedRoute>
                }
            />
        },
        {
            name: "right-holder-geo-allowed",
            route: <Route
                path="right-holder-geo-allowed"
                element={
                    <ProtectedRoute forGroup={Group.IGame}>
                        <OperatorGeoRestrictions
                            users={users}
                            key={currentUserId}
                            menuStructure={dropdownData[0]}
                            rightHolderId={currentUserId}/>
                    </ProtectedRoute>
                }
            />
        },
        {
            name: "rightHolder-pricing",
            route: <Route
                path="rightHolder-pricing"
                element={
                    <ProtectedRoute forGroup={Group.IGame}>
                        <Pricing
                            users={users}
                            key={currentUserId}
                            menuStructure={dropdownData[0]}
                            rightHolderId={currentUserId}/>
                    </ProtectedRoute>
                }
            />
        },
        {
            name: "rightHolder-analytics",
            route:
                <Route path="cms/analytics"
                       element={
                           <ProtectedRoute forGroup={Group.RightHolder}>
                               <AnalyticsPage menuStructure={rightsHolderSidebarData[2]}/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "rightHolder-settings",
            route:
                <Route path="cms/rightsholder-settings"
                       element={
                           <ProtectedRoute forGroup={Group.RightHolder}>
                               <UserManagementRHOP menuStructure={rightsHolderSidebarData[4]}
                                                   roleName={'Right Holder'}/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "operator-booked-content",
            route:
                <Route path="operator-booked-content"
                       element={
                           <ProtectedRoute forGroup={Group.Operator}>
                               <BookedContent
                                   operatorId={currentUserId}
                                   users={users}
                                   key={currentUserId}
                                   menuStructure={dropdownData[0]}
                               />
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "operator-portfolio",
            route:
                <Route path="operator-portfolio"
                       element={
                           <ProtectedRoute forGroup={Group.Operator}>
                               <OperatorPortfolio
                                   operatorId={currentUserId}
                                   users={users}
                                   key={currentUserId}
                                   menuStructure={dropdownData[0]}
                               />
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "operator-rights-holders",
            route:
                <Route path="operator-rights-holders"
                       element={
                           <ProtectedRoute forGroup={Group.Operator}>
                               <RightsHoldersTiles
                                   operatorId={currentUserId}
                                   users={users}
                                   key={currentUserId}
                                   menuStructure={dropdownData[0]}
                               />
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "operator-rights-holders-portfolio",
            route:
                <Route path="operator-rights-holders-portfolio"
                       element={
                           <ProtectedRoute forGroup={Group.Operator}>
                               <RightsHolderPortfolio
                                   operatorId={currentUserId}
                                   users={users}
                                   key={currentUserId}
                                   menuStructure={dropdownData[0]}
                               />
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "property-detail",
            route:
                <Route path="property-detail"
                       element={
                           <ProtectedRoute forGroup={Group.Operator}>
                               <PropertyDetail
                                   operatorId={currentUserId}
                                   users={users}
                                   key={currentUserId}
                                   menuStructure={dropdownData[0]}
                               />
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "available-content",
            route:
                <Route path="available-content"
                       element={
                           <ProtectedRoute forGroup={Group.Operator}>
                               <AvailableContent
                                   operatorId={currentUserId}
                                   users={users}
                                   key={currentUserId}
                                   menuStructure={dropdownData[0]}/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "favourite-content",
            route:
                <Route path="favourite-content"
                       element={
                           <ProtectedRoute forGroup={Group.Operator}>
                               <FavouriteContent
                                   operatorId={currentUserId}
                                   users={users}
                                   key={currentUserId}
                                   menuStructure={dropdownData[0]}/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "operator-billing",
            route:
                <Route path="operator-billing"
                       element={
                           <ProtectedRoute forGroup={Group.Operator}>
                               <Billing menuStructure={operatorSidebarData[2]}/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "rightHolder-cart",
            route:
                <Route path="cms/igame-rightHolders/:id/rightHolder-cart"
                       element={
                           <ProtectedRoute forGroup={Group.IGame}>
                               <Cart/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "operator-cart",
            route:
                <Route path="cms/igame-rightHolders/:id/operator-cart"
                       element={
                           <ProtectedRoute forGroup={Group.IGame}>
                               <OperatorCart/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: "book-fixture-cart",
            route:
                <Route path="cms/igame-rightHolders/:id/book-fixture-cart"
                       element={
                           <ProtectedRoute forGroup={Group.IGame}>
                               <OperatorCart/>
                           </ProtectedRoute>
                       }>
                </Route>
        },
        {
            name: null,
            route: <Route path="cms/*" element={<Navigate replace to="/nomatch"/>}/>

        },
    ];
};
