import ProtectedRoute from "../../../../components/ProtectedRoute/ProtectedRoute";
import {Group} from "../../../../enums/group";
import {operatorSidebarData} from "../../CmsSideBarData/CmsSideBarData";
import {Route} from "react-router";
import React from "react";
import AnalyticsPage from "../../OperatorView/AnalyticsPage/AnalyticsPage";
import Billing from "../../OperatorView/Billing/Billing";
import UserManagementRHOP from "../../RightsHolderView/Settings/UserManagementRHOP";
import {IAppRoute} from "./RouteInterface";
import AuditTrailsUserManagement
    from "../../../../components/AuditTrails/AuditTrailsUserManagement/AuditTrailsUserManagement";
import BookedContent from "../../OperatorView/BookContent/BookedContent";
import PropertyDetail from "../../OperatorView/PropertyDetail/PropertyDetail";
import RightsHolderPortfolio from "../../OperatorView/RightsHoldersPortfolio/RightsHolderPortfolio";
import AvailableContent from "../../OperatorView/AvailableContent/AvailableContent";
import FavouriteContent from "../../OperatorView/FavouriteContent/FavouriteContent";
import OperatorCart from "../../../../components/Carts/OperatorCart/OperatorCart";
import OperatorPortfolio from "../../OperatorView/OperatorPortfolio/OperatorPortfolio";
import RightsHoldersTiles from "../../OperatorView/RightsHoldersPortfolio/RightsHoldersTiles/RightsHoldersTiles";

export const OperatorRoutes: IAppRoute[] = [
    {
        name: "operator-portfolio",
        route:
            <Route path="/cms/operator-portfolio"
                   element={
                       <ProtectedRoute forGroup={Group.Operator}>
                           <OperatorPortfolio menuStructure={operatorSidebarData[0]} operatorId={null}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "operator-rights-holders",
        route:
            <Route path="cms/operator-rights-holders"
                   element={
                       <ProtectedRoute forGroup={Group.Operator}>
                           <RightsHoldersTiles menuStructure={operatorSidebarData[1]} operatorId={null}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "operator-rights-holders-portfolio",
        route:
            <Route path="cms/operator-rights-holders-portfolio"
                   element={
                       <ProtectedRoute forGroup={Group.Operator}>
                           <RightsHolderPortfolio menuStructure={operatorSidebarData[1]} operatorId={null}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "operator-booked-content",
        route:
            <Route path="cms/operator-booked-content"
                   element={
                       <ProtectedRoute forGroup={Group.Operator}>
                           <BookedContent menuStructure={operatorSidebarData[0]} operatorId={""}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "operator-cart",
        route:
            <Route path="cms/operator-cart"
                   element={
                       <ProtectedRoute forGroup={Group.Operator}>
                           <OperatorCart/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "book-fixture-cart",
        route:
            <Route path="cms/book-fixture-cart"
                   element={
                       <ProtectedRoute forGroup={Group.Operator}>
                           <OperatorCart/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "property-detail",
        route:
            <Route path="cms/property-detail"
                   element={
                       <ProtectedRoute forGroup={Group.Operator}>
                           <PropertyDetail menuStructure={operatorSidebarData[0]} operatorId={null}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "favourite-content",
        route:
            <Route path="cms/favourite-content"
                   element={
                       <ProtectedRoute forGroup={Group.Operator}>
                           <FavouriteContent menuStructure={operatorSidebarData[2]} operatorId={null}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "available-content",
        route:
            <Route path="cms/available-content"
                   element={
                       <ProtectedRoute forGroup={Group.Operator}>
                           <AvailableContent menuStructure={operatorSidebarData[2]} operatorId={null}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "operator-analytics",
        route:
            <Route path="cms/operator-analytics"
                   element={
                       <ProtectedRoute forGroup={Group.Operator}>
                           <AnalyticsPage menuStructure={operatorSidebarData[3]}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "operator-billing",
        route:
            <Route path="cms/operator-billing"
                   element={
                       <ProtectedRoute forGroup={Group.Operator}>
                           <Billing menuStructure={operatorSidebarData[4]}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "operator-settings",
        route:
            <Route path="cms/operator-settings"
                   element={
                       <ProtectedRoute forGroup={Group.Operator}>
                           <UserManagementRHOP menuStructure={operatorSidebarData[5]} roleName={'operator'}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "audit-trails",
        route:
            <Route path="/cms/audit-trails-user-management"
                   element={
                       <ProtectedRoute forGroup={Group.Operator}>
                           <AuditTrailsUserManagement menuStructure={operatorSidebarData[5]}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
]
