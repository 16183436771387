import React, {useEffect, useState} from 'react';
import styles from './index.module.scss'
import SelectInput from "../../../../../../components/SelectInput/SelectInput";
import Input from "../../../../../../components/Input/Input";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/hooks";
import {
    getIngestDetail, getIPChannels,
    IUpdateBTTowerBody, IUpdateIPBody, IUpdateSatelliteBody,
    sendIngest, updateBTTower, updateIP, updateSatellite
} from "../../../../../../store/iGameReporting/iGameReportingSlice";
import {formatSelectOptions} from "../../../../../../helpers/formatSelectOptions";
import {SingleValue} from "react-select";
import SatelliteForm from "../SatelliteForm/SatelliteForm";
import {useControlAdditionalForms} from "./hooks/useControlAdditionalForms";
import IPForm from "../IPForm/IPForm";
import upload from "../../../../../../img/upload.png";
import {checkFileIsInvalid} from "../../UserManagement/helpers/checkFileIsInvalid";
import BTTowerForm from "../BTTowerForm/BTTowerForm";

interface IIngestFormProps {
    closeIngestForm: () => void
    fixtureId: string
    rightHolderId: string
    updatingFixtureId: string | number
}

export interface ISatelliteDetails {
    fixtureId: string
    startDate: string,
    endDate: string,
    satelliteId: string,
    bissCode: string,
    symbolRate: number,
    downloadFrequency: number,
    downloadPolarity: string,
    audio: string,
    fileName: string | null
    fileUrl: string
    bissType?: number
    nS3_NS4?: boolean
}

const IngestForm: React.FC<IIngestFormProps> = ({closeIngestForm, fixtureId}) => {
    const dispatch = useAppDispatch()
    const {ingestFormInit} = useAppSelector(state => state.iGameReportingSlice)
    const [selectedIngestType, setSelectedIngestType] = useState<SingleValue<{ value: string, label: string }>>(null);
    const [currentFile, setCurrentFile] = useState<File | null>(null);
    const [satelliteDetails, setSatelliteDetails] = useState<ISatelliteDetails | null>(null);
    const [BTTowerLocalEnd, setBTTowerLocalEnd] = useState<string | null>("");
    const [IPInfo, setIPInfo] = useState<number>(0);
    const [currentSelectedIngMethod, setCurrentSelectedIngMethod] = useState<"BTTower" | "IP" | "Satellite" | "">("");
    const [uploaded, setUploaded] = useState<boolean>(false);
    const [dragActive, setDragActive] = useState(false);
    const [error, setError] = useState("");
    const {
        additionalIsOpenFormsState: {BTTower, IP, Satellite},
        setIOpenAdditionalForm
    } = useControlAdditionalForms(selectedIngestType?.label)


    useEffect(() => {
        if (fixtureId) {
            dispatch(getIngestDetail(fixtureId))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fixtureId]);


    useEffect(() => {
        if (ingestFormInit.ingestMethod) {
            setCurrentSelectedIngMethod(ingestFormInit.ingestMethod)
            setIngestDetailsByMethod(ingestFormInit.ingestMethod)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ingestFormInit]);


    const setIngestDetailsByMethod = (method: "BTTower" | "IP" | "Satellite") => {
        switch (method) {
            case "Satellite": {
                if (ingestFormInit.satellite) {
                    setSatelliteDetails(ingestFormInit.satellite)
                }
                break;
            }
            case "IP": {
                if (ingestFormInit.ip && ingestFormInit.rightHolderId) {
                    dispatch(getIPChannels(fixtureId))
                    setIPInfo(ingestFormInit.ip.channelId)
                }
                break;
            }
            case "BTTower": {
                if (ingestFormInit.btTower) {
                    setBTTowerLocalEnd(ingestFormInit.btTower.source)
                }
                break;
            }
            default:
                return
        }
    }

    const setBTTower = (localEnd: string) => {
        setBTTowerLocalEnd(localEnd)
        setCurrentSelectedIngMethod("BTTower")
    }
    const setIP = (endPortal: number) => {
        setIPInfo(endPortal)
        setCurrentSelectedIngMethod("IP")
    }
    const setSatellite = (details: ISatelliteDetails) => {
        setSatelliteDetails(details)
        setCurrentSelectedIngMethod("Satellite")
    }


    const updateIngestMethod = (method: "BTTower" | "IP" | "Satellite" | "") => {
        switch (method) {
            case "BTTower": {
                const payload = {
                    rightHolderId: ingestFormInit.rightHolderId,
                    fixtureId,
                    fixture: {
                        fixtureId,
                        source: BTTowerLocalEnd
                    }

                } as IUpdateBTTowerBody
                dispatch(updateBTTower(payload))
                break;
            }
            case "IP": {
                const payload = {
                    rightHolderId: ingestFormInit.rightHolderId,
                    fixtureId,
                    fixture: {
                        fixtureId,
                        channelId: IPInfo
                    }

                } as IUpdateIPBody
                dispatch(updateIP(payload))
                break;
            }
            case "Satellite": {
                if (satelliteDetails) {
                    const payload = {
                        fixtureId,
                        fixture: satelliteDetails

                    } as IUpdateSatelliteBody
                    dispatch(updateSatellite(payload))
                }
            }
        }
    }

    const onUpload = (e: React.MouseEvent) => {
        e.preventDefault()
        let formData = new FormData()
        formData.append("FixtureId", fixtureId)
        formData.append("Note", "")
        if (currentFile) {
            dispatch(sendIngest(formData))
            formData.append("File", currentFile)
        }
        updateIngestMethod(currentSelectedIngMethod)
        closeIngestForm()
    }

    const closeBTTowerForm = () => {
        setSelectedIngestType(null)
        setIOpenAdditionalForm("BTTower", false)
    }

    const closeSRTowerForm = () => {
        setSelectedIngestType(null)
        setIOpenAdditionalForm("IP", false)
    }

    const closeSatelliteForm = () => {
        setSelectedIngestType(null)
        setIOpenAdditionalForm("Satellite", false)
    }

    const openAdditionalFormForUpdate = (currentMethod: "BTTower" | "IP" | "Satellite" | "") => {
        const currentOption = formatSelectOptions(ingestFormInit.ingetsTypes.items).find(option => option.label === currentMethod)
        if (currentOption) {
            setSelectedIngestType(currentOption)
        }
    }
    const handleDrag = <T extends HTMLElement>(e: React.DragEvent<T>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    }

    const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files?.[0]) {
            const errorMessage = await checkFileIsInvalid(e.dataTransfer.files[0], 500, 500);

            if (errorMessage) {
                setError(errorMessage);
                setUploaded(false);
                return;
            }
            setError("");
            setUploaded(true);
            setCurrentFile(e.dataTransfer.files[0])
        }
    }

    const handleUploadingOnClick = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            const errorMessage = await checkFileIsInvalid(
                e.target.files[0],
                500,
                500,
                500 * 1024,
                ["jpg", "png"],
                ['image/jpeg', 'image/png'],
                false
            );

            if (errorMessage) {
                setError(errorMessage);
                setUploaded(false);
                return;
            }
            setUploaded(true);
            setError("");
            setCurrentFile(e.target.files[0])
        }
    }

    return (
        <div className={styles.overlay}>
            {BTTower &&
                <BTTowerForm
                    localEnd={BTTowerLocalEnd}
                    setBTTower={setBTTower}
                    closeForm={closeBTTowerForm}
                />}
            {IP &&
                <IPForm
                    currentChannel={IPInfo}
                    setIP={setIP}
                    closeForm={closeSRTowerForm}
                />
            }
            {Satellite &&
                <SatelliteForm
                    satelliteDetails={satelliteDetails}
                    setSatellite={setSatellite}
                    closeForm={closeSatelliteForm}
                    fixtureId={fixtureId}

                />}
            <form
                onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                className={styles.form}
            >
                <h6 className={styles.title}>Ingest</h6>
                <div className={styles.content}>
                    <div className={styles.inputs}>
                        <div className={styles.selectSection}>
                            <div className={styles.inputInner}>
                                <label className={styles.label}>Rights Holder
                                    <Input
                                        value={ingestFormInit.rightHolder}
                                        readOnly={true}
                                    />
                                </label>
                            </div>
                            <div className={styles.inputInner}>
                                <label className={styles.label}>Sport
                                    <Input
                                        value={ingestFormInit.sportName}
                                        readOnly={true}
                                    />
                                </label>
                            </div>
                            <div className={styles.inputInner}>
                                <label className={styles.label}>Technical Provider
                                    <Input
                                        value={ingestFormInit.technicalProvider}
                                        readOnly={true}
                                    />
                                </label>
                            </div>
                            {!currentSelectedIngMethod && <div className={styles.inputInner}>
                                <label className={styles.label}>Ingest method
                                    <SelectInput
                                        value={selectedIngestType}
                                        onChange={(e) => setSelectedIngestType(e)}
                                        options={formatSelectOptions(ingestFormInit.ingetsTypes.items)}
                                        placeholder={"Choose an option"}
                                    />
                                </label>
                            </div>
                            }
                            {currentSelectedIngMethod && <div className={styles.ingestMethodInfo}>
                                <p className={styles.infoText}>Selected ingest method :
                                    <span className={styles.methodName}>{currentSelectedIngMethod}</span>
                                </p>
                                <div className={styles.methodControls}>
                                    <p onClick={() => openAdditionalFormForUpdate(currentSelectedIngMethod)}>Update</p>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <label
                        className={uploaded
                            ? `${styles.uploadLabel} ${styles.uploaded}`
                            : dragActive
                                ? `${styles.uploadLabel} ${styles.dragActive}`
                                : styles.uploadLabel}
                        htmlFor="file"
                        data-testid={"fileLoaderLabel"}
                    >
                        <img src={upload} alt="upload"/>
                        <div className={styles.uploadTextSection}>
                            {
                                dragActive &&
                                <div className={styles.coverElement}
                                     onDragEnter={handleDrag}
                                     onDragLeave={handleDrag}
                                     onDragOver={handleDrag}
                                     onDrop={handleDrop}>
                                </div>
                            }
                            <h2 className={styles.uploadTitle}>Upload files</h2>
                            {
                                uploaded
                                    ?
                                    <p>File uploaded!</p>
                                    :
                                    <>
                                        <p>
                                            Drag and drop file here to upload or click to find it on your
                                            computer
                                        </p>
                                        <p className={styles.uploadDescription}>
                                            Max file size is 500kb Supported file types are .jpg and .png
                                        </p>
                                    </>
                            }
                        </div>
                        <input
                            onChange={handleUploadingOnClick}
                            type="file"
                            id="file"
                            className={styles.uploadInput}
                        />
                    </label>
                    <p className={styles.formErrorBlock}>{error}</p>
                </div>
                <div className={styles.buttons}>
                    <button onClick={() => closeIngestForm()} className={styles.cancelButton}>Cancel</button>
                    <button
                        onClick={(e) => onUpload(e)}
                        className={styles.submitButton}
                    >Confirm
                    </button>
                </div>
            </form>
        </div>
    );
};

IngestForm.displayName = "IngestFormReporting"
export default IngestForm;
