import React, {useEffect, useState} from 'react';
import styles from './index.module.scss'
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {
    confirmAccess,
    getUnconfirmedAccesses,
    ISavedAccess
} from "../../../../../store/operatorAccess/operatorAccessSlice";
import {toastr} from "react-redux-toastr";
import Checkbox from "../../../../../components/Checkbox";
import {FaPencilAlt, FaRegTrashAlt} from "react-icons/fa";


interface ICreateAccessTableProps {
    openConfirmModal: (deletedAccess: ISavedAccess, selectedAccesses: string[]) => void;
    setUpdatingAccess: (accessId: string) => void;
    rightHolderId: string | null;
}

const CreateAccessTable: React.FC<ICreateAccessTableProps> = ({setUpdatingAccess, rightHolderId, openConfirmModal}) => {
    const dispatch = useAppDispatch()
    const {unconfirmedAccess} = useAppSelector(state => state.operatorAccess)
    const [selectedAccesses, setSelectedAccesses] = useState<string[]>([]);

    useEffect(() => {
        dispatch(getUnconfirmedAccesses(rightHolderId));
    }, [rightHolderId, dispatch]);


    const selectAccess = (e: React.MouseEvent<HTMLDivElement>, accessId: string) => {
        e.preventDefault()
        e.stopPropagation()
        const isExistAccessInList = selectedAccesses.find(access => access === accessId)
        if (isExistAccessInList) {
            const accesses = selectedAccesses.filter(access => access !== accessId)
            setSelectedAccesses(accesses)
        } else {
            const selectedAccess = unconfirmedAccess.find(access => access.id === accessId)
            if (selectedAccess) {
                setSelectedAccesses([...selectedAccesses, selectedAccess.id])
            }
        }
    }
    const onConfirmAccess = () => {
        const body = {
            client: rightHolderId,
            selectedAccesses
        }
        if (selectedAccesses.length > 0) {
            dispatch(confirmAccess(body))
            setSelectedAccesses([])
        } else {
            toastr.warning("Booking", " There is no chosen accesses ")
        }
    }

    const checkCheckbox = (accessId: string) => {
        const isChecked = selectedAccesses.find(access => access === accessId)
        return !!isChecked
    }

    const checkIsAllFixturesSelected = (access: ISavedAccess) => {
        if (!access.fixturesCount && access.assignedAllFixtures) {
            return "All"
        } else {
            return access.fixturesCount
        }
    }

    const selectAllAccesses = () => {
        const allAccesses = unconfirmedAccess.map(access => access.id);
        setSelectedAccesses(allAccesses)
    }

    const cancelSelectedAccesses = () => {
        setSelectedAccesses([]);
    }

    return (
        <div className={styles.tableOverlay}>
            <h3 className={styles.tableTitle}>Create Access</h3>

            <div className={styles.tableScroll}>
                <div className={styles.tableWrapper}>
                    <div className={styles.tableMenu}>
                        <div className={styles.controlBox}>
                            <div onClick={onConfirmAccess}
                                 className={selectedAccesses.length > 0 ? styles.menuButton : styles.notActiveMenuBtn}>
                                Confirm +
                            </div>

                            <div onClick={selectAllAccesses}
                                 className={selectedAccesses.length !== unconfirmedAccess.length ? styles.menuButton : styles.notActiveMenuBtn}>
                                Select all
                            </div>

                            <div onClick={cancelSelectedAccesses}
                                 className={selectedAccesses.length > 0 ? styles.menuButton : styles.notActiveMenuBtn}>
                                Cancel
                            </div>
                        </div>
                    </div>

                    <table className={styles.pricingTable}>
                        <thead>
                        <tr>
                            <th>Operator</th>
                            <th>Right</th>
                            <th>Fixtures</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {unconfirmedAccess.map((access, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <div className={styles.cellWithCheckbox}>
                                            <div onClick={(e) => selectAccess(e, access.id)}
                                                 className={styles.checkbox}>
                                                <Checkbox isChecked={checkCheckbox(access.id)}/>
                                            </div>
                                            <p>{access.operatorName}</p>
                                        </div>
                                    </td>
                                    <td>{access.rightName}</td>
                                    <td>{checkIsAllFixturesSelected(access)}</td>
                                    <td>
                                        <div className={styles.tableControl}>
                                            <FaPencilAlt onClick={() => setUpdatingAccess(access.id)}
                                                         data-testid="update-pricing-button"/>

                                            <FaRegTrashAlt
                                                onClick={() => openConfirmModal(access, selectedAccesses)}
                                                data-testid="delete-pricing-button"/>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default CreateAccessTable;
CreateAccessTable.displayName = "CreateAccessTable"
