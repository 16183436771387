import {validateFileSize} from "../../../../../../helpers/validateFileSize";

export const checkFileIsInvalid = (
    file: File,
    width: number,
    height: number,
    maxFileSize: number | null = null,
    allowedExtensions = ['png', 'svg'],
    allowedMimeTypes = ['image/png', 'image/svg+xml'],
    validateDimension = true
) => {
    const validateFileFormat = (file: File): boolean => {
        // @ts-ignore
        const extension = file.name.split('.').pop().toLowerCase();
        const mimeType = file.type;

        const isExtensionAllowed = allowedExtensions.includes(extension);
        const isMimeTypeAllowed = allowedMimeTypes.includes(mimeType);

        return isExtensionAllowed && isMimeTypeAllowed;
    }
    const checkFileSize = (file: File) => {
        if (maxFileSize === null) {
            return true;
        }
        return file.size <= maxFileSize;
    }

    if (!validateFileFormat(file)) {
        return Promise.resolve("File format is invalid");
    }
    if (!checkFileSize(file)) {
        return Promise.resolve("Max size should be 500kb");
    }
    if (validateDimension) {
        return validateFileSize(file, width, height);
    }
}

