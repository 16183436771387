import {PageTabs} from "../UserManagement";

export const generateTypeOfUser = (userType: PageTabs) => {
    switch (userType) {
        case PageTabs.RIGHTS_HOLDERS:
            return 'rights holder';
        case PageTabs.OPERATORS:
            return 'operator';
        default:
            return 'user';
    }
}
