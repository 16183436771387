import {IngestFormController, IngestTabs} from "@enums/ingest";
import {UseFormClearErrors, UseFormSetError} from "react-hook-form/dist/types/form";
import {FieldValues, UseFormGetValues} from "react-hook-form";
import {hasNestedObjectData} from "@helpers/hasNestedObjectData";
const errorMessageMs = 4000;
interface IIngestValidatSumbition {
    getValues: UseFormGetValues<FieldValues>;
    setError: UseFormSetError<FieldValues>;
    clearErrors: UseFormClearErrors<FieldValues>;
}
class IngestValidate {

    detectInvalidFileType(files: File[], supportedFileTypes: string[]) {
        const invalidFiles = files.filter(file => {
            const extension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
            return !supportedFileTypes.includes(extension);
        });
        return invalidFiles.length > 0;
    }

    detectInvalidContentSupplier({getValues, setError, clearErrors}: IIngestValidatSumbition) {
        if (!getValues(IngestFormController.ContentSupplier)) {
            setError(IngestFormController.ContentSupplier, {
                type: "required",
                message: "Content supplier is required",
            })
            setTimeout(() => {
                clearErrors()
            }, errorMessageMs)
            return true
        }
        return false
    }

    detectInvalidPrimaryMethod({getValues, setError, clearErrors}: IIngestValidatSumbition) {
        if (!getValues(IngestFormController.PrimaryMethod)) {
            setError(IngestFormController.PrimaryMethod, {
                type: "required",
                message: "Primary ingest is required",
            });
            setTimeout(() => {
                clearErrors()
            }, errorMessageMs);
            return true
        }
        return false
    }

    detectInvalidBackupMethod({getValues, setError, clearErrors}: IIngestValidatSumbition) {
        if (!getValues(IngestFormController.BackupMethod)) {
            setError(IngestFormController.BackupMethod, {
                type: "required",
                message: "Backup ingest is required",
            });
            setTimeout(() => {
                clearErrors()
            }, errorMessageMs);
            return true
        }
        return false
    }

    detectInvalidBackupIPChannels({getValues, setError, clearErrors}: IIngestValidatSumbition) {
        if (!getValues(IngestFormController.BackupIPChannels)) {
            setError(IngestFormController.BackupIPChannels, {
                type: "required",
                message: "Backup IP channel is required",
            });
            setTimeout(() => {
                clearErrors()
            }, errorMessageMs);
            return true
        }
        return false
    }

    detectInvalidPrimaryIPChannels({getValues, setError, clearErrors}: IIngestValidatSumbition) {
        if (!hasNestedObjectData(getValues(IngestFormController.PrimaryIPChannels))) {
            setError(IngestFormController.PrimaryIPChannels, {
                type: "required",
                message: "Primary IP channel is required",
            });
            setTimeout(() => {
                clearErrors()
            }, errorMessageMs);
            return true
        }
        return false
    }

    detectInvalidBackupBTTowerSource({getValues, setError, clearErrors}: IIngestValidatSumbition) {
        if (!getValues(IngestFormController.BackupSource)) {
            setError(IngestFormController.BackupSource, {
                type: "required",
                message: "Backup BTTower Source is required",
            });
            setTimeout(() => {
                clearErrors()
            }, errorMessageMs);
            return true
        }
        return false
    }

    detectInvalidPrimaryBTTowerSource({getValues, setError, clearErrors}: IIngestValidatSumbition) {
        if (!getValues(IngestFormController.PrimarySource)) {
            setError(IngestFormController.PrimarySource, {
                type: "required",
                message: "BTTower Source is required",
            });
            setTimeout(() => {
                clearErrors()
            }, errorMessageMs);
            return true
        }
        return false
    }

    isInvalidIngestType(formTab: IngestTabs, isBackup: boolean, setError: UseFormSetError<FieldValues>, clearErrors: UseFormClearErrors<FieldValues>) {
        if (formTab === IngestTabs.Primary && isBackup) {
            setError(IngestFormController.IngestTypeError, {
                type: "required",
                message: "Unselect back-up activation on back-up ingset tab",
            });
            setTimeout(() => {
                clearErrors()
            }, errorMessageMs);
            return true
        }

        if (formTab === IngestTabs.BackUP && !isBackup) {
            setError(IngestFormController.IngestTypeError, {
                type: "required",
                message: "Primary ingest activated. Activate backup checkbox ",
            });
            setTimeout(() => {
                clearErrors()
            }, errorMessageMs);
            return true
        }
        return false
    }
}

const ingestValidateInstance = new IngestValidate();
export default ingestValidateInstance;