import React from "react";
import {Route} from "react-router";
import ProtectedRoute from "../../../../components/ProtectedRoute/ProtectedRoute";
import {Group} from "../../../../enums/group";
import BookFixtures from "../../RightsHolderView/ScheduleIngest/BookFixtures/BookFixtures";
import {rightsHolderSidebarData} from "../../CmsSideBarData/CmsSideBarData";
import UpcomingBookedFixtures from "../../RightsHolderView/ScheduleIngest/UpcomingBookedFixtures/UpcomingBookFixtures";
import AnalyticsPage from "../../RightsHolderView/AnalyticsPage/AnalyticsPage";
import UserManagementRHOP from "../../RightsHolderView/Settings/UserManagementRHOP";
import {IAppRoute} from "./RouteInterface";
import AuditTrailPricing from "../../../../components/AuditTrails/AuiditTrailsPricing/AuditTrailPricing";
import AuditTrailsOperatorAccess
    from "../../../../components/AuditTrails/AuditTrailsOperatorAccess/AuditTrailsOperatorAccess";
import AuditTrailsBookings from "../../../../components/AuditTrails/AuditTrailsBookings/AuditTrailsBookings";
import AuditTrailsUserManagement
    from "../../../../components/AuditTrails/AuditTrailsUserManagement/AuditTrailsUserManagement";
import ReportingIssues from "../../IGameView/Settings/ReportingIssues/ReportingIssues";
import Pricing from "../../RightsHolderView/Pricing/Pricing";
import ContentAccess from "../../RightsHolderView/ContentAccess/ContentAccess";
import PropertyRestrictions from "../../RightsHolderView/GeoRestrictions/PropertyRestrictions/PropertyRestrictions";
import Cart from "../../../../components/Carts/Cart/Cart";
import OperatorGeoRestrictions
    from "../../RightsHolderView/GeoRestrictions/OperatorGeoRestrictions/OperatorGeoRestrictions";
import IGameMediaBilling from "../../RightsHolderView/Billing/iGameMedia/IGameMediaBilling";
import BillingOperator from "../../RightsHolderView/Billing/BillingOperator/BillingOperator";
import RHReporting from "../../RightsHolderView/Settings/RHReporting/RHReporting";


export const RightHolderRouters: IAppRoute[] = [
    {
        name: "rightHolder-book-fixtures",
        route:
            <Route path="cms/book-fixtures"
                   element={
                       <ProtectedRoute forGroup={Group.RightHolder}>
                           <BookFixtures menuStructure={rightsHolderSidebarData[0]} rightHolderId={null}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "rightHolder-upcoming-fixtures",
        route:
            <Route path="cms/upcoming-fixtures"
                   element={
                       <ProtectedRoute forGroup={Group.RightHolder}>
                           <UpcomingBookedFixtures menuStructure={rightsHolderSidebarData[0]} rightHolderId={null}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "rightHolder-operator-access",
        route:
            <Route path="cms/operator-access"
                   element={
                       <ProtectedRoute forGroup={Group.RightHolder}>
                           <ContentAccess
                               menuStructure={rightsHolderSidebarData[1]} rightHolderId={null}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "right-holder-geo-allowed",
        route:
            <Route path="cms/right-holder-geo-allowed"
                   element={
                       <ProtectedRoute forGroup={Group.RightHolder}>
                           <OperatorGeoRestrictions menuStructure={rightsHolderSidebarData[1]} rightHolderId={null}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "right-holder-geo-blocked",
        route:
            <Route path="cms/right-holder-geo-blocked"
                   element={
                       <ProtectedRoute forGroup={Group.RightHolder}>
                           <PropertyRestrictions menuStructure={rightsHolderSidebarData[1]} rightHolderId={null}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "rightHolder-pricing",
        route:
            <Route path="cms/pricing"
                   element={
                       <ProtectedRoute forGroup={Group.RightHolder}>
                           <Pricing menuStructure={rightsHolderSidebarData[1]} rightHolderId={null}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "rightHolder-analytics",
        route:
            <Route path="cms/analytics"
                   element={
                       <ProtectedRoute forGroup={Group.RightHolder}>
                           <AnalyticsPage menuStructure={rightsHolderSidebarData[2]}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "rightHolder-billing-operator",
        route:
            <Route path="cms/billing-operator"
                   element={
                       <ProtectedRoute forGroup={Group.RightHolder}>
                           <BillingOperator menuStructure={rightsHolderSidebarData[3]}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "rightHolder-billing-igame",
        route:
            <Route path="cms/billing-igame/*"
                   element={
                       <ProtectedRoute forGroup={Group.RightHolder}>
                           <IGameMediaBilling menuStructure={rightsHolderSidebarData[3]}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "rightHolder-settings",
        route:
            <Route path="cms/rightHolder-settings"
                   element={
                       <ProtectedRoute forGroup={Group.RightHolder}>
                           <UserManagementRHOP menuStructure={rightsHolderSidebarData[4]} roleName={'rightHolder'}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "audit-trails",
        route:
            <Route path="/cms/audit-trails-pricing"
                   element={
                       <ProtectedRoute forGroup={Group.RightHolder}>
                           <AuditTrailPricing menuStructure={rightsHolderSidebarData[4]}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "audit-trails",
        route:
            <Route path="/cms/audit-trails-operator-access"
                   element={
                       <ProtectedRoute forGroup={Group.RightHolder}>
                           <AuditTrailsOperatorAccess menuStructure={rightsHolderSidebarData[4]}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "audit-trails",
        route:
            <Route path="/cms/audit-trails-bookings"
                   element={
                       <ProtectedRoute forGroup={Group.RightHolder}>
                           <AuditTrailsBookings menuStructure={rightsHolderSidebarData[4]}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "audit-trails",
        route:
            <Route path="/cms/audit-trails-user-management"
                   element={
                       <ProtectedRoute forGroup={Group.RightHolder}>
                           <AuditTrailsUserManagement menuStructure={rightsHolderSidebarData[4]}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "reporting-issues",
        route:
            <Route path="/cms/reporting-issues"
                   element={
                       <ProtectedRoute forGroup={Group.RightHolder}>
                           <ReportingIssues menuStructure={rightsHolderSidebarData[5]}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "rh-reporting",
        route:
            <Route path="/cms/rh-reporting"
                   element={
                       <ProtectedRoute forGroup={Group.RightHolder}>
                           <RHReporting menuStructure={rightsHolderSidebarData[5]}/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
    {
        name: "rightHolder-cart",
        route:
            <Route path="cms/igame-rightHolders/:id/rightHolder-cart"
                   element={
                       <ProtectedRoute forGroup={Group.RightHolder}>
                           <Cart/>
                       </ProtectedRoute>
                   }>
            </Route>
    },
]


