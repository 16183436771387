import * as signalR from "@microsoft/signalr";
import {connectSocket} from "./store/auth/authSlice";
import {IAlert, removeActiveAlert, setActiveAlert, updateAlerts} from "./store/alerts/alertsSlice";

const getConnection = (token: string): signalR.HubConnection => {
    const baseURL = process.env.REACT_APP_API_ENDPOINT;

    return new signalR.HubConnectionBuilder()
        .withUrl(`${baseURL}/AlertHub`, {accessTokenFactory: () => token})
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();
};

const createSocketMiddleware = () => {
    let hubConnection: signalR.HubConnection;
    return (storeAPI: any) => (next: any) => (action: any) => {
        switch (action.type) {
            case connectSocket.type: {
                const token = action.payload;

                if (!token) {
                    console.error("Token is empty");
                    return next(action);
                }
                hubConnection = getConnection(token);
                hubConnection.start().then(() => {
                    console.log("Connected");
                });
                hubConnection.onclose((error: any = '') => {
                    console.log("Connection closed. " + error);
                });
                hubConnection.on("onError", alert);

                hubConnection.on("ReceiveAlert", (data: IAlert) => {
                    storeAPI.dispatch(setActiveAlert(data));
                    storeAPI.dispatch(updateAlerts(data));

                    setTimeout(() => {
                        storeAPI.dispatch(removeActiveAlert());
                    }, 5000)
                });

                return next(action);
            }
            default: {
                return next(action);
            }
        }
    }
};

export {createSocketMiddleware}
