import React, {useEffect, useState} from 'react';
import styles from "./index.module.scss";
import {RiDeleteBinLine} from "react-icons/ri";
import {BiEditAlt, BiEnvelope} from "react-icons/bi";
import {IoRefreshOutline} from "react-icons/io5";
import {useAppDispatch, useAppSelector} from "@hooks/hooks";
import ConfirmModal from "../../../../../../../../../components/ConfirmModal/ConfirmModal";
import {IUser, resetUserPassword} from "@store/settings/settingsSlice";
import {
    clearTableContents,
    clearTechnicalProviderDetails,
    clearUserDetails, getTechnicalProviderDetails,
    getUserDetails, IUserItem, restoreUser, userAlert,
} from "@store/userManagement/userManagementSlice";
import UserForm from "../UserForm/UserForm";
import DeleteUserConfirmation
    from "../../../../../../../../../components/DeleteUserConfirmation/DeleteUserConfirmation";
import moment from "moment/moment";
import {Tooltip} from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import {PageTabs} from "../../../../UserManagement";
import TechnicalProviderForm from "../TechnicalProviderForm/TechnicalProviderForm";
import {usersSorting, useUsersSorting} from "../../../../UserManagementHooks/useUsersSorting";
import Sorting from "../../../Sorting/Sorting";
import {useNavigate} from "react-router-dom";
import {InnerRole} from "@enums/innerRole";
import {useDisableScroll} from "@hooks/useDisableScroll";
import auditIcon from "../../../../../../../../../img/auditWhite.svg";
import {HiOutlineEnvelope} from "react-icons/hi2";
import CustomCheckbox from "../../../../../../../../../components/CustomCheckbox/CustomCheckbox";
import {checkAuditPageAllowance} from "@helpers/checkAuditPageAllowance";
import Loader from "../../../../../../../../../components/Loader/Loader";

interface IUsersTableProps {
    users: IUser [] | null;
    userRole: string;
    sortUsers: (field: string, isAscending: boolean) => void;
    pdfLayout?: React.RefObject<HTMLDivElement>;
    isDownloadingPDF: boolean;
    isShort?: boolean;
}

const UsersTable: React.FC<IUsersTableProps> = React.memo((
    {
        users,
        pdfLayout,
        userRole,
        sortUsers,
        isDownloadingPDF,
        isShort
    }) => {
    const dispatch = useAppDispatch();
    const {role, specificRole} = useAppSelector(state => state.userInfo);
    const {loading} = useAppSelector(state => state.userManagement);
    const [isOpenEditForm, setIsOpenEditForm] = useState<boolean>(false);
    const [isOpenConfirm, setIsOpenConfirm] = useState(false);
    const [deletingUser, setDeletingUser] = useState<IUserItem | undefined>(undefined);
    const [emailForResetPassword, setEmailForResetPassword] = useState<string | undefined>(undefined);
    const [currentSortingField, setCurrentSortingField] = useState("");
    const {onSortUsers, sortingState} = useUsersSorting(sortUsers);
    const navigate = useNavigate();
    useDisableScroll(isOpenConfirm, isOpenEditForm);

    useEffect(() => {
        return () => {
            if (userRole === PageTabs.TECHNICAL_PROVIDERS) {
                dispatch(clearTableContents("technicalProviders"));
            }
            if (userRole === PageTabs.I_GAME_USERS) {
                dispatch(clearTableContents("users"));
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closeForm = () => {
        if (isOpenEditForm) setIsOpenEditForm(false);
        if (userRole === PageTabs.TECHNICAL_PROVIDERS) dispatch(clearTechnicalProviderDetails());
        dispatch(clearUserDetails());
    };

    const editUserHandler = (id: string) => {
        setIsOpenEditForm(true);
        if (userRole === PageTabs.TECHNICAL_PROVIDERS) {
            dispatch(getTechnicalProviderDetails(id))
        } else {
            dispatch(getUserDetails(id))
        }
    }

    const resetPasswordHandler = () => {
        if (emailForResetPassword) {
            const resetData = {
                email: emailForResetPassword
            }
            dispatch(resetUserPassword(resetData));
        }
        setEmailForResetPassword(undefined);
    }

    const restoreUserHandler = (id: string) => {
        dispatch(restoreUser({
            userId: id,
            role: userRole
        }));
    }

    const handleCheckbox = (userId: string) => {
        dispatch(userAlert({
            userId,
            role: userRole
        }));
    }

    const onSort = (sortingField: string): void => {
        setCurrentSortingField(sortingField);
        onSortUsers(sortingField);
    }

    const handleCloseConfirm = (): void => {
        setIsOpenConfirm(!isOpenConfirm);
        if (deletingUser) {
            setDeletingUser(undefined)
        } else if (emailForResetPassword) {
            setEmailForResetPassword(undefined)
        }
    }

    const goToAuditTrail = (email: string, id: string) => {
        const encodedEmail = encodeURIComponent(email);

        navigate(`/cms/audit-trails-user-management?email=${encodedEmail}&id=${id}&isExternal=${true}`);
    }

    const setTableStyle = () => {
        if (isDownloadingPDF) {
            return styles.pdfTable;
        } else if (isShort) {
            return `${styles.tableInner} ${styles.tableShort}`;
        } else {
            return styles.tableInner;
        }
    }

    return (
        <>
            {loading && <Loader/>}
            {!loading && users ? <>
                    {isOpenEditForm && userRole !== PageTabs.TECHNICAL_PROVIDERS &&
                        <UserForm
                            closeForm={closeForm}
                            userType={userRole}/>
                    }

                    {isOpenEditForm && userRole === PageTabs.TECHNICAL_PROVIDERS &&
                        <TechnicalProviderForm closeForm={closeForm}/>
                    }

                    {emailForResetPassword &&
                        <ConfirmModal
                            title={'Reset Password'}
                            message={'Send the current user a reset password link?'}
                            closeForm={handleCloseConfirm}
                            action={resetPasswordHandler}>
                            <HiOutlineEnvelope color={'#FFFFFF'} size={100}
                            />
                        </ConfirmModal>
                    }

                    {deletingUser &&
                        <DeleteUserConfirmation
                            user={deletingUser}
                            closeConfirmModal={handleCloseConfirm}
                            deletingUserId={deletingUser.id}
                            userRole={userRole}
                        />
                    }

                    <div ref={pdfLayout} className={setTableStyle()}>
                        <table className={styles.usersTable}>
                            <thead className={isDownloadingPDF && styles.pdfHead}>
                            <tr>
                                <th onClick={() => onSort(usersSorting.EMAIL)}>
                                    <Sorting
                                        sortingState={sortingState}
                                        currentSortingField={currentSortingField}
                                        sortingProperty={usersSorting.EMAIL}
                                        sortingColumnName={'Email'}
                                    />
                                </th>

                                {userRole === PageTabs.TECHNICAL_PROVIDERS &&
                                    <th onClick={() => onSort(usersSorting.TECHNICAL_PROVIDER)}>
                                        <Sorting
                                            sortingState={sortingState}
                                            currentSortingField={currentSortingField}
                                            sortingProperty={usersSorting.TECHNICAL_PROVIDER}
                                            sortingColumnName={'Technical Provider'}
                                        />
                                    </th>
                                }
                                <th onClick={() => onSort(usersSorting.LAST_LOGIN_DATE)}>
                                    <Sorting
                                        sortingState={sortingState}
                                        currentSortingField={currentSortingField}
                                        sortingProperty={usersSorting.LAST_LOGIN_DATE}
                                        sortingColumnName={'Last Login Date'}
                                    />
                                </th>

                                {userRole !== PageTabs.TECHNICAL_PROVIDERS &&
                                    <th onClick={() => onSort(usersSorting.ROLE_NAME)}>
                                        <Sorting
                                            sortingState={sortingState}
                                            currentSortingField={currentSortingField}
                                            sortingProperty={usersSorting.ROLE_NAME}
                                            sortingColumnName={'Role'}
                                        />
                                    </th>
                                }

                                {!isDownloadingPDF && <>
                                    <th className={styles.contentCenter}>
                                        <span className="exclude-from-pdf">Actions</span>
                                    </th>
                                    <th className={styles.contentCenter}>Reset Password</th>
                                    <th className={styles.contentCenter}>Alerting Mode</th>
                                    {
                                        checkAuditPageAllowance() &&
                                        <th className={styles.contentCenter}>Audit Tool</th>
                                    }
                                </>
                                }
                            </tr>
                            </thead>

                            <tbody>
                            {users.map(user => (
                                <tr key={user.id} className={user.isDeleted && styles.deletedUser}>
                                    <td>{user.email}</td>

                                    {userRole === PageTabs.TECHNICAL_PROVIDERS &&
                                        <td>{user.technicalProvider}</td>}

                                    <td>{user.lastLoginDate && moment(user.lastLoginDate).format("YYYY-MM-DD HH:mm")}</td>

                                    {userRole !== PageTabs.TECHNICAL_PROVIDERS &&
                                        <td>{user.roleName}</td>}

                                    {!isDownloadingPDF &&
                                        <>
                                            <td>
                                                <div className={`${styles.actions} exclude-from-pdf`}>

                                                    {specificRole === InnerRole.SuperAdmin &&
                                                        <>
                                                            <button onClick={() => {
                                                                if (user.isDeleted) return;
                                                                return editUserHandler(user.id);
                                                            }}
                                                                    className={styles.actionBtn}>
                                                                <Tooltip className={styles.tooltip} id="update-tooltip"/>
                                                                <BiEditAlt
                                                                    size={18}
                                                                    color={user.isDeleted ? '#8D8D8D' : '#FFFFFF'}
                                                                    data-tooltip-id="update-tooltip"
                                                                    data-tooltip-content={"Update"}
                                                                />
                                                            </button>

                                                            <button
                                                                onClick={() => {
                                                                    if (user.isDeleted) return;
                                                                    setIsOpenConfirm(!isOpenConfirm);
                                                                    setDeletingUser(user);
                                                                }}
                                                                className={styles.actionBtn}
                                                            >
                                                                <Tooltip className={styles.tooltip} id="delete-tooltip"/>
                                                                <RiDeleteBinLine
                                                                    size={18}
                                                                    color={user.isDeleted ? '#8D8D8D' : '#FFFFFF'}
                                                                    data-tooltip-id="delete-tooltip"
                                                                    data-tooltip-content={"Delete"}/>
                                                            </button>
                                                        </>
                                                    }

                                                    {specificRole === InnerRole.SuperAdmin &&
                                                        <button
                                                            onClick={() => {
                                                                if (!user.isDeleted) return
                                                                restoreUserHandler(user.id);
                                                            }}
                                                            className={styles.actionBtn}
                                                        >
                                                            <Tooltip className={styles.tooltip} id="restore-tooltip"/>
                                                            <IoRefreshOutline
                                                                size={18}
                                                                color={user.isDeleted ? '#8D8D8D' : '#FFFFFF'}
                                                                data-tooltip-id="restore-tooltip"
                                                                data-tooltip-content={"Restore"}
                                                            />
                                                        </button>
                                                    }
                                                </div>
                                            </td>

                                            <td className={styles.contentCenter}>
                                                <button
                                                    onClick={() => {
                                                        if (user.isDeleted) return;
                                                        setIsOpenConfirm(!isOpenConfirm);
                                                        setEmailForResetPassword(user.email);
                                                    }}
                                                    className={styles.actionBtn}
                                                >
                                                    <BiEnvelope
                                                        size={18}
                                                        color={user.isDeleted ? '#8D8D8D' : '#FFFFFF'}
                                                    />
                                                </button>
                                            </td>

                                            <td className={styles.contentCenter}>
                                                {role !== PageTabs.OPERATORS &&
                                                    <button className={styles.actionBtn}>
                                                        <CustomCheckbox
                                                            checked={!!user.isAlertTicked}
                                                            onChange={() => handleCheckbox(user.id)}
                                                            disabled={user.isDeleted}
                                                            id={user.id}
                                                        />
                                                    </button>
                                                }
                                            </td>

                                            {
                                                checkAuditPageAllowance() &&
                                                <td className={styles.contentCenter}>
                                                    <button
                                                        onClick={() => {
                                                            if (user.isDeleted) return;
                                                            goToAuditTrail(user.email, user.id);
                                                        }}
                                                        className={styles.actionBtn}
                                                    >
                                                        <img
                                                            src={auditIcon} alt="audit"
                                                            style={{filter: user.isDeleted ? "brightness(0.6)" : "brightness(1)"}}
                                                        />
                                                    </button>
                                                </td>
                                            }
                                        </>
                                    }
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    {users && users.length === 0 && <div className={styles.searchResult}>No results...</div>}
                </>
                : null
            }
        </>
    );
});

UsersTable.displayName = "UsersTable"
export default UsersTable;
