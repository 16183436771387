import React, {useEffect, useRef} from "react";
import styles from "./index.module.scss";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import OperatorDefaultTable from "../../../../components/OperatorDefaultTable/OperatorDefaultTable";
import {useAppDispatch, useAppSelector} from "@hooks/hooks";
import {IColumnItem} from "@components/OperatorSortingHeader/OperatorSortingHeader";
import cart from "../../../../img/cartEmpty.svg";
import bfCart from "../../../../img/additionalCartIcon.svg";
import MonthTable from "./children/MonthTable/MonthTable";
import NoDataFoundStub from "../../../../components/NoDataFoundStub/NoDataFoundStub";
import {addFixtureToCart, addPropertyToCart} from "@store/carts/operatorCart/operatorCartSlice";
import {useGetCartItems} from "@hooks/useGetCartItems";
import {IOperatorPageProps} from "../RightsHoldersPortfolio/RightsHolderPortfolio";
import {useLocation, useNavigate} from "react-router-dom";
import {
    getSheduleAndCardData,
    getUpcomingFixtures, IUpcomingFixture, IUpcomingFixturesPayload,
    setPageNumber, setRemainingSelection, updateFixtureCartStatus
} from "@store/propertyDetail/propertyDetail";
import {AppInfiniteScroll} from "@components/AppInfiniteScroll/AppInfiniteScroll";
import DayTable from "./children/DayTable/DayTable";
import {SlArrowLeft} from "react-icons/sl";
import {useSorting} from "./hooks/useSorting";
import {addFixtureToBFCart} from "@store/carts/operatorBFCart/bookFixtureCartSlice";

const PropertyDetail: React.FunctionComponent<IOperatorPageProps> = ({menuStructure, operatorId, users}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    useGetCartItems(operatorId);
    const containerRef = useRef<HTMLDivElement>(null);
    const {
        pageNumber,
        itemsPerPage,
        scrollLoader,
        maxItemsInTheTable,
        records,
        totalCount,
        scheduleAndCardData,
    } = useAppSelector(state => state.propertyDetail);
    const query = new URLSearchParams(useLocation().search);
    const propertyId = query.get("propertyId");
    const propertyName = query.get("propertyName");
    const {setSorting, sorting} = useSorting(propertyId, itemsPerPage, operatorId, getUpcomingFixtures);

    const columnsData: IColumnItem[] = [
        {
            title: "Right Holder",
            criteria: "RightHolder",
            key: "rightHolder",
            textAlign: "left"
        },
        {
            title: "Sport",
            criteria: "Sport",
            key: "sport",
            textAlign: "left"
        },
        {
            title: "Property",
            criteria: "Property",
            key: "property",
        },
        {
            title: "Fixture",
            criteria: "Fixture",
            key: "fixture",
            textAlign: "left"
        },
        {
            title: "Fixture Rating",
            criteria: "Rating",
            key: "rating",
            textAlign: "center"
        },
        {
            title: "Fixture Date",
            criteria: "Date",
            key: "date",
            textAlign: "left"
        },
        {
            title: "Fixture Day",
            criteria: "Day",
            key: "day",
            textAlign: "left"
        },
        {
            title: "Start Time",
            criteria: "Time",
            key: "time",
            textAlign: "center"
        },
        {
            title: "Remaining Selections",
            criteria: null,
            key: "remainingSelections",
            textAlign: "center"
        },
        {
            title: "Add Fixture To Cart",
            criteria: null,
            key: "isBooked",
            textAlign: "center"
        }
    ];

    useEffect(() => {
        if (propertyId) {
            const payload: IUpcomingFixturesPayload = {
                propertyId,
                pageNumber,
                itemsPerPage,
                sorting
            }
            if (operatorId) {
                payload.operatorId = operatorId;
            }

            dispatch(getUpcomingFixtures({payload}))
            dispatch(getSheduleAndCardData(propertyId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyId]);

    const handleAddFixtureToCart = (fixture: IUpcomingFixture): void => {
        if (fixture.isInCart) {
            // dispatch(removeItemFromCart({
            //     operatorId,
            //     fixtureId: fixture.id,
            //     updateFixtureFunc: () => dispatch(updateFixtureCartStatus({
            //         fixtureId: fixture.id,
            //         isInCart: false
            //     }))
            // }))
        } else {
            dispatch(addFixtureToCart({
                operatorId,
                fixtureId: fixture.id,
                updateFixtureFunc: () => dispatch(updateFixtureCartStatus({
                    fixtureId: fixture.id,
                    isInCart: true
                }))
            }))
        }
    }

    const handleAddPropertyToCart = (): void => {
        if (propertyId) {
            dispatch(addPropertyToCart({
                    operatorId,
                    propertyId,
                }
            ))
        }
    }


    const addFixturesToAdditionalCart = (fixture: IUpcomingFixture) => {
        if (!propertyId) return;

        const payload = {
            fixtureId: fixture.id,
            operatorId
        }

        dispatch(addFixtureToBFCart({
                payload,
                setRemainingSelection: () => dispatch(setRemainingSelection(fixture.id))
            }
        ))
    }

    const renderActionCell = (fixture: IUpcomingFixture): React.ReactNode => {
        if (fixture.isInCart) {
            return (
                <button
                    className={styles.bookedStatus}
                    onClick={() => handleAddFixtureToCart(fixture)}>
                    Booked
                </button>
            )
        } else {
            return renderAdditionalCart(fixture);
        }

    }

    const renderAdditionalCart = (fixture: IUpcomingFixture): React.ReactNode => {
        if (!fixture.remainingSelections) {
            return (
                <button className={styles.addToCartButton} onClick={() => handleAddFixtureToCart(fixture)}>
                    <img className={styles.addToCartIcon} src={cart} alt="cart"/>
                </button>
            )
        } else {
            return (
                <button className={styles.addToCartButton} onClick={() => addFixturesToAdditionalCart(fixture)}>
                    <img className={styles.addToCartIcon} src={bfCart} alt="cart"/>
                </button>
            )
        }
    }

    const assignCellColor = (
        property: string,
        value: number,
        values: number[]
    ) => {
        const min = Math.min(...values);
        const max = Math.max(...values);
        const range = max - min;

        const step = range / 5;
        const thresholds = [
            min + step,
            min + step * 2,
            min + step * 3,
            min + step * 4,
        ];

        if (value >= min && value < thresholds[0]) {
            return "#ffd6e0";
        } else if (value >= thresholds[0] && value < thresholds[1]) {
            return "#ffadc2";
        } else if (value >= thresholds[1] && value < thresholds[2]) {
            return "#ff85a3";
        } else if (value >= thresholds[2] && value < thresholds[3]) {
            return "#ff5c85";
        } else {
            return "#ff3466";
        }
    }

    const fetchData = (page: number, pageSize: number, scrollPosition: string) => {
        if (propertyId) {
            const payload: IUpcomingFixturesPayload = {
                propertyId,
                pageNumber: page,
                itemsPerPage: pageSize,
                sorting
            }
            if (operatorId) {
                payload.operatorId = operatorId;
            }

            dispatch(getUpcomingFixtures({
                payload,
                scrollPosition
            }));
        }
    }

    const setPage = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber));
    }

    const handleGoBack = () => {
        navigate(-1);
    }

    return (
        <>
            <PageHeader
                menuStructure={menuStructure}
                users={users}
                title={"Property Detail"}>
            </PageHeader>
            <div className={styles.page}>
                <div className={styles.pageContainer}>
                    <div className={styles.upperSection}>
                        <h2 className={styles.title}>Property Detail: <br/>{propertyName}</h2>
                        <button
                            className={styles.addPropertyToCartButton}
                            type="button"
                            onClick={handleAddPropertyToCart}
                        >
                            Add Property to Cart
                        </button>
                    </div>
                    <button className={styles.backButton}
                            onClick={handleGoBack}>
                        <SlArrowLeft/>
                        Back
                    </button>
                    <div className={styles.contentBox}>
                        <div className={styles.upcomingFixturesTable}>
                            <h2 className={styles.mainTableTitle}>
                                Upcoming Fixtures
                            </h2>
                            {
                                !records.length &&
                                <NoDataFoundStub textColor={"white"}/>
                            }
                            {
                                !!records.length &&
                                <AppInfiniteScroll
                                    containerRef={containerRef}
                                    fetchData={fetchData}
                                    pageSize={itemsPerPage}
                                    totalItems={totalCount}
                                    currentPage={pageNumber}
                                    setPage={setPage}
                                    isLoading={scrollLoader}
                                    scrollingContainerHeight={"400px"}
                                    scrollBounce={200}
                                    maxItemsInTheTable={maxItemsInTheTable}
                                    tableDataLength={records.length}>
                                    <OperatorDefaultTable
                                        data={records.map(fixture => ({
                                            ...fixture,
                                            property: propertyName
                                        }))}
                                        columnsData={columnsData}
                                        renderActionCell={renderActionCell}
                                        height="400px"
                                        withInfiniteScroll={true}
                                        sorting={sorting}
                                        setSorting={setSorting}
                                    />
                                </AppInfiniteScroll>
                            }
                            <div className={styles.tableUnderline}></div>
                        </div>
                        <div className={styles.lowerSection}>
                            <div className={styles.scheduleSection}>
                                <h4 className={styles.scheduleTitle}>
                                    Schedule
                                </h4>
                                <MonthTable
                                    data={scheduleAndCardData.fixturesByMonth}
                                    assignCellColor={assignCellColor}/>
                                <DayTable
                                    data={scheduleAndCardData.fixturesByDayAndTime}
                                    assignCellColor={assignCellColor}/>
                            </div>
                            <div className={styles.propertyCard}>
                                <div className={styles.cardContent}>
                                    <div className={styles.leftSide}>
                                        <p className={styles.sportText}>{records[0]?.sport}</p>
                                        <p className={styles.propertyText}>{propertyName}</p>
                                    </div>
                                    <div className={styles.rightSide}>
                                        <p className={styles.propertyInfo}>
                                            Top Division in {scheduleAndCardData.region}<br/>
                                            {scheduleAndCardData.seasonMatches} matches in a season<br/>
                                            {scheduleAndCardData.remainingMatches} matches remaining<br/>
                                            in the current season
                                        </p>
                                        <ul className={styles.propertyStats}>
                                            <li className={styles.propertyStatsItem}>Geoblocking <span>{scheduleAndCardData.geoBlocking}</span>
                                            </li>
                                            <li className={styles.propertyStatsItem}>Property
                                                Rating <span>{scheduleAndCardData.propertyRating}</span></li>
                                            <li className={styles.propertyStatsItem}>Concurrency
                                                Rating <span>{scheduleAndCardData.concurrencyRating}</span></li>
                                            <li className={styles.propertyStatsItem}>Season
                                                Start/End <span>{scheduleAndCardData.seasonStartEnd}</span></li>
                                            <li className={styles.propertyStatsItem}>Most Common
                                                Day <span>{scheduleAndCardData.mostCommonDay}</span></li>
                                            <li className={styles.propertyStatsItem}>Most Common
                                                Time <span>{scheduleAndCardData.mostCommonTime}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PropertyDetail;


