import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {client} from "../../services/api-servise";
import {AxiosError} from "axios";
import {toastr} from "react-redux-toastr";
import {IFixturesReportPayload, IFixturesState, IGetFixturesReport, IReportFixture} from "./types";
import {formClient} from "../../services/multiPartForm-service";
import {IFixtureDetailBody, IReport} from "../iGameReporting/iGameReportingSlice";
import {getPickerDateFormat} from "../../helpers/dateFormatters";
import {DateTime} from "luxon";
import {formatSelectOptions} from "../../helpers/formatSelectOptions";
import {
    ISorting
} from "../../pages/Cms/TechnicalProviderView/UpcomingBookedFixtures/children/FixturesTable/FixturesTable";
import {appendDataHelper, prependDataHelper} from "../../helpers/reducerHelpers";
import {ingestTypes} from "@pages/Cms/IGameView/Settings/Reporting/ReportingTable/helpers/ingestTypes";


const initialState: IFixturesState = {
    fixturesReport: [],
    pageNumber: 1,
    itemsPerPage: 10,
    totalCount: 0,
    isLoading: false,
    nextRecommendedEndDate: null,
    hasMoreScroll: true,
    maxItemsInTheTable: 30,
    sorting: {
        criteria: 'Date',
        isAscending: true
    },
    filters: {
        startDate: getPickerDateFormat(DateTime.now().minus({days: 3}).toISO()),
        endDate: getPickerDateFormat(DateTime.now().toISO()),
        ingestType: {value: "4", label: "All"}
    },
    controls: [
        {
            datePicker: false,
            label: "Ingest Type",
            initValue: null,
            valueName: "ingestType",
            options: formatSelectOptions(ingestTypes),
            isMulti: false,
            separator: true

        },
        {
            datePicker: true,
            label: "Start Date",
            initValue: null,
            valueName: "startDate",
            options: null,
            withTime: false
        },
        {
            datePicker: true,
            label: "End Date",
            initValue: null,
            valueName: "endDate",
            options: null,
            withTime: false
        },
    ]
}

export const getFixturesReport = createAsyncThunk<IGetFixturesReport, IFixturesReportPayload, { rejectValue: string }>(
    'get-fixtures-report',
    async ({payload, scrollPosition}, {rejectWithValue, dispatch}) => {
        try {
            const {data, headers} = await client.post(`/api/Reports/Fixture-details/search`, payload);
            const totalCount = headers['x-total-count']
            if (totalCount) {
                dispatch(setTotalCount(Number(totalCount)))
            }
            if (scrollPosition === "down") {
                return dispatch(appendData({data: data.records}))
            }
            if (scrollPosition === "up") {
                return dispatch(prependData({data: data.records}))
            }
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const changeFixtureDetail = createAsyncThunk<IReport, IFixtureDetailBody, { rejectValue: string }>(
    'reporting/send-ingest',
    async (fixtureDetail, {rejectWithValue}) => {
        try {
            const {data} = await formClient.put(`/api/Reports/Fixture-details`, fixtureDetail);
            toastr.success('IGame', `Fixture detail was changed`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const reportingFixturesSlice = createSlice({
    name: 'reporting-fixtures',
    initialState,
    reducers: {
        setPageNumber: (state, action) => {
            state.pageNumber = action.payload
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload
        },
        setHasMore: (state, action) => {
            state.hasMoreScroll = action.payload
        },
        setFilters: (state, action: PayloadAction<{ value: any; name: keyof IFixturesState["filters"] }>) => {
            state.filters[action.payload.name] = action.payload.value;
        },
        clearFilter: (state) => {
            state.filters = initialState.filters
        },
        setSorting: (state, action: PayloadAction<ISorting>) => {
            state.sorting = action.payload
        },
        appendData(state, action: PayloadAction<{ data: IReportFixture[] }>) {
            appendDataHelper(state, action.payload.data, 'fixturesReport');
        },
        prependData(state, action: PayloadAction<{ data: IReportFixture[] }>) {
            prependDataHelper(state, action.payload.data, 'fixturesReport');
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFixturesReport.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFixturesReport.fulfilled, (state, action) => {
                state.isLoading = false;
                state.nextRecommendedEndDate = action.payload.nextRecommendedEndDate;
                state.pageNumber = action.meta.arg.payload.pageNumber
                if (action.payload.records) {
                    state.fixturesReport = action.payload.records
                }
            })
            .addCase(getFixturesReport.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(changeFixtureDetail.fulfilled, (state, action) => {
                const {isSetup, isVerified, fixtureId} = action.meta.arg
                state.fixturesReport = state.fixturesReport.map(report => {
                    if (report.fixtureId === fixtureId) {
                        return {...report, isVerified, setup: isSetup}
                    }
                    return report
                })
            })
    },
})

export const {
    setTotalCount,
    setHasMore,
    setPageNumber,
    setFilters,
    clearFilter,
    setSorting,
    appendData,
    prependData
} = reportingFixturesSlice.actions
export default reportingFixturesSlice.reducer
