import {useEffect} from "react";

export const useDisableScroll = (...args: boolean[]) => {
    useEffect(() => {
        const isDisable = args.includes(true);
        if (isDisable) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [args])
};
