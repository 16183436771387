import React, {ChangeEvent} from 'react';
import styles from '../../RightsHolderView/ScheduleIngest/UpcomingBookedFixtures/index.module.scss';
import BookingComponentHeader from "../../../../components/BookingComponentHeader/BookingComponentHeader";
import {getFixtures} from "../../../../store/rightHolderBooking/rightHolderBookingSlice";
import {useSortingAndSearch} from "../../RightsHolderView/ScheduleIngest/SheduleIngestHooks/useSortingAndSearch";
import FixturesTable from "./children/FixturesTable/FixturesTable";
import {
    useFormatUpcomingTableData
} from "../../RightsHolderView/ScheduleIngest/UpcomingBookedFixtures/hooks/useFormatUpcomingTableData";
import {useAppSelector} from "../../../../hooks/hooks";
import {RootState} from "../../../../store";

const UpcomingBookedFixtures: React.FC = React.memo(() => {
    const {tableData} = useFormatUpcomingTableData();
    const {itemsPerPage} = useAppSelector((state: RootState) => state.rightHolderBooking);
    const {setSorting, sorting, setSearchWord, searchWord} = useSortingAndSearch(null, getFixtures, true, itemsPerPage);

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchWord(e.currentTarget.value);
    }

    return (
        <>
            <div className={styles.pageContainer}>
                <div className={styles.bodyWrapper}>
                    <BookingComponentHeader
                        data={tableData}
                        searchWord={searchWord}
                        handleSearchChange={handleSearchChange}
                        title={"Upcoming Booked Fixtures"}
                        upcoming={true}
                    />
                    <FixturesTable
                        data={tableData}
                        sorting={sorting}
                        setSorting={setSorting}
                        rightHolderId={"1"}
                    />
                </div>
            </div>
        </>
    )
})

export default UpcomingBookedFixtures;
