import React, {FunctionComponent, useEffect, useState} from 'react';
import commonStyles from "../children/AuditTrailPageInner/index.module.scss";
import {useLocation, useNavigate} from "react-router-dom";
import AuditTrailPageInner from "../children/AuditTrailPageInner/AuditTrailPageInner";
import AuditTrailsTable from "../children/AuditTrailsTable/AuditTrailsTable";
import {IMenuStructure} from "../../../pages/Cms/AppRoutes/routes/RouteInterface";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {
    getFixturesBookings,
    IFixturesResponse,
    skipBookingsSearch
} from "../../../store/auditTrailsBookings/auditTrailsBookingsSlice";
import {useAuditingEntitiesSearch} from "../hooks/useAuditingEntitiesSearch";
import {useBookingDetailsFromQueryString} from "../hooks/useBookingDetailsFromQueryString";
import BookingsDetailsTable from "./children/BookingDetailsTable/BookingDetailsTable";
import {DateTime} from "luxon";
import SearchByTextInput from "../../SearchByTextInput/SearchByTextInput";
import {RecordTypes} from "../../../enums/recordTypes";
import {getAuditTrails} from "../../../store/auditTrailsCommon/auditTrailsCommonSlice";

export interface IAuditTrailsBookingsProps {
    menuStructure: IMenuStructure;
}

const AuditTrailsBookings: FunctionComponent<IAuditTrailsBookingsProps> = React.memo(({
                                                                                          menuStructure
                                                                                      }) => {
    const dispatch = useAppDispatch();
    const [fixtureId, setFixtureId] = useState<string | null>(null);
    const [initial, setInitial] = useState(true);
    const {setRecordData, recordData} = useBookingDetailsFromQueryString();
    const {pageNumber, itemsPerPage} = useAppSelector(state => state.auditTrailsBookings);
    const {searchText, setSearchText} = useAuditingEntitiesSearch(
        getFixturesBookings,
        pageNumber,
        itemsPerPage,
        skipBookingsSearch,
        initial,
        setInitial,
        fixtureId,
        setFixtureId,
    );
    const {trailsItemsPerPage} = useAppSelector(state => state.auditTrailsCommon);

    const query = new URLSearchParams(useLocation().search);
    const queryId = query.get("id");
    const isExternal = !!query.get("isExternal");
    let navigate = useNavigate();

    useEffect(() => {
        dispatch(skipBookingsSearch());
    }, [dispatch]);

    useEffect(() => {
        setFixtureId(queryId);
    }, [queryId]);

    useEffect(() => {
        if (fixtureId) {
            dispatch(getAuditTrails({
                auditRecordType: RecordTypes.Fixture,
                recordId: fixtureId,
                pageNumber: 1,
                itemsPerPage: trailsItemsPerPage,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fixtureId]);

    const handleFixtureSelection = (fixture: IFixturesResponse) => {
        setFixtureId(fixture.id);
        setRecordData({
            technicalProvider: fixture.contentSupplier,
            competition: fixture.competition,
            title: fixture.title,
            startTime: fixture.startDate
        })
    }

    const handleTableDataSearch = (text: string) => {
        setRecordData(null);
        setSearchText(text);
    }

    const handleGoBack = () => {
        navigate(-1);
    }

    return (
        <AuditTrailPageInner
            pageTitle={"Audit Trails"}
            pageSubTitle={"Bookings"}
            pageHeader={"Audit Trails - Bookings"}
            menuStructure={menuStructure}
            backButtonAction={handleGoBack}
            isExternal={isExternal}>
            {
                recordData && fixtureId &&
                <div className={commonStyles.recordInfo} key="details">
                    <p className={commonStyles.recordInfoItem}><span>Property: </span>{recordData?.competition}</p>
                    <p className={commonStyles.recordInfoItem}>
                        <span>Technical Provider: </span>{recordData?.technicalProvider}</p>
                    <p className={commonStyles.recordInfoItem}><span>Title: </span>{recordData?.title}</p>
                    <p className={commonStyles.recordInfoItem}>
                        <span>Start Time: </span>
                        {recordData.startTime && DateTime.fromISO(recordData.startTime).toFormat("dd/MM/yyyy HH:mm:ss")}
                    </p>
                </div>
            }
            <div className={commonStyles.searchInput} key="inputs-container">
                <SearchByTextInput
                    value={searchText}
                    onChange={(e) => handleTableDataSearch(e)}
                />
            </div>
            {
                fixtureId
                    ?
                    <AuditTrailsTable
                        height="38vh"
                        type={true}
                        auditRecordType={RecordTypes.Fixture}
                        recordId={fixtureId}
                    />
                    :
                    <BookingsDetailsTable
                        handleFixtureSelection={handleFixtureSelection}
                        searchText={searchText}
                    />
            }
        </AuditTrailPageInner>
    )
})

export default AuditTrailsBookings;
