import {useEffect, useState} from "react";
import {useAppSelector} from "../../../../../../hooks/hooks";
import {RootState} from "../../../../../../store";
import {ITableRowData} from "../../BookFixtures/BookFixtures";

export const useFormatUpcomingTableData = () => {
    const initData = useAppSelector((state: RootState) => state.initInfo.source);
    const fixtures = useAppSelector((state: RootState) => state.rightHolderBooking.source);
    const [tableData, setTableData] = useState<ITableRowData[]>([]);
    useEffect(() => {
        const findRegionName = (item: any, field: string) => {
            if (initData.countries.items.find(x => x.id === item[field])) {
                return initData.countries.items.find(x => x.id === item[field])!.name;
            } else {
                return "";
            }
        }

        const tableData = fixtures.map(item => ({
            ...item,
            homeRegion: findRegionName(item, "homeRegionId"),
            awayRegion: findRegionName(item, "awayRegionId"),
            checked: false,
            ingestDetails: null
        }))
        setTableData(tableData);
    }, [initData, fixtures])
    return {tableData, setTableData}
}
