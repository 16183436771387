import React, {useState} from 'react';
import styles from './index.module.scss'
import {Tooltip} from "react-tooltip";
import ingestIcon from '../../../../../../img/ingestIcon.svg'
import report from '../../../../../../img/report.svg'
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/hooks";
import moment from "moment/moment";
import ReportForm from "../ReportForm/ReportForm";
import FaultForm from "../IssueForm/IssueForm";
import IngestForm from "../IngestForm/IngestForm";
import {
    changeFixtureDetail,
    getFixturesReport,
    setPageNumber,
    setSorting
} from "../../../../../../store/reportingFixtures/reportingFixturesSlice";
import uuid from "react-uuid";
import {useDisableScroll} from "../../../../../../hooks/useDisableScroll";
import AppTableWhite from "../../../../../../components/AppTableWhite/AppTableWhite";
import SortingHeader from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/children/SortingHeader/SortingHeader";
import {ISorting} from "../../../../TechnicalProviderView/UpcomingBookedFixtures/children/FixturesTable/FixturesTable";
import CustomCheckbox from "../../../../../../components/CustomCheckbox/CustomCheckbox";
import NoDataFoundStub from "../../../../../../components/NoDataFoundStub/NoDataFoundStub";
import {AppInfiniteScroll} from "../../../../../../components/AppInfiniteScroll/AppInfiniteScroll";
import {useDeepCompareEffect} from "../../../../../../hooks/useDeepCompareEffect";
import {toastr} from "react-redux-toastr";
import {createReportingPayload} from "@pages/Cms/IGameView/Settings/Reporting/helpers/createReportingPayload";
import {headerControls} from "@pages/Cms/IGameView/Settings/Reporting/ReportingTable/helpers/headerControls";
import {IReportFixture} from "@store/reportingFixtures/types";

interface IReportingTableProps {
    searchValue?: string;
    containerRef: React.RefObject<HTMLDivElement>;
}


const ReportingTable: React.FC<IReportingTableProps> = (
    {
        searchValue,
        containerRef
    }) => {
    const dispatch = useAppDispatch()
    const [reportFormState, setReportFormState] = useState({isShow: false, fixture: {} as IReportFixture});
    const [isShowFaultForm, setIsShowFaultForm] = useState(false);
    const [ingestFormState, setIngestFormState] = useState({isShow: false, fixtureId: "", rightHolderId: ""});
    const [updatingFixtureId, setUpdatingFixtureId] = useState<string | number>("");
    const {
        pageNumber,
        itemsPerPage,
        totalCount,
        fixturesReport,
        isLoading,
        maxItemsInTheTable,
        filters,
        sorting
    } = useAppSelector(state => state.reportingFixtures)


    useDeepCompareEffect(() => {
        if (containerRef && containerRef.current) {
            containerRef.current.scrollTop = 0
        }
    }, [filters]);

    useDisableScroll(reportFormState.isShow);
    const closeFaultForm = () => setIsShowFaultForm(false)
    const closeReportForm = () => setReportFormState({isShow: false, fixture: {} as IReportFixture})
    const openReportForm = () => setReportFormState({...reportFormState, isShow: true})
    const closeIngestForm = () => setIngestFormState({...ingestFormState, isShow: false})

    const onChangeDetail = (fixtureId: string, detail: "isVerified" | "setup", ingestType: number) => {
        const currentReport = fixturesReport.find(report => report.fixtureId === fixtureId)
        if (currentReport) {
            const updatedReport = {...currentReport, [detail]: !currentReport[detail]}
            const payload = {
                fixtureId: updatedReport.fixtureId,
                isVerified: updatedReport.isVerified,
                isSetup: updatedReport.setup,
                ingestType
            }
            dispatch(changeFixtureDetail(payload))
        }
    }

    const fetchTableData = async (page: number, pageSize: number, scrollPosition: string) => {
        const payload = createReportingPayload(searchValue, page, pageSize, sorting, filters, true, scrollPosition);

        if (containerRef && containerRef.current) {
            containerRef.current.scrollTop = 0
        }
        dispatch(getFixturesReport(payload))
    };

    const onSetSorting = (sorting: ISorting) => {
        if (!filters.startDate || !filters.endDate) return;
        const payload = createReportingPayload(searchValue, 1, itemsPerPage, sorting, filters, false);

        if (containerRef.current) {
            containerRef.current.scrollTop = 0;
        }
        dispatch(setSorting(sorting))
        dispatch(getFixturesReport(payload))
    }
    const setPage = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber))
    }

    const openIngestForm = (fixtureId: string, rightHolderId: string, ingestType: number) => {
        if (ingestType) {
            setIngestFormState({
                isShow: true,
                fixtureId,
                rightHolderId,
            })
        } else {
            return toastr.error("IGame", "Fixture is not ingested")
        }
    }
    return (
        <div>
            {ingestFormState.isShow &&
                <IngestForm
                    updatingFixtureId={updatingFixtureId}
                    rightHolderId={ingestFormState.rightHolderId}
                    fixtureId={ingestFormState.fixtureId}
                    closeIngestForm={closeIngestForm}

                />
            }
            {isShowFaultForm &&
                <FaultForm
                    setUpdatingReportId={setUpdatingFixtureId}
                    updatingReportId={updatingFixtureId}
                    openReportForm={openReportForm}
                    fixture={reportFormState.fixture}
                    closeForm={closeFaultForm}
                />
            }
            {reportFormState.isShow &&
                <ReportForm
                    setUpdatingReportId={setUpdatingFixtureId}
                    openFaultForm={() => setIsShowFaultForm(true)}
                    closeReportForm={closeReportForm}
                    fixtureId={reportFormState.fixture.fixtureId}
                />
            }
            {fixturesReport.length === 0 && <NoDataFoundStub
                message={"No records found matching the given filter criteria"}
                textColor="white"
            />}
            {fixturesReport.length !== 0 &&
                <AppInfiniteScroll
                    containerRef={containerRef}
                    scrollBounce={400}
                    scrollingContainerHeight="45vh"
                    isLoading={isLoading}
                    currentPage={pageNumber}
                    setPage={setPage}
                    fetchData={fetchTableData}
                    pageSize={itemsPerPage}
                    totalItems={totalCount}
                    maxItemsInTheTable={maxItemsInTheTable}
                    tableDataLength={fixturesReport.length}
                >
                    {
                        <AppTableWhite>
                            {fixturesReport.length > 0 &&
                                <SortingHeader
                                    sorting={sorting}
                                    setSorting={onSetSorting}
                                    controls={headerControls}
                                />}
                            <tbody>
                            {fixturesReport?.map(fixture => {
                                return (
                                    <tr key={uuid()}>
                                        <td>
                                            {moment(fixture.date).format("DD/MM/YYYY") + " " + moment(fixture.startUtc).format("HH:mm")}
                                        </td>
                                        <td>{moment(fixture.lineUp).format("HH:mm")}</td>
                                        <td>{fixture.sport}</td>
                                        <td>{fixture.competition}</td>
                                        <td>{fixture.fixture}</td>
                                        <td>
                                            <button
                                                id={"ingest"}
                                                className={styles.tableIcon}
                                                onClick={() =>
                                                    openIngestForm(
                                                        fixture.fixtureId,
                                                        fixture.rightHolderId,
                                                        fixture.ingestType
                                                    )
                                                }
                                            >
                                                <img
                                                    src={ingestIcon}
                                                    alt="logo"
                                                />
                                            </button>
                                            <button
                                                id={"doc"}
                                                className={styles.tableIcon}
                                                onClick={() => {
                                                    setReportFormState({isShow: true, fixture: fixture})
                                                }}
                                            >
                                                <img className={styles.reportIcon} src={report} alt="logo"/>
                                            </button>
                                            <Tooltip
                                                anchorSelect="#ingest"
                                                content="Ingest"
                                                className={styles.reportToolTip}
                                            />
                                            <Tooltip
                                                anchorSelect="#doc"
                                                content="Report"
                                                className={styles.reportToolTip}
                                            />
                                        </td>
                                        <td className={styles.textCentre}>
                                            <div
                                                className={styles.checkboxInner}
                                                onClick={() => onChangeDetail(fixture.fixtureId, "isVerified", fixture.ingestType)}>
                                                <CustomCheckbox
                                                    readOnly
                                                    id={fixture.fixtureId}
                                                    checked={!!fixture.isVerified}
                                                />
                                            </div>
                                        </td>
                                        <td className={styles.textCentre}>
                                            <div
                                                className={styles.checkboxInner}
                                                onClick={() => onChangeDetail(fixture.fixtureId, "setup", fixture.ingestType)}>
                                                <CustomCheckbox
                                                    readOnly
                                                    checked={!!fixture.setup}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </AppTableWhite>}
                </AppInfiniteScroll>}
        </div>
    );
};

ReportingTable.displayName = "ReportingTable"
export default ReportingTable;
