import {useEffect, useState} from "react";
import {IInitData} from "../../../../../../../store/generalIssues/generalIssuesSlice";
import {IDropdownOption} from "../../../../../../../components/AnalyticsDashboard/types";
import {formatSelectOptions} from "../../../../../../../helpers/formatSelectOptions";


export interface IFormOptions {
    faultsOptions: IDropdownOption[];
    affectedPlatformsOptions: IDropdownOption[];
    rightHoldersOptions: IDropdownOption[];
    contentSuppliersOptions: IDropdownOption[];
    protocolsOptions: IDropdownOption[];
    detailOptions: IDropdownOption[];
}

export const useGenerateDropdownOptions = (initData: IInitData) => {
    const {
        contentSuppliers,
        affectedPlatforms,
        faults,
        protocols,
        rightHolders,
        details
    } = initData;

    const [options, setOptions] = useState<IFormOptions>({
        faultsOptions: [],
        affectedPlatformsOptions: [],
        rightHoldersOptions: [],
        contentSuppliersOptions: [],
        protocolsOptions: [],
        detailOptions: []
    });

    useEffect(() => {
        setOptions({
            faultsOptions: formatSelectOptions(faults.items),
            affectedPlatformsOptions: formatSelectOptions(affectedPlatforms.items),
            rightHoldersOptions: formatSelectOptions(rightHolders.items),
            contentSuppliersOptions: formatSelectOptions(contentSuppliers.items),
            protocolsOptions: formatSelectOptions(protocols.items),
            detailOptions: formatSelectOptions(details?.items),
        });
    }, [faults, affectedPlatforms, rightHolders, contentSuppliers, protocols, details]);

    const {
        faultsOptions,
        affectedPlatformsOptions,
        rightHoldersOptions,
        contentSuppliersOptions,
        protocolsOptions,
        detailOptions
    } = options;

    return {
        faultsOptions,
        affectedPlatformsOptions,
        rightHoldersOptions,
        contentSuppliersOptions,
        protocolsOptions,
        detailOptions
    };
};
