import {
    IStepFourFormData,
    IStepOneFormData,
    IStepThreeFormData,
    IStepTwoFormData
} from "../UserManagementHooks/useRightHolderFormData";
import {IRHPayload} from "@store/userManagement/userManagementSlice";

export const createNewRHPayload = (
    stepOneFD: IStepOneFormData,
    stepTwoFD: IStepTwoFormData,
    stepThreeFD: IStepThreeFormData,
    stepFourFD: IStepFourFormData,
): IRHPayload => {

    const rhPayload: IRHPayload = {
        name: stepOneFD.name,
        dataProviderId: 0,
        adminEmail: stepOneFD.adminEmail,
        commercialEmail: stepOneFD.commercialEmail,
        schedulingEmail: stepOneFD.schedulingEmail,
        liveIssuesContactEmail: stepOneFD.liveIssuesContactEmail,
        rolePages: stepTwoFD.rolePages,
        contractStartDate: stepThreeFD.contractStartDate ? stepThreeFD.contractStartDate : ''
    }

    if (stepOneFD.dataProviderId) {
        rhPayload.dataProviderId = +stepOneFD.dataProviderId.value;
    }

    if (stepOneFD.address?.length) {
        rhPayload.address = stepOneFD.address;
    }

    if (stepOneFD.clientAdmin?.length) {
        rhPayload.clientAdmin = stepOneFD.clientAdmin;
    }

    if (stepOneFD.adminEmail?.length) {
        rhPayload.adminEmail = stepOneFD.adminEmail;
    }

    if (stepOneFD.commercialContact?.length) {
        rhPayload.commercialContact = stepOneFD.commercialContact;
    }

    if (stepOneFD.schedulingContact?.length) {
        rhPayload.schedulingContact = stepOneFD.schedulingContact;
    }

    if (stepOneFD.liveIssuesContact?.length) {
        rhPayload.liveIssuesContact = stepOneFD.liveIssuesContact;
    }

    if (stepTwoFD.pricing) {
        rhPayload.pricing = stepTwoFD.pricing;
    }

    if (stepTwoFD.pricing) {
        rhPayload.pricing = stepTwoFD.pricing;
    }

    if (stepThreeFD.allowancePerOperator) {
        rhPayload.allowancePerOperator = stepThreeFD.allowancePerOperator;
    }

    if (stepThreeFD.overageRatePerGB) {
        rhPayload.overageRatePerGB = stepThreeFD.overageRatePerGB;
    }

    if (stepThreeFD.playerRatePerView) {
        rhPayload.playerRatePerView = stepThreeFD.playerRatePerView;
    }

    if (stepThreeFD.operatorRatePerMonth) {
        rhPayload.operatorRatePerMonth = stepThreeFD.operatorRatePerMonth;
    }

    if (stepThreeFD.ingestRatePerHour) {
        rhPayload.ingestRatePerHour = stepThreeFD.ingestRatePerHour;
    }

    if (stepFourFD.logo) {
        rhPayload.logo = stepFourFD.logo;
    }

    return rhPayload
}
