import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import SelectInput from "../../../../../components/SelectInput/SelectInput";
import Input from "../../../../../components/Input/Input";
import styles from './styles.module.scss'
import {useAppDispatch, useAppSelector} from "@hooks/hooks";
import {MultiValue, SingleValue} from "react-select";
import {formatSelectOptions} from "@helpers/formatSelectOptions";
import {
    clearCompetitionOptions,
    createAssignment, getRightsWithTechProviders, IRightAssignment,
    IRightsWithTechProvidersPayload,
    updateUnconfirmedAssignment
} from "@store/rightAssignment/rightAssignmentSlice";
import LogoUploader from "../../../../../components/LogoUploader/LogoUploader";
import UploadedLogoViewer from "../../../../../components/UploadedLogoViewer/UploadedLogoViewer";
import DatePicker from "../../../../../components/DatePicker/DatePicker";
import {IDate} from "../../../RightsHolderView/ScheduleIngest/BookFixtures/children/AddFixtureForm/AddFixtureForm";
import {
    formatDate
} from "../../../RightsHolderView/ScheduleIngest/BookFixtures/children/SatelliteForm/helpers/formatDate";
import {formatRangeDate, getPickerDateFormat} from "../../../../../helpers/dateFormatters";
import {validateDates} from "../helpers/validateDates";
import {ISelectOption} from "@store/operatorBookedContent/operatorBookedContentSlice";

export interface IFormInput {
    rightHolder: SingleValue<ISelectOption>
    sports: SingleValue<ISelectOption>
    rights: SingleValue<ISelectOption>
    dataSource: SingleValue<ISelectOption>
    providers: MultiValue<ISelectOption>,
    onePlatformPropertyName?: string
    fixtures: number | string
    providerEmail: string
    startDate: IDate;
    endDate: IDate;
}

interface ICompetitionFormProps {
    closeForm: () => void
    updatingAssignment: number
    setUpdatingAssignment: Dispatch<SetStateAction<number>>
}

const RightAssignmentForm: React.FC<ICompetitionFormProps> = (
    {
        closeForm,
        updatingAssignment,
        setUpdatingAssignment
    }) => {
    const dispatch = useAppDispatch()
    const {
        initOptions: {competitions, rightHolders, competitionDataSources, sports},
        unconfirmedAssignments,
        isThereRightsBySource
    } = useAppSelector(state => state.rightAssignment)
    const {handleSubmit, control, setValue, formState: {errors}, setError} = useForm<IFormInput>();
    const [rightHolder, setRightHolder] = useState<string | null>("");
    const [dataSource, setDataSource] = useState<string | null>("");
    const [competition, setCompetition] = useState<string | null>("");
    const [sport, setSport] = useState<string | null>("");
    const [logo, setLogo] = useState("");
    const [editingLogo, setEditingLogo] = useState("");
    const [startTime, setStartTime] = useState<IDate | null>(null);
    const [endTime, setEndTime] = useState<IDate | null>(null);

    useEffect(() => {
        if (updatingAssignment) {
            const assignment = unconfirmedAssignments.find(({id}) => id === updatingAssignment);
            if (assignment) {
                updateAssignmentData(assignment);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatingAssignment]);

    const updateAssignmentData = (assignment: IRightAssignment) => {
        setEditingLogo(assignment.logo);
        handleRightHolderAndDataSource(assignment);
        handleRightsWithTechProviders(assignment);
        setDates(assignment);
        setCompetition(assignment.competitionId);
        setValue("fixtures", assignment.availableFixtures);
    };

    const handleRightHolderAndDataSource = (assignment: IRightAssignment) => {
        const rightHolder = findOptionById(formatSelectOptions(rightHolders), assignment.rightHolderId);
        const dataSource = findOptionByLabel(formatSelectOptions(competitionDataSources), assignment.dataSource);

        if (rightHolder) {
            setValue("rightHolder", rightHolder);
            setRightHolder(rightHolder.value);
        }
        if (dataSource) {
            setValue("dataSource", dataSource);
        }
    };

    const handleRightsWithTechProviders = (assignment: IRightAssignment) => {
        if (assignment.dataSource && assignment.rightHolderId) {
            const rightsOptionsPayload: IRightsWithTechProvidersPayload = {
                rightHolderId: assignment.rightHolderId,
                sportId: assignment.sportId,
                competitionDataSource: assignment.dataSource,
                competitionId: assignment.competitionId,
            };

            dispatch(getRightsWithTechProviders(rightsOptionsPayload)).then((data) => {
                // @ts-ignore
                const right = findOptionById(formatSelectOptions(data.payload.items), assignment.competitionId);
                if (right) {
                    setValue("rights", right);
                }
            });
        }
    };

    const setDates = (assignment: IRightAssignment) => {
        if (assignment.startDate) {
            setStartTime(getPickerDateFormat(assignment.startDate));
        }
        if (assignment.endDate) {
            setEndTime(getPickerDateFormat(assignment.endDate));
        }
    };

    const findOptionById = (options: ISelectOption[], id: string | null) => options.find(option => option.value === id);
    const findOptionByLabel = (options: ISelectOption[], label: string) => options.find(option => option.label === label);


    const onCreateAssignment = (assignment: IFormInput) => {
        if (validateDates(assignment, setError)) {

            if (!isThereRightsBySource) {
                return
            }
            const formData = new FormData();

            formData.append('rightHolderId', assignment.rightHolder!.value);
            formData.append('availableFixtures', assignment.fixtures.toString());
            formData.append('competitionId', assignment.rights!.value);
            formData.append('startDate', formatRangeDate(assignment.startDate));
            formData.append('endDate', formatRangeDate(assignment.endDate));
            if (assignment.onePlatformPropertyName) {
                formData.append('onePlatformPropertyName', assignment.onePlatformPropertyName);
            }
            if (logo) {
                formData.append('logo', logo);
            }
            const body = {
                payload: formData,
                closeForm
            }
            dispatch(createAssignment(body))
        }
    }

    const onUpdateAssignment = (assignment: IFormInput) => {
        if (validateDates(assignment, setError)) {

            const formData = new FormData();
            formData.append('id', updatingAssignment.toString());
            formData.append('rightHolderId', assignment.rightHolder!.value);
            formData.append('availableFixtures', assignment.fixtures.toString());
            formData.append('competitionId', competition!);
            formData.append('startDate', formatDate(assignment.startDate));
            formData.append('endDate', formatDate(assignment.endDate));
            if (assignment.onePlatformPropertyName) {
                formData.append('onePlatformPropertyName', assignment.onePlatformPropertyName);
            }
            if (logo) {
                formData.append('logo', logo);
            }
            dispatch(updateUnconfirmedAssignment(formData))
            setUpdatingAssignment(0)
            closeForm()
        }
    }
    const onCloseForm = () => {
        setUpdatingAssignment(0)
        closeForm()
        dispatch(clearCompetitionOptions([]))
    }

    const selectRightHolderHandler = (e: SingleValue<{ label: string, value: string }>) => {
        setRightHolder(e?.value!)
        const rightsOptionsPayload: IRightsWithTechProvidersPayload = {
            rightHolderId: e?.value!,
            sportId: sport!,
            competitionDataSource: dataSource!
        }

        if (dataSource && sport) {
            dispatch(getRightsWithTechProviders(rightsOptionsPayload))
        }
    }

    const selectDataSourceHandler = (e: SingleValue<{ label: string, value: string }>) => {
        setDataSource(e!.label)
        const rightsOptionsPayload: IRightsWithTechProvidersPayload = {
            rightHolderId: rightHolder!,
            sportId: sport!,
            competitionDataSource: e?.label!
        }

        setValue("rights", null)

        if (sport) {
            dispatch(getRightsWithTechProviders(rightsOptionsPayload))
        }
    }

    const selectSportHandler = (e: SingleValue<{ label: string, value: string }>) => {
        setSport(e?.value!)
        const rightsOptionsPayload: IRightsWithTechProvidersPayload = {
            rightHolderId: rightHolder!,
            sportId: e?.value!,
            competitionDataSource: dataSource!
        }

        dispatch(getRightsWithTechProviders(rightsOptionsPayload))
    }

    return (
        <div className={styles.overlay}>
            <form
                onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                onClick={(e) => e.stopPropagation()}
                onSubmit={updatingAssignment ? handleSubmit(onUpdateAssignment) : handleSubmit(onCreateAssignment)}
                className={styles.form}>
                <h6 className={styles.title}>{updatingAssignment ? 'Editing' : 'New'} Assignment</h6>
                <div className={styles.content}>
                    <div className={styles.formContent}>
                        <div className={styles.dateInputSection}>
                            <div className={styles.calendar}>Start Date
                                <div className={styles.calendarInputWrapper}>
                                    <Controller
                                        control={control}
                                        name="startDate"
                                        rules={{required: true}}
                                        render={({
                                                     field: {onChange},
                                                 }) => (
                                            <DatePicker
                                                onChange={date => {
                                                    onChange(date)
                                                }}
                                                withTime={false}
                                                initValue={startTime}
                                                modalPositioningClass="calendar-modal-down"
                                            />
                                        )}
                                    />
                                </div>
                                {
                                    errors.startDate && errors.startDate.type === "required"
                                    && <span className={styles.formErrorBlock}>Start Date is required</span>
                                }
                                {
                                    errors.startDate && errors.startDate.type === "date"
                                    && <span className={styles.formErrorBlock}>{errors.startDate.message}</span>
                                }
                            </div>
                            <div className={styles.calendar}>End Date
                                <div className={styles.calendarInputWrapper}>
                                    <Controller
                                        control={control}
                                        name="endDate"
                                        rules={{required: true}}
                                        render={({
                                                     field: {onChange},
                                                 }) => (
                                            <DatePicker
                                                onChange={date => {
                                                    onChange(date)
                                                }}
                                                initValue={endTime}
                                                withTime={false}
                                                modalPositioningClass="calendar-modal-down"
                                            />
                                        )}
                                    />
                                </div>
                                {
                                    errors.endDate && errors.endDate.type === "required"
                                    && <span className={styles.formErrorBlock} data-testid={"endDateRequiredError"}>
                                        End date is required
                                    </span>
                                }
                                {
                                    errors.endDate?.type === "date"
                                    && <span
                                        className={styles.formErrorBlock}>{"End date can't be earlier than start date"}
                                    </span>
                                }
                            </div>
                        </div>
                        <div className={styles.inputInner}>
                            <label className={styles.label}>Right Holder
                                <Controller
                                    name="rightHolder"
                                    defaultValue={null}
                                    control={control}
                                    rules={{required: true}}
                                    render={({field: {onChange, value}}) =>
                                        <SelectInput
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e)
                                                selectRightHolderHandler(e)
                                            }}
                                            options={formatSelectOptions(rightHolders)}
                                            placeholder={"Choose an option"}
                                        />}
                                />
                                {errors.rightHolder &&
                                    <div className={styles.formErrorBlock}>Right Holder is required field</div>}
                            </label>
                        </div>
                        {rightHolder &&
                            <div className={styles.inputInner}>
                                <label className={styles.label}>Data Source
                                    <Controller
                                        name="dataSource"
                                        defaultValue={null}
                                        control={control}
                                        rules={{required: true}}
                                        render={({field: {onChange, value}}) =>
                                            <SelectInput
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e)
                                                    selectDataSourceHandler(e)
                                                }}
                                                options={formatSelectOptions(competitionDataSources)}
                                                placeholder={"Choose an option"}
                                            />}
                                    />
                                    {errors.dataSource &&
                                        <div className={styles.formErrorBlock}>Data Source is required field</div>
                                    }
                                    {!isThereRightsBySource && !updatingAssignment &&
                                        <div className={styles.formErrorBlock}>
                                            There are no properties by current data source and right holder
                                        </div>
                                    }
                                </label>
                            </div>
                        }
                        {rightHolder && dataSource &&
                            <div className={styles.inputInner}>
                                <label className={styles.label}>Sport
                                    <Controller
                                        name="sports"
                                        defaultValue={null}
                                        control={control}
                                        rules={{required: true}}
                                        render={({field: {onChange, value}}) =>
                                            <SelectInput
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e)
                                                    selectSportHandler(e)
                                                }}
                                                options={formatSelectOptions(sports)}
                                                placeholder={"Choose an option"}
                                            />}
                                    />
                                    {errors.rights &&
                                        <div className={styles.formErrorBlock}>Sport is required field</div>}
                                </label>
                            </div>
                        }
                        {competitions.length > 0 && sport &&
                            <div className={styles.inputInner}>
                                <label className={styles.label}>Rights
                                    <Controller
                                        name="rights"
                                        defaultValue={null}
                                        control={control}
                                        rules={{required: true}}
                                        render={({field: {onChange, value: operator}}) => <SelectInput
                                            value={operator}
                                            onChange={(e) => {
                                                onChange(e)
                                                setCompetition(e!.value)
                                            }}
                                            options={formatSelectOptions(competitions)}
                                            placeholder={"Choose an option"}
                                        />}
                                    />
                                    {errors.rights &&
                                        <div className={styles.formErrorBlock}>Right is required field</div>}
                                </label>
                            </div>
                        }
                        {(rightHolder && competition) || updatingAssignment ?
                            <div>
                                <div className={styles.inputInner}>
                                    <label className={styles.label}>ONEPlatform Property Name
                                        <Controller
                                            control={control}
                                            defaultValue={""}
                                            name="onePlatformPropertyName"
                                            render={({field: {onChange, value}}) => (
                                                <Input
                                                    onWheel={(e) => e.currentTarget.blur()}
                                                    onChange={(e) => {
                                                        onChange(e)
                                                    }}
                                                    value={value}
                                                    type="text"
                                                    placeholder={"Enter Property Name"}
                                                />
                                            )}
                                        />
                                    </label>
                                </div>
                            </div>
                            :
                            null
                        }
                        {(rightHolder && competition) || updatingAssignment ? <div>
                                <div className={styles.inputInner}>
                                    <label className={styles.label}>Available Fixtures
                                        <Controller
                                            control={control}
                                            defaultValue={""}
                                            name="fixtures"
                                            rules={{
                                                max: 50000,
                                                required: true,
                                                validate: value => Number(value) > 0 || Number(value) === 0
                                            }}
                                            render={({field: {onChange, value}}) => (
                                                <Input
                                                    onWheel={(e) => e.currentTarget.blur()}
                                                    onChange={(e) => {
                                                        onChange(e)
                                                    }}
                                                    value={value}
                                                    type="number"
                                                    placeholder={"Number of fixtures"}
                                                />
                                            )}
                                        />
                                        {errors?.fixtures?.type === "required" &&
                                            <div className={styles.formErrorBlock}>
                                                Number of fixtures is required field
                                            </div>
                                        }
                                        {errors?.fixtures?.type === "validate" &&
                                            <div className={styles.formErrorBlock}>
                                                Number of fixtures should be positive
                                            </div>
                                        }
                                        {errors?.fixtures?.type === "max" &&
                                            <div className={styles.formErrorBlock}>
                                                Number of fixtures could not be bigger than 50000
                                            </div>
                                        }
                                    </label>
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                    <LogoUploader setLogo={setLogo}/>
                    {<UploadedLogoViewer
                        isEditMode={!!editingLogo}
                        logo={editingLogo}
                    />
                    }
                </div>
                <div className={styles.buttons}>
                    <button
                        onClick={onCloseForm}
                        className={styles.cancelButton}>Cancel
                    </button>
                    <button
                        className={styles.submitButton}
                        type="submit">
                        {updatingAssignment ? "Update" : "Create"}
                    </button>
                </div>
            </form>
        </div>
    );
};

RightAssignmentForm.displayName = "RightAssignmentForm"
export default RightAssignmentForm;
