import React from 'react';
import styles from './index.module.scss';
import {MdLogout} from "react-icons/md";
import {useAppDispatch} from "../../../../../hooks/hooks";
import {logout} from "../../../../../store/auth/authSlice";
import {clearCartState} from "../../../../../store/rightHolderBooking/rightHolderBookingSlice";

const DropDownMenu: React.FC = () => {
    const dispatch = useAppDispatch();
    const logoutHandler = () => {
        localStorage.removeItem("book-token");
        localStorage.removeItem("book-refresh");
        localStorage.removeItem("client");
        localStorage.removeItem("lastVisitedPage");
        localStorage.removeItem("currentUserRole");
        dispatch(clearCartState());

        window.location.href = '/';
        dispatch(logout(null));
    }

    return (
        <div className={styles.dropdownMenu}>
            <div
                onClick={logoutHandler}
                className={styles.logoutIcon}
            >
                <MdLogout/>
            </div>
            <p onClick={logoutHandler} className={styles.link}>
                Logout
            </p>
        </div>
    );
};

export default DropDownMenu;