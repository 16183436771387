import React, {useEffect, useRef} from "react";

function deepCompareEquals(a: any, b: any): boolean {
    return JSON.stringify(a) === JSON.stringify(b);
}

export const useDeepCompareEffect = (
    callback: React.EffectCallback,
    dependencies: any[]
) => {
    const currentDependenciesRef = useRef<any[]>();

    if (!deepCompareEquals(currentDependenciesRef.current, dependencies)) {
        currentDependenciesRef.current = dependencies;
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(callback, [currentDependenciesRef.current]);
};
