import React from 'react';
import {Chart} from "react-google-charts";
import styles from './index.module.scss'

interface IFixturesChart {
    chartData: (string | { role: string; })[][]
    isStacked: boolean
    barColor: string[]
}

const FixturesChart: React.FC<IFixturesChart> = ({chartData, isStacked, barColor}) => {

    const options = {
        backgroundColor: "transparent",
        isStacked,
        hAxis: {
            textStyle: {
                color: '#FFF',
                fontSize: 12,
            },
            gridlines: {color: '#FFFFFF'},
        },
        vAxis: {
            textStyle: {color: '#FFFFFF', fontSize: 12},
            baselineColor: "#FFFFFF",
            gridlines: {color: '#b2b0b0', count: 5},
            minorGridlines: {color: 'transparent'},
        },
        legend: {
            position: 'none',
        },
        colors: barColor,
        bar: {groupWidth: '10px'},
    };

    return (
        <div className={styles.chartContainer}>
            <Chart
                legendToggle={false}
                chartType="ColumnChart"
                width="105%"
                height="700px"
                data={chartData}
                options={options}
            />
        </div>
    );
};

export default FixturesChart;
