import {AnyAction, createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {toastr} from "react-redux-toastr";
import {client} from "../../services/api-servise";
import {AxiosError} from "axios";
import {IInputOption} from "../operatorAccess/operatorAccessSlice";


export interface IGetPortfolioPayload {
    operatorId: string | null,
    rightHoldersIds: string[],
    sportsIds: string[],
    countriesIds: string[],
    sorting: {
        property: string,
        isAscending: boolean
    }
}

export interface IRightHoldersPayload {
    operatorId: string | null;
    rightHoldersIds: string[];
    sportsIds: string[];
    countriesIds: string[];
}

export interface IRightHolderResponse {
    id: string;
    name: string;
    logo: string;
}

export interface IRightHolderPortfolio {
    rightId: string;
    sport: string;
    rightHolder: string;
    property: string;
    propertyId: string;
    isPropertyFavourite: boolean;
    geoBlocking: string;
    numberOfMatches: number;
    rightStartDate: string;
    rightEndDate: string;
    rightState: string;
    isInCart: boolean;
}

export interface IFiltersResponse {
    rightHolders: {
        items: IInputOption[]
    };
    sports: {
        items: IInputOption[]
    };
    countries: {
        items: IInputOption[]
    };
}

export interface IDropdownOptions {
    rightHolders: IInputOption[];
    sports: IInputOption[];
    countries: IInputOption[];
}

export interface ISelectOption {
    value: string,
    label: string
}

export interface IFiltersState {
    rightHolders: ISelectOption[];
    sports: ISelectOption[];
    countries: ISelectOption[];
}

export interface IRightsOverviewState {
    rightsHolders: IRightHolderResponse[];
    rightsOverviewPortfolio: IRightHolderPortfolio[];
    filters: IFiltersState;
    dropdownOptions: IDropdownOptions;
    loadingStatus: "idle" | "loading" | "failed";
    error: string;
    rightHolderId: string;
}

export interface ISetFiltersPayload {
    value: ISelectOption[];
    name: keyof IRightsOverviewState["filters"];
}

const initialState: IRightsOverviewState = {
    rightsHolders: [],
    rightsOverviewPortfolio: [],
    filters: {
        rightHolders: [],
        sports: [],
        countries: [],
    },
    dropdownOptions: {
        rightHolders: [],
        sports: [],
        countries: [],
    },
    loadingStatus: "idle",
    error: "",
    rightHolderId: ''
}

export const getRightHolders = createAsyncThunk<IRightHolderResponse[], IRightHoldersPayload, { rejectValue: string }>(
    "operator-rights-overview/get-rightHolders",
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.post("api/RightsOverview/right-holders", payload);
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error("IGame", `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getFiltersOptions = createAsyncThunk<IFiltersResponse, IRightHoldersPayload, { rejectValue: string }>(
    "operator-rights-overview/filter-items",
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.post("api/RightsOverview/filter-items", payload);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error("IGame", `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getPortfolio = createAsyncThunk<IRightHolderPortfolio[], IGetPortfolioPayload, { rejectValue: string }>(
    'operator-rights-overview/fetch-right-holders',
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.post("api/RightsOverview/portfolio", payload);
            return data.items;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const operatorRightsOverviewSlice = createSlice({
    name: 'operator-rights-overview',
    initialState,
    reducers: {
        setFilters: (state, action: PayloadAction<ISetFiltersPayload>) => {
            state.filters[action.payload.name] = action.payload.value;
        },
        resetFilters: (state) => {
            state.filters.rightHolders = [];
            state.filters.sports = [];
            state.filters.countries = [];
        },
        setRightHolderId: (state, action: PayloadAction<string>) => {
            state.rightHolderId = action.payload;
        },
        changeFavStarStatus: (state, action: PayloadAction<{ id: string; isAdd: boolean }>) => {
            state.rightsOverviewPortfolio.map((item) => {
                if (item.rightId === action.payload.id) {
                    item.isPropertyFavourite = action.payload.isAdd;
                }
                return item;
            })
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(getRightHolders.pending, (state) => {
                state.error = "";
                state.loadingStatus = "loading";
            })
            .addCase(getRightHolders.fulfilled, (state, action: PayloadAction<IRightHolderResponse[]>) => {
                state.loadingStatus = "idle";
                state.rightsHolders = action.payload;
            })
            .addCase(getRightHolders.rejected, (state) => {
                state.loadingStatus = "failed";
            })
            .addCase(getFiltersOptions.fulfilled, (state, action) => {
                state.dropdownOptions.rightHolders = action.payload.rightHolders.items;
                state.dropdownOptions.sports = action.payload.sports.items;
                state.dropdownOptions.countries = action.payload.countries.items;
            })
            .addCase(getPortfolio.fulfilled, (state, action) => {
                state.rightsOverviewPortfolio = action.payload;
            })
            .addMatcher(isError, (state, action: PayloadAction<string>) => {
                state.error = action.payload;
            })
    },
})

export const {
    setFilters,
    resetFilters,
    setRightHolderId,
    changeFavStarStatus,
} = operatorRightsOverviewSlice.actions
export default operatorRightsOverviewSlice.reducer

function isError(action: AnyAction) {
    return action.type.endsWith("rejected")
}
