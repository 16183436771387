import React from 'react';
import styles from "../index.module.scss";
import Checkbox from "../../../../../../../../../components/Checkbox";
import {isCheckedPages} from "../../../../helpers/isCheckedPages";
import {findRoleId, formatPageName} from "../../helpers";
import {Control, Controller, FieldErrors} from "react-hook-form";
import {isRequiredSelect} from "../helpers";
import MultiSelect from "../../../../../../../../../components/MultiSelect/MultiSelect";
import {ISelectItem, IUserRole} from "../../../../../../../../../store/userManagement/userManagementSlice";
import {IStepTwoFormData} from "../../../../UserManagementHooks/useOperatorFormData";

interface IStepTwoFormProps {
    control: Control,
    errors: FieldErrors,
    operatorVisibilities: ISelectItem[],
    stepTwoFormData: IStepTwoFormData,
    roles: IUserRole[],
    checkboxHandler: (checkboxId: number, roleId: string) => void,
    rightHoldersInit: ISelectItem[],
    handleSelectData: (selectName: string, onChange: any) => (selectedData: ISelectItem[]) => void,
    selectAllPages: (pageVisibilities: ISelectItem[], roleId: string) => void,
    deselectAllPages: (roleId: string) => void
}

const StepTwoForm: React.FC<IStepTwoFormProps> = React.memo(({
                                                                 control,
                                                                 errors,
                                                                 operatorVisibilities,
                                                                 stepTwoFormData,
                                                                 roles,
                                                                 checkboxHandler,
                                                                 rightHoldersInit,
                                                                 handleSelectData,
                                                                 selectAllPages,
                                                                 deselectAllPages
                                                             }) => {
    return (
        <div className={styles.formContent}>

            <div className={styles.label}>Page Visibility for Super Admin
                <div>
                    <div className={styles.checkboxes}>
                        {operatorVisibilities.map(page => (
                            <div key={page.id}>
                                <Checkbox
                                    isChecked={isCheckedPages(findRoleId("SuperAdmin", roles), +page.id, stepTwoFormData.rolePages)}
                                    checkboxId={`superAdmin-${page.id}`}
                                    onChangeHandler={() => checkboxHandler(+page.id, findRoleId("SuperAdmin", roles),)}
                                    filled={true}
                                />
                                <span>{formatPageName("Operator", page.name)}</span>
                            </div>
                        ))}
                        <div className={styles.quickSelectionButtons}>
                            <button
                                onClick={() => selectAllPages(operatorVisibilities, findRoleId("SuperAdmin", roles))}
                                className={styles.quickSelectionButton}
                                type="button"
                                data-testid={`superAdmin-select-all`}
                            >Select All
                            </button>
                            <button
                                onClick={() => deselectAllPages(findRoleId("SuperAdmin", roles))}
                                className={styles.quickSelectionButton}
                                type="button"
                                data-testid={`superAdmin-deselect-all`}
                            >Deselect All
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.label}>Page Visibility for Admin
                <div>
                    <div className={styles.checkboxes}>
                        {operatorVisibilities.map(page => (
                            <div key={page.id}>
                                <Checkbox
                                    isChecked={isCheckedPages(findRoleId("Admin", roles), +page.id, stepTwoFormData.rolePages)}
                                    checkboxId={`admin-${page.id}`}
                                    onChangeHandler={() => checkboxHandler(+page.id, findRoleId("Admin", roles),)}
                                    filled={true}
                                />
                                <span>{formatPageName("Operator", page.name)}</span>
                            </div>
                        ))}
                        <div className={styles.quickSelectionButtons}>
                            <button
                                onClick={() => selectAllPages(operatorVisibilities, findRoleId("Admin", roles))}
                                className={styles.quickSelectionButton}
                                type="button"
                                data-testid={`admin-select-all`}
                            >Select All
                            </button>
                            <button
                                onClick={() => deselectAllPages(findRoleId("Admin", roles))}
                                className={styles.quickSelectionButton}
                                type="button"
                                data-testid={`admin-deselect-all`}
                            >Deselect All
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.label}>Page Visibility for Finance
                <div>
                    <div className={styles.checkboxes}>
                        {operatorVisibilities.map(page => (
                            <div key={page.id}>
                                <Checkbox
                                    isChecked={isCheckedPages(findRoleId("Finance", roles), +page.id, stepTwoFormData.rolePages)}
                                    checkboxId={`finance-${page.id}`}
                                    onChangeHandler={() => checkboxHandler(+page.id, findRoleId("Finance", roles),)}
                                    filled={true}
                                />
                                <span>{formatPageName("Operator", page.name)}</span>
                            </div>
                        ))}
                        <div className={styles.quickSelectionButtons}>
                            <button
                                onClick={() => selectAllPages(operatorVisibilities, findRoleId("Finance", roles))}
                                className={styles.quickSelectionButton}
                                type="button"
                                data-testid={`finance-select-all`}
                            >Select All
                            </button>
                            <button
                                onClick={() => deselectAllPages(findRoleId("Finance", roles))}
                                className={styles.quickSelectionButton}
                                type="button"
                                data-testid={`finance-deselect-all`}
                            >Deselect All
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.label}>Page Visibility for User
                <div>
                    <div className={styles.checkboxes}>
                        {operatorVisibilities.map(page => (
                            <div key={page.id}>
                                <Checkbox
                                    isChecked={isCheckedPages(findRoleId("User", roles), +page.id, stepTwoFormData.rolePages)}
                                    checkboxId={`user-${page.id}`}
                                    onChangeHandler={() => checkboxHandler(+page.id, findRoleId("User", roles),)}
                                    filled={true}
                                />
                                <span>{formatPageName("Operator", page.name)}</span>
                            </div>
                        ))}
                        <div className={styles.quickSelectionButtons}>
                            <button
                                onClick={() => selectAllPages(operatorVisibilities, findRoleId("User", roles))}
                                className={styles.quickSelectionButton}
                                type="button"
                                data-testid={`user-select-all`}
                            >Select All
                            </button>
                            <button
                                onClick={() => deselectAllPages(findRoleId("User", roles))}
                                className={styles.quickSelectionButton}
                                type="button"
                                data-testid={`user-deselect-all`}
                            >Deselect All
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.selectContainer}>
                <p className={styles.selectTitle}>Focused Rights Holder <span>*</span></p>
                <label>
                    <Controller
                        control={control}
                        name="rightHolders"
                        rules={{
                            validate: {
                                isRequiredSelect: (e) => isRequiredSelect(e)
                            }
                        }}
                        render={({field: {onChange}}) => (
                            <MultiSelect
                                options={rightHoldersInit}
                                selectedValues={stepTwoFormData.rightHolders}
                                onSelect={handleSelectData('rightHolders', onChange)}
                                onRemove={handleSelectData('rightHolders', onChange)}
                            />
                        )}
                    />
                    {errors?.rightHolders?.type === "isRequiredSelect" &&
                        <p className={styles.formErrorBlock}>Required Field</p>}
                </label>
            </div>
        </div>
    );
});

export default StepTwoForm;
