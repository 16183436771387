import {useEffect, useState} from "react";
import {DeliveryMethod} from "../../../../../../enums/deliveryMethod";
import {UseFormWatch} from "react-hook-form";
import {IFormInputs} from "../children/IngestResourcesForm/IngestResourcesForm";

export const useSetRequiredField = (watch: UseFormWatch<IFormInputs>) => {
    const [isPortRequired, setIsPortRequired] = useState(false);
    const [isStreamRequired, setIsStreamRequired] = useState(false);

    const deliveryMethod = watch("deliveryMethod", {value: "", label: ""});

    useEffect(() => {
        setIsPortRequired(deliveryMethod?.value === DeliveryMethod.SRTCaller || deliveryMethod?.value === DeliveryMethod.SRTListener);
        setIsStreamRequired(deliveryMethod?.value === DeliveryMethod.RTMPPush || deliveryMethod?.value === DeliveryMethod.RTMPPull);
    }, [deliveryMethod]);

    return {isPortRequired, isStreamRequired};
}
