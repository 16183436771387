import React, {useState} from 'react';
import styles from "./index.module.scss";
import {FaChevronDown} from "react-icons/fa";
import {IOperatorBilling} from "../../../../../../../store/iGameOperatorBilling/iGameOperatorBillingSlice";


export interface IBillingAccordionProps {
    billingData: IOperatorBilling;
    isExpandedAccordion: boolean;
}


const BillingAccordion: React.FC<IBillingAccordionProps> = ({billingData, isExpandedAccordion}) => {
    const {
        provider,
        numberOfBookings,
        numberOfStreams,
        numberOfAdHocBookings,
        total,
        providerBillingItems
    } = billingData;
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <React.Fragment key={provider}>
            <tr onClick={() => setIsExpanded(!isExpanded)}>
                <td className={styles.accordionCell}>
                    <div
                        data-testid={"chevron-icon"}
                        className={!isExpanded ? styles.tableChevron : `${styles.tableChevron} ${styles.tableChevronTop}`}>
                        <FaChevronDown size={14}/>
                    </div>
                    <p className={styles.providerCell}>{provider}</p>
                </td>
                <td></td>
                <td></td>
                <td>{numberOfBookings}</td>
                <td>{numberOfStreams}</td>
                <td>{numberOfAdHocBookings}</td>
                <td>{total}</td>
            </tr>

            {isExpanded || isExpandedAccordion ?
                <>
                    {providerBillingItems.map(item => (
                        <React.Fragment key={item.competition}>
                            <tr className={styles.expandedRow}>
                                <td></td>
                                <td>{item.competition}</td>
                                <td></td>
                                <td>{item.numberOfBookings}</td>
                                <td>{item.numberOfStreams}</td>
                                <td>{item.numberOfAdHocBookings}</td>
                                <td>{Math.round(item.total * 100) / 100}</td>
                            </tr>

                            {item.competitionBillingItems.map(subItem => (
                                <tr key={item.competition}>
                                    <td></td>
                                    <td></td>
                                    <td>{subItem.title}</td>
                                    <td>{subItem.numberOfBookings}</td>
                                    <td>{subItem.numberOfStreams}</td>
                                    <td>{subItem.numberOfAdHocBookings}</td>
                                    <td>{Math.round(item.total * 100) / 100}</td>
                                </tr>
                            ))}
                        </React.Fragment>
                    ))}
                </> : null
            }
        </React.Fragment>
    );
};

export default BillingAccordion;
