import React, {Dispatch, SetStateAction, useRef} from 'react';
import commonStyles from "../../../children/AuditTrailsTable/index.module.scss";
import styles from "./index.module.scss";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import AppTableWhite from "../../../../AppTableWhite/AppTableWhite";
import auditIcon from "../../../../../img/auditWhite.svg";
import {getUsers, setPageNumber} from "../../../../../store/auditTrailsUserManagement/auditTrailsUserManagementSlice";
import {AppInfiniteScroll} from "../../../../AppInfiniteScroll/AppInfiniteScroll";

interface IUserManagementDetailsTableProps {
    setUserEmail: Dispatch<SetStateAction<string | null>>;
    searchValue: string;
}

const UserManagementDetailsTable: React.FC<IUserManagementDetailsTableProps> = ({setUserEmail, searchValue}) => {
    const dispatch = useAppDispatch();
    const containerRef = useRef<HTMLDivElement>(null);
    const {
        usersList,
        isDeleted,
        pageNumber,
        itemsPerPage,
        totalCount,
        maxItemsInTheTable,
        scrollLoader,
    } = useAppSelector(state => state.auditTrailsUserManagement);

    const fetchData = (page: number, pageSize: number, scrollPosition: string) => {
        dispatch(getUsers({
            searchValue,
            pageNumber: page,
            itemsPerPage: pageSize,
            scrollPosition
        }));
    }

    const setPage = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber));
    }

    return (
        <div className={commonStyles.tableContainer}>
            <div className={commonStyles.searchTableWrapper}>
                <AppInfiniteScroll
                    containerRef={containerRef}
                    fetchData={fetchData}
                    pageSize={itemsPerPage}
                    totalItems={totalCount}
                    currentPage={pageNumber}
                    setPage={setPage}
                    isLoading={scrollLoader}
                    scrollingContainerHeight="55vh"
                    scrollBounce={200}
                    maxItemsInTheTable={maxItemsInTheTable}
                    tableDataLength={usersList.length}>
                    <AppTableWhite>
                        <thead>
                        <tr>
                            {
                                !isDeleted &&
                                <th className={styles.noSpaceCell}>
                                    Group
                                </th>
                            }
                            <th className={styles.noSpaceCell}>
                                User
                            </th>
                            <th className={`${styles.noSpaceCell} ${styles.actionCell}`}>
                                Go to Audit Trails
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            usersList.length ?
                                usersList.map((user) => (
                                    <tr key={user.userId}>
                                        {
                                            !isDeleted &&
                                            <td className={styles.noSpaceCell}>
                                                {user.userGroup}
                                            </td>
                                        }
                                        <td className={styles.noSpaceCell}>
                                            {user.user}
                                        </td>
                                        <td className={styles.noSpaceCell}>
                                            <button onClick={() => {
                                                if (isDeleted) return;
                                                setUserEmail(user.user);
                                            }}
                                                    className={styles.actionBtn}>
                                                <img src={auditIcon} alt="audit"/>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                                :
                                <tr>
                                    <td className={styles.noSpaceCell}>
                                        Use search by user email to get data
                                    </td>
                                </tr>
                        }
                        </tbody>
                    </AppTableWhite>
                </AppInfiniteScroll>
            </div>
        </div>
    );
};

UserManagementDetailsTable.displayName = "UserManagementDetailsTable"
export default UserManagementDetailsTable;
