import React, {useEffect, useState} from 'react';
import RightsAssignmentTable from "./children/RightsAssignmentTable/RightsAssignmentsTable";
import AuditTrailsTable from "../children/AuditTrailsTable/AuditTrailsTable";
import AuditTrailPageInner from "../children/AuditTrailPageInner/AuditTrailPageInner";
import {useAuditingEntitiesSearch} from "../hooks/useAuditingEntitiesSearch";
import {useLocation, useNavigate} from "react-router-dom";
import {IMenuStructure} from "../../../pages/Cms/AppRoutes/routes/RouteInterface";
import {
    getRightsAssignmentAudit, IAuditRightAssignment,
    skipRightsAssignmentSearch
} from "../../../store/auditTrailsRightsAssignment/auditTrailsRightsAssignment";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {useRecordDetailsFromQueryString} from "../hooks/useRecordDetailsFromQueryString";
import commonStyles from "../children/AuditTrailPageInner/index.module.scss";
import SearchByTextInput from "../../SearchByTextInput/SearchByTextInput";
import {RecordTypes} from "../../../enums/recordTypes";
import {getAuditTrails} from "../../../store/auditTrailsCommon/auditTrailsCommonSlice";

export interface IAuditTrailsRAProps {
    menuStructure: IMenuStructure;
}

const AuditTrailsRightsAssignment: React.FC<IAuditTrailsRAProps> = React.memo(({menuStructure}) => {
    const [initial, setInitial] = useState(true);
    const [rightId, setRightId] = useState<string | null>(null);
    const {pageNumber, itemsPerPage} = useAppSelector(state => state.auditTrailsRightsAssignment);
    const {searchText, setSearchText} = useAuditingEntitiesSearch(
        getRightsAssignmentAudit,
        pageNumber,
        itemsPerPage,
        skipRightsAssignmentSearch,
        initial,
        setInitial,
        rightId,
        setRightId
    );
    const {trailsItemsPerPage} = useAppSelector(state => state.auditTrailsCommon);
    const {setRecordData, recordData} = useRecordDetailsFromQueryString();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const query = new URLSearchParams(useLocation().search);
    const queryId = query.get("id");
    const isExternal = !!query.get("isExternal");

    useEffect(() => {
        dispatch(skipRightsAssignmentSearch());
    }, [dispatch]);

    useEffect(() => {
        setRightId(queryId);
    }, [queryId]);

    useEffect(() => {
        if (rightId) {
            dispatch(getAuditTrails({
                auditRecordType: RecordTypes.RightAssignment,
                recordId: rightId,
                pageNumber: 1,
                itemsPerPage: trailsItemsPerPage,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rightId]);

    const handleRightAssignmentSelection = (rightAssignment: IAuditRightAssignment) => {
        setRightId(rightAssignment.id);
        setRecordData({
            rightHolder: rightAssignment.rightHolder,
            competition: rightAssignment.competition
        })
    }

    const handleTableDataSearch = (text: string) => {
        setRecordData(null);
        setSearchText(text);
    }

    const handleGoBack = () => {
        navigate(-1);
    }

    return (
        <AuditTrailPageInner
            pageTitle={"Audit Trails"}
            pageSubTitle={"Rights Assignment"}
            pageHeader={"Audit Trails - Rights Assignment"}
            menuStructure={menuStructure}
            backButtonAction={handleGoBack}
            isExternal={isExternal}>
            {
                recordData && rightId &&
                <div className={commonStyles.recordInfo} key="details">
                    <p className={commonStyles.recordInfoItem}><span>Rights Holder: </span>{recordData?.rightHolder}</p>
                    <p className={commonStyles.recordInfoItem}><span>Property: </span>{recordData?.competition}</p>
                </div>
            }
            <div className={commonStyles.searchInput} key="inputs-container">
                <SearchByTextInput
                    value={searchText}
                    onChange={(e) => handleTableDataSearch(e)}/>
            </div>
            {
                rightId
                    ?
                    <AuditTrailsTable
                        height="38vh"
                        auditRecordType={RecordTypes.RightAssignment}
                        recordId={rightId}
                    />
                    :
                    <RightsAssignmentTable
                        handleRightAssignmentSelection={handleRightAssignmentSelection}
                        searchText={searchText}
                    />
            }
        </AuditTrailPageInner>
    );
});

AuditTrailsRightsAssignment.displayName = "AuditTrailsRightsAssignment"
export default AuditTrailsRightsAssignment;
