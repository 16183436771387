import React, {useState} from 'react';
import styles from './index.module.scss';
import {IAccess} from "../../../../../../store/operatorAccess/operatorAccessSlice";
import {IUserItem} from "../../../../../../store/iGameInit/iGameInitSlice";
import {useAppSelector} from "../../../../../../hooks/hooks";
import {useNavigate} from "react-router-dom";
import {FaChevronDown, FaPencilAlt, FaRegTrashAlt} from "react-icons/fa";
import {Tooltip} from "react-tooltip";
import audit from "../../../../../../img/auditWhite.svg";
import {checkAuditPageAllowance} from "../../../../../../helpers/checkAuditPageAllowance";


interface ITableAccordionProps {
    operatorName: string
    accesses: IAccess[]
    openConfirmModal: (operatorId: string, rightId: string) => void;
    setUpdatingExistingAccess: (id: string, rightName: string) => void;
    users?: IUserItem[];
    rightHolderId: string | null;
}

const TableAccordion: React.FC<ITableAccordionProps> = React.memo((
    {
        operatorName,
        accesses,
        openConfirmModal,
        setUpdatingExistingAccess,
        users,
        rightHolderId
    }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const {organization} = useAppSelector(state => state.userInfo);
    const navigate = useNavigate();

    const checkIsAllFixturesSelected = (access: IAccess) => {
        if (access.fixturesCount) {
            return access.fixturesCount
        } else {
            return "All"
        }
    }

    const goToAuditTrail = (id: string, operator: string, competition: string) => {
        const encodedOrganization = encodeURIComponent(organization);
        const encodedOperator = encodeURIComponent(operator);
        const encodedCompetition = encodeURIComponent(competition);

        if (users) {
            const rightHolder = users.find(item => item.id === rightHolderId);
            const encodedRightHolder = rightHolder ? encodeURIComponent(rightHolder.name) : "";

            if (rightHolder) {
                navigate(`/cms/audit-trails-operator-access?id=${id}&rightHolder=${encodedRightHolder}&operator=${encodedOperator}&competition=${encodedCompetition}&isExternal=${true}`);
            }
        } else {
            navigate(`/cms/audit-trails-operator-access?id=${id}&rightHolder=${encodedOrganization}&operator=${encodedOperator}&competition=${encodedCompetition}&isExternal=${true}`);
        }
    }

    return (
        <>
            <tbody>
            <tr onClick={() => setIsExpanded(!isExpanded)}>
                <td className={styles.accordionCell}>
                    <div
                        className={!isExpanded ? styles.tableChevron : `${styles.tableChevron} ${styles.tableChevronTop}`}>
                        <FaChevronDown size={14}/>
                    </div>
                    {operatorName}
                </td>
                <td></td>
                <td></td>
                <td className={styles.actions}></td>
            </tr>
            </tbody>

            {isExpanded && <tbody>
            {accesses?.map((access, index) => {
                return (
                    <tr key={index} className={styles.accordionRow}>
                        <td></td>
                        <td>{access.rightName}</td>
                        <td>{checkIsAllFixturesSelected(access)}</td>
                        <td>
                            <div className={styles.actions}>
                                <button onClick={() => {
                                    setUpdatingExistingAccess(access.id, access.rightName);
                                }}
                                        className={styles.editBtn}
                                        type="button"
                                >
                                    <FaPencilAlt color={'FFFFFF'}/>
                                </button>
                                <button onClick={() => openConfirmModal(access.id, access.rightId)}
                                        className={styles.deleteBtn}
                                        type="button"
                                >
                                    <FaRegTrashAlt color={'FFFFFF'}/>
                                </button>
                                {
                                    checkAuditPageAllowance() &&
                                    <button
                                        className={styles.auditTrail}
                                        onClick={() => goToAuditTrail(access.id, operatorName, access.rightName)}
                                        type="button">
                                        <Tooltip className={styles.userTableTooltip} id="audit-trail-tooltip"/>
                                        <img
                                            src={audit}
                                            alt={"audit"}
                                            data-tooltip-id="audit-trail-tooltip"
                                            data-tooltip-content={"Audit Trail"}/>
                                    </button>
                                }
                            </div>
                        </td>
                    </tr>
                )
            })}
            </tbody>}
        </>
    )
});

export default TableAccordion;
