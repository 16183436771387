import React, {useState} from 'react';
import {IMenuStructure} from "../../../AppRoutes/routes/RouteInterface";
import commonStyles from "../Reporting/index.module.scss";
import IssuesTable from "./components/IssuesTable";
import styles from "./index.module.scss";
import SearchInput from "../../../../../components/SearchInput/SearchInput";
import {useDebounce} from "@helpers/useDebounce";
import {
    getIssuesReport,
    IIssuesReportPayload, IIssuesState, resetFilters, setFilters,
} from "@store/reportingIssuesSlice/reportingIssuesSlice";
import {useAppDispatch, useAppSelector} from "@hooks/hooks";
import DownloadIssuesSCV from "./components/DownloadIssuesSCV/DownloadIssuesSCV";
import PageHeader from "../../../../../components/PageHeader/PageHeader";
import {useDeepCompareEffect} from "@hooks/useDeepCompareEffect";
import HeaderFilters from "../../../OperatorView/AvailableContent/HeaderFilters/HeaderFilters";
import {createPayload} from "./helpers/createPayload";
import {Group} from "@enums/group";
import {IReportingFilters} from "@store/reportingFixtures/types";

export interface IReportingIssuesProps {
    menuStructure: IMenuStructure
}

const ReportingIssues: React.FC<IReportingIssuesProps> = ({menuStructure}) => {
    const dispatch = useAppDispatch()
    const [searchValue, setSearchValue] = useState<string>('');
    const reportingIssuesStoreData = useAppSelector(state => state.reportingIssues);
    const {role} = useAppSelector(state => state.userInfo);
    const {filters} = reportingIssuesStoreData;
    const filtersControls = [
        {
            datePicker: true,
            label: "Start Date",
            valueName: "startDate",
            options: null,
            withTime: false
        },
        {
            datePicker: true,
            label: "End Date",
            valueName: "endDate",
            options: null,
            withTime: false
        },
    ];

    useDeepCompareEffect(() => {
        const payload: IIssuesReportPayload = createPayload(reportingIssuesStoreData, searchValue);
        dispatch(getIssuesReport(payload));
    }, [filters]);

    const onSearch = () => {
        const payload: IIssuesReportPayload = createPayload(reportingIssuesStoreData, searchValue);
        dispatch(getIssuesReport(payload))
    }
    const onSetFilters = (value: IReportingFilters, name: keyof IIssuesState["filters"]) => {
        dispatch(setFilters({value, name}));
    }

    useDebounce(onSearch, searchValue, 500)
    return (
        <>
            <PageHeader menuStructure={menuStructure}
                        title={role === Group.IGame ? "Reporting" : "Issues"}
                        subTitle={role === Group.IGame ? "Issues" : ""}>
                <DownloadIssuesSCV
                    searchValue={searchValue ? searchValue : ''}
                />
            </PageHeader>
            <div className={styles.pageWrapper}>
                <div className={commonStyles.header}>
                    <div className={commonStyles.pageContainer}>
                        <h1 className={commonStyles.title}>Issues</h1>
                        <HeaderFilters
                            resetFilters={() => dispatch(resetFilters())}
                            filterControls={filtersControls}
                            setFilters={onSetFilters}
                            filterValues={filters}
                        />
                    </div>
                </div>
                <div className={commonStyles.container}>
                    <div className={commonStyles.contentWrapper}>
                        <div className={styles.searchInputInner}>
                            <div className={styles.searchInput}>
                                <SearchInput
                                    searchValue={searchValue}
                                    onChange={(e) => setSearchValue(e)}
                                />
                            </div>
                        </div>
                        <div className={styles.tableWrapper}>
                            <IssuesTable
                                searchValue={searchValue}
                                filters={filters}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReportingIssues;
