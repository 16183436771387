import React, {useEffect, useRef, useState} from "react";
import styles from './index.module.scss';
import unfold from "../../img/unfold.svg";
import {DateTime} from "luxon";
import {formatDate, getPickerDateFormat} from "../../helpers/dateFormatters";
import {
    DonutChartDataItem,
    IMainDataRequestPayload,
} from "../../store/operatorAnalytics/operatorAnalyticsSlice";
import {useAppDispatch} from "../../hooks/hooks";
import {DateTypes, ViewsByOptions} from "../../enums/analytics";
import DonutView from "./children/DonutView/DonutView";
import TopTenView from "./children/TopTenView/TopTenView";
import download from "../../img/download.png";
import PageHeader from "../PageHeader/PageHeader";
import {toastr} from "react-redux-toastr";
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import {
    DonutChartKey,
    IAnalyticsDashboardProps,
    IDropdownOptions, IEnumStringTypes,
    IFilterItem,
    IFilters, IMainChartConvertedData, IMinorFilters,
    IndividualGraphKey, ITrendLineData, IViewByOptions, TopTenChartKey, TotalGraphKey
} from "./types";
import {useRandomColors} from "./AnalyticsDashboardHooks/useRandomColors";
import Bookmarks from "./children/Bookmarks/Bookmarks";
import MainChartSection from "./children/MainChartSection/MainChartSection";
import Loader from "../Loader/Loader";
import {useDeepCompareEffect} from "../../hooks/useDeepCompareEffect";
import GeoChart from "./children/GeoChart/GeoChart";
import HeaderFilters from "../../pages/Cms/OperatorView/AvailableContent/HeaderFilters/HeaderFilters";
import Breakdown from "./children/Breakdown/Breakdown";
import {makeSelectOptionsFromStringsArray} from "../../helpers/makeSelectOptionsFromStringsArray";
import {ClientName} from "../../enums/clientName";
import moment from "moment";

const AnalyticsDashboard: React.FunctionComponent<IAnalyticsDashboardProps> = React.memo((
    {
        menuStructure,
        getInitData,
        initData,
        data,
        getData,
        createBookmark,
        updateBookmark,
        deleteBookmark,
        setSelectedBookmark,
        downloadCsv,
        client,
        loading,
        controls,
        filters,
        setFilters,
        resetFilters,
        setFiltersByBookmarks
    }) => {

    const defaultFilters: IFilters = {
        date: {
            from: getPickerDateFormat(DateTime.now().set({hour: 0, minute: 0, second: 0}).minus({days: 32}).toISO()),
            to: getPickerDateFormat(DateTime.now().set({hour: 0, minute: 0, second: 0}).minus({days: 1}).toISO())
        },
        customers: [],
        dataProviders: [],
        sports: [],
        competitions: [],
        countries: [],
        dataTypes: {
            id: 1,
            name: "Views"
        },
        streamFormats: []
    }

    const [donutMode, setDonutMode] = useState<boolean>(false);
    const [mainChartData, setMainChartData] = useState<any>();
    const [initTrendState, setInitTrendState] = useState(true);
    const [donutChartData, setDonutChartData] = useState<any>();
    const [topTenData, setTopTenData] = useState<any>();
    const [geoChartData, setGeoChartData] = useState<any>();
    const [fullWidthMainChart, setFullWidthMainChart] = useState<boolean>(false);
    const [trendVisibility, setTrendVisibility] = useState(false);
    const [startPointIndex, setStartPointIndex] = useState<number | undefined>(undefined);
    const [endPointIndex, setEndPointIndex] = useState<number | undefined>(undefined);
    const [zoomModeOn, setZoomModeOn] = useState(false);
    const [bookmarkInUsage, setBookmarkInUsage] = useState("");
    const colors = useRandomColors();
    const dispatch = useAppDispatch();
    const pdfLayout = useRef(null);
    const defaultMinorFilters: IMinorFilters = {
        donutChartAndTopTenViewType: "Sport",
        mainChartType: "Total",
        mainChartDateViewType: "Day"
    }
    const clientName: IEnumStringTypes = ClientName;
    const defaultDropdownOptions = {
        customers: [],
        dataProviders: [],
        sports: [],
        competitions: [],
        countries: [],
        dataTypes: [],
        streamFormats: [],
        donutChartViewTypes: makeSelectOptionsFromStringsArray([...client.map(x => clientName[x]), "Sport", "Property"]),
        topTenViewTypes: makeSelectOptionsFromStringsArray(["Sport", "Property", ...client.map(x => clientName[x]), "Fixture"]),
        mainChartTypes: makeSelectOptionsFromStringsArray(["Total", "Individual"]),
        mainChartDateViewTypes: makeSelectOptionsFromStringsArray(["Day", "Week", "Time Of Day", "Weekday"])
    }
    const [dropdownOptions, setDropdownOptions] = useState<IDropdownOptions>(defaultDropdownOptions);
    const [minorFilters, setMinorFilters] = useState(defaultMinorFilters);
    const validateDateRange = () => {
        const fromDate = moment(filters.startDate);
        const today = moment();
        const currentDate = moment({
            year: today.year(),
            month: today.month() + 1,
            day: today.date()
        });

        return fromDate.isAfter(currentDate);
    }

    useDeepCompareEffect(() => {
        if (validateDateRange()) {
            toastr.warning("IGame", "The date range cannot be greater than the current date")
            return;
        }
        const getIds = (items: IFilterItem[]) => {
            return items.length > 0 ? items.map(item => +item.id) : [];
        }

        const getNames = (items: IFilterItem[]) => {
            return items.length > 0 ? items.map(item => item.name) : [];
        }

        const model = {
            startDate: formatDate(filters.startDate),
            endDate: formatDate(filters.endDate),
            providersIds: getIds(defaultFilters.dataProviders),
            customersIds: getIds(defaultFilters.customers),
            sportsIds: filters.sports.map(sport => sport.value),
            competitions: getNames(defaultFilters.competitions),
            countries: getNames(defaultFilters.countries),
            streamFormats: getNames(defaultFilters.streamFormats)
        };

        dispatch(getData(createRequestModel()));
        dispatch(getInitData(model));
    }, [filters]);

    useEffect(() => {
        if (typeof endPointIndex === "number") {
            setZoomModeOn(true);
        }
    }, [endPointIndex]);

    useEffect(() => {
        const fromDate = moment(filters.startDate);
        const toDate = moment(filters.endDate);

        const rangeInYears = toDate.diff(fromDate, "years");
        const rangeInMonths = toDate.diff(fromDate, "months");
        const rangeInWeeks = toDate.diff(fromDate, "weeks");

        if (rangeInYears >= 2) {
            setDropdownOptions({
                ...dropdownOptions,
                mainChartDateViewTypes: makeSelectOptionsFromStringsArray(["Day", "Week", "Month", "Year", "Time Of Day", "Weekday"])
            })
        } else if (rangeInMonths >= 2) {
            setDropdownOptions({
                ...dropdownOptions,
                mainChartDateViewTypes: makeSelectOptionsFromStringsArray(["Day", "Week", "Month", "Time Of Day", "Weekday"])
            })
        } else if (rangeInWeeks >= 2) {
            setDropdownOptions({
                ...dropdownOptions,
                mainChartDateViewTypes: makeSelectOptionsFromStringsArray(["Day", "Week", "Time Of Day", "Weekday"])
            })
        } else {
            setDropdownOptions({
                ...dropdownOptions,
                mainChartDateViewTypes: makeSelectOptionsFromStringsArray(["Day", "Time Of Day", "Weekday"])
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.startDate, filters.endDate])

    const cropDataForZoom = (dataToCrop: any) => {
        if (typeof startPointIndex === "number" && typeof endPointIndex === "number") {
            let first: number;
            let second: number;

            if (startPointIndex === endPointIndex && startPointIndex !== 0 && endPointIndex !== 0) {
                toastr.error("Error!", "Dates are too close");
                setZoomModeOn(false);
            } else {
                [first, second] = startPointIndex < endPointIndex ? [startPointIndex, endPointIndex] : [endPointIndex, startPointIndex];

                if (first < 0) {
                    first = 0;
                }
                if (second < 0) {
                    second = 0;
                }

                const croppedData = dataToCrop.map((line: any) => {
                    return {
                        ...line,
                        data: line.data.slice(first, second + 1)
                    }
                });
                setZoomModeOn(true);

                return croppedData;
            }
        }
    }

    useEffect(() => {
        if (trendVisibility) {
            setMainChartData([...createMainChartBasicData(), ...createTrendLinesData(createMainChartBasicData())]);
        } else {
            setMainChartData(createMainChartBasicData());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, colors, minorFilters.mainChartDateViewType, minorFilters.mainChartType]);


    useEffect(() => {
        if (mainChartData) {
            if (!trendVisibility && !initTrendState) {
                const midIndex = mainChartData.length / 2;
                setMainChartData(mainChartData.slice(0, midIndex));
            } else {
                setMainChartData([...mainChartData, ...createTrendLinesData(mainChartData)]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trendVisibility, initTrendState]);

    useEffect(() => {
        if (zoomModeOn) {
            setMainChartData(cropDataForZoom(mainChartData));
        } else {
            if (trendVisibility) {
                setMainChartData([...createMainChartBasicData(), ...createTrendLinesData(createMainChartBasicData())]);
            } else {
                setMainChartData(createMainChartBasicData());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zoomModeOn, endPointIndex]);

    useEffect(() => {
        createAndSetDonutChartData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, colors, minorFilters.donutChartAndTopTenViewType]);


    useEffect(() => {
        createAndSetTopTenChartData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, minorFilters.donutChartAndTopTenViewType]);


    useEffect(() => {
        if (data && data.mapBreakdown.geoData.length) {
            createAndSetGeoChartData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const createRequestModel = (): IMainDataRequestPayload => {
        return {
            startDate: formatDate(filters.startDate),
            endDate: formatDate(filters.endDate),
            sports: {
                items: filters.sports.map((x) => ({id: +x.value, name: x.label}))
            },
            competitions: filters.competitions.map((x) => x.value),
            countries: filters.countries.map((x) => x.value),
            customers: {
                items: filters.customers.map((x) => ({id: +x.value, name: x.label}))
            },
            dataProviders: {
                items: filters.dataProviders.map((x) => ({id: +x.value, name: x.label}))
            },
            streamFormats: filters.streamFormats.map((x) => x.value),
            dataType: +filters.dataType.value
        }
    }

    const createMainChartBasicData = (): IMainChartConvertedData => {
        const dateTypes: IViewByOptions = DateTypes;
        const key = dateTypes[minorFilters.mainChartDateViewType.split(" ").join("")];

        if (minorFilters.mainChartType === "Individual") {
            const individualKey = key as IndividualGraphKey;
            const individualGraphData = data?.graphs.individual[individualKey] ?? [];
            const notEmptyData = individualGraphData.filter(x => x.coordinates.length !== 0);
            return notEmptyData
                .map((x: any, index: number) => ({
                    data: x.coordinates.map((item: any, i: number) => ({
                        x: i,
                        y: item.y,
                        name: createDateLabel(item),
                        color: colors[index],
                        categoryItem: x.label
                    }))
                }))
        } else {
            const totalKey = key as TotalGraphKey;
            const dataObj = data?.graphs.total[totalKey];
            const mainData = dataObj!.coordinates
                .map((item: any, index: number) => ({
                    x: index,
                    y: item.y,
                    name: createDateLabel(item),
                    color: colors[0],
                    categoryItem: dataObj!.label || defaultFilters.dataTypes.name
                }))
            return mainData.length !== 0 ? [{data: mainData}] : [];
        }
    }

    const createAndSetDonutChartData = () => {
        const viewsByOptions: IViewByOptions = ViewsByOptions;
        if (minorFilters.donutChartAndTopTenViewType !== "Fixture") {
            if (data && Object.keys(data?.donutCharts).length !== 0) {
                const key = viewsByOptions[minorFilters.donutChartAndTopTenViewType] as DonutChartKey;
                const total = data!.donutCharts[key]!.total;
                const donutData = data!.donutCharts[key]!.data
                    .map((item: DonutChartDataItem, index: number) => ({
                        y: item.value,
                        name: item.name,
                        color: colors[index],
                        percentage: item.percentage
                    }));
                setDonutChartData({total, graph: [{data: donutData}]});
            }
        } else {
            return;
        }
    }

    const exitZoomMode = () => {
        setZoomModeOn(false);
    }

    const createAndSetTopTenChartData = () => {
        const viewsByOptions: IViewByOptions = ViewsByOptions;

        if (data && Object.keys(data?.topTenCharts).length !== 0) {
            const key = viewsByOptions[minorFilters.donutChartAndTopTenViewType] as TopTenChartKey;
            const topData = data!.topTenCharts[key];
            setTopTenData(topData);
        } else {
            setTopTenData([]);
        }
    }

    const createAndSetGeoChartData = () => {
        const mapData = data!.mapBreakdown.geoData.map(x => {
            return [x.country, x.value];
        });
        const filteredData = mapData && mapData.filter(item => item[1] !== 0);
        setGeoChartData([["Country", "Events count"], ...filteredData]);
    }

    const createDateLabel = (item: {
        x: string,
        xStartTime: string,
        xStartDate: string,
        xEndDate: string,
        xDayName: string,
        xMonthName: string,
        xYear: string
    }): string => {

        if (minorFilters.mainChartDateViewType === "Time Of Day".trim()) {
            return item.xStartTime;
        }
        if (minorFilters.mainChartDateViewType === "Weekday") {
            return item.xDayName.substring(0, 3);
        }
        if (minorFilters.mainChartDateViewType === "Day") {
            return DateTime.fromISO(item.x).toFormat('dd-MM-yyyy ');
        }
        if (minorFilters.mainChartDateViewType === "Week") {
            return `${DateTime.fromISO(item.xStartDate).toFormat('dd-MM-yyyy ')}->`;
        }
        if (minorFilters.mainChartDateViewType === "Month") {
            return `${item.xMonthName.substring(0, 3)} ${item.xYear}`;
        }
        if (minorFilters.mainChartDateViewType === "Year") {
            return String(item.x);
        }
        return "";
    }

    const createTrendLinesData = (data: any): ITrendLineData[] => {
        return data.map((dataObj: any) => {

            const sumXY = dataObj.data.reduce((acc: number, curr: any) => {
                return acc + (curr.y * curr.x);
            }, 0);
            const sumX = dataObj.data.reduce((acc: number, curr: any) => {
                return acc + curr.x;
            }, 0);
            const sumY = dataObj.data.reduce((acc: number, curr: any) => {
                return acc + curr.y;
            }, 0);
            const sumXSquared = dataObj.data.reduce((acc: number, curr: any) => {
                return acc + Math.pow(curr.x, 2);
            }, 0);
            const itemsCount = dataObj.data.length;
            const slope = (sumXY - (sumX * sumY) / itemsCount) / (sumXSquared - Math.pow(sumX, 2) / itemsCount);
            const yIntercept = (sumY - slope * (sumX)) / itemsCount;

            const trendLine = dataObj.data.map((item: any) => {
                const trendY = Math.floor((slope * item.x) + yIntercept);
                return {
                    ...item,
                    y: trendY < 0 ? 0 : trendY
                };
            });

            return {
                data: trendLine
            };
        });
    };


    const handleDownloadCsv = () => {
        dispatch(downloadCsv(createRequestModel()))
            .then((response: any) => {
                const blob = new Blob([response.payload], {type: 'text/csv'});
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'analytics.csv');
                document.body.appendChild(link);
                link.click();
                toastr.clean();
            })
    }

    const handleDownloadPdf = () => {
        const layout: any = pdfLayout.current;
        html2canvas(layout)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'px', [canvas.width, canvas.height]);
                const pdfWidth = pdf.internal.pageSize.width;
                const pdfHeight = pdf.internal.pageSize.height;
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save("download.pdf");
            })
    }

    const onSetFilters = (value: any, name: string) => {
        setFilters(value, name)
        setBookmarkInUsage("")
    }
    const onResetFilters = () => {
        resetFilters()
        setBookmarkInUsage("")
    }

    return (
        <>
            <div className={styles.header}>
                <PageHeader menuStructure={menuStructure} title={"Analytics"}>
                    <div className={styles.downloads}>
                        <button onClick={handleDownloadPdf}>
                            PDF
                            <img src={download} alt="pdf"/>
                        </button>
                        <button onClick={handleDownloadCsv}>
                            CSV
                            <img src={download} alt="csv"/>
                        </button>
                    </div>
                </PageHeader>
            </div>
            <div className={styles.analyticsPage} ref={pdfLayout}>
                <div className={styles.filtersSectionWrapper}>
                    <div className={styles.filtersSection}>
                        <h1 className={styles.title}>Analytics</h1>
                        <div className={styles.filter}>
                            <HeaderFilters
                                resetFilters={onResetFilters}
                                filterControls={controls}
                                setFilters={onSetFilters}
                                filterValues={filters}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.container}>
                    <Bookmarks
                        setBookmarkInUsage={setBookmarkInUsage}
                        bookmarkInUsage={bookmarkInUsage}
                        bookmarks={initData.bookmarks}
                        filters={filters}
                        setFilters={setFiltersByBookmarks}
                        client={client}
                        createBookmark={createBookmark}
                        updateBookmark={updateBookmark}
                        deleteBookmark={deleteBookmark}
                        setSelectedBookmark={setSelectedBookmark}
                    />
                    <Breakdown
                        info={data}
                    />

                    <div className={styles.mainSection}>
                        {
                            loading
                                ?
                                <Loader
                                    backgroundColor="transparent"
                                />
                                :
                                <div className={styles.charts}>
                                    {
                                        !fullWidthMainChart
                                            ?
                                            <div className={styles.unfolded}>
                                                {
                                                    donutMode ?
                                                        <DonutView
                                                            viewType={filters.dataType.label}
                                                            setDonutMode={setDonutMode}
                                                            setFilters={setMinorFilters}
                                                            dropdownOptions={dropdownOptions}
                                                            minorFilters={minorFilters}
                                                            colors={colors}
                                                            data={donutChartData}
                                                            loading={loading}
                                                        />
                                                        :
                                                        <TopTenView
                                                            setDonutMode={setDonutMode}
                                                            setFilters={setMinorFilters}
                                                            dropdownOptions={dropdownOptions}
                                                            filters={minorFilters}
                                                            topTenData={topTenData}
                                                            loading={loading}
                                                        />
                                                }
                                            </div>
                                            :
                                            <div className={styles.folded}>
                                                <img
                                                    src={unfold} alt="state"
                                                    onClick={() => setFullWidthMainChart(false)}
                                                />
                                            </div>
                                    }
                                    <MainChartSection
                                        viewType={filters.dataType.label}
                                        setTrendVisibility={setTrendVisibility}
                                        trendVisibility={trendVisibility}
                                        setMinorFilters={setMinorFilters}
                                        minorFilters={minorFilters}
                                        dropdownOptions={dropdownOptions}
                                        mainChartData={mainChartData}
                                        fullWidthMainChart={fullWidthMainChart}
                                        colors={colors}
                                        loading={loading}
                                        setStartPointIndex={setStartPointIndex}
                                        setEndPointIndex={setEndPointIndex}
                                        zoomModeOn={zoomModeOn}
                                        setZoomModeOn={setZoomModeOn}
                                        exitZoomMode={exitZoomMode}
                                        setInitTrendState={setInitTrendState}
                                    />
                                </div>
                        }
                        <GeoChart
                            dataTypes={filters.dataType}
                            mainData={data}
                            geoChartData={geoChartData}
                        />
                    </div>
                </div>
            </div>
        </>
    )
})
AnalyticsDashboard.displayName = "AnalyticsDashboard";
export default AnalyticsDashboard;
