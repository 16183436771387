import styles from './index.module.scss'
import React, {useState} from "react";
import {Controller, FieldValues, SubmitHandler, useForm} from "react-hook-form";
import {login, skipAuthorization} from "../../../store/auth/authSlice";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import Input from "../../../components/Input/Input";
import SecondStepForm from "../SecondStepForm/SecondStepForm";
import logo from "../../../img/new_logo.svg";
import ForgotPassword from "../../ForgotPassword/ForgotPassword";

interface IFormInputs {
    email: string;
    password: string;
    stayLoggedIn: boolean;
}

const FirstStepForm = () => {
    const [formVisibility, setFormVisibility] = useState(false);
    const {loader, isFirstStep, isTwoFactorAuthentication} = useAppSelector(state => state.auth);
    const [userCredentials, setUserCredentials] = useState({email: '', password: '', stayLoggedIn: true})
    const {register, handleSubmit, formState: {errors}, control} = useForm<IFormInputs>();
    const dispatch = useAppDispatch()

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        setUserCredentials({...userCredentials, [name]: e.target.value});
    }

    const onSubmit: SubmitHandler<FieldValues> = () => {
        if (isFirstStep) {
            return;
        }

        if (loader) {
            return
        } else {
            dispatch(login(userCredentials))
        }
    };

    return (
        <>
            <div className={styles.loginLeftSide}>
                <div className={styles.titleText}>
                    <h3 className={styles.titleTextWelcome}>Welcome to</h3>
                    <h2 className={styles.titleTextProjectName}>
                        <span className={styles.titleTextProjectNameBold}>ONE</span>Marketplace
                    </h2>
                </div>
                <div className={styles.logoPoweredBy}>
                    Powered by
                    <img src={logo} alt="logo"/>
                </div>
            </div>
            <div className={styles.loginRightSide}>
                <form className={styles.loginForm}
                      onSubmit={handleSubmit(onSubmit)}
                      noValidate
                      autoComplete="new-password">
                    <h2 className={styles.loginFormTitle}>Log in</h2>
                    <div className={styles.loginInputs}>
                        <label className={styles.loginInputsLabel}>Email
                            <Controller
                                control={control}
                                name="email"
                                rules={{
                                    required: true,
                                    maxLength: 40,
                                    minLength: 10,
                                    pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                }}
                                render={({field: {onChange}}) => (
                                    <Input
                                        onChange={(e) => {
                                            onChange(e);
                                            handleChange(e)
                                        }}
                                        value={userCredentials.email}
                                        type="text"
                                        name="email"
                                        placeholder={"Enter your email..."}
                                        className={`${styles.loginInput} ${errors.email && styles.loginInputError}`}
                                    />
                                )}
                            />
                            {errors?.email?.type === "required" &&
                                <div className={styles.formError}>Email is required field</div>}
                            {errors?.email?.type === "minLength" &&
                                <div className={styles.formError}>Email should contain at least 10
                                    characters</div>}
                            {errors?.email?.type === "maxLength" &&
                                <div className={styles.formError}>Email should contain max 40 characters</div>}
                            {errors?.email?.type === "pattern" &&
                                <div className={styles.formError}>Email should be like example@xxx.xx</div>}
                            {errors?.email?.type === "validate" &&
                                <div className={styles.formError}>User with this email already exists</div>}
                        </label>
                        <label className={styles.loginInputsLabel}>Password
                            <Controller
                                control={control}
                                name="password"
                                rules={{
                                    required: true,
                                    maxLength: 40,
                                    minLength: 5,
                                }}
                                render={({field: {onChange}}) => (
                                    <Input
                                        onChange={(e) => {
                                            onChange(e);
                                            handleChange(e)
                                        }}
                                        value={userCredentials.password}
                                        type="password"
                                        name="password"
                                        placeholder={"Enter your password..."}
                                        className={`${styles.loginInput} ${errors.password && styles.loginInputError}`}
                                    />
                                )}
                            />
                            {errors?.password?.type === "required" &&
                                <div className={styles.formError}>Password is required field</div>}
                            {errors?.password?.type === "minLength" &&
                                <div className={styles.formError}>Password should contain at least 5
                                    characters</div>}
                            {errors?.password?.type === "maxLength" &&
                                <div className={styles.formError}>Password should contain max 40 characters</div>
                            }
                        </label>
                    </div>
                    <div className={styles.loginHelpers}>
                        <input
                            {...register("stayLoggedIn")}
                            type="checkbox"
                            className={styles.inputStaySigned}
                            name="stayLoggedIn"
                            id="stayLoggedIn"
                            checked={userCredentials.stayLoggedIn}
                            onChange={() => {
                                setUserCredentials({
                                    ...userCredentials,
                                    stayLoggedIn: !userCredentials.stayLoggedIn
                                });
                            }}
                        />
                        <label className={styles.rememberLabel} htmlFor="stayLoggedIn">Remember me</label>
                        <div className={styles.loginFormForgotPassword} onClick={() => setFormVisibility(true)}>Forgot
                            your password?
                        </div>
                    </div>
                    <button
                        name="Login"
                        type='submit' className={styles.loginSignInBtn}>
                        Log In
                    </button>
                </form>
            </div>
            {
                isFirstStep
                &&
                isTwoFactorAuthentication
                &&
                <SecondStepForm onClose={() => dispatch(skipAuthorization())}/>
            }
            {
                formVisibility
                &&
                <ForgotPassword setFormVisibility={setFormVisibility}/>
            }
        </>
    )
};

export default FirstStepForm;
