import React from 'react';
import styles from "../index.module.scss";
import {Control, Controller} from "react-hook-form";
import RadioButton from "../../../../../../../../../components/RadioButton/RadioButton";
import Checkbox from "../../../../../../../../../components/Checkbox";
import {isCheckedPages} from "../../../../helpers/isCheckedPages";
import {findRoleId, formatPageName} from "../../helpers";
import uuid from "react-uuid";
import {ISelectItem, IUserRole} from "../../../../../../../../../store/userManagement/userManagementSlice";
import {IStepTwoFormData} from "../../../../UserManagementHooks/useRightHolderFormData";

interface IStepTwoFormProps {
    control: Control,
    pricings: ISelectItem[],
    pageVisibilities: ISelectItem[],
    checkboxHandler: (checkboxId: number, roleId: string) => void,
    handlePricing: (buttonId: number, onChange: (...event: number[]) => void) => void,
    stepTwoFormData: IStepTwoFormData,
    roles: IUserRole[],
    selectAllPages: (pageVisibilities: ISelectItem[], roleId: string) => void,
    deselectAllPages: (roleId: string) => void
}

const StepTwoForm: React.FC<IStepTwoFormProps> = React.memo((
    {
        control,
        pricings,
        pageVisibilities,
        checkboxHandler,
        handlePricing,
        stepTwoFormData,
        roles,
        selectAllPages,
        deselectAllPages
    }) => {

    return (
        <div className={styles.formContent}>
            <div className={styles.twoInputsContainer}>
                <div className={styles.label}>Pricing
                    <div>
                        <Controller
                            control={control}
                            name="pricing"
                            render={({field: {onChange}}) => (
                                <div className={styles.radioButtons}>
                                    {pricings.map(price => (
                                        <button
                                            onClick={() => handlePricing(+price.id, onChange)}
                                            key={price.id}>
                                            <RadioButton
                                                text={price.name}
                                                checked={stepTwoFormData.pricing === +price.id}
                                            />
                                        </button>
                                    ))}
                                </div>
                            )}
                        />
                    </div>
                </div>
            </div>

            <div className={styles.checkboxInner}>
                <div className={styles.label}>Page Visibility for Super Admin
                    <div>
                        <div className={styles.checkboxes}>
                            {pageVisibilities.map(page => (
                                <div key={page.id}>
                                    <Checkbox
                                        isChecked={isCheckedPages(findRoleId("SuperAdmin", roles), +page.id, stepTwoFormData.rolePages)}
                                        checkboxId={`superAdmin-${page.id}`}
                                        onChangeHandler={() => checkboxHandler(+page.id, findRoleId("SuperAdmin", roles))}
                                        filled={true}
                                    />
                                    <span>{formatPageName("Rights Holder", page.name)}</span>
                                </div>
                            ))}
                            <div className={styles.quickSelectionButtons}>
                                <button
                                    onClick={() => selectAllPages(pageVisibilities, findRoleId("SuperAdmin", roles))}
                                    className={styles.quickSelectionButton}
                                    type="button"
                                    data-testid={`superAdmin-select-all`}
                                >Select All
                                </button>
                                <button
                                    onClick={() => deselectAllPages(findRoleId("SuperAdmin", roles))}
                                    className={styles.quickSelectionButton}
                                    type="button"
                                    data-testid={`superAdmin-deselect-all`}
                                >Deselect All
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.label}>Page Visibility for Admin
                <div>
                    <div className={styles.checkboxes}>
                        {pageVisibilities.map(page => (
                            <div key={page.id}>
                                <Checkbox
                                    isChecked={isCheckedPages(findRoleId("Admin", roles), +page.id, stepTwoFormData.rolePages)}
                                    checkboxId={uuid()}
                                    onChangeHandler={() => checkboxHandler(+page.id, findRoleId("Admin", roles))}
                                    filled={true}
                                />
                                <span>{formatPageName("Rights Holder", page.name)}</span>
                            </div>
                        ))}
                        <div className={styles.quickSelectionButtons}>
                            <button
                                onClick={() => selectAllPages(pageVisibilities, findRoleId("Admin", roles))}
                                className={styles.quickSelectionButton}
                                type="button"
                                data-testid={"admin-select-all"}
                            >Select All
                            </button>
                            <button
                                onClick={() => deselectAllPages(findRoleId("Admin", roles))}
                                className={styles.quickSelectionButton}
                                type="button"
                                data-testid={`admin-deselect-all`}
                            >Deselect All
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.checkboxInner}>
                <div className={styles.label}>Page Visibility for Finance
                    <div>
                        <div className={styles.checkboxes}>
                            {pageVisibilities.map(page => (
                                <div key={page.id}>
                                    <Checkbox
                                        isChecked={isCheckedPages(findRoleId("Finance", roles), +page.id, stepTwoFormData.rolePages)}
                                        checkboxId={uuid()}
                                        onChangeHandler={() => checkboxHandler(+page.id, findRoleId("Finance", roles))}
                                        filled={true}
                                    />
                                    <span>{formatPageName("Rights Holder", page.name)}</span>
                                </div>
                            ))}
                            <div className={styles.quickSelectionButtons}>
                                <button
                                    onClick={() => selectAllPages(pageVisibilities, findRoleId("Finance", roles))}
                                    className={styles.quickSelectionButton}
                                    type="button"
                                    data-testid={`finance-select-all`}
                                >Select All
                                </button>
                                <button
                                    onClick={() => deselectAllPages(findRoleId("Finance", roles))}
                                    className={styles.quickSelectionButton}
                                    type="button"
                                    data-testid={`finance-deselect-all`}
                                >Deselect All
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.checkboxInner}>
                <div className={styles.label}>Page Visibility for User
                    <div>
                        <div className={styles.checkboxes}>
                            {pageVisibilities.map(page => (
                                <div key={page.id}>
                                    <Checkbox
                                        isChecked={isCheckedPages(findRoleId("User", roles), +page.id, stepTwoFormData.rolePages)}
                                        checkboxId={uuid()}
                                        onChangeHandler={() => checkboxHandler(+page.id, findRoleId("User", roles))}
                                        filled={true}
                                    />
                                    <span>{formatPageName("Rights Holder", page.name)}</span>
                                </div>
                            ))}
                            <div className={styles.quickSelectionButtons}>
                                <button
                                    onClick={() => selectAllPages(pageVisibilities, findRoleId("User", roles))}
                                    className={styles.quickSelectionButton}
                                    type="button"
                                    data-testid={`user-select-all`}
                                >Select All
                                </button>
                                <button
                                    onClick={() => deselectAllPages(findRoleId("User", roles))}
                                    className={styles.quickSelectionButton}
                                    type="button"
                                    data-testid={`user-deselect-all`}
                                >Deselect All
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default StepTwoForm;
