import React, {ChangeEvent, useEffect, useState} from 'react';
import styles from './index.module.scss';
import Input from "../../../Input/Input";
import search from "../../../../img/search.svg";
import {FiCheck} from "react-icons/fi";
import {IUserItem} from "../../../../store/iGameInit/iGameInitSlice";
import {useLocation, useNavigate} from "react-router-dom";
import {clearCartState} from "../../../../store/rightHolderBooking/rightHolderBookingSlice";
import {useAppDispatch} from "../../../../hooks/hooks";

interface IUsersDropdownProps {
    users?: IUserItem[];
    title?: string;
}

const UsersDropdown: React.FC<IUsersDropdownProps> = React.memo(({users, title}) => {
    const [usersOptions, setUsersOptions] = useState<IUserItem[] | undefined>([]);
    const [searchWord, setSearchWord] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        setUsersOptions(users);
    }, [users]);

    useEffect(() => {
        if (users) {
            const usersFound = users.filter(x => x.name.toLowerCase().includes(searchWord.toLowerCase()));
            setUsersOptions(usersFound);
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchWord]);

    const handleUserClick = (user: IUserItem) => {
        const currentUser = localStorage.getItem("client");

        if (currentUser && currentUser === user.id.toString()) return;

        const pathParts = location.pathname.split('/');
        pathParts[3] = user.id;
        localStorage.setItem("client", user.id);
        dispatch(clearCartState());
        const updatedPath = pathParts.join('/');
        navigate(updatedPath);
    }

    return (
        <ul className={styles.usersDropdown}>
            <div className={styles.searchUser}>
                <Input
                    type="text"
                    placeholder="What are you looking for?"
                    value={searchWord}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchWord(e.target.value)}
                    autoFocus={true}
                />
                <img src={search} alt="search"/>
            </div>

            <div className={styles.usersList}>
                {
                    usersOptions!.map(item => (
                        <li className={styles.userItem}
                            key={item.id}
                            onClick={() => handleUserClick(item)}>

                            {item.name}
                            {item.name === title && <FiCheck/>
                            }
                        </li>
                    ))
                }
            </div>
        </ul>
    );
});

export default UsersDropdown;
