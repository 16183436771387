import {createAsyncThunk, createSlice, PayloadAction, AnyAction} from "@reduxjs/toolkit";
import {auth} from "../../services/auth-service";
import {toastr} from "react-redux-toastr";
import {AxiosError} from "axios";

export interface IAuthResponse {
    accessToken: string | null,
    refreshToken: string | null,
    confirmed: boolean,
    isTwoFactorAuthentication: boolean
}

export interface IAuthVerifyResponse {
    accessToken: string
    refreshToken: string
    confirmed: boolean
}

export interface IAuthState {
    isFirstStep: boolean
    error: string
    userEmail: string,
    loader: boolean,
    isTwoFactorAuthentication: boolean,
    socketConnectionMessage: string
}

export interface ILoginPayload {
    email: string
    password: string
}

export interface IVerifyPayload {
    email: string,
    verificationCode: string,
    staySignedIn: boolean
}

const initialState: IAuthState = {
    isFirstStep: false,
    error: "",
    userEmail: "",
    loader: false,
    isTwoFactorAuthentication: false,
    socketConnectionMessage: ""
}

export const login = createAsyncThunk<IAuthResponse, ILoginPayload, { rejectValue: string }>(
    'auth/login',
    async (ILoginPayload, {rejectWithValue}) => {
        try {
            const {data} = await auth.post("/api/Auth/first-step", ILoginPayload)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const verifyUser = createAsyncThunk<IAuthVerifyResponse, IVerifyPayload, { rejectValue: string }>(
    'auth/verify',
    async (IVerifyPayload, {rejectWithValue}) => {
        try {
            const {data} = await auth.post("/api/Auth/second-step", IVerifyPayload)
            localStorage.setItem("book-token", data.accessToken)
            localStorage.setItem("book-refresh", data.refreshToken)
            window.location.href = "/"
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)


export const logout = createAsyncThunk<any, null, { rejectValue: string }>(
    'auth/logout',
    async (ILogoutPayload, {rejectWithValue}) => {
        try {
            const {data} = await auth.get("/api/Azure/Logout?redirectUrl=http://localhost:3000/")
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        skipAuthorization: (state) => {
            state.isFirstStep = false;
            state.error = "";
            state.userEmail = "";
            state.loader = false;
        },
        connectSocket: (state, action: PayloadAction<string>) => {
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.error = ""
                state.loader = true
            })
            .addCase(login.fulfilled, (state, action) => {
                if (action.payload.accessToken) {
                    localStorage.setItem("book-token", action.payload.accessToken)
                }
                if (action.payload.refreshToken) {
                    localStorage.setItem("book-refresh", action.payload.refreshToken)
                }
                state.isTwoFactorAuthentication = action.payload.isTwoFactorAuthentication
                state.loader = false
                state.isFirstStep = true
                state.userEmail = action.meta.arg.email
                if (!action.payload.isTwoFactorAuthentication) {
                    window.location.href = "/"
                }
            })
            .addCase(verifyUser.pending, (state) => {
                state.error = ""
                state.loader = true
            })
            .addCase(verifyUser.fulfilled, (state) => {
                state.loader = false
            })
            .addMatcher(isError, (state, action: PayloadAction<string>) => {
                state.error = action.payload
                state.loader = false
            })
    },
})

export const {
    skipAuthorization,
    connectSocket
} = authSlice.actions

export default authSlice.reducer

function isError(action: AnyAction) {
    return action.type.endsWith("rejected")
}
