import {store} from "../store";
import {appPages} from "../pages/Cms/AppRoutes/appPages/appPages";

export const checkAuditPageAllowance = () => {
    const state = store.getState();
    const pages = state.userInfo.pages;
    const pageNumber = Object.entries(appPages).find(([_, val]) => val === "audit-trails")?.[0];

    if (pageNumber) {
        return pages.includes(+pageNumber);
    }
}