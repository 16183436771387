import {
    IGetOperatorDetails,
    ISelectItem,
    IUserRole
} from "../../../../../../../../store/userManagement/userManagementSlice";

export const checkIsUniqOperatorName = (name: string, editUser: IGetOperatorDetails | undefined, operators: IUserRole[]) => {
    if (editUser) {
        const originalName = editUser.name
        if (originalName === name) {
            return true
        } else {
            const isUniqName = operators.find(operator => operator.name === name)
            return !isUniqName
        }

    } else {
        const isUniqName = operators.find(operator => operator.name === name)
        return !isUniqName
    }
}

export const isRequiredSelect = (e: (ISelectItem | undefined)[]) => {
    if (e === undefined) {
        return false
    }
    if (e.length === 0) {
        return false
    }
}

export const setCustomerIdValue = (editUser: IGetOperatorDetails | undefined, customers: ISelectItem[]) => {
    if (editUser) {
        const currentCustomer = customers.find(customer => customer.id === editUser.customerId.toString());
        if (currentCustomer) {
            return {
                value: currentCustomer.id,
                label: `${currentCustomer.id} (${currentCustomer.name})`
            }
        } else {
            return {value: '', label: ''}
        }
    } else {
        return {value: '', label: ''}
    }
}

export const setMultiSelectValue = (selectName: string, editUser: IGetOperatorDetails | undefined, initData: ISelectItem[]) => {
    if (editUser) {
        if (selectName === 'territories' && editUser.territoryIdes && editUser.territoryIdes.length > 0) {
            return editUser.territoryIdes.map(userTerritoryId => (
                initData.find(territory => userTerritoryId === territory.id)
            ))
        } else if (selectName === 'rightHolders' && editUser.rightHolders && editUser.rightHolders.length > 0) {
            return editUser.rightHolders.map(userRightHolderId => (
                initData.find(rightHolder => userRightHolderId === rightHolder.id)))
        }
    }
    return []
}
