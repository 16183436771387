import React, {useEffect, useState} from "react";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {ITableRowData} from "../../BookFixtures";
import styles from "./index.module.scss";
import {IDate} from "../AddFixtureForm/AddFixtureForm";
import {DateTime} from "luxon";
import {useAppDispatch, useAppSelector} from "@hooks/hooks";
import {RootState} from "@store/index";
import {
    getSatelliteDetails,
    ICreateSatelliteIngestData,
    skipLoadingStatus,
} from "@store/rightHolderBooking/rightHolderBookingSlice";
import {getPickerDateFormat} from "@helpers/dateFormatters";
import SelectInput from "@components/SelectInput/SelectInput";
import DatePicker from "@components/DatePicker/DatePicker";
import {makeSelectOptionsFromStringsArray} from "@helpers/makeSelectOptionsFromStringsArray";
import {getSatellites} from "@store/init/initSlice";
import {IngestOptionId} from "@enums/ingest";
import CheckboxWithLabel from "@components/CheckboxWithLabel/CheckboxWithLabel";
import {IMethodPayloads, IUploadedFiles} from "../IngestForm/types/types";
import UploadComponent from "../UploadComponent/UploadComponent";
import uuid from "react-uuid";
import IngestValidate from "../IngestForm/helpers/IngestValidate";
import {toastr} from "react-redux-toastr";
import FormLabel from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/components/FormLabel/FormLabel";

export interface ISatelliteFormProps {
    fixtureData: ITableRowData | null;
    setMethodPayloads: React.Dispatch<React.SetStateAction<IMethodPayloads>>;
    methodPayloads: IMethodPayloads
    uploadedFiles: IUploadedFiles;
    closeSatelliteForm: () => void;
    iGameChannel: string;
    setUploadedFiles: React.Dispatch<React.SetStateAction<IUploadedFiles>>;
}

export interface ISatelliteInput {
    fixtureId: string;
    startDate: IDate | null;
    endDate: IDate | null;
    satellite: { value: string; label: string; } | null;
    bissCode: string | undefined;
    symbolRate: number;
    downloadFrequency: number;
    downloadPolarity: { value: string; label: string; };
    audio: { value: string; label: string; };
    nS3_NS4: boolean | undefined,
    fileName: string | undefined,
    bissType: number
}

export enum BISSTypes {
    BISSMode1 = 1,
    BISSE = 2,
    BISSCA = 3
}

const SatelliteForm: React.FunctionComponent<ISatelliteFormProps> = React.memo((
    {
        closeSatelliteForm,
        fixtureData,
        setMethodPayloads,
        methodPayloads,
        uploadedFiles,
        setUploadedFiles
    }) => {
    const polarityData = ["X", "Y"];
    const audioData = ["1/2", "3/4", "5/6", "7/8", "9/10"];
    const [startDate, setStartDate] = useState<IDate | null>(null);
    const [endDate, setEndDate] = useState<IDate | null>(null);
    const [dragActive, setDragActive] = useState(false);
    const [bissType, setBissType] = useState<number>(0);
    const [isNS3NS4, setIsNS3NS4] = useState<boolean | undefined>(false);
    const dispatch = useAppDispatch();
    const satellitesData = useAppSelector((state: RootState) => state.initInfo.satellites);
    const details = useAppSelector((state: RootState) => state.rightHolderBooking.details.satellite);
    const {backupDetails, isActivateBackup} = useAppSelector((state: RootState) => state.rightHolderBooking);
    const {
        handleSubmit,
        formState: {errors},
        control,
        reset,
        setError,
        setValue
    } = useForm<ISatelliteInput>();

    const handleFormClose = () => {
        dispatch(skipLoadingStatus());
    }

    const satelliteOptions = satellitesData.map(option => ({
        value: option.id,
        label: option.name,
    }))

    useEffect(() => {
        if (fixtureData) {
            setStartDate(getPickerDateFormat(fixtureData.startDate));
            setEndDate(getPickerDateFormat(fixtureData.endDate));
            if (fixtureData.ingestType === IngestOptionId.Satellite) {
                dispatch(getSatelliteDetails({id: fixtureData.id}));
            }
        }

        dispatch(getSatellites(null));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (fixtureData?.id === details?.fixtureId && !isActivateBackup && details) {
            setFormFixtureValues()
        }
        if (backupDetails.satelliteFixture && fixtureData?.id === backupDetails.satelliteFixture.fixtureId) {
            setFormBackupValues()
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details, backupDetails]);

    const setFormFixtureValues = () => {
        if (!details) return;
        setStartDate(getPickerDateFormat(details.startDate!));
        setEndDate(getPickerDateFormat(details.endDate!));
        const satelliteFound = satelliteOptions.find(x => x.value === details.satelliteId);
        const polarity = makeSelectOptionsFromStringsArray(polarityData).find(item => item.value === details.downloadPolarity)
        const audio = makeSelectOptionsFromStringsArray(audioData).find(item => item.value === details.audio)
        reset({
            fixtureId: details.fixtureId,
            startDate,
            endDate,
            satellite: satelliteFound ? satelliteFound : null,
            bissCode: details.bissCode,
            symbolRate: details.symbolRate!,
            downloadFrequency: details.downloadFrequency!,
            downloadPolarity: polarity,
            audio,
            fileName: details.fileName
        });
        setBissType(details.bissType)
        setIsNS3NS4(details.nS3_NS4)
    }

    const setFormBackupValues = () => {
        setStartDate(getPickerDateFormat(backupDetails.satelliteFixture.startDate));
        setEndDate(getPickerDateFormat(backupDetails.satelliteFixture.endDate));
        const satelliteFound = satelliteOptions.find(x => x.value === backupDetails.satelliteFixture.satelliteId);
        const polarity = makeSelectOptionsFromStringsArray(polarityData).find(item => item.value === backupDetails.satelliteFixture.downloadPolarity)
        const audio = makeSelectOptionsFromStringsArray(audioData).find(item => item.value === backupDetails.satelliteFixture.audio)
        reset({
            fixtureId: backupDetails.fixtureId,
            startDate,
            endDate,
            satellite: satelliteFound ? satelliteFound : null,
            bissCode: backupDetails.satelliteFixture.bissCode,
            symbolRate: backupDetails.satelliteFixture.symbolRate,
            downloadFrequency: backupDetails.satelliteFixture.downloadFrequency,
            downloadPolarity: polarity,
            audio
        });
        setBissType(backupDetails.satelliteFixture.bissType)
        setIsNS3NS4(backupDetails.satelliteFixture.nS3_NS4)
    }

    useEffect(() => {
        if (methodPayloads.Satellite && !isActivateBackup && fixtureData) {
            const fixture = methodPayloads.Satellite.fixture
            setValue("bissCode", fixture.bissCode)
            if (fixture.symbolRate) {
                setValue("symbolRate", fixture.symbolRate)
            }
            if (fixture.downloadFrequency) {
                setValue("downloadFrequency", fixture.downloadFrequency)
            }
            setBissType(fixture.bissType)
            setIsNS3NS4(fixture.nS3_NS4)
            const satellite = satelliteOptions.find(option => option.value === fixture.satelliteId)
            if (satellite) {
                setValue("satellite", satellite)
            }
            const downloadPolarity = makeSelectOptionsFromStringsArray(
                polarityData).find(polarity => polarity.value === fixture.downloadPolarity
            )
            if (downloadPolarity) {
                setValue("downloadPolarity", downloadPolarity)
            }
            const audio = makeSelectOptionsFromStringsArray(
                audioData).find(audio => audio.value === fixture.audio
            )
            if (audio) {
                setValue("audio", audio)
            }
        }
        if (methodPayloads.SatelliteBackup && isActivateBackup && fixtureData) {
            const fixture = methodPayloads.SatelliteBackup.fixture
            setValue("bissCode", fixture.bissCode)
            if (fixture.symbolRate) {
                setValue("symbolRate", fixture.symbolRate)
            }
            if (fixture.downloadFrequency) {
                setValue("downloadFrequency", fixture.downloadFrequency)
            }
            setBissType(fixture.bissType)
            setIsNS3NS4(fixture.nS3_NS4)
            const satellite = satelliteOptions.find(option => option.value === fixture.satelliteId)
            if (satellite) {
                setValue("satellite", satellite)
            }
            const downloadPolarity = makeSelectOptionsFromStringsArray(
                polarityData).find(polarity => polarity.value === fixture.downloadPolarity
            )
            if (downloadPolarity) {
                setValue("downloadPolarity", downloadPolarity)
            }
            const audio = makeSelectOptionsFromStringsArray(
                audioData).find(audio => audio.value === fixture.audio
            )
            if (audio) {
                setValue("audio", audio)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [methodPayloads]);


    const formatDate = (dateInPickerFormat: IDate) => {
        if (dateInPickerFormat) {
            const {year, month, day, hour, minute} = dateInPickerFormat;
            return DateTime.local(year, month, day, hour, minute).toUTC().toString();
        } else {
            return "";
        }
    }

    const handleDrag = <T extends HTMLElement>(e: React.DragEvent<T>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    }

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
    }

    const onSubmit: SubmitHandler<ISatelliteInput> = (data) => {
        const datesSelected = data.startDate || data.endDate;
        const detailsModel: ICreateSatelliteIngestData = {
            fixtureId: fixtureData!.id,
            startDate: data.startDate ? formatDate(data.startDate) : null,
            endDate: data.endDate ? formatDate(data.endDate) : null,
            satelliteId: data.satellite ? data.satellite.value : null,
            bissCode: data.bissCode || "",
            symbolRate: +data.symbolRate || null,
            downloadFrequency: +data.downloadFrequency || null,
            downloadPolarity: data.downloadPolarity ? data.downloadPolarity.value : "",
            audio: data.audio ? data.audio.value : "",
            fileUrl: "",
            bissType,
            nS3_NS4: isNS3NS4
        }

        setMethodPayloads({...methodPayloads, Satellite: {fixture: detailsModel}});
        // eslint-disable-next-line no-mixed-operators
        if (datesSelected && DateTime.now() > DateTime.fromISO(formatDate(data.startDate as IDate)) || DateTime.now() > DateTime.fromISO(formatDate(data.endDate as IDate))) {
            setError('startDate', {type: 'date', message: "Dates can't be set earlier than current date"});
            return;
        }
        if (datesSelected && DateTime.fromISO(formatDate(data.startDate as IDate)) > DateTime.fromISO(formatDate(data.endDate as IDate))) {
            setError('startDate', {type: 'date', message: "End date can't be earlier than start date"});
            return;
        }
        closeSatelliteForm()
    }

    const selectBISSType = (e: React.MouseEvent<HTMLDivElement>, BISSType: number) => {
        e.stopPropagation()
        e.preventDefault()
        setBissType(BISSType)
    }

    const selectIsNS3NS4 = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        e.preventDefault()
        setIsNS3NS4(!isNS3NS4)
    }
    const handleUploadingOnClick = (e: React.DragEvent<HTMLInputElement>) => {
        e.preventDefault();
        const supportedFileTypes = ['.eml', '.txt', '.rtf', '.olm', '.msg'];
        const input = e.target as HTMLInputElement;
        if (!input.files) {
            return;
        }
        const addedFiles: File[] = Object.values(input.files);

        if (IngestValidate.detectInvalidFileType(addedFiles, supportedFileTypes)) {
            return toastr.error('Invalid file type', `There are available file types: ${supportedFileTypes.map(file => file).join(', ')}`);
        }

        if (isActivateBackup) {
            setUploadedFiles({...uploadedFiles, backUpFiles: uploadedFiles.backUpFiles.concat(addedFiles)});
        } else {
            setUploadedFiles({
                ...uploadedFiles, primaryFiles: uploadedFiles.primaryFiles.concat(addedFiles)
            });
        }
    }

    return (
        <div onClick={() => handleFormClose()} className={styles.overlay}>
            <form
                onClick={(e) => e.stopPropagation()}
                className={styles.form}
                action=""
                onSubmit={handleSubmit(onSubmit)}
                onDragEnter={handleDrag}>
                {
                    dragActive &&
                    <div className={styles.coverElement}
                         onDragEnter={handleDrag}
                         onDragLeave={handleDrag}
                         onDragOver={handleDrag}
                         onDrop={handleDrop}>
                    </div>
                }
                <FormLabel text={isActivateBackup ? "Back-up Ingest" : "Primary Ingest"}/>
                <div className={styles.contentWrapper}>
                    <div className={styles.inputsWrapper}>

                        <div className={styles.uploadWrapper}>
                            <UploadComponent
                                handleUploadingOnClick={handleUploadingOnClick}
                                title={'Satellite'}/>
                        </div>

                        {uploadedFiles.primaryFiles.length ?
                            <label> Uploaded files:
                                {uploadedFiles.primaryFiles.map(file => (
                                    <p className={styles.file}
                                       key={uuid()}>{file.name}</p>
                                ))}
                            </label>
                            :
                            null
                        }

                        {uploadedFiles.backUpFiles.length ?
                            <label> Uploaded files:
                                {uploadedFiles.backUpFiles.map(file => (
                                    <p className={styles.file}
                                       key={uuid()}>{file.name}</p>
                                ))}
                            </label>
                            :
                            null
                        }

                        {details?.filesUrls &&
                        <label> Download Satellite file:
                            {details.filesUrls.map(file => (
                                <button className={`${styles.file} ${styles.fileUrl}`}
                                        onClick={() => window?.open(file, '_blank')}
                                        key={uuid()}>{file}</button>
                            ))}

                        </label>
                        }

                        <label className={styles.selectList} data-testid={'satellite-label'}>Satellite
                            <Controller
                                control={control}
                                name="satellite"
                                rules={{required: true}}
                                render={({
                                             field: {onChange, value},
                                         }) => (
                                    <SelectInput
                                        options={satelliteOptions}
                                        onChange={x => {
                                            onChange(x);
                                        }}
                                        value={value}
                                        aria-label={"satellite-select"}
                                    />
                                )}
                            />
                            {
                                errors.satellite && errors.satellite.type === "required"
                                && <span className={styles.error} data-testid={"satelliteRequiredError"}>This field is required</span>
                            }
                        </label>
                        <div className={styles.bissTypeControls}>
                            <Controller
                                control={control}
                                name="bissType"
                                render={() => (
                                    <>
                                        <div className={styles.checkboxGroup}>
                                            <div onClick={(e) => selectBISSType(e, 1)}>
                                                <CheckboxWithLabel
                                                    checked={bissType === BISSTypes.BISSMode1}
                                                    labelText={"BISS Mode 1"}
                                                    data-testid={"biss-mode1"}
                                                />
                                            </div>
                                            <div onClick={(e) => selectBISSType(e, 2)}>
                                                <CheckboxWithLabel
                                                    checked={bissType === BISSTypes.BISSE}
                                                    labelText={"BISS-E"}
                                                    data-testid={"biss-E"}
                                                />
                                            </div>
                                            <div onClick={(e) => selectBISSType(e, 3)}>
                                                <CheckboxWithLabel
                                                    checked={bissType === BISSTypes.BISSCA}
                                                    labelText={"BISS CA"}
                                                    data-testid={"biss-CA"}
                                                />
                                            </div>
                                            <div onClick={(e) => {
                                                selectIsNS3NS4(e)
                                                selectBISSType(e, 4)
                                            }}>
                                                <CheckboxWithLabel
                                                    checked={isNS3NS4}
                                                    labelText={"NS3/NS4"}
                                                    data-testid={"NS3"}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            />
                        </div>
                        <label>Biss Code
                            <Controller
                                control={control}
                                name="bissCode"
                                render={({
                                             field: {onChange, value},
                                             formState: {errors},
                                         }) => (
                                    <input
                                        onChange={val => {
                                            onChange(val);
                                        }}
                                        value={value || ""}
                                        type="text"
                                        aria-invalid={errors.bissCode ? "true" : "false"}
                                    />
                                )}
                            />
                        </label>
                        <label>Symbol Rate
                            <Controller
                                control={control}
                                name="symbolRate"
                                rules={{
                                    pattern: {
                                        value: /^\d+$/,
                                        message: "This field should contain only digits"
                                    }
                                }}
                                render={({
                                             field: {onChange, value},
                                         }) => (
                                    <input
                                        className={styles.inputShort}
                                        onChange={val => {
                                            onChange(val);
                                        }}
                                        value={value || ""}
                                        type="text"
                                        aria-invalid={errors.symbolRate ? "true" : "false"}
                                    />
                                )}
                            />
                            <span className={styles.units}>Msy/s</span>
                            {
                                errors.symbolRate && errors.symbolRate.type === "pattern"
                                && <span className={styles.error}
                                         data-testid={"symbolRateDigitsRequiredError"}>{errors.symbolRate.message}</span>
                            }
                        </label>
                        <label>Download Frequency
                            <Controller
                                control={control}
                                name="downloadFrequency"
                                rules={{
                                    pattern: {
                                        value: /^\d+$/,
                                        message: "This field should contain only digits"
                                    }
                                }}
                                render={({
                                             field: {onChange, value},
                                         }) => (
                                    <input
                                        onChange={val => {
                                            onChange(val);
                                        }}
                                        value={value || ""}
                                        type="text"
                                        aria-invalid={errors.downloadFrequency ? "true" : "false"}
                                    />
                                )}
                            />
                            {
                                errors.downloadFrequency && errors.downloadFrequency.type === "pattern"
                                && <span className={styles.error}
                                         data-testid={"downloadFrequencyDigitsRequiredError"}>{errors.downloadFrequency.message}</span>
                            }
                        </label>
                        <label className={styles.selectList}>Download Polarity
                            <Controller
                                control={control}
                                name="downloadPolarity"
                                render={({
                                             field: {onChange, value},
                                         }) => (
                                    <SelectInput
                                        options={makeSelectOptionsFromStringsArray(polarityData)}
                                        onChange={x => {
                                            onChange(x);
                                        }}
                                        value={value}
                                        aria-label={"download - polarity"}
                                    />
                                )}
                            />
                        </label>
                        <label className={styles.selectList}>Audio
                            <Controller
                                control={control}
                                name="audio"
                                render={({
                                             field: {onChange, value},
                                         }) => (
                                    <SelectInput
                                        options={makeSelectOptionsFromStringsArray(audioData)}
                                        onChange={x => {
                                            onChange(x);
                                        }}
                                        value={value}
                                        aria-label={'audio-select'}
                                    />
                                )}
                            />
                        </label>

                        {!details &&
                        <label>File Name
                            <Controller
                                control={control}
                                name="fileName"
                                render={({field: {onChange, value}}) => (
                                    <input
                                        onChange={val => {
                                            onChange(val);
                                        }}
                                        value={value || ""}
                                        type="text"
                                    />
                                )}
                            />
                        </label>
                        }
                        <div className={styles.dateInputSection}>
                            <div className={styles.calendar}>Start Time and Date
                                <div className={styles.calendarInputWrapper}>
                                    <Controller
                                        control={control}
                                        name="startDate"
                                        rules={{required: true}}
                                        render={({
                                                     field: {onChange},
                                                 }) => (
                                            <DatePicker
                                                onChange={date => {
                                                    onChange(date)
                                                }}
                                                initValue={startDate}
                                            />
                                        )}
                                    />
                                </div>
                                {
                                    errors.startDate && errors.startDate.type === "required"
                                    &&
                                    <span className={styles.error} data-testid={"startDateRequiredError"}>Start Date is required</span>
                                }
                            </div>
                            <div className={styles.calendar}>End Time and Date
                                <div className={styles.calendarInputWrapper}>
                                    <Controller
                                        control={control}
                                        name="endDate"
                                        rules={{required: true}}
                                        render={({
                                                     field: {onChange},
                                                 }) => (
                                            <DatePicker
                                                onChange={date => {
                                                    onChange(date)
                                                }}
                                                initValue={endDate}
                                                modalPositioningClass="calendar-modal-up-right"
                                            />
                                        )}
                                    />
                                </div>
                                {
                                    errors.endDate && errors.endDate.type === "required"
                                    && <span className={styles.error} data-testid={"endDateRequiredError"}>End date is required</span>
                                }
                                {
                                    errors.startDate?.message
                                        ? <span className={styles.error}>{String(errors.startDate.message)}</span>
                                        : null
                                }
                            </div>
                            <p className={styles.timeFormat}>Time in UTC</p>
                        </div>
                    </div>
                </div>
                <div className={styles.buttons}>
                    <button className={styles.cancelButton} onClick={closeSatelliteForm}>Cancel</button>
                    <button
                        className={styles.submitButton}
                        type="submit">
                        Confirm
                    </button>
                </div>
            </form>
        </div>
    )
});

export default SatelliteForm;
