import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../../../hooks/hooks";
import styles from "./index.module.scss";
import Loader from "../../../../../../../components/Loader/Loader";
import AddUserButton from "../AddUserButton/AddUserButton";
import TechnicalProviderForm from "./children/TechnicalProviderForm/TechnicalProviderForm";
import {PageTabs} from "../../UserManagement";
import {
    clearTechnicalProviders,
    getTechnicalProviders,
    getTechnicalProvidersInitData,
    sortTechnicalProviders
} from "../../../../../../../store/userManagement/userManagementSlice";
import {createUsersQueriesString} from "./helpers/createUsersQueriesString";
import {InnerRole} from "../../../../../../../enums/innerRole";
import {useDisableScroll} from "../../../../../../../hooks/useDisableScroll";
import UsersTable from "./children/UsersTable/UsersTable";

interface ITechnicalProvidersUsersProps {
    pdfLayout: React.RefObject<HTMLDivElement>;
    isDownloadingPDF: boolean;
    setCurrentTab: Dispatch<SetStateAction<string>>;
}

const TechnicalProvidersUsers: React.FC<ITechnicalProvidersUsersProps> = React.memo((
    {
        pdfLayout,
        isDownloadingPDF,
        setCurrentTab
    }) => {
    const [isOpenForm, setIsOpenForm] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const {technicalProviders, loading} = useAppSelector(state => state.userManagement);
    const {specificRole} = useAppSelector(state => state.userInfo);
    useDisableScroll(isOpenForm);

    useEffect(() => {
        dispatch(getTechnicalProviders(""))
        dispatch(getTechnicalProvidersInitData(null));
        setCurrentTab(PageTabs.TECHNICAL_PROVIDERS)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(clearTechnicalProviders());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const sortUsers = (field: string, isAscending: boolean) => {
        const payload = createUsersQueriesString(
            field,
            isAscending
        )
        dispatch(sortTechnicalProviders(payload))
    }

    return (
        <>
            {isOpenForm &&
                <TechnicalProviderForm closeForm={() => setIsOpenForm(false)}/>}

            {loading && <Loader/>}
            {!loading &&
                <div className={styles.page}>
                    <div className={styles.tableOverlay}>
                        <div className={specificRole === InnerRole.SuperAdmin ? styles.tableInner : null}>
                            {specificRole === InnerRole.SuperAdmin &&
                                <div className={styles.addUserBtn}>
                                    <AddUserButton
                                        buttonName={PageTabs.TECHNICAL_PROVIDERS}
                                        onClickHandler={() => setIsOpenForm(true)}
                                    />
                                </div>
                            }

                            <UsersTable
                                pdfLayout={pdfLayout}
                                sortUsers={sortUsers}
                                users={technicalProviders}
                                userRole={PageTabs.TECHNICAL_PROVIDERS}
                                isDownloadingPDF={isDownloadingPDF}
                            />
                        </div>
                    </div>
                </div>
            }
        </>
    );
});

export default TechnicalProvidersUsers;
