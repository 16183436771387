import React from "react";
import AnalyticsDashboard from "@components/AnalyticsDashboard/AnalyticsDashboard";
import {
    createFiltersBookmark,
    deleteFiltersBookmark,
    downloadOperatorAnalyticsCsv,
    getInitFiltersOptions,
    getOperatorAnalytics,
    setFilters,
    setSelectedBookmark,
    updateFiltersBookmark,
    setFiltersByBookmarks,
    resetFilters,
    IOperatorAnalyticsState
} from "@store/operatorAnalytics/operatorAnalyticsSlice";
import {useAppDispatch, useAppSelector} from "@hooks/hooks";
import {RootState} from "@store/index";
import {IPageMenuProps} from "../../AppRoutes/routes/RouteInterface";
import {ISelectOption} from "@store/operatorBookedContent/operatorBookedContentSlice";

const AnalyticsPage: React.FunctionComponent<IPageMenuProps> = ({menuStructure}) => {
    const dispatch = useAppDispatch()
    const {
        initData,
        mainData,
        loading,
        filters,
        controls
    } = useAppSelector((state: RootState) => state.operatorAnalytics);

    const onSetFilters = (value: ISelectOption, name: keyof IOperatorAnalyticsState["filters"]) => {
        dispatch(setFilters({value, name}))
    }
    const onResetFilters = () => {
        dispatch(resetFilters())
    }
    const onSetFiltersByBookmarks = (filters: any) => {
        dispatch(setFiltersByBookmarks(filters))
    }
    return (
        <>
            <AnalyticsDashboard
                filters={filters}
                setFilters={onSetFilters}
                setFiltersByBookmarks={onSetFiltersByBookmarks}
                resetFilters={onResetFilters}
                controls={controls}
                menuStructure={menuStructure}
                getInitData={getInitFiltersOptions}
                initData={initData}
                getData={getOperatorAnalytics}
                data={mainData}
                createBookmark={createFiltersBookmark}
                updateBookmark={updateFiltersBookmark}
                deleteBookmark={deleteFiltersBookmark}
                setSelectedBookmark={setSelectedBookmark}
                downloadCsv={downloadOperatorAnalyticsCsv}
                client={["dataProviders"]}
                loading={loading}
            />
        </>
    )
}

export default AnalyticsPage;
