import React, {useEffect} from 'react';
import {Controller, useForm} from "react-hook-form";
import styles from './index.module.scss'
import {
    IBTTowerInput
} from "../../../../RightsHolderView/ScheduleIngest/BookFixtures/children/IngestForm/children/BTTower/BTTower";


interface IBTTowerFormState {
    closeForm: () => void
    setBTTower: (localEnd: string) => void
    localEnd: string | null
}

const BTTowerForm: React.FC<IBTTowerFormState> = ({closeForm, setBTTower, localEnd}) => {
    const {handleSubmit, formState: {errors}, control, getValues, setValue} = useForm<IBTTowerInput>();
    useEffect(() => {
        if (localEnd) {
            setValue("source", localEnd)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localEnd]);

    const setBTTowerIngest = () => {
        setBTTower(getValues("source"))
        closeForm()
    }

    return (
        <div onClick={() => closeForm()} className={styles.overlay}>
            <form
                onClick={(e) => e.stopPropagation()}
                onSubmit={handleSubmit(setBTTowerIngest)}
                className={styles.form}>
                <div className={styles.contentWrapper}>
                    <div className={styles.titleInner}>
                        <h1 className={styles.title}>Update Selected Ingest Method</h1>
                    </div>
                    <h6 className={styles.method}>BT Tower</h6>
                    <label>Source
                        <Controller
                            control={control}
                            name="source"
                            rules={{
                                required: true
                            }}
                            render={({
                                         field: {onChange, value},
                                     }) => (
                                <input
                                    onChange={val => {
                                        onChange(val);
                                    }}
                                    type="text"
                                    aria-invalid={!!errors.source}
                                    value={value}
                                />
                            )}
                        />
                        {
                            errors.source && errors.source.type === "required"
                            && <span className={styles.error}>This field is required</span>
                        }
                    </label>
                </div>
                <div className={styles.buttons}>
                    <button onClick={() => closeForm()} className={styles.cancelButton}>Cancel</button>
                    <button
                        className={styles.submitButton}
                        type="submit">Confirm
                    </button>
                </div>
            </form>
        </div>
    );
};

BTTowerForm.displayName = "BTTowerFormReporting"
export default BTTowerForm;
