import uuid from "react-uuid";

export interface ISidebarItem {
    title: string;
    menuItemId?: string;
    path: string;
    pageName?: string | null;
    pageNumber?: number | null;
    children?: ISidebarItem[];
}

export const IGameSidebarData: ISidebarItem[] = [
    {
        title: "Rights Holders",
        menuItemId: uuid(),
        path: "/cms/igame-rightHolders",
        pageName: "igame-rightHolders",
        pageNumber: 1,
    },
    {
        title: "Operators",
        menuItemId: uuid(),
        path: "/cms/igame-operators",
        pageName: "igame-operators",
        pageNumber: 2
    },
    {
        title: "Analytics",
        menuItemId: uuid(),
        path: "/cms/igame-analytics",
        pageName: "igame-analytics",
        pageNumber: 3
    },
    {
        title: "Billing",
        menuItemId: uuid(),
        path: "/cms/igame-billing",
        pageName: "igame-billing",
        pageNumber: 4
    },
    {
        title: "Settings",
        menuItemId: uuid(),
        pageName: "Settings",
        children: [
            {
                title: "User Management",
                menuItemId: uuid(),
                path: `/cms/user-management`,
                pageName: "user-management",
                pageNumber: 5
            },
            {
                title: "Publishing",
                menuItemId: uuid(),
                path: `/cms/publishing`,
                pageName: "publishing",
                pageNumber: 6
            },
            {
                title: "Rights Assignment",
                menuItemId: uuid(),
                path: `/cms/rights-assignment`,
                pageName: "rights-assignment",
                pageNumber: 7
            },
            {
                title: "Reporting",
                menuItemId: uuid(),
                path: `/Reporting`,
                pageName: "Reporting",
                children: [
                    {
                        title: "Reporting",
                        menuItemId: uuid(),
                        path: `/cms/reporting`,
                        pageName: "Reporting",
                        pageNumber: 8
                    },
                    {
                        title: "Issues",
                        menuItemId: uuid(),
                        path: `/cms/reporting-issues`,
                        pageName: "Reporting",
                        pageNumber: 18
                    },
                    {
                        title: "BT Tower Bookings",
                        menuItemId: uuid(),
                        path: `/cms/bt-bookings`,
                        pageName: "Reporting",
                        pageNumber: 17
                    },
                ]
            },
            {
                title: "Ingest Resources",
                menuItemId: uuid(),
                path: `/cms/ingest-resources`,
                pageName: "IngestResource",
                pageNumber: 9
            },
            {
                title: "Audit Trails",
                menuItemId: uuid(),
                path: "/audit-trails",
                pageName: "audit-trails",
                children: [
                    {
                        title: "Ingest Resources",
                        menuItemId: uuid(),
                        path: "/cms/audit-trails-ingest",
                        pageName: "audit-trails",
                        pageNumber: 10
                    },
                    {
                        title: "Pricing",
                        menuItemId: uuid(),
                        path: "/cms/audit-trails-pricing",
                        pageName: "audit-trails",
                        pageNumber: 11
                    },
                    {
                        title: "Content Access",
                        menuItemId: uuid(),
                        path: "/cms/audit-trails-operator-access",
                        pageName: "audit-trails",
                        pageNumber: 12
                    },
                    {
                        title: "Rights Assignment",
                        menuItemId: uuid(),
                        path: "/cms/audit-trails-rights-assignment",
                        pageName: "audit-trails",
                        pageNumber: 13
                    },
                    {
                        title: "Bookings",
                        menuItemId: uuid(),
                        path: "/cms/audit-trails-bookings",
                        pageName: "audit-trails",
                        pageNumber: 14
                    },
                    {
                        title: "Rights Restrictions",
                        menuItemId: uuid(),
                        path: "/cms/audit-trails-rights-restrictions",
                        pageName: "audit-trails",
                        pageNumber: 15
                    },
                    {
                        title: "User Management",
                        menuItemId: uuid(),
                        path: "/cms/audit-trails-user-management",
                        pageName: "audit-trails",
                        pageNumber: 16
                    }
                ]
            }
        ],
        path: "/cms"
    },
];

export const operatorSidebarData: ISidebarItem[] = [
    {
        title: "Content Management",
        menuItemId: uuid(),
        pageName: "Content Management",
        children: [
            {
                title: "Booked Content",
                menuItemId: uuid(),
                path: `/cms/operator-booked-content`,
                pageName: "operator-booked-content",
                pageNumber: 3
            },
            {
                title: "Operator Portfolio",
                menuItemId: uuid(),
                path: `/cms/operator-portfolio`,
                pageName: "operator-portfolio",
                pageNumber: 32
            },
        ],
        path: "/cms/content-management"
    },
    {
        title: "Rights Overview",
        menuItemId: uuid(),
        pageName: "Rights Overview",
        children: [
            {
                title: "Rights Holders Portfolio",
                menuItemId: uuid(),
                path: `/cms/operator-rights-holders`,
                pageName: "operator-rights-holders",
                pageNumber: 33
            },
        ],
        path: "/cms/rights-overview"
    },
    {
        title: "Content Analysis",
        menuItemId: uuid(),
        pageName: "Content Analysis",
        children: [
            {
                title: "Available Content",
                menuItemId: uuid(),
                path: `/cms/available-content`,
                pageName: "available-content",
                pageNumber: 5
            },
            {
                title: "Favourite Content",
                menuItemId: uuid(),
                path: `/cms/favourite-content`,
                pageName: "favourite-content",
                pageNumber: 6
            }
        ],
        path: "/cms/content-analysis"
    },
    {
        title: "Analytics",
        menuItemId: uuid(),
        path: "/cms/operator-analytics",
        pageName: "operator-analytics",
        pageNumber: 6
    },
    {
        title: "Billing",
        menuItemId: uuid(),
        path: "/cms/operator-billing",
        pageName: "operator-billing",
        pageNumber: 7
    },
    {
        title: "Settings",
        menuItemId: uuid(),
        pageName: "Settings",
        children: [
            {
                title: "User Management",
                menuItemId: uuid(),
                path: "/cms/operator-settings",
                pageName: "operator-settings",
                pageNumber: 8
            },
            {
                title: "Audit Trails",
                menuItemId: uuid(),
                path: "/audit-trails",
                pageName: "audit-trails",
                children: [
                    {
                        title: "User Management",
                        menuItemId: uuid(),
                        path: "/cms/audit-trails-user-management",
                        pageName: "audit-trails",
                        pageNumber: 9
                    }
                ]
            }
        ],
        path: "/cms"
    },
];

export const rightsHolderSidebarData: ISidebarItem[] = [
    {
        title: "Schedule Ingest",
        menuItemId: uuid(),
        pageName: "Schedule Ingest",
        children: [
            {
                title: "Book Fixtures",
                menuItemId: uuid(),
                path: `/cms/book-fixtures`,
                pageName: "rightHolder-book-fixtures",
                pageNumber: 1
            },
            {
                title: "Upcoming Booked Fixtures",
                menuItemId: uuid(),
                path: `/cms/upcoming-fixtures`,
                pageName: "rightHolder-upcoming-fixtures",
                pageNumber: 2
            }
        ],
        path: `/cms`
    },
    {
        title: "Rights Management",
        menuItemId: uuid(),
        pageName: "Rights Management",
        children: [
            {
                title: "Content Access",
                menuItemId: uuid(),
                path: `/cms/operator-access`,
                pageName: "rightHolder-operator-access",
                pageNumber: 3
            },
            {
                title: "Property Restrictions",
                menuItemId: uuid(),
                path: `/cms/right-holder-geo-blocked`,
                pageName: "right-holder-geo-blocked",
                pageNumber: 4
            },
            {
                title: "Operator Geo-Restrictions",
                menuItemId: uuid(),
                path: `/cms/right-holder-geo-allowed`,
                pageName: "right-holder-geo-allowed",
                pageNumber: 5
            },
            {
                title: "Pricing",
                menuItemId: uuid(),
                path: `/cms/pricing`,
                pageName: "rightHolder-pricing",
                pageNumber: 6
            }
        ],
        path: "/cms"
    },
    {
        title: "Analytics",
        menuItemId: uuid(),
        path: "/cms/analytics",
        pageName: "rightHolder-analytics",
        pageNumber: 7
    },
    {
        title: "Billing",
        menuItemId: uuid(),
        pageName: "Billing",
        children: [
            {
                title: "Operator",
                menuItemId: uuid(),
                path: `/cms/billing-operator`,
                pageName: "rightHolder-billing-operator",
                pageNumber: 8
            },
            {
                title: "IGame Media",
                menuItemId: uuid(),
                path: `/cms/billing-igame`,
                pageName: "rightHolder-billing-igame",
                pageNumber: 9
            }
        ],
        path: "/cms"
    },
    {
        title: "Settings",
        menuItemId: uuid(),
        pageName: "Settings",
        children: [
            {
                title: "User Management",
                menuItemId: uuid(),
                path: `/cms/rightHolder-settings`,
                pageName: "rightHolder-settings",
                pageNumber: 10
            },
            {
                title: "Audit Trails",
                menuItemId: uuid(),
                path: "/audit-trails",
                pageName: "audit-trails",
                children: [
                    {
                        title: "Audit Trails - Pricing",
                        menuItemId: uuid(),
                        path: "/cms/audit-trails-pricing",
                        pageName: "audit-trails",
                        pageNumber: 11
                    },
                    {
                        title: "Audit Trails - Content Access",
                        menuItemId: uuid(),
                        path: "/cms/audit-trails-operator-access",
                        pageName: "audit-trails",
                        pageNumber: 12
                    },
                    {
                        title: "Audit Trails - Bookings",
                        menuItemId: uuid(),
                        path: "/cms/audit-trails-bookings",
                        pageName: "audit-trails",
                        pageNumber: 13
                    },
                    {
                        title: "Audit Trails - User Management",
                        menuItemId: uuid(),
                        path: "/cms/audit-trails-user-management",
                        pageName: "audit-trails",
                        pageNumber: 14
                    }
                ]
            }
        ],
        path: "/cms"
    },
    {
        title: "Reporting",
        menuItemId: uuid(),
        pageName: "Reporting",
        children: [
            {
                title: "Issues",
                menuItemId: uuid(),
                path: `/cms/reporting-issues`,
                pageName: "reporting-issues",
                pageNumber: 30
            },
            {
                title: "Reporting",
                menuItemId: uuid(),
                path: `/cms/rh-reporting`,
                pageName: "rh-reporting",
                pageNumber: 40
            },
        ],
        path: "/cms"
    },
];



