import {AnyAction, createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {client} from "../../services/api-servise";
import {toastr} from "react-redux-toastr";
import {AxiosError} from "axios";
import {IConfirmResp, IDeleteUserBody} from "../settings/settingsSlice";
import {formClient} from "../../services/multiPartForm-service";
import {createFormData} from "../../pages/Cms/IGameView/Settings/UserManagement/children/UserTypesForms/helpers";
import {
    IStepOneFormData
} from "../../pages/Cms/IGameView/Settings/UserManagement/UserManagementHooks/useOperatorFormData";
import {SingleValue} from "react-select";
import {IDropdownOption} from "../../components/AnalyticsDashboard/types";


interface IUpdateIProviderBody {
    payload: {
        userId: string,
        technicalProviderId: string
    },
    closeForm: () => void
}

interface IGetTechnicalProviderDetailsResp {
    technicalProvider: string,
    technicalProviderId: string,
    id: string,
    email: string,
    lastLoginDate: string,
    roleId: number,
    roleName: string,
    isAlertTicked: boolean,
    isDeleted: boolean
}

interface IAddTechnicalProviderBody {
    payload: {
        email: string,
        technicalProviderId: string
    },
    closeForm: () => void
}

interface ITechnicalProvidersInitDataResp {
    items: ISelectItem[];
}

interface IGetTechnicalProvidersResp {
    users: ITechnicalProvider[];
}

export interface ITechnicalProvider {
    technicalProvider: string,
    id: string;
    email: string;
    isAlertTicked: null | boolean;
    lastLoginDate: string | null;
    roleId: string;
    roleName: string | null;
    isDeleted: boolean;
}

export interface IGetPagesBody {
    clientId: string | null,
    roleId: string
}

export interface IUpdateIGameUserBody {
    payload: {
        userId: string,
        roleId: string,
    },
    closeForm: () => void
}

interface IGetUserDetailsResp {
    id: string,
    email: string,
    lastLoginDate: string,
    roleId: string,
    roleName: string,
    isAlertTicked: boolean,
    isDeleted: boolean,
    pages: {
        items: ISelectItem[]
    },
    clientId: string
}

export interface IAddIGameUserBody {
    payload: {
        email: string,
        roleId: string,
    },
    closeForm: () => void
}

export interface IAddUserBody {
    payload: {
        email: string,
        roleId: string,
        pages: number[],
        clientId: string | null,
    },
    closeForm: () => void
}

export interface IPagesResp {
    pages: {
        items: ISelectItem[];
    }
}

export interface ICreateOperatorPayload {
    customerId: number,
    name: string,
    clientAdmin?: string,
    adminEmail: string,
    commercialContact?: string,
    commercialEmail: string,
    scheduleContact?: string,
    scheduleContactEmail: string,
    liveIssuesContact?: string,
    liveIussesContactEmail: string,
    territoryIdes: string[],
    rightHolders?: string[],
    rolePages: IUserPages[],
    operatorId?: string
    logo?: File
}

export interface IOperatorBody {
    payload: ICreateOperatorPayload,
    closeForm: () => void
}

export interface IGetOperatorDetails {
    id: string,
    customerId: number,
    name: string,
    clientAdmin: string,
    adminEmail: string,
    commercialContact: string,
    commercialEmail: string,
    scheduleContact: string,
    scheduleContactEmail: string,
    liveIssuesContact: string,
    liveIussesContactEmail: string,
    territoryIdes: string[],
    rightHolders: string[],
    rolePageDTOs: IUserPages[]
}

export interface IGetRightHolderDetails {
    id: string,
    name: string,
    dataProviderId: number,
    address: string,
    clientAdmin: string,
    adminEmail: string,
    commercialContact: string,
    commercialEmail: string,
    schedulingContact: string,
    schedulingEmail: string,
    liveIssuesContact: string,
    liveIssuesContactEmail: string,
    pricing: number,
    allowancePerOperator: number,
    overageRatePerGB: number,
    playerRatePerView: number,
    operatorRatePerMonth: number,
    ingestRatePerHour: number,
    rolePageDTOs: IUserPages[]
    contractStartDate: string,
    isBandwidthEnabled: boolean,
    isIngestEnabled: boolean,
    isPlayerUsageEnabled: boolean,
    isOperatorEnabled: boolean
}

export interface IUserPages {
    roleId: string,
    pageIds: number[]
}

export interface IRHPayload {
    name: string,
    dataProviderId: number,
    address?: string,
    clientAdmin?: string,
    adminEmail: string,
    commercialContact?: string,
    commercialEmail: string,
    schedulingContact?: string,
    schedulingEmail: string,
    liveIssuesContact?: string,
    liveIssuesContactEmail: string,
    pricing?: number,
    allowancePerOperator?: number,
    overageRatePerGB?: number,
    playerRatePerView?: number,
    operatorRatePerMonth?: number
    ingestRatePerHour?: number,
    rolePages: IUserPages[],
    rightHolderId?: string,
    logo?: File,
    contractStartDate: string
}

export interface IRightHolderBody {
    payload: IRHPayload,
    closeForm: () => void
}

export interface IGetOperatorsInitResp {
    rightHolders: {
        items: ISelectItem[]
    },
    customers: {
        items: ISelectItem[]
    },
    territories: {
        items: ISelectItem[]
    },
    operatorVisibilities: {
        items: ISelectItem[]
    },
    roles: {
        items: IUserRole[]
    },
    customersInUse: string[],
    existingEmails: string[]
}

export interface ISelectItem {
    id: string,
    name: string
}

export interface IGetRightHoldersInitResp {
    pageVisibilities: {
        items: ISelectItem[]
    },
    dataProviders: {
        items: ISelectItem[]
    },
    pricings: {
        items: ISelectItem[]
    },
    roles: {
        items: IUserRole[]
    },
    dataProvidersInUse: {
        items: ISelectItem[]
    },
    existingEmails: string[]
}

export interface IUserItem {
    id: string;
    email: string;
    isAlertTicked: null | boolean;
    lastLoginDate: string | null;
    roleId: string;
    roleName: string | null;
    isDeleted: boolean;
}

export interface IUsersResp {
    users: IUserItem[],
    clientName: string,
    clientId: string
}

export interface IUserRole {
    id: string;
    name: string;
    logo: null | string
}

export interface IGetUsersResp {
    rightHolders: IUserRole[],
    operators: IUserRole[],
    rightHoldersCount: number,
    operatorsCount: number
}

export interface ICheckCustomerBody {
    customer: SingleValue<IDropdownOption>,
    setInFormData: (arg: IStepOneFormData) => void,
    stepOneFormData: IStepOneFormData
}

export interface IIGameUserManagementStateState {
    rightHolders: IUserRole[],
    rightHoldersCount: number,
    operators: IUserRole[],
    operatorsCount: number,
    technicalProviders: ITechnicalProvider[] | null,
    clientName: string,
    clientId: string | null,
    users: IUserItem[] | null,

    dataProviders: ISelectItem[],
    dataProvidersInUse: ISelectItem[],
    technicalProvidersInitData: ISelectItem[],
    pageVisibilities: ISelectItem[],
    pricings: ISelectItem[],
    roles: IUserRole[],

    rightHoldersInit: ISelectItem[],
    customers: ISelectItem[],
    customersInUse: string [],
    existingEmails: string[]
    territories: ISelectItem[],
    operatorVisibilities: ISelectItem[],

    rightsHolderDetails: IGetRightHolderDetails | undefined,
    currentOperator: IGetOperatorDetails | undefined,

    pages: ISelectItem[],
    userDetails: IGetUserDetailsResp | undefined,
    technicalProviderDetails: IGetTechnicalProviderDetailsResp | undefined,
    currentUserPages: ISelectItem[],
    csvFile: string | undefined
    csvFileName: string
    error: string,
    loading: boolean,
    pagesLoading: boolean,
    formLoading: boolean,
    isUserCreation: boolean,
    isCustomerAvailable: boolean | undefined,
    isCustomerStatusUpdating: boolean

    [key: string]: any;
}

const iGameUserManagementState: IIGameUserManagementStateState = {
    rightHolders: [],
    rightHoldersCount: 0,
    operators: [],
    technicalProviders: null,
    operatorsCount: 0,

    clientName: '',
    clientId: null,
    users: null,

    dataProviders: [],
    dataProvidersInUse: [],
    technicalProvidersInitData: [],
    pageVisibilities: [],
    pricings: [],
    roles: [],

    rightHoldersInit: [],
    customers: [],
    customersInUse: [],
    existingEmails: [],
    territories: [],
    operatorVisibilities: [],

    rightsHolderDetails: undefined,
    currentOperator: undefined,
    currentUserPages: [],

    pages: [],
    userDetails: undefined,
    technicalProviderDetails: undefined,
    csvFile: undefined,
    csvFileName: "",
    error: '',
    loading: false,
    pagesLoading: false,
    formLoading: false,
    isUserCreation: false,
    isCustomerAvailable: undefined,
    isCustomerStatusUpdating: false
};


export const getUserType = createAsyncThunk<IGetUsersResp, string | null, { rejectValue: string }>(
    'userManagement/getUserType',
    async (searchWord, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`/api/IGames/users${searchWord ? `?searchText=${searchWord}` : ''}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getRightHoldersInitData = createAsyncThunk<IGetRightHoldersInitResp, null, { rejectValue: string }>(
    'userManagement/getRightHoldersInitData',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`/api/IGames/right-holders/init`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const addRightHolder = createAsyncThunk<IUserRole, IRightHolderBody, { rejectValue: string }>(
    'userManagement/addRightHolder',
    async (body, {rejectWithValue, dispatch}) => {
        try {
            const payload = createFormData(body.payload);
            const {data} = await formClient.post(`/api/IGames/right-holders`, payload)
            toastr.success('IGame', `User has been added`)
            dispatch(getUserType(null));
            body.closeForm()
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getRightHolderDetails = createAsyncThunk<IGetRightHolderDetails, string, { rejectValue: string }>(
    'userManagement/getRightHolderDetails',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`/api/IGames/right-holders/details?userId=${id}`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const updateRightHolder = createAsyncThunk<IUserRole, IRightHolderBody, { rejectValue: string }>(
    'userManagement/updateRightHolder',
    async (body, {rejectWithValue}) => {
        try {
            const payload = createFormData(body.payload);
            const {data} = await formClient.put(`/api/IGames/right-holders`, payload)
            toastr.success('IGame', `User has been updated`)
            body.closeForm()
            return data
        } catch (e: any) {
            toastr.error('IGame', `${e.response.data}`)
            return rejectWithValue(e.response.data)
        }
    }
)

export const getOperatorsInitData = createAsyncThunk<IGetOperatorsInitResp, null, { rejectValue: string }>(
    'userManagement/getOperatorsInitData',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`/api/IGames/operators/init`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const addOperator = createAsyncThunk<IUserRole, IOperatorBody, { rejectValue: string }>(
    'userManagement/addOperator',
    async (body, {rejectWithValue}) => {
        try {
            const payload = createFormData(body.payload);
            const {data} = await formClient.post(`/api/IGames/operators`, payload)
            toastr.success('IGame', `User has been added`)
            body.closeForm()
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getOperatorDetails = createAsyncThunk<IGetOperatorDetails, string, { rejectValue: string }>(
    'userManagement/getOperatorDetails',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`/api/IGames/operator/details?userId=${id}`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const updateOperator = createAsyncThunk<IUserRole, IOperatorBody, { rejectValue: string }>(
    'userManagement/updateOperator',
    async (body, {rejectWithValue}) => {
        try {
            const payload = createFormData(body.payload);
            const {data} = await formClient.put(`/api/IGames/operators`, payload)
            toastr.success('IGame', `User has been updated`)
            body.closeForm()
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const deleteRightHolder = createAsyncThunk<IConfirmResp, any, { rejectValue: string }>(
    'userManagement/deleteRightHolder',
    async (userId, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`api/IGames/right-holder?clientId=${userId}`)
            toastr.success('IGame', `Right Holder has been deleted`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const deleteOperator = createAsyncThunk<IConfirmResp, any, { rejectValue: string }>(
    'userManagement/deleteOperator',
    async (userId, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`api/IGames/operator?clientId=${userId}`)
            toastr.success('IGame', `Operator has been deleted`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getRightHolderUsers = createAsyncThunk<IUsersResp, string, { rejectValue: string }>(
    'userManagement/getRightHolderUsers',
    async (clientId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Users/right-holder?clientId=${clientId}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const searchRightHolderUsers = createAsyncThunk<IUsersResp, string, { rejectValue: string }>(
    'userManagement/searchRightHolderUsers',
    async (queriesParameters, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Users/right-holder?${queriesParameters}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getRightHolderPages = createAsyncThunk<IPagesResp, IGetPagesBody, { rejectValue: string }>(
    'userManagement/getRightHolderPages',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Users/right-holder/init?clientId=${body.clientId}&roleId=${body.roleId}`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const addRightHolderUser = createAsyncThunk<IUserItem, IAddUserBody, { rejectValue: string, }>(
    'userManagement/addRightHolderUser',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.post(`api/Users/right-holder`, body.payload)
            toastr.success('IGame', `User has been added`)
            body.closeForm();
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getOperatorUsers = createAsyncThunk<IUsersResp, string, { rejectValue: string }>(
    'userManagement/getOperatorUsers',
    async (clientId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Users/operator?clientId=${clientId}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const searchOperatorUsers = createAsyncThunk<IUsersResp, string, { rejectValue: string }>(
    'userManagement/searchOperatorUsers',
    async (queriesParameters, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Users/operator?${queriesParameters}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getOperatorPages = createAsyncThunk<IPagesResp, IGetPagesBody, { rejectValue: string }>(
    'userManagement/getOperatorPages',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Users/operator/init?clientId=${body.clientId}&roleId=${body.roleId}`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const addOperatorUser = createAsyncThunk<IUserItem, IAddUserBody, { rejectValue: string, }>(
    'userManagement/addOperatorUser',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.post(`api/Users/operator`, body.payload)
            toastr.success('IGame', `User has been added`)
            body.closeForm()
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getUserDetails = createAsyncThunk<IGetUserDetailsResp, string, { rejectValue: string }>(
    'userManagement/getUserDetails',
    async (userId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Users/details?userId=${userId}`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getIGameUsers = createAsyncThunk<IUsersResp, string | null, { rejectValue: string }>(
    'userManagement/getIGameEmails',
    async (searchValue, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Users/iGame${searchValue ? `?searchText=${searchValue}` : ''}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const sortIGameUsers = createAsyncThunk<IUsersResp, string, { rejectValue: string }>(
    'userManagement/sort-IGame-users',
    async (queriesParameters, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Users/iGame?${queriesParameters}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const addIGameUser = createAsyncThunk<IUserItem, IAddIGameUserBody, { rejectValue: string, }>(
    'userManagement/addIGameUser',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.post(`api/Users/iGame`, body.payload)
            toastr.success('IGame', `User has been added`)
            body.closeForm()
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const updateUser = createAsyncThunk<IUserItem, IUpdateIGameUserBody, { rejectValue: string, }>(
    'userManagement/updateUser',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`api/Users/client`, body.payload)
            toastr.success('IGame', `User has been updated`)
            body.closeForm()
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const softDeleteUser = createAsyncThunk<IConfirmResp, IDeleteUserBody, { rejectValue: string }>(
    'userManagement/softDeleteIGameUser',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`api/Users/soft-delete?userId=${body.userId}&reason=${body.reason}`)
            toastr.success('IGame', `User has been deleted`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const hardDeleteUser = createAsyncThunk<IConfirmResp, IDeleteUserBody, { rejectValue: string }>(
    'userManagement/hardDeleteIGameUser',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`api/Users/hard-delete?userId=${body.userId}`)
            toastr.success('IGame', `User has been deleted`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const restoreUser = createAsyncThunk<IConfirmResp, { userId: string, role: string }, { rejectValue: string }>(
    'userManagement/restoreUser',
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`api/Users/restore?userId=${payload.userId}`)
            toastr.success('IGame', `User has been restored`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const userAlert = createAsyncThunk<IConfirmResp, { userId: string, role: string }, { rejectValue: string }>(
    'userManagement/userAlert',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`api/Users/Alert?userId=${body.userId}`);
            toastr.success('IGame', `The alerting mode has been changed`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getIGameCSV = createAsyncThunk<string, null, { rejectValue: string }>(
    'userManagement/get-IGame-CSV',
    async (_, {rejectWithValue, dispatch}) => {
        try {
            const {data, headers} = await client.get(`/api/Users/igame/download`)
            if (headers['content-disposition']) {
                const contentDisposition = headers['content-disposition'];
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    const filename = matches[1].replace(/['"]/g, '');
                    dispatch(setCSVFileName(filename))
                }
            }
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getOperatorsCSV = createAsyncThunk<string, string | null, { rejectValue: string }>(
    'userManagement/get-operators-CSV',
    async (operatorId, {rejectWithValue, dispatch}) => {
        try {
            const {data, headers} = await client.get(`/api/Users/operator/download?operatorId=${operatorId}`)
            if (headers['content-disposition']) {
                const contentDisposition = headers['content-disposition'];
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    const filename = matches[1].replace(/['"]/g, '');
                    dispatch(setCSVFileName(filename))
                }
            }
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)
export const getRightsHolderCSV = createAsyncThunk<string, string | null, { rejectValue: string }>(
    'userManagement/get-rights-holder-CSV',
    async (rightHolderId, {rejectWithValue, dispatch}) => {
        try {
            const {data, headers} = await client.get(`api/Users/right-holder/download?rightHolderId=${rightHolderId}`)
            if (headers['content-disposition']) {
                const contentDisposition = headers['content-disposition'];
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    const filename = matches[1].replace(/['"]/g, '');
                    dispatch(setCSVFileName(filename))
                }
            }
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getTechnicalProviders = createAsyncThunk<IGetTechnicalProvidersResp, string | null, {
    rejectValue: string
}>(
    'userManagement/getTechnicalProviders',
    async (searchValue, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Users/technical-provider${searchValue ? `?searchText=${searchValue}` : ''}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const sortTechnicalProviders = createAsyncThunk<IGetTechnicalProvidersResp, string>(
    'userManagement/sortTechnicalProviders',
    async (queriesParameters, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Users/technical-provider?${queriesParameters}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getTechnicalProvidersInitData = createAsyncThunk<ITechnicalProvidersInitDataResp, string | null, {
    rejectValue: string
}>(
    'userManagement/getTechnicalProvidersInitData',
    async (searchValue, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Users/technical-provider/init`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const addTechnicalProviderUser = createAsyncThunk<ITechnicalProvider, IAddTechnicalProviderBody, {
    rejectValue: string,
}>(
    'userManagement/addTechnicalProviderUser',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.post(`api/Users/technical-provider`, body.payload)
            toastr.success('IGame', `Technical Provider has been added`)
            body.closeForm()
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getTechnicalProviderDetails = createAsyncThunk<IGetTechnicalProviderDetailsResp, string, {
    rejectValue: string
}>(
    'userManagement/getTechnicalProviderDetails',
    async (userId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Users/technical-provider/details?userId=${userId}`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const updateTechnicalProvider = createAsyncThunk<ITechnicalProvider, IUpdateIProviderBody, {
    rejectValue: string,
}>(
    'userManagement/updateTechnicalProvider',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`api/Users/technical-provider`, body.payload)
            toastr.success('IGame', `Technical Provider has been updated`)
            body.closeForm()
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getTechnicalProviderCSV = createAsyncThunk<string, null, { rejectValue: string }>(
    'userManagement/get-technical-provider-CSV',
    async (_, {rejectWithValue, dispatch}) => {
        try {
            const {data, headers} = await client.get(`api/Users/technical-provider/download`)
            if (headers['content-disposition']) {
                const contentDisposition = headers['content-disposition'];
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    const filename = matches[1].replace(/['"]/g, '');
                    dispatch(setCSVFileName(filename))
                }
            }
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const checkIsCustomerAvailable = createAsyncThunk<boolean, ICheckCustomerBody, {
    rejectValue: string,
}>(
    'userManagement/checkIsCustomerAvailable',
    async ({customer, setInFormData, stepOneFormData}, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/IGames/customer/${customer!.value}/is-available`)
            if (data) {
                setInFormData({...stepOneFormData, customerId: customer})
            } else {
                toastr.error('IGame', `Customer is already linked to another operator`);
                setInFormData({...stepOneFormData, customerId: undefined})

            }
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const userManagementSlice = createSlice({
    name: 'iGameUserManagement',
    reducers: {
        clearCSVFile: (state) => {
            state.csvFile = undefined
        },
        setCSVFileName: (state, action: PayloadAction<string>) => {
            state.csvFileName = action.payload
        },
        clearUserDetails: (state) => {
            state.userDetails = undefined;
        },
        clearRightHolderDetails: (state) => {
            state.rightsHolderDetails = undefined;
        },
        clearOperatorDetails: (state) => {
            state.currentOperator = undefined;
        },
        clearTechnicalProviderDetails: (state) => {
            state.technicalProviderDetails = undefined;
        },
        clearTableContents: (state, action: PayloadAction<string>) => {
            const clientType: string = action.payload;
            state[clientType] = null;
        },
        clearTechnicalProviders: (state) => {
            state.technicalProviders = null;
        },
        clearUsers: (state) => {
            state.users = null;
        },
    },
    initialState: iGameUserManagementState,
    extraReducers: (builder) => {
        builder
            .addCase(getIGameCSV.fulfilled, (state, action) => {
                state.csvFile = action.payload
            })
            .addCase(getOperatorsCSV.fulfilled, (state, action) => {
                state.csvFile = action.payload
            })
            .addCase(getRightsHolderCSV.fulfilled, (state, action) => {
                state.csvFile = action.payload
            })
            .addCase(getUserType.pending, (state) => {
                state.loading = true
            })
            .addCase(getUserType.fulfilled, (state, action) => {
                state.loading = false
                state.rightHolders = action.payload.rightHolders;
                state.rightHoldersCount = action.payload.rightHoldersCount;
                state.operators = action.payload.operators;
                state.operatorsCount = action.payload.operatorsCount
            })
            .addCase(getRightHoldersInitData.fulfilled, (state, action) => {
                state.dataProviders = action.payload.dataProviders.items;
                state.dataProvidersInUse = action.payload.dataProvidersInUse.items;
                state.existingEmails = action.payload.existingEmails
                state.pageVisibilities = action.payload.pageVisibilities.items;
                state.pricings = action.payload.pricings.items;
                state.roles = action.payload.roles.items;
            })
            .addCase(getRightHolderDetails.pending, (state) => {
                state.loading = true
            })
            .addCase(getRightHolderDetails.fulfilled, (state, action) => {
                state.loading = false
                state.rightsHolderDetails = action.payload;
            })
            .addCase(addRightHolder.pending, (state) => {
                state.isUserCreation = true;
            })
            .addCase(addRightHolder.fulfilled, (state, action) => {
                state.isUserCreation = false;
                state.rightHolders.push(action.payload)
            })
            .addCase(updateRightHolder.fulfilled, (state, action) => {
                state.rightHolders = state.rightHolders.map(rightHolder => {
                    if (rightHolder.id === action.payload.id) {
                        rightHolder = action.payload
                    }
                    return rightHolder
                })
            })
            .addCase(getOperatorsInitData.fulfilled, (state, action) => {
                state.rightHoldersInit = action.payload.rightHolders.items;
                state.customers = action.payload.customers.items;
                state.territories = action.payload.territories.items;
                state.operatorVisibilities = action.payload.operatorVisibilities.items
                state.roles = action.payload.roles.items
                state.customersInUse = action.payload.customersInUse
                state.existingEmails = action.payload.existingEmails
            })
            .addCase(addOperator.pending, (state) => {
                state.isUserCreation = true;
            })
            .addCase(addOperator.fulfilled, (state, action) => {
                state.isUserCreation = false;
                state.operators.push(action.payload)
            })
            .addCase(getOperatorDetails.pending, (state) => {
                state.loading = false
            })
            .addCase(updateOperator.fulfilled, (state, action) => {
                state.operators = state.operators.map(operator => {
                    if (operator.id === action.payload.id) {
                        operator = action.payload
                    }
                    return operator
                })
            })
            .addCase(getOperatorDetails.fulfilled, (state, action) => {
                state.loading = false
                state.currentOperator = action.payload
            })
            .addCase(deleteRightHolder.fulfilled, (state, {meta}) => {
                state.rightHolders = state.rightHolders.filter(item => item.id !== meta.arg)
            })
            .addCase(deleteOperator.fulfilled, (state, {meta}) => {
                state.operators = state.operators.filter(item => item.id !== meta.arg)
            })
            .addCase(getRightHolderUsers.pending, (state) => {
                state.loading = true
            })
            .addCase(getRightHolderUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.users = action.payload.users;
                state.clientName = action.payload.clientName;
                state.clientId = action.payload.clientId
            })
            .addCase(searchRightHolderUsers.fulfilled, (state, action) => {
                state.users = action.payload.users;
                state.clientName = action.payload.clientName;
                state.clientId = action.payload.clientId
            })
            .addCase(getRightHolderPages.pending, (state) => {
                state.pagesLoading = true;
            })
            .addCase(getRightHolderPages.fulfilled, (state, action) => {
                state.pagesLoading = false;
                state.pages = action.payload.pages.items;
            })
            .addCase(addRightHolderUser.fulfilled, (state, action) => {
                if (state.users) {
                    state.users.push(action.payload);
                }
            })
            .addCase(getOperatorUsers.pending, (state) => {
                state.loading = true
            })
            .addCase(getOperatorUsers.fulfilled, (state, action) => {
                state.loading = false
                state.users = action.payload.users
                state.clientName = action.payload.clientName
                state.clientId = action.payload.clientId
            })
            .addCase(searchOperatorUsers.fulfilled, (state, action) => {
                state.users = action.payload.users
                state.clientName = action.payload.clientName
                state.clientId = action.payload.clientId
            })
            .addCase(getOperatorPages.pending, (state) => {
                state.pagesLoading = true;
            })
            .addCase(getOperatorPages.fulfilled, (state, action) => {
                state.pagesLoading = false;
                state.pages = action.payload.pages.items;
            })
            .addCase(addOperatorUser.fulfilled, (state, action) => {
                if (state.users) {
                    state.users.push(action.payload);
                }
            })
            .addCase(getUserDetails.fulfilled, (state, action) => {
                state.userDetails = action.payload;
                state.currentUserPages = action.payload.pages.items
            })
            .addCase(getIGameUsers.pending, (state) => {
                state.loading = true
            })
            .addCase(getIGameUsers.fulfilled, (state, action) => {
                state.loading = false
                state.users = action.payload.users;
                state.clientId = action.payload.clientId;
                state.clientName = action.payload.clientName;
            })
            .addCase(sortIGameUsers.fulfilled, (state, action) => {
                state.users = action.payload.users;
                state.clientId = action.payload.clientId;
                state.clientName = action.payload.clientName;
            })
            .addCase(addIGameUser.fulfilled, (state, action) => {
                if (state.users) {
                    state.users.push(action.payload);
                }
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                if (state.users) {
                    if (state.users.find(user => user.id === action.meta.arg.payload.userId)) {
                        state.users = state.users.map(user => {
                            if (user.id === action.meta.arg.payload.userId) {
                                user = action.payload;
                            }
                            return user
                        })
                    }
                }
            })
            .addCase(softDeleteUser.fulfilled, (state, {meta: {arg}}) => {
                if (arg.role === 'Technical Providers' && state.technicalProviders) {
                    state.technicalProviders.forEach(user => {
                        if (user.id === arg.userId) {
                            user.isDeleted = true
                        }
                    })
                } else if (state.users) {
                    state.users.forEach(user => {
                        if (user.id === arg.userId) {
                            user.isDeleted = true
                        }
                    })
                }
            })
            .addCase(hardDeleteUser.fulfilled, (state, {meta: {arg}}) => {
                if (arg.role === 'Technical Providers' && state.technicalProviders) {
                    state.technicalProviders = state.technicalProviders.filter(user => user.id !== arg.userId);
                } else if (state.users) {
                    state.users = state.users.filter(user => user.id !== arg.userId);
                }
            })
            .addCase(restoreUser.fulfilled, (state, {meta: {arg}}) => {
                if (arg.role === 'Technical Providers' && state.technicalProviders) {
                    state.technicalProviders.forEach(user => {
                        if (user.id === arg.userId) user.isDeleted = false;
                    })
                } else {
                    state.users && state.users.forEach(user => {
                        if (user.id === arg.userId) user.isDeleted = false;
                    })
                }
            })
            .addCase(userAlert.fulfilled, (state, {meta: {arg}}) => {
                if (arg.role === 'Technical Providers' && state.technicalProviders) {
                    state.technicalProviders.forEach(user => {
                        if (user.id === arg.userId) user.isAlertTicked = !user.isAlertTicked;
                    })
                } else {
                    state.users && state.users.forEach(user => {
                        if (user.id === arg.userId) user.isAlertTicked = !user.isAlertTicked;
                    })
                }
            })
            .addCase(getTechnicalProviders.pending, (state) => {
                state.loading = true;
            })
            .addCase(getTechnicalProviders.fulfilled, (state, action) => {
                state.technicalProviders = action.payload.users;
                state.loading = false;
            })
            .addCase(sortTechnicalProviders.fulfilled, (state, action) => {
                state.technicalProviders = action.payload.users
            })
            .addCase(getTechnicalProvidersInitData.fulfilled, (state, action) => {
                state.technicalProvidersInitData = action.payload.items;
            })
            .addCase(addTechnicalProviderUser.fulfilled, (state, action) => {
                if (state.technicalProviders) {
                    state.technicalProviders.push(action.payload)
                }
            })
            .addCase(getTechnicalProviderDetails.fulfilled, (state, action) => {
                state.technicalProviderDetails = action.payload;
            })
            .addCase(updateTechnicalProvider.fulfilled, (state, action) => {
                if (state.technicalProviders) {
                    state.technicalProviders = state.technicalProviders.map(technicalProvider => {
                        if (technicalProvider.id === action.payload.id) {
                            technicalProvider = action.payload
                        }
                        return technicalProvider
                    })
                }
            })
            .addCase(getTechnicalProviderCSV.fulfilled, (state, action) => {
                state.csvFile = action.payload
            })
            .addCase(checkIsCustomerAvailable.pending, (state) => {
                state.isCustomerStatusUpdating = true
            })
            .addCase(checkIsCustomerAvailable.fulfilled, (state, action) => {
                state.isCustomerStatusUpdating = false
                state.isCustomerAvailable = action.payload
            })
            .addMatcher(isError, (state, action: PayloadAction<string>) => {
                state.error = action.payload;
            })
    }
})
export const {
    clearCSVFile,
    setCSVFileName,
    clearUserDetails,
    clearRightHolderDetails,
    clearOperatorDetails,
    clearTechnicalProviderDetails,
    clearTableContents,
    clearTechnicalProviders,
    clearUsers
} = userManagementSlice.actions
export default userManagementSlice.reducer

function isError(action: AnyAction) {
    return action.type.endsWith("rejected");
}
