import React, {useEffect} from 'react';
import styles from "../../index.module.scss";
import {IngestFormController, IngestOptionId, IngestTabs} from "@enums/ingest";
import {FieldErrors, FieldValues, UseFormSetValue} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "@hooks/hooks";
import {RootState} from "@store/index";
import {hasNestedObjectData} from "@helpers/hasNestedObjectData";
import IngestDetails
    from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/children/IngestForm/helpers/IngestDetails";
import {getIpChannels} from "@store/init/initSlice";

export interface IGenerateTabs {
    setCurrentTabState: React.Dispatch<React.SetStateAction<IngestTabs>>;
    setValue: UseFormSetValue<FieldValues>;
    currentTabState: IngestTabs;
    errors: FieldErrors;
}
const IngestFormTabs: React.FC<IGenerateTabs> = (
    {
        setCurrentTabState,
        currentTabState,
        setValue,
        errors
    }) => {

    const dispatch = useAppDispatch();
    const {
        details,
        backupDetails,
        isActivateBackup,
    } = useAppSelector((state: RootState) => state.rightHolderBooking);
    const channels = useAppSelector((state: RootState) => state.initInfo.ipChannels);

    const cleanFormData = (ingestTab: IngestTabs) => {
        if (ingestTab === IngestTabs.Primary) {
            setValue(IngestFormController.PrimaryMethod, null)
            setValue(IngestFormController.PrimarySource, null)
            setValue(IngestFormController.PrimaryIPChannels, null)
            return setValue(IngestFormController.IGameChannels, null)
        }
        setValue(IngestFormController.BackupMethod, null)
        setValue(IngestFormController.BackupSource, null)
        setValue(IngestFormController.BackupIPChannels, null)
        setValue(IngestFormController.BackupIGameChannels, null)
    }

    const setDetails = (ingestTab: IngestTabs) => {
        if (ingestTab === IngestTabs.BackUP) {
            if (backupDetails.btTowerFixture) {
                return setValue(IngestFormController.BackupSource, backupDetails.btTowerFixture.source);
            }
            if (backupDetails.ipFixture) {
                return IngestDetails.setBackupIPChannels(backupDetails, channels, setValue)
            }
        }

        if (ingestTab === IngestTabs.Primary) {
            if (details.ip) {
                IngestDetails.setPrimaryIpChannel(details, channels, setValue)
            }
            if (details.btTower) {
                setValue(IngestFormController.PrimarySource, details?.btTower?.source);
            }
        }
    }

    const fetchChannels = (fixtureId: string, ingestType: any) => {
        if (fixtureId) {
            dispatch(getIpChannels({fixtureId, ingestType}));
        }
    }

    const detailsHandler = (tabState: IngestTabs) => {
        setCurrentTabState(tabState);
        if (!hasNestedObjectData(details) && !hasNestedObjectData(backupDetails)) {
            return
        }
        if (tabState === IngestTabs.BackUP) {
            if (!hasNestedObjectData(backupDetails)) {
                return cleanFormData(IngestTabs.BackUP);
            }
            return setDetails(IngestTabs.BackUP)
        }

        if (tabState === IngestTabs.Primary) {
            if (!hasNestedObjectData(details)) {
                return cleanFormData(IngestTabs.Primary)
            }
            return setDetails(IngestTabs.Primary)
        }
    };

    const tabHandler = (tab: IngestTabs) => {
        setCurrentTabState(tab)
        if (tab === IngestTabs.Primary) {
            if (details.ip) {
                return fetchChannels(details.ip.fixtureId, IngestOptionId.IP)
            }
            if (details.btTower) {
                return fetchChannels(details.btTower.fixtureId, IngestOptionId.BTTower)
            }
            if (details.satellite) {
                return fetchChannels(details.satellite.fixtureId, IngestOptionId.Satellite)
            }
        }
        if (tab === IngestTabs.BackUP) {
            if (backupDetails.ipFixture) {
                return fetchChannels(backupDetails.ipFixture.fixtureId, IngestOptionId.IP)
            }
            if (backupDetails.btTowerFixture) {
                return fetchChannels(backupDetails.btTowerFixture.fixtureId, IngestOptionId.BTTower)
            }
            if (backupDetails.satelliteFixture) {
                return fetchChannels(backupDetails.satelliteFixture.fixtureId, IngestOptionId.Satellite)
            }
        }
    }

    useEffect(() => {
        detailsHandler(currentTabState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details, backupDetails, currentTabState]);
    return (
        <div className={styles.ingestTabs}>
            <button
                type="button"
                onClick={() => tabHandler(IngestTabs.Primary)}
                className={currentTabState === IngestTabs.Primary ? `${styles.ingestTab} ${styles.activeTab}` : styles.ingestTab}
            >
                Primary Ingest
            </button>

            <button
                disabled={false}
                type="button"
                onClick={() => tabHandler(IngestTabs.BackUP)}
                className={currentTabState === IngestTabs.BackUP ? `${styles.ingestTab} ${styles.activeTab}` : styles.ingestTab}
            >
                Back-up Ingest
            </button>

            <div className={styles.currentIndicator} style={{left: isActivateBackup ? '60%' : '70px'}}/>
        </div>
    );
};

export default IngestFormTabs;
