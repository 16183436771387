import React, {useEffect, useState} from "react";
import styles from "../../index.module.scss";
import NoDataFoundStub from "../../../../../../components/NoDataFoundStub/NoDataFoundStub";
import {IFixtureByDayAndTime} from "@store/propertyDetail/propertyDetail";
import uuid from "react-uuid";

export interface IDayTableProps {
    data: IFixtureByDayAndTime[];
    assignCellColor: (property: string, value: number, values: number[]) => string;
}

const DayTable: React.FunctionComponent<IDayTableProps> = ({data, assignCellColor}) => {
    const [values, setValues] = useState<number[]>([]);

    useEffect(() => {
        const tableValues = data.map(item => item.fixturesPercentage);
        setValues(tableValues);
    }, [data]);

    return (
        <div className={styles.dayTableOuterContainer}>
            <h5 className={styles.tableTitle}>
                Fixtures by day: Percentage and Number of Events
            </h5>
            {
                !data.length &&
                <NoDataFoundStub textColor={"white"}/>
            }
            {
                !!data.length &&
                <div className={styles.dayTableWrapper}>
                    <table className={styles.dayTable}>
                        <thead>
                        <tr className={styles.headerRow}>
                            <th className={styles.headerCell}>Weekday</th>
                            <th className={styles.headerCell}>Start Time (UTC)</th>
                            <th className={styles.headerCell}>Total Events</th>
                            <th className={styles.headerCell}>% of Fixtures</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.map((item) => (
                            <tr className={styles.bodyRow}
                                style={{backgroundColor: assignCellColor("fixturesPercentage", item.fixturesPercentage, values)}}
                                key={uuid()}>
                                <td className={styles.bodyCell}>{item.weekDay}</td>
                                <td className={styles.bodyCell}>{item.startTimeUtc}</td>
                                <td className={styles.bodyCell}>{item.totalEvents}</td>
                                <td className={styles.bodyCell}>{item.fixturesPercentage}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )
}

export default DayTable;
