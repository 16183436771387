import React from "react";
import styles from "./index.module.scss";
import {BsArrowDown, BsArrowUp} from "react-icons/bs";
import {ISorting} from "../OperatorDefaultTable/OperatorDefaultTable";

export interface IOperatorSortingHeaderProps {
    sorting: ISorting | null | undefined;
    setSorting: ((value: ISorting) => void) | undefined;
    columnsData: IColumnItem[]
}

export interface IColumnItem {
    title: string;
    criteria: string | null;
    key: string;
    favorite?: boolean
    isPropertyFavourite?: boolean,
    textAlign?: "left" | "right" | "center" | "justify";
}

const OperatorSortingHeader: React.FunctionComponent<IOperatorSortingHeaderProps> = (
    {
        sorting,
        setSorting,
        columnsData
    }) => {

    const handleSorting = (criteria: any): void => {
        if (sorting && setSorting) {
            if (criteria === sorting.property) {
                setSorting({
                    property: criteria,
                    isAscending: !sorting!.isAscending
                })
            } else {
                setSorting({
                    property: criteria,
                    isAscending: true
                })
            }
        } else {
            return;
        }
    }

    return (
        <thead>
        <tr>
            {
                columnsData.map((column, index) => {
                    if (column.criteria === null) {
                        return (
                            <th
                                style={{textAlign: column.textAlign}}
                                key={index}
                                data-testid={`test-${column.key}`}
                            >
                                {column.title}
                            </th>
                        )
                    } else {
                        return (
                            <th
                                style={{textAlign: column.textAlign}}
                                className={
                                    sorting && sorting.property === column.criteria
                                        ? styles.headerClickableSelected
                                        : styles.headerClickable}
                                onClick={() => handleSorting(column.criteria)}
                                data-testid={`test-${column.key}`}
                                key={index}>
                                {column.title}
                                {
                                    sorting && sorting.property === column.criteria
                                        ? sorting && sorting.isAscending ? <BsArrowUp/> : <BsArrowDown/>
                                        : null
                                }
                            </th>
                        )
                    }
                })
            }
        </tr>
        </thead>
    )
}

export default OperatorSortingHeader;
