import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {client} from "@services/api-servise";
import {AxiosError} from "axios";
import {toastr} from "react-redux-toastr";
import {appendDataHelper, prependDataHelper} from "@helpers/reducerHelpers";

export type Items = {
    id: string | number;
    name: string;
}

export interface IDropDownItems {
    items: Items[];
}

export interface ISearchPayload {
    searchText?: string;
    eventStartTime?: string;
    pageNumber: number;
    itemsPerPage: number;
    scrollPosition?: string;
}

export interface ISearchAffectedPayload {
    searchText?: string;
    eventStartTime?: string;
}

export interface IFixtures {
    fixtureId: string;
    startTime: string;
    endTime: string;
    title: string;
}

export interface IGetFixturesResponse {
    records: IFixtures[];
    totalCount: 0;
    nextRecommendedEndDate: string;
}

export interface IOpenGeneralIssue {
    id: string;
    issueSource: string;
    issueDescription: string;
    detail: string;
    rightHolders: string;
    competitions: string;
    technicalProviders: string;
}

export interface IAffectedEvent {
    id: string;
    fixtureId: string;
    startTime: string;
    title: string;
    reportId: string;
    isSelected: boolean;
}

export interface IInitData {
    faults: IDropDownItems;
    details: IDropDownItems;
    affectedPlatforms: IDropDownItems;
    rightHolders: IDropDownItems;
    contentSuppliers: IDropDownItems;
    protocols: IDropDownItems;
    openGeneralIssues: IOpenGeneralIssue[];
    openGeneralIssuesCount: 0;
}

export interface IGeneralIssue {
    id?: string;
    issueDescription: string;
    issueStartTime: string | null;
    issueEndTime?: string | null;
    fault: number;
    platform: number;
    streamType: number;
    rightHolders: string[];
    contentSuppliers: string[];
    selectedEvents?: string[];
    unresolved: boolean;
    eventsLatestEndDate?: string | null;
    severity: number;
    detail: number;
}

interface IGeneralIssuesState {
    initData: IInitData;
    ongoingGeneralIssues: IOpenGeneralIssue[];
    ongoingIssueDetails: IGeneralIssue | null;
    affectedEvents: IAffectedEvent[];
    fixtures: IFixtures[] | null;
    selectedFixtures: IFixtures[] | null;
    pageNumber: number;
    itemsPerPage: number;
    totalCount: number;
    maxItemsInTheTable: number;
    scrollLoader: boolean;
}

const initialState: IGeneralIssuesState = {
    initData: {
        faults: {
            items: [{id: "", name: ""}]
        },
        details: {
            items: [{id: 0, name: ""}]
        },
        affectedPlatforms: {
            items: [{id: "", name: ""}]
        },
        rightHolders: {
            items: [{id: "", name: ""}]
        },
        contentSuppliers: {
            items: [{id: "", name: ""}]
        },
        protocols: {
            items: [{id: "", name: ""}]
        },
        openGeneralIssues: [{
            id: '',
            issueSource: '',
            issueDescription: '',
            detail: '',
            rightHolders: '',
            competitions: '',
            technicalProviders: ''
        }],
        openGeneralIssuesCount: 0
    },
    ongoingGeneralIssues: [{
        id: '',
        issueSource: '',
        issueDescription: '',
        detail: '',
        rightHolders: '',
        competitions: '',
        technicalProviders: ''
    }],
    ongoingIssueDetails: {} as IGeneralIssue,
    affectedEvents: [{
        id: '',
        fixtureId: '',
        startTime: '',
        title: '',
        reportId: '',
        isSelected: true,

    }],
    fixtures: null,
    selectedFixtures: [],
    pageNumber: 1,
    itemsPerPage: 15,
    totalCount: 0,
    maxItemsInTheTable: 45,
    scrollLoader: false,
};

export const createGeneralIssue = createAsyncThunk<IInitData, IGeneralIssue>(
    'reporting-general-issues/post',
    async (payload) => {
        try {
            const {data} = await client.post(`/api/Reports/general-issues`, payload);
            toastr.success('IGame', 'General issue created')
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                return toastr.error('IGame', `${e.response?.data}`);
            }
        }
    }
);

export const editGeneralIssue = createAsyncThunk<IInitData, IGeneralIssue>(
    'reporting-general-issues/put',
    async (payload) => {
        try {
            const {data} = await client.put(`/api/Reports/general-issues`, payload);
            toastr.success('IGame', 'General issue updated')
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                return toastr.error('IGame', `${e.response?.data}`);
            }
        }
    }
);

export const getOneGeneralIssue = createAsyncThunk<IGeneralIssue, string>(
    'reporting-general-issues/select_details',
    async (id) => {
        try {
            const {data} = await client.get(`/api/Reports/general-issues/details?reportId=${id}`);
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                return toastr.error('IGame', `${e.response?.data}`);
            }
        }
    }
);

export const getGeneralIssuesInit = createAsyncThunk<IInitData>(
    'reporting-general-issues/init',
    async () => {
        try {
            const {data} = await client.get(`/api/Reports/general-issues/init`);
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                return toastr.error('IGame', `${e.response?.data}`);
            }
        }
    }
);

export const getDetails = createAsyncThunk<IDropDownItems, number, { rejectValue: string }>(
    'reporting/get-issue-details',
    async (issueSource, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`/api/Reports/issue-source/${issueSource}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getFixturesInGeneralIssues = createAsyncThunk<IGetFixturesResponse, ISearchPayload, { rejectValue: string }>(
    'reporting-general-issues/search',
    async ({
               searchText,
               eventStartTime,
               pageNumber,
               itemsPerPage,
               scrollPosition
           }, {rejectWithValue, dispatch}) => {
        if (searchText?.trim() === '') {
            return dispatch(clearFixtures())
        }
        let api: string = `api/Reports/general-issues/search?searchText=${searchText}&pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`;
        if (eventStartTime) {
            api = `api/Reports/general-issues/search?searchText=${searchText}&eventStartTime=${eventStartTime}&pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`;
        }
        try {
            const {data, headers} = await client.get(api);
            if (!data.records.length) {
                toastr.info('IGame', `There are no available events.`)
            }
            const totalCount = headers['x-total-count']
            if (totalCount) {
                dispatch(setTotalCount(Number(totalCount)));
            }
            if (scrollPosition && scrollPosition === "down") {
                dispatch(appendData(data));
                return;
            }
            if (scrollPosition && scrollPosition === "up") {
                dispatch(prependData(data));
                return;
            }
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getOngoingGeneralIssues = createAsyncThunk<IOpenGeneralIssue[]>(
    'reporting-general-issues/ongoing-issues',
    async () => {
        try {
            const {data} = await client.get(`api/Reports/general-issues/open`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                return toastr.error('IGame', `${e.response?.data}`)
            }
        }
    }
)

export const getAffectedEvents = createAsyncThunk<IAffectedEvent[], ISearchAffectedPayload>(
    'reporting-general-issues/get-affected-events',
    async (payload) => {
        const {searchText, eventStartTime} = payload;
        try {
            const {data} = await client.get(`api/Reports/general-issues/affected-events?searchText=${searchText}&eventStartTime=${eventStartTime}`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                return toastr.error('IGame', `${e.response?.data}`)
            }
        }
    }
)

export const reportingGeneralIssuesSlice = createSlice({
    name: 'reporting-general-issues',
    initialState,
    reducers: {
        clearFixtures: (state) => {
            state.fixtures = initialState.fixtures;
        },
        clearSelectedFixtures: (state) => {
            state.selectedFixtures = initialState.selectedFixtures;
        },
        selectFixture: (state, action: PayloadAction<IFixtures>) => {
            const isSelected = state.selectedFixtures?.some(f => f.fixtureId === action.payload.fixtureId);
            if (isSelected) {
                state.selectedFixtures = state.selectedFixtures?.filter(f => f.fixtureId !== action.payload.fixtureId)!;
            } else {
                state.selectedFixtures = [...state.selectedFixtures!, action.payload];
            }
        },
        unselectFixture: (state, action: PayloadAction<string>) => {
            state.selectedFixtures = state.selectedFixtures?.filter(f => f.fixtureId !== action.payload)!;
        },
        clearIssueDetails: (state) => {
            state.ongoingIssueDetails = null;
            state.ongoingGeneralIssues = [];
        },
        setPageNumber: (state, action: PayloadAction<number>) => {
            state.pageNumber = action.payload
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload
        },
        appendData(state, action) {
            appendDataHelper(state, action.payload, 'fixtures');
        },
        prependData(state, action) {
            prependDataHelper(state, action.payload, 'fixtures');
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getGeneralIssuesInit.fulfilled, (state, action) => {
                state.initData = action.payload;
            })
            .addCase(getFixturesInGeneralIssues.fulfilled, (state, action) => {
                if (action.payload !== undefined) {
                    state.fixtures = action.payload.records;
                    state.pageNumber = 1;
                }
            })
            .addCase(getOngoingGeneralIssues.fulfilled, (state, action) => {
                state.ongoingGeneralIssues = action.payload;
            })
            .addCase(getOneGeneralIssue.fulfilled, (state, action) => {
                state.ongoingIssueDetails = action.payload;
            })
            .addCase(getAffectedEvents.fulfilled, (state, action) => {
                state.affectedEvents = action.payload;
            })
            .addCase(getDetails.fulfilled, (state, action) => {
                state.initData.details = action.payload;
            })
    },
})

export const {
    clearSelectedFixtures,
    clearFixtures,
    selectFixture,
    clearIssueDetails,
    setTotalCount,
    setPageNumber,
    appendData,
    prependData
} = reportingGeneralIssuesSlice.actions;
export default reportingGeneralIssuesSlice.reducer;
