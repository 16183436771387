import React, {useEffect, useState} from 'react';
import styles from "./index.module.scss";
import {RiDeleteBinLine} from "react-icons/ri";
import {BiEditAlt, BiEnvelope} from "react-icons/bi";
import {IoRefreshOutline} from "react-icons/io5";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/hooks";
import ConfirmModal from "../../../../../../components/ConfirmModal/ConfirmModal";
import DeleteUserConfirmation from "../../../../../../components/DeleteUserConfirmation/DeleteUserConfirmation";
import {
    getUserDetails, resetUserPassword, restoreUser, userAlert
} from "../../../../../../store/settings/settingsSlice";
import SettingsForm from "../SettingsForm/SettingsForm";
import Checkbox from "../../../../../../components/Checkbox";
import {InnerRole} from "../../../../../../enums/innerRole";
import moment from "moment";
import {Tooltip} from "react-tooltip";
import AppTableWhite from "../../../../../../components/AppTableWhite/AppTableWhite";
import NoDataFoundStub from "../../../../../../components/NoDataFoundStub/NoDataFoundStub";
import {HiOutlineEnvelope} from "react-icons/hi2";

export interface ISettingsTableProps {
    roleName: string;
}

const SettingsTable: React.FC<ISettingsTableProps> = React.memo(({roleName}) => {
    const dispatch = useAppDispatch();
    const [currentUserRole, setCurrentUserRole] = useState<string | undefined>(undefined);
    const [isOpenEditForm, setIsOpenEditForm] = useState<boolean>(false);
    const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = useState(false);
    const [deletingUserId, setDeletingUserId] = useState<string | undefined>(undefined);
    const [emailForResetPassword, setEmailForResetPassword] = useState<string | undefined>(undefined);
    const [isOpenSendEmailConfirm, setIsOpenSendEmailConfirm] = useState(false);
    const {users, userDetails} = useAppSelector(state => state.settings);
    const {role, specificRole} = useAppSelector(state => state.userInfo);

    useEffect(() => {
        if (role) {
            setCurrentUserRole(role)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const closeForm = () => {
        if (isOpenEditForm) setIsOpenEditForm(false);
    };

    const openConfirmModal = (id: string) => {
        setDeletingUserId(id);
        setIsOpenDeleteConfirm(true);
    }

    const editUserHandler = (id: string) => {
        dispatch(getUserDetails(id))
        setIsOpenEditForm(true);
    }

    const resetPasswordHandler = () => {
        if (emailForResetPassword) {
            const resetData = {
                email: emailForResetPassword
            }
            dispatch(resetUserPassword(resetData));
        }
        setEmailForResetPassword(undefined);
    }

    const restoreUserHandler = (id: string) => {
        dispatch(restoreUser(id));
    }

    const handleCheckbox = (userId: string) => {
        dispatch(userAlert(userId));
    }

    const getColStyle = (isUserDeleted: boolean) => {
        return isUserDeleted ? styles.deletedCol : ''
    }

    return (
        <div className={styles.tableWrapper} data-testid="settings-table">
            {isOpenEditForm &&
                <SettingsForm
                    closeForm={closeForm}
                    userDetails={userDetails}
                    roleName={roleName}
                />}

            {isOpenSendEmailConfirm && <ConfirmModal
                title={'Reset Password'}
                message={'Send the current user a reset password link?'}
                closeForm={() => setIsOpenSendEmailConfirm(false)}
                action={resetPasswordHandler}>
                <HiOutlineEnvelope color={'#FFFFFF'} size={100}/>
            </ConfirmModal>}

            {isOpenDeleteConfirm && <DeleteUserConfirmation
                closeConfirmModal={() => setIsOpenDeleteConfirm(false)}
                deletingUserId={deletingUserId}
            />}
            <AppTableWhite>
                <thead>
                <tr>
                    <th>Email</th>
                    <th>Last Login Date</th>
                    <th>Role</th>
                    <th className={styles.actionsTitle}>Actions</th>
                </tr>
                </thead>

                <tbody>
                {React.useMemo(() => {
                    if (users.length) return users.map((user) => (
                        <tr key={user.id} className={styles.tbodyRow}>
                            <td className={getColStyle(user.isDeleted)}
                                data-testid={user.isDeleted ? "user-cell-deleted" : "user-cell"}>{user.email}</td>
                            <td className={getColStyle(user.isDeleted)}
                                data-testid={user.isDeleted ? "user-cell-deleted" : "user-cell"}>
                                {user.lastLoginDate && moment(user.lastLoginDate).format("YYYY-MM-DD HH:mm")}
                            </td>
                            <td className={getColStyle(user.isDeleted)}
                                data-testid={user.isDeleted ? "user-cell-deleted" : "user-cell"}>{user.roleName === "SuperAdmin" ? "Super Admin" : user.roleName}</td>
                            <td className={getColStyle(user.isDeleted)}
                                data-testid={user.isDeleted ? "user-cell-deleted" : "user-cell"}>
                                <div className={styles.actionsIcons}>
                                    {
                                        specificRole === InnerRole.SuperAdmin &&
                                        <>
                                            <div onClick={() => {
                                                if (user.isDeleted) return;
                                                return openConfirmModal(user.id);
                                            }}
                                                 data-testid="delete-user-button"
                                            >
                                                <Tooltip className={styles.tooltip} id="delete-tooltip"/>
                                                <RiDeleteBinLine
                                                    className={user.isDeleted ?
                                                        styles.disabledActionBtn : styles.actionBtn}
                                                    data-tooltip-id="delete-tooltip"
                                                    data-tooltip-content={"Delete"}
                                                />
                                            </div>

                                            <div onClick={() => {
                                                if (user.isDeleted) return;
                                                return editUserHandler(user.id);
                                            }}
                                                 data-testid="edit-user-button"
                                            >
                                                <Tooltip className={styles.tooltip} id="update-tooltip"/>
                                                <BiEditAlt
                                                    className={user.isDeleted ?
                                                        styles.disabledActionBtn : styles.actionBtn}
                                                    data-tooltip-id="update-tooltip"
                                                    data-tooltip-content={"Update"}
                                                />
                                            </div>
                                        </>
                                    }
                                    <div onClick={() => {
                                        if (user.isDeleted) return;
                                        setIsOpenSendEmailConfirm(true);
                                        setEmailForResetPassword(user.email);
                                    }}
                                         data-testid="reset-password-button"
                                    >

                                        <Tooltip
                                            className={styles.tooltip}
                                            id="send-email-tooltip"
                                        />
                                        <BiEnvelope
                                            className={user.isDeleted ?
                                                styles.disabledActionBtn : styles.actionBtn}
                                            data-tooltip-id="send-email-tooltip"
                                            data-tooltip-content={"Reset password"
                                            }
                                        />
                                    </div>
                                    {
                                        specificRole === InnerRole.SuperAdmin &&
                                        <div onClick={() => {
                                            if (!user.isDeleted) return
                                            restoreUserHandler(user.id);
                                        }}
                                             data-testid="restore-user-button"
                                        >
                                            <Tooltip className={styles.tooltip} id="restore-tooltip"/>
                                            <IoRefreshOutline
                                                data-tooltip-id="restore-tooltip"
                                                data-tooltip-content={"Restore"}
                                                className={user.isDeleted ?
                                                    styles.disabledActionBtn : styles.actionBtn}
                                            />
                                        </div>
                                    }
                                    {currentUserRole !== 'Operator' ?
                                        <div data-testid="alert-mode-checkbox">
                                            <Tooltip
                                                className={styles.tooltip}
                                                id="alert-mode-tooltip"
                                            />
                                            <div
                                                data-tooltip-id="alert-mode-tooltip"
                                                data-tooltip-content={"Alerting mode"}
                                            >
                                                <Checkbox
                                                    isChecked={user.isAlertTicked}
                                                    onChangeHandler={() => handleCheckbox(user.id)}
                                                    isDisabled={user.isDeleted}
                                                />
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </td>
                        </tr>
                    ));
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                }, [currentUserRole, users])
                }
                </tbody>
            </AppTableWhite>
            {!users.length && <NoDataFoundStub textColor={"white"}/>}
        </div>
    );
});

export default SettingsTable;
