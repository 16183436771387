import React from 'react';
import styles from '../index.module.scss';
import {Link, useParams} from "react-router-dom";
import {RxArrowLeft} from "react-icons/rx";
import Switcher from "../../Switcher/Switcher";
import {useAppSelector} from "../../../hooks/hooks";
import {useSwitchUsers} from "../../../pages/Cms/IGameView/Billing/IGameBillingHooks/useSwitchUsers";
import User from '../../../img/user.png'
import {Group} from "../../../enums/group";
import {RHBillingTotalData} from "../../../store/rightsHolderBilling/iGameBillingSlice";

interface IRightHolderUser {
    totalData: RHBillingTotalData
}

const RightHolderUser: React.FC<IRightHolderUser> = React.memo(({totalData}) => {
    const {id} = useParams();
    const {rightHolders} = useAppSelector(state => state.userManagement);
    const {role} = useAppSelector(state => state.userInfo);
    const {handleNextUser, handlePreviousUser} = useSwitchUsers(rightHolders, id, 'rightHolders')

    return (
        <div className={styles.userInfoWrapper}>
            {role === Group.IGame &&
                <Link to={'/cms/igame-billing'}>
                    <button className={styles.backButton}>
                        <RxArrowLeft color={'#FF3466'}/>
                    </button>
                </Link>
            }

            <div className={styles.userInner}>
                {
                    totalData.logo ?
                        <img src={totalData.logo} alt="rightHolder_user"/>
                        :
                        <img src={User} alt="rightHolder_user"/>
                }

                {role === Group.IGame &&
                    <p className={styles.userName}>{totalData.userName}</p>
                }

                <p className={styles.userRole}>Rights Holder</p>

                {role === Group.IGame &&
                    <Switcher
                        handleNextButtonClick={handleNextUser}
                        handlePrevButtonClick={handlePreviousUser}/>
                }
            </div>

            <p className={styles.totalUsers}>{totalData.activeOperators} Active
                operators</p>

            <div>
                <div className={styles.totalInner}>
                    <div className={styles.totalCell}>
                        <p className={styles.totalNumber}>{totalData.bandwidthOverage}</p>
                        <p className={styles.cellName}>Bandwidth Overage</p>
                    </div>
                    <div className={styles.totalCell}>
                        <p className={styles.totalNumber}>{totalData.fixturesCount}</p>
                        <p className={styles.cellName}>Fixtures</p>
                    </div>
                </div>
                <div className={styles.totalInner}>
                    <div className={styles.totalCell}>
                        <p className={styles.totalNumber}>{totalData.ingestHours}</p>
                        <p className={styles.cellName}>Ingest Hours</p>
                    </div>

                    <div className={styles.totalCell}>
                        <p className={styles.totalNumber}>{totalData.playersView}</p>
                        <p className={styles.cellName}>Player Views</p>
                    </div>
                </div>
            </div>
        </div>
    );
});

RightHolderUser.displayName = "RightHolderUser"
export default RightHolderUser;
