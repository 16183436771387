import React, {useEffect, useRef, useState} from 'react';
import {IPageMenuProps} from "../../../AppRoutes/routes/RouteInterface";
import {formatRangeDate, getCurrentMonth, getPreviousMonth} from "../../../../../helpers/dateFormatters";
import {IDate} from "../../../../../components/AnalyticsDashboard/types";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {useParams} from "react-router-dom";
import {pdfFormatter} from "../../../../../helpers/pdfFormatter";
import styles from "./index.module.scss";
import PageHeader from "../../../../../components/PageHeader/PageHeader";
import DownloadBlock from "../../../../../components/DownloadBlock/DownloadBlock";
import BillingCalendar from "../../../../../components/Billing/BillingCalendar/BillingCalendar";
import OperatorUser from "../../../../../components/Billing/OperatorUser/OperatorUser";
import {
    getBillingOperatorCSV,
    getBillingOperatorData, IBillingReqBody
} from "../../../../../store/rightsHolderBilling/rightHolderBillingOperatorSlice";
import BillingAccordion from "./children/BillingAccordion/BillingAccordion";
import uuid from "react-uuid";
import {validateDates} from "../../../../../helpers/validateDates";
import Loader from "../../../../../components/Loader/Loader";

const BillingOperator: React.FC<IPageMenuProps> = React.memo(({menuStructure}) => {
        const initialStartDate = getPreviousMonth();
        const initialEndDate = getCurrentMonth();
        const [startDate, setStartDate] = useState<IDate>(initialStartDate);
        const [endDate, setEndDate] = useState<IDate>(initialEndDate);
        const [isValidEndDate, setIsValidEndDate] = useState<boolean>(true);
        const [isExpandedAccordion, setIsExpandedAccordion] = useState<boolean>(false);
        const {
            billingCSV,
            csvFileName,
            totalData,
            operatorsData,
            loading
        } = useAppSelector(state => state.rightHolderBillingOperator);
        const {id} = useParams();
        const pdfLayout = useRef(null);
        const dispatch = useAppDispatch();

        useEffect(() => {
            const isValidDates = validateDates(startDate, endDate);
            setIsValidEndDate(isValidDates);
            if (!isValidDates) return;

            const body: IBillingReqBody = {
                startDate: formatRangeDate(startDate),
                endDate: formatRangeDate(endDate)
            }

            dispatch(getBillingOperatorData(body));
            dispatch(getBillingOperatorCSV(body));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [id, formatRangeDate(startDate), formatRangeDate(endDate)]);

        const pdfHandler = () => {
            setIsExpandedAccordion(true);

            setTimeout(() => {
                const layout: any = pdfLayout.current;
                pdfFormatter(layout);

                setIsExpandedAccordion(false);
            })
        }

        return (
            <div className={styles.pageInner} ref={pdfLayout}>
                <PageHeader menuStructure={menuStructure} title={'Operator'}>
                    <DownloadBlock
                        fileName={csvFileName}
                        csvFile={billingCSV}
                        pdfHandler={pdfHandler}
                        isActiveLink={true}
                    />
                </PageHeader>

                <div className={styles.pageContainer}>
                    <div className={styles.titleInner}>
                        <h1 className={styles.title}>Operator</h1>
                        <BillingCalendar
                            startDate={startDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            setStartDate={setStartDate}
                            isValidEndDate={isValidEndDate}/>
                    </div>


                    <div className={styles.billingWrapper}>
                        <OperatorUser totalData={totalData}/>

                        <div className={styles.tableWrapper}>
                            <div className={styles.billingTable}>
                                {loading && <Loader/>}
                                {!loading &&
                                    <table>
                                        <thead>
                                        <tr>
                                            <th className={styles.accordionCellInner}>Operator</th>
                                            <th className={styles.widthCell}>Right</th>
                                            <th className={styles.widthCell}>Match Price (£)</th>
                                            <th>Ad Hoc Price (£)</th>
                                            <th>Number of Fixtures</th>
                                            <th>Number of Ad Hoc Bookings</th>
                                            <th>Total (£)</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {operatorsData?.map(item => (
                                            <BillingAccordion
                                                billingData={item}
                                                key={uuid()}
                                                isExpandedAccordion={isExpandedAccordion}
                                            />
                                        ))
                                        }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
);

BillingOperator.displayName = "BillingOperator"
export default BillingOperator;
