import React, {Dispatch, SetStateAction, useState} from 'react';
import styles from './index.module.scss'
import {checkFileIsInvalid} from "../../pages/Cms/IGameView/Settings/UserManagement/helpers/checkFileIsInvalid";

interface ILogoUploaderProps {
    setLogo: Dispatch<SetStateAction<string>>
}

const LogoUploader: React.FC<ILogoUploaderProps> = ({setLogo}) => {

    const [dragActive, setDragActive] = useState(false);
    const [uploaded, setUploaded] = useState<boolean>(false);
    const [error, setError] = useState("");

    const handleDrag = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    }

    const handleDrop = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files?.[0]) {
            const errorMessage = await checkFileIsInvalid(e.dataTransfer.files[0], 500, 500);

            if (errorMessage) {
                setError(errorMessage);
                setUploaded(false);
                return;
            }
            setError("");
            setUploaded(true);
            setLogo(e.dataTransfer.files[0])
        }
    }

    const handleUploadingOnClick = async (e: any) => {
        e.preventDefault();
        if (e.target.files?.[0]) {
            const errorMessage = await checkFileIsInvalid(e.target.files[0], 500, 500);

            if (errorMessage) {
                setError(errorMessage);
                setUploaded(false);
                return;
            }
            setError("");
            setUploaded(true);
            setLogo(e.target.files[0])
        }
    }

    return (
        <div className={styles.logoUploader}>
            <div className={`${styles.uploadContent}`} onDragEnter={handleDrag}>
                {
                    dragActive &&
                    <div className={styles.coverElement}
                         onDragEnter={handleDrag}
                         onDragLeave={handleDrag}
                         onDragOver={handleDrag}
                         onDrop={handleDrop}>
                    </div>
                }
                <div className={styles.oneInputContainer}>
                    <label
                        className={uploaded
                            ? `${styles.uploadLabel} ${styles.uploaded}`
                            : dragActive
                                ? `${styles.uploadLabel} ${styles.dragActive}`
                                : styles.uploadLabel}
                        htmlFor="file"
                        data-testid={"fileLoaderLabel"}
                    >
                        <div className={styles.uploadTextSection}>
                            <h2 className={styles.formTitle} data-testid="satelliteTitle">Property
                                Logo</h2>
                            <h2 className={styles.uploadTitle}>Upload files</h2>
                            {
                                uploaded
                                    ?
                                    <p>File uploaded!</p>
                                    :
                                    <>
                                        <p>
                                            Drag and drop file here to upload or click to find it on your
                                            computer
                                        </p>
                                        <p className={styles.uploadDescription}>
                                            Upload files' should read: Supported file types are .png and
                                            .svg. The minimum size should be 500 x 500. The file can be
                                            bigger as long as it is in this ratio and has file type
                                            .svg or .png.
                                        </p>
                                    </>
                            }
                        </div>
                        <input
                            onChange={handleUploadingOnClick}
                            type="file"
                            id="file"
                            className={styles.uploadInput}
                        />
                    </label>
                    <p className={styles.formErrorBlock}>{error}</p>
                </div>
            </div>
        </div>
    );
};

export default LogoUploader;
