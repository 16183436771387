import React, {useEffect, useState} from 'react';
import styles from "./index.module.scss";
import {useAppDispatch, useAppSelector} from "@hooks/hooks";
import {
    confirmPricing,
    getUnconfirmedPricing, IRightPricingItem,
} from "@store/operatorPricing/operatorPricingSlice";
import {toastr} from "react-redux-toastr";
import Checkbox from "../../../../../components/Checkbox";
import {FaPencilAlt, FaRegTrashAlt} from "react-icons/fa";
import {PricingTablesNames} from "../Pricing";


interface ICreatePriceTableProps {
    openConfirm: (price: IRightPricingItem, tableName: string) => void;
    openUpdateForm: (pricingId: string) => void;
    rightHolderId: string | null;
    testConfirmPricingFunction?: (payload: {
        pricing: string[];
        rightHolderId: string | null;
    }) => void;
}

const CreatePriceTable: React.FC<ICreatePriceTableProps> = React.memo((
    {
        openConfirm,
        openUpdateForm,
        rightHolderId,
        testConfirmPricingFunction
    }) => {
    const dispatch = useAppDispatch()
    const {unconfirmedPricing} = useAppSelector(state => state.operatorPricing)
    const [selectedPricing, setSelectedPricing] = useState<string[]>([]);

    useEffect(() => {
        dispatch(getUnconfirmedPricing(rightHolderId))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rightHolderId]);

    const selectPricing = (e: React.MouseEvent<HTMLDivElement>, pricingId: string) => {
        e.preventDefault()
        e.stopPropagation()
        const isExistAccessInList = selectedPricing.find(pricing => pricing === pricingId)
        if (isExistAccessInList) {
            const allPricing = selectedPricing.filter(pricing => pricing !== pricingId)
            setSelectedPricing(allPricing)
        } else {
            const oneSelectedPricing = unconfirmedPricing.find(pricing => pricing.id === pricingId)
            if (oneSelectedPricing) {
                setSelectedPricing([...selectedPricing, oneSelectedPricing.id])
            }
        }
    }

    const checkCheckbox = (pricingId: string) => {
        const isChecked = selectedPricing.find(pricing => pricing === pricingId)
        return !!isChecked
    }

    const selectAllPricings = () => {
        const allAllPricings = unconfirmedPricing.map(pricing => pricing.id)
        if (allAllPricings.length === selectedPricing.length) {
            return toastr.warning("IGame", "All pricings are already selected")
        }
        setSelectedPricing(allAllPricings)
    }

    const onConfirmPricing = () => {
        if (selectedPricing.length === 0) {
            return toastr.warning("IGame", "There is no chosen Pricing")
        } else {
            const body = {
                pricing: selectedPricing,
                rightHolderId
            }
            testConfirmPricingFunction && testConfirmPricingFunction(body);
            dispatch(confirmPricing(body))
            setSelectedPricing([])
        }
    }

    const unSelectAllPricing = () => {
        if (selectedPricing.length > 0) {
            setSelectedPricing([])
        } else {
            return toastr.warning("IGame", "There are no changes to cancel")
        }
    }

    return (
        <div className={styles.tableOverlay}>
            <h3 className={styles.tableTitle}>{PricingTablesNames.CREATE_PRICING}</h3>

            <div className={styles.tableScroll}>
                <div className={styles.tableWrapper}>
                    <div className={styles.tableMenu}>
                        <div data-testid="access-granted-message">{`${unconfirmedPricing.length} Pricing Granted`}</div>
                        <div className={styles.controlBox}>
                            <div onClick={onConfirmPricing}
                                 className={selectedPricing.length > 0 ? styles.menuButton : styles.notActiveMenuBtn}>
                                Confirm +
                            </div>

                            <div onClick={selectAllPricings}
                                 className={selectedPricing.length !== unconfirmedPricing.length ? styles.menuButton : styles.notActiveMenuBtn}>
                                Select all
                            </div>

                            <div onClick={unSelectAllPricing}
                                 className={selectedPricing.length > 0 ? styles.menuButton : styles.notActiveMenuBtn}>
                                Cancel
                            </div>
                        </div>
                    </div>

                    <table
                        data-testid={"pricing-table"}
                        className={styles.pricingTable}
                    >
                        <thead>
                        <tr>
                            <th>Operator</th>
                            <th>Right</th>
                            <th>Min PPF (£)</th>
                            <th>Max PPF (£)</th>
                            <th>Ad Hoc</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {unconfirmedPricing.map((price, index: number) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <div className={styles.cellWithCheckbox}>
                                            <div onClick={(e) => selectPricing(e, price.id)}
                                                 className={styles.checkbox}>
                                                <Checkbox isChecked={checkCheckbox(price.id)}/>
                                            </div>
                                            <p>{price.operatorName}</p>
                                        </div>
                                    </td>
                                    <td>{price.rightName}</td>
                                    <td>{price.minPPF}</td>
                                    <td>{price.maxPPF}</td>
                                    <td>{price.adHoc}</td>
                                    <td>
                                        <div className={styles.tableControl}>
                                            <FaPencilAlt
                                                onClick={() => openUpdateForm(price.id)}
                                                data-testid="update-pricing-button"
                                            />

                                            <FaRegTrashAlt
                                                onClick={() => openConfirm(price, PricingTablesNames.CREATE_PRICING)}
                                                data-testid="delete-pricing-button"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
});

export default CreatePriceTable;
CreatePriceTable.displayName = "CreatePriceTable"
