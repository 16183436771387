import React, {useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/hooks";
import {
    getIssuesReport,
} from "../../../../../../store/reportingIssuesSlice/reportingIssuesSlice";
import styles from './index.module.scss'
import {Tooltip} from "react-tooltip";
import InfiniteScroll from "react-infinite-scroll-component";
import AppTableWhite from '../../../../../../components/AppTableWhite/AppTableWhite';
import descrMark from '../../../../../../img/RedExclamationMark.png'
import {IDateData} from "../../../../OperatorView/ConcurrencyInspector/ConcurrencyTable/ConcurrencyTable";
import {formatDate} from "../../../../../../helpers/dateFormatters";
import audit from "../../../../../../img/auditWhite.svg";
import {useNavigate} from "react-router-dom";
import {setIssuesDetails} from "../../../../../../store/auditTrailsIssues/auditTrailsIssuesSlice";
import NoDataFoundStub from "../../../../../../components/NoDataFoundStub/NoDataFoundStub";

interface IIssuesTable {
    searchValue: string;
    filters: {
        startDate: IDateData;
        endDate: IDateData;
    }
}

const IssuesTable: React.FC<IIssuesTable> = ({searchValue, filters}) => {
    const dispatch = useAppDispatch()
    const {issuesReport, itemsPerPage} = useAppSelector(state => state.reportingIssues)
    const [hasMore, setHasMore] = useState(true);
    const navigate = useNavigate();

    const fetchIssuesReport = () => {
        setHasMore(true)
        const payload = {
            payload: {
                searchValue,
                pageNumber: 1,
                itemsPerPage,
                from: formatDate(filters.startDate),
                to: formatDate(filters.endDate)
            },
            isScroll: false
        };
        if (filters) {
            dispatch(getIssuesReport(payload));
        }
    }

    return (
        <div className={styles.infiniteScrollContainer}>
            <InfiniteScroll
                dataLength={issuesReport?.length}
                height={"48vh"}
                next={fetchIssuesReport}
                hasMore={hasMore}
                loader={null}
                scrollableTarget="scrollableDiv"
            >
                {issuesReport.length === 0 && <NoDataFoundStub
                    message={"No records found matching the given filter criteria"}
                    textColor="white"
                />}
                <AppTableWhite>
                    <thead>

                    {issuesReport.length > 0 &&
                        <tr>
                            <th>Rights Holder</th>
                            <th>Content Supplier</th>
                            <th>Property</th>
                            <th>Fixture</th>
                            <th>Fixture Start Time</th>
                            <th>Issue Source</th>
                            <th>Severenity</th>
                            <th>Issue Start Time</th>
                            <th>Issue End Time</th>
                            <th>Protocol</th>
                            <th>Description</th>
                        </tr>}
                    </thead>
                    <tbody>

                    {issuesReport?.map((report, index) => {
                        return (
                            <tr key={`${index + 1}`}>
                                <td>{report.rightHolderName}</td>
                                <td>{report.contentSupplier}</td>
                                <td>{report.propertyName}</td>
                                <td>{report.fixtureTitle}</td>
                                <td>{report.fixtureStartTime}</td>
                                <td>{report.issueSource}</td>
                                <td>{report.issueSeverity}</td>
                                <td>{report.issueStartTime}</td>
                                <td>{report.issueEndTime}</td>
                                <td>{report.streamType}</td>
                                <td className={styles.tableButtons}>
                                    <button className={styles.tableButton}>
                                        <Tooltip className={styles.userTableTooltip} id="delete-tooltip"/>
                                        <img
                                            className={styles.descrIcon}
                                            data-tooltip-content={report.issueDescription}
                                            data-tooltip-id="delete-tooltip"
                                            alt={'delete-tooltip'}
                                            src={descrMark}/>
                                    </button>
                                    <button className={styles.tableButton}
                                            onClick={() => {
                                                navigate("/cms/audit-trails-issues")
                                                dispatch(setIssuesDetails({
                                                    id: report.id,
                                                    rightHolder: report.rightHolderName,
                                                    technicalProvider: report.contentSupplier,
                                                    competition: report.propertyName
                                                }))
                                            }}>
                                        <img
                                            className={styles.auditIcon}
                                            src={audit}
                                            alt={"audit"}
                                        />
                                    </button>
                                </td>
                            </tr>
                        )
                    })}

                    </tbody>
                </AppTableWhite>
            </InfiniteScroll>
        </div>
    );
};

export default IssuesTable;
