import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {client} from "../../services/api-servise";
import {toastr} from "react-redux-toastr";

export interface IRightsRestrictionsTrailsDataResponse {
    action: string;
    affectedColumn: string | null;
    customer: string | null;
    oldValue: string | null;
    newValue: string | null;
    userEmail: string;
    modifiedAt: string;
}

export interface IAuditTrailsState {
    trails: {
        source: IRightsRestrictionsTrailsDataResponse[];
        loading: boolean
    };
}

const initialState: IAuditTrailsState = {
    trails: {
        source: [],
        loading: false
    }
}

export const getRightsRestrictionsTrails = createAsyncThunk<IRightsRestrictionsTrailsDataResponse[], {
    rightHolderId: string,
    competitionId: string
}, { rejectValue: string }>(
    'audit-trails/rights-restrictions-trails',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/auditTrails/restriction?rightHolderId=${body.rightHolderId}&competitionId=${body.competitionId}`)
            return data;
        } catch (e: any) {
            toastr.error('Error!', `${e.response.data}`)
            return rejectWithValue(e.response.data)
        }
    }
)

export const auditTrailsRightsRestrictionsSlice = createSlice({
    name: 'audit-trails-rights-restrictions',
    initialState,
    reducers: {
        skipRightsRestrictionsSearch: (state) => {
            state.trails.source = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRightsRestrictionsTrails.pending, (state) => {
                state.trails.loading = true;
            })
            .addCase(getRightsRestrictionsTrails.fulfilled, (state, action) => {
                state.trails.source = action.payload;
                state.trails.loading = false;
            })
            .addCase(getRightsRestrictionsTrails.rejected, (state) => {
                state.trails.loading = false;
            })
    },
})

export const {skipRightsRestrictionsSearch} = auditTrailsRightsRestrictionsSlice.actions
export default auditTrailsRightsRestrictionsSlice.reducer
