import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import './index.scss'
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/hooks";
import {
    addAllCountries,
    addAllowedCountry,
    addBlockedCountry, deleteCountry,
    ITerritory
} from "../../../../../../store/geoRestrictions/geoRestrictionsSlice";
import {SingleValue} from "react-select";
import {toastr} from "react-redux-toastr";
import {DragEvent} from 'react';
import {
    IGeoBoard,
    IGeoFilteredCountries,
    IGeoFilterValues
} from "../../OperatorGeoRestrictions/OperatorGeoRestrictions";
import {IoIosClose} from "react-icons/io";

interface IBoard {
    id: number,
    boardName: string,
    items: ITerritory[]
}

export interface IDragBoardProps {
    operator: SingleValue<{ value: string, label: string } | undefined>
    competition: SingleValue<{ value: string, label: string } | undefined>
    setIsShowConfirmBtn: Dispatch<SetStateAction<boolean>>
    filteredTerritories: IGeoFilteredCountries
    setFiltersValue: Dispatch<SetStateAction<IGeoFilterValues>>
    setFilteredTerritories: Dispatch<SetStateAction<IGeoFilteredCountries>>
    setBoards?: Dispatch<SetStateAction<IGeoBoard[]>>
    boards?: IGeoBoard[]
    unconfirmedCountries: ITerritory[],
    setUnconfirmedCountries: (arg: ITerritory[]) => void
}

const DragBoard: React.FC<IDragBoardProps> = React.memo(({
                                                             competition,
                                                             operator,
                                                             setIsShowConfirmBtn,
                                                             filteredTerritories,
                                                             setFiltersValue,
                                                             setFilteredTerritories,
                                                             setBoards,
                                                             boards,
                                                             unconfirmedCountries,
                                                             setUnconfirmedCountries
                                                         }) => {
    const dispatch = useAppDispatch()
    const {allTerritories, allowedTerritories, blockedTerritories} = useAppSelector(state => state.geoRestrictions);
    const [currentDragCountry, setCurrentDragCountry] = useState({} as ITerritory);
    const [currentBoard, setCurrentBoard] = useState({} as IBoard);

    useEffect(() => {
        const newBoards = boards!.map(board => {
            if (board.boardName === "Allowed Countries") {
                return {
                    id: 2,
                    boardName: "Allowed Countries",
                    items: filteredTerritories.allowedTerr && filteredTerritories.allowedTerr.length > 0 ?
                        filteredTerritories.allowedTerr : allowedTerritories.slice().sort((a, b) => a.name.localeCompare(b.name))
                }
            }
            if (board.boardName === "Blocked Countries") {
                return {
                    id: 3,
                    boardName: "Blocked Countries",
                    items: filteredTerritories.blockedTerr && filteredTerritories.blockedTerr.length > 0 ?
                        filteredTerritories.blockedTerr : blockedTerritories
                }
            }
            if (board.boardName === "All Territories") {
                return {
                    id: 1,
                    boardName: "All Territories",
                    items: filteredTerritories.allTerr.length > 0 ? filteredTerritories.allTerr : allTerritories
                }
            }
            return board
        })
        setBoards!(newBoards)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowedTerritories, blockedTerritories, allTerritories, filteredTerritories])

    const checkIsExistCountryInColumn = (currentCountry: ITerritory, countriesInColumn: ITerritory[]) => {
        const isExist = countriesInColumn.find(country => country.code === currentCountry.code)
        if (isExist) {
            toastr.warning("IGame", "Current country is already exist in column")
        }
        return !!isExist
    }

    const onDragstart = (item: ITerritory, board: IBoard) => {
        setCurrentDragCountry(item)
        setCurrentBoard(board)
    }

    const onDragOverHandler = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        const target = e.target as HTMLDivElement
        const className = target.className
        if (className === "board Allowed Countries"
            || className === "board Blocked Countries"
            || className === "board All Territories"
        ) {
            target.style.border = "2px solid #FF3466"
        }
    }

    const onDropHandler = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        const target = e.target as HTMLDivElement
        target.style.border = "none"
        const currentBoardClass = target.className

        if (currentBoardClass.indexOf('Territories') > 0) {
            if (currentBoard.boardName === "All Territories") {
                return
            }
            dispatch(deleteCountry({territory: currentDragCountry, boardName: currentBoard.boardName}))

            const updatedTerritories = unconfirmedCountries.filter(territory => territory.id !== currentDragCountry.id)
            setUnconfirmedCountries(updatedTerritories)

            setIsShowConfirmBtn(true)
        }
        if (currentBoardClass.indexOf('Blocked') > 0) {
            if (currentBoard.boardName === "Blocked Countries") {
                return
            }
            if (operator && competition) {
                return toastr.warning("IGame", "Blocked Countries applies globally to a right. " +
                    "Please remove operator selection in order to modify Blocked Countries")
            }
            if (operator) {
                return toastr.warning("IGame", "Right needs to be selected in order to add Blocked Countries")
            }
            if (!checkIsExistCountryInColumn(currentDragCountry, blockedTerritories)) {
                dispatch(addBlockedCountry(currentDragCountry))
                setIsShowConfirmBtn(true)
                setUnconfirmedCountries([...unconfirmedCountries, currentDragCountry])
            }

        }
        if (currentBoardClass.indexOf('Allowed Countries') > 0) {
            if (currentBoard.boardName === "Allowed Countries") {
                return
            }
            if (competition && !operator) {
                return toastr.warning(
                    "IGame",
                    "Operator needs to be selected in order to add Allowed Countries"
                )
            }
            if (!checkIsExistCountryInColumn(currentDragCountry, allowedTerritories)) {
                dispatch(addAllowedCountry(currentDragCountry))
                setIsShowConfirmBtn(true)
                setUnconfirmedCountries([...unconfirmedCountries, currentDragCountry])
            }
        }

        if (currentDragCountry.code === 'all' && operator && competition) {
            return toastr.warning("IGame", "Blocked Countries applies globally to a right. " +
                "Please remove operator selection in order to modify Blocked Countries")

        } else if (currentDragCountry.code === 'all') {
            const updatedCountries = allTerritories.slice(2);

            setIsShowConfirmBtn(true);
            dispatch(addAllCountries(
                {
                    countries: updatedCountries,
                    boardName: boards![1].boardName
                }
            ))
            setUnconfirmedCountries(updatedCountries)
        }

        setFiltersValue({allTerr: "", allowedTerr: "", blockedTerr: ""})
        setFilteredTerritories({blockedTerr: [], allowedTerr: [], allTerr: []})
    }

    const dragLeave = (e: DragEvent<HTMLDivElement>) => {
        const target = e.target as HTMLDivElement
        const className = target.className
        if (className === "board Allowed Countries"
            || className === "board Blocked Countries"
            || className === "board All Territories"
        ) {
            target.style.border = "none"
        }
    }

    const onDragEndHandler = (e: DragEvent<HTMLDivElement>) => {
        const target = e.target as HTMLDivElement
        const className = target.className
        if (className === "board Allowed Countries"
            || className === "board Blocked Countries"
            || className === "board All Territories"
        ) {
            target.style.border = "none"
        }
    }

    const onRemoveCountryFromList = (item: ITerritory, board: IBoard) => {
        setCurrentDragCountry(item);
        setCurrentBoard(board);

        const updatedCountries = unconfirmedCountries.filter(country => country.id !== item.id)
        setUnconfirmedCountries(updatedCountries);

        if (unconfirmedCountries.length === 1) {
            setIsShowConfirmBtn(false)
        } else {
            setIsShowConfirmBtn(true)
        }

        dispatch(deleteCountry({territory: item, boardName: board.boardName}))
        setFiltersValue({allTerr: "", allowedTerr: "", blockedTerr: ""})
        setFilteredTerritories({blockedTerr: [], allowedTerr: [], allTerr: []})
    }

    return (
        <div className={"dragBoard"}>
            {boards?.map(board => {
                return (
                    <div key={board.id}
                         onDragOver={(e) => onDragOverHandler(e)}
                         onDragLeave={(e) => dragLeave(e)}
                         onDragEnd={(e) => onDragEndHandler(e)}
                         onDrop={(e) => onDropHandler(e)}
                         className={`board ${board.boardName}`}>

                        {board?.items.length ? board.items.map(item => {
                                return (
                                    <div
                                        key={item.id}
                                        draggable={true}
                                        onDragStart={() => onDragstart(item, board)}
                                        className={"boardItem"}>
                                        <p className={"text"}>{item.name}</p>
                                        <div className={"flag-inner"}>
                                            {item.flag &&
                                                <img className={"flag"} src={`${item.flag}`} alt="flag"/>
                                            }

                                            {board.boardName === 'Blocked Countries' ||
                                            board.boardName === 'Allowed Countries' ?
                                                <IoIosClose size={20}
                                                            onClick={() => onRemoveCountryFromList(item, board)}/>
                                                : null
                                            }
                                        </div>

                                    </div>
                                )
                            })
                            :
                            <div className={'instruction'}>
                                <p>To Select Blocked Countries: </p>
                                <span>Drag and drop Country Listings <br/>
                                from the All Countries List to here</span>
                            </div>

                        }
                    </div>
                )
            })}
        </div>
    );
});

export default DragBoard;
