import React, {ChangeEvent, useEffect, useState} from 'react';
import styles from "../index.module.scss";
import {Control, Controller, FieldErrors} from "react-hook-form";
import Input from "../../../../../../../../../components/Input/Input";
import {IStepThreeCheckboxes, IStepThreeFormData} from "../../../../UserManagementHooks/useRightHolderFormData";
import DatePicker from "../../../../../../../../../components/DatePicker/DatePicker";
import CheckboxWithLabel from "../../../../../../../../../components/CheckboxWithLabel/CheckboxWithLabel";
import {IDate} from "@components/AnalyticsDashboard/types";
import {getPickerDateFormat} from "@helpers/dateFormatters";
import {useAppSelector} from "@hooks/hooks";


interface IStepThreeFormProps {
    control: Control,
    errors: FieldErrors,
    stepThreeFormData: IStepThreeFormData,
    onChangeStepThree: (event: ChangeEvent<HTMLInputElement>) => void
    setContractDate: (date: IDate) => void
    setCheckboxes: (name: keyof IStepThreeCheckboxes) => void
}

const StepThreeForm: React.FC<IStepThreeFormProps> = React.memo((
    {
        control,
        errors,
        stepThreeFormData,
        onChangeStepThree,
        setContractDate,
        setCheckboxes
    }) => {
    const {rightsHolderDetails} = useAppSelector(state => state.userManagement)
    const [sectionState, setSectionState] = useState({
        bandwidth: false,
        ingest: false,
        playerUsage: false,
        operator: false,
    });
    useEffect(() => {
        setFormDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setFormDetails = () => {
        if (rightsHolderDetails) {
            setSectionState({
                bandwidth: rightsHolderDetails.isBandwidthEnabled,
                ingest: rightsHolderDetails.isIngestEnabled,
                playerUsage: rightsHolderDetails.isPlayerUsageEnabled,
                operator: rightsHolderDetails.isOperatorEnabled,
            })
        }
    }

    const onSetSectionVisibility = (name: keyof typeof sectionState) => {
        setSectionState(prevState => ({
            ...prevState,
            [name]: !prevState[name],
        }));
    };

    return (
        <div className={styles.formContent}>
            <div className={styles.contractDateSection}>
                <div className={styles.datePickerInner}>
                    <div className={styles.label}>Contract Start Date <span>*</span>
                        <Controller
                            control={control}
                            name="contractStartDate"
                            rules={{
                                required: "Required Field",
                                validate: {
                                    validateDate: (value) => {
                                        if (!value || !value.year || !value.month || !value.day) {
                                            return "Please select a valid date";
                                        }
                                        return true;
                                    }
                                }
                            }}
                            render={({field: {onChange}, fieldState: {error}}) => (
                                <>
                                    <DatePicker
                                        withTime={false}
                                        initValue={getPickerDateFormat(stepThreeFormData.contractStartDate ? stepThreeFormData.contractStartDate : "")}
                                        placeholder={"Select date"}
                                        onChange={(e) => {
                                            onChange(e)
                                            setContractDate(e)
                                        }}
                                    />
                                    {error && <p className={styles.formError}>{error.message}</p>}
                                </>
                            )}
                        />
                    </div>
                </div>
            </div>


            <div className={`${styles.formSubTitle} ${styles.formSubTitleBorder}`}>
                <p className={styles.subtitle}>Bandwidth</p>
                <div className={styles.checkSection}>
                    <CheckboxWithLabel
                        id={"Bandwidth"}
                        checked={sectionState.bandwidth}
                        onChange={() => {
                            onSetSectionVisibility("bandwidth")
                            setCheckboxes("isBandwidthEnabled")
                        }}
                        labelText={"Enabled"}
                    />
                </div>
            </div>


            <div className={styles.twoInputsContainer}>
                <label className={styles.label}>Allowance Per Operator (TB)
                    <Controller
                        control={control}
                        name="allowancePerOperator"
                        rules={{
                            minLength: 1,
                            min: 1,
                        }}
                        render={({field: {onChange}}) => (
                            <Input
                                disabled={!sectionState.bandwidth}
                                onChange={(e) => {
                                    onChange(e);
                                    onChangeStepThree(e)
                                }}
                                value={stepThreeFormData.allowancePerOperator === 0 ? "" : stepThreeFormData.allowancePerOperator}
                                type="number"
                                name="allowancePerOperator"
                                placeholder={"Allowance Per Operator"}
                            />
                        )}
                    />
                    {errors?.allowancePerOperator?.type === "minLength" &&
                        <p className={styles.formErrorBlock}>Allowance Per Operator should contain at
                            least
                            1 character</p>}
                    {errors?.allowancePerOperator?.type === "min" &&
                        <p className={styles.formErrorBlock}>
                            Allowance Per Operator should be positive number
                        </p>
                    }
                </label>

                <label className={styles.label}>Player Rate Per View (£)
                    <Controller
                        control={control}
                        name="overageRatePerGB"
                        rules={{
                            minLength: 1,
                            min: 1
                        }}
                        render={({field: {onChange}}) => (
                            <Input
                                disabled={!sectionState.bandwidth}
                                onChange={(e) => {
                                    onChange(e);
                                    onChangeStepThree(e)
                                }}
                                value={stepThreeFormData.overageRatePerGB === 0 ? "" : stepThreeFormData.overageRatePerGB}
                                type="number"
                                name="overageRatePerGB"
                                placeholder={"Player Rate Per View"}
                            />
                        )}
                    />
                    {errors?.overageRatePerGB?.type === "minLength" &&
                        <p className={styles.formErrorBlock}>
                            Overage per Rate should contain at least 1 characters
                        </p>}
                    {errors?.overageRatePerGB?.type === "min" &&
                        <p className={styles.formErrorBlock}>
                            Overage per Rate should be positive number
                        </p>
                    }
                </label>
            </div>

            <div className={`${styles.formSubTitle} ${styles.formSubTitleBorder}`}>
                <p className={styles.subtitle}>Ingest</p>
                <div className={styles.checkSection}>
                    <CheckboxWithLabel
                        id={"Ingest"}
                        checked={sectionState.ingest}
                        onChange={() => {
                            onSetSectionVisibility("ingest")
                            setCheckboxes("isIngestEnabled")
                        }}
                        labelText={"Enabled"}
                    />
                </div>
            </div>

            <div className={styles.oneInputContainer}>
                <label className={`${styles.label} ${styles.labelWidth}`}>Ingest Rate Per Hour
                    (TB)
                    <Controller
                        control={control}
                        name="ingestRatePerHour"
                        rules={{
                            minLength: 1,
                            min: 1
                        }}
                        render={({field: {onChange}}) => (
                            <Input
                                disabled={!sectionState.ingest}
                                onChange={(e) => {
                                    onChange(e);
                                    onChangeStepThree(e)
                                }}
                                value={stepThreeFormData.ingestRatePerHour === 0 ? "" : stepThreeFormData.ingestRatePerHour}
                                type="number"
                                name="ingestRatePerHour"
                                placeholder={"Ingest Rate Per Hour"}
                            />
                        )}
                    />
                    {errors?.ingestRatePerHour?.type === "minLength" &&
                        <p className={styles.formErrorBlock}>Ingest Per Hour should contain at least 1
                            characters</p>}
                    {errors?.ingestRatePerHour?.type === "min" &&
                        <p className={styles.formErrorBlock}>
                            Ingest Per Hour should be positive number
                        </p>
                    }
                </label>
            </div>

            <div className={`${styles.formSubTitle} ${styles.formSubTitleBorder}`}>
                <p className={styles.subtitle}>Player Usage</p>
                <div className={styles.checkSection}>
                    <CheckboxWithLabel
                        id={"PlayerUsage"}
                        checked={sectionState.playerUsage}
                        onChange={() => {
                            onSetSectionVisibility("playerUsage")
                            setCheckboxes("isPlayerUsageEnabled")
                        }}
                        labelText={"Enabled"}
                    />
                </div>
            </div>

            <div className={styles.oneInputContainer}>
                <div className={styles.label}>Player Rate per View (£)
                    <Controller
                        control={control}
                        name="playerRatePerView"
                        rules={{
                            minLength: 1,
                            min: 1
                        }}
                        render={({field: {onChange}}) => (
                            <Input
                                disabled={!sectionState.playerUsage}
                                onChange={(e) => {
                                    onChange(e);
                                    onChangeStepThree(e)
                                }}
                                value={stepThreeFormData.playerRatePerView === 0 ? "" : stepThreeFormData.playerRatePerView}
                                type="number"
                                name="playerRatePerView"
                                placeholder={"Player Rate per View"}
                            />
                        )}
                    />
                    {errors?.playerRatePerView?.type === "minLength" &&
                        <p className={styles.formErrorBlock}>
                            Player Rate per Hour should contain at least 1 characters
                        </p>
                    }
                    {errors?.playerRatePerView?.type === "min" &&
                        <p className={styles.formErrorBlock}>
                            Player Rate per Hour should be positive number
                        </p>
                    }
                </div>
            </div>

            <div className={`${styles.formSubTitle} ${styles.formSubTitleBorder}`}>
                <p className={styles.subtitle}>Operator</p>
                <div className={styles.checkSection}>
                    <CheckboxWithLabel
                        id={"Operator"}
                        checked={sectionState.operator}
                        onChange={() => {
                            onSetSectionVisibility("operator")
                            setCheckboxes("isOperatorEnabled")
                        }}
                        labelText={"Enabled"}
                    />
                </div>
            </div>

            <label className={styles.label}>Operator Rate Per Month (£)
                <Controller
                    control={control}
                    name="operatorRatePerMonth"
                    rules={{
                        minLength: 1,
                        min: 1
                    }}
                    render={({field: {onChange}}) => (
                        <Input
                            disabled={!sectionState.operator}
                            onChange={(e) => {
                                onChange(e);
                                onChangeStepThree(e)
                            }}
                            value={stepThreeFormData.operatorRatePerMonth === 0 ? "" : stepThreeFormData.operatorRatePerMonth}
                            type="number"
                            name="operatorRatePerMonth"
                            placeholder={"Operator Rate Per Month"}
                        />
                    )}
                />
                {errors?.operatorRatePerMonth?.type === "minLength" &&
                    <p className={styles.formErrorBlock}>
                        Operator Rate per Hour should contain at least 1
                        characters
                    </p>
                }
                {errors?.operatorRatePerMonth?.type === "min" &&
                    <p className={styles.formErrorBlock}>
                        Operator Rate per Hour should be positive number
                    </p>
                }
            </label>
        </div>
    );
});

export default StepThreeForm;
