import React, {useState} from 'react';
import styles from "./index.module.scss";
import {FaChevronDown} from "react-icons/fa";
import {IPublishBody, IRightHolder, publish} from "../../../../../../../store/publishing/publishingSlice";
import {useAppDispatch, useAppSelector} from "../../../../../../../hooks/hooks";


export interface IPublishingAccordionProps {
    rightHolder: IRightHolder,
    setIsAccordionOpen: (arg: boolean) => void,
    searchValue: string | undefined
}


const PublishingAccordion: React.FC<IPublishingAccordionProps> = React.memo((
    {
        rightHolder,
        setIsAccordionOpen,
        searchValue
    }) => {
    const [isRowExpanded, setIsRowExpanded] = useState(false);
    const {publishPlatforms} = useAppSelector(state => state.publishing);
    const dispatch = useAppDispatch();

    const handleOpenAccordion = () => {
        setIsRowExpanded(!isRowExpanded);
        setIsAccordionOpen(!isRowExpanded)
    }

    const platformButtonHandler = (publishPlatformId: string, rightHolderRightId: string) => {
        const body: IPublishBody = {
            payload: [{
                rightHolderRightId,
                publishPlatformId: publishPlatformId.toString()
            }],
            searchValue
        }
        dispatch(publish(body))
    }

    return (
        <>
            <tr onClick={handleOpenAccordion} className={styles.collapsedRow}
                key={rightHolder.rightHolderName}>
                <td className={styles.accordionCell}>
                    <div
                        className={!isRowExpanded ? styles.tableChevron : `${styles.tableChevron} ${styles.tableChevronTop}`}>
                        <FaChevronDown size={14}/>
                    </div>
                    {rightHolder.rightHolderName}
                </td>
                <td></td>
            </tr>

            {isRowExpanded && <>
                {rightHolder.rightHolderPublishItems.map((item, index) => (
                    <tr key={index} className={styles.accordionRow}>
                        <td></td>
                        <td>{item.sport}</td>
                        <td>{item.rightName}</td>
                        <td>
                            <div className={styles.buttonOTTInner}>
                                {publishPlatforms.map(btn =>
                                    <button
                                        key={btn.id} onClick={() => platformButtonHandler(btn.id, item.id)}
                                        className={btn.name === item.platformName ?
                                            `${styles.platformBtn} ${styles.platformBtnActive}` : styles.platformBtn}
                                        disabled={btn.name === item.platformName}>
                                        {btn.name}
                                    </button>
                                )}
                            </div>
                        </td>
                    </tr>
                ))}
            </>
            }
        </>
    );
});

PublishingAccordion.displayName = "PublishingAccordion"
export default PublishingAccordion;
