import React, {useState} from 'react';
import styles from './index.module.scss'

interface InfiniteScrollWrapperProps {
    fetchData: (page: number, pageSize: number, scrollPosition: 'up' | 'down') => void;
    pageSize: number;
    totalItems: number;
    children: React.ReactNode;
    currentPage: number;
    setPage: (pageNumber: number) => void;
    isLoading: boolean;
    scrollingContainerHeight: string;
    scrollBounce: number;
    maxItemsInTheTable: number;
    tableDataLength: number;
    containerRef: React.RefObject<HTMLDivElement>;
}

export const AppInfiniteScroll: React.FC<InfiniteScrollWrapperProps> = (
    {
        fetchData,
        pageSize,
        totalItems,
        currentPage,
        setPage,
        children,
        isLoading,
        scrollingContainerHeight,
        scrollBounce,
        maxItemsInTheTable,
        tableDataLength,
        containerRef
    }) => {

    const [prevDirection, setPrevDirection] = useState('down');
    const maxPagesDisplayed = maxItemsInTheTable / pageSize;
    const loadMoreData = async (direction: 'up' | 'down') => {
        let newPage;

        if ((currentPage <= maxPagesDisplayed && tableDataLength < maxItemsInTheTable) || direction === prevDirection) {
            newPage = direction === 'down' ? currentPage + 1 : currentPage - 1;
        } else {
            if (prevDirection === 'down') {
                newPage = currentPage - maxPagesDisplayed;
            } else {
                newPage = currentPage + maxPagesDisplayed;
            }
        }

        if (newPage < 1 || (newPage - 1) * pageSize >= totalItems || isLoading) {
            return;
        }
        fetchData(newPage, pageSize, direction);
        setPage(newPage);
    }

    const handleScroll = () => {
        const trashHold = 20
        if (!containerRef.current) {
            return;
        }
        const {scrollTop, scrollHeight, clientHeight} = containerRef.current;

        if (scrollTop === 0) {
            if (!(currentPage - 1)) {
                return;
            }
            if (tableDataLength < maxItemsInTheTable && currentPage <= maxPagesDisplayed) {
                return;
            }
            if (currentPage === maxItemsInTheTable / pageSize && prevDirection === 'down') {
                return;
            }

            loadMoreData('up');
            setPrevDirection('up');
            containerRef.current.scrollTop = scrollTop + scrollBounce;

        } else if (scrollTop + clientHeight + trashHold >= scrollHeight) {

            if ((currentPage * pageSize >= totalItems)) {
                return;
            }

            loadMoreData('down');
            setPrevDirection('down');
            containerRef.current.scrollTop = scrollTop - scrollBounce;
        }
    };

    return (
        <div
            ref={containerRef}
            className={styles.scrollingContainer}
            style={{height: scrollingContainerHeight}}
            onScroll={handleScroll}
        >
            {children}
        </div>
    );
};
