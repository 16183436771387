import styles from './index.module.scss'
import React from "react";

interface IRadioButtonProps {
    checked: boolean,
    text: string
}

const RadioButton: React.FC<IRadioButtonProps> = ({checked, text}) => {
    return (
        <div className={styles.btnInner}>
            <input checked={checked} className={styles.radioInput} type="radio" data-testid="radio-button" readOnly/>
            <label className={styles.label} htmlFor="radio-button">{text}</label>
        </div>
    );
};

RadioButton.displayName = "RadioButton"
export default RadioButton;
