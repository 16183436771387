import {useNavigate} from "react-router-dom";
import {IUserRole} from "../../../../../store/userManagement/userManagementSlice";

export const useSwitchUsers = (users: IUserRole[], currentUserId: string | undefined, usersRole: string) => {
    const navigate = useNavigate();

    const handleNextUser = () => {
        if (currentUserId) {
            const currentUserIndex = users.findIndex(user => user.id === currentUserId);
            if (currentUserIndex === users.length - 1) {
                const newUserId = users[0].id
                navigate(`/cms/igame-billing/${usersRole}/${newUserId}`)
            } else {
                const newUserId = users[currentUserIndex + 1].id
                navigate(`/cms/igame-billing/${usersRole}/${newUserId}`)
            }
        }
    }

    const handlePreviousUser = () => {
        if (currentUserId) {
            const currentUserIndex = users.findIndex(user => user.id === currentUserId);

            if (currentUserIndex === 0) {
                const newUserId = users[users.length - 1].id
                navigate(`/cms/igame-billing/${usersRole}/${newUserId}`)
            } else {
                const newUserId = users[currentUserIndex - 1].id
                navigate(`/cms/igame-billing/${usersRole}/${newUserId}`)
            }
        }
    }
    return {handleNextUser, handlePreviousUser}
}
