import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {client} from "../../services/api-servise";
import {AxiosError} from "axios";
import {toastr} from "react-redux-toastr";
import {getPickerDateFormat} from "../../helpers/dateFormatters";
import {DateTime} from "luxon";
import {IDateData} from "../../pages/Cms/OperatorView/ConcurrencyInspector/ConcurrencyTable/ConcurrencyTable";

export interface IFixtureIssue {
    id: string;
    rightHolderName: string;
    contentSupplier: string;
    propertyName: string;
    fixtureTitle: string;
    fixtureStartTime: string;
    issueStartTime: string;
    issueEndTime: string;
    issueSource: string;
    issueDescription: string;
    affectedPlatform: string;
    streamType: string;
    issueDetail: string;
    issueSeverity: string;
}

export interface IIssuesReportPayload {
    payload: {
        searchValue: string,
        pageNumber: number,
        itemsPerPage: number,
        from: string,
        to: string,
    },
    isScroll: boolean
}

export const getIssuesReport = createAsyncThunk<IFixtureIssue[], IIssuesReportPayload, { rejectValue: string }>(
    'issues - get - report',
    async (payload, {rejectWithValue, dispatch}) => {
        try {
            const {data, headers} = await client.post(`/api/Reports/reports/issues/search`, payload.payload);
            const totalCount = headers['x-total-count']
            dispatch(setTotalCount(Number(totalCount)))
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export interface IIssuesState {
    issuesReport: IFixtureIssue[]
    pageNumber: number
    itemsPerPage: number
    totalCount: number
    filters: {
        startDate: IDateData,
        endDate: IDateData
    }
}

const initialState: IIssuesState = {
    issuesReport: [],
    pageNumber: 1,
    itemsPerPage: 5,
    totalCount: 0,
    filters: {
        startDate: getPickerDateFormat(DateTime.now().minus({days: 3}).toISO()),
        endDate: getPickerDateFormat(DateTime.now().toISO()),
    }
}
export const reportingIssuesSlice = createSlice({
    name: 'reporting-issues',
    initialState,
    reducers: {
        setPageNumber: (state, action: PayloadAction<number>) => {
            state.pageNumber = action.payload
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload
        },
        resetFilters: (state) => {
            state.filters = {
                startDate: getPickerDateFormat(DateTime.now().minus({days: 3}).toISO()),
                endDate: getPickerDateFormat(DateTime.now().toISO()),
            }
        },
        setFilters: (state, action: PayloadAction<{ value: any; name: keyof IIssuesState["filters"] }>) => {
            state.filters[action.payload.name] = action.payload.value;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getIssuesReport.fulfilled, (state, action) => {
                if (action.meta.arg.isScroll) {
                    state.issuesReport.push(...action.payload)
                } else {
                    state.issuesReport = action.payload ? action.payload : []
                }
            })
    },
})
export const {setTotalCount, resetFilters, setFilters} = reportingIssuesSlice.actions
export default reportingIssuesSlice.reducer
