import React, {ReactNode} from "react";
import styles from "./index.module.scss";
import OperatorSortingHeader, {IColumnItem} from "../OperatorSortingHeader/OperatorSortingHeader";

import FavoriteStar from "../FavoriteStar/FavoriteStar";
import {addFavouriteItem, removeFavouriteItem} from "../../store/contentAnalysis/contentAnalysisSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {ContentItem} from "../../store/contentAnalysis/types";
import {
    IRightHolderPortfolio
} from "../../store/operatorRightsOverview/operatorRightsOverviewSlice";
import moment from "moment/moment";
import {useNavigate} from "react-router-dom";
import {Group} from "../../enums/group";

export interface ISorting {
    property: string;
    isAscending: boolean;
}

interface IOperatorDefaultTableProps {
    data: any;
    sorting?: ISorting;
    setSorting?: ((value: ISorting) => void);
    columnsData: IColumnItem[];
    renderActionCell?: (rowItem: any) => ReactNode;
    height?: string;
    withInfiniteScroll?: boolean;
    redirectionToPropertyDetailPage?: boolean;
    renderIndicator?: (rowItem: any) => ReactNode;
}

type SetFavProps = ContentItem & IRightHolderPortfolio;

const OperatorDefaultTable: React.FunctionComponent<IOperatorDefaultTableProps> = (
    {
        data,
        sorting,
        setSorting,
        columnsData,
        renderActionCell,
        height,
        withInfiniteScroll,
        redirectionToPropertyDetailPage,
        renderIndicator
    }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {role} = useAppSelector(state => state.userInfo);

    const setFavourite = (contentItem: SetFavProps) => {
        const isFav = contentItem.isPropertyFavourite || contentItem.isFavourite;
        const id = contentItem.propertyId || contentItem.rightId;

        if (isFav) {
            return dispatch(removeFavouriteItem(id));
        }
        dispatch(addFavouriteItem(id));
    }

    const goToPropertyDetail = (propertyId: string, propertyName: string) => {
        if (redirectionToPropertyDetailPage) {
            if (role === Group.IGame) {
                const clientId = localStorage.getItem("client");
                if (clientId) {
                    return navigate(`/cms/igame-operators/${clientId}/property-detail?propertyId=${propertyId}&propertyName=${propertyName}`);
                }
            } else {
                return navigate(`/cms/property-detail?propertyId=${propertyId}&propertyName=${propertyName}`);
            }
        } else {
            return;
        }
    }

    const setCellClass = (rowItem: any, key: string) => {
        const classes = [];

        if (rowItem.rightState === "Available") {
            classes.push(styles.greyCell);
        } else if (key === "property" && redirectionToPropertyDetailPage) {
            classes.push(styles.clickable);
        }

        return classes.join(" ");
    }

    const setFavouritePropertyCellClass = (rowItem: any) => {
        if (rowItem.rightState === "Available") {
            return styles.greyCell;
        } else {
            return "";
        }
    }

    const renderRowItem = (rowItem: any, cell: IColumnItem) => {
        if (cell.key === 'date') {
            return moment(rowItem[cell.key]).format("YYYY-MM-DD")
        } else if (cell.key === 'remainingSelections' && !rowItem[cell.key]) {
            return ''
        } else {
            return rowItem[cell.key]
        }
    }

    return (
        <div className={styles.tableContainer}>
            <div
                className={withInfiniteScroll ? `${styles.tableWrapper} ${styles.noTableScroll}` : `${styles.tableWrapper}`}
                style={{height}}>
                <table className={styles.operatorTable} data-testid="operator-table">
                    <OperatorSortingHeader
                        sorting={sorting}
                        setSorting={setSorting}
                        columnsData={columnsData}
                    />
                    <tbody>
                    {
                        data.map((rowItem: any, index: number) => (
                            <tr key={index}>
                                {
                                    columnsData.slice(0, -1).map((cell: IColumnItem, index) => {
                                            if (cell.favorite || cell.isPropertyFavourite) {
                                                return (
                                                    <td
                                                        className={setFavouritePropertyCellClass(rowItem)}
                                                        key={index}
                                                    >
                                                        <div
                                                            className={styles.favourite}
                                                            onClick={() => setFavourite(rowItem)}
                                                        >
                                                            <FavoriteStar
                                                                isActive={rowItem.isFavourite || rowItem.isPropertyFavourite}
                                                                fontSize={16}
                                                            />
                                                        </div>
                                                        <span
                                                            className={styles.clickable}
                                                            onClick={cell.key === "property" ? () => goToPropertyDetail(rowItem.propertyId, rowItem[cell.key]) : undefined}
                                                        >
                                                            {rowItem[cell.key]}
                                                        </span>
                                                    </td>
                                                )
                                            } else if (cell.key === "indicator" && renderIndicator) {
                                                return (
                                                    <td key={index}>
                                                        {renderIndicator(rowItem)}
                                                    </td>
                                                )
                                            } else {
                                                return (
                                                    <td
                                                        style={{textAlign: cell.textAlign}}
                                                        className={setCellClass(rowItem, cell.key)}
                                                        key={index}
                                                        onClick={cell.key === "property" ? () => goToPropertyDetail(rowItem.propertyId, rowItem[cell.key]) : undefined}
                                                    >
                                                        {renderRowItem(rowItem, cell)}
                                                    </td>
                                                )
                                            }
                                        }
                                    )
                                }

                                {renderActionCell ?
                                    <td key={crypto.randomUUID()}
                                        style={{textAlign: "center"}}
                                    >
                                        {renderActionCell(rowItem)}
                                    </td>
                                    :
                                    null
                                }
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

OperatorDefaultTable.displayName = "OperatorDefaultTable";
export default OperatorDefaultTable;
