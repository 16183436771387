import {IDropdownOption} from "../components/AnalyticsDashboard/types";

export const changeSelectInputLabels = (options: IDropdownOption[], labelChanges: { from: string, to: string }[]) => {
    return options.map((option) => {
        labelChanges.forEach((label) => {
            if (label.from === option.label) {
                option.label = label.to;
            }
        })
        return option;
    })
}
