import React, {useEffect, useState} from 'react';
import styles from "./index.module.scss";
import {Controller, useForm} from "react-hook-form";
import {useAppDispatch} from "../../hooks/hooks";
import Input from "../Input/Input";
import {hardDeleteUser, IUserItem, softDeleteUser} from "../../store/userManagement/userManagementSlice";
import Checkbox from "../Checkbox";
import {toastr} from "react-redux-toastr";
import {generateTypeOfUser} from "../../pages/Cms/IGameView/Settings/UserManagement/helpers/generateTypeOfUser";
import {PageTabs} from "../../pages/Cms/IGameView/Settings/UserManagement/UserManagement";


export interface DeleteUserConfirmationProps {
    closeConfirmModal: () => void;
    deletingUserId: string | undefined;
    userRole?: string
    user?: IUserItem
}


const DeleteUserConfirmation: React.FC<DeleteUserConfirmationProps> = (
    {
        closeConfirmModal,
        deletingUserId,
        user,
        userRole
    }) => {
    const {handleSubmit, formState: {errors}, control} = useForm();
    const [reason, setReason] = useState<string>('');
    const [hardDelete, setHardDelete] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (user) {
            if (user.isDeleted) {
                setHardDelete(true)
            }
        }
    }, [user]);

    const deleteUserHandler = () => {
        if (deletingUserId && reason && userRole) {
            const body = {
                userId: deletingUserId,
                reason,
                role: userRole
            }
            if (hardDelete) {
                dispatch(hardDeleteUser(body))
            } else {
                dispatch(softDeleteUser(body))
            }
        }
        closeConfirmModal();
    }
    const toggleIsHardDelete = () => {
        if (user) {
            if (user.isDeleted) {
                return toastr.warning("IGame", "Current user was soft deleted. You can delete user without recovery only")
            }
        }
        setHardDelete(!hardDelete)
    }
    return (
        <div className={styles.overlay} data-testid="delete-confirmation-modal">
            <form className={styles.form} onSubmit={handleSubmit(deleteUserHandler)}>
                <div className={styles.decor}>
                    <div className={styles.attention}>!</div>
                </div>
                <div className={styles.content}>
                    <p className={styles.message}>{
                        user?.email ? `Are you sure you want to delete ${generateTypeOfUser(userRole as PageTabs)}: ${user.email}?` :
                            "Are you sure you want to delete this user?"
                    }</p>

                    <div className={styles.reasonWrapper}>
                        <div className={styles.reasonInner}>

                            <label className={styles.label} htmlFor={'reason'}>Why do you want to delete this account?
                                <Controller
                                    control={control}
                                    name={'reason'}
                                    rules={{
                                        required: true,
                                        maxLength: 300,
                                        minLength: 10,
                                        pattern: /^[A-Za-z\s]+$/,
                                    }}
                                    render={({field: {onChange}}) => (
                                        <Input
                                            onChange={(e) => {
                                                onChange(e);
                                                setReason(e.target.value)
                                            }}
                                            value={reason}
                                            type="text"
                                            placeholder={"reason..."}
                                        />
                                    )}
                                />
                                {errors?.reason?.type === "required" &&
                                    <div className={styles.formErrorBlock}>Reason is required field</div>}
                                {errors?.reason?.type === "minLength" &&
                                    <div className={styles.formErrorBlock}>Reason should contain at least 10
                                        characters</div>}
                                {errors?.reason?.type === "maxLength" &&
                                    <div className={styles.formErrorBlock}>Reason should contain max 300
                                        characters</div>}
                                {errors?.reason?.type === "pattern" &&
                                    <div className={styles.formErrorBlock}>Reason should contain only letters</div>}
                            </label>
                        </div>
                    </div>

                    <Checkbox
                        onChangeHandler={() => toggleIsHardDelete()}
                        isChecked={hardDelete}
                        filled={true}
                    />

                    <span className={styles.checkboxLabel}>Delete user without the possibility of recovery</span>

                </div>

                <div className={styles.buttons}>
                    <button
                        onClick={closeConfirmModal}
                        className={styles.cancelButton}
                        data-testid="cancel-button"
                    >Cancel
                    </button>
                    <button className={styles.submitButton}>Confirm</button>
                </div>
            </form>
        </div>
    );
};

export default DeleteUserConfirmation;
