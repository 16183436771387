import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';
import PageHeader from "../../PageHeader/PageHeader";
import {IoClose} from "react-icons/io5";
import AppTableWhite from "../../AppTableWhite/AppTableWhite";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {ICartProps} from "../Cart/Cart";
import {RootState} from "../../../store";
import {getGroupedCartItems, setIsCartOpen} from "@store/carts/operatorCart/operatorCartSlice";

import uuid from 'react-uuid';
import CartExpandTableRows from "@components/Carts/OperatorCart/children/CartExpandTableRows/CartExpandTableRows";
import {getBFCartItems} from "@store/carts/operatorBFCart/bookFixtureCartSlice";


const OperatorCart: React.FC<ICartProps> = React.memo(({menuStructure}) => {
    const [operatorId, setOperatorId] = useState<string | null>(null);
    const [isOperatorCartActive, setIsOperatorCartActive] = useState<boolean>(false);
    const {id} = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const {cartItems} = useAppSelector((state: RootState) => state.operatorCart);
    const {bfCartItems} = useAppSelector((state: RootState) => state.bookFixtureCart);


    useEffect(() => {
        dispatch(setIsCartOpen(true));
        if (id) {
            setOperatorId(id)
        }

        if (location.pathname.includes('book-fixture-cart')) {
            fetchBookFixtureCartData();
        } else {
            fetchOperatorCartData();
            setIsOperatorCartActive(!isOperatorCartActive);
        }

        return () => {
            dispatch(setIsCartOpen(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const fetchBookFixtureCartData = () => {
        if (id) {
            dispatch(getBFCartItems(id))
        } else {
            dispatch(getBFCartItems(null));
        }
    }


    const fetchOperatorCartData = () => {
        if (id) {
            dispatch(getGroupedCartItems(id))
        } else {
            dispatch(getGroupedCartItems(null));
        }
    }

    const getCurrentCartUtems = () => {
        if (isOperatorCartActive) {
            return cartItems;
        } else {
            return bfCartItems;
        }
    }

    return (
        <>
            <PageHeader menuStructure={menuStructure} title={"Cart"}/>
            <div className={styles.checkoutPage}>
                <div className={styles.pageContainer}>
                    <div className={styles.upperSection}>
                        <h1 className={styles.checkoutTitle}>
                            {isOperatorCartActive ? 'Cart' : 'Book Fixture Cart'}
                        </h1>
                        <p className={styles.checkoutDescription}>
                            {isOperatorCartActive && cartItems.length !== 0 && "Here are the items you have selected for ingest."}
                            {!isOperatorCartActive && bfCartItems.length !== 0 && "Here are the items you have selected for booking."}
                        </p>
                        <button
                            className={styles.closeButton}
                            onClick={() => navigate(-1)}>
                            <IoClose className={styles.closeIcon}/>
                        </button>
                    </div>
                    <div className={styles.mainContentWrapper}>
                        <div className={styles.tableContainer}>
                            {getCurrentCartUtems().length === 0
                                ?
                                <p className={styles.cartEmptyText}>Your cart is empty at the moment.</p>
                                :
                                <div className={styles.tableWrapper}>
                                    <AppTableWhite>
                                        <thead>
                                        <tr>
                                            <th className={styles.providerInner}>Provider</th>
                                            <th className={styles.sportInner}>Sport</th>
                                            <th className={styles.propertyInner}>Property</th>
                                            <th className={styles.availableFixturesInner}>
                                                {isOperatorCartActive ? 'Available Fixtures' : 'Fixture Selection'}
                                            </th>
                                            {isOperatorCartActive &&
                                                <th className={styles.fixtureCountInner}>Requested Fixture Count</th>
                                            }
                                            <th className={styles.deleteCol}>Delete</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {getCurrentCartUtems().map((providerItem, index) => (
                                            <React.Fragment key={index}>
                                                {providerItem.sports.map((sport, sportIndex) => (
                                                    <React.Fragment key={sportIndex}>
                                                        {sport.properties.map((property, propertyIndex) => (
                                                            <CartExpandTableRows
                                                                propertyIndex={propertyIndex}
                                                                sport={sport}
                                                                sportIndex={sportIndex}
                                                                providerItem={providerItem}
                                                                property={property}
                                                                operatorId={operatorId}
                                                                key={uuid()}
                                                                isOperatorCartActive={isOperatorCartActive}
                                                            />
                                                        ))}
                                                    </React.Fragment>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                        </tbody>
                                    </AppTableWhite>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

export default OperatorCart;
