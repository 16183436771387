import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import styles from "./index.module.scss";
import AddUserButton from "../AddUserButton/AddUserButton";
import RightHolderForm from "../UserTypesForms/RightHolderForm/RightHolderForm";
import OperatorForm from "../UserTypesForms/OperatorForm/OperatorForm";
import {useAppDispatch, useAppSelector} from "../../../../../../../hooks/hooks";
import {useNavigate} from "react-router-dom";
import {
    getOperatorDetails,
    getOperatorsInitData,
    getRightHolderDetails,
    getRightHoldersInitData,
    deleteRightHolder,
    deleteOperator,
    clearRightHolderDetails, clearOperatorDetails,
} from "../../../../../../../store/userManagement/userManagementSlice";
import Loader from "../../../../../../../components/Loader/Loader";
import ConfirmModal from "../../../../../../../components/ConfirmModal/ConfirmModal";
import {PageTabs} from "../../UserManagement";
import {InnerRole} from "../../../../../../../enums/innerRole";
import {useDisableScroll} from "../../../../../../../hooks/useDisableScroll";
import {generateTypeOfUser} from '../../helpers/generateTypeOfUser';
import trash from "../../../../../../../img/trashWhite.svg";
import defaultLogo from "../../../../../../../img/defaultUserLogo.svg";


interface IDeleteUser {
    id: string,
    name: string,
    role: string
}

export interface IUserManagementTable {
    userRole: string;
    setCurrentTab: Dispatch<SetStateAction<string>>;
    setUserIdInTab: Dispatch<SetStateAction<string | undefined>>;
}

const UserManagementTable: React.FC<IUserManagementTable> = React.memo((
    {
        userRole,
        setCurrentTab,
        setUserIdInTab
    }) => {
    const [isOpenRightsHolderForm, setIsOpenRightsHolderForm] = useState<boolean>(false);
    const [isOpenOperatorForm, setIsOpenOperatorForm] = useState<boolean>(false);
    const [deletingUser, setDeletingUser] = useState<IDeleteUser | undefined>(undefined);
    const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {rightHolders, operators, loading} = useAppSelector(state => state.userManagement);
    const {specificRole} = useAppSelector(state => state.userInfo);
    useDisableScroll(isOpenOperatorForm, isOpenRightsHolderForm, isOpenDeleteConfirm);


    useEffect(() => {
        dispatch(getOperatorsInitData(null));
        dispatch(getRightHoldersInitData(null));
        setCurrentTab(userRole)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);


    const openAddFormHandler = (buttonName: string) => {
        if (buttonName === PageTabs.RIGHTS_HOLDERS) {
            setIsOpenRightsHolderForm(!isOpenRightsHolderForm)
        } else if (buttonName === PageTabs.OPERATORS) {
            setIsOpenOperatorForm(!isOpenOperatorForm)
        }
    }

    const openEditFormHandler = (buttonName: string, userId: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        if (buttonName === PageTabs.RIGHTS_HOLDERS) {
            dispatch(getRightHolderDetails(userId));
            setIsOpenRightsHolderForm(true);

        } else if (buttonName === PageTabs.OPERATORS) {
            dispatch(getOperatorDetails(userId));
            setIsOpenOperatorForm(true);
        }
    }

    const clickUserTileHandler = (userId: string, tab: string) => {
        setUserIdInTab(userId)
        navigate(`${userId}`);
        setCurrentTab(tab)
    }

    const openDeleteConfirmHandler = (userId: string, userName: string, userRole: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setDeletingUser({
            id: userId,
            name: userName,
            role: userRole
        });
        setIsOpenDeleteConfirm(true);
    }

    const deleteUserHandler = () => {
        if (deletingUser && deletingUser.role === PageTabs.RIGHTS_HOLDERS) {
            dispatch(deleteRightHolder(deletingUser.id));
        } else if (deletingUser && deletingUser.role === PageTabs.OPERATORS) {
            dispatch(deleteOperator(deletingUser.id));
        }
    }

    const closeForm = () => {
        if (isOpenRightsHolderForm) {
            setIsOpenRightsHolderForm(false);
            dispatch(clearRightHolderDetails());
        } else if (isOpenOperatorForm) {
            setIsOpenOperatorForm(false);
            dispatch(clearOperatorDetails());
        }
    };

    return (
        <div className={styles.pageContainer}>
            {loading && <Loader/>}
            {!loading && <>
                {isOpenRightsHolderForm && <RightHolderForm closeForm={closeForm}/>}

                {isOpenOperatorForm && <OperatorForm closeForm={closeForm}/>}

                {isOpenDeleteConfirm && <ConfirmModal
                    title={`Delete ${userRole}`}
                    closeForm={() => setIsOpenDeleteConfirm(false)}
                    action={deleteUserHandler}
                    message={`Are you sure you want to delete ${generateTypeOfUser(userRole as PageTabs)}: ${deletingUser?.name}?`}
                >
                    <img className={styles.deleteIcon} src={trash} alt="trash"/>
                </ConfirmModal>
                }

                <div className={styles.tableOverlay}>
                    <div className={styles.tableInner}>
                        <div className={styles.optionsContainer}>
                            {
                                specificRole === InnerRole.SuperAdmin &&
                                <AddUserButton
                                    buttonName={userRole}
                                    onClickHandler={() => openAddFormHandler(userRole)}
                                />
                            }
                        </div>
                        {operators.length === 0 && userRole === PageTabs.OPERATORS &&
                            <div className={styles.searchMessage}>No results...</div>
                        }
                        {rightHolders.length === 0 && userRole === PageTabs.RIGHTS_HOLDERS &&
                            <div className={styles.searchMessage}>No results...</div>
                        }

                        <div className={styles.usersWrapper}>
                            {userRole === PageTabs.OPERATORS ?
                                operators.map(operator => (
                                    <div className={styles.userItem} key={operator.id}
                                         onClick={() => clickUserTileHandler(operator.id, PageTabs.OPERATORS)}>
                                        <div className={styles.userName}>
                                            <p>{operator.name}</p>
                                            {operator.logo &&
                                                <img className={styles.userLogo} src={operator.logo} alt="LOGO"/>
                                            }
                                            {!operator.logo &&
                                                <div className={styles.defaultLogoWrapper}>
                                                    <img className={styles.userLogo} src={defaultLogo} alt="LOGO"/>
                                                </div>
                                            }
                                        </div>
                                        {
                                            specificRole === InnerRole.SuperAdmin &&
                                            <div className={styles.buttonsContainer}>
                                                <button
                                                    className={styles.editButton}
                                                    onClick={openEditFormHandler(`${userRole}`, operator.id)}
                                                >Edit info
                                                </button>
                                                <button
                                                    className={styles.deleteButton}
                                                    onClick={openDeleteConfirmHandler(operator.id, operator.name, PageTabs.OPERATORS)}
                                                >Delete
                                                </button>
                                            </div>
                                        }
                                    </div>
                                ))
                                :
                                rightHolders.map(rightHolder => (
                                    <button
                                        className={styles.tileInner}
                                        key={rightHolder.id}
                                        onClick={() => clickUserTileHandler(rightHolder.id, PageTabs.RIGHTS_HOLDERS)}>
                                        <div className={styles.userItem} key={rightHolder.id}>
                                            <div className={styles.userName}>
                                                <p>{rightHolder.name}</p>
                                                {rightHolder.logo &&
                                                    <img className={styles.userLogo} src={rightHolder.logo}
                                                         alt="LOGO"/>}
                                                {!rightHolder.logo &&
                                                    <div className={styles.defaultLogoWrapper}>
                                                        <img className={styles.userLogo} src={defaultLogo} alt="LOGO"/>
                                                    </div>
                                                }
                                            </div>
                                            {
                                                specificRole === InnerRole.SuperAdmin &&
                                                <div className={styles.buttonsContainer}>
                                                    <button className={styles.editButton}
                                                            onClick={openEditFormHandler(`${userRole}`, rightHolder.id)}
                                                    >Edit info
                                                    </button>
                                                    <button className={styles.deleteButton}
                                                            onClick={openDeleteConfirmHandler(rightHolder.id, rightHolder.name, PageTabs.RIGHTS_HOLDERS)}
                                                    >Delete
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </button>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </>}
        </div>
    );
});

UserManagementTable.displayName = "UserManagementTable"
export default UserManagementTable;
