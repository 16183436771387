import {IFormData} from "../GeneralIssueForm";
import {formatStringArrayFromOptions} from "./formatStringArrayFromOptions";
import {IGeneralIssue} from "../../../../../../../store/generalIssues/generalIssuesSlice";
import moment from "moment";

interface ICreateGeneralIssuePayload {
    formData: IFormData;
    issueTime: {
        start: string,
        end: string | undefined
    };
    isUnresolved: boolean;
}

export const createPayload = (props: ICreateGeneralIssuePayload): IGeneralIssue => {

    const {formData, issueTime, isUnresolved} = props;
    let fault = 0;
    let platform = 0;
    let streamType = 0;

    if (formData.fault) {
        fault = +formData.fault.value;
    }

    if (formData.affectedPlatform) {
        platform = +formData.affectedPlatform.value;
    }

    if (formData.protocol) {
        streamType = +formData.protocol.value;
    }

    return {
        issueDescription: formData.description,
        issueStartTime: moment(issueTime.start, 'DD/MM/YYYY HH:mm').utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        fault,
        platform,
        streamType,
        rightHolders: formatStringArrayFromOptions(formData.rightHolder ? formData.rightHolder : []),
        contentSuppliers: formatStringArrayFromOptions(formData.contentSupplier ? formData.contentSupplier : []),
        unresolved: isUnresolved,
        detail: +formData.detail.value,
        severity: +formData.severity.value,
    };
}
