import React from 'react';

export const mapAllowedRoutes = (routes: any[]) => {
    return (
        <>
            {routes.map((route, index) => {
                return (
                    <React.Fragment key={index}>
                        {route.route}
                    </React.Fragment>

                )
            })}
        </>
    );
};
