import {appPages} from "../appPages/appPages";

export const formatAllowedPages = (allowedPages: number[]): string[] => {
    const allPages: string[] = [];

    for (const number of allowedPages) {
        if (appPages.hasOwnProperty(number)) {
            allPages.push(appPages[number]);
        }
    }
    return allPages;
}
