import React, {Dispatch, SetStateAction, useState} from 'react';
import {Controller, FieldValues, SubmitHandler, useForm} from "react-hook-form";
import Input from "../../components/Input/Input";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {resetUserPassword} from "../../store/settings/settingsSlice";
import devicesLogo from "../../img/devices.svg";
import close from "../../img/close.svg";
import styles from "./index.module.scss";

interface IForgotPasswordPopupProps {
    setFormVisibility: Dispatch<SetStateAction<boolean>>;
}

interface IForgotPasswordForm {
    email: string;
}

const ForgotPassword: React.FunctionComponent<IForgotPasswordPopupProps> = ({setFormVisibility}) => {
    const dispatch = useAppDispatch();
    const {loading} = useAppSelector(state => state.settings)
    const {handleSubmit, formState: {errors}, control} = useForm<IForgotPasswordForm>();
    const [email, setEmail] = useState("");

    const onSubmit: SubmitHandler<FieldValues> = () => {
        if (!loading) {
            const payload = {email}
            dispatch(resetUserPassword(payload));
            setTimeout(() => {
                setFormVisibility(false);
            }, 1000)
        }
    };

    return (
        <div className={styles.formOverlay}>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.forgotPasswordForm}>
                <div className={styles.mainSection}>
                    <div className={styles.mainSectionDevicesLogo}>
                        <img src={devicesLogo} alt="email-logo"/>
                    </div>
                    <div className={styles.mainSectionFormItems}>
                        <button className={styles.formCloseButton} onClick={(e) => {
                            e.preventDefault()
                            setFormVisibility(false)
                        }}>
                            <img src={close} alt="close"/>
                        </button>
                        <h6 className={styles.formTitle}>Forgot your password?</h6>
                        <p className={styles.formSubtitle}>
                            Please enter the email address associated with your
                            OneMap account and confirmation link for password reset will be sent to you.
                        </p>
                        <label className={styles.inputLabel} htmlFor="email">
                            Email
                            <Controller
                                control={control}
                                name="email"
                                rules={{
                                    required: true,
                                    maxLength: 40,
                                    minLength: 8,
                                    pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                }}
                                render={({field: {onChange}}) => (
                                    <Input
                                        name="email"
                                        id="email"
                                        className={styles.emailInput}
                                        onChange={(e) => {
                                            onChange(e);
                                            setEmail(e.target.value)
                                        }}
                                        value={email}
                                        type="text"
                                        placeholder={"email@domain.com"}
                                    />
                                )}
                            />
                            {errors?.email?.type === "required" &&
                                <div className={styles.formError}>Email is required field</div>}
                            {errors?.email?.type === "minLength" &&
                                <div className={styles.formError}>Email should contain at least 10
                                    characters</div>}
                            {errors?.email?.type === "maxLength" &&
                                <div className={styles.formError}>Email should contain max 40 characters</div>}
                            {errors?.email?.type === "pattern" &&
                                <div className={styles.formError}>Email should be like example@xxx.xx</div>}
                        </label>
                    </div>
                </div>
                <div className={styles.buttons}>
                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            setFormVisibility(false)
                        }}
                        className={styles.cancelButton}>
                        Cancel
                    </button>
                    <button
                        className={styles.submitButton}
                        type="submit">
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ForgotPassword;
