import React, {useEffect, useMemo, useState} from "react";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import "react-calendar-datetime-picker/dist/style.css";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import styles from "./index.module.scss";
import {ISorting, ITableRowData} from "../../BookFixtures";
import {useAppDispatch, useAppSelector} from "@hooks/hooks";
import {RootState} from "@store/index";
import {formatDate, getPickerDateFormat} from "@helpers/dateFormatters";
import {
    addFixture,
    IFixturePayload,
    IGetFixturesPayload,
    IUpdateFixturePayload,
    updateFixture
} from "@store/rightHolderBooking/rightHolderBookingSlice";
import SelectInput from "@components/SelectInput/SelectInput";
import DatePicker from "@components/DatePicker/DatePicker";
import Input from "@components/Input/Input";
import {DateTime} from "luxon";
import {MultiValue, SingleValue} from "react-select";
import {formatCompetitionsOptions} from "../../helpers/formatCompetitionsOptions";
import moment from "moment";
import {client} from "@services/api-servise";
import {AxiosError} from "axios";
import {toastr} from "react-redux-toastr";
import {IDropdownOption} from "@components/AnalyticsDashboard/types";
import UploadSCVForm from "../UploadSCVForm/UploadSCVForm";
import FormTabs from "./components/FormTabs/FormTabs";
import {formatRestrictedCountries} from "@helpers/formatRestrictedCountries";
import {formatSelectOptions} from "@helpers/formatSelectOptions";
import {homeAwayNationsCode} from "../../../../GeoRestrictions/OperatorGeoRestrictions/OperatorGeoRestrictions";


export interface IAddFixtureFormProps {
    closeForm: () => void;
    editedFixture: any;
    setEditedFixture: (fixture: ITableRowData | null) => void;
    rightHolderId: string | null;
    getSortedItems?: () => void;
    isUpcoming: boolean;
    searchWord: string;
    sorting: ISorting;
    sport: MultiValue<{ value: string, label: string }>
}

export interface IDate {
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
}

export interface IAddFormInputs {
    title: string;
    description: string;
    competition: string;
    sport: string;
    homeRegion: string;
    awayRegion: string;
    restrictedCountries: string;
    startDate: IDate;
    endDate: IDate;
    isUnknownTeams: boolean;
    extraTime: boolean;
    contentSupplier: string;
}

export interface ICompetitionOptionType {
    value: string;
    label: string;
    duration: number;
    extraTimeDuration: number;
    country: string;
}

export interface IFormTab {
    createFixtureForm: boolean
    uploadSCVForm: boolean
}

const AddFixtureForm: React.FunctionComponent<IAddFixtureFormProps> = React.memo((
    {
        closeForm,
        setEditedFixture,
        editedFixture,
        rightHolderId,
        getSortedItems,
        isUpcoming,
        searchWord,
        sorting,
        sport
    }) => {
    const dispatch = useAppDispatch();
    const initData = useAppSelector((state: RootState) => state.initInfo.source);
    const {loadingStatus, pageNumber, itemsPerPage} = useAppSelector((state: RootState) => state.rightHolderBooking);
    const [blockedCountries, setBlockedCountries] = useState<MultiValue<{ label: string; value: string; }>>([]);
    const [isValidDate, setIsValidDate] = useState(true);
    const [isFixtureTimeEnd, setIsFixtureTimeEnd] = useState(false);
    const [selectedCompetition, setSelectedCompetition] = useState<SingleValue<ICompetitionOptionType> | undefined>(undefined);
    const [selectedStartDate, setSelectedStartDate] = useState<IDate | null>(editedFixture ? null : getPickerDateFormat(DateTime.now().toISO(), true));
    const [estimatedEndDate, setEstimatedEndDate] = useState<IDate | null>(editedFixture ? null : getPickerDateFormat(DateTime.now().toISO(), true));
    const [isCompetitionChanged, setIsCompetitionChanged] = useState<boolean>(false);
    const competitions = formatCompetitionsOptions(initData?.competitions);
    const sports = formatSelectOptions(initData?.sports?.items);
    const regions = formatSelectOptions(initData?.countries?.items);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [formTabState, setFormTabState] = useState<IFormTab>({createFixtureForm: true, uploadSCVForm: false});

    useEffect(() => {
        if (editedFixture) {
            setSelectedCompetition(getCompetitionObject(formatCompetitionsOptions(initData?.competitions), editedFixture.competition));
            setEstimatedEndDate(getPickerDateFormat(editedFixture.endDate))
            setSelectedStartDate(getPickerDateFormat(editedFixture.startDate))

            checkEditingFixtureDate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedFixture])


    useEffect(() => {
        return () => {
            setEditedFixture(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (selectedStartDate && selectedCompetition && isCompetitionChanged) {
            const formattedStartDate = {...selectedStartDate};
            formattedStartDate.month -= 1;

            const newEndDate = watchExtraTime ?
                moment(formattedStartDate)
                    .add(selectedCompetition.duration, 'minutes')
                    .add(selectedCompetition.extraTimeDuration, 'minutes').utc().format()
                :
                moment(formattedStartDate)
                    .add(selectedCompetition.duration, 'minutes').utc().format()

            setEstimatedEndDate(getPickerDateFormat(newEndDate));
            setValue("endDate", getPickerDateFormat(newEndDate));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStartDate])


    useEffect(() => {
        if (selectedCompetition && !editedFixture) {
            setValue("homeRegion", selectedCompetition.country);
            setValue("awayRegion", selectedCompetition.country);
            setEstimatedEndDate(getPickerDateFormat(DateTime.now().toISO(), true));
            setSelectedStartDate(getPickerDateFormat(DateTime.now().toISO(), true))

        } else if (isCompetitionChanged) {
            setEstimatedEndDate(getPickerDateFormat(DateTime.now().toISO(), true));
            setSelectedStartDate(getPickerDateFormat(DateTime.now().toISO(), true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompetition]);

    const checkEditingFixtureDate = () => {
        if (editedFixture) {
            const fixtureEndTime = DateTime.fromISO(editedFixture.endDate);
            const currentTime = DateTime.now();

            if (currentTime > fixtureEndTime) {
                setIsFixtureTimeEnd(true)
            }
        }
    }

    const setBlockedCountriesByCompetition = async (competitionId: string) => {
        try {
            const {data} = await client.get(`api/RightsManagement/geo-restrictions?&CompetitionId=${competitionId}&RightHolderId=${rightHolderId}`);

            if (data.isHomeAwayNationsGeoBlocked) {
                return setBlockedCountries(formatRestrictedCountries(formatSelectOptions(data.geoBlockedCountries)));
            } else {
                return setBlockedCountries(formatSelectOptions(data.geoBlockedCountries))
            }

        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data.title}`)
            }
        }
    }

    const setBlockedCountryByEditFixture = () => {
        if (editedFixture) {
            const blockedCountries = editedFixture.geoBlockCountries
            const filteredBlockedCountries = initData?.countries?.items.filter(country => blockedCountries.includes(country.code))

            if (editedFixture.isHomeAwayNationsGeoBlocked) {
                setBlockedCountries(formatRestrictedCountries(formatSelectOptions(filteredBlockedCountries)));
            } else {
                setBlockedCountries(formatSelectOptions(filteredBlockedCountries));
            }
        } else {
            setBlockedCountries([])
        }
    }

    const {
        register,
        watch,
        handleSubmit,
        formState: {errors},
        control,
        getValues,
        setError,
        setValue,
    } = useForm<IAddFormInputs>({
        defaultValues: useMemo(() => {
            if (editedFixture !== null) {
                setBlockedCountryByEditFixture()
                return {
                    ...editedFixture,
                    startDate: null,
                    endDate: null,
                };
            } else {
                return {
                    title: "",
                    description: "",
                    competition: "",
                    homeRegion: "",
                    awayRegion: "",
                    startDate: null,
                    endDate: null,
                    isUnknownTeams: false,
                    extraTime: false
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [editedFixture])
    });

    const watchTitle = watch("title", '');
    const watchExtraTime = editedFixture ? watch("extraTime", editedFixture.extraTime) : watch("extraTime", false);

    useEffect(() => {
        if (watchTitle === "TBD vs TBD") {
            setValue("isUnknownTeams", true);
        } else {
            setValue("isUnknownTeams", false);
        }
    }, [watchTitle, setValue]);


    useEffect(() => {
        if (selectedCompetition) {
            const endDate = getValues('endDate');
            const formattedEndDate = {...endDate};
            formattedEndDate.month -= 1;

            const newEndDate = watchExtraTime ?
                moment(formattedEndDate).add(selectedCompetition.extraTimeDuration, 'minutes').utc().format() :
                moment(formattedEndDate).subtract(selectedCompetition.extraTimeDuration, 'minutes').utc().format();

            setValue("endDate", getPickerDateFormat(newEndDate));
            setEstimatedEndDate(getPickerDateFormat(newEndDate))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchExtraTime])


    useEffect(() => {
        handleChange();
        if (inputRef!.current !== null) {
            inputRef.current.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeUnknownTeam = () => {
        const isUnknownTeams = getValues('isUnknownTeams');

        if (!isUnknownTeams) {
            setValue('title', "TBD vs TBD");
        } else {
            setValue('title', "");
        }
        setValue('isUnknownTeams', !isUnknownTeams);
    }

    const collectBlockedCountries = () => {
        const result: string[] = initData.countries.items
            .filter(item => blockedCountries.some((geoItem: { value: string; }) => item.id === geoItem.value))
            .map(item => item.code);

        return result;
    };

    const handleChange = () => {
        setIsValidDate(Object.values(getValues()).some(value => value === undefined || value === ""));
    }

    const onSubmit: SubmitHandler<IAddFormInputs> = (data) => {
        if (loadingStatus === 'loading') {
            return;
        }

// eslint-disable-next-line no-mixed-operators
        if (DateTime.now() > DateTime.fromISO(formatDate(data.startDate)) ||
            DateTime.now() > DateTime.fromISO(formatDate(data.endDate)) ||
            formatDate(DateTime.now()) === formatDate(data.startDate)) {

            setError('startDate', {type: 'date', message: "Dates can't be set earlier than current date"});
            return;
        }
        if (!isValidDate && DateTime.fromISO(formatDate(data.startDate)) > DateTime.fromISO(formatDate(data.endDate))) {
            setError('startDate', {type: 'date', message: "End date can't be earlier than start date"});
            return;
        }

        const fixtureModel = {
            rightHolderId,
            title: data.title,
            description: data.description,
            competitionId: data.competition,
            homeRegionId: setInputValue(regions, data.homeRegion),
            awayRegionId: setInputValue(regions, data.awayRegion),
            startDate: formatDate(data.startDate),
            endDate: formatDate(data.endDate),
            extraTime: data.extraTime,
            geoBlockCountries: collectBlockedCountries(),
            isHomeAwayNationsGeoBlocked: blockedCountries.some(country => country.value === homeAwayNationsCode)
        }

        const updatedFixtureModel = {
            rightHolderId,
            title: data.title,
            description: data.description,
            competitionId: setInputValue(competitions, data.competition),
            sportId: setInputValue(sports, data.sport),
            homeRegionId: setInputValue(regions, data.homeRegion),
            awayRegionId: setInputValue(regions, data.awayRegion),
            startDate: formatDate(data.startDate),
            endDate: formatDate(data.endDate),
            geoBlockCountries: collectBlockedCountries(),
            extraTime: data.extraTime,
            isHomeAwayNationsGeoBlocked: blockedCountries.some(country => country.value === homeAwayNationsCode)
        }

        let requestPayload: IGetFixturesPayload = {
            rightHolderId: rightHolderId || null,
            searchText: searchWord,
            sorting: {
                property: sorting.criteria ? sorting.criteria : "",
                isAscending: sorting.isAscending
            },
            sportIds: sport.map(item => item.value),
            upcoming: isUpcoming,
            pageNumber,
            itemsPerPage
        }

        if (!editedFixture) {
            const addFixtureRequestData = {...fixtureModel};

            const payload: IFixturePayload = {
                fixture: addFixtureRequestData,
                closeForm,
                getSortedFixturesPayload: {payload: requestPayload}
            }
            dispatch(addFixture(payload));
        } else {
            const payload: IUpdateFixturePayload = {
                fixture: updatedFixtureModel,
                id: editedFixture.id,
                ingestType: editedFixture.ingestType,
                closeForm,
                getSortedFixturesPayload: {payload: requestPayload}
            }
            dispatch(updateFixture(payload));
        }
    }

    const handleFormClose = () => {
        closeForm();
    }

    const setInputValue = (options: IDropdownOption[] | undefined, val: string) => {
        if (options?.find(option => option.label === val)) {
            return options?.find(option => option.label === val)!.value;
        } else if (options?.find(option => option.value === val)) {
            return options?.find(option => option.value === val)!.value;
        } else {
            return "";
        }
    }

    const setInputLabel = (options: IDropdownOption[] | undefined, value: string) => {
        if (options?.find(option => option.label === value)) {
            return options?.find(option => option.label === value)!.label;
        } else if (options?.find(option => option.value === value)) {
            return options?.find(option => option.value === value)!.label;
        } else {
            return "";
        }
    }

    const getCompetitionObject = (competitions: ICompetitionOptionType[], competitionLabel: string) => {
        const currentCompetition = competitions.find(comp => comp.label === competitionLabel);
        if (currentCompetition) {
            return {
                value: competitionLabel,
                label: setInputLabel(competitions, competitionLabel),
                duration: currentCompetition.duration,
                extraTimeDuration: currentCompetition.extraTimeDuration,
                country: currentCompetition.country
            }
        } else {
            return {
                value: "",
                label: "",
                duration: 0,
                extraTimeDuration: 0,
                country: ""
            }
        }
    }

    const options = formatRestrictedCountries(regions)

    return (
        <div className={styles.overlay}>
            {formTabState.createFixtureForm &&
                <form
                    className={styles.form}
                    action=""
                    onSubmit={handleSubmit(onSubmit)}
                >
                    {!isUpcoming && !editedFixture &&
                        <FormTabs tabsState={formTabState} setTabsState={setFormTabState}/>
                    }

                    {editedFixture !== null &&
                        <h1 className={styles.editFormTitle}>Edit Fixture</h1>
                    }
                    <div className={styles.contentWrapper}>
                        <div className={styles.inputsWrapper}>
                            <label>Title
                                <Controller
                                    control={control}
                                    name="title"
                                    rules={{
                                        required: true,
                                        minLength: 2,
                                        maxLength: 150,
                                        pattern: {
                                            value: /[^\s]/,
                                            message: "Field can not contain only spaces"
                                        }
                                    }}
                                    render={({
                                                 field: {onChange, value},
                                                 formState: {errors},
                                             }) => (
                                        <Input
                                            onChange={val => {
                                                onChange(val);
                                                handleChange();
                                            }}
                                            value={value}
                                            type="text"
                                            aria-invalid={errors.title ? "true" : "false"}
                                            disabled={isFixtureTimeEnd}
                                        />
                                    )}
                                />
                                {
                                    errors.title && errors.title.type === "required"
                                    && <span
                                        className={styles.error}
                                        data-testid={"titleRequiredError"}>
                                    This field is required
                                </span>
                                }
                                {
                                    errors.title && errors.title.type === "minLength"
                                    && <span className={styles.error} data-testid={"titleMinimumLengthError"}>
                                    This field can not contain less than 2 symbols
                                </span>
                                }
                                {
                                    errors.title && errors.title.type === "maxLength"
                                    && <span className={styles.error} data-testid={"titleMaximumLengthError"}>
                                    This field can not contain more than 150 symbols
                                </span>
                                }
                                {
                                    errors.title && errors.title.type === "pattern"
                                    && <span
                                        className={styles.error}
                                        data-testid={"titleOnlySpacesError"}>
                                    {errors.title.message}
                                </span>
                                }
                            </label>
                            <label>Description
                                <Controller
                                    control={control}
                                    name="description"
                                    rules={{
                                        maxLength: 150,
                                    }}
                                    render={({
                                                 field: {onChange, value},
                                                 formState: {errors},
                                             }) => (
                                        <Input
                                            onChange={val => {
                                                onChange(val);
                                            }}
                                            value={value}
                                            type="text"
                                            aria-invalid={errors.description ? "true" : "false"}
                                            disabled={isFixtureTimeEnd}
                                        />
                                    )}
                                />
                                {
                                    errors.description && errors.description.type === "maxLength"
                                    && <span className={styles.error} data-testid={"titleMaximumLengthError"}>
                                    This field can not contain more than 150 symbols
                                </span>
                                }
                            </label>
                            <label>Property
                                <Controller
                                    control={control}
                                    name="competition"
                                    rules={{required: true}}
                                    render={({
                                                 field: {onChange},
                                             }) => (
                                        <SelectInput
                                            options={formatCompetitionsOptions(initData?.competitions)}
                                            onChange={x => {
                                                onChange(x!.value);
                                                handleChange();
                                                setIsCompetitionChanged(true)
                                                setSelectedCompetition(x);
                                                setBlockedCountriesByCompetition(x!.value)
                                            }}
                                            value={selectedCompetition}
                                            disabled={!!editedFixture}
                                        />
                                    )}
                                />
                                {
                                    errors.competition && errors.competition.type === "required"
                                    && <span className={styles.error} data-testid={"competitionRequiredError"}>
                                    This field is required
                                </span>
                                }
                            </label>
                            <label>Home Region
                                <Controller
                                    control={control}
                                    name="homeRegion"
                                    rules={{required: true}}
                                    render={({field: {onChange, value}}) => (
                                        <SelectInput
                                            options={formatSelectOptions(initData?.countries?.items)}
                                            onChange={x => {
                                                onChange(x!.value)
                                                handleChange();
                                            }}
                                            value={{value, label: setInputLabel(regions, value)}}
                                            disabled={isFixtureTimeEnd}
                                        />
                                    )}
                                />
                                {
                                    errors.homeRegion && errors.homeRegion.type === "required"
                                    && <span className={styles.error} data-testid={"homeRegionRequiredError"}>
                                    This field is required
                                </span>
                                }
                            </label>
                            <label>Away Region
                                <Controller
                                    control={control}
                                    name="awayRegion"
                                    rules={{required: true}}
                                    render={({
                                                 field: {onChange, value},
                                             }) => (
                                        <SelectInput
                                            options={formatSelectOptions(initData?.countries?.items)}
                                            onChange={x => {
                                                onChange(x!.value)
                                                handleChange();
                                            }}
                                            value={{value, label: setInputLabel(regions, value)}}
                                            disabled={isFixtureTimeEnd}
                                        />
                                    )}
                                />
                                {
                                    errors.awayRegion && errors.awayRegion.type === "required"
                                    && <span className={styles.error} data-testid={"awayRegionRequiredError"}>
                                    This field is required
                                </span>
                                }
                            </label>
                            <label>Restricted Countries
                                <Controller
                                    name="restrictedCountries"
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <SelectInput
                                            showExpander
                                            onChange={(e) => onChange(e)}
                                            value={value}
                                            options={options}
                                            isMulti={true}
                                            placeholder={"Select content supplier"}
                                            closeMenuOnSelect={false}
                                            maxItemsToShowExpander={10}
                                            itemsLength={value ? value.length : 0}
                                            disabled={isFixtureTimeEnd}
                                        />
                                    )}
                                />
                            </label>
                            <div className={styles.dateSection}>
                                <div className={styles.calendar}>Start Date
                                    <div className={styles.calendarInputWrapper}>
                                        <Controller
                                            control={control}
                                            name="startDate"
                                            rules={{required: true}}
                                            render={({field: {onChange}}) => (
                                                <DatePicker
                                                    onChange={(date) => {
                                                        onChange(date)
                                                        handleChange();
                                                        setSelectedStartDate(date);
                                                    }}
                                                    placeholder="Select start date..."
                                                    modalPositioningClass="calendar-modal-up"
                                                    initValue={selectedStartDate}
                                                />
                                            )}
                                        />
                                    </div>
                                    {
                                        errors.startDate && errors.startDate.type === "required"
                                        && <span className={styles.error}>This field is required</span>
                                    }
                                </div>


                                <div className={styles.calendar}>End Date
                                    <div className={styles.calendarInputWrapper}>
                                        <Controller
                                            control={control}
                                            name="endDate"
                                            rules={
                                                {
                                                    required: true,
                                                    validate: {
                                                        validEndDate: (date) =>
                                                            DateTime.fromISO(formatDate(getValues("startDate"))) <
                                                            DateTime.fromISO(formatDate(date))
                                                    }
                                                }
                                            }
                                            render={({field: {onChange}}) => (
                                                <DatePicker
                                                    onChange={date => {
                                                        onChange(date)
                                                        handleChange();
                                                    }}
                                                    modalPositioningClass="calendar-modal-up-right"
                                                    initValue={estimatedEndDate}
                                                    placeholder="Select end date..."
                                                />
                                            )}
                                        />
                                    </div>
                                    {
                                        errors.endDate && errors.endDate.type === "required"
                                        && <span className={styles.error}>This field is required</span>
                                    }
                                </div>
                                {
                                    errors.startDate && errors.startDate.type === "date"
                                    && <span className={styles.error}>{errors.startDate.message}</span>
                                }
                                {
                                    errors.endDate?.type === "validEndDate"
                                    &&
                                    <span className={styles.error}>{"End date can't be earlier than start date"}</span>
                                }
                                <p className={styles.timesFormatMessage}>
                                    Time in UTC
                                </p>
                            </div>
                            <div className={styles.checkBoxInputsSection}>
                                <div className={styles.unknownTeamField}>
                                    <input
                                        data-testid={"UnknownTeam"}
                                        type="checkbox"
                                        id="unknownTeam"
                                        {...register("isUnknownTeams", {})}
                                        onChange={onChangeUnknownTeam}
                                        disabled={isFixtureTimeEnd}
                                    />
                                    <label>Unknown Team</label>
                                </div>
                                <div className={styles.unknownTeamField}>
                                    <input
                                        data-testid={"extraTime"}
                                        type="checkbox"
                                        id="extraTime"
                                        {...register("extraTime", {})}
                                        disabled={isFixtureTimeEnd}
                                    />
                                    <label>Extra Time</label>

                                    {!selectedCompetition &&
                                        <span
                                            className={styles.attentionText}>Property should be selected before</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.buttons}>
                        <button className={styles.cancelButton}
                                type="button"
                                onClick={handleFormClose}>Cancel
                        </button>
                        <button className={styles.submitButton}
                                type="submit"
                                disabled={loadingStatus === 'loading'}>Confirm
                        </button>
                    </div>
                </form>}
            {formTabState.uploadSCVForm &&
                <UploadSCVForm
                    getSortedItems={getSortedItems}
                    handleFormClose={handleFormClose}
                    rightHolderId={rightHolderId}
                    tabsState={formTabState}
                    setTabsState={setFormTabState}
                />
            }
        </div>
    );
})

export default AddFixtureForm;
