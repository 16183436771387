import React, {useEffect, useRef, useState} from 'react';
import styles from "./index.module.scss";
import PageHeader from "../../../../../components/PageHeader/PageHeader";
import DownloadBlock from "../../../../../components/DownloadBlock/DownloadBlock";
import IGameSubHeader from "../../../../../components/IGameSubHeader/IGameSubHeader";
import {useParams} from "react-router-dom";
import {formatRangeDate, getCurrentMonth, getPreviousMonth} from "../../../../../helpers/dateFormatters";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {pdfFormatter} from "../../../../../helpers/pdfFormatter";
import BillingAccordion from "./children/BillingAccordion/BillingAccordion";
import {getBilling, getBillingCSV} from "../../../../../store/iGameOperatorBilling/iGameOperatorBillingSlice";
import {getUserType} from "../../../../../store/userManagement/userManagementSlice";
import {IPageMenuProps} from "../../../AppRoutes/routes/RouteInterface";
import BillingCalendar from "../../../../../components/Billing/BillingCalendar/BillingCalendar";
import {IDate} from "../../../../../components/AnalyticsDashboard/types";
import OperatorUser from "../../../../../components/Billing/OperatorUser/OperatorUser";
import {validateDates} from "../../../../../helpers/validateDates";

const OperatorBilling: React.FC<IPageMenuProps> = React.memo(({menuStructure}) => {
    const initialStartDate = getPreviousMonth();
    const initialEndDate = getCurrentMonth();
    const [startDate, setStartDate] = useState<IDate>(initialStartDate);
    const [endDate, setEndDate] = useState<IDate>(initialEndDate);
    const [isValidEndDate, setIsValidEndDate] = useState<boolean>(true);
    const [isExpandedAccordion, setIsExpandedAccordion] = useState<boolean>(false);
    const {
        operatorBillingData,
        billingCSV,
        csvFileName,
        totalData
    } = useAppSelector(state => state.iGameOperatorBilling);
    const {rightHoldersCount, operatorsCount} = useAppSelector(state => state.userManagement);
    const {id} = useParams();
    const pdfLayout = useRef(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getUserType(null));
    }, [dispatch]);

    useEffect(() => {
        const isValidDates = validateDates(startDate, endDate);
        setIsValidEndDate(isValidDates);
        if (!isValidDates) return;

        const body = {
            operatorId: id ? id : "",
            rightHolderId: "",
            startDate: formatRangeDate(startDate),
            endDate: formatRangeDate(endDate)
        }

        dispatch(getBilling(body))
        dispatch(getBillingCSV(body));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, startDate, endDate]);

    const pdfHandler = () => {
        setIsExpandedAccordion(true);

        setTimeout(() => {
            const layout = pdfLayout.current;
            if (layout) {
                pdfFormatter(layout);
            }
        })
        setIsExpandedAccordion(false);
    }

    return (
        <div>
            <PageHeader menuStructure={menuStructure}>
                <DownloadBlock
                    fileName={csvFileName}
                    csvFile={billingCSV}
                    pdfHandler={pdfHandler}
                    isActiveLink={true}
                />
            </PageHeader>

            <div className={styles.pageInner} ref={pdfLayout}>
                <IGameSubHeader
                    rightsHoldersCount={rightHoldersCount}
                    operatorsCount={operatorsCount}
                    hasOptions={true}
                    title={'Billing'}>

                    <BillingCalendar
                        startDate={startDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        setStartDate={setStartDate}
                        isValidEndDate={isValidEndDate}/>
                </IGameSubHeader>

                <div className={styles.pageContainer}>
                    <div className={styles.billingWrapper}>
                        <OperatorUser totalData={totalData}/>
                        <div className={styles.tableWrapper}>
                            <div className={styles.billingTable}>
                                <table>
                                    <thead>
                                    <tr>
                                        <th className={styles.accordionCellInner}>Provider</th>
                                        <th className={styles.widthCell}>Competition</th>
                                        <th className={styles.widthCell}>Title</th>
                                        <th>Number of Bookings</th>
                                        <th>Number of Streams</th>
                                        <th>Number of Ad Hoc Bookings</th>
                                        <th>Total (£)</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {operatorBillingData?.map((item, index) => (
                                        <BillingAccordion
                                            billingData={item}
                                            key={index}
                                            isExpandedAccordion={isExpandedAccordion}
                                        />
                                    ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

OperatorBilling.displayName = "OperatorBilling"
export default OperatorBilling;
