import React from 'react';
import styles from "./index.module.scss";
import download from "../../../../../../../img/download.png";
import {client} from "../../../../../../../services/api-servise";
import {useAppSelector} from "../../../../../../../hooks/hooks";
import {toastr} from "react-redux-toastr";
import {formatDate, formatDateForCSV} from "../../../../../../../helpers/dateFormatters";

interface IDownloadIssuesSCV {
    searchValue: string
}

const DownloadIssuesSCV: React.FC<IDownloadIssuesSCV> = ({searchValue}) => {
    const {itemsPerPage, pageNumber, filters} = useAppSelector(state => state.reportingIssues)

    const downloadSCVFile = async () => {
        try {
            const payload = {
                searchValue,
                pageNumber,
                itemsPerPage,
                from: formatDate(filters.startDate),
                to: formatDate(filters.endDate)
            };
            const response = await client.post("api/Reports/reports/issues/csv", payload)
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement('a');
            link.href = url;
            link.download = `Issues ${formatDateForCSV(filters.startDate, filters.endDate)}.csv`;
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url)
        } catch (e) {
            return toastr.error("IGame", "Something went wrong")
        }

    }
    return (
        <div className={styles.downloadContainer}>
            <button onClick={downloadSCVFile} className={styles.csvBtn}>
                Issues CSV
                <img src={download} alt="csv"/>
            </button>
        </div>
    );
};

export default DownloadIssuesSCV;
