import React from 'react';
import styles from "./index.module.scss";
import {HiOutlineDownload} from "react-icons/hi";
import DownloadLink from "react-download-link";
import downLoadLinkStyle from './downLoadLinkStyle';


export interface IDownloadProps {
    csvFile?: string;
    pdfHandler?: () => void;
    isActiveLink?: boolean
    fileName: string;
    withoutPDF?: boolean;
    csvButtonName?: string;
}

const DownloadBlock: React.FC<IDownloadProps> = React.memo((
        {
            csvFile,
            pdfHandler,
            isActiveLink,
            fileName,
            withoutPDF,
            csvButtonName
        }) => {
        return (
            <div className={styles.downloadContainer}>
                {!withoutPDF &&
                    < div onClick={isActiveLink ? pdfHandler : undefined}
                          className={isActiveLink ? styles.downloadItem : `${styles.downloadItem} ${styles.disabledButton}`}>
                        <span>PDF</span>
                        <HiOutlineDownload size={'18px'}/>
                    </div>
                }

                {
                    csvFile === undefined ?
                        <div className={styles.disabledCsvWrapper}>
                            <div
                                className={csvFile ? styles.downloadItem : `${styles.downloadItem} ${styles.disabledButton}`}>
                                <span className={styles.downloadType}>CSV</span>
                                <HiOutlineDownload size={'18px'}/>
                            </div>
                        </div>
                        :
                        <DownloadLink
                            label={
                                <div className={styles.enabledCsvWrapper}>
                                    {csvButtonName ?
                                        <span className={styles.downloadType}>{csvButtonName}</span>
                                        :
                                        <span className={styles.downloadType}>{'CSV'}</span>
                                    }

                                    <HiOutlineDownload size={'18px'}/>
                                </div>
                            }
                            filename={fileName}
                            exportFile={() => csvFile}
                            style={downLoadLinkStyle}
                        />
                }
            </div>
        )
            ;
    })
;

DownloadBlock.displayName = "DownloadBlock"
export default DownloadBlock;
