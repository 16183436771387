import React, {useEffect} from "react";
import styles from "./index.module.scss";
import VideoPlayer from "../../../../../../components/VideoPlayer/VideoPlayer";
import {IconContext} from "react-icons";
import {IoCloseSharp} from "react-icons/io5";
import {useAppSelector} from "../../../../../../hooks/hooks";
import {RootState} from "../../../../../../store";
import OutsideClickHandler from "react-outside-click-handler";

export interface IStreamPopupProps {
    source: string;
    setSource: (link: string) => void;
    onCloseStreamPopup: () => void;
    formatPanelVisible: boolean;
    setFormatPanelVisible: (val: boolean) => void;
}

const StreamPopup: React.FunctionComponent<IStreamPopupProps> = ({
                                                                     source,
                                                                     setSource,
                                                                     onCloseStreamPopup,
                                                                     formatPanelVisible,
                                                                     setFormatPanelVisible
                                                                 }) => {

    const {hlsStreamLink, ullStreamLink} = useAppSelector((state: RootState) => state.operatorBookedContent);

    const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        experimentalSvgIcons: true,
        sources: [
            {
                src: source,
                type: "video/mp4",
            },
        ],
        controlBar: {
            skipButtons: {
                forward: 10,
                backward: 10,
            },
        },
    };

    useEffect(() => {
        if (hlsStreamLink && ullStreamLink) {
            setFormatPanelVisible(true);
        } else if (hlsStreamLink || ullStreamLink) {
            const link = hlsStreamLink || ullStreamLink;
            link && setSource(link);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hlsStreamLink, ullStreamLink]);

    return (
        <OutsideClickHandler onOutsideClick={onCloseStreamPopup}>
            <div className={styles.streamPopup}>
                <button
                    className={formatPanelVisible ? `${styles.closePopupButton}` : `${styles.closePopupButton} ${styles.topButton}`}
                    onClick={onCloseStreamPopup}>
                    <IconContext.Provider value={{color: "#ECECECFF", className: `${styles.closePopupIcon}`}}>
                        <div>
                            <IoCloseSharp/>
                        </div>
                    </IconContext.Provider>;
                </button>
                {
                    formatPanelVisible &&
                    <div className={styles.formatSelectionPanel}>
                        <button className={styles.formatButton} onClick={() => setSource(hlsStreamLink)}>HLS</button>
                        <button className={styles.formatButton} onClick={() => setSource(ullStreamLink)}>ULL</button>
                    </div>
                }
                <VideoPlayer options={videoJsOptions}/>
            </div>
        </OutsideClickHandler>
    )
}

export default StreamPopup;
