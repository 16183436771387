import React, {useRef} from 'react';
import styles from './index.module.scss';
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {
    getPricingAudit,
    IAuditPricingRecord,
    setPageNumber
} from "../../../../../store/auditTrailsPricing/auditTrailsPricing";
import commonStyles from "../../../children/AuditTrailsTable/index.module.scss";
import AppTableWhite from "../../../../AppTableWhite/AppTableWhite";
import auditIcon from "../../../../../img/auditWhite.svg";
import {AppInfiniteScroll} from "../../../../AppInfiniteScroll/AppInfiniteScroll";

export interface IPricingTableProps {
    handlePriceSelection: (pricing: IAuditPricingRecord) => void;
    searchText: string;
}

const PricingTable: React.FC<IPricingTableProps> = ({handlePriceSelection, searchText}) => {
    const dispatch = useAppDispatch();
    const containerRef = useRef<HTMLDivElement>(null);
    const {
        pricingAuditList,
        pageNumber,
        itemsPerPage,
        totalCount,
        scrollLoader,
        maxItemsInTheTable,
    } = useAppSelector(state => state.auditTrailsPricing);

    const fetchData = (page: number, pageSize: number, scrollPosition: string) => {
        dispatch(getPricingAudit({
            searchText,
            pageNumber: page,
            itemsPerPage: pageSize,
            scrollPosition
        }));
    }

    const setPage = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber));
    }

    return (
        <div className={commonStyles.tableContainer}>
            <div className={commonStyles.searchTableWrapper}>
                <AppInfiniteScroll
                    containerRef={containerRef}
                    fetchData={fetchData}
                    pageSize={itemsPerPage}
                    totalItems={totalCount}
                    currentPage={pageNumber}
                    setPage={setPage}
                    isLoading={scrollLoader}
                    scrollingContainerHeight="55vh"
                    scrollBounce={200}
                    maxItemsInTheTable={maxItemsInTheTable}
                    tableDataLength={pricingAuditList.length}>
                    <AppTableWhite>
                        <thead>
                        <tr>
                            <th>Rights Holder</th>
                            <th>Property</th>
                            <th>Operator</th>
                            <th className={styles.actionCell}>
                                Go to Audit Trails
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            pricingAuditList.length ?
                                pricingAuditList.map((price) => (
                                    <tr key={price.id}>
                                        <td>{price.rightHolder}</td>
                                        <td>{price.competition}</td>
                                        <td>{price.operator}</td>
                                        <td>
                                            <button onClick={() => handlePriceSelection(price)}
                                                    className={styles.actionBtn}>
                                                <img src={auditIcon} alt="audit"/>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                                :
                                <tr>
                                    <td className={styles.noSpaceCell}>
                                        Use search to get a list of possible pricing records
                                    </td>
                                </tr>
                        }
                        </tbody>
                    </AppTableWhite>
                </AppInfiniteScroll>
            </div>
        </div>
    );
};

PricingTable.displayName = "PricingTable"
export default PricingTable;
