import React from 'react';
import TableSort from "../../../../../../../img/tableSort.svg";
import styles from './index.module.scss';

export interface ISortingField {
    field: string,
    isAscending: boolean
}

interface ISortingProps {
    sortingState: ISortingField[],
    currentSortingField: string,
    sortingProperty: string,
    sortingColumnName: string
}

const Sorting: React.FC<ISortingProps> = ({
                                              sortingState,
                                              currentSortingField,
                                              sortingProperty,
                                              sortingColumnName
                                          }) => {


    const getCurrentDirectionByFieldName = (field: string) => {
        const currentDirection = sortingState.find(state => state.field === field)
        if (currentDirection) {
            return currentDirection.isAscending
        }
    }
    return (
        <div className={styles.sortingInner}>
            <span className={styles.sortingName}>{sortingColumnName}</span>

            {currentSortingField === sortingProperty ?
                <img
                    src={TableSort}
                    alt={'sorting-icon'}
                    className={getCurrentDirectionByFieldName(sortingProperty) ? `${styles.tableSortAsc}` : `${styles.tableSort}`}/>
                :
                <img src={TableSort}
                     alt={'sorting-icon'}
                     className={`${styles.hiddenTableSort} ${styles.tableSortAsc}`}/>}
        </div>
    );
};

Sorting.displayName = 'Sorting';
export default Sorting;
