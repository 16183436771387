import React from 'react';
import styles from "./index.module.scss";


interface IRightHolderFormButtons {
    onClickHandler: () => void;
    text: string;
}

const FormButtonsBlock: React.FC<IRightHolderFormButtons> = React.memo(({onClickHandler, text}) => {

    return (
        <div className={styles.buttons}>
            <button onClick={onClickHandler} className={styles.cancelButton} type="button">Cancel</button>
            <button
                className={styles.submitButton}
                type="submit">{text}
            </button>
        </div>
    );
});

FormButtonsBlock.displayName = "FormButtonsBlock"
export default FormButtonsBlock;