import {Group} from "../../../enums/group";
import {mapAllowedRoutes} from "./mapAllowedRoutes";
import {getAllowedRoutes} from "./getAllowedRoutes";
import {formatAllowedPages} from "./helpers/formatAllowedPages";
import {TechnicalProviderRoutes} from "./routes/TechnicalProviderRoutes";
import {routeHandler} from "./routes/routeHandler";

export const setRoutesByUserRole = (role: string, pages: number[]) => {
    if (role) {
        switch (role) {
            case Group.RightHolder: {
                return mapAllowedRoutes(getAllowedRoutes(routeHandler(Group.RightHolder)!, formatAllowedPages(pages)))
            }
            case Group.Operator: {
                return mapAllowedRoutes(getAllowedRoutes(routeHandler(Group.Operator)!, formatAllowedPages(pages)))
            }
            case Group.IGame: {
                return mapAllowedRoutes(getAllowedRoutes(routeHandler(Group.IGame)!, formatAllowedPages(pages)))
            }
            case Group.TechnicalProvider: {
                return TechnicalProviderRoutes[0].route
            }
            default:
                return
        }
    }
}
