import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {client} from "../../services/api-servise";
import {AxiosError} from "axios";
import {toastr} from "react-redux-toastr";
import {appendDataHelper, prependDataHelper} from "../../helpers/reducerHelpers";

export interface IUpcomingFixturesGereralPayload {
    payload: IUpcomingFixturesPayload;
    scrollPosition?: string;
}

export interface IUpcomingFixturesPayload {
    propertyId: string;
    operatorId?: string;
    pageNumber: number;
    itemsPerPage: number;
    sorting: {
        property: string;
        isAscending: boolean;
    }
}

export interface IUpcomingFixture {
    id: string;
    name: string;
    rating: number
    sport: string;
    rightHolder: string;
    date: string;
    day: string;
    time: string;
    remainingSelections: number,
    rightHolderId: string,
    isInCart: boolean
}

export interface IUpcomingFixturesResponse {
    records: IUpcomingFixture[];
    totalCount: number;
}

export interface IFixtureByDayAndTime {
    weekDay: string;
    startTimeUtc: string;
    totalEvents: number;
    fixturesPercentage: number;
}

export interface IFixtureByMonth {
    month: string;
    fixturesPercentage: number;
    totalEvents: number;
}

export interface IScheduleAndCardResponse {
    region: string;
    seasonMatches: number;
    remainingMatches: number;
    geoBlocking: string;
    propertyRating: string;
    concurrencyRating: string;
    seasonStartEnd: string;
    mostCommonDay: string;
    mostCommonTime: string;
    fixturesByDayAndTime: IFixtureByDayAndTime[];
    fixturesByMonth: IFixtureByMonth[];
}

export interface IPropertyDetailState {
    records: IUpcomingFixture[];
    totalCount: number;
    scheduleAndCardData: IScheduleAndCardResponse;
    fixturesLoading: boolean;
    scheduleLoading: boolean;
    itemsPerPage: number;
    pageNumber: number;
    scrollLoader: boolean;
    maxItemsInTheTable: number;
}

const initialState: IPropertyDetailState = {
    records: [],
    totalCount: 0,
    scheduleAndCardData: {
        region: "",
        seasonMatches: 0,
        remainingMatches: 0,
        geoBlocking: "",
        propertyRating: "",
        concurrencyRating: "",
        seasonStartEnd: "",
        mostCommonDay: "",
        mostCommonTime: "",
        fixturesByDayAndTime: [],
        fixturesByMonth: []
    },
    fixturesLoading: false,
    scheduleLoading: false,
    itemsPerPage: 15,
    pageNumber: 1,
    scrollLoader: false,
    maxItemsInTheTable: 45,
}

export const getUpcomingFixtures = createAsyncThunk<IUpcomingFixturesResponse, IUpcomingFixturesGereralPayload, {
    rejectValue: string
}>(
    'property-detail-get-fixtures',
    async (body, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.post("api/PropertyDetails/upcoming-fixtures", body.payload);
            const totalCount = data.totalCount;

            if (totalCount) {
                dispatch(setTotalCount(Number(totalCount)));
            }
            if (body.scrollPosition && body.scrollPosition === "down") {
                dispatch(appendData(data.records));
                return;
            }
            if (body.scrollPosition && body.scrollPosition === "up") {
                dispatch(prependData(data.records));
                return;
            }
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getSheduleAndCardData = createAsyncThunk<IScheduleAndCardResponse, string, { rejectValue: string }>(
    'property-detail-get-schedule',
    async (propertyId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`/api/PropertyDetails/${propertyId}`);
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const propertyDetailSlice = createSlice({
    name: 'property-details',
    initialState,
    reducers: {
        setPageNumber: (state, action: PayloadAction<number>) => {
            state.pageNumber = action.payload
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload
        },
        appendData: (state, action) => {
            const data = action.payload;
            appendDataHelper(state, data, 'records');
        },
        prependData(state, action) {
            const data = action.payload;
            prependDataHelper(state, data, 'records');
        },
        setRemainingSelection: (state, action) => {
            state.records = state.records.map(fixture => {
                if (fixture.id === action.payload) {
                    fixture.remainingSelections = fixture.remainingSelections - 1
                }
                return fixture
            })
        },
        updateFixtureCartStatus: (state, action) => {
            state.records = state.records.map(fixture => {
                if (fixture.id === action.payload.fixtureId) {
                    fixture.isInCart = action.payload.isInCart
                }
                return fixture
            })
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUpcomingFixtures.pending, (state) => {
                state.fixturesLoading = true;
            })
            .addCase(getUpcomingFixtures.fulfilled, (state, action) => {
                if (action.payload !== undefined) {
                    state.records = action.payload.records;
                    state.totalCount = action.payload.totalCount;
                }
                state.fixturesLoading = false;
            })
            .addCase(getUpcomingFixtures.rejected, (state) => {
                state.fixturesLoading = false;
            })
            .addCase(getSheduleAndCardData.pending, (state) => {
                state.scheduleLoading = true;
            })
            .addCase(getSheduleAndCardData.fulfilled, (state, action) => {
                state.scheduleAndCardData = action.payload;
                state.scheduleLoading = false;
            })
            .addCase(getSheduleAndCardData.rejected, (state) => {
                state.scheduleLoading = false;
            })
    },
})
export const {
    setTotalCount,
    appendData,
    prependData,
    setPageNumber,
    updateFixtureCartStatus,
    setRemainingSelection
} = propertyDetailSlice.actions
export default propertyDetailSlice.reducer
