import React from 'react';
import styles from './index.module.scss'
import moment from "moment/moment";
import {clearIpUsage, ICheckedIp} from "../../../../../../../store/rightHolderBooking/rightHolderBookingSlice";
import {useAppDispatch} from "../../../../../../../hooks/hooks";

export interface IConfirmModalProps {
    ipUsage: ICheckedIp;
    onSubmit: () => void;
}

const ConfirmIPModal: React.FC<IConfirmModalProps> = React.memo(({ipUsage, onSubmit}) => {
    const dispatch = useAppDispatch();

    const onClick = (e: React.MouseEvent) => {
        e.preventDefault();
        onSubmit();
        onCloseUsedIpConfirm();
    }

    const onCloseUsedIpConfirm = () => {
        dispatch(clearIpUsage());
    }

    return (
        <div onClick={() => onCloseUsedIpConfirm()} className={styles.overlay} data-testid="confirm-modal">
            <form onClick={(e) => e.stopPropagation()} className={styles.form}>
                <div className={styles.decor}>
                    <div className={styles.attention}>!</div>
                </div>
                <div className={styles.content}>
                    <h2 className={styles.message}>The channel you have selected is in use for a concurrent event: </h2>

                    <div>
                        <div className={styles.property}>
                            <span>{ipUsage.concurrentFixture!.property} -{'\u00A0'}</span>
                            <span>{ipUsage.concurrentFixture!.title} -{'\u00A0'}</span>
                            <span>{moment(ipUsage.concurrentFixture!.startTime).format('DD-MM-YYYY HH:mm')}</span>
                        </div>

                        <h3 className={styles.question}>Are you sure you want to assign this channel to the selected
                            event:</h3>

                        <div className={styles.property}>
                            <span>{ipUsage.selectedFixture!.property} -{'\u00A0'}</span>
                            <span>{ipUsage.selectedFixture!.title} -{'\u00A0'}</span>
                            <span> {moment(ipUsage.selectedFixture!.startTime).format(`DD-MM-YYYY HH:mm`)}</span>
                        </div>
                    </div>
                </div>
                <div className={styles.buttons}>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            onCloseUsedIpConfirm()
                        }}
                        className={styles.cancelButton}
                        data-testid="cancel-button"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={(e) => onClick(e)}
                        className={styles.submitButton}>
                        Confirm
                    </button>
                </div>
            </form>
        </div>
    );
});
ConfirmIPModal.displayName = "ConfirmIPModal"
export default ConfirmIPModal;
