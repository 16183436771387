import React, {useRef} from 'react';
import styles from './index.module.scss';
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import commonStyles from "../../../children/AuditTrailsTable/index.module.scss";
import AppTableWhite from "../../../../AppTableWhite/AppTableWhite";
import auditIcon from "../../../../../img/auditWhite.svg";
import {
    getIngestResourcesDetails,
    IResourceDetailsResponse, setPageNumber
} from "../../../../../store/auditTrailsIngestResources/auditTrailsIngestResourcesSlice";
import {AppInfiniteScroll} from "../../../../AppInfiniteScroll/AppInfiniteScroll";

export interface IResourcesDetailsTableProps {
    handleIngestResourceSelection: (rightAssignment: IResourceDetailsResponse) => void;
    searchText: string;
}

const ResourcesDetailsTable: React.FC<IResourcesDetailsTableProps> = ({handleIngestResourceSelection, searchText}) => {
    const dispatch = useAppDispatch();
    const containerRef = useRef<HTMLDivElement>(null);
    const {
        resources,
        pageNumber,
        itemsPerPage,
        totalCount,
        scrollLoader,
        maxItemsInTheTable,
    } = useAppSelector(state => state.auditTrailsIngestResources);

    const fetchData = (page: number, pageSize: number, scrollPosition: string) => {
        dispatch(getIngestResourcesDetails({
            searchText,
            pageNumber: page,
            itemsPerPage: pageSize,
            scrollPosition
        }));
    }

    const setPage = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber))
    }

    return (
        <div className={commonStyles.tableContainer}>
            <div className={commonStyles.searchTableWrapper}>
                <AppInfiniteScroll
                    containerRef={containerRef}
                    fetchData={fetchData}
                    pageSize={itemsPerPage}
                    totalItems={totalCount}
                    currentPage={pageNumber}
                    setPage={setPage}
                    isLoading={scrollLoader}
                    scrollingContainerHeight="49vh"
                    scrollBounce={200}
                    maxItemsInTheTable={maxItemsInTheTable}
                    tableDataLength={resources.length}>
                    <AppTableWhite>
                        <thead>
                        <tr>
                            <th>Rights Holder</th>
                            <th>Property</th>
                            <th>Technical Provider(s)</th>
                            <th className={styles.actionCell}>
                                Go to Audit Trails
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            resources.length ?
                                resources.map((ingestResource) => (
                                    <tr key={ingestResource.id}>
                                        <td>{ingestResource.rightHolder}</td>
                                        <td>{ingestResource.competition}</td>
                                        <td>{ingestResource.technicalProvider}</td>
                                        <td>
                                            <button onClick={() => handleIngestResourceSelection(ingestResource)}
                                                    className={styles.actionBtn}>
                                                <img src={auditIcon} alt="audit"/>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                                :
                                <tr>
                                    <td className={styles.noSpaceCell}>
                                        Use search to get a list of possible ingest resources
                                    </td>
                                </tr>
                        }
                        </tbody>
                    </AppTableWhite>
                </AppInfiniteScroll>
            </div>
        </div>
    );
};

ResourcesDetailsTable.displayName = "ResourcesDetailsTable"
export default ResourcesDetailsTable;




















