import React, {useEffect} from 'react';
import {Control, Controller, FieldErrors, FieldValues, UseFormRegister, UseFormResetField} from "react-hook-form";
import {SingleValue} from "react-select";
import {ITableRowData} from "../../../../BookFixtures";
import {useAppDispatch, useAppSelector} from "@hooks/hooks";
import {formatSelectOptions} from "@helpers/formatSelectOptions";
import SelectInput from "@components/SelectInput/SelectInput";
import {SingleValueType} from "../../types/types";
import {IngestFormController} from "@enums/ingest";
import {setIsShowIngetsMethod} from "@store/rightHolderBooking/rightHolderBookingSlice";

interface IUseContentSuppliersFormProps {
    fixture: ITableRowData | null;
    control: Control;
    errors: FieldErrors;
    setValue: (name: string, value: SingleValueType) => void;
    register: UseFormRegister<FieldValues>;
    resetField: UseFormResetField<FieldValues>;
}

export const ContentSuppliers = (
    {
        fixture,
        control,
        errors,
        setValue,
        register,
        resetField,
    }: IUseContentSuppliersFormProps) => {

    const dispatch = useAppDispatch();
    const {contentSuppliers} = useAppSelector(state => state.rightHolderBooking);

    useEffect(() => {
        if (contentSuppliers.length > 0) {
            const formattedContentSupplier = formatSelectOptions(contentSuppliers)

            if (formattedContentSupplier && formattedContentSupplier.length > 0) {
                const currentContentSupplier = formattedContentSupplier.find(option => option.label === fixture?.contentSupplier);

                if (currentContentSupplier) {
                    setValue(IngestFormController.ContentSupplier, currentContentSupplier);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentSuppliers, fixture]);

    useEffect(() => {
        return () => {
            setValue(IngestFormController.ContentSupplier, null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSupplierChange = (supplier: SingleValue<{ value: string; label: string }>) => {
        setValue(IngestFormController.ContentSupplier, supplier);
        resetField(IngestFormController.PrimaryMethod);
        resetField(IngestFormController.BackupMethod);
        resetField(IngestFormController.PrimarySource);
        resetField(IngestFormController.BackupSource);
        resetField(IngestFormController.BackupIPChannels);
        resetField(IngestFormController.PrimaryIPChannels);
        resetField(IngestFormController.IGameChannels);
        resetField(IngestFormController.BackupIGameChannels);
        dispatch(setIsShowIngetsMethod(false))
    };

    return (
        <label>Content Supplier
            <Controller
                control={control}
                name={IngestFormController.ContentSupplier}
                rules={{required: true}}
                render={({
                             field: {onChange, value},
                         }) => (
                    <SelectInput
                        {...register(IngestFormController.ContentSupplier)}
                        disabled={!contentSuppliers.length}
                        options={formatSelectOptions(contentSuppliers)}
                        onChange={option => {
                            onChange(option);
                            handleSupplierChange(option);
                        }}
                        value={value}
                        data-testid="content-supplier"
                        placeholder={!contentSuppliers.length ? 'There are no available content supplier' : null}
                    />
                )}
            />
            {errors.ContentSupplier && errors.ContentSupplier.type === "required" && (
                <span>{errors.ContentSupplier.message && String(errors.ContentSupplier.message)}</span>
            )}
        </label>
    );
};
