import React, {useEffect, useMemo, useState} from 'react';
import styles from './index.module.scss'
import SelectInput from "../../../../../components/SelectInput/SelectInput";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {
    getRightsOptionsByOperator, IUpdatedPricing,
    updateUnconfirmedPricing
} from "../../../../../store/operatorPricing/operatorPricingSlice";
import {formatSelectOptions} from "../../../../../helpers/formatSelectOptions";
import {SingleValue} from "react-select";
import {getOperatorAccessInit} from "../../../../../store/operatorAccess/operatorAccessSlice";
import {Controller, useForm} from "react-hook-form";
import Input from "../../../../../components/Input/Input";

interface IPricingFormProps {
    closeForm: () => void;
    updatingPricingId: string | undefined;
    rightHolderId: string | null;
    testSubmitFunction?: (payload: {
        id: string;
        rightHolderId: string;
        operatorId: string;
        rightId: string;
        adHoc: number;
        minPPF: number;
        maxPPF: number;
    }) => void;
}

interface IFormInput {
    minPPF: number
    maxPPF: number
    adHoc: number
}

const UpdatePricingForm: React.FC<IPricingFormProps> = React.memo(({
                                                                       closeForm,
                                                                       updatingPricingId,
                                                                       rightHolderId,
                                                                       testSubmitFunction
                                                                   }) => {
    const dispatch = useAppDispatch()
    const [operator, setOperator] = useState<SingleValue<{ value: string, label: string } | undefined>>(undefined);
    const [right, setRight] = useState<SingleValue<{ value: string, label: string } | undefined>>(undefined);
    const [minPricing, setMinPricing] = useState<number | string>("");
    const [maxPricing, setMaxPricing] = useState<number | string>("");
    const [hocPricing, setHocPricing] = useState<number | string>("");
    const {rightsInpOptions, operatorInpOptions, unconfirmedPricing} = useAppSelector(state => state.operatorPricing)
    const {control, setValue, handleSubmit, formState: {errors}} = useForm<IFormInput>();
    useEffect(() => {
        setDefaultValues()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSetOperator = (option: SingleValue<{ value: string, label: string }>) => {
        if (option) {
            dispatch(getRightsOptionsByOperator({rightHolder: rightHolderId, operator: option.value}))
            setOperator(option)
        }
    }

    const setDefaultValues = () => {
        dispatch(getOperatorAccessInit(null))
        const updatingPricing = unconfirmedPricing.find(pricing => pricing.id === updatingPricingId)
        if (updatingPricing) {
            setOperator({value: updatingPricing.operatorId.toString(), label: updatingPricing.operatorName})
            setRight({value: updatingPricing.rightId, label: updatingPricing.rightName})
            setMinPricing(updatingPricing.minPPF)
            setMaxPricing(updatingPricing.maxPPF)
            setHocPricing(updatingPricing.adHoc)
            setValue("minPPF", updatingPricing.minPPF)
            setValue("maxPPF", updatingPricing.maxPPF)
            setValue("adHoc", updatingPricing.adHoc)
        }
    }

    const onUpdateNewPricing = () => {
        const updatedPricing = {
            payload: {
                id: updatingPricingId,
                rightHolderId,
                operatorId: operator!.value,
                rightId: right!.value,
                adHoc: hocPricing ? Number(hocPricing) : 0,
                minPPF: minPricing ? Number(minPricing) : 0,
                maxPPF: maxPricing ? Number(maxPricing) : 0,
            },
            closeForm
        } as IUpdatedPricing

        testSubmitFunction && testSubmitFunction(updatedPricing.payload);
        dispatch(updateUnconfirmedPricing(updatedPricing))
    }

    const formatSelectOperators = useMemo(() => (
        formatSelectOptions(operatorInpOptions)
    ), [operatorInpOptions]);

    const formatSelectRights = useMemo(() => (
        formatSelectOptions(rightsInpOptions)
    ), [rightsInpOptions]);


    return (
        <div className={styles.overlay} data-testid="update-pricing-form">
            <form onClick={(e) => e.stopPropagation()}
                  onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                  onSubmit={handleSubmit(onUpdateNewPricing)}
                  className={styles.form}>
                <div className={styles.content}>
                    <div>
                        <h6 className={styles.title}>Update Pricing</h6>
                    </div>
                    <div>
                        <label className={styles.label}>Operator
                            <SelectInput
                                value={operator}
                                onChange={handleSetOperator}
                                options={formatSelectOperators}
                                placeholder={"Choose an option"}
                                aria-label={"operator-select"}
                            />
                        </label>
                    </div>
                    <div>
                        <label className={styles.label}>Right
                            <SelectInput
                                value={right}
                                onChange={setRight}
                                options={formatSelectRights}
                                placeholder={"Choose an option"}
                                aria-label={"right-select"}
                            />
                        </label>
                    </div>
                    <div>
                        <label className={styles.label}>Minimum Price Per Fixture (£)
                            <Controller
                                control={control}
                                name="minPPF"
                                rules={{
                                    required: true,
                                    validate: value => Number(value) > 0
                                }}
                                render={({field: {onChange}}) => (
                                    <Input
                                        onChange={(e) => {
                                            onChange(e);
                                            setMinPricing(e.target.value ? Number(e.target.value) : "");
                                        }}
                                        value={minPricing}
                                        type="number"
                                        placeholder={"Enter minimum price..."}
                                    />
                                )}
                            />
                            {errors?.minPPF?.type === "required" &&
                                <div className={styles.formErrorBlock} data-testid="minPPF-required-error">MinPPF is
                                    required field</div>}
                            {errors?.minPPF?.type === "validate" &&
                                <div className={styles.formErrorBlock}
                                     data-testid="minPPF-must-be-positive-error">MinPPF should be positive number</div>}
                        </label>
                    </div>
                    <div>
                        <label className={styles.label}>Maximum Price Per Fixture (£)
                            <Controller
                                control={control}
                                name="maxPPF"
                                rules={{
                                    required: true,
                                    validate: value => Number(value) > 0
                                }}
                                render={({field: {onChange}}) => (
                                    <Input
                                        onChange={(e) => {
                                            onChange(e);
                                            setMaxPricing(e.target.value ? Number(e.target.value) : "");
                                        }}
                                        value={maxPricing}
                                        type="number"
                                        placeholder={"Enter maximum price..."}
                                    />
                                )}
                            />
                            {errors?.maxPPF?.type === "required" &&
                                <div className={styles.formErrorBlock} data-testid="maxPPF-required-error">MaxPPF is
                                    required field</div>}
                            {errors?.maxPPF?.type === "validate" &&
                                <div className={styles.formErrorBlock}
                                     data-testid="maxPPF-must-be-positive-error">MaxPPF should be positive number</div>}
                        </label>
                    </div>
                    <div>
                        <label className={styles.label}>Ad Hoc Pricing (£)
                            <Controller
                                control={control}
                                name="adHoc"
                                rules={{
                                    required: true,
                                    validate: value => Number(value) > 0
                                }}
                                render={({field: {onChange}}) => (
                                    <Input
                                        onChange={(e) => {
                                            onChange(e);
                                            setHocPricing(e.target.value ? Number(e.target.value) : "");
                                        }}
                                        value={hocPricing}
                                        type="number"
                                        placeholder={"Enter Hoc pricing..."}
                                    />
                                )}
                            />
                            {errors?.adHoc?.type === "required" &&
                                <div className={styles.formErrorBlock} data-testid="ad-hoc-required-error">adHoc is
                                    required field</div>}
                            {errors?.adHoc?.type === "validate" &&
                                <div className={styles.formErrorBlock} data-testid="ad-hoc-must-be-positive-error">adHoc
                                    should be positive number</div>}
                        </label>
                    </div>
                </div>
                <div className={styles.buttons}>
                    <button onClick={closeForm} className={styles.cancelButton}>Cancel</button>
                    <button
                        className={styles.submitButton}
                        type="submit">Confirm
                    </button>
                </div>
            </form>
        </div>
    );
});

export default UpdatePricingForm;
