import React, {useEffect, useRef, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import SelectInput from "../../../../../../components/SelectInput/SelectInput";
import DatePicker from "../../../../../../components/DatePicker/DatePicker";
import Input from "../../../../../../components/Input/Input";
import moment from "moment/moment";
import {convertDate, formatCalendarDate, formatDateForPostGeneralIssue} from "./helpers/calendarFormater";
import FixturesTable from "./components/FixturesTable/FixturesTable";
import {useDebounce} from "../../../../../../helpers/useDebounce";
import styles from './index.module.scss'
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/hooks";
import {
    clearSelectedFixtures,
    getAffectedEvents, getDetails,
    getFixturesInGeneralIssues,
    getGeneralIssuesInit, getOngoingGeneralIssues,
    IGeneralIssue, setPageNumber,
} from "../../../../../../store/generalIssues/generalIssuesSlice";
import {useGenerateDropdownOptions} from "./hooks/useGenerateDropdownOptions";
import OngoingIssuesTable from "./components/OngoingIssuesTable/OngoingIssuesTable";
import AffectedEventsTable from "./components/AffectedEventsTable/AffectedEventsTable";
import {generalIssueSubmitHandler} from "./helpers/generalIssueSubmitHandler";
import ConfirmModal from "../../../../../../components/ConfirmModal/ConfirmModal";
import exitIcon from '../../../../../../img/exitIcon.svg';
import {createPayload} from "./helpers/createPayload";
import {IDate, IDropdownOption} from "../../../../../../components/AnalyticsDashboard/types";
import {ISelectOption} from "../../../../../../store/operatorBookedContent/operatorBookedContentSlice";
import {findOptions} from "./helpers/findOptions";
import Checkbox from "../../../../../../components/Checkbox";
import tooltipIcon from "../../../../../../img/info.png"
import {Tooltip} from "react-tooltip";
import {SingleValue} from "react-select";
import {editLabelInDropdownOptions} from "../../../../../../helpers/editLabelInDropdownOptions";
import {changeSelectInputLabels} from "../../../../../../helpers/changeSelectInputLabels";
import {AppInfiniteScroll} from "@components/AppInfiniteScroll/AppInfiniteScroll";

export interface IFormData {
    fault: ISelectOption | undefined;
    description: string;
    affectedPlatform: ISelectOption | undefined;
    contentSupplier: IDropdownOption[];
    rightHolder: IDropdownOption[];
    protocol: ISelectOption | undefined;
    detail: ISelectOption;
    severity: ISelectOption;
    id?: number;
}

interface GeneralIssueFormProps {
    formHandler: React.Dispatch<React.SetStateAction<boolean>>;
    editIssue?: IGeneralIssue;
    searchText: string | undefined;
    searchEventStartTime: string;
}

const GeneralIssueForm: React.FC<GeneralIssueFormProps> = (
    {
        formHandler,
        editIssue,
        searchText,
        searchEventStartTime
    }) => {
    const [issueTime, setIssueTime] = useState<{
        start: string,
        end: string | undefined
    }>({
        start: moment().format("DD/MM/YYYY HH:mm"),
        end: undefined
    });
    const [searchValue, setSearchValue] = useState("");
    const [openOngoingIssues, setOngoingIssues] = useState<boolean>(false);
    const [openAffectedEvents, setAffectedEvents] = useState<boolean>(false);
    const [closeModal, setCloseModal] = useState<boolean>(false);
    const [formStyles, setFormStyles] = useState<string>(styles.hiddenForm);
    const [isUnresolved, setIsUnresolved] = useState<boolean>(false);
    const [showIssueDetail, setShowIssueDetail] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const {
        initData,
        selectedFixtures,
        fixtures,
        ongoingGeneralIssues,
        ongoingIssueDetails,
        pageNumber,
        itemsPerPage,
        maxItemsInTheTable,
        totalCount,
        scrollLoader
    } = useAppSelector((state) => state.reportingGeneralIssues);
    const {
        setValue,
        control,
        handleSubmit,
        formState: {errors}
    } = useForm<IFormData>({
        defaultValues: {
            fault: undefined,
            detail: undefined,
            severity: undefined,
            description: '',
            affectedPlatform: undefined,
            contentSupplier: [],
            rightHolder: [],
            protocol: undefined
        },
    });
    const containerRef = useRef<HTMLDivElement>(null);

    const severenityOptions = [
        {value: '1', label: 'S1'},
        {value: '2', label: 'S2'},
        {value: '3', label: 'S3'},
    ]
    const labelChanges = [
        {from: "I S P", to: "ISP"},
        {from: "Bt Tower", to: "BT Tower"},
        {from: "Ip Contribution", to: "IP Contribution"},
        {from: "C D N", to: "CDN"},
        {from: "I Game", to: "iGame"}
    ];
    const {
        contentSuppliersOptions,
        affectedPlatformsOptions,
        protocolsOptions,
        faultsOptions,
        rightHoldersOptions,
        detailOptions
    } = useGenerateDropdownOptions(initData)


    useEffect(() => {
        dispatch(getGeneralIssuesInit());
        dispatch(getOngoingGeneralIssues());
    }, [dispatch]);

    useEffect(() => {
        if (ongoingIssueDetails) {
            setDetails(ongoingIssueDetails);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ongoingIssueDetails]);

    useEffect(() => {
        if (ongoingGeneralIssues?.length) {
            setFormStyles(styles.hiddenForm);
            setOngoingIssues(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ongoingGeneralIssues]);

    const searchRequest = () => {
        const searchParameters = {
            searchText: searchValue,
            eventStartTime: convertDate(issueTime.start),
            pageNumber,
            itemsPerPage
        };
        dispatch(getFixturesInGeneralIssues(searchParameters));
    };

    useDebounce(searchRequest, [searchValue], 500);

    const setDetails = (ongoingIssueDetails: IGeneralIssue) => {
        const faultOption = findOptions(initData.faults?.items, ongoingIssueDetails.fault);
        const protocolOption = findOptions(initData.protocols?.items, ongoingIssueDetails.streamType);
        const contentSuppliersOptions = findOptions(initData.contentSuppliers?.items, ongoingIssueDetails.contentSuppliers);
        const rightsHolderOptions = findOptions(initData.rightHolders?.items, ongoingIssueDetails.rightHolders);
        const platformOption = findOptions(initData.affectedPlatforms?.items, ongoingIssueDetails.platform);
        const detailsOptions = findOptions(initData.details?.items, ongoingIssueDetails.detail);
        setValue("description", ongoingIssueDetails.issueDescription);
        setValue("fault", faultOption as ISelectOption);
        setValue("detail", detailsOptions as ISelectOption);
        setValue("protocol", protocolOption as ISelectOption);
        setValue('contentSupplier', contentSuppliersOptions as IDropdownOption[])
        setValue('rightHolder', rightsHolderOptions as IDropdownOption[])
        setValue("affectedPlatform", platformOption as ISelectOption);

        setIssueTime({
            start: moment(ongoingIssueDetails.issueStartTime).format('DD/MM/YYYY HH:mm'),
            end: `${moment().format('DD/MM/YYYY')} XX:XX`
        })

        setIsUnresolved(ongoingIssueDetails.unresolved);
        handleIssueEndTime()
    }

    const handleIssueEndTime = () => {
        let endTime;

        if (isUnresolved && ongoingIssueDetails?.eventsLatestEndDate) {
            endTime = moment(ongoingIssueDetails.eventsLatestEndDate).format('DD/MM/YYYY HH:mm');
        } else if (!isUnresolved && ongoingIssueDetails?.issueEndTime) {
            endTime = moment(ongoingIssueDetails.issueEndTime).format('DD/MM/YYYY HH:mm');
        } else {
            endTime = `${moment().format('DD/MM/YYYY')} XX:XX`;
        }

        setIssueTime({
            ...issueTime,
            end: endTime
        });
    };

    const onSubmit = (formData: IFormData) => {
        const payload = createPayload({formData, issueTime, isUnresolved});

        if (ongoingIssueDetails) {
            payload.id = ongoingIssueDetails.id;
        }
        generalIssueSubmitHandler(payload, fixtures, formatDateForPostGeneralIssue(issueTime.end), dispatch, formHandler, !!ongoingIssueDetails);
        setIsUnresolved(false);
        closeGeneralIssuesForm();
    };

    const fetchOngoingIssues = () => {
        dispatch(getOngoingGeneralIssues());
        setOngoingIssues(true);
    }

    const fetchAffectedEvents = () => {
        dispatch(getAffectedEvents({searchText, eventStartTime: searchEventStartTime}))
        setAffectedEvents(true)
    }

    const closeOngoingIssuesTable = () => {
        setOngoingIssues(false);
        setCloseModal(false);
        setIsUnresolved(false);
        dispatch(clearSelectedFixtures());
    }

    const closeGeneralIssuesForm = () => {
        dispatch(clearSelectedFixtures());
        setIsUnresolved(false);
        formHandler(false);
    }

    const unresolvedHandler = () => {
        setIsUnresolved(!isUnresolved)
        handleIssueEndTime()
    }

    const setDefaultFormData = () => {
        setValue("fault", undefined);
        setValue("detail", {} as ISelectOption)
        setValue("description", '');
        setValue("protocol", undefined);
        setValue("contentSupplier", []);
        setValue("rightHolder", []);
        setValue("affectedPlatform", undefined);
    }

    const changeIssueEndTime = (date: IDate) => {
        setIssueTime({...issueTime, end: formatCalendarDate(date, true)})
    }

    const issueSourceHandler = (onChange: (e: SingleValue<ISelectOption>) => void, e: SingleValue<ISelectOption>) => {
        if (e) {
            onChange(e)
            setShowIssueDetail(!showIssueDetail)
            dispatch(getDetails(parseInt(e.value)));
            setValue("detail", {} as ISelectOption)
        }
    }

    const fetchData = (page: number, pageSize: number, scrollPosition: string) => {
        const searchParameters = {
            searchText: searchValue,
            eventStartTime: convertDate(issueTime.start),
            pageNumber: page,
            itemsPerPage: pageSize,
            scrollPosition
        };
        dispatch(getFixturesInGeneralIssues(searchParameters));
    }

    const setPage = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber));
    }

    return (
        <div className={styles.overlay}>
            <form onSubmit={handleSubmit(onSubmit)} className={formStyles}>
                <h6 className={styles.title}>General Issue</h6>
                <div className={styles.content}>
                    <label className={styles.label}>Issue Source
                        <Controller
                            name="fault"
                            control={control}
                            rules={{required: true}}
                            render={({field: {onChange, value}}) => (
                                <SelectInput
                                    value={value}
                                    onChange={(e) => {
                                        issueSourceHandler(onChange, e)
                                    }}
                                    options={editLabelInDropdownOptions(faultsOptions, "I Game", "iGame")}
                                    placeholder="Select issue source"
                                />
                            )}
                        />
                        {errors.fault?.type === "required" &&
                            <div className={styles.formErrorBlock}>Issue Source is required field</div>}
                    </label>
                    <label className={styles.label}>Issue Detail
                        <Controller
                            name="detail"
                            control={control}
                            rules={{required: true}}
                            render={({field: {onChange, value}}) => (
                                <SelectInput
                                    disabled={!detailOptions.length}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                    options={changeSelectInputLabels(detailOptions, labelChanges)}
                                    placeholder="Select issue detail"
                                />
                            )}
                        />
                        {errors.detail?.type === "required" &&
                            <div className={styles.formErrorBlock}>Issue Detail is required field</div>}
                    </label>
                    <label className={styles.label}>
                        <div className={styles.severityLabel}>
                            <div>Severity</div>
                            <div
                                className={styles.tooltipWrapper}
                                data-tooltip-id="severity-tooltip"
                                data-tooltip-content={"..."}
                            >
                                <Tooltip className={styles.severityTooltip} id="severity-tooltip"/>
                                <img
                                    className={styles.tooltipIcon}
                                    src={tooltipIcon}
                                    alt="tooltip-icon"
                                />
                            </div>
                        </div>
                        <Controller
                            name="severity"
                            control={control}
                            rules={{required: true}}
                            render={({field: {onChange, value}}) => (
                                <SelectInput
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                    options={severenityOptions}
                                    placeholder="Select severety"
                                />
                            )}
                        />
                        {errors.severity?.type === "required" &&
                            <div className={styles.formErrorBlock}>Severity is required field</div>}
                    </label>

                    <label className={styles.label}>Issue Description
                        <Controller
                            name="description"
                            control={control}
                            render={({field: {onChange, value}}) => (
                                <textarea
                                    onChange={(e) => onChange(e)}
                                    value={value}
                                    className={styles.textarea}/>
                            )}
                        />
                    </label>

                    <div className={styles.calendar}>Issue Start Time
                        <div className={styles.hiddenCalendar}>
                            <DatePicker
                                placeholder={" "}
                                inputClass={styles.calendarInput}
                                onChange={(date) => setIssueTime({
                                    ...issueTime,
                                    start: formatCalendarDate(date, false),
                                })}
                                modalPositioningClass="calendar-modal-down"
                            />
                        </div>
                        <div>
                            <Input value={issueTime.start}/>
                        </div>
                    </div>

                    <div className={styles.calendar}>Issue End Time
                        <div className={styles.hiddenCalendar}>
                            <DatePicker
                                isDisabled={isUnresolved}
                                placeholder={" "}
                                inputClass={styles.calendarInput}
                                onChange={(date) => changeIssueEndTime(date)}
                                modalPositioningClass="calendar-modal-down"
                            />
                        </div>
                        <div className={styles.endTimeWrapper}>
                            <div className={styles.unresolved}>
                                <Checkbox
                                    isChecked={isUnresolved}
                                    onChangeHandler={unresolvedHandler}
                                />
                                <span className={styles.unresolvedLabel}>
                                    Unresolved
                                </span>
                            </div>
                        </div>
                        <div>
                            <Input value={issueTime.end}/>
                        </div>
                    </div>

                    <h6 className={styles.sectionTitle}>Applies to:</h6>

                    <label htmlFor="affectedPlatform" className={styles.label}>Affected Platform
                        <Controller
                            name="affectedPlatform"
                            control={control}
                            render={({field: {onChange, value}}) => (
                                <SelectInput
                                    onChange={(e) => onChange(e)}
                                    value={value}
                                    options={affectedPlatformsOptions}
                                    isClearable={true}
                                    placeholder={"Select affected platform"}
                                />
                            )}
                        />
                    </label>

                    <label htmlFor="contentSupplier" className={styles.label}>Content Supplier
                        <Controller
                            name="contentSupplier"
                            control={control}
                            render={({field: {onChange, value}}) => (
                                <SelectInput
                                    showExpander
                                    onChange={(e) => onChange(e)}
                                    value={value}
                                    options={contentSuppliersOptions}
                                    isMulti={true}
                                    placeholder={"Select content supplier"}
                                    closeMenuOnSelect={false}
                                    maxItemsToShowExpander={10}
                                    itemsLength={value ? value.length : 0}
                                />
                            )}
                        />
                    </label>

                    <label htmlFor="rightHolder" className={styles.label}>Rights Holder
                        <Controller
                            name="rightHolder"
                            control={control}
                            render={({field: {onChange, value}}) => (
                                <SelectInput
                                    showExpander
                                    onChange={(e) => onChange(e)}
                                    value={value}
                                    options={rightHoldersOptions}
                                    isMulti={true}
                                    placeholder={"Select rights holder"}
                                    maxItemsToShowExpander={10}
                                    itemsLength={value ? value.length : 0}
                                    closeMenuOnSelect={false}
                                />
                            )}
                        />
                    </label>

                    <label htmlFor="protocol" className={styles.label}>Protocol
                        <Controller
                            name="protocol"
                            control={control}
                            render={({field: {onChange, value}}) => (
                                <SelectInput
                                    onChange={(e) => onChange(e)}
                                    value={value}
                                    options={protocolsOptions}
                                    isClearable={true}
                                    placeholder={"Select protocol"}
                                />
                            )}
                        />
                    </label>

                    <div>
                        <label className={styles.label}>Find events</label>
                        <Input
                            value={searchValue}
                            placeholder={"Search fixtures"}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                    </div>

                    <div className={styles.tableBtnWrapper}>
                        <div className={styles.selectInner}>
                            <button
                                disabled={!selectedFixtures?.length}
                                onClick={fetchAffectedEvents}
                                className={styles.openButton}
                                type="button">
                                <span className={styles.btnDecor}>{` > `}</span>
                                <p className={selectedFixtures?.length ? styles.isSelected : styles.nonSelected}>
                                    Affected Events
                                </p>
                            </button>
                        </div>
                        {!editIssue &&
                            <div className={styles.selectInner}>
                                <button
                                    onClick={fetchOngoingIssues}
                                    className={styles.openButton}
                                    type="button">
                                    <span className={styles.btnDecor}>{` > `}</span>
                                    Open Issues {initData.openGeneralIssuesCount}
                                </button>
                            </div>
                        }
                    </div>
                    <div>
                        {
                            fixtures?.length ?
                                <AppInfiniteScroll
                                    containerRef={containerRef}
                                    fetchData={fetchData}
                                    pageSize={itemsPerPage}
                                    totalItems={totalCount}
                                    currentPage={pageNumber}
                                    setPage={setPage}
                                    isLoading={scrollLoader}
                                    scrollingContainerHeight={"30vh"}
                                    scrollBounce={200}
                                    maxItemsInTheTable={maxItemsInTheTable}
                                    tableDataLength={fixtures.length}>

                                    <FixturesTable
                                        searchedFixtures={fixtures}
                                    />

                                </AppInfiniteScroll>
                                : null
                        }
                    </div>
                </div>
                <div className={styles.buttons}>
                    <button type={'button'} onClick={() => setCloseModal(true)} className={styles.cancelButton}>Cancel
                    </button>
                    <button
                        className={styles.submitButton}
                        type="submit">
                        Confirm
                    </button>
                </div>
            </form>
            {openOngoingIssues &&
                <OngoingIssuesTable
                    clearFormData={setDefaultFormData}
                    closePopup={closeOngoingIssuesTable}
                    ongoingIssues={ongoingGeneralIssues}
                    setFormStyles={setFormStyles}
                    generalIssueFormHandler={formHandler}
                    searchEventStartTime={searchEventStartTime}
                    searchText={searchText}
                />}
            {openAffectedEvents && selectedFixtures?.length &&
                <AffectedEventsTable
                    closePopup={() => setAffectedEvents(false)}
                />
            }
            {closeModal &&
                <ConfirmModal
                    message={'Are you sure you want to exit?'}
                    action={closeGeneralIssuesForm}
                    closeForm={() => setCloseModal(false)}
                    wide={true}
                    children={<img className={styles.exitIcon} src={exitIcon} alt="logo"/>}
                />}
        </div>
    );
};

export default GeneralIssueForm;
