import {DateTime} from "luxon";
import {formatDatePicker, getPickerDateFormat, ICalendarDate} from "@helpers/dateFormatters";
import {toastr} from "react-redux-toastr";
import {IContentAnalysisFilters} from "@store/contentAnalysis/types";
import {setFilters} from "@store/contentAnalysis/contentAnalysisSlice";

const adjustDateAndDispatch = (
    newDate: DateTime,
    adjustmentDays: number,
    name: keyof IContentAnalysisFilters,
    message: string,
    dispatch: (action: { payload: { value: string; name: keyof IContentAnalysisFilters } }) => void
) => {
    const adjustedDate = newDate.plus({days: adjustmentDays}).toISO();
    toastr.warning("Warning!", message);

    if (adjustedDate) {
        dispatch(
            setFilters({
                value: getPickerDateFormat(adjustedDate),
                name
            })
        );
    }
};

const handleDateValidation = (
    newDate: DateTime,
    comparisonDate: DateTime,
    name: string,
    isStartDate: boolean,
    dispatch: (action: { payload: { value: string; name: keyof IContentAnalysisFilters } }) => void
) => {
    const diff = Math.abs(newDate.diff(comparisonDate, "days").days);

    if (isStartDate) {
        if (newDate > comparisonDate) {
            adjustDateAndDispatch(
                newDate,
                3,
                "endDate",
                "Start date cannot be later than the end date. Dates were automatically adjusted.",
                dispatch
            );
        }
        if (diff > 28) {
            adjustDateAndDispatch(
                newDate,
                28,
                "endDate",
                "Start date exceeds maximum date range of 28 days. Dates were automatically adjusted.",
                dispatch
            );
        }
    } else {
        if (newDate < comparisonDate) {
            adjustDateAndDispatch(
                newDate,
                -3,
                "startDate",
                "End date cannot be earlier than the start date. Dates were automatically adjusted.",
                dispatch
            );
        }
        if (diff > 28) {
            adjustDateAndDispatch(
                newDate,
                -28,
                "startDate",
                "End date exceeds maximum date range of 28 days. Dates were automatically adjusted.",
                dispatch
            );
        }
    }
};

export const validateAndSetDates = (
    value: ICalendarDate,
    name: string,
    filters: IContentAnalysisFilters,
    dispatch: (action: { payload: { value: string; name: keyof IContentAnalysisFilters } }) => void
) => {
    const start = DateTime.fromISO(formatDatePicker(filters.startDate));
    const end = DateTime.fromISO(formatDatePicker(filters.endDate));
    const newDate = DateTime.fromISO(formatDatePicker(value));

    const isStartDate = name === "startDate";
    const comparisonDate = isStartDate ? end : start;

    handleDateValidation(newDate, comparisonDate, name, isStartDate, dispatch);
};
