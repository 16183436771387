import React, {ReactNode} from 'react';
import styles from './index.module.scss'

interface IPageButtonProps {
    onClick?: () => void
    children: ReactNode
}

const PrimaryButton: React.FC<IPageButtonProps> = React.memo(({onClick, children}) => {
    const handleClick = (e: React.MouseEvent): void => {
        e.preventDefault();
        e.stopPropagation();
        if (onClick) {
            onClick();
        }
    }

    return (
        <button
            onClick={(e) => handleClick(e)}
            onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
            className={styles.btn}
        >
            {children}
        </button>
    );
});

PrimaryButton.displayName = "PrimaryButton"
export default PrimaryButton;
