import {AnyAction, createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {toastr} from "react-redux-toastr";
import {client} from "../../services/api-servise";
import {AxiosError} from "axios";
import {IInputOption} from "../operatorAccess/operatorAccessSlice";
import {appendDataHelper, prependDataHelper} from "../../helpers/reducerHelpers";

interface IIngestResourceDetails {
    id: string,
    competitionId: string,
    sportId: string,
    technicalProviderId: number,
    rightHolderId: string,
    deliveryMethod: number,
    url: string,
    port: number,
    passPhrase: string,
    pushLogin: string,
    application: string,
    stream: string,
    igmChannel: string,
    boro: boolean
}

export interface IResourceReq {
    payload: {
        id?: string,
        rightHolderId?: string,
        technicalProvider?: string,
        competitionId?: string,
        deliveryMethod: number,
        url: string,
        ports: number,
        pushLogin: string,
        application: string,
        stream: string,
        channel: string,
        passPhrase: string,
        boro: boolean
    },
    closeForm: () => void;
}

interface IFormInitDataPayload {
    rightHolderId: string | null;
    sportIds: string | null;
}

interface IFormInitDataResp {
    rightHolders: {
        items: IInputOption[];
    },
    technicalProviders: {
        items: IInputOption[];
    },
    sports: IInputOption[];
    competitions: {
        items: IInputOption[];
    },
    deliveryMethods: {
        items: IInputOption[];
    }
}

export interface IIngestResource {
    id: string,
    competitionName: string,
    technicalProviderName: string,
    rightHolderName: string,
    deliveryMethod: string,
    url: string,
    port: number,
    passPhrase: string,
    pushLogin: string,
    application: string,
    stream: string,
    igmChannel: string,
    boro: boolean
}

interface IIngestResourcesState {
    ingestResources: IIngestResource[];
    rightHolders: IInputOption[];
    technicalProviders: IInputOption[];
    competitions: IInputOption[];
    deliveryMethods: IInputOption[];
    sports: IInputOption[];
    ingestResourceDetails: IIngestResourceDetails | undefined;
    totalCount: number
    itemsPerPage: number
    pageNumber: number
    loading: boolean;
    loadingFormData: boolean;
    error: string;
    scrollLoader: boolean;
    maxItemsInTheTable: number;
}

export interface IGetIngestResourceBody {
    searchValue: string
    pageNumber: number
    itemsPerPage: number
    scrollPosition?: string
}

const ingestResourcesState: IIngestResourcesState = {
    ingestResources: [],
    rightHolders: [],
    technicalProviders: [],
    competitions: [],
    deliveryMethods: [],
    sports: [],
    ingestResourceDetails: undefined,
    totalCount: 0,
    itemsPerPage: 10,
    pageNumber: 1,
    loading: false,
    loadingFormData: false,
    error: '',
    scrollLoader: false,
    maxItemsInTheTable: 30,
}

export const getIngestResources = createAsyncThunk<IIngestResource[], IGetIngestResourceBody, { rejectValue: string }>(
    'ingestResources/get-ingest-resources',
    async ({
               searchValue,
               pageNumber,
               itemsPerPage,
               scrollPosition
           },
           {rejectWithValue, dispatch}) => {
        try {
            const url = `api/IngestResources/confirmed?PageNumber=${pageNumber}&ItemsPerPage=${itemsPerPage}` +
                (searchValue ? `&searchValue=${searchValue}` : "");
            const {
                data,
                headers
            } = await client.get(url)
            const totalCount = headers['x-total-count']
            if (totalCount) {
                dispatch(setTotalCount(Number(totalCount)));
            }
            if (scrollPosition && scrollPosition === "down") {
                dispatch(appendData(data));
                return;
            }
            if (scrollPosition && scrollPosition === "up") {
                dispatch(prependData(data));
                return;
            }
            return data;


        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`);
                return rejectWithValue(e.response?.data);
            }
        }
    }
)

export const getFormInitData = createAsyncThunk<IFormInitDataResp, IFormInitDataPayload | null, {
    rejectValue: string
}>(
    'ingestResources/get-form-init-data',
    async (payload, {rejectWithValue}) => {
        try {
            const initial = !payload;
            const url = initial
                ? "api/IngestResources/init"
                : `api/IngestResources/init?rightHolderId=${payload.rightHolderId}${payload.sportIds && `&sportIds=${payload.sportIds}`}`;
            const {data} = await client.get(url);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getIngestResourceDetails = createAsyncThunk<IIngestResourceDetails, string, {
    rejectValue: string
}>(
    'ingestResources/get-ingest-resource-details',
    async (ingestResourceId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/IngestResources/details?ingestResourceId=${ingestResourceId}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const createIngestResource = createAsyncThunk<IIngestResource, IResourceReq, {
    rejectValue: string
}>(
    'ingestResources/create-ingest-resource',
    async (body, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.post(`api/IngestResources`, body.payload);
            dispatch(body.closeForm);
            toastr.success("IGame", "Ingest Resource was created");
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data.title}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)


export const updateIngestResource = createAsyncThunk<IIngestResource, IResourceReq, {
    rejectValue: string
}>(
    'ingestResources/update-ingest-resource',
    async (body, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.put(`api/IngestResources`, body.payload);
            dispatch(body.closeForm);
            toastr.success("IGame", "Ingest Resource was updated");
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const deleteIngestResource = createAsyncThunk<IIngestResource, string, {
    rejectValue: string
}>(
    'ingestResources/delete-ingest-resource',
    async (ingestResourceId, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`api/IngestResources?ingestResourceId=${ingestResourceId}`);
            toastr.success("IGame", "Ingest Resource was removed");
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const ingestResourcesSlice = createSlice({
    name: 'ingestResources',
    reducers: {
        clearIngestResourceDetails: (state) => {
            state.ingestResourceDetails = undefined;
        },
        setPageNumber: (state, action: PayloadAction<number>) => {
            state.pageNumber = action.payload
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload
        },
        appendData(state, action) {
            appendDataHelper(state, action.payload, 'ingestResources');
        },
        prependData(state, action) {
            prependDataHelper(state, action.payload, 'ingestResources');
        },
    },
    initialState: ingestResourcesState,

    extraReducers: (builder) => {
        builder
            .addCase(getIngestResources.pending, (state) => {
                state.scrollLoader = true
            })
            .addCase(getIngestResources.fulfilled, (state, action) => {
                if (action.payload !== undefined) {
                    state.ingestResources = action.payload
                }
                state.scrollLoader = false
            })
            .addCase(getFormInitData.pending, (state) => {
                state.loadingFormData = true;
            })
            .addCase(getFormInitData.fulfilled, (state, action) => {
                state.loadingFormData = false;
                state.rightHolders = action.payload.rightHolders.items;
                state.technicalProviders = action.payload.technicalProviders.items;
                state.sports = action.payload.sports;
                state.competitions = action.payload.competitions.items;
                state.deliveryMethods = action.payload.deliveryMethods.items;
            })
            .addCase(getIngestResourceDetails.pending, (state) => {
                state.loadingFormData = true;
            })
            .addCase(getIngestResourceDetails.fulfilled, (state, action) => {
                state.loadingFormData = false;
                state.ingestResourceDetails = action.payload;
            })
            .addCase(createIngestResource.fulfilled, (state, action) => {
                state.ingestResources.push(action.payload);
            })
            .addCase(updateIngestResource.fulfilled, (state, action) => {
                state.ingestResources = state.ingestResources.map(resource => resource.id === action.payload.id ? action.payload : resource)
            })
            .addCase(deleteIngestResource.fulfilled, (state, {meta}) => {
                state.ingestResources = state.ingestResources.filter(resource => resource.id !== meta.arg);
            })
            .addMatcher(isError, (state, action: PayloadAction<string>) => {
                state.error = action.payload;
                state.loading = false;
            })
    }
})
export const {
    clearIngestResourceDetails,
    setPageNumber,
    setTotalCount,
    appendData,
    prependData
} = ingestResourcesSlice.actions
export default ingestResourcesSlice.reducer

function isError(action: AnyAction) {
    return action.type.endsWith("rejected")
}
