import React, {InputHTMLAttributes} from 'react';
import styles from "./index.module.scss";


interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string,
}


const Input: React.FC<IInputProps> = ({label, ...props}) => {

    return (
        <>
            {label && <label className={label && styles.label} htmlFor={label}>{label}</label>}
            <input
                onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                className={styles.input}
                id={label ? label : undefined}
                {...props}
            />
        </>
    );
};

Input.displayName = "Input"
export default Input;
