export enum Ingest {
    Unknown = "Select Ingest",
    BTTower = "BT Tower",
    Satellite = "Satellite",
    IP = "IP"
}

export enum IngestOptionId {
    Unknown = 0,
    BTTower = 1,
    Satellite = 2,
    IP = 3
}

export enum IngestTabs {
    Primary = 1,
    BackUP = 2
}

export enum IngestFormController {
    IGameChannels = "IGameChannels",
    BackupIGameChannels = "BackupIGameChannels",
    PrimarySource = "PrimarySource",
    BackupSource = "BackupSource",
    PrimaryIPChannels = "PrimaryIPChannels",
    BackupIPChannels = "BackupIPChannels",
    PrimaryMethod = "PrimaryMethod",
    BackupMethod = "BackupMethod",
    ContentSupplier = "ContentSupplier",
    IngestTypeError = "IngestTypeError"
}
