import React, {useEffect} from 'react';
import styles from './index.module.scss'
import FirstStepForm from "./FirstStepForm/FirstStepForm";
import {useNavigate} from "react-router-dom";


const Login: React.FC = () => {
    const navigate = useNavigate()

    useEffect(() => {
        const isAuth = localStorage.getItem("book-token")

        if (isAuth) {
            navigate("/")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.loginPage}>
            <FirstStepForm/>
        </div>
    )
};

export default Login;
