import React, {useRef, useState} from "react";
import styles from "./index.module.scss";
import ChartLabel from "../../../ChartLabel/ChartLabel";
import {Chart, Pies, Transform} from "rumble-charts";
import {throttle} from "../../../../helpers/throttle";
import DonutChartLegend from "../DonutChartLegend/DonutChartLegend";

export interface IDonutChartProps {
    data: any;
    minorFilters: any;
    colors: string[];
    total: number;
    viewType: string
}

export interface ILabelData {
    color: string
    name: string
    percentage: number
    x: number
    y: number
}

export interface IDonutChartBasicData {
    name: string;
    eventsNumber: number;
}

export interface IEvent {
    clientX: number;
    clientY: number;
    pageX: number;
    pageY: number;
    target: any;
}

const DonutChart: React.FunctionComponent<IDonutChartProps> = (
    {
        data,
        colors,
        minorFilters,
        total,
        viewType
    }) => {

    const labelRef = useRef<HTMLDivElement>(null);
    const rectRef = useRef<HTMLDivElement>(null);
    const [labelData, setLabelData] = useState<ILabelData | null>(null);

    const setLabelPosition = (e: IEvent) => {
        const chartElement = rectRef.current;
        if (!chartElement) {
            return;
        }

        const chartRect = chartElement.getBoundingClientRect();
        const xCoord = e.clientX - chartRect.left;
        const yCoord = e.clientY - chartRect.top;

        if (labelRef.current !== null) {
            labelRef.current.classList.add(`${styles.donutChartLabelActive}`);
            labelRef.current.style.left = xCoord - 100 + "px";
            labelRef.current.style.top = yCoord - 50 + "px";
        }
    }

    const onMouseOver = throttle((e: IEvent, seriesIndex: number) => {
        setLabelData(data[0].data[seriesIndex]);
        setLabelPosition(e);
        return (e.target.style.opacity = 0.8);
    }, 300);

    const onMouseLeave = throttle((e: IEvent) => {
        setLabelData(null);
        setLabelPosition(e);
        return (e.target.style.opacity = 1);
    }, 300);

    const onMouseMove = throttle((e: IEvent) => {
        setLabelPosition(e);
    }, 200);

    return (
        <>
            <div className={styles.donut} ref={rectRef}>
                <div ref={labelRef} className={styles.donutChartLabel}>
                    {
                        labelData ? (
                            <ChartLabel
                                labelData={labelData}
                                colors={colors}
                                forMainChart={false}
                            />
                        ) : null
                    }
                </div>
                <div className={styles.chartInner}>
                    <Chart
                        height={345}
                        minY={0}
                        series={data}
                        width={345}>
                        <Transform method={['transpose', 'stack']}>
                            <Pies
                                combined
                                innerRadius="73%"
                                pieAttributes={({seriesIndex}) => ({
                                    onMouseOver: (e: IEvent) => onMouseOver(e, seriesIndex),
                                    onMouseLeave: (e: IEvent) => onMouseLeave(e),
                                    onMouseMove: (e: IEvent) => onMouseMove(e),
                                })}
                            />
                        </Transform>
                    </Chart>
                    <DonutChartLegend
                        minorFilters={minorFilters}
                        data={data}
                        colors={colors}
                    />
                </div>
                <div className={styles.donutTotal}>
                    <p>{viewType}</p>
                    <p>{total.toLocaleString('en-US')}</p>
                </div>
            </div>
        </>
    )
}
DonutChart.displayName = "DonutChart"
export default DonutChart;
