import React, {useState} from 'react'
import './style.scss';
import DropdownTooltip from '../DropdownTooltip/DropdownTooltip';
import Select, {FormatOptionLabelContext, GroupBase, Props} from "react-select";

type SelectProps<Option, IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>> = Props<Option, IsMulti, Group> &
    {
        width?: string;
        background?: string;
        defaultValue?: { value: string, label: string } | null;
        alteringClass?: string;
        minHeight?: string;
        disabled?: boolean;
        showExpander?: boolean
        maxItemsToShowExpander?: number
        itemsLength?: number
    }

const SelectInput = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>
(props: SelectProps<Option, IsMulti, Group>) => {
    const {
        width,
        background,
        defaultValue,
        isMulti,
        alteringClass,
        disabled,
        showExpander = false,
        maxItemsToShowExpander = 4,
        itemsLength
    } = props;
    const backgroundColor = background ? background : '#FFFFFF';
    const [isExpandedInput, setIsExpandedInput] = useState(false);

    const checkIsToShowExpandBtn = () => {
        if (itemsLength && showExpander) {
            return itemsLength > maxItemsToShowExpander;
        }
        return false;
    }

    const labelContentHandler = (data: any, context: FormatOptionLabelContext) => {
        const isOptionSelected = defaultValue && data.label === defaultValue.label;
        let labelContent = <>{data.label}</>;

        if (context === 'value' && isMulti) {
            labelContent = <DropdownTooltip id={data.value} text={data.label}/>;

        } else if (context === 'menu' && isOptionSelected) {
            labelContent = (
                <div style={{position: 'relative'}}>
                    {data.label}
                    <span className="selected-checkmark"/>
                </div>
            );
        }
        return labelContent;
    }
    return (
        <div onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
        }} className={"select-inner"} style={{width, backgroundColor}}>
            {checkIsToShowExpandBtn() &&
                <button className={"expander"} onClick={() => {
                    setIsExpandedInput(!isExpandedInput)
                }}>
                    {isExpandedInput ? <span className={"expanded-icon"}>--</span> :
                        <span className={"collapsed-icon"}>+</span>}
                </button>
            }
            <Select
                isDisabled={disabled}
                styles={{
                    control: (baseStyles) => ({
                        ...baseStyles,
                        height: isExpandedInput ? "auto" : "48px",
                        overflow: itemsLength && itemsLength > maxItemsToShowExpander ? "hidden" : "visible",
                        boxShadow: isExpandedInput ? "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px" : "none"
                    })
                }}
                classNamePrefix={alteringClass || "app-select"}
                {...props}
                formatOptionLabel={(data: any, {context}) => labelContentHandler(data, context)}
            />
        </div>
    );
};


SelectInput.displayName = "SelectInput"
export default SelectInput;

