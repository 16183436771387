import React, {useRef, useState} from 'react';
import {AppInfiniteScroll} from "../../../../../../../components/AppInfiniteScroll/AppInfiniteScroll";
import AppTableWhite from "../../../../../../../components/AppTableWhite/AppTableWhite";
import SortingHeader
    , {
    IColumnHeaderItem
} from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/children/SortingHeader/SortingHeader";
import uuid from "react-uuid";
import moment from "moment";
import styles from "../../../../../IGameView/Settings/Reporting/ReportingTable/index.module.scss";
import ingestIcon from "../../../../../../../img/ingestIcon.svg";
import {Tooltip} from "react-tooltip";
import CustomCheckbox from "../../../../../../../components/CustomCheckbox/CustomCheckbox";
import {useAppDispatch, useAppSelector} from "../../../../../../../hooks/hooks";
import {getReports, setRHPageNumber, setRHSorting} from "../../../../../../../store/rhReporting/rhReportingSlice";
import IngestForm from "../../../../../IGameView/Settings/Reporting/IngestForm/IngestForm";
import {
    ISorting
} from "../../../../../TechnicalProviderView/UpcomingBookedFixtures/children/FixturesTable/FixturesTable";
import {formatRangeDate} from "../../../../../../../helpers/dateFormatters";
import {IFixturesReportPayload} from "../../../../../../../store/reportingFixtures/types";
import NoDataFoundStub from "../../../../../../../components/NoDataFoundStub/NoDataFoundStub";
import {toastr} from "react-redux-toastr";
import {OrderByProperty} from "@pages/Cms/IGameView/Settings/Reporting/ReportingTable/helpers/headerControls";

interface IRHReportingTableProps {
    searchValue: string | undefined
}

const RHReportingTable: React.FC<IRHReportingTableProps> = ({searchValue}) => {
    const dispatch = useAppDispatch()
    const {
        isLoading,
        pageNumber,
        itemsPerPage,
        totalCount,
        fixturesReports,
        sorting,
        maxItemsInTheTable,
        RHfilters,

    } = useAppSelector(state => state.rhReporting)
    const [ingestFormState, setIngestFormState] = useState({isShow: false, fixtureId: "", rightHolderId: ""});
    const [updatingFixtureId, setUpdatingFixtureId] = useState<string | number>("");
    const containerRef = useRef<HTMLDivElement>(null);
    const headerControls: IColumnHeaderItem[] = [
        {
            title: "Start Date",
            criteria: OrderByProperty.Date,
            textAlign: "left"
        },
        {
            title: "Line-Up UTC",
            criteria: null,
            textAlign: "left"
        },
        {
            title: "Sport",
            criteria: null,
            textAlign: "left"
        },
        {
            title: "Property",
            criteria: null,
            textAlign: "left"
        },
        {
            title: "Title",
            criteria: null,
            textAlign: "left"
        },
        {
            title: "",
            criteria: null,
            textAlign: "left"
        },
        {
            title: "Verified",
            criteria: null,
            textAlign: "center"
        },
        {
            title: "Setup",
            criteria: null,
            textAlign: "center"
        }
    ]

    const setPage = (pageNumber: number) => {
        dispatch(setRHPageNumber(pageNumber))
    }

    const onSetSorting = (sorting: ISorting) => {
        if (!RHfilters.startDate || !RHfilters.endDate) return;
        const payload: IFixturesReportPayload = {
            payload: {
                searchValue: searchValue ? searchValue : '',
                pageNumber: 1,
                itemsPerPage,
                sorting: {
                    property: sorting.criteria,
                    isAscending: sorting.isAscending
                },
                ingestType: +RHfilters.ingestType!.value === 4 ? null : +RHfilters.ingestType!!.value,
                dateFrom: formatRangeDate(RHfilters.startDate),
                dateTo: formatRangeDate(RHfilters.endDate)
            },
            isScroll: false
        };

        dispatch(setRHSorting(sorting))
        dispatch(getReports(payload))
        dispatch(setRHPageNumber(1))
    }

    const fetchTableData = (page: number, pageSize: number, scrollPosition: string) => {
        const payload = {
            payload: {
                searchValue,
                pageNumber: page,
                itemsPerPage: pageSize,
                sorting: {
                    property: sorting.criteria,
                    isAscending: sorting.isAscending,
                },
                ingestType: null,
                dateFrom: formatRangeDate(RHfilters.startDate),
                dateTo: formatRangeDate(RHfilters.endDate),
            },
            isScroll: true,
            scrollPosition
        };
        if (containerRef && containerRef.current) {
            containerRef.current.scrollTop = 0
        }
        dispatch(getReports(payload))
    }

    const closeIngestForm = () => setIngestFormState({...ingestFormState, isShow: false})

    const openRhIngestForm = (fixtureId: string, rightHolderId: string, ingestType: number) => {
        if (ingestType) {
            setIngestFormState({
                isShow: true,
                fixtureId,
                rightHolderId,
            })
            setUpdatingFixtureId(fixtureId)
        } else {
            return toastr.error("IGame", "Fixture is not ingested")
        }
    }

    return (
        <div>
            {ingestFormState.isShow &&
                <IngestForm
                    updatingFixtureId={updatingFixtureId}
                    rightHolderId={ingestFormState.rightHolderId}
                    fixtureId={ingestFormState.fixtureId}
                    closeIngestForm={closeIngestForm}

                />
            }
            {fixturesReports.length === 0 && <NoDataFoundStub
                message={"No records found matching the given filter criteria"}
                textColor="white"
            />}
            {fixturesReports.length !== 0 &&
                <AppInfiniteScroll
                    containerRef={containerRef}
                    scrollBounce={400}
                    scrollingContainerHeight="45vh"
                    isLoading={isLoading}
                    currentPage={pageNumber}
                    setPage={setPage}
                    fetchData={fetchTableData}
                    pageSize={itemsPerPage}
                    totalItems={totalCount}
                    maxItemsInTheTable={maxItemsInTheTable}
                    tableDataLength={fixturesReports.length}
                >
                    {
                        <AppTableWhite>
                            {fixturesReports.length > 0 &&
                                <SortingHeader
                                    sorting={sorting}
                                    setSorting={onSetSorting}
                                    controls={headerControls}
                                />}
                            <tbody>
                            {fixturesReports?.map(fixture => {
                                return (
                                    <tr key={uuid()}>
                                        <td>
                                            {moment(fixture.date).format("DD/MM/YYYY") + " " + moment(fixture.startUtc).format("HH:mm")}
                                        </td>
                                        <td>{moment(fixture.lineUp).format("HH:mm")}</td>
                                        <td>{fixture.sport}</td>
                                        <td>{fixture.competition}</td>
                                        <td>{fixture.fixture}</td>
                                        <td>
                                            <button
                                                id={"ingest"} className={styles.tableIcon}
                                                onClick={() => {
                                                    openRhIngestForm(
                                                        fixture.fixtureId,
                                                        fixture.rightHolderId,
                                                        fixture.ingestType
                                                    )
                                                }
                                                }
                                            >
                                                <img
                                                    src={ingestIcon}
                                                    alt="logo"
                                                />
                                            </button>
                                            <Tooltip
                                                anchorSelect="#ingest"
                                                content="Ingest"
                                                className={styles.reportToolTip}
                                            />
                                        </td>
                                        <td className={styles.textCentre}>
                                            <div
                                                className={styles.checkboxInner}>
                                                <CustomCheckbox
                                                    readOnly
                                                    id={fixture.fixtureId}
                                                    checked={!!fixture.isVerified}
                                                />
                                            </div>
                                        </td>
                                        <td className={styles.textCentre}>
                                            <div
                                                className={styles.checkboxInner}>
                                                <CustomCheckbox
                                                    readOnly
                                                    checked={!!fixture.setup}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </AppTableWhite>}
                </AppInfiniteScroll>}
        </div>
    );
};

export default RHReportingTable;
