import React from "react";
import styles from "./index.module.scss";
import {BsArrowDown, BsArrowUp} from "react-icons/bs";
import {ISorting} from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/BookFixtures";


export interface ISortingHeaderProps {
    checkout?: boolean;
    sorting?: ISorting;
    setSorting?: (sorting: ISorting) => void;
    controls: IColumnHeaderItem[];
}

export interface IColumnHeaderItem {
    title: string | string[];
    criteria: string | null;
    textAlign?: "left" | "right" | "center" | "justify"
}

const SortingHeader: React.FunctionComponent<ISortingHeaderProps> = (
    {
        checkout,
        sorting,
        setSorting,
        controls
    }) => {

    const handleSorting = (criteria: string | null): void => {
        if (!checkout && sorting && setSorting) {
            if (criteria === sorting.criteria) {
                setSorting({
                    criteria,
                    isAscending: !sorting.isAscending
                })
            } else {
                setSorting({
                    criteria,
                    isAscending: true
                })
            }
        } else {
            return;
        }
    }

    const generateTableHeaderStyles = (column: IColumnHeaderItem) => {
        let className = "";
        if (!checkout) {
            if (column.criteria === "ContentSupplier") {
                className = styles.contentSupplierColumn;
            } else if (column.title === "Actions") {
                className = styles.headerActions;
            } else if (column.title !== "Actions" && column.criteria === null) {
                className = styles.nonClickable;
            } else if (sorting && sorting.criteria === column.criteria) {
                className = styles.headerClickableSelected;
            } else {
                className = styles.headerClickable;
            }
        }
        return className;
    }

    return (
        <thead data-testid="sorting-header">
        <tr>
            {
                controls.map((column) => {
                    if (column.criteria !== null && typeof column.title === "string") {
                        return (
                            <th
                                style={{textAlign: column.textAlign}}
                                className={generateTableHeaderStyles(column)}
                                onClick={() => handleSorting(column.criteria)}
                                key={column.criteria}
                            >
                                {column.title === 'Content Supplier' ? <>{'Content'}
                                    <br/> {'Supplier'}</> : column.title}
                                {
                                    sorting?.criteria === column.criteria && (
                                        <div className={styles.arrow}>
                                            {sorting.isAscending ? <BsArrowUp/> : <BsArrowDown/>}
                                        </div>
                                    )
                                }
                            </th>
                        )
                    } else {
                        if (Array.isArray(column.title)) {
                            return (
                                <th
                                    style={{textAlign: column.textAlign}}
                                    key={column.criteria}
                                    className={styles.nonClickable}>
                                    <div className={styles.complexTitle}>
                                        {
                                            column.title.map(titlePart => (
                                                <span key={titlePart}>
                                                {titlePart}
                                            </span>
                                            ))
                                        }
                                    </div>
                                </th>
                            )
                        } else {
                            return (
                                <th
                                    style={{textAlign: column.textAlign}}
                                    key={column.criteria} className={generateTableHeaderStyles(column)}>
                                    {column.title}
                                </th>
                            )
                        }
                    }
                })
            }
        </tr>
        </thead>
    )
}

export default SortingHeader;
