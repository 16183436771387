import React from 'react';
import {FaStar} from "react-icons/fa";
import styles from "./index.module.scss"
import {CiStar} from "react-icons/ci";

interface IFavoriteStar {
    isActive: boolean
    fontSize: number
    color?: string
}

const FavoriteStar: React.FC<IFavoriteStar> = ({isActive, color, fontSize}) => {
    if (isActive) {
        return (
            <FaStar color={color} style={{fontSize: fontSize + "px"}} className={styles.favStarActive}/>
        )
    } else {
        return <CiStar style={{fontSize: fontSize + "px"}} className={styles.favStar}/>
    }
};

export default FavoriteStar;
