import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';
import {useForm} from "react-hook-form";
import {IoIosClose} from "react-icons/io";
import FormButtonsBlock from "../FormButtonsBlock/FormButtonsBlock";
import FormRouting from "../FormRouting/FormRouting";
import {useAppDispatch, useAppSelector} from "../../../../../../../../hooks/hooks";
import {
    addRightHolder, IRightHolderBody, IUserPages,
    updateRightHolder
} from "../../../../../../../../store/userManagement/userManagementSlice";
import {
    createNewRHPayload
} from "../../../helpers/createRightsHolderPayload";
import Loader from "../../../../../../../../components/Loader/Loader";
import {useRightHolderFormData} from "../../../UserManagementHooks/useRightHolderFormData";
import {setProviderValue} from "./helpers";
import {IUserTypeForm} from "../OperatorForm/OperatorForm";
import StepTwoForm from "./children/StepTwoForm";
import StepThreeForm from "./children/StepThreeForm";
import StepOneForm from "./children/StepOneForm";
import ConfirmModal from "../../../../../../../../components/ConfirmModal/ConfirmModal";
import {AiOutlineExclamation} from 'react-icons/ai';
import StepFourForm from "./children/StepFourForm";
import {toastr} from "react-redux-toastr";

const RightHolderForm: React.FC<IUserTypeForm> = React.memo(({closeForm}) => {
    const {handleSubmit, control, setValue, formState: {errors}} = useForm({
        mode: 'all'
    });
    const [currentTab, setCurrentTab] = useState<string>('Step 1');
    const [completedSteps, setCompletedSteps] = useState<number[]>([]);
    const {
        pageVisibilities,
        dataProviders,
        pricings,
        loading,
        existingEmails,
        dataProvidersInUse,
        rightHolders,
        rightsHolderDetails,
        roles,
    } = useAppSelector(state => state.userManagement);

    const {
        stepOneFormData,
        stepTwoFormData,
        stepThreeFormData,
        stepFourFormData,
        setStepOneFormData,
        setStepTwoFormData,
        setStepThreeFormData,
        handleProviderId,
        onChangeStepOne,
        handlePricing,
        onChangeStepThree,
        handleFileSelection,
        checkboxHandler,
        selectAllPages,
        deselectAllPages,
        onChangeContractDateForStepThree,
        onChangeStepThreeCheckboxes
    } = useRightHolderFormData();

    const dispatch = useAppDispatch();

    const [isOpenExitConfirm, setIsOpenExitConfirm] = useState(false);

    const addCompletedFormStep = (step: number) => {
        const isExistStep = completedSteps.includes(step)
        if (!isExistStep) {
            setCompletedSteps([...completedSteps, step])
        }
    }

    useEffect(() => {
        setFormData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rightsHolderDetails]);

    const setFormData = () => {
        if (rightsHolderDetails) {
            const stepOneNewData = {
                name: rightsHolderDetails.name,
                dataProviderId: setProviderValue(rightsHolderDetails, dataProviders, dataProvidersInUse),
                address: rightsHolderDetails.address,
                clientAdmin: rightsHolderDetails.clientAdmin,
                adminEmail: rightsHolderDetails.adminEmail,
                commercialContact: rightsHolderDetails.commercialContact,
                commercialEmail: rightsHolderDetails.commercialEmail,
                schedulingContact: rightsHolderDetails.schedulingContact,
                schedulingEmail: rightsHolderDetails.schedulingEmail,
                liveIssuesContact: rightsHolderDetails.liveIssuesContact,
                liveIssuesContactEmail: rightsHolderDetails.liveIssuesContactEmail,
            }
            setValue("dataProviderId", setProviderValue(rightsHolderDetails, dataProviders))
            setValue("name", rightsHolderDetails.name)
            setStepOneFormData(stepOneNewData)

            const stepTwoNewData = {
                pricing: rightsHolderDetails.pricing,
                rolePages: rightsHolderDetails.rolePageDTOs
            }
            setStepTwoFormData(stepTwoNewData)

            const stepThreeNewData = {
                allowancePerOperator: rightsHolderDetails.allowancePerOperator ? rightsHolderDetails.allowancePerOperator : undefined,
                overageRatePerGB: rightsHolderDetails.overageRatePerGB ? rightsHolderDetails.overageRatePerGB : undefined,
                ingestRatePerHour: rightsHolderDetails.ingestRatePerHour ? rightsHolderDetails.ingestRatePerHour : undefined,
                playerRatePerView: rightsHolderDetails.playerRatePerView ? rightsHolderDetails.playerRatePerView : undefined,
                operatorRatePerMonth: rightsHolderDetails.operatorRatePerMonth ? rightsHolderDetails.operatorRatePerMonth : undefined,
                contractStartDate: rightsHolderDetails.contractStartDate,
                isBandwidthEnabled: rightsHolderDetails.isBandwidthEnabled,
                isIngestEnabled: rightsHolderDetails.isIngestEnabled,
                isOperatorEnabled: rightsHolderDetails.isOperatorEnabled,
                isPlayerUsageEnabled: rightsHolderDetails.isPlayerUsageEnabled
            }
            setValue("allowancePerOperator", stepThreeNewData.allowancePerOperator)
            setValue("overageRatePerGB", stepThreeNewData.overageRatePerGB)
            setValue("ingestRatePerHour", stepThreeNewData.ingestRatePerHour)
            setValue("playerRatePerView", stepThreeNewData.playerRatePerView)
            setValue("operatorRatePerMonth", stepThreeNewData.operatorRatePerMonth)
            setValue("contractStartDate", stepThreeNewData.contractStartDate)
            setStepThreeFormData(stepThreeNewData)
        }
    }

    const submitFormHandler = () => {
        if (currentTab === 'Step 1') {
            addCompletedFormStep(0)
            setCurrentTab('Step 2');
        }

        if (currentTab === 'Step 2') {
            const somePagesSelected = stepTwoFormData.rolePages.some((role: IUserPages) => role.pageIds.length > 0);
            if (!somePagesSelected) return toastr.error("Error!", "You need to select at least one page to proceed");

            addCompletedFormStep(1);
            setCurrentTab('Step 3');
        }
        if (currentTab === 'Step 3') {
            setCurrentTab('Step 4');
        }
        if (currentTab === 'Step 4') {
            const payload = createNewRHPayload(
                stepOneFormData,
                stepTwoFormData,
                stepThreeFormData,
                stepFourFormData
            );

            if (rightsHolderDetails) {
                payload.rightHolderId = rightsHolderDetails.id;
                const updateUser: IRightHolderBody = {
                    payload,
                    closeForm
                }
                dispatch(updateRightHolder(updateUser));
            } else {
                const newUser: IRightHolderBody = {
                    payload,
                    closeForm
                }
                dispatch(addRightHolder(newUser));
            }
        }
    }

    const closeAllPopups = () => {
        setIsOpenExitConfirm(false);
        closeForm();
    }

    return (
        <div className={styles.overlay}>
            {loading && <Loader/>}
            {!loading || rightsHolderDetails ?
                <div className={styles.formContainer}>

                    <div className={styles.closeButtonWrapper}>
                        <IoIosClose onClick={() => setIsOpenExitConfirm(true)}/>
                    </div>

                    <div className={styles.titleWrapper}>
                        <h6>{rightsHolderDetails ? `Edit Rights Holder: ${rightsHolderDetails.name}` : 'Add Rights Holder'}</h6>
                    </div>

                    {currentTab === 'Step 1' && <form onSubmit={handleSubmit(submitFormHandler)}>
                        <div className={styles.stepsWrapper}>
                            <FormRouting
                                submitFormHandler={submitFormHandler}
                                handleSubmit={handleSubmit}
                                setCompletedSteps={setCompletedSteps}
                                completedSteps={completedSteps}
                                currentTab={currentTab}
                                totalSteps={4}
                                setCurrentTab={setCurrentTab}
                            />
                        </div>

                        <StepOneForm
                            stepOneFormData={stepOneFormData}
                            control={control}
                            errors={errors}
                            onChangeStepOne={onChangeStepOne}
                            rightsHolderDetails={rightsHolderDetails}
                            rightHolders={rightHolders}
                            existingEmails={existingEmails}
                            dataProvidersInUse={dataProvidersInUse}
                            handleProviderId={handleProviderId}
                            dataProviders={dataProviders}
                        />

                        <FormButtonsBlock onClickHandler={() => setIsOpenExitConfirm(true)} text={'Next'}/>
                    </form>}

                    {currentTab === 'Step 2' && <form onSubmit={handleSubmit(submitFormHandler)}>
                        <div className={styles.stepsWrapper}>
                            <FormRouting
                                submitFormHandler={submitFormHandler}
                                handleSubmit={handleSubmit}
                                setCompletedSteps={setCompletedSteps}
                                completedSteps={completedSteps}
                                currentTab={currentTab}
                                totalSteps={4}
                                setCurrentTab={setCurrentTab}
                            />
                        </div>

                        <StepTwoForm
                            control={control}
                            pricings={pricings}
                            pageVisibilities={pageVisibilities}
                            checkboxHandler={checkboxHandler}
                            handlePricing={handlePricing}
                            stepTwoFormData={stepTwoFormData}
                            roles={roles}
                            selectAllPages={selectAllPages}
                            deselectAllPages={deselectAllPages}
                        />

                        <FormButtonsBlock onClickHandler={() => setIsOpenExitConfirm(true)} text={'Next'}/>
                    </form>
                    }

                    {currentTab === 'Step 3' && <form onSubmit={handleSubmit(submitFormHandler)}>
                        <div className={styles.stepsWrapper}>
                            <FormRouting
                                submitFormHandler={submitFormHandler}
                                handleSubmit={handleSubmit}
                                setCompletedSteps={setCompletedSteps}
                                completedSteps={completedSteps}
                                currentTab={currentTab}
                                totalSteps={4}
                                setCurrentTab={setCurrentTab}
                            />
                        </div>

                        <StepThreeForm
                            setCheckboxes={onChangeStepThreeCheckboxes}
                            setContractDate={onChangeContractDateForStepThree}
                            control={control}
                            errors={errors}
                            stepThreeFormData={stepThreeFormData}
                            onChangeStepThree={onChangeStepThree}
                        />
                        <FormButtonsBlock onClickHandler={() => setIsOpenExitConfirm(true)} text={'Next'}/>
                    </form>
                    }

                    {currentTab === 'Step 4' && <form onSubmit={handleSubmit(submitFormHandler)}>
                        <div className={styles.stepsWrapper}>
                            <FormRouting
                                submitFormHandler={submitFormHandler}
                                handleSubmit={handleSubmit}
                                setCompletedSteps={setCompletedSteps}
                                completedSteps={completedSteps}
                                currentTab={currentTab}
                                totalSteps={4}
                                setCurrentTab={setCurrentTab}
                            />
                        </div>

                        <StepFourForm
                            control={control}
                            errors={errors}
                            stepFourFormData={stepFourFormData}
                            handleFileSelection={handleFileSelection}
                        />
                        <FormButtonsBlock onClickHandler={() => setIsOpenExitConfirm(true)} text={'Confirm'}/>
                    </form>
                    }
                </div>
                : null
            }
            {isOpenExitConfirm &&
                <ConfirmModal
                    closeForm={() => setIsOpenExitConfirm(false)}
                    action={closeAllPopups}
                    message={"Are you sure you want to exit?"}>
                    <AiOutlineExclamation color={'#FFFFFF'} size={140}/>
                </ConfirmModal>
            }
        </div>
    );
});

RightHolderForm.displayName = "RightHolderForm"
export default RightHolderForm;
