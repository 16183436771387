import styles from "../../index.module.scss";
import {Control, Controller, FieldErrors, FieldValues, UseFormGetValues} from 'react-hook-form';
import React, {useEffect} from "react";
import {useAppSelector} from "@hooks/hooks";
import {RootState} from "@store/index";
import IngestRender from "../../helpers/IngestRender";
import {IMethodPayloads} from "../../types/types";
import {IngestFormController, IngestTabs} from "@enums/ingest";
import {IIngestFormData} from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/BookFixtures";

export interface IBTTowerInput {
    source: string;
}

interface IUseBTTowerFormProps {
    ingestFormData: IIngestFormData;
    iGameChannelValue: { label: string, value: string };
    setMethodPayloads: React.Dispatch<React.SetStateAction<IMethodPayloads>>;
    methodPayloads: IMethodPayloads;
    currentTabState: IngestTabs
    errors: FieldErrors;
    control: Control;
    getValues: UseFormGetValues<FieldValues>;
}

export const BTTower = (
    {
        ingestFormData,
        methodPayloads,
        iGameChannelValue,
        setMethodPayloads,
        currentTabState,
        errors,
        control,
        getValues,
    }: IUseBTTowerFormProps) => {

    const {details} = useAppSelector((state: RootState) => state.rightHolderBooking);


    const changeBTTSource = (source: string) => {
        const payload = {
            fixture: {
                fixtureId: ingestFormData.fixture?.id,
                source,
            },
        };
        currentTabState === IngestTabs.BackUP ? setMethodPayloads({
            ...methodPayloads,
            BTTowerBackup: payload
        }) : setMethodPayloads({...methodPayloads, BTTower: payload});
    };

    useEffect(() => {
        if (details.btTower && iGameChannelValue) {
            changeBTTSource(details.btTower.source);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iGameChannelValue?.value]);

    const BTTower = (
        <div className={styles.test}>
            <label>
                Source
                <Controller
                    control={control}
                    name={currentTabState === IngestTabs.Primary ? IngestFormController.PrimarySource : IngestFormController.BackupSource}
                    rules={{required: true}}
                    render={({field: {onChange, value}}) => (
                        <input
                            name={currentTabState === IngestTabs.Primary ? IngestFormController.PrimarySource : IngestFormController.BackupSource}
                            onChange={e => {
                                onChange(e.target.value);
                                changeBTTSource(e.target.value);
                            }}

                            type="text"
                            value={value || ''}
                            aria-invalid={!!errors.source}
                        />
                    )}
                />
                {errors.BackupSource && errors.BackupSource.type === "required" && (
                    <span>{errors.BackupSource.message && String(errors.BackupSource.message)}</span>
                )}
                {errors.PrimarySource && errors.PrimarySource.type === "required" && (
                    <span>{errors.PrimarySource.message && String(errors.PrimarySource.message)}</span>
                )}
            </label>
        </div>
    );

    return (IngestRender.BTTower(BTTower, currentTabState, getValues))
};
