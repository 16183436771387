import React from "react";
import SelectInput from "../../../SelectInput/SelectInput";
import {IDropdownOptions, IMinorFilters} from "../../types";
import NoDataFoundStub from "../../../NoDataFoundStub/NoDataFoundStub";
import styles from "./index.module.scss";
import AppTableWhite from "../../../AppTableWhite/AppTableWhite";

export interface ITopTenViewProps {
    setDonutMode: (value: boolean) => void;
    setFilters: (filters: IMinorFilters) => void;
    dropdownOptions: IDropdownOptions;
    filters: IMinorFilters;
    topTenData: any;
    loading: boolean;
}

const TopTenView: React.FunctionComponent<ITopTenViewProps> = (
    {
        setDonutMode,
        setFilters,
        dropdownOptions,
        filters,
        topTenData
    }) => {
    return (
        <>
            <div className={styles.unfoldedUpper}>
                <div className={styles.header}>
                    <h6>Top ten</h6>
                    <div className={styles.optionsDropdown}>
                        <SelectInput
                            options={dropdownOptions.topTenViewTypes}
                            onChange={(x) => setFilters({
                                ...filters,
                                donutChartAndTopTenViewType: x!.label
                            })}
                            placeholder="View by"
                            value={{
                                value: filters.donutChartAndTopTenViewType.toLowerCase(),
                                label: filters.donutChartAndTopTenViewType
                            }}
                        />
                    </div>
                </div>
                <div className={styles.leftSideModeButtons}>
                    <button onClick={() => setDonutMode(true)} className={styles.leftBtn}>Donut</button>
                    <button className={styles.rightBtn}>
                        List
                    </button>
                </div>
            </div>
            <div className={styles.topTen}>
                {
                    topTenData && topTenData.length !== 0
                        ?
                        <div className={styles.tableWrapper}>
                            <AppTableWhite>
                                <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Views</th>
                                    <th>Unique User</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    topTenData.map((x: any, index: number) => (
                                        <tr key={index}>
                                            <td>{x.name}</td>
                                            <td>{x.views.toLocaleString('en-US')}</td>
                                            <td>{x.uniqueUsers.toLocaleString('en-US')}</td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </AppTableWhite>
                        </div>
                        :
                        <NoDataFoundStub textColor={"#FFFFFF"}/>
                }
            </div>
        </>
    )
}
TopTenView.displayName = "TopTenView"
export default TopTenView;
