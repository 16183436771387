import React, {useState} from 'react';
import {IBookmarkPayload} from "../../../../../../store/operatorAnalytics/operatorAnalyticsSlice";
import styles from "./index.module.scss"
import FavoriteStar from "../../../../../FavoriteStar/FavoriteStar";
import edit from "../../../../../../img/editBlackIcon.svg";
import trash from "../../../../../../img/deleteBlackIcon.svg";
import ConfirmModal from "../../../../../ConfirmModal/ConfirmModal";
import trashWhite from "../../../../../../img/trashWhite.svg";
import {useAppDispatch} from "../../../../../../hooks/hooks";
import {AppThunk} from "../../../../../../store";
import close from "../../../../../../img/close.svg";
import {Tooltip} from "react-tooltip";

interface IBookmarkList {
    bookmarks: IBookmarkPayload[]
    closeBookmark: () => void
    onBookmarkClick: (bookmark: IBookmarkPayload) => void
    onEditBookmark: (item: { id: string; name: string; }) => void
    deleteBookmark: (bookmark: string) => AppThunk;
    bookmarkInUsage: string
}

const BookmarkList: React.FC<IBookmarkList> = (
    {
        bookmarks,
        closeBookmark,
        onBookmarkClick,
        onEditBookmark,
        deleteBookmark,
        bookmarkInUsage
    }) => {
    const dispatch = useAppDispatch()
    const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
    const [deletingBookmark, setDeletingBookmark] = useState<IBookmarkPayload | null>(null);
    const handleDeleteModalClose = () => {
        setIsShowDeleteModal(false)
    }
    return (
        <div
            className={styles.overlay}
            data-testid="bookmarks-list"
        >
            {
                isShowDeleteModal && deletingBookmark &&
                <ConfirmModal
                    message={"Do you want to delete this bookmark?"}
                    closeForm={handleDeleteModalClose}
                    action={() => dispatch(deleteBookmark(deletingBookmark.id))}
                    title="Delete Bookmark">
                    <img
                        className={styles.deleteIconBig}
                        src={trashWhite}
                        alt="trash"/>
                </ConfirmModal>
            }
            <div onClick={(e) => e.stopPropagation()} className={styles.bookmarkList}>
                <button
                    onClick={(event) => {
                        event.stopPropagation()
                        closeBookmark()
                    }}
                    className={styles.closeButton}
                >

                    <img src={close} alt="close"/>
                </button>
                <div className={styles.titleInner}>
                    <h6 className={styles.title}>Bookmarked</h6>
                </div>
                <ul className={styles.list}>
                    {
                        bookmarks.map((x: IBookmarkPayload, index: number) => (
                            x.name !== ""
                            &&
                            <li
                                onClick={() => onBookmarkClick(x)}
                                className={
                                    x.id === bookmarkInUsage
                                        ? `${styles.listItem} ${styles.listItemActive}`
                                        : styles.listItem
                                }
                                key={index}
                                data-testid="bookmark"
                            >
                                <div className={styles.listContent}>
                                    <div className={styles.bookmark}>
                                        <FavoriteStar
                                            color={"#FF3466"}
                                            isActive={true} fontSize={20}
                                        />
                                        <p
                                            className={styles.bookmarkName}
                                            data-tooltip-content={x.name}
                                            data-tooltip-id={x.id}
                                        >
                                            {x.name}
                                        </p>
                                        <Tooltip className={styles.tooltip} id={x.id}/>
                                    </div>
                                    <div className={styles.controls}>
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                onEditBookmark(x)
                                            }}
                                            className={styles.actionBtn}
                                            type="button"
                                            data-testid="edit-bookmark-button">
                                            <img
                                                src={edit}
                                                alt={"edit"}
                                            />
                                        </button>
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setDeletingBookmark(x)
                                                setIsShowDeleteModal(true)
                                            }}
                                            className={styles.actionBtn}
                                            type="button"
                                            data-testid="delete-bookmark-button">
                                            <img
                                                src={trash}
                                                alt={"delete"}
                                            />
                                        </button>
                                    </div>
                                </div>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    );
};

export default BookmarkList;
