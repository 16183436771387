import React, {useEffect} from 'react';
import styles from './index.module.scss';
import {IOperatorPageProps} from "../RightsHoldersPortfolio/RightsHolderPortfolio";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import AppTableWhite from "../../../../components/AppTableWhite/AppTableWhite";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {RootState} from "../../../../store";
import {
    getPortfolio,
    getPortfolioCSV,
    ISportTableItem
} from "../../../../store/operatorPortfolio/operatorPortfolioSlice";
import DownloadLink from "react-download-link";
import downLoadLinkStyles from "./downLoadLinkStyles";
import Loader from "../../../../components/Loader/Loader";
import {useParams} from "react-router-dom";
import {useGetCartItems} from "../../../../hooks/useGetCartItems";


const OperatorPortfolio: React.FC<IOperatorPageProps> = React.memo(({menuStructure, users, operatorId}) => {
    const {
        isLoading,
        portfolio,
        portfolioCSV,
        portfolioCSVName
    } = useAppSelector((state: RootState) => state.operatorPortfolio);
    const dispatch = useAppDispatch();
    const {id} = useParams();
    useGetCartItems(id);


    useEffect(() => {
        dispatch(getPortfolio(operatorId));
        dispatch(getPortfolioCSV(operatorId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])


    const getPropertyBorder = (propertyIndex: number, sportIndex: number, sportPropertiesLength: number, providerSportsLength: number): string => {
        if (propertyIndex === sportPropertiesLength - 1 && sportIndex === providerSportsLength - 1) {
            return styles.providerRowEnd
        } else {
            return ''
        }
    }

    const getSportsRowSpan = (sports: ISportTableItem[]): number => {
        return sports.reduce(
            (sum, s) => sum + s.properties.length, 0)
    }

    return (
        <>
            <PageHeader
                menuStructure={menuStructure}
                users={users}
                title={"Operator Portfolio"}
            >
                <DownloadLink
                    label={<p className={styles.csvBtn}>Export as CSV</p>}
                    filename={portfolioCSVName}
                    exportFile={() => portfolioCSV}
                    style={downLoadLinkStyles}
                />
            </PageHeader>

            <div className={styles.pageInner}>
                {isLoading && <Loader/>}
                {!isLoading &&
                    <div className={styles.pageContainer}>
                        <h2 className={styles.headerTitle}>Operator Portfolio</h2>
                        <div className={styles.tableWrapperInner}>
                            <div className={styles.tableWrapper}>
                                <AppTableWhite>
                                    <thead>
                                    <tr>
                                        <th data-testid={'provider-header'}>Provider</th>
                                        <th data-testid={'sport-header'}>Sport</th>
                                        <th data-testid={'property-header'}>Property</th>
                                        <th data-testid={'av-fixtures-header'}>Available Fixtures</th>
                                        <th data-testid={'pur-fixtures-header'}>Purchased Fixtures</th>
                                        <th data-testid={'rem-fixtures-header'}>Remaining Fixtures</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {portfolio.map((provider, index) => (
                                        <React.Fragment key={index}>
                                            {provider.sports.map((sport, sportIndex) => (
                                                <React.Fragment key={sportIndex}>
                                                    {sport.properties.map((property, propertyIndex) => (
                                                        <React.Fragment key={propertyIndex}>
                                                            <tr className={
                                                                getPropertyBorder(propertyIndex, sportIndex, sport.properties.length, provider.sports.length)
                                                            }>
                                                                {sportIndex === 0 && propertyIndex === 0 &&
                                                                    <td rowSpan={getSportsRowSpan(provider.sports)}>
                                                                        {provider.provider}
                                                                    </td>
                                                                }

                                                                {propertyIndex === 0 &&
                                                                    <td rowSpan={sport.properties.length}>
                                                                        {sport.sport}
                                                                    </td>
                                                                }
                                                                <td>{property.property}</td>
                                                                <td>{property.availableFixtures}</td>
                                                                <td>{property.purchasedFixtures}</td>
                                                                <td>{property.remainingFixtures}</td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ))}
                                                </React.Fragment>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                    </tbody>
                                </AppTableWhite>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
});

export default OperatorPortfolio;
