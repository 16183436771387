import React from 'react';
import "./index.css"

interface ICheckboxWithLabelProps extends React.InputHTMLAttributes<HTMLInputElement> {
    labelText: string;
}

const CheckboxWithLabel: React.FC<ICheckboxWithLabelProps> = (props) => {
    return (
        <div className="form-group">
            <input
                readOnly
                {...props}
                type="checkbox"
                id={props.id}
            />
            <label htmlFor={props.id}>{props.labelText}</label>
        </div>
    );
};

export default CheckboxWithLabel;
