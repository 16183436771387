import React, {useState} from "react";
import {
    homeAwayNationsCode,
    IGeoFilteredCountries,
    IGeoFilterValues
} from "../OperatorGeoRestrictions/OperatorGeoRestrictions";
import {useAppDispatch, useAppSelector} from "@hooks/hooks";
import {SingleValue} from "react-select";
import {
    clearBoard, confirmGeoRestrictions,
    getCountriesByFilter, getGeoInitOptions, getOperatorOptions, getOtherRestrictions,
    getRightsOptions, getSeparateInitOptions, IConfirmGeoPayload, ITerritory
} from "@store/geoRestrictions/geoRestrictionsSlice";
import {toastr} from "react-redux-toastr";


export const useFiltersData = (rightHolderId: string | null) => {
    const [operator, setOperator] = useState<SingleValue<{ value: string, label: string } | undefined>>(undefined);
    const [right, setRight] = useState<SingleValue<{ value: string, label: string } | undefined>>(undefined);
    const [isShowConfirmBtn, setIsShowConfirmBtn] = useState(false);
    const [unconfirmedCountries, setUnconfirmedCountries] = useState<ITerritory[]>([]);

    const [filteredTerritories, setFilteredTerritories] = useState<IGeoFilteredCountries>({
        allTerr: [],
        blockedTerr: [],
        allowedTerr: []
    });

    const [filtersValues, setFiltersValues] = useState<IGeoFilterValues>({
        allTerr: "",
        blockedTerr: "",
        allowedTerr: ""
    });

    const {allowedTerritories, blockedTerritories, allTerritories} = useAppSelector(state => state.geoRestrictions);
    const dispatch = useAppDispatch()


    const setFilterValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFiltersValues({...filtersValues, [e.target.name]: e.target.value})

        if (e.target.name === "allTerr") {
            const territories = allTerritories.filter(territory => territory.name.toLowerCase().includes(e.target.value.toLowerCase()))
            setFilteredTerritories({...filteredTerritories, allTerr: territories})
        }
        if (e.target.name === "allowedTerr") {
            const territories = allowedTerritories.filter(territory => territory.name.toLowerCase().includes(e.target.value.toLowerCase()))
            setFilteredTerritories({...filteredTerritories, allowedTerr: territories})
        }
        if (e.target.name === "blockedTerr") {
            const territories = blockedTerritories.filter(territory => territory.name.toLowerCase().includes(e.target.value.toLowerCase()))
            setFilteredTerritories({...filteredTerritories, blockedTerr: territories})
        }
    }

    const onClearSearchInput = (e: React.MouseEvent<HTMLButtonElement>) => {
        const target = e.currentTarget;
        setFiltersValues({...filtersValues, [target.name]: ""})
        setFilteredTerritories({...filteredTerritories, [target.name]: []});
    }

    const onChangeOperator = (option: SingleValue<{ value: string, label: string }>, isAllowedCounties: boolean) => {
        if (isShowConfirmBtn && (!option || option.value.toLowerCase() !== operator?.value.toLowerCase())) {
            return toastr.warning("IGame", "You neither confirmed nor cancelled previous operator selection. In order to change a value, please perform an action first.");
        } else {
            setOperator(option);

            if (!isShowConfirmBtn) {
                if (!option && !right) {
                    dispatch(getGeoInitOptions(rightHolderId));
                    return dispatch(clearBoard());
                }
                if (!option && right) {
                    dispatch(getOperatorOptions({competitionId: right.value, rightHolderId}));
                    dispatch(getSeparateInitOptions({rightHolderId, selector: "right"}));

                    return dispatch(getCountriesByFilter({
                        payload: {
                            operatorId: null,
                            competitionId: right.value,
                            rightHolderId
                        },
                        isAllowedCounties
                    }));
                }
                dispatch(getRightsOptions({operatorId: option!.value, rightHolderId}));

                return dispatch(getCountriesByFilter({
                    payload: {
                        operatorId: option!.value,
                        competitionId: right ? right.value : null,
                        rightHolderId
                    },
                    isAllowedCounties
                }));
            }
        }
    };


    const onChangeRight = (option: SingleValue<{ value: string, label: string }>, isAllowedCounties: boolean) => {
        if (isShowConfirmBtn && (!option || option.value.toLowerCase() !== right!.value.toLowerCase())) {
            return toastr.warning("IGame", "You neither confirmed nor cancelled previous right selection. In order to change a value perform an action please first.");
        } else {
            setRight(option)

            if (!isShowConfirmBtn) {
                if (!option && !operator) {
                    dispatch(getGeoInitOptions(rightHolderId));
                    return dispatch(clearBoard());
                }
                if (!option && operator) {
                    dispatch(getRightsOptions({operatorId: operator.value, rightHolderId}));
                    dispatch(getSeparateInitOptions({rightHolderId, selector: "operator"}));

                    return dispatch(getCountriesByFilter({
                        payload: {
                            operatorId: operator.value,
                            competitionId: null,
                            rightHolderId
                        },
                        isAllowedCounties
                    }))
                }
                dispatch(getOperatorOptions({competitionId: option!.value, rightHolderId}));
                dispatch(getOtherRestrictions({
                    rightId: option!.value,
                    rightHolderId
                }))

                return dispatch(getCountriesByFilter({
                    payload: {
                        operatorId: operator ? operator.value : null,
                        competitionId: option ? option.value : null,
                        rightHolderId
                    },
                    isAllowedCounties
                }))
            }
        }
    }

    const onConfirmGeoRestrictions = () => {
        const filteredAllowedTerr = allowedTerritories?.filter(country => country.code !== homeAwayNationsCode);
        const allowedTerr = filteredAllowedTerr.map(country => country.code);

        const filteredBlockedTerr = blockedTerritories?.filter(country => country.code !== homeAwayNationsCode);
        const blockedTerr = filteredBlockedTerr?.map(country => country.code);

        const isHomeAwayNationsGeoBlocked = allowedTerritories.some(country => country.code === homeAwayNationsCode) ||
            blockedTerritories.some(country => country.code === homeAwayNationsCode)

        if (rightHolderId) {
            const payload: IConfirmGeoPayload = {
                rightHolderId,
                rightId: right ? right.value : "",
                operatorId: operator ? operator.value : null,
                isHomeAwayNationsGeoBlocked,
                allowedCountries: allowedTerr ? allowedTerr : [],
                blockedCountries: blockedTerr ? blockedTerr : [],
            }
            setIsShowConfirmBtn(false)
            dispatch(confirmGeoRestrictions(payload));
            setUnconfirmedCountries([]);
        } else {
            const payload: IConfirmGeoPayload = {
                rightId: right ? right.value : "",
                operatorId: operator ? operator.value : null,
                isHomeAwayNationsGeoBlocked,
                allowedCountries: allowedTerr ? allowedTerr : [],
                blockedCountries: blockedTerr ? blockedTerr : [],
            }
            setIsShowConfirmBtn(false)
            dispatch(confirmGeoRestrictions(payload));
            setUnconfirmedCountries([]);
        }
    }

    const onCancelChanges = (isAllowedCounties: boolean) => {
        dispatch(getCountriesByFilter(
            {
                payload: {
                    operatorId: operator ? operator.value : null,
                    competitionId: right ? right.value : null,
                    rightHolderId
                },
                isAllowedCounties
            }))
        setIsShowConfirmBtn(false);
        setUnconfirmedCountries([]);
    }

    return {
        filtersValues,
        setFiltersValues,
        setFilterValue,
        filteredTerritories,
        setFilteredTerritories,
        onClearSearchInput,
        onConfirmGeoRestrictions,
        onCancelChanges,
        operator,
        onChangeOperator,
        right,
        onChangeRight,
        isShowConfirmBtn,
        setIsShowConfirmBtn,
        unconfirmedCountries,
        setUnconfirmedCountries
    }
}
