import {AnyAction, createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {toastr} from "react-redux-toastr";
import {client} from "../../services/api-servise";
import {AxiosError} from "axios";

export interface IBandwidth {
    operator: string,
    overage: number,
    total: number
}

export interface IOperator {
    name: string,
    dateAdded: string,
    total: number
}

export interface IIngest {
    right: string,
    hours: number,
    total: number
}

export interface IStream {
    operator: string,
    playersView: number,
    total: number
}

export interface IBillingResponse {
    bandwidthOverage: number,
    fixturesCount: number,
    playersView: number,
    ingestHours: number,
    activeOperators: number,
    userName: string,
    streams: IStream[],
    ingests: IIngest[],
    operators: IOperator[],
    bandwidths: IBandwidth[]
    logo: null | string,
    isBandwidthEnabled: boolean,
    isIngestEnabled: boolean,
    isPlayerUsageEnabled: boolean,
    isOperatorEnabled: boolean,
}

export interface IBillingReqBody {
    startDate: string,
    endDate: string
}

export interface RHBillingTotalData {
    bandwidthOverage: number,
    fixturesCount: number,
    playersView: number,
    ingestHours: number,
    activeOperators: number,
    userName?: string,
    logo: string | null
}

export interface IGameState {
    totalData: RHBillingTotalData,
    streams: IStream[],
    ingests: IIngest[],
    operators: IOperator[],
    bandwidths: IBandwidth[],
    error: string,
    IGameCSV: string,
    csvFileName: string
    loading: boolean
}

const initialState: IGameState = {
    totalData: {
        bandwidthOverage: 0,
        fixturesCount: 0,
        playersView: 0,
        ingestHours: 0,
        activeOperators: 0,
        logo: null
    },
    streams: [],
    ingests: [],
    operators: [],
    bandwidths: [],
    error: '',
    IGameCSV: '',
    csvFileName: "",
    loading: false
}


export const getIGameData = createAsyncThunk<IBillingResponse, IBillingReqBody, { rejectValue: string }>(
    'iGame/getIGameData',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.post(`api/Billing/igame`, body)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)


export const getIGameDataCSV = createAsyncThunk<string, IBillingReqBody, { rejectValue: string }>(
    'iGame/getIGameDataCSV',
    async (body, {rejectWithValue, dispatch}) => {
        try {
            const {data, headers} = await client.post(`api/Billing/igame/download`, body)
            if (headers['content-disposition']) {
                const contentDisposition = headers['content-disposition'];
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(contentDisposition);
                const filename = matches ? matches[1].replace(/['"]/g, '') : null;
                if (filename) {
                    dispatch(setCSVFileName(filename))
                }
            }
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)


export const RHIGameBillingSlice = createSlice({
    name: 'RHIGameBilling',
    reducers: {
        setCSVFileName: (state, action: PayloadAction<string>) => {
            state.csvFileName = action.payload
        }
    },
    initialState,

    extraReducers: (builder) => {
        builder
            .addCase(getIGameData.pending, (state) => {
                state.loading = true;
            })
            .addCase(getIGameData.fulfilled, (state, action) => {
                state.loading = false;
                state.totalData.bandwidthOverage = action.payload.bandwidthOverage;
                state.totalData.fixturesCount = action.payload.fixturesCount;
                state.totalData.playersView = action.payload.playersView;
                state.totalData.ingestHours = action.payload.ingestHours;
                state.totalData.activeOperators = action.payload.activeOperators;
                state.totalData.logo = action.payload.logo

                state.streams = action.payload.streams;
                state.ingests = action.payload.ingests;
                state.operators = action.payload.operators;
                state.bandwidths = action.payload.bandwidths;
            })
            .addCase(getIGameDataCSV.fulfilled, (state, action) => {
                state.IGameCSV = action.payload;
            })
            .addMatcher(isError, (state, action: PayloadAction<string>) => {
                state.error = action.payload;
            })
    }
})
export const {setCSVFileName} = RHIGameBillingSlice.actions
export default RHIGameBillingSlice.reducer


function isError(action: AnyAction) {
    return action.type.endsWith("rejected");
}
