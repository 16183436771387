import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import styles from "./index.module.scss"
import {IFormTab} from "../AddFixtureForm/AddFixtureForm";
import FormTabs from "../AddFixtureForm/components/FormTabs/FormTabs";
import {client} from "../../../../../../../services/api-servise";
import {toastr} from "react-redux-toastr";

interface IUploadSCVFormProps {
    setTabsState: Dispatch<SetStateAction<IFormTab>>;
    tabsState: IFormTab;
    rightHolderId: string | null;
    handleFormClose: () => void;
    getSortedItems: (() => void) | undefined;
}

const UploadSCVForm: React.FC<IUploadSCVFormProps> = (
    {
        tabsState,
        setTabsState, rightHolderId,
        handleFormClose,
        getSortedItems
    }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    useEffect(() => {
        if (selectedFile) handleUpload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFile]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            if (file.name.endsWith('.csv')) {
                setSelectedFile(file);
            } else {
                setSelectedFile(null);
            }
        }
    };

    const downloadTemplate = async () => {
        try {
            const response = await client.get(`api/Fixtures/right-holder/csv-template?rightHolderId=${rightHolderId}`)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = 'Template.csv';
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url)

        } catch (e) {
            console.log(e)
        }
    }

    const downloadCompetition = async () => {
        try {
            const response = await client.get(`api/Fixtures/right-holder/csv-competitions?rightHolderId=${rightHolderId}`)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = 'Competition.csv';
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url)

        } catch (e) {
            console.log(e)
        }
    }

    const handleUpload = async () => {
        if (!selectedFile) {
            return;
        }

        const formData = new FormData();
        formData.append('csvFile', selectedFile)

        try {
            const {
                data,
                status
            } = await client.post(`api/Fixtures/right-holder/csv?rightHolderId=${rightHolderId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                responseType: 'text'

            });
            if (status === 200) {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'ErrorDescription.csv')
                document.body.appendChild(link);
                link.click();
                if (link.parentNode) {
                    link.parentNode.removeChild(link);
                }
                window.URL.revokeObjectURL(url);
                return toastr.error("IGame", "Wrong column headers in file or extra properties in rows")
            }
            if (getSortedItems) {
                getSortedItems()
            }
            handleFormClose();
            toastr.success("IGame", "File has been uploaded");
            setSelectedFile(null);
        } catch (error) {
            // @ts-ignore
            toastr.error("IGame", error.response.data)
        }
    };

    return (
        <div className={styles.SCVForm}>
            <FormTabs setTabsState={setTabsState} tabsState={tabsState}/>
            <div className={styles.content}>
                <div className={styles.btnWrapper}>
                    <button
                        onClick={() => downloadTemplate()}
                        className={styles.btn}>Download Sample Template
                    </button>
                    <button
                        onClick={() => downloadCompetition()}
                        className={styles.btn}>Download Competition Template
                    </button>
                </div>
            </div>

            <div className={styles.buttons}>
                <button onClick={handleFormClose} className={styles.cancelButton}>Cancel</button>
                <button
                    className={styles.submitButton}
                    type="button">
                    <input
                        id="file-upload"
                        type="file"
                        accept=".csv"
                        onChange={handleFileChange}
                        style={{display: 'none'}}
                    />
                    <label className={styles.uploadLabel} htmlFor="file-upload">Upload</label>
                </button>
            </div>
        </div>
    );
};

export default UploadSCVForm;
