import {useDispatch, useSelector} from 'react-redux'
import type {TypedUseSelectorHook} from 'react-redux'
import type {RootState, AppDispatch} from '../store'
import {useState} from "react";
import {useDebounce} from "../helpers/useDebounce";
import {AsyncThunkAction} from "@reduxjs/toolkit";

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector


export const useSearchUsers = (thunk: (searchValue: string) => AsyncThunkAction<{}, string | null, any>) => {
    const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
    const dispatch = useAppDispatch();

    const onSearch = (searchValue: string) => {
        setSearchValue(searchValue)
    }

    const searchRequest = () => {
        if (searchValue !== undefined) {
            dispatch(thunk(encodeURIComponent(searchValue)));
        }
    }

    useDebounce(searchRequest, [searchValue], 500);

    return {searchValue, setSearchValue, onSearch}
}
