import React from 'react';
import PageHeader from "../../../../../../components/PageHeader/PageHeader";
import DownloadBlock from "../../../../../../components/DownloadBlock/DownloadBlock";
import styles from "./index.module.scss";
import {IMenuStructure} from "@pages/Cms/AppRoutes/routes/RouteInterface";
import {IOperatorBillingTotalData} from "@store/iGameOperatorBilling/iGameOperatorBillingSlice";


export interface IBillingHeaderProps {
    menuStructure: IMenuStructure;
    pageTitle: string;
    headerTitle: string;
    totalDataTitles: string[];
    totalData: IOperatorBillingTotalData;
    cvsFile: string;
    csvFileName: string
    pdfHandler: () => void;
}

const DescriptionHeader: React.FC<IBillingHeaderProps> = React.memo(({
                                                                         menuStructure,
                                                                         pageTitle,
                                                                         headerTitle,
                                                                         totalData,
                                                                         totalDataTitles,
                                                                         cvsFile,
                                                                         csvFileName,
                                                                         pdfHandler
                                                                     }) => {

    const totalDataValues: number[] = Object.values(totalData);

    return (
        <>
            <PageHeader menuStructure={menuStructure} title={headerTitle}>
                <DownloadBlock
                    fileName={csvFileName}
                    csvFile={cvsFile}
                    pdfHandler={pdfHandler}
                />
            </PageHeader>

            <div className={styles.headerContainer}>
                <div className={styles.pageContainer}>

                    <div className={styles.subheader}>
                        <h1 className={styles.headerTitle}>{pageTitle}</h1>

                        <div className={styles.totalData}>
                            <div className={styles.totalDataTitles}>
                                {totalDataTitles.map((item, index) => <p key={index}>{item}</p>)}
                            </div>

                            <div className={styles.totalDataValues}>
                                {totalDataValues.map((item, index) => <p key={index}>{item}</p>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

export default DescriptionHeader;
