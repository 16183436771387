import React, {useEffect, useRef} from "react";
import {Chart, DotParams, Dots, Handlers, Layer, Lines, Point, Ticks} from "rumble-charts";
import ChartLabel from "../../../ChartLabel/ChartLabel";
import styles from "./index.module.scss";
import {IDropdownOptions} from "../../types";
import NoDataFoundStub from "../../../NoDataFoundStub/NoDataFoundStub";
import {IEvent} from "../DonutChart/DonutChart";
import {formatNumberWithCommas} from "../../helpers/formatNumberWithCommas";

export interface IMainChartProps {
    chartData: any;
    fullWidthMainChart: boolean;
    colors: string[];
    trendLinesVisible: boolean;
    loading: boolean;
    dropdownOptions: IDropdownOptions;
    rectRef: React.RefObject<HTMLDivElement>;
    handleMouseDown: (e: React.MouseEvent<HTMLDivElement>) => void;
    isLabelVisible: boolean;
    labelData: any;
    setIsLabelVisible: (value: boolean) => void;
    setLabelData: (data: any) => void;
    setIsDragging: (val: boolean) => void;
    isDragging: boolean;
    getOverlayStyle: () => React.CSSProperties;
    windowWidth: number;
    setWindowWidth: (val: number) => void;
    handleMouseUp: (e: React.MouseEvent<HTMLDivElement>) => void;
    handleMouseMove: (e: React.MouseEvent<HTMLDivElement>) => void;
    handleDotHover: (data: Point) => void;
}

const MainChart: React.FunctionComponent<IMainChartProps> = ({
                                                                 chartData,
                                                                 fullWidthMainChart,
                                                                 colors,
                                                                 trendLinesVisible,
                                                                 dropdownOptions,
                                                                 rectRef,
                                                                 handleMouseDown,
                                                                 isLabelVisible,
                                                                 labelData,
                                                                 setIsLabelVisible,
                                                                 setLabelData,
                                                                 isDragging,
                                                                 getOverlayStyle,
                                                                 windowWidth,
                                                                 setWindowWidth,
                                                                 handleMouseUp,
                                                                 handleMouseMove,
                                                                 handleDotHover
                                                             }) => {
    const labelRef = useRef<HTMLDivElement>(null);
    const hideTimeout = useRef<ReturnType<typeof setTimeout>>();

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            clearTimeout(hideTimeout.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setLabelPosition = (e: IEvent, mouseMove: boolean) => {
        const chartElement = rectRef.current;
        if (!chartElement) {
            return;
        }

        const chartRect = chartElement.getBoundingClientRect();

        const xCoord = e.clientX - chartRect.left;
        const yCoord = e.clientY - chartRect.top;

        if (mouseMove && labelRef.current !== null) {
            labelRef.current.classList.add(`${styles.mainChartLabelActive}`);
            labelRef.current.style.left = xCoord + "px";
            labelRef.current.style.top = yCoord + "px";
        } else {
            if (labelRef.current) {
                labelRef.current.classList.remove(`${styles.mainChartLabelActive}`);
            }
        }
    };

    const setColoursForTrendLines = () => {
        const usedColors = colors.slice(0, chartData.length);
        const colorsCut = usedColors.slice(0, chartData.length / 2);
        return [...colorsCut, ...colorsCut];
    };

    const checkTicksVisibility = (coordinatesNumber: number, index: number): string => {
        if (chartData[0].data[index]) {
            if (coordinatesNumber > 20 && coordinatesNumber <= 40 && !(index % 2 === 0)) {
                return "";
            }
            if (coordinatesNumber > 40 && coordinatesNumber <= 100 && !(index % 5 === 0)) {
                return "";
            }
            if (coordinatesNumber > 100 && coordinatesNumber <= 200 && !(index % 10 === 0)) {
                return "";
            }
            if (coordinatesNumber > 200 && coordinatesNumber <= 500 && !(index % 20 === 0)) {
                return "";
            }
            if (coordinatesNumber > 500 && !(index % 50 === 0)) {
                return "";
            }
            return chartData[0].data[index].name;
        } else {
            return "";
        }
    };

    const calculateChartWidth = (): number => {
        if (dropdownOptions.mainChartTypes.length <= 1) {
            return fullWidthMainChart ? windowWidth - 250 : windowWidth - 630;
        } else {
            return fullWidthMainChart ? windowWidth - 450 : windowWidth - 790;
        }
    };

    const calculateLayerWidth = (): number => {
        if (dropdownOptions.mainChartTypes.length <= 1) {
            return fullWidthMainChart ? windowWidth - 400 : windowWidth - 780;
        } else {
            return fullWidthMainChart ? windowWidth - 600 : windowWidth - 940;
        }
    };

    return chartData && chartData.length !== 0 ? (
        <div
            ref={rectRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
        >
            <div ref={labelRef} className={styles.mainChartLabel}>
                {isLabelVisible && labelData ? (
                    <ChartLabel
                        labelData={labelData}
                        colors={colors}
                        forMainChart={true}
                    />
                ) : null}
            </div>
            <Chart
                height={420}
                minY={0}
                scaleX={{
                    paddingEnd: 0,
                    paddingStart: 0,
                }}
                scaleY={{
                    paddingTop: 10,
                }}
                series={chartData}
                style={{
                    fontFamily: "sans-serif Poppins-Regular",
                    fontSize: "0.85em",
                }}
                width={calculateChartWidth()}
            >
                <Layer
                    height="80%"
                    position="top center"
                    width={calculateLayerWidth()}
                >
                    <Ticks
                        axis="y"
                        labelAttributes={{
                            x: -15,
                        }}
                        labelStyle={{
                            dominantBaseline: "middle",
                            fill: "#FFFFFF",
                            textAnchor: "end",
                            fontSize: "12px",
                        }}
                        lineLength="100%"
                        lineStyle={{
                            stroke: "lightgray",
                        }}
                        lineVisible
                        label={(label) => label.tick.y && formatNumberWithCommas(label.tick.y)}
                    />
                    <Ticks
                        axis="x"
                        label={({index}) => {
                            return checkTicksVisibility(chartData[0].data.length, index);
                        }}
                        labelAttributes={(tick) => ({
                            y: 10,
                            transform:
                                chartData[0].data.length > 12 ||
                                (chartData[0].data.length > 5 &&
                                    chartData[0].data.length <= 12 &&
                                    chartData[0].data[tick.index].name.length > 12)
                                    ? "rotate(-25)"
                                    : undefined,
                        })}
                        ticks={{minDistance: 1, distance: 1}}
                        labelStyle={(tick) => ({
                            dominantBaseline: "text-before-edge",
                            fill: "#FFFFFF",
                            textAnchor:
                                chartData[0].data.length > 12 ||
                                (chartData[0].data.length > 5 &&
                                    chartData[0].data.length <= 12 &&
                                    chartData[0].data[tick.index].name.length > 12)
                                    ? "end"
                                    : "middle",
                            fontSize: `${
                                chartData[0].data.length > 50 || windowWidth < 1600
                                    ? "10px"
                                    : "12px"
                            }`,
                            fontWeight: "700",
                        })}
                    />
                    <Handlers>
                        <Lines
                            colors={trendLinesVisible ? setColoursForTrendLines() : colors}
                            seriesStyle={(series: any) => {
                                if (
                                    trendLinesVisible &&
                                    (series.seriesIndex > chartData.length / 2 ||
                                        series.seriesIndex === chartData.length / 2)
                                ) {
                                    return {strokeDasharray: "3, 3"};
                                }
                            }}
                        />
                        <Dots
                            dotType={["circle"]}
                            className="dots"
                            dotStyle={() => {
                                return {
                                    transition: "all 200ms",
                                };
                            }}
                            dotAttributes={(data: DotParams) => ({
                                onMouseOver: (e: IEvent) => {
                                    return (e.target.style.scale = 1.5);
                                },
                                onMouseMove: (e: IEvent) => {
                                    setIsLabelVisible(true);
                                    handleDotHover(data.point);
                                    setLabelPosition(e, true);
                                },
                                onMouseLeave: (e: IEvent) => {
                                    hideTimeout.current = setTimeout(() => {
                                        if (labelRef.current) {
                                            setIsLabelVisible(false);
                                            setLabelData(null);
                                            setLabelPosition(e, false);
                                        }
                                    }, 100);
                                    return (e.target.style.scale = 1);
                                },
                                y: 5,
                            })}
                        />
                    </Handlers>
                </Layer>
            </Chart>
            {isDragging && <div style={getOverlayStyle()}/>}
        </div>
    ) : (
        <div
            className={styles.noDataFoundStubWrapper}
        >
            <NoDataFoundStub textColor={"#FFFFFF"} message={"Please select more properties in filter"}/>
        </div>
    );
};
MainChart.displayName = "MainChart";
export default MainChart;

