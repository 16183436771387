import {ISidebarItem} from "../Sidebar";

interface ICurrentMenuItem {
    menuItem: any,
    parentItem: ISidebarItem | null,
    grandparentItem: ISidebarItem | null,
    rangeLevel: number
}

export const findItemByPath = (
    menuArr: ISidebarItem[],
    currentPath: string,
    parentItem: ISidebarItem | null = null,
    grandparentItem: ISidebarItem | null = null,
    rangeLevel: number = 1): null | ICurrentMenuItem => {

    for (const menuItem of menuArr) {
        if (menuItem.path === currentPath) {
            return {menuItem, parentItem, grandparentItem, rangeLevel};
        }

        if (menuItem.children) {
            const foundMenuItem = findItemByPath(menuItem.children, currentPath, menuItem, parentItem, rangeLevel + 1);
            if (foundMenuItem) {
                return foundMenuItem;
            }
        }
    }
    return null;
};
