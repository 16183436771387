import {IIssuesReportPayload, IIssuesState} from "../../../../../../store/reportingIssuesSlice/reportingIssuesSlice";
import {formatDate} from "../../../../../../helpers/dateFormatters";

export const createPayload = (reportingIssuesStore: IIssuesState, searchValue: string): IIssuesReportPayload => {
    const {pageNumber, itemsPerPage, filters} = reportingIssuesStore;
    return {
        payload: {
            from: formatDate(filters.startDate),
            to: formatDate(filters.endDate),
            searchValue,
            itemsPerPage,
            pageNumber,
        },
        isScroll: false
    }
}