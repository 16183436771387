import React, {useState} from 'react';
import styles from "./index.module.scss";
import {FaChevronDown} from "react-icons/fa";
import uuid from "react-uuid";
import {IBillingOperator} from "../../../../../../../store/rightsHolderBilling/rightHolderBillingOperatorSlice";


export interface IBillingAccordionProps {
    billingData: IBillingOperator;
    isExpandedAccordion: boolean;
}

const BillingAccordion: React.FC<IBillingAccordionProps> = ({billingData, isExpandedAccordion}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const {
        operator,
        matchPrice,
        adHocPrice,
        numberOfFixtures,
        numberOfAdHocBookings,
        total,
        rightHolderRightBillingItems
    } = billingData;

    return (
        <React.Fragment key={uuid()}>
            <tr onClick={() => setIsExpanded(!isExpanded)}>
                <td className={styles.accordionCell}>
                    <div
                        className={!isExpanded ? styles.tableChevron : `${styles.tableChevron} ${styles.tableChevronTop}`}
                        data-testid="chevron-icon">
                        <FaChevronDown size={14}/>
                    </div>
                    <p className={styles.operatorCell}>{operator}</p>
                </td>
                <td></td>

                <td>{matchPrice}</td>
                <td>{adHocPrice}</td>
                <td>{numberOfFixtures}</td>
                <td>{numberOfAdHocBookings}</td>
                <td>{total}</td>
            </tr>

            {isExpanded || isExpandedAccordion ?
                <tr className={styles.expandedRow}>
                    <td></td>
                    <td>
                        {rightHolderRightBillingItems.map(item => (
                            <p key={uuid()}>{item.right}</p>
                        ))}
                    </td>
                    <td>
                        {rightHolderRightBillingItems.map(item => (
                            <p key={uuid()}>{item.matchPrice}</p>
                        ))}
                    </td>

                    <td>
                        {rightHolderRightBillingItems.map(item => (
                            <p key={uuid()}>{item.adHocPrice}</p>
                        ))}
                    </td>

                    <td>
                        {rightHolderRightBillingItems.map(item => (
                            <p key={uuid()}>{item.numberOfFixtures}</p>
                        ))}
                    </td>

                    <td>
                        {rightHolderRightBillingItems.map(item => (
                            <p key={uuid()}>{item.numberOfAdHocBookings}</p>
                        ))}
                    </td>

                    <td>
                        {rightHolderRightBillingItems.map(item => (
                            <p key={uuid()}>{item.total.toFixed(2)}</p>
                        ))}
                    </td>
                </tr>
                :
                null
            }
        </React.Fragment>
    );
};

export default BillingAccordion;
