import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IInputOption, IOperatorInitResponse} from "../operatorAccess/operatorAccessSlice";
import {client} from "../../services/api-servise";
import {toastr} from "react-redux-toastr";
import {AxiosError} from "axios";
import {
    homeAwayNationsCode
} from "../../pages/Cms/RightsHolderView/GeoRestrictions/OperatorGeoRestrictions/OperatorGeoRestrictions";

interface IGetRestrictionsReq {
    rightId: string;
    rightHolderId?: string | null
}

interface IRestriction {
    id: string
    isFullScreenDisabled: boolean | undefined
}

export interface IGeoRestrictionsState {
    operators: IInputOption[],
    rights: IInputOption[],
    allTerritories: ITerritory[],
    allowedTerritories: ITerritory[],
    blockedTerritories: ITerritory[],
    restriction: IRestriction | undefined,
    restrictionsCSV: string | undefined,
    csvFileName: string;
    isLoading: boolean;
}

export interface ITerritory {
    id: string,
    name: string,
    code: string,
    flag: string | null
}

export interface ICountriesByFiltersResponse {
    geoAllowedCountries: ITerritory[],
    geoBlockedCountries: ITerritory[],
    isHomeAwayNationsGeoBlocked: boolean
}

export interface IGetCountriesByFilterBody {
    payload: {
        operatorId: string | null,
        rightHolderId: string | null,
        competitionId: string | null | undefined
    },
    isAllowedCounties?: boolean

}

export interface IAddToAllCountriesBody {
    territory: ITerritory,
    boardName: string
}

export interface IConfirmGeoPayload {
    rightHolderId?: string,
    operatorId: string | null,
    isHomeAwayNationsGeoBlocked: boolean,
    rightId: string,
    allowedCountries: string[],
    blockedCountries: string[]
}

export interface IOperatorSeparateInitResponse {
    rightHolderId: string | null,
    selector: "right" | "operator"
}

const initialState: IGeoRestrictionsState = {
    rights: [],
    operators: [],
    allTerritories: [],
    allowedTerritories: [],
    blockedTerritories: [],
    restriction: undefined,
    restrictionsCSV: undefined,
    csvFileName: "",
    isLoading: false
}

export const getGeoInitOptions = createAsyncThunk<IOperatorInitResponse, string | null, { rejectValue: string }>(
    'geo/get-init',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/RightsManagement/geo-restrictions/init${id ? `?RightHolderId=${id}` : ""}`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getSeparateInitOptions = createAsyncThunk<IOperatorInitResponse, IOperatorSeparateInitResponse, { rejectValue: string }>(
    'geo/get-init-separately',
    async (payload, {rejectWithValue}) => {
        try {
            const baseUrl = "api/RightsManagement/geo-restrictions/init";
            const queryParam = payload.rightHolderId ? `?RightHolderId=${payload.rightHolderId}` : "";
            const {data} = await client.get(`${baseUrl}${queryParam}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const confirmGeoRestrictions = createAsyncThunk<any, IConfirmGeoPayload, { rejectValue: string }>(
    'geo/confirm-geo-restrictions',
    async (body, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.put("api/RightsManagement/geo-restrictions/confirm", body)
            dispatch(getRestrictionsCSV(body.rightHolderId ? body.rightHolderId : null))

            toastr.success('IGame', `Geo restrictions confirmed `)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }

    }
)

export const getRightsOptionsByOperator = createAsyncThunk<IOperatorInitResponse, string, { rejectValue: string }>(
    'geo/get-rights-by-operator',
    async (operatorId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/RightsManagement/operator-access/init?operatorId=${operatorId}`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getAllTerritories = createAsyncThunk<ITerritory[], boolean | undefined, { rejectValue: string }>(
    'geo/get-all-territories',
    async (isAllowedCounties, {rejectWithValue}) => {
        try {
            const {data} = await client.get("api/Countries")
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getCountriesByFilter = createAsyncThunk<ICountriesByFiltersResponse, IGetCountriesByFilterBody, {
    rejectValue: string
}>(
    'geo/get-allowed-territories',
    async ({payload, isAllowedCounties}, {rejectWithValue, dispatch}) => {
        try {
            dispatch(getAllTerritories(isAllowedCounties))
            if (payload.operatorId) {
                const {data} = await client.get(`api/RightsManagement/geo-restrictions?${payload.operatorId ? `OperatorId=${payload.operatorId}` : ""}${payload.competitionId ? `&CompetitionId=${payload.competitionId}` : ""}${payload.rightHolderId ? `&RightHolderId=${payload.rightHolderId}` : ""}`)
                return data
            } else {
                const {data} = await client.get(`api/RightsManagement/geo-restrictions?${payload.competitionId ? `CompetitionId=${payload.competitionId}` : ""}${payload.rightHolderId ? `&RightHolderId=${payload.rightHolderId}` : ""}`)
                return data
            }

        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getOperatorOptions = createAsyncThunk<{ items: IInputOption[] }, {
    competitionId: string,
    rightHolderId: string | null
}, { rejectValue: string }>(
    'geo/get-operator-options',
    async ({competitionId, rightHolderId}, {rejectWithValue,}) => {
        try {
            const {data} = await client.get(`api/Operators/right/${competitionId}${rightHolderId ? `?rightHolderId=${rightHolderId}` : ""}`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getRightsOptions = createAsyncThunk<{ items: IInputOption[] }, {
    operatorId: string,
    rightHolderId: string | null
}, { rejectValue: string }>(
    'geo/get-rights-options',
    async ({operatorId, rightHolderId}, {rejectWithValue,}) => {
        try {
            const {data} = await client.get(`api/Competitions/operator/${operatorId}${rightHolderId ? `?rightHolderId=${rightHolderId}` : ""}`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getOtherRestrictions = createAsyncThunk<IRestriction, IGetRestrictionsReq, { rejectValue: string }>(
    'geo/other-restrictions',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/RightsManagement/right-holder/restrictions/${body.rightId}${body.rightHolderId ? '?RightHolderId=' + body.rightHolderId : ''}`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const updateOtherRestrictions = createAsyncThunk<IRestriction, IRestriction, {
    rejectValue: string
}>(
    'geo/update-other-restrictions',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`api/RightsManagement/right-holder/restrictions`, body)
            toastr.success('IGame', `Format Restrictions has been updated`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getRestrictionsCSV = createAsyncThunk<string, string | null, { rejectValue: string }>(
    'geo/get-restrictions-CSV',
    async (rightHolderId, {rejectWithValue, dispatch}) => {
        try {
            const {data, headers} = await client.get(
                `api/RightsManagement/right-holder/restrictions/report${rightHolderId ? '?RightHolderId=' + rightHolderId : ''}`)
            if (headers['content-disposition']) {
                const contentDisposition = headers['content-disposition'];
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(contentDisposition);
                if (matches) {
                    const filename = matches[1].replace(/['"]/g, '');
                    dispatch(setCSVFileName(filename))
                }
            }
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)


export const geoRestrictionSlice = createSlice({
    name: 'geo-restriction',
    reducers: {
        addAllowedCountry: (state, action: PayloadAction<ITerritory>) => {
            const sortedCountries = [action.payload, ...state.allowedTerritories];
            sortedCountries.sort((a, b) => a.name.localeCompare(b.name));

            state.allowedTerritories = sortedCountries
        },

        addAllCountries: (state, action: PayloadAction<{ countries: ITerritory[], boardName: string }>) => {
            if (action.payload.boardName === 'Blocked Countries') {
                state.blockedTerritories = action.payload.countries
            } else {
                state.allowedTerritories = action.payload.countries
            }
        },

        addToAllCountries: (state, action: PayloadAction<IAddToAllCountriesBody>) => {
            const sortedCountries = [action.payload.territory, ...state.allTerritories];
            sortedCountries.sort((a, b) => a.name.localeCompare(b.name));
            state.allTerritories = sortedCountries
        },

        deleteCountry: (state, action: PayloadAction<IAddToAllCountriesBody>) => {
            if (action.payload.boardName === "Blocked Countries") {
                state.blockedTerritories = state.blockedTerritories.filter(territory => territory.id !== action.payload.territory.id)
            }

            if (action.payload.boardName === "Allowed Countries") {
                state.allowedTerritories = state.allowedTerritories.filter(territory => territory.id !== action.payload.territory.id)
            }

            if (action.payload.boardName === "All Territories") {
                state.allTerritories = state.allTerritories.filter(territory => territory.id !== action.payload.territory.id)
            }

        },

        addBlockedCountry: (state, action: PayloadAction<ITerritory>) => {
            const sortedCountries = [action.payload, ...state.blockedTerritories];
            sortedCountries.sort((a, b) => a.name.localeCompare(b.name));
            state.blockedTerritories = sortedCountries;
        },

        setAllowedCountries: (state, action: PayloadAction<ITerritory[]>) => {
            state.allowedTerritories = action.payload
        },

        clearBoard: (state) => {
            state.allowedTerritories = []
            state.blockedTerritories = []
            state.allTerritories = []
        },

        setCSVFileName: (state, action: PayloadAction<string>) => {
            state.csvFileName = action.payload
        },
    },

    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getGeoInitOptions.fulfilled, (state, action) => {
                const operators = action.payload.operators
                const rights = action.payload.rights
                if (operators) {
                    state.operators = operators.items
                }
                if (rights) {
                    state.rights = rights.items
                }
            })
            .addCase(getSeparateInitOptions.fulfilled, (state, action) => {
                const operators = action.payload.operators
                const rights = action.payload.rights
                if (operators && action.meta.arg.selector === "operator") {
                    state.operators = operators.items
                }
                if (rights && action.meta.arg.selector === "right") {
                    state.rights = rights.items
                }
            })
            .addCase(getOperatorOptions.fulfilled, (state, action) => {
                state.operators = action.payload.items
            })

            .addCase(getRightsOptions.fulfilled, (state, action) => {
                state.rights = action.payload.items
            })

            .addCase(getRightsOptionsByOperator.fulfilled, (state, action) => {
                const rights = action.payload.rights.items
                if (rights) {
                    state.rights = rights
                }
            })

            .addCase(getAllTerritories.fulfilled, (state, action) => {
                if (action.meta?.arg) {
                    state.allTerritories = action.payload;
                } else {
                    const additionalTiles: ITerritory[] = [
                        {
                            code: 'all',
                            flag: null,
                            id: "all-Countries",
                            name: "All Countries"
                        },
                        {
                            code: homeAwayNationsCode,
                            flag: null,
                            id: "home-away-nations",
                            name: "Home/Away Nations"
                        }
                    ]

                    state.allTerritories = additionalTiles.concat(action.payload);
                }
            })
            .addCase(getCountriesByFilter.fulfilled, (state, action) => {
                if (action.payload.geoAllowedCountries) {
                    state.allowedTerritories = action.payload.geoAllowedCountries.sort((a, b) => a.name.localeCompare(b.name));
                } else {
                    state.allowedTerritories = [];
                }

                if (action.payload.geoBlockedCountries && action.payload.isHomeAwayNationsGeoBlocked) {
                    state.blockedTerritories = action.payload.geoBlockedCountries.sort((a, b) => a.name.localeCompare(b.name));
                    state.blockedTerritories.unshift({
                        code: homeAwayNationsCode,
                        flag: null,
                        id: "home-away-nations",
                        name: "Home/Away Nations"
                    })
                } else if (action.payload.geoBlockedCountries) {
                    state.blockedTerritories = action.payload.geoBlockedCountries.sort((a, b) => a.name.localeCompare(b.name));
                } else {
                    state.blockedTerritories = [];
                }
            })
            .addCase(getOtherRestrictions.fulfilled, (state, action) => {
                state.restriction = action.payload;
            })
            .addCase(getRestrictionsCSV.fulfilled, (state, action) => {
                state.restrictionsCSV = action.payload;
            })
    }
})
export const {
    addAllowedCountry,
    addAllCountries,
    addBlockedCountry,
    deleteCountry,
    clearBoard,
    setCSVFileName
} = geoRestrictionSlice.actions
export default geoRestrictionSlice.reducer
