import React, {useEffect, useState} from 'react';
import styles from "./index.module.scss"
import {useForm} from "react-hook-form";
import {Ingest, IngestFormController, IngestOptionId, IngestTabs} from "@enums/ingest";
import {
    assignContentSupplier,
    clearIngestDetails,
    createBtTowerIngest,
    createBtTowerIngestBackup,
    createIpIngest,
    createIPIngestBackup,
    createSatelliteIngest,
    createSatelliteIngestBackup,
    ICreateSatelliteIngestPayload,
    IFilesPayload,
    IIPBackupPayload,
    ISatelliteBackupBody,
    IUpdateBtTowerIngestPayload,
    IUpdateSatelliteIngestPayload,
    setIsActivateBackup,
    updateBtTowerIngest,
    updateIpIngest,
    updateSatelliteIngest
} from "@store/rightHolderBooking/rightHolderBookingSlice";
import {useAppDispatch, useAppSelector} from "@hooks/hooks";
import {RootState} from "@store/index";
import {
    IFormIngestTypeState,
    IIngestMethodProps,
    IIngestProps,
    IMethodPayloads,
    ISelectedMethods,
    IShowFormType,
    IUploadedFiles,
    SingleValueType
} from "./types/types";
import {BTTower} from "./children/BTTower/BTTower";
import {IPChannels} from "./children/IPChannels/IPChannels";
import SatelliteForm from "../SatelliteForm/SatelliteForm";
import ConfirmIPModal from "../../../UpcomingBookedFixtures/children/ConfirmIpModal/ConfirmIPModal";
import {toastr} from "react-redux-toastr";
import IngestDetails from "./helpers/IngestDetails";
import IngestPayload from "./helpers/IngestPayload";
import {IGameChannelInput, IGameChannels} from "./children/IGameChannels/IGameChannels";
import {getIpChannels, ISetIGameChannelBody, setIGameChannel} from "@store/init/initSlice";
import IngestMethodSelector from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/children/IngestForm/children/IngestMethodSelector/IngestMethodSelector";
import {ContentSuppliers} from "./children/ContentSuppliers/ContentSuppliers";
import IngestRender from "./helpers/IngestRender";
import IngestValidate from "./helpers/IngestValidate";
import IngestFormTabs
    from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/children/IngestForm/children/IngestFormTabs/IngestFormTabs";
import IngestFileUploader
    from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/children/IngestForm/children/IngestFileUploader/IngestFileUploader";
import {hasNestedObjectData} from "@helpers/hasNestedObjectData";
import {IIngestFormData} from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/BookFixtures";

export const ingestOptions = [
    {value: IngestOptionId.IP, label: Ingest.IP},
    {value: IngestOptionId.BTTower, label: Ingest.BTTower},
    {value: IngestOptionId.Satellite, label: Ingest.Satellite},
]

const IngestForm: React.FC<IIngestMethodProps> = (
    {
        setIngestFormData,
        ingestFormData,
        closeForm,
    }) => {

    const dispatch = useAppDispatch();
    const {register, formState: {errors}, control, setError, clearErrors, setValue, getValues, resetField} = useForm();
    const [currentTabState, setCurrentTabState] = useState<IngestTabs>(IngestTabs.Primary);
    const [showFormType, setShowFormType] = useState<IShowFormType>({showIngest: true, showSatellite: false});
    const [uploadedFiles, setUploadedFiles] = useState<IUploadedFiles>({primaryFiles: [], backUpFiles: []});
    const [selectedMethods, setSelectedMethods] = useState<ISelectedMethods>({primaryType: null, backupType: null});
    const [methodPayloads, setMethodPayloads] = useState<IMethodPayloads>({} as IMethodPayloads);
    const [ingestType, setIngestType] = useState<IFormIngestTypeState>({} as IFormIngestTypeState);

    const {
        details,
        backupDetails,
        isActivateBackup,
        ipUsage, isShowIngestMethod
    } = useAppSelector((state: RootState) => state.rightHolderBooking);
    const channels = useAppSelector((state: RootState) => state.initInfo.ipChannels);

    useEffect(() => {
        IngestDetails.setDefaultSelectedMethod(ingestFormData, setSelectedMethods)

        if (hasNestedObjectData(backupDetails?.ipFixture?.fixtureId)) {
            IngestDetails.setBackupIPChannels(backupDetails, channels, setValue)
            setIngestType({BTTower: false, satellite: false, IP: true})
        }
        if (ingestFormData.backupIngestType && !ingestFormData.type) {
            setCurrentTabState(IngestTabs.BackUP)
            dispatch(setIsActivateBackup(true))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backupDetails, dispatch, ingestFormData, channels]);

    useEffect(() => {
        IngestDetails.handleDetails({
            backupDetails,
            setMethodPayloads,
            setIngestType,
            details,
            setValue
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details, backupDetails])

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const isInvalidIngestType = IngestValidate.isInvalidIngestType(currentTabState, isActivateBackup, setError, clearErrors)

        if (isInvalidIngestType) {
            return
        }

        isActivateBackup ? handleBackupIngest({
                methodPayloads,
                ingestFormData
            })
            :
            handleIngest();
    };

    const onSetIGameChannel = (channel: { value: string; label: string }) => {
        const payload: ISetIGameChannelBody = IngestPayload.createIGameChannelPayload(ingestFormData, channel, isActivateBackup)
        dispatch(setIGameChannel(payload));
        closeForm();
    };

    const handleIngest = () => {
        const isInvalidContentSupplier = IngestValidate.detectInvalidContentSupplier({getValues, clearErrors, setError})
        const isInvalidPrimaryMethod = IngestValidate.detectInvalidPrimaryMethod({getValues, clearErrors, setError})

        if (isInvalidContentSupplier ?? isInvalidPrimaryMethod) {
            return
        }
        handleContentSuppler();

        if (ingestFormData.type === IngestOptionId.BTTower) {
            handleBtTowerIngest(ingestFormData)
        }
        if (ingestFormData.type === IngestOptionId.Satellite) {
            handleSatelliteIngest(ingestFormData)
        }
        if (ingestFormData.type === IngestOptionId.IP) {
            handleIPIngest()
        }
    }

    const handleContentSuppler = () => {
        const contentSupplier = getValues(IngestFormController.ContentSupplier)
        if (contentSupplier) {
            const reqBody = IngestPayload.createContentSupplier(ingestFormData, contentSupplier.value)
            dispatch(assignContentSupplier(reqBody))
        }
    };

    const handleBtTowerIngest = (ingestFormData: IIngestFormData) => {
        const isInvalidPrimaryBTTowerSource = IngestValidate.detectInvalidPrimaryBTTowerSource({
            getValues,
            clearErrors,
            setError
        })
        if (isInvalidPrimaryBTTowerSource) {
            return
        }
        const files: IFilesPayload | null = IngestPayload.uploadedFilesPayload(ingestFormData.fixture!.id, false, uploadedFiles)
        if (!ingestFormData.fixture?.ingestType) {
            return createBTTower(files);
        }
        updateBTTower(files)
    };

    const handleSatelliteIngest = (ingestFormData: IIngestFormData) => {
        if (!methodPayloads.Satellite) {
            return isActivateBackup ? toastr.error("Back-up Ingest", "Fill in satellite ingest details") :
                toastr.error("Primary Ingest", "Fill in satellite ingest details")
        }
        const files = IngestPayload.uploadedFilesPayload(ingestFormData.fixture!.id, false, uploadedFiles)
        if (!ingestFormData.fixture?.ingestType) {
            return createSatellite(files)
        }
        updateSatellite(files)
    };

    const handleIPIngest = () => {
        const isInvalidIPChannel = IngestValidate.detectInvalidPrimaryIPChannels({getValues, clearErrors, setError})
        if (isInvalidIPChannel) {
            return
        }

        if (!ingestFormData.fixture?.ingestType) {
            return createIPChannel()
        }
        updateIPChannel()
    };


    const handleBackupIngest = (props: IIngestProps) => {
        const {methodPayloads, ingestFormData} = props;
        const files = IngestPayload.uploadedFilesPayload(ingestFormData.fixture!.id, true, uploadedFiles)
        const isInvalidContentSupplier = IngestValidate.detectInvalidContentSupplier({getValues, clearErrors, setError})
        const isInvalidBackupMethod = IngestValidate.detectInvalidBackupMethod({getValues, clearErrors, setError})

        if (isInvalidContentSupplier ?? isInvalidBackupMethod) {
            return
        }

        if (!isShowIngestMethod && ingestFormData.fixture) {
            const contentSupplier = getValues(IngestFormController.ContentSupplier)
            const requestBody = IngestPayload.createContentSupplier(ingestFormData, contentSupplier?.value)
            dispatch(assignContentSupplier(requestBody));
        }

        if (ingestFormData.backupIngestType === IngestOptionId.BTTower) {
            const isInvalidBackupBTTower = IngestValidate.detectInvalidBackupBTTowerSource({
                getValues,
                clearErrors,
                setError
            })
            if (isInvalidBackupBTTower) {
                return
            }
            const channel = getValues(IngestFormController.IGameChannels);
            const requestBody = IngestPayload.createBackupBTTower(ingestFormData, methodPayloads, files)
            dispatch(createBtTowerIngestBackup(requestBody)).then(() => {
                if (channel) {
                    onSetIGameChannel(channel);
                }
            })
            return handleCloseForm();
        }
        if (ingestFormData.backupIngestType === IngestOptionId.Satellite) {
            const requestBody: ISatelliteBackupBody = IngestPayload.createBackupSatellite(ingestFormData, methodPayloads, files)
            dispatch(createSatelliteIngestBackup(requestBody))
            return handleCloseForm();
        }
        if (ingestFormData.backupIngestType === IngestOptionId.IP) {
            const isInvalidBackupIPChannes = IngestValidate.detectInvalidBackupIPChannels({
                getValues,
                clearErrors,
                setError
            })
            if (isInvalidBackupIPChannes) {
                return
            }
            const requestBody: IIPBackupPayload = IngestPayload.createBackupIP(ingestFormData, getValues)
            dispatch(createIPIngestBackup(requestBody));
            return handleCloseForm()
        }

    };

    const createIPChannel = () => {
        const channelValue = getValues(IngestFormController.PrimaryIPChannels).value;
        const payload = IngestPayload.createIP(ingestFormData, channelValue);
        dispatch(createIpIngest(payload))
        dispatch(clearIngestDetails());
        handleCloseForm();
    };

    const updateIPChannel = () => {
        const channel = getValues(IngestFormController.PrimaryIPChannels);
        const payload = IngestPayload.updateIP(ingestFormData, channel.value);
        dispatch(updateIpIngest(payload))
        dispatch(clearIngestDetails());
        handleCloseForm();
    };

    const createBTTower = (files: IFilesPayload | null) => {
        const payload = IngestPayload.createBTTower(methodPayloads, files);
        const channel = getValues(IngestFormController.IGameChannels);
        dispatch(createBtTowerIngest(payload)).then(() => {
            if (channel) {
                onSetIGameChannel(channel);
            }
        })
        dispatch(clearIngestDetails());
        handleCloseForm();
    }

    const updateBTTower = (files: IFilesPayload | null) => {
        const channel: IGameChannelInput = getValues(IngestFormController.IGameChannels)
        const payload: IUpdateBtTowerIngestPayload = IngestPayload.updateBTTower(ingestFormData, methodPayloads, files, channel)
        dispatch(updateBtTowerIngest(payload)).then(() => {
            if (channel) {
                onSetIGameChannel(channel);
            }
        });
        dispatch(clearIngestDetails());
        handleCloseForm();
    };

    const createSatellite = (files: IFilesPayload | null) => {
        const payload: ICreateSatelliteIngestPayload = IngestPayload.createSatellite(methodPayloads, files)
        const channel = getValues(IngestFormController.IGameChannels);
        dispatch(createSatelliteIngest(payload)).then(() => {
            if (channel) {
                onSetIGameChannel(channel);
            }
        });
        dispatch(clearIngestDetails());
        handleCloseForm();
    };

    const updateSatellite = (files: IFilesPayload | null) => {
        if (methodPayloads.Satellite) {
            const channel = getValues(IngestFormController.IGameChannels);
            const payload: IUpdateSatelliteIngestPayload = IngestPayload.updateSatellite(ingestFormData, files, methodPayloads.Satellite.fixture)
            dispatch(updateSatelliteIngest(payload)).then(() => {
                if (channel) {
                    onSetIGameChannel(channel)
                }
            });
            dispatch(clearIngestDetails());
            handleCloseForm();
        }
    }

    const onChangeIngestMethod = (method: SingleValueType) => {
        if (method) {
            setSelectedMethods({...selectedMethods, primaryType: method})
            setIngestFormData({...ingestFormData, type: Number(method.value)})
            if (method.value === IngestOptionId.BTTower) {
                dispatch(getIpChannels({fixtureId: ingestFormData.fixture?.id!, ingestType: IngestOptionId.BTTower}));
                setIngestType({BTTower: true, satellite: false, IP: false});
            }
            if (method.value === IngestOptionId.Satellite) {
                setIngestType({BTTower: false, satellite: true, IP: false});
                dispatch(getIpChannels({fixtureId: ingestFormData.fixture?.id!, ingestType: IngestOptionId.Satellite}));
            }
            if (method.value === IngestOptionId.IP) {
                setIngestType({BTTower: false, satellite: false, IP: true});
                dispatch(getIpChannels({fixtureId: ingestFormData.fixture?.id!, ingestType: IngestOptionId.IP}));
            }
        }
    }

    const isBackupHandler = () => {
        dispatch(setIsActivateBackup(!isActivateBackup))
    }

    const handleCloseForm = () => {
        setIngestType({BTTower: false, IP: false, satellite: false})
        dispatch(clearIngestDetails());
        dispatch(setIsActivateBackup(false));
        closeForm();
    }

    return (
        <div className={styles.overlay}>
            {showFormType.showIngest && (
                <form
                    onSubmit={(e) => onSubmit(e)}
                    className={styles.form}
                    data-testid="ingest-form">
                    <IngestFormTabs
                        errors={errors}
                        currentTabState={currentTabState}
                        setCurrentTabState={setCurrentTabState}
                        setValue={setValue}
                    />
                    <div className={styles.contentWrapper}>
                        <ContentSuppliers
                            resetField={resetField}
                            fixture={ingestFormData.fixture}
                            control={control}
                            setValue={setValue}
                            errors={errors}
                            register={register}
                        />
                        <IngestMethodSelector
                            register={register}
                            currentTabState={currentTabState}
                            selectedMethods={selectedMethods}
                            selectHandler={onChangeIngestMethod}
                            setIngestFormData={setIngestFormData}
                            ingestOptions={ingestOptions}
                            clearErrors={clearErrors}
                            setValue={setValue}
                            errors={errors}
                            control={control}
                            setSelectedMethods={setSelectedMethods}
                        />
                        <BTTower
                            getValues={getValues}
                            ingestFormData={ingestFormData}
                            methodPayloads={methodPayloads}
                            setMethodPayloads={setMethodPayloads}
                            currentTabState={currentTabState}
                            errors={errors}
                            iGameChannelValue={getValues(IngestFormController.IGameChannels)}
                            control={control}
                        />
                        <IngestFileUploader
                            setUploadedFiles={setUploadedFiles}
                            uploadedFiles={uploadedFiles}
                            currentTabState={currentTabState}
                            getValues={getValues}
                        />
                        <IGameChannels
                            currentTabState={currentTabState}
                            selectedMethods={selectedMethods}
                            errors={errors}
                            setValue={setValue}
                            getValues={getValues}
                            control={control}
                        />
                        <IPChannels
                            errors={errors}
                            getValues={getValues}
                            fixtureData={ingestFormData.fixture}
                            ingestType={ingestType}
                            currentTabState={currentTabState}
                            control={control}
                            setValue={setValue}
                            clearErrors={clearErrors}
                            register={register}
                        />
                        {errors.IngestTypeError && errors.IngestTypeError.type === "required" && (
                            <span className={styles.errorMessage}>{errors.IngestTypeError.message && String(errors.IngestTypeError.message)}</span>
                        )}
                        {IngestRender.SatelliteDetailsButton(setShowFormType, currentTabState, getValues)}
                        {IngestRender.BackupCheckbox(currentTabState, isActivateBackup, isBackupHandler, errors)}
                    </div>
                    {IngestRender.FormControlButton(showFormType, handleCloseForm)}
                </form>
            )}
            {showFormType.showSatellite &&
                <SatelliteForm
                    closeSatelliteForm={() => setShowFormType({showIngest: true, showSatellite: false})}
                    uploadedFiles={uploadedFiles}
                    methodPayloads={methodPayloads}
                    setMethodPayloads={setMethodPayloads}
                    fixtureData={ingestFormData.fixture}
                    setUploadedFiles={setUploadedFiles}
                    iGameChannel={getValues(IngestFormController.IGameChannels)?.value}
                />}
            {ipUsage && ipUsage.isResourceInUse &&
                <ConfirmIPModal
                    ipUsage={ipUsage}
                    onSubmit={handleIPIngest}
                />}
        </div>
    );
};

export default IngestForm;
