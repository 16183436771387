import React from 'react';
import styles from './index.module.scss';
import upload from "../../../../../../../img/upload.png";

interface IUploadComponentProps {
    handleUploadingOnClick: (arg: any) => void;
    title: string;
}

const UploadComponent: React.FC<IUploadComponentProps> = React.memo(({handleUploadingOnClick, title}) => {
    return (
        <label
            className={styles.uploadLabel}
            htmlFor="file"
            data-testid={"fileLoaderLabel"}>
            <img src={upload} alt="upload"/>

            <div className={styles.uploadTextSection}>
                <h2 className={styles.formTitle} data-testid="satelliteTitle">{title}</h2>
                <h2 className={styles.uploadTitle}>Upload files</h2>

                <p>Drag and drop file here to upload or click to find it on your computer</p>
                <p className={styles.uploadDescription}>Supported file types are .eml .txt .rft
                    .olm and .msg.</p>
            </div>
            <input
                onChange={handleUploadingOnClick}
                type="file"
                multiple
                id="file"
                className={styles.uploadInput}
            />
        </label>
    );
});

export default UploadComponent;
