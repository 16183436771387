import React, {useRef, useState} from "react";
import styles from "./index.module.scss";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/hooks";
import {RootState} from "../../../../../../store";
import Loader from "../../../../../../components/Loader/Loader";
import ErrorComponent from "../../../../../../components/ErrorComponent/ErrorComponent";
import AppTable from "../../../../../../components/AppTable/AppTable";
import NoDataFoundStub from "../../../../../../components/NoDataFoundStub/NoDataFoundStub";
import SortingHeader from "./children/SortingHeader";
import moment from "moment/moment";
import {IIngestFormData, ITableRowData} from "../../../../RightsHolderView/ScheduleIngest/BookFixtures/BookFixtures";
import {
    getBackupDetails,
    getContentSuppliers
} from "../../../../../../store/rightHolderBooking/rightHolderBookingSlice";
import IngestForm from "../../../../RightsHolderView/ScheduleIngest/BookFixtures/children/IngestForm/IngestForm";


interface IFixturesTableProps {
    data: ITableRowData[];
    sorting?: ISorting;
    setSorting?: (value: ISorting) => void;
    rightHolderId: string;
}

export interface ISorting {
    criteria: string | null;
    isAscending: boolean;
}


const FixturesTable: React.FunctionComponent<IFixturesTableProps> = React.memo((
    {
        data,
        sorting,
        setSorting,
        rightHolderId
    }) => {
    const dispatch = useAppDispatch()
    const loadingStatus = useAppSelector((state: RootState) => state.rightHolderBooking.loadingStatus);
    const [ingestFormVisible, setIngestFormVisible] = useState(false);
    const tableRef = useRef<HTMLTableElement | null>(null);
    const tableWrapper = useRef<HTMLDivElement | null>(null);
    const [ingestFormData, setIngestFormData] = useState<IIngestFormData>({
        type: 0,
        backupIngestType: 0,
        fixture: null
    });

    const startSetIngest = (fixture: ITableRowData) => {
        if (fixture) {
            const IngestType = fixture.ingestType
            dispatch(getContentSuppliers(fixture.id));
            dispatch(getBackupDetails(fixture.id))
            setIngestFormData({...ingestFormData, type: IngestType, fixture})
            setIngestFormVisible(true)
        }
    }

    const closeIngestForm = () => {
        setIngestFormVisible(false)
    }

    return <div ref={tableWrapper} className={styles.tableWrap}>
        {loadingStatus === "loading" && <Loader/>}
        {loadingStatus === "idle" && data.length === 0 && <NoDataFoundStub/>}
        {(loadingStatus === "idle" || loadingStatus === "succeeded") && data.length !== 0 &&
            <AppTable reference={tableRef}>
                <SortingHeader
                    sorting={sorting}
                    setSorting={setSorting}
                />
                <tbody>

                {data.map((item, index) => (
                    <tr key={index}>
                        <td>{item.sport}</td>
                        <td>{item.rightHolderName}</td>
                        <td>{item.competition}</td>
                        <td>{moment(item.startDate).format(`YYYY-DD-MM HH:mm`)}</td>
                        <td>{moment(item.endDate).format(`YYYY-DD-MM HH:mm`)}</td>
                        <td>{item.title}</td>
                        <td className={styles.ingestCell}>
                            <button
                                className={styles.ingestBtn}
                                onClick={() => startSetIngest(item)}
                            >
                                Select Ingest
                            </button>
                        </td>
                    </tr>
                ))
                }
                </tbody>
            </AppTable>
        }
        {loadingStatus === "failed" && <ErrorComponent/>}
        {
            ingestFormVisible &&
            <IngestForm
                rightHolderId={rightHolderId}
                closeForm={closeIngestForm}
                setIngestFormData={setIngestFormData}
                ingestFormData={ingestFormData}
            />
        }
    </div>
})

export default FixturesTable;
