import React, {useEffect, useState} from "react";
import styles from "./index.module.scss";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {RootState} from "../../../../store";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import Loader from "../../../../components/Loader/Loader";
import NoDataFoundStub from "../../../../components/NoDataFoundStub/NoDataFoundStub";
import OperatorDefaultTable from "../../../../components/OperatorDefaultTable/OperatorDefaultTable";
import ErrorComponent from "../../../../components/ErrorComponent/ErrorComponent";
import {IColumnItem} from "../../../../components/OperatorSortingHeader/OperatorSortingHeader";
import HeaderFilters from "../AvailableContent/HeaderFilters/HeaderFilters";
import {formatSelectOptions} from "../../../../helpers/formatSelectOptions";
import {formatDatePicker} from "../../../../helpers/dateFormatters";
import StreamPopup from "./children/StreamPopup/StreamPopup";
import {makeSelectOptionsFromStringsArray} from "../../../../helpers/makeSelectOptionsFromStringsArray";
import {
    getBookedContent, getBookedContentCSV,
    getFiltersOptions, getStreamLinks, IBookedContentState,
    IBookedFixture, resetFilters, setFilters
} from "../../../../store/operatorBookedContent/operatorBookedContentSlice";
import {useSortingAndFilters} from "./BookContentHooks/useSortingAndFilters";
import {validateDateRange} from "./helpers/validateDateRange";
import {useDeepCompareEffect} from "../../../../hooks/useDeepCompareEffect";
import {useParams} from "react-router-dom";
import download from "../../../../img/download.png";
import {useGetCartItems} from "../../../../hooks/useGetCartItems";
import {IOperatorPageProps} from "../RightsHoldersPortfolio/RightsHolderPortfolio";


const BookedContent: React.FunctionComponent<IOperatorPageProps> = ({menuStructure, operatorId, users}) => {
    const [streamPopupVisible, setStreamPopupVisible] = useState<boolean>(false);
    const [formatPanelVisible, setFormatPanelVisible] = useState(false);
    const [source, setSource] = useState<string>("");
    const {
        loadingStatus,
        contentInfo,
        filters,
        dropdownOptions,
    } = useAppSelector((state: RootState) => state.operatorBookedContent);
    const {setSorting, sorting} = useSortingAndFilters(operatorId, getBookedContent, filters);
    const dispatch = useAppDispatch();
    const {id} = useParams();
    useGetCartItems(id);

    const columnsData: IColumnItem[] = [
        {
            title: "Date",
            criteria: "Date",
            key: "date"
        },
        {
            title: "Day",
            criteria: "Day",
            key: "day"
        },
        {
            title: "Time",
            criteria: "Time",
            key: "time",
        },
        {
            title: "Provider",
            criteria: "Provider",
            key: "provider",
        },
        {
            title: "Sport",
            criteria: "Sport",
            key: "sport",
        },
        {
            title: "Property",
            criteria: "Property",
            key: "property",
        },
        {
            title: "Fixture",
            criteria: "Fixture",
            key: "fixture",
        },
        {
            title: "Play Content",
            criteria: "IsLive",
            key: "streamLinkUrl",
        }
    ];

    const filtersControls = [
        {
            datePicker: true,
            label: "Start Date",
            valueName: "startDate",
            options: null
        },
        {
            datePicker: true,
            label: "End Date",
            valueName: "endDate",
            options: null
        },
        {
            datePicker: false,
            label: "Rights Holder",
            valueName: "providers",
            options: formatSelectOptions(dropdownOptions.providers)
        },
        {
            datePicker: false,
            label: "Sport",
            valueName: "sports",
            options: formatSelectOptions(dropdownOptions.sports)
        },
        {
            datePicker: false,
            label: "Property",
            valueName: "properties",
            options: makeSelectOptionsFromStringsArray(dropdownOptions.properties)
        },

    ]

    useEffect(() => {
        dispatch(resetFilters());
    }, [dispatch]);

    useDeepCompareEffect(() => {
        if (validateDateRange(filters.startDate, filters.endDate)) {
            dispatch(getFiltersOptions({
                startDate: formatDatePicker(filters.startDate),
                endDate: formatDatePicker(filters.endDate),
                providersIds: [],
                sportsIds: [],
                properties: [],
                operatorId,
            }))
        }
    }, [filters]);

    const openStreamPopup = (eventId: string) => {
        setStreamPopupVisible(true);
        dispatch(getStreamLinks({operatorId, eventId}))
    }

    const renderActionCell = (fixture: IBookedFixture): React.ReactNode => {
        return (
            <button className={styles.playIcon} onClick={() => openStreamPopup(fixture.eventId)}>
                <div className={styles.triangle}></div>
            </button>
        )
    }

    const closeStreamPopupHandler = () => {
        setStreamPopupVisible(false);
        setFormatPanelVisible(false);
    }

    const handleDownloadCsv = () => {
        dispatch(getBookedContentCSV({
            startDate: formatDatePicker(filters.startDate),
            endDate: formatDatePicker(filters.endDate),
            providersIds: filters.providers.map(provider => +provider.value),
            sportsIds: filters.sports.map(sport => +sport.value),
            properties: filters.properties.map(property => property.value),
            sorting: {
                property: sorting.property,
                isAscending: sorting.isAscending
            },
            operatorId,
        }));
    }

    const onSetFilters = (value: any, name: keyof IBookedContentState["filters"]) => {
        dispatch(setFilters({value, name}));
    }

    const resetFilter = () => {
        return dispatch(resetFilters())
    }

    return (
        <>
            <PageHeader
                menuStructure={menuStructure}
                users={users}
                title={"Booked Content"}>
                <div className={styles.downloadsWrapper}>
                    <button
                        className={contentInfo.bookedEvents.length
                            ?
                            styles.downloadButton
                            :
                            `${styles.downloadButton} ${styles.disabled}`}
                        disabled={!contentInfo.bookedEvents.length}
                        onClick={handleDownloadCsv}>
                        CSV
                        <img src={download} alt="csv"/>
                    </button>
                </div>
            </PageHeader>
            <div className={styles.page}>
                <div className={styles.pageContainer}>
                    <div className={styles.upperSection}>
                        <h2 className={styles.title}>Booked Content</h2>
                        <HeaderFilters
                            resetFilters={resetFilter}
                            filterControls={filtersControls}
                            setFilters={onSetFilters}
                            filterValues={filters}
                        />
                    </div>
                    <div className={styles.tableOverlay}>
                        <div className={styles.tableTitleSection}>
                            <h2 className={styles.tableTitle}>List View</h2>
                            <div className={styles.tableLegend}>
                                <span data-testid={'booked-count'}>{contentInfo.count}</span><br/>Booked
                            </div>
                        </div>
                        {
                            loadingStatus === "loading"
                            &&
                            <Loader/>
                        }
                        {
                            loadingStatus === "idle" && !contentInfo.bookedEvents.length
                            &&
                            <NoDataFoundStub textColor={"white"}/>
                        }
                        {
                            loadingStatus === "idle" && contentInfo.bookedEvents.length > 0
                            &&
                            <OperatorDefaultTable
                                data={contentInfo.bookedEvents}
                                sorting={sorting}
                                setSorting={setSorting}
                                columnsData={columnsData}
                                renderActionCell={renderActionCell}
                                height="39vh"
                            />
                        }
                        {
                            loadingStatus === "failed"
                            &&
                            <ErrorComponent textColor={"white"}/>
                        }
                        {
                            streamPopupVisible
                            &&
                            <StreamPopup
                                source={source}
                                setSource={setSource}
                                onCloseStreamPopup={closeStreamPopupHandler}
                                formatPanelVisible={formatPanelVisible}
                                setFormatPanelVisible={setFormatPanelVisible}
                            />
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default BookedContent;
