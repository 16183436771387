import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {client} from "../../services/api-servise";
import {AxiosError} from "axios";
import {toastr} from "react-redux-toastr";
import {ISelectItem} from "../userManagement/userManagementSlice";


interface IUpdatePortPayload {
    destinationPortId: number | null
    btTowerFixtureId: string
}

interface IGetAvailablePorts {
    destinationPorts: {
        items: ISelectItem[]
    }
}

export interface IGetBookingsPayload {
    searchValue: string | undefined,
    sorting: {
        property: string | null,
        isAscending: boolean
    }
}

interface IBTFixture {
    id: string,
    date: string,
    startTime: string,
    endTime: string,
    competition: string,
    fixture: string,
    source: string,
    destinationPortId: number,
    destinationPort: string
}

interface IBtBookingsState {
    bookings: IBTFixture[],
    totalCount: number,
    availablePorts: ISelectItem[]
    isLoading: boolean
}

const initialState: IBtBookingsState = {
    bookings: [],
    availablePorts: [],
    totalCount: 0,
    isLoading: false
}


export const getBookings = createAsyncThunk<IBTFixture[], IGetBookingsPayload, { rejectValue: string }>(
    'bt-bookings/getBookings',
    async (payload, {rejectWithValue, dispatch}) => {
        try {
            const {data, headers} = await client.post(`api/Reports/bt-bookings`, payload);
            const totalCount = headers['x-total-count']
            dispatch(setTotalCount(Number(totalCount)))
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getAvailablePorts = createAsyncThunk<IGetAvailablePorts, string, { rejectValue: string }>(
    'bt-bookings/getAvailablePorts',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Reports/destination-port`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)


export const updateDestinationPort = createAsyncThunk<IBTFixture, IUpdatePortPayload, { rejectValue: string }>(
    'bt-bookings/updateDestinationPort',
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`api/Reports/destination-port`, payload);
            toastr.success('IGame', `Destination port has been updated`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)


export const btBookingsSlice = createSlice({
    name: 'bt-bookings',
    initialState,
    reducers: {
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload
        },
        clearAvailablePorts: (state) => {
            state.availablePorts = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBookings.fulfilled, (state, action) => {
                state.bookings = action.payload;
            })
            .addCase(getAvailablePorts.fulfilled, (state, action) => {
                state.availablePorts = action.payload.destinationPorts.items;
            })
            .addCase(updateDestinationPort.fulfilled, (state, action) => {
                state.bookings = state.bookings.map(booking => {
                    if (booking.id === action.meta.arg.btTowerFixtureId) {
                        booking = action.payload
                    }
                    return booking
                })
            })
    },
})
export const {setTotalCount, clearAvailablePorts} = btBookingsSlice.actions
export default btBookingsSlice.reducer
