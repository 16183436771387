import React from 'react';
import styles from '../index.module.scss';
import {Link, useParams} from "react-router-dom";
import {RxArrowLeft} from "react-icons/rx";
import Switcher from "../../Switcher/Switcher";
import {useAppSelector} from "../../../hooks/hooks";
import {useSwitchUsers} from "../../../pages/Cms/IGameView/Billing/IGameBillingHooks/useSwitchUsers";
import User from '../../../img/user.png'
import {IOperatorBillingTotalData} from "../../../store/iGameOperatorBilling/iGameOperatorBillingSlice";
import {Group} from "../../../enums/group";


interface IOperatorUser {
    totalData: IOperatorBillingTotalData
}

const OperatorUser: React.FC<IOperatorUser> = React.memo(({totalData}) => {
    const {id} = useParams();
    const {operators} = useAppSelector(state => state.userManagement);
    const {role} = useAppSelector(state => state.userInfo);
    const {operatorName} = useAppSelector(state => state.iGameOperatorBilling);
    const {handleNextUser, handlePreviousUser} = useSwitchUsers(operators, id, 'operators');

    return (
        <div className={styles.userInfoWrapper}>
            {role === Group.IGame &&
                <Link to={'/cms/igame-billing/operators'}>
                    <button className={styles.backButton}>
                        <RxArrowLeft color={'#FF3466'}/>
                    </button>
                </Link>
            }

            <div className={styles.userInner}>
                {
                    totalData.logo ?
                        <img src={totalData.logo} alt="operator_user"/>
                        :
                        <img src={User} alt="operator_user"/>
                }

                {role === Group.IGame &&
                    <p className={styles.userName}>{operatorName}</p>
                }
                <p className={styles.userRole}>Operator</p>

                {role === Group.IGame &&
                    <Switcher
                        handleNextButtonClick={handleNextUser}
                        handlePrevButtonClick={handlePreviousUser}/>
                }
            </div>

            {role === Group.IGame &&
                <p className={styles.totalUsers}>{totalData.providers} Active providers</p>
            }

            <div className={styles.totalWrapper}>
                <div className={styles.totalInner}>
                    <div className={styles.totalCell}>
                        <p className={styles.totalNumber}>{totalData.streamDelivered}</p>
                        <p className={styles.cellName}>Streams Delivered</p>
                    </div>
                    <div className={styles.totalCell}>
                        <p className={styles.totalNumber}>{totalData.totalFixtures}</p>
                        <p className={styles.cellName}>Total Fixtures</p>
                    </div>
                </div>
                <div className={styles.totalInner}>
                    <div className={styles.totalCell}>
                        <p className={styles.totalNumber}>{totalData.adHocBookings}</p>
                        <p className={styles.cellName}>Ad Hoc Bookings</p>
                    </div>

                    {role === Group.IGame ?
                        <div className={styles.totalCell}>
                            <p className={styles.totalNumber}>{totalData.deliveredContentHours}</p>
                            <p className={styles.cellName}>Hours Delivered</p>
                        </div>
                        :
                        <div className={styles.totalCell}>
                            <p className={styles.totalNumber}>{totalData.activeOperators}</p>
                            <p className={styles.cellName}>Active Operators</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
});

OperatorUser.displayName = "RightHolderUser"
export default OperatorUser;
