import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import styles from './index.module.scss'
import SelectInput from "../../../../../../components/SelectInput/SelectInput";
import {getPickerDateFormat} from "@helpers/dateFormatters";
import {useAppDispatch, useAppSelector} from "@hooks/hooks";
import {formatSelectOptions} from "@helpers/formatSelectOptions";
import {MultiValue, SingleValue} from "react-select";
import moment from "moment/moment";
import {Controller, useForm} from "react-hook-form";
import {
    createReports, getIssueDetails,
    ICreatedReport,
    IUpdatedReportDetails,
    updateReport
} from "@store/iGameReporting/iGameReportingSlice";
import DatePicker from "@components/DatePicker/DatePicker";
import {validateIssueTimes} from "./helpers/validateIStartAndEndTime";
import Checkbox from "@components/Checkbox";
import {IRangeDate} from "@pages/Cms/OperatorView/ConcurrencyInspector/ConcurrencyTable/ConcurrencyTable";
import {Tooltip} from "react-tooltip";
import tooltipIcon from "@img/info.png";
import {changeSelectInputLabels} from "@helpers/changeSelectInputLabels";
import {editLabelInDropdownOptions} from "@helpers/editLabelInDropdownOptions";
import {createDateRangeValue} from "./helpers/createDateRangeValue";
import {DateTime} from "luxon";
import {IReportFixture} from "@store/reportingFixtures/types";

interface IDate {
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number
}

interface IFaultFormProps {
    closeForm: () => void
    openReportForm: () => void
    fixture: IReportFixture
    updatingReportId: string | number
    setUpdatingReportId: Dispatch<SetStateAction<string | number>>
}

type FormData = {
    fault: string;
    description: string
    platform: string
    protocol: string
    range: IRangeDate
    startTime: IDate
    endTime: IDate
    detail: string
    severity: string | null;
};

interface IIssueSourceHandler {
    e: SingleValue<{ value: string, label: string }>;
    onChange: (...event: any[]) => void;
}

const IssueForm: React.FC<IFaultFormProps> = React.memo((
    {
        closeForm,
        fixture,
        updatingReportId,
        setUpdatingReportId
    }) => {
    const dispatch = useAppDispatch()
    const {control, handleSubmit, setValue, formState: {errors}} = useForm<FormData>();
    const {reportFormInit, reportsDetails, streamTypeOptions} = useAppSelector(state => state.iGameReportingSlice)
    const [isUnresolved, setIsUnresolved] = useState<boolean>(false);
    const [issueDescription, setIssueDescription] = useState("")
    const [issueStartTime, setIssueStartTime] = useState("");
    const [issueEndTime, setIssueEndTime] = useState("");
    const [isValidIssueTime, setIsValidIssueTime] = useState(true)
    const [selectedFault, setSelectedFault] = useState<SingleValue<{
        value: string,
        label: string;
    } | undefined>>(undefined);
    const [selectedDetail, setSelectedDetail] = useState<SingleValue<{
        value: string,
        label: string
    } | null>>(null);

    const [selectedPlatform, setSelectedPlatform] = useState<SingleValue<{
        value: string,
        label: string
    } | undefined>>(undefined);
    const [selectedSeverity, setSelectedSeverity] = useState<SingleValue<{
        value: string,
        label: string
    } | undefined>>(undefined);

    const [selectedProtocol, setSelectedProtocol] = useState<MultiValue<{
        value: string,
        label: string
    }>>(formatSelectOptions(streamTypeOptions));

    const severenityData = [
        {value: "1", label: 'S1'},
        {value: "2", label: 'S2'},
        {value: "3", label: 'S3'},
    ]

    const getInitRange = (updatingReportId: string | number) => {
        if (updatingReportId) {
            const currentReport = reportsDetails.find(report => report.id === updatingReportId)
            if (currentReport) {
                return {
                    from: createDateRangeValue(currentReport, true),
                    to: createDateRangeValue(currentReport, false)
                }
            }
        }
        return {
            from: getPickerDateFormat(moment().format()),
            to: getPickerDateFormat(moment().format()),
        }
    };

    useEffect(() => {
        setFormInputsValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatingReportId]);

    const setFormInputsValues = () => {
        if (updatingReportId) {
            const currentReport = reportsDetails.find(report => report.id === updatingReportId)
            if (currentReport) {

                const fault = formatSelectOptions(reportFormInit.faults).find(fault => fault.label === currentReport.fault)
                const platform = formatSelectOptions(reportFormInit.affectedPlatforms).find(platform => parseInt(platform.value) === currentReport.affectedPlatform)
                setIssueDescription(currentReport.description)
                setSelectedFault(fault)
                setSelectedPlatform(platform)
                setIssueStartTime(currentReport.issueStartTime)
                setIssueEndTime(currentReport.issueEndTime)
                setValue("platform", currentReport.affectedPlatform.toString())
                setValue("fault", currentReport.fault.toString())
                setValue("description", currentReport.description)
            }
        }
    }

    const createReport = () => {
        let streamTypeIds = ''
        selectedProtocol.forEach(protocol => streamTypeIds += protocol.value)

        if (validateIssueTimes(issueStartTime, issueEndTime, isUnresolved)) {
            const report: ICreatedReport = {
                fixtureId:fixture.fixtureId,
                fault: parseInt(selectedFault!.value),
                description: issueDescription,
                issueStartTime,
                issueEndTime: isUnresolved ? fixture.endDate : issueEndTime,
                affectedPlatform: parseInt(selectedPlatform!.value),
                streamType: +streamTypeIds,
                unresolved: isUnresolved,
                severity: parseInt(selectedSeverity!.value),
                detail: parseInt(selectedDetail!.value),
            }

            dispatch(createReports(report))
            closeForm()
        } else {
            setIsValidIssueTime(false)
        }
    }

    const updateReportInReportForm = () => {
        let streamTypeIds = ''
        selectedProtocol.forEach(protocol => streamTypeIds += protocol.value)

        const updatedReport = {
            id: updatingReportId,
            fixtureId:fixture.fixtureId,
            fault: parseInt(selectedFault!.value),
            description: issueDescription,
            issueStartTime,
            issueEndTime,
            affectedPlatform: parseInt(selectedPlatform!.value)
        }
        const payload: IUpdatedReportDetails = {
            id: updatingReportId,
            fixtureId: updatedReport.fixtureId,
            fault: updatedReport.fault,
            description: updatedReport.description,
            issueStartTime: updatedReport.issueStartTime,
            issueEndTime: updatedReport.issueEndTime,
            affectedPlatform: updatedReport.affectedPlatform,
            streamType: +streamTypeIds,
            unresolved: isUnresolved,
        }
        dispatch(updateReport(payload))
        setUpdatingReportId("")
        closeForm()
    }


    const setStartTime = (date: IDate) => {
        const issueStartTime = DateTime.fromObject(date).toISO();
        if (issueStartTime) setIssueStartTime(issueStartTime);
        const endTime = DateTime.fromObject(date).plus({minutes: 15}).toISO();
        if (endTime) {
            setValue("endTime", getPickerDateFormat(endTime));
            setIssueEndTime(endTime);
        }
    };

    const unresolvedCheckboxHandler = () => {
        setIsUnresolved(!isUnresolved);
        if (reportFormInit.mainReportInfo.fixtureEndDate) {
            setIssueEndTime(reportFormInit.mainReportInfo.fixtureEndDate);
        }
    }

    const issueSourceHandler = ({e, onChange}: IIssueSourceHandler) => {
        if (e) {
            setSelectedFault(e)
            setSelectedDetail(null)
            onChange(e)
            dispatch(getIssueDetails(Number(e.value)));
        }
    };

    const generateDetailsOptions = () => {
        const labels = [
            {from: "I S P", to: "ISP"},
            {from: "Bt Tower", to: "BT Tower"},
            {from: "Ip Contribution", to: "IP Contribution"},
            {from: "C D N", to: "CDN"},
        ];
        const formattedOptionsDetails = formatSelectOptions(reportFormInit.details);
        return changeSelectInputLabels(formattedOptionsDetails, labels);
    };

    return (
        <div onClick={() => closeForm()} className={styles.overlay}>
            <form
                onClick={(e) => e.stopPropagation()}
                onSubmit={updatingReportId ? handleSubmit(updateReportInReportForm) : handleSubmit(createReport)}
                className={styles.form}
            >
                <div className={styles.content}>
                    <h6 className={styles.title}>Issue Form</h6>
                    <div className={styles.inputInner}>
                        <label className={styles.label}>Issue source
                            <Controller
                                name="fault"
                                control={control}
                                rules={{required: true}}
                                render={({field: {onChange}}) => (
                                    <SelectInput
                                        value={selectedFault}
                                        onChange={(e) => {
                                            issueSourceHandler({e, onChange})
                                        }}
                                        options={editLabelInDropdownOptions(formatSelectOptions(reportFormInit.faults), "I Game", "iGame")}
                                        placeholder={"Choose an option"}
                                    />
                                )}
                            />
                        </label>
                    </div>
                    {errors.fault?.type === "required"
                        &&
                        <div className={styles.formErrorBlock}>
                            Issue source is required field
                        </div>
                    }
                    <div className={styles.inputInner}>
                        <label className={styles.label}>Issue detail
                            <Controller
                                name="detail"
                                control={control}
                                rules={{required: true}}
                                render={({field: {onChange}}) => (
                                    <SelectInput
                                        disabled={!selectedFault?.value}
                                        value={selectedDetail}
                                        onChange={(e) => {
                                            setSelectedDetail(e)
                                            onChange(e)
                                        }}
                                        options={generateDetailsOptions()}
                                        placeholder={"Choose an option"}
                                    />
                                )}
                            />
                        </label>
                    </div>
                    {errors.detail?.type === "required"
                        &&
                        <div className={styles.formErrorBlock}>
                            Issue detail is required field
                        </div>
                    }
                    <div className={styles.inputInner}>
                        <label className={styles.label}>
                            <div className={styles.severityLabelWrapper}>
                                <div>Severity</div>
                                <div
                                    className={styles.tooltipSeverityWrapper}
                                    data-tooltip-id="severity-tooltip"
                                    data-tooltip-content={"..."}
                                >
                                    <Tooltip className={styles.tooltip} id="severity-tooltip"/>
                                    <img
                                        className={styles.tooltipImg}
                                        src={tooltipIcon}
                                        alt="tooltip-icon"
                                    />
                                </div>
                            </div>
                            <Controller
                                name="severity"
                                control={control}
                                rules={{required: true}}
                                render={({field: {onChange}}) => (
                                    <SelectInput
                                        value={selectedSeverity}
                                        onChange={(e) => {
                                            onChange(e)
                                            setSelectedSeverity(e)
                                        }}
                                        options={severenityData}
                                        placeholder="Select severety"
                                    />
                                )}
                            />
                            {errors.severity?.type === "required" &&
                                <div className={styles.formErrorBlock}>Severity is required field</div>}
                        </label>
                    </div>
                    <div className={styles.issueDescription}>
                        <label className={styles.label}>Issue Description
                            <Controller
                                name="description"
                                control={control}
                                rules={{required: true}}
                                render={({field: {onChange}}) => (
                                    <textarea
                                        value={issueDescription}
                                        onChange={(e) => {
                                            onChange(e.target.value)
                                            setIssueDescription(e.target.value)
                                        }}
                                        className={styles.textarea}
                                    />
                                )}
                            />
                        </label>
                    </div>
                    {errors.description?.type === "required"
                        &&
                        <div className={styles.formErrorBlock}>
                            Issue description is required field
                        </div>
                    }
                    <div className={styles.dateBlock}>
                        <div className={styles.rangePicker}>
                            <label className={styles.label}> Start time
                                <Controller
                                    name="startTime"
                                    control={control}
                                    render={({field: {onChange}}) => (
                                        <DatePicker
                                            modalPositioningClass={'calendar-modal-down'}
                                            initValue={getInitRange(updatingReportId)!.from}
                                            withTime={true}
                                            placeholder={"Select start time"}
                                            onChange={(date) => {
                                                onChange(date)
                                                setStartTime(date)
                                            }

                                            }
                                        />
                                    )}
                                />
                            </label>
                        </div>
                        <div className={styles.rangePicker}>
                            <label className={styles.label}>End time
                                <Controller
                                    name="endTime"
                                    control={control}
                                    render={({field: {onChange}}) => (
                                        <DatePicker
                                            isDisabled={isUnresolved}
                                            modalPositioningClass={"calendar-modal-up"}
                                            initValue={getPickerDateFormat(issueEndTime)}
                                            placeholder={"Select end time"}
                                            onChange={(date) => onChange(date)}
                                        />
                                    )}
                                />
                            </label>
                            <div className={styles.unresolvedWrapper}>
                                <Checkbox
                                    isChecked={isUnresolved}
                                    onChangeHandler={unresolvedCheckboxHandler}
                                />
                                <span className={styles.unresolvedCheckbox}>Unresolved</span>
                            </div>
                        </div>
                        <div className={styles.dateError}>
                            {!isValidIssueTime &&
                                <div
                                    className={styles.formErrorBlock}>
                                    Start time and end time must be on the same day and
                                    Start time must be before end time!
                                </div>
                            }
                        </div>
                    </div>
                    <div className={styles.inputInner}>
                        <label className={styles.label}>Affected Platforms
                            <Controller
                                name="platform"
                                control={control}
                                rules={{required: true}}
                                render={({field: {onChange}}) => (
                                    <SelectInput
                                        value={selectedPlatform}
                                        onChange={(e) => {
                                            onChange(e)
                                            setSelectedPlatform(e)
                                        }}
                                        options={formatSelectOptions(reportFormInit.affectedPlatforms)}
                                        placeholder={"Choose an option"}
                                    />
                                )}
                            />
                        </label>
                    </div>
                    {errors.platform?.type === "required" &&
                        <div
                            className={styles.formErrorBlock}>Affected platforms is required field
                        </div>
                    }
                    <div className={styles.inputInner}>
                        <label className={styles.label}>Affected Protocol
                            <Controller
                                name="protocol"
                                control={control}
                                render={({field: {onChange}}) => (
                                    <SelectInput
                                        isDisabled={streamTypeOptions.length <= 1}
                                        isMulti={true}
                                        value={selectedProtocol}
                                        onChange={(e) => {
                                            onChange(e)
                                            setSelectedProtocol(e)
                                        }}
                                        options={formatSelectOptions(streamTypeOptions)}
                                        placeholder={"Choose an option"}
                                    />
                                )}
                            />
                        </label>
                    </div>
                </div>
                <div className={styles.buttons}>
                    <button
                        onClick={() => closeForm()}
                        className={styles.cancelButton}>Cancel
                    </button>
                    <button
                        type={"submit"}
                        className={styles.submitButton}>Confirm
                    </button>
                </div>
            </form>
        </div>
    );
})

IssueForm.displayName = "IssueForm"
export default IssueForm;
