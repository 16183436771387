import {AnyAction, createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {toastr} from "react-redux-toastr";
import {client} from "@services/api-servise";
import {AxiosError} from "axios";

interface IAddFixtureToCartPayload {
    operatorId?: string | null,
    fixtureId: string,
    updateFixtureFunc: () => void;
}

interface IRemoveCartItemReq {
    cartItemId: string,
    operatorId: string | null
}

interface IUpdateFixtureCountBody {
    operatorId: string | null,
    cartItemId: string,
    requestedFixtureCount: number
}

interface IAddPropertyToCartBody {
    operatorId: string | null,
    propertyId: string,
    updatePropertyFunc?: () => void
}

interface IOperatorCartFixture {
    cartItemId: string,
    fixtureId: string,
    fixtureTitle: string,
    startTime: string,
    day: string,
    date: string,
    time: string
}

export interface IPropertyCartItem {
    cartItemId: string,
    property: string,
    propertyId: string,
    availableFixtures: number,
    requestedFixtureCount: number,
    isBulkBooking: true,
    fixtures: IOperatorCartFixture[]
}

export interface ISportCartItem {
    sport: string,
    properties: IPropertyCartItem[]
}

export interface IGroupedCartItem {
    provider: string,
    sports: ISportCartItem[]
}

export interface IOperatorCartState {
    cartItems: IGroupedCartItem[],
    isLoading: boolean;
    isOperatorCartOpen: boolean;
    savedExpandProperties: string[];
    error: string;
}

const initialState: IOperatorCartState = {
    cartItems: [],
    isLoading: false,
    isOperatorCartOpen: false,
    savedExpandProperties: [],
    error: "",
}

export const getGroupedCartItems = createAsyncThunk<IGroupedCartItem[], string | null, { rejectValue: string }>(
    'operator-cart/get-grouped-cart-items',
    async (operatorId, {rejectWithValue}) => {
        try {
            if (operatorId) {
                const {data} = await client.get(`api/shopping-cart/operator-grouped?operatorId=${operatorId}`)
                return data
            } else {
                const {data} = await client.get(`api/shopping-cart/operator-grouped`)
                return data
            }
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const addPropertyToCart = createAsyncThunk<string, IAddPropertyToCartBody, { rejectValue: string }>(
    'operator-cart/add-property-to-cart',
    async (payload, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.post(`api/shopping-cart/bulk-property`, payload)
            dispatch(getGroupedCartItems(payload.operatorId));
            if (payload.updatePropertyFunc) payload.updatePropertyFunc();
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const addFixtureToCart = createAsyncThunk<any, IAddFixtureToCartPayload, {
    rejectValue: string
}>(
    'right-holder-booking/add-fixture-to-cart',
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.post("api/shopping-cart", payload);
            payload.updateFixtureFunc();
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const updateFixtureCount = createAsyncThunk<any, IUpdateFixtureCountBody, { rejectValue: string }>(
    'operator-cart/update-fixture-count',
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`api/shopping-cart/bulk-property`, payload)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const removeItemFromCart = createAsyncThunk<any, IRemoveCartItemReq, { rejectValue: string }>(
    'operator-cart/remove-item-from-cart',
    async (payload, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.delete(`api/shopping-cart/remove/${payload.cartItemId}`)
            dispatch(getGroupedCartItems(payload.operatorId))
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const operatorCartSlice = createSlice({
    name: 'operator-cart',
    initialState,
    reducers: {
        setIsCartOpen: (state, action) => {
            state.isOperatorCartOpen = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getGroupedCartItems.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getGroupedCartItems.fulfilled, (state, action) => {
                state.isLoading = false
                state.cartItems = action.payload
            })
            .addMatcher(isError, (state, action: PayloadAction<string>) => {
                state.error = action.payload;
            })
    },
})

export const {setIsCartOpen} = operatorCartSlice.actions
export default operatorCartSlice.reducer

function isError(action: AnyAction) {
    return action.type.endsWith("rejected")
}
