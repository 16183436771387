import {
    IColumnHeaderItem
} from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/children/SortingHeader/SortingHeader";
import styles from "../index.module.scss";
import {ISorting} from "../../../../TechnicalProviderView/UpcomingBookedFixtures/children/FixturesTable/FixturesTable";
import {getBookings, IGetBookingsPayload} from "../../../../../../store/btBookings/btBookingsSlice";
import {useAppDispatch} from "../../../../../../hooks/hooks";
import {useState} from "react";


enum OrderByProperty {
    Date = "Date",
    StartTime = "StartTime",
    EndTime = "EndTime",
}

export const useBookingsSorting = (searchValue: string | undefined) => {
    const [sortingCriteria, setSortingCriteria] = useState<ISorting>({
        criteria: 'Date',
        isAscending: true,
    });
    const dispatch = useAppDispatch();

    const headerControls = [
        {
            id: 1,
            title: "Date",
            criteria: OrderByProperty.Date,
        },
        {
            id: 2,
            title: "Start",
            criteria: OrderByProperty.StartTime,
        },
        {
            id: 3,
            title: "End",
            criteria: OrderByProperty.EndTime,
        },
        {
            id: 4,
            title: "Property",
            criteria: null,
        },
        {
            id: 6,
            title: "Fixture",
            criteria: null,
        },
        {
            id: 7,
            title: "Source",
            criteria: null,
        },
        {
            id: 8,
            title: "Destination Port",
            criteria: null,
        }
    ]

    const generateTableHeaderStyles = (column: IColumnHeaderItem) => {
        if (sortingCriteria.criteria === column.criteria) {
            return styles.headerClickableSelected;
        } else {
            return styles.headerClickable;
        }
    }

    const handleSorting = (criteria: string) => {
        if (criteria === sortingCriteria.criteria) {
            onSetSorting({
                criteria,
                isAscending: !sortingCriteria.isAscending
            })
        } else {
            onSetSorting({
                criteria,
                isAscending: true
            })
        }
    }

    const onSetSorting = (sorting: ISorting) => {
        const payload: IGetBookingsPayload = {
            searchValue,
            sorting: {
                property: sorting.criteria,
                isAscending: sorting.isAscending
            }
        };
        dispatch(getBookings(payload))
        setSortingCriteria(sorting);
    }

    return {
        headerControls,
        generateTableHeaderStyles,
        handleSorting,
        sortingCriteria
    }
}
