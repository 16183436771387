import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

export const pdfFormatter = async (layout: HTMLElement): Promise<void> => {
    const clonedLayout = layout.cloneNode(true) as HTMLElement;
    document.body.appendChild(clonedLayout);

    const tables = Array.from(clonedLayout.querySelectorAll("table"));
    if (!tables.length) {
        document.body.removeChild(clonedLayout);
        return;
    }

    const pdf = new jsPDF("p", "mm", "a4");

    tables.forEach((table, index) => {
        const headers = Array.from(table.querySelectorAll("thead tr th")).map((th) =>
            (th as HTMLElement).innerText.trim()
        );
        const body = Array.from(table.querySelectorAll("tbody tr")).map((row) =>
            Array.from(row.querySelectorAll("td")).map((td) => (td as HTMLElement).innerText.trim())
        );

        if (index > 0) pdf.addPage();

        pdf.setFontSize(14);
        pdf.setTextColor(40);
        pdf.text("Users Table", 14, 15);

        autoTable(pdf, {
            head: [headers],
            body,
            startY: 20,
            theme: "grid",
            styles: { fontSize: 10, cellPadding: 2, fillColor: [186, 106, 132], textColor: [255, 255, 255] },
            headStyles: { fillColor: [0, 0, 0], textColor: [255, 255, 255] },
            alternateRowStyles: { fillColor: [168, 120, 189] },
            margin: { top: 15 },
        });
    });

    pdf.save("download.pdf");
    document.body.removeChild(clonedLayout);
};


