import React, {Dispatch, SetStateAction, useState} from 'react';
import styles from './index.module.scss';
import {NavLink, useLocation} from "react-router-dom";
import {findItemByPath} from "./helpers/findItemByPath";
import {useDeepCompareEffect} from "../../../../hooks/useDeepCompareEffect";

export interface ISidebarThirdLevelItem {
    menuItemId?: string;
    path: string;
    title: string;
}

export interface ISidebarSubItem {
    menuItemId?: string;
    path: string;
    title: string;
    children?: ISidebarThirdLevelItem[];
}

export interface ISidebarItem {
    menuItemId?: string;
    path: string;
    title: string;
    children?: ISidebarSubItem[];
}

interface ISidebarProps {
    sidebarDataset: ISidebarItem[],
    menuVisible: boolean,
    setMenuVisible: Dispatch<SetStateAction<boolean>>;
}

const Sidebar: React.FC<ISidebarProps> = ({sidebarDataset, menuVisible, setMenuVisible}) => {
    const [firstRangeActive, setFirstRangeActive] = useState<ISidebarItem | null>(null);
    const [secondRangeActive, setSecondRangeActive] = useState<ISidebarSubItem | null>(null);
    const [thirdRangeActive, setThirdRangeActive] = useState<ISidebarThirdLevelItem | null>(null);
    const location = useLocation();

    useDeepCompareEffect(() => {
        const currentMenuItem = findItemByPath(sidebarDataset, location.pathname);
        if (currentMenuItem) {
            const {menuItem, parentItem, grandparentItem, rangeLevel} = currentMenuItem;

            if (rangeLevel === 1) {
                setFirstRangeActive(menuItem);

            } else if (rangeLevel === 2) {
                setFirstRangeActive(parentItem);
                setSecondRangeActive(menuItem);

            } else if (rangeLevel === 3) {
                setFirstRangeActive(grandparentItem);
                setSecondRangeActive(parentItem);
                setThirdRangeActive(menuItem);
            }
        }
    }, [location.pathname, sidebarDataset]);


    const handleFirstRangeLabelClick = (event: any, menuItem: ISidebarItem, hasChildren: boolean) => {
        if (secondRangeActive) setSecondRangeActive(null);
        if (thirdRangeActive) setThirdRangeActive(null);

        setFirstRangeActive(menuItem);

        if (!hasChildren) setMenuVisible(!menuVisible);
    };

    const handleSecondRangeLabelClick = (event: any, menuItem: ISidebarSubItem, hasChildren: boolean) => {
        if (thirdRangeActive) setThirdRangeActive(null);

        setSecondRangeActive(secondRangeActive && secondRangeActive.menuItemId === menuItem.menuItemId ? null : menuItem);

        if (!hasChildren) setMenuVisible(!menuVisible);
    };

    const handleThirdRangeLabelClick = (event: any, menuItem: ISidebarThirdLevelItem) => {
        setThirdRangeActive(thirdRangeActive && thirdRangeActive.menuItemId === menuItem.menuItemId ? null : menuItem);
        setMenuVisible(!menuVisible);
    };

    return (
        <nav className={menuVisible ? styles.sidebar : `${styles.sidebar} ${styles.hidden}`}>
            <ul className={styles.menu}>
                {sidebarDataset.map((firstRange, index) => (
                    <li key={index}
                        className={firstRangeActive && !firstRangeActive.children &&
                        firstRangeActive.menuItemId === firstRange.menuItemId
                            ? `${styles.menuTabActive} ${styles.menuTab}` : styles.menuTab}
                    >

                        {firstRange.hasOwnProperty("children") && firstRange.children !== undefined ?
                            <div onClick={(e) => handleFirstRangeLabelClick(e, firstRange, true)}>
                                {firstRange.title}
                            </div>
                            :
                            <NavLink
                                onClick={(e) => handleFirstRangeLabelClick(e, firstRange, false)}
                                to={firstRange.path}
                            >
                                {firstRange.title}
                            </NavLink>
                        }

                        {firstRange.children && firstRangeActive?.menuItemId === firstRange.menuItemId && (
                            <ul className={styles.menu}>
                                {firstRange.children.map((secondRange, idx) => (
                                    <li key={idx}
                                        className={secondRangeActive && !secondRange.children &&
                                        secondRangeActive.menuItemId === secondRange.menuItemId
                                            ? `${styles.subMenuTabActive} ${styles.subMenuTab}` : styles.subMenuTab}
                                    >
                                        {secondRange.hasOwnProperty("children") ?
                                            <div onClick={(e) => handleSecondRangeLabelClick(e, secondRange, true)}>
                                                {secondRange.title}
                                            </div>
                                            :
                                            <NavLink to={secondRange.path}
                                                     onClick={(e) => handleSecondRangeLabelClick(e, secondRange, false)}>
                                                {secondRange.title}
                                            </NavLink>
                                        }

                                        {secondRange.children && secondRangeActive?.menuItemId === secondRange.menuItemId && (
                                            <ul className={styles.subMenu}>
                                                {secondRange.children.map((thirdRange, idx) => (
                                                    <li key={idx}
                                                        className={thirdRangeActive && thirdRangeActive.menuItemId === thirdRange.menuItemId
                                                            ? `${styles.subMenuTabActive} ${styles.subMenuTab}` : styles.subMenuTab}
                                                    >
                                                        {thirdRange.hasOwnProperty("children") ?
                                                            <div
                                                                onClick={(e) => handleThirdRangeLabelClick(e, thirdRange)}>
                                                                {thirdRange.title}
                                                            </div>
                                                            :
                                                            <NavLink to={thirdRange.path}
                                                                     onClick={(e) => handleThirdRangeLabelClick(e, thirdRange)}>
                                                                {thirdRange.title}
                                                            </NavLink>
                                                        }
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    );
};

Sidebar.displayName = "Sidebar";
export default Sidebar;
