import React, {useRef} from 'react';
import styles from "./index.module.scss";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import commonStyles from "../../../children/AuditTrailsTable/index.module.scss";
import {DateTime} from "luxon";
import {
    getFixturesBookings,
    IFixturesResponse,
    setPageNumber
} from "../../../../../store/auditTrailsBookings/auditTrailsBookingsSlice";
import AppTableWhite from "../../../../AppTableWhite/AppTableWhite";
import auditIcon from "../../../../../img/auditWhite.svg";
import {AppInfiniteScroll} from "../../../../AppInfiniteScroll/AppInfiniteScroll";

interface IBookingsDetailsTableProps {
    handleFixtureSelection: (fixture: IFixturesResponse) => void;
    searchText: string;
}

const BookingsDetailsTable: React.FC<IBookingsDetailsTableProps> = ({handleFixtureSelection, searchText}) => {
    const dispatch = useAppDispatch();
    const containerRef = useRef<HTMLDivElement>(null);
    const {
        fixtures,
        pageNumber,
        itemsPerPage,
        totalCount,
        scrollLoader,
        maxItemsInTheTable,
    } = useAppSelector(state => state.auditTrailsBookings);

    const fetchData = (page: number, pageSize: number, scrollPosition: string) => {
        dispatch(getFixturesBookings({
            searchText,
            pageNumber: page,
            itemsPerPage: pageSize,
            scrollPosition
        }));
    }

    const setPage = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber));
    }

    return (
        <div className={commonStyles.tableContainer}>
            <div className={commonStyles.searchTableWrapper}>
                <AppInfiniteScroll
                    containerRef={containerRef}
                    fetchData={fetchData}
                    pageSize={itemsPerPage}
                    totalItems={totalCount}
                    currentPage={pageNumber}
                    setPage={setPage}
                    isLoading={scrollLoader}
                    scrollingContainerHeight="55vh"
                    scrollBounce={200}
                    maxItemsInTheTable={maxItemsInTheTable}
                    tableDataLength={fixtures.length}>
                    <AppTableWhite>
                        <thead>
                        <tr>
                            <th>Sport</th>
                            <th>Property</th>
                            <th>Content Supplier</th>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th className={styles.actionCell}>Go to Audit Trails</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            fixtures.length ?
                                fixtures.map(item => (
                                    <tr key={item.id}>
                                        <td>
                                            {item.sport}
                                        </td>
                                        <td>
                                            {item.competition}
                                        </td>
                                        <td>
                                            {item.contentSupplier}
                                        </td>
                                        <td>
                                            {DateTime.fromISO(item.startDate).toFormat("dd/MM/yyyy HH:mm:ss")}
                                        </td>
                                        <td>
                                            {DateTime.fromISO(item.endDate).toFormat("dd/MM/yyyy HH:mm:ss")}
                                        </td>
                                        <td>
                                            {item.title}
                                        </td>
                                        <td>
                                            {item.description}
                                        </td>
                                        <td>
                                            <button onClick={() => handleFixtureSelection(item)}
                                                    className={styles.actionBtn}>
                                                <img src={auditIcon} alt="audit"/>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                                :
                                <tr>
                                    <td>
                                        Use search to get a list of possible fixtures
                                    </td>
                                </tr>
                        }
                        </tbody>
                    </AppTableWhite>
                </AppInfiniteScroll>
            </div>
        </div>
    );
};

BookingsDetailsTable.displayName = "ResourcesDetailsTable"
export default BookingsDetailsTable;
