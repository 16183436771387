export const StreamsHeaders = [
    {
        title: 'Right',
        id: 1
    },
    {
        title: 'Player Views',
        id: 2
    },
    {
        title: 'Total (£)',
        id: 3
    }
]

export const IngestHeaders = [
    {
        title: 'Right',
        id: 1
    },
    {
        title: 'Hours',
        id: 2
    },
    {
        title: 'Total (£)',
        id: 3
    }
]

export const OperatorsHeaders = [
    {
        title: 'Name',
        id: 1
    },
    {
        title: 'Date Added',
        id: 2
    },
    {
        title: 'Total (£)',
        id: 3
    }
]

export const BandwidthHeaders = [
    {
        title: 'Operator',
        id: 1
    },
    {
        title: 'Overage (GB)',
        id: 2
    },
    {
        title: 'Total (£)',
        id: 3
    }
]
