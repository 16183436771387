import React, {Dispatch, SetStateAction} from 'react';
import styles from './style.module.scss'
import AssignmentAccordion from "./AssignmentTableAccordion/AssignmentAccordion";
import {useAppSelector} from "../../../../../hooks/hooks";

interface IExistingAssignmentTableProps {
    openConfirm: (assignmentId: number) => void;
    setUpdatingExistingAssignment: Dispatch<SetStateAction<{ rightHolder: string, id: number }>>;
    openUpdateExistingForm: Dispatch<SetStateAction<boolean>>;
}

const ExistingAssignmentTable: React.FC<IExistingAssignmentTableProps> = (
    {
        openConfirm,
        setUpdatingExistingAssignment,
        openUpdateExistingForm
    }) => {
    const {confirmedAssignment} = useAppSelector(state => state.rightAssignment)
    return (
        <div className={styles.tableOverlay}>
            <h1 className={styles.tableTitle}>Existing Assignment</h1>
            <div className={styles.tableScroll}>
                <div className={styles.tableWrapper}>
                    <table className={styles.assignmentTable}>
                        <thead>
                        <tr>
                            <th className={styles.noSpaceCell}>
                                Rights Holders
                            </th>
                            <th className={`${styles.noSpaceCell} ${styles.rightsColumn}`}>
                                Rights
                            </th>
                            <th className={`${styles.noSpaceCell} ${styles.rightsColumn}`}>
                                Sport
                            </th>
                            <th className={`${styles.noSpaceCell} ${styles.centredCell}`}>
                                Available <br/> Fixtures
                            </th>
                            <th>
                            </th>
                        </tr>
                        </thead>
                        {confirmedAssignment?.map((assignment, index) => {
                            if (assignment.rightHolderAssignments.length > 0) {
                                return (
                                    <AssignmentAccordion
                                        key={index + 1}
                                        openConfirm={openConfirm}
                                        providerName={assignment.provider}
                                        assignments={assignment.rightHolderAssignments}
                                        setUpdatingExistingAssignment={setUpdatingExistingAssignment}
                                        openUpdateExistingForm={openUpdateExistingForm}
                                    />
                                )
                            }
                            return null
                        })}
                    </table>
                </div>
            </div>
        </div>
    );
};

ExistingAssignmentTable.displayName = "ExistingAssignmentTable"
export default ExistingAssignmentTable;
