import React, {useState} from 'react';
import styles from "./index.module.scss";
import chevronWhite from "../../img/chevronWhite.png";
import {IMenuStructure} from "../../pages/Cms/AppRoutes/routes/RouteInterface";
import OutsideClickHandler from "react-outside-click-handler";
import {IUserItem} from "../../store/iGameInit/iGameInitSlice";
import chevronMagenta from "../../img/chevronMagenta.png";
import UsersDropdown from "./children/UsersDropdown/UsersDropdown";
import MenuDropdown from "./children/MenuDropdown/MenuDropdown";

interface IPageHeaderProps {
    menuStructure?: IMenuStructure;
    title?: string;
    subTitle?: string;
    children?: React.ReactNode;
    users?: IUserItem[];
}

const PageHeader: React.FC<IPageHeaderProps> = React.memo((
    {
        menuStructure,
        title,
        subTitle,
        children,
        users
    }) => {
    const [dropdownMenuVisible, setDropdownMenuVisible] = useState(false);
    const [dropdownSubMenuVisible, setDropdownSubMenuVisible] = useState(false);
    const [usersDropdownVisible, setUsersDropdownVisible] = useState(false);

    const closeDropdownMenu = () => {
        setDropdownMenuVisible(false);
        setDropdownSubMenuVisible(false);
        setUsersDropdownVisible(false);
    }

    const handleDropdownClick = () => {
        if (dropdownSubMenuVisible) {
            setDropdownSubMenuVisible(!dropdownSubMenuVisible);
        }
        setDropdownMenuVisible(!dropdownMenuVisible);
        setUsersDropdownVisible(false);
    }

    const handleSubDropdownClick = () => {
        setDropdownMenuVisible(false);
        setDropdownSubMenuVisible(!dropdownSubMenuVisible);
    }

    const handleUsersDropdownClick = () => {
        setUsersDropdownVisible(!usersDropdownVisible);
        setDropdownMenuVisible(false);
        setDropdownSubMenuVisible(false);
    }

    return (
        <OutsideClickHandler onOutsideClick={closeDropdownMenu}>
            <div className={styles.headerInner}>
                <div className={styles.pageContainer}>
                    <div className={styles.pageHeader}>
                        <div className={styles.path}>
                            <button
                                className={styles.headerBtn}
                                onClick={() => users && handleUsersDropdownClick()}
                            >
                                {
                                    users && users!.length > 1 &&
                                    <img
                                        src={chevronMagenta}
                                        alt="chevron"
                                        className={styles.usersChevron}
                                    />
                                }
                                <span className={styles.menuTitle}>{menuStructure?.title ?? ""}</span>
                            </button>

                            {
                                menuStructure?.children &&
                                <>
                                    <button
                                        className={styles.headerBtn}
                                        onClick={handleDropdownClick}
                                    >
                                        <span className={styles.separator}>/</span>
                                        <p className={styles.chevronInner}>
                                            <img
                                                className={dropdownMenuVisible && menuStructure ? styles.chevronUp : ""}
                                                src={chevronWhite}
                                                alt="chevron"/>
                                        </p>

                                        <span className={styles.childrenTitle}>{title}</span>
                                    </button>

                                    {subTitle &&
                                        <button
                                            className={styles.subTitleBtn}
                                            onClick={handleSubDropdownClick}
                                        >
                                            <span className={styles.separator}>/</span>

                                            <p className={styles.chevronInner}>
                                                <img
                                                    className={dropdownSubMenuVisible ? styles.chevronUp : ""}
                                                    src={chevronWhite}
                                                    alt="chevron"/>
                                            </p>

                                            <span>{subTitle}</span>
                                        </button>
                                    }
                                </>
                            }
                            {
                                menuStructure &&
                                <MenuDropdown
                                    dropdownMenuVisible={dropdownMenuVisible}
                                    menuStructure={menuStructure}
                                    title={title}
                                    subTitle={subTitle}
                                    setDropdownSubMenuVisible={setDropdownSubMenuVisible}
                                    dropdownSubMenuVisible={dropdownSubMenuVisible}
                                />
                            }

                            {
                                usersDropdownVisible && menuStructure &&
                                <UsersDropdown
                                    users={users}
                                    title={title}
                                />
                            }
                        </div>

                        {children && children}
                    </div>
                </div>
            </div>
        </OutsideClickHandler>
    );
});

PageHeader.displayName = 'PageHeader';
export default PageHeader;
