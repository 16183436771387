import React from "react";
import {BsArrowDown, BsArrowUp} from "react-icons/bs";
import {
    IColumnHeaderItem,
} from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/children/SortingHeader/SortingHeader";
import {ISorting} from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/BookFixtures";


const columnsData: IColumnHeaderItem[] = [
    {
        title: "Sport",
        criteria: "Sport",
    },
    {
        title: "Right holder",
        criteria: "RightHolder",
    },
    {
        title: "Competition",
        criteria: "Competition",
    },
    {
        title: "Start Time",
        criteria: "StartDate",
    },
    {
        title: "End Time",
        criteria: "EndDate",
    },
    {
        title: "Title",
        criteria: "Title",
    },
    {
        title: "Ingest",
        criteria: null,
    }
];

interface ISortingHeaderProps {
    sorting?: ISorting;
    setSorting?: (sorting: ISorting) => void;
}

const SortingHeader: React.FunctionComponent<ISortingHeaderProps> = ({
                                                                         sorting,
                                                                         setSorting
                                                                     }) => {
    const handleSorting = (criteria: string | null): void => {
        if (sorting && setSorting) {
            if (criteria === sorting!.criteria) {
                setSorting({
                    criteria,
                    isAscending: !sorting!.isAscending
                })
            } else {
                setSorting({
                    criteria,
                    isAscending: true
                })
            }
        } else {
            return;
        }
    }

    return (
        <thead>
        <tr>
            {
                columnsData.map((column, index) => {
                    if (column.criteria === null) {
                        return (
                            <th key={index}>
                                {column.title}
                            </th>
                        )
                    } else {
                        return (
                            <th onClick={() => handleSorting(column.criteria)}
                                key={index}>
                                {column.title}
                                {
                                    sorting && sorting.criteria === column.criteria
                                        ? sorting && sorting.isAscending ? <BsArrowUp/> : <BsArrowDown/>
                                        : null
                                }
                            </th>
                        )
                    }
                })
            }
        </tr>
        </thead>
    )
}

export default SortingHeader;
