import React from 'react';
import styles from './index.module.scss';
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import notification from "../../../../img/notification.svg";
import {markAlertAsSeen, removeActiveAlert} from "../../../../store/alerts/alertsSlice";
import {useNavigate} from "react-router-dom";
import close from "../../../../img/close.svg";
import {useGetProperPage} from "../../hooks/useGetProperPage";
import {navigateToTable} from "../../helpers/navigateToTable";
import {AuditAction} from "../../../../enums/auditActions";

const AlertPopup: React.FC = React.memo(() => {
    const dispatch = useAppDispatch();
    const {active} = useAppSelector(state => state.alerts);
    const {role} = useAppSelector(state => state.userInfo);
    const {itemsPerPage} = useAppSelector(state => state.rightHolderBooking);
    const navigate = useNavigate();
    const {fetchFixturePageNumber} = useGetProperPage();

    const navigateToFixture = async () => {
        if (active) {
            const pageNumber = await fetchFixturePageNumber(active.entityId, itemsPerPage, active.groupId);
            if (pageNumber) {
                navigateToTable(navigate, role, pageNumber, active.entityId, active.groupId);
            }
            dispatch(markAlertAsSeen({alertId: active.id}));
        }
        dispatch(removeActiveAlert());
    };

    const readAndClose = () => {
        if (active) {
            dispatch(markAlertAsSeen({alertId: active.id}));
        }
        dispatch(removeActiveAlert());
    };

    return (
        <div className={styles.overlay}>
            <div className={active ? `${styles.alertPopup} ${styles.show}` : styles.alertPopup}>
                <button className={styles.closeButton} onClick={() => dispatch(removeActiveAlert())}>
                    <img src={close} alt="close"/>
                </button>
                <h2 className={styles.title}>Notification Center</h2>
                <div className={styles.decor}>
                    <img className={styles.bellIconBig} src={notification} alt="notification"/>
                </div>
                <div className={styles.content}>
                    {active && (
                        <>
                            <p className={styles.text}>{active.topText}</p>
                            <p className={styles.textLarge}>{active.description}</p>
                            <p className={styles.textLarge}>{active.bottomText}</p>
                        </>
                    )}
                </div>
                <div className={styles.buttons}>
                    <button onClick={readAndClose} className={styles.cancelButton} type="button">
                        Mark as seen
                    </button>
                    {
                        active?.auditAction !== AuditAction.Delete &&
                        <button onClick={navigateToFixture} className={styles.submitButton} type="button">
                            Go to Fixture
                        </button>
                    }
                </div>
            </div>
        </div>
    );
});

export default AlertPopup;
