import {useEffect, useState} from "react";
import {useDebounce} from "../../../../../../helpers/useDebounce";
import {useAppDispatch} from "../../../../../../hooks/hooks";
import {
    getIGameUsers,
    getTechnicalProviders,
    getUserType
} from "../../../../../../store/userManagement/userManagementSlice";
import {PageTabs} from "../UserManagement";


export const useUserManagementSearch = (currentTab: string) => {
    const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
    const dispatch = useAppDispatch();

    useEffect(() => {
        setSearchValue('')
    }, [currentTab])

    const onSearch = (searchValue: string) => {
        setSearchValue(searchValue)
    }

    const searchRequest = () => {
        if (searchValue === undefined) return;

        switch (currentTab) {
            case PageTabs.I_GAME_USERS:
                dispatch(getIGameUsers(searchValue))
                break;

            case PageTabs.OPERATORS:
                dispatch(getUserType(encodeURIComponent(searchValue)));
                break;

            case PageTabs.RIGHTS_HOLDERS:
                dispatch(getUserType(encodeURIComponent(searchValue)));
                break;

            case PageTabs.TECHNICAL_PROVIDERS:
                dispatch(getTechnicalProviders(searchValue))
                break;

            default:
                return
        }
    }

    useDebounce(searchRequest, [searchValue], 500);

    return {searchValue, setSearchValue, onSearch}
}
