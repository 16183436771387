import React from 'react';

import FileUploader
    from "@pages/Cms/IGameView/Settings/UserManagement/children/UserTypesForms/components/FileUploader/FileUploader";

interface IStepThreeFormProps {
    handleFileSelection: (file: File) => void
}

const StepThreeForm: React.FC<IStepThreeFormProps> = React.memo(({handleFileSelection}) => {
    return (
        <FileUploader
            handleFileSelection={handleFileSelection}
        />
    );
});

export default StepThreeForm;
