export const loadGoogleCharts = (): Promise<typeof google> => {
    return new Promise((resolve, reject) => {
        if (window.google && window.google.visualization) {
            resolve(window.google);
        } else {
            const script = document.createElement('script');
            const apiKey = 'AIzaSyBc46wI6uDMpKOxwwWMrxnf_lGP8DKwaZ8';
            script.src = `https://www.gstatic.com/charts/loader.js?key=${apiKey}`;
            script.onload = () => {
                window.google.charts.load('upcoming', { mapsApiKey: apiKey, packages: [ 'geochart'] });
                window.google.charts.setOnLoadCallback(() => resolve(window.google));
            };
            script.onerror = reject;
            document.head.appendChild(script);
        }
    });
};

