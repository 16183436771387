import React, {ReactNode} from 'react';
import styles from './index.module.scss'
import OperatorAccessTable from "../../AuditTrailsOperatorAccess/children/OperatorAccessTable/OperatorAccessTable";
import AuditTrailsTable from "../AuditTrailsTable/AuditTrailsTable";
import RightsAssignmentsTable
    from "../../AuditTrailsRightsAssignment/children/RightsAssignmentTable/RightsAssignmentsTable";
import PricingAuditTable from "../../AuiditTrailsPricing/children/PricingTable/PricingTable";
import BookingsDetailsTable from "../../AuditTrailsBookings/children/BookingDetailsTable/BookingDetailsTable";
import ResourcesDetailsTable
    from "../../AuditTrailsIngestResources/children/ResourcesDetailsTable/ResourcesDetailsTable";
import UserManagementDetailsTable
    from "../../AuditTrailsUserManagement/children/UserManagementDetailsTable/UserManagementDetailsTable";
import PageHeader from "../../../PageHeader/PageHeader";
import {IMenuStructure} from "../../../../pages/Cms/AppRoutes/routes/RouteInterface";
import {IUserItem} from "../../../../store/iGameInit/iGameInitSlice";
import Input from "../../../Input/Input";
import {SlArrowLeft} from "react-icons/sl";

interface AuditTrailPageInnerProps {
    menuStructure: IMenuStructure;
    children?: ReactNode;
    pageTitle: string
    pageSubTitle?: string,
    pageHeader: string,
    users?: IUserItem[];
    backButtonAction?: Function;
    isExternal?: boolean;
}

const AuditTrailPageInner: React.FC<AuditTrailPageInnerProps> = (
    {
        menuStructure,
        children,
        pageTitle,
        pageSubTitle,
        users,
        pageHeader,
        backButtonAction,
        isExternal
    }) => {

    const inputComponent = React.Children.map(children, (child) => {
        if (React.isValidElement(child) && (child.type === Input || child.key === "inputs-container")) {
            return child;
        }
        return null;
    });

    const details = React.Children.map(children, (child) => {
        if (React.isValidElement(child) && (child.type === Input || child.key === "details")) {
            return child;
        }
        return null;
    });

    const dropdowns = React.Children.map(children, (child) => {
        if (React.isValidElement(child) && (child.key === "dropdowns")) {
            return child;
        }
        return null;
    });

    const table = React.Children.map(children, (child) => {
        if (
            React.isValidElement(child) &&
            (child.type === OperatorAccessTable
                || child.type === RightsAssignmentsTable
                || child.type === PricingAuditTable
                || child.type === BookingsDetailsTable
                || child.type === ResourcesDetailsTable
                || child.type === UserManagementDetailsTable
                || child.type === AuditTrailsTable
            )
        ) {
            return child;
        }
        return null;
    });

    return (
        <div>
            <PageHeader menuStructure={menuStructure}
                        title={pageTitle}
                        users={users}
                        subTitle={pageSubTitle}/>
            <div className={styles.page}>
                <div className={styles.pageContainer}>
                    <div className={styles.upperSection}>
                        <h1 className={styles.pageTitle}>{pageHeader}</h1>
                        {
                            backButtonAction && isExternal &&
                            <button className={styles.backButton}
                                    onClick={() => backButtonAction()}>
                                <SlArrowLeft/>
                                Back
                            </button>
                        }
                        {dropdowns}
                    </div>
                    <div
                        className={dropdowns?.length ? `${styles.mainContentWrapper} ${styles.topSpace}` : styles.mainContentWrapper}>
                        <div className={styles.detailsSection}>
                            {details}
                            {inputComponent}
                        </div>
                        {table}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuditTrailPageInner;
