import React, {useEffect, useMemo, useState} from 'react';
import styles from '../../../../../../../RightsHolderView/Settings/children/SettingsForm/index.module.scss'
import {SingleValue} from "react-select";
import {Controller, useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "../../../../../../../../../hooks/hooks";
import Input from "../../../../../../../../../components/Input/Input";
import {formatSelectOptions} from "../../../../../../../../../helpers/formatSelectOptions";
import SelectInput from "../../../../../../../../../components/SelectInput/SelectInput";
import Loader from "../../../../../../../../../components/Loader/Loader";
import {
    addTechnicalProviderUser,
    updateTechnicalProvider
} from "../../../../../../../../../store/userManagement/userManagementSlice";
import {IDropdownOption} from "../../../../../../../../../components/AnalyticsDashboard/types";


interface ISettingsFormProps {
    closeForm: () => void;
}

const TechnicalProviderForm: React.FC<ISettingsFormProps> = React.memo(({
                                                                            closeForm
                                                                        }) => {
    const [email, setEmail] = useState<string>('');
    const [technicalProvider, setTechnicalProvider] = useState<SingleValue<IDropdownOption> | undefined>(undefined);
    const {handleSubmit, control, formState: {errors}, setValue} = useForm();
    const dispatch = useAppDispatch();
    const {
        technicalProviderDetails,
        technicalProvidersInitData,
        formLoading
    } = useAppSelector(state => state.userManagement);

    useEffect(() => {
        if (technicalProviderDetails) {
            setEmail(technicalProviderDetails.email);

            const currentProvider = technicalProvidersInitData.find(provider => provider.id === technicalProviderDetails.technicalProviderId)

            if (currentProvider) {
                setTechnicalProvider({
                    value: currentProvider.id.toString(),
                    label: currentProvider.name
                })
                setValue("technicalProvider", {
                    value: currentProvider.id.toString(),
                    label: currentProvider.name
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [technicalProviderDetails])


    const formatSelectProviders = useMemo(() => (
        formatSelectOptions(technicalProvidersInitData)
    ), [technicalProvidersInitData]);


    const createUserData = () => {
        if (technicalProviderDetails && technicalProvider) {
            const editedTechnicalProvider = {
                payload: {
                    userId: technicalProviderDetails.id,
                    technicalProviderId: technicalProvider.value,
                },
                closeForm
            }
            dispatch(updateTechnicalProvider(editedTechnicalProvider));

        } else if (technicalProvider) {
            const newTechnicalProvider = {
                payload: {
                    email,
                    technicalProviderId: technicalProvider.value
                },
                closeForm
            }
            dispatch(addTechnicalProviderUser(newTechnicalProvider));
        }
    }

    return (
        <div className={styles.overlay}>
            {formLoading && <Loader/>}

            {!formLoading &&
                <form onSubmit={handleSubmit(createUserData)}
                      onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                      className={styles.form}>
                    <div className={styles.contentWrapper}>
                        <h6 className={styles.title}>{technicalProviderDetails ? 'Edit User' : 'New User'}</h6>
                        <div className={styles.inpWrapper}>
                            <div className={styles.inputInner}>
                                <label className={styles.label}>Email
                                    <Controller
                                        control={control}
                                        name="email"
                                        rules={{
                                            required: !technicalProviderDetails,
                                            maxLength: 40,
                                            minLength: 10,
                                            pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i
                                        }}
                                        render={({field: {onChange}}) => (
                                            <Input
                                                onChange={(e) => {
                                                    onChange(e);
                                                    setEmail(e.target.value)
                                                }}
                                                value={email}
                                                type="text"
                                                placeholder={"user@domain.ext"}
                                                disabled={!!technicalProviderDetails}
                                            />
                                        )}
                                    />
                                    {errors?.email?.type === "required" &&
                                        <div className={styles.formErrorBlock}>Email is required field</div>}
                                    {errors?.email?.type === "minLength" &&
                                        <div className={styles.formErrorBlock}>Email should contain at least 10
                                            characters</div>}
                                    {errors?.email?.type === "maxLength" &&
                                        <div className={styles.formErrorBlock}>Email should contain max 40
                                            characters</div>}
                                    {errors?.email?.type === "pattern" &&
                                        <div className={styles.formErrorBlock}>Email should be like
                                            example@xxx.xx</div>}
                                    {errors?.email?.type === "validate" &&
                                        <div className={styles.formErrorBlock}>User with this email already
                                            exists</div>}
                                </label>
                            </div>
                        </div>

                        <div className={styles.inpWrapper}>
                            <div className={styles.inputInner}>
                                <label className={styles.label}>Technical Provider
                                    <Controller
                                        name="technicalProvider"
                                        control={control}
                                        rules={{
                                            required: !technicalProvider
                                        }}
                                        render={({field: {onChange}}) =>
                                            <SelectInput
                                                value={technicalProvider}
                                                onChange={(option) => {
                                                    onChange(option)
                                                    setTechnicalProvider(option);
                                                }}
                                                options={formatSelectProviders}
                                                placeholder={"Choose an option"}
                                            />
                                        }
                                    />
                                </label>
                                {errors.technicalProvider &&
                                    <div className={styles.formErrorBlock}>Technical Provider is required field</div>}
                            </div>
                        </div>
                    </div>

                    <div className={styles.buttons}>
                        <button onClick={closeForm} className={styles.cancelButton}>Cancel</button>
                        <button
                            className={styles.submitButton}
                            type="submit">Confirm
                        </button>
                    </div>
                </form>
            }
        </div>
    );
});
TechnicalProviderForm.displayName = "TechnicalProviderForm";
export default TechnicalProviderForm;
