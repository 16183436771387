import {ISidebarItem} from "../../CmsSideBarData/CmsSideBarData";
import {Group} from "../../../../enums/group";
import {IAppRoute} from "./RouteInterface";
import {IUserItem} from "../../../../store/iGameInit/iGameInitSlice";
import {RightHolderRouters} from "./RightHolderRoutes";
import {OperatorRoutes} from "./OperatorRoutes";
import {generateIGameRoutes} from "./generateIGameRoutes";

export interface IGameRouteProps {
    dropdownDataSet?: ISidebarItem[];
    rightsHolderId?: string;
    rightsHolderUsers?: IUserItem[];
    operatorId?: string;
    operatorUsers?: IUserItem[];
}

export const routeHandler = (props: IGameRouteProps | Group): IAppRoute[] => {
    switch (props) {
        case Group.IGame:
            return generateIGameRoutes(props);
        case Group.Operator:
            return OperatorRoutes;
        case Group.RightHolder:
            return RightHolderRouters;
        default:
            return generateIGameRoutes(props);
    }
}
