import {createAsyncThunk, createSlice, PayloadAction, AnyAction} from "@reduxjs/toolkit";
import {toastr} from "react-redux-toastr";
import {client} from "../../services/api-servise";
import {AxiosError} from "axios";
import {IFilterControl} from "../../pages/Cms/OperatorView/AvailableContent/helpers/renderFilterControls";
import {IGameAnalyticFilter} from "../igameAnalytics/igameAnalyticsSlice";
import {getPickerDateFormat} from "../../helpers/dateFormatters";
import {DateTime} from "luxon";
import {formatSelectOptions} from "../../helpers/formatSelectOptions";
import {makeOptionsByStringArr} from "../../components/AnalyticsDashboard/helpers/makeOptionsByStringArr";

export interface IListItem {
    id: string;
    name: string;
}

export interface IListItems {
    items: IListItem[];
}

export interface IInitFiltersOptionsRequest {
    startDate: string;
    endDate: string;
    providersIds: number[];
    customersIds: number[];
    sportsIds: number[];
    competitions: string[];
    countries: string[];
    streamFormats: string[];
}

export interface IInitFiltersOptionsResponse {
    competitions: string[];
    sports: IListItems;
    countries: string[];
    customers: IListItems;
    dataProviders: IListItems;
    dataTypes: IListItems;
    streamFormats: string[];
    bookmarks: IBookmarkPayload[]
}

export interface IBookmarkPayload {
    id: number;
    name: string;
    competitions: string[];
    sports: IListItems;
    countries: string[];
    customers: IListItems;
    dataProviders: IListItems;
    dataTypes: IListItems;
    streamFormats: string[];

    [key: string]: any;
}

export interface IBookmarkUpdatePayload {
    id: number;
    name: string;
}

export interface IMainDataRequestPayload {
    [p: string]: number | string | IListItems | string[];

    sports: IListItems;
    endDate: string;
    dataType: number;
    competitions: string[];
    countries: string[];
    customers: IListItems;
    dataProviders: IListItems;
    streamFormats: string[];
    startDate: string
}

export type CoordinateByHour = {
    y: number;
    xStartTime: string;
    xEndTime: string;
};

export type CoordinateByDay = {
    y: number;
    x: string;
};

export type CoordinateByWeek = {
    y: number;
    xStartDate: string;
    xEndDate: string;
};

export type CoordinateByWeekDay = {
    y: number;
    xDay: number;
    xDayName: string;
};

export type CoordinateByMonth = {
    y: number;
    xYear: number;
    xMonth: number;
    xMonthName: string;
};

export type CoordinateByYear = {
    y: number;
    x: number;
};

export type GraphSection = {
    label: string;
    coordinates: CoordinateByHour[] | CoordinateByDay[] | CoordinateByWeek[] | CoordinateByWeekDay[] | CoordinateByMonth[] | CoordinateByYear[];
};

export type TotalGraphs = {
    byHour: GraphSection;
    byDay: GraphSection;
    byWeek: GraphSection;
    byWeekDay: GraphSection;
    byMonth: GraphSection;
    byYear: GraphSection;
};

export type IndividualGraphs = {
    byHour: GraphSection[];
    byDay: GraphSection[];
    byWeek: GraphSection[];
    byWeekDay: GraphSection[];
    byMonth: GraphSection[];
    byYear: GraphSection[];
};

export type Graphs = {
    total: TotalGraphs;
    individual: IndividualGraphs;
};

export type TopTenChartItem = {
    name: string;
    views: number;
    uniqueUsers: number;
};

export type TopTenCharts = {
    bySport?: TopTenChartItem[];
    byCompetition?: TopTenChartItem[];
    byProvider?: TopTenChartItem[];
    byFixture?: TopTenChartItem[];
};

export type DonutChartDataItem = {
    name: string;
    value: number;
    percentage: number;
};

export type DonutChart = {
    data: DonutChartDataItem[];
    total: number;
};

export type DonutCharts = {
    bySport?: DonutChart;
    byCompetition?: DonutChart;
    byProvider?: DonutChart;
};

export type GeoDataItem = {
    country: string;
    value: number;
};

export type MapBreakdown = {
    topCountry: string;
    uniqueUsers: number;
    totalViews: number;
    viewPerEvent: number;
    geoData: GeoDataItem[];
    geoLabelData: string[][];
};

export interface IMainAnalyticsDataResponse {
    osBreakdown: {
        windows: number;
        android: number;
        ios: number;
        other: number;
    };
    deviceBreakdown: {
        desktop: number;
        tablet: number;
        mobile: number;
        other: number;
    };
    graphs: Graphs,
    topTenCharts: TopTenCharts;
    donutCharts: DonutCharts;
    mapBreakdown: MapBreakdown;
}

export interface IRightHolderAnalyticsState {
    initData: IInitFiltersOptionsResponse;
    mainData: IMainAnalyticsDataResponse;
    bookmarkClicked: IBookmarkPayload | null;
    csvData: string;
    loading: boolean;
    error: string;
    filters: IGameAnalyticFilter
    controls: IFilterControl[]
}

const initialState: IRightHolderAnalyticsState = {
    filters: {
        startDate: getPickerDateFormat(DateTime.now().set({hour: 0, minute: 0, second: 0}).minus({days: 32}).toISO()),
        endDate: getPickerDateFormat(DateTime.now().set({hour: 0, minute: 0, second: 0}).minus({days: 1}).toISO()),
        competitions: [],
        countries: [],
        customers: [],
        dataProviders: [],
        sports: [],
        dataType: {value: 1, label: "Views"},
        streamFormats: []
    },
    controls: [
        {
            datePicker: true,
            label: "Start Date",
            valueName: "startDate",
            options: null
        },
        {
            datePicker: true,
            label: "End Date",
            valueName: "endDate",
            options: null
        },
        {
            datePicker: false,
            label: "Right Holder",
            valueName: "dataProviders",
            options: []
        },
        {
            datePicker: false,
            label: "Operator",
            valueName: "customers",
            options: []
        },
        {
            datePicker: false,
            label: "Country",
            valueName: "countries",
            options: []
        },
        {
            datePicker: false,
            label: "Sport",
            valueName: "sports",
            options: []
        },
        {
            datePicker: false,
            label: "Property",
            valueName: "competitions",
            options: []
        },
        {
            datePicker: false,
            label: "Data Type",
            valueName: "dataType",
            isMulti: false,
            options: []
        },
        {
            datePicker: false,
            label: "Stream Format",
            valueName: "streamFormats",
            options: []
        },
    ],
    initData: {
        competitions: [],
        sports: {
            items: []
        },
        countries: [],
        customers: {
            items: []
        },
        dataProviders: {
            items: []
        },
        dataTypes: {
            items: []
        },
        streamFormats: [],
        bookmarks: []
    },
    mainData: {
        osBreakdown: {
            windows: 0,
            android: 0,
            ios: 0,
            other: 0
        },
        deviceBreakdown: {
            desktop: 0,
            tablet: 0,
            mobile: 0,
            other: 0
        },
        graphs: {
            total: {
                byHour: {
                    label: '',
                    coordinates: []
                },
                byDay: {
                    label: '',
                    coordinates: []
                },
                byWeek: {
                    label: '',
                    coordinates: []
                },
                byWeekDay: {
                    label: '',
                    coordinates: []
                },
                byMonth: {
                    label: '',
                    coordinates: []
                },
                byYear: {
                    label: '',
                    coordinates: []
                },
            },
            individual: {
                byHour: [],
                byDay: [],
                byWeek: [],
                byWeekDay: [],
                byMonth: [],
                byYear: [],
            }
        },
        topTenCharts: {},
        donutCharts: {},
        mapBreakdown: {
            topCountry: "",
            uniqueUsers: 0,
            totalViews: 0,
            viewPerEvent: 0,
            geoData: [],
            geoLabelData: []
        }
    },
    bookmarkClicked: null,
    csvData: '',
    loading: false,
    error: ''
}

export const getInitFiltersOptions = createAsyncThunk<IInitFiltersOptionsResponse, IInitFiltersOptionsRequest, {
    rejectValue: string
}>(
    'right-holder-analytics/filter-options',
    async (payload, {rejectWithValue}) => {
        try {
            const response: any = await client.post("/api/Analytics/filter-items", payload);
            return response.data;
        } catch (e) {
            if (e instanceof AxiosError) {
                const errorMessage = e.response!.data;
                toastr.error('Error', `${errorMessage}`);
                return rejectWithValue(errorMessage);
            }
        }
    }
)

export const createFiltersBookmark = createAsyncThunk<any, IBookmarkPayload, { rejectValue: string }>(
    'right-holder-analytics/bookmark',
    async (IBookmarkPayload, {rejectWithValue}) => {
        try {
            const response = await client.post("/api/Analytics/bookmark", IBookmarkPayload);
            toastr.info('Success', "The bookmark was created");
            return response.data;
        } catch (e) {
            if (e instanceof AxiosError) {
                const errorMessage = e.response!.data.title;
                toastr.error('Error', `${errorMessage}`);
                return rejectWithValue(errorMessage);
            }
        }
    }
)

export const updateFiltersBookmark = createAsyncThunk<any, IBookmarkUpdatePayload, { rejectValue: string }>(
    'right-holder-analytics/bookmark-update',
    async (payload, {rejectWithValue}) => {
        try {
            await client.put("/api/Analytics/bookmark", payload);
            toastr.info('Success', "The bookmark was updated");
            return payload;
        } catch (e) {
            if (e instanceof AxiosError) {
                const errorMessage = e.response!.data.title;
                toastr.error('Error', `${errorMessage}`);
                return rejectWithValue(errorMessage);
            }
        }
    }
)

export const deleteFiltersBookmark = createAsyncThunk<number, number, { rejectValue: string }>(
    'right-holder-analytics/bookmark-delete',
    async (bookmarkId, {rejectWithValue}) => {
        try {
            await client.delete(`/api/Analytics/bookmark/${bookmarkId}`);
            toastr.info('Success', "The bookmark was deleted");
            return bookmarkId;
        } catch (e: any) {
            if (e instanceof AxiosError) {
                const errorMessage = e.response!.data.title;
                toastr.error('Error', `${errorMessage}`);
                return rejectWithValue(errorMessage);
            } else {
                const errorMessage = "Something went wrong...";
                toastr.error('Error', `${errorMessage}`);
                return rejectWithValue(errorMessage);
            }

        }
    }
)

export const getRightHolderAnalytics = createAsyncThunk<IMainAnalyticsDataResponse, IMainDataRequestPayload, {
    rejectValue: string
}>(
    'right-holder-analytics/get-data',
    async (payload, {rejectWithValue}) => {
        try {
            const response = await client.post("/api/Analytics/right-holder", payload);
            return response.data;
        } catch (e) {
            if (e instanceof AxiosError) {
                const errorMessage = e.response!.data.title;
                toastr.error('Error', `${errorMessage}`);
                return rejectWithValue(errorMessage);
            }
        }
    }
)

export const downloadRightHolderAnalyticsCsv = createAsyncThunk<any, IMainDataRequestPayload, { rejectValue: string }>(
    'right-holder-analytics/load-csv',
    async (payload, {rejectWithValue}) => {
        try {
            const response = await client.post("/api/Analytics/right-holder/download", payload);
            toastr.info("Success!", "CSV is downloaded");
            return response.data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data.title}`)
                return rejectWithValue(e.response?.data.title)
            }
        }
    }
)

export const rightHolderAnalyticsSlice = createSlice({
    name: 'right-holder-analytics',
    initialState,
    reducers: {
        setSelectedBookmark: (state, action) => {
            state.bookmarkClicked = action.payload;
        },
        setFilters: (state, action: PayloadAction<{
            value: any;
            name: keyof IRightHolderAnalyticsState["filters"]
        }>) => {
            state.filters[action.payload.name] = action.payload.value;
        },
        resetFilters: (state) => {
            state.filters = initialState.filters
        },
        setFiltersByBookmarks: (state, action) => {
            state.filters = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getInitFiltersOptions.pending, (state) => {
                state.error = "";
                state.loading = true;
            })
            .addCase(getInitFiltersOptions.fulfilled, (state, action: PayloadAction<IInitFiltersOptionsResponse>) => {
                state.loading = false;
                state.initData = action.payload;
                state.controls[2].options = formatSelectOptions(action.payload.dataProviders.items)
                state.controls[3].options = formatSelectOptions(action.payload.customers.items)
                state.controls[4].options = makeOptionsByStringArr(action.payload.countries.sort((a, b) => a.localeCompare(b)))
                state.controls[5].options = formatSelectOptions(action.payload.sports.items)
                state.controls[6].options = makeOptionsByStringArr(action.payload.competitions)
                state.controls[7].options = formatSelectOptions(action.payload.dataTypes.items)
                state.controls[8].options = makeOptionsByStringArr(action.payload.streamFormats)
            })
            .addCase(createFiltersBookmark.pending, (state) => {
                state.error = "";
                state.loading = true;
            })
            .addCase(createFiltersBookmark.fulfilled, (state, action) => {
                state.loading = false;
                state.initData.bookmarks.push(action.payload);
            })
            .addCase(updateFiltersBookmark.pending, (state) => {
                state.error = "";
                state.loading = true;
            })
            .addCase(updateFiltersBookmark.fulfilled, (state, action) => {
                state.loading = false;
                state.initData.bookmarks = state.initData.bookmarks.map(x => {
                    if (x.id === action.payload.id) {
                        return {
                            ...x,
                            name: action.payload.name
                        }
                    } else {
                        return x;
                    }
                });
            })
            .addCase(deleteFiltersBookmark.fulfilled, (state, action: PayloadAction<number>) => {
                state.loading = false;
                state.initData.bookmarks = state.initData.bookmarks.filter(x => x.id !== action.payload);
            })
            .addCase(getRightHolderAnalytics.pending, (state) => {
                state.error = "";
                state.loading = true;
            })
            .addCase(getRightHolderAnalytics.fulfilled, (state, action: PayloadAction<IMainAnalyticsDataResponse>) => {
                state.loading = false;
                state.mainData = action.payload;
            })
            .addCase(downloadRightHolderAnalyticsCsv.pending, (state) => {
                state.error = "";
                state.loading = true;
            })
            .addCase(downloadRightHolderAnalyticsCsv.fulfilled, (state, action) => {
                state.loading = false;
                state.csvData = action.payload;
            })
            .addMatcher(isError, (state, action: PayloadAction<string>) => {
                state.error = action.payload;
                state.loading = false;
            })
    },
})

export const {setSelectedBookmark, setFiltersByBookmarks, setFilters, resetFilters} = rightHolderAnalyticsSlice.actions
export default rightHolderAnalyticsSlice.reducer

function isError(action: AnyAction) {
    return action.type.endsWith("rejected")
}
