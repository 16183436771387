import React, {useEffect, useState} from 'react';
import styles from "./index.module.scss";
import {Controller, FieldValues, SubmitHandler, useForm} from "react-hook-form";
import {useNavigate, useSearchParams} from "react-router-dom";
import Input from "../../components/Input/Input";
import {confirmResetPassword} from "../../store/settings/settingsSlice";
import {useAppDispatch} from "../../hooks/hooks";
import VisualPasswordHelper from "./components/passwordVisualHelper/visualPasswordHelper";
import close from "../../img/close.svg";

interface IResetPasswordForm {
    password: string;
    confirmPassword: string;
}

export interface IValidationState {
    iOneUppercase: boolean,
    isOneLowercase: boolean,
    isOneNumber: boolean,
    isOneSpecialSymbol: boolean,
    correctLength: boolean
}

const ResetPassword = () => {
    const {handleSubmit, formState: {errors}, control, getValues, watch} = useForm<IResetPasswordForm>();
    const [password, setPassword] = useState("");
    const [validationState, setValidationState] = useState<IValidationState>({
        iOneUppercase: false,
        isOneLowercase: false,
        isOneNumber: false,
        isOneSpecialSymbol: false,
        correctLength: false
    });
    const [allChecksPassed, setAllChecksPassed] = useState(false);
    const [currentUsingInput, setCurrentUsingInput] = useState<"password" | "confirm" | "">("");
    const [matched, setMatched] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const watchConfirmPassword = watch("confirmPassword", '');

    useEffect(() => {
        setAllChecksPassed(Object.values(validationState).every(value => value === true))
    }, [validationState]);

    useEffect(() => {
        const confirmToken = searchParams.get('ct')
        if (confirmToken) {
            localStorage.removeItem("book-token");
            localStorage.removeItem("book-refresh");
            localStorage.setItem("book-token", confirmToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setMatched(checkIsMatchPassword());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchConfirmPassword, validationState]);

    const onSubmit: SubmitHandler<FieldValues> = () => {
        const payload = {
            password: getValues("password")
        }
        dispatch(confirmResetPassword(payload))

        setTimeout(() => {
            localStorage.removeItem("book-token");
            navigate('/*');
        }, 1000)
    };

    const checkIsMatchPassword = () => {
        return getValues("password") === getValues("confirmPassword")
    }

    const redirectToLogin = () => {
        navigate("/");
    }

    return (
        <>
            <div className={styles.formUnderlayBackground}/>
            <div className={styles.resetPasswordModalOverlay}>
                <div className={styles.resetPasswordModalContainer}>
                    <button className={styles.resetPasswordModalCloseButton} onClick={redirectToLogin}>
                        <img src={close} alt="close"/>
                    </button>
                    <h1 className={styles.resetPasswordModalTitle}>Reset password</h1>
                    <p className={styles.resetPasswordModalText}>Please enter new password in the form below</p>
                    <form className={styles.resetPasswordModalForm} onSubmit={handleSubmit(onSubmit)}>
                        <div className={styles.resetPasswordModalInputsSection}>
                            <label htmlFor="password" className={styles.resetPasswordModalLabel}>
                                New password
                                <Controller
                                    control={control}
                                    name="password"
                                    rules={{
                                        required: true,
                                        maxLength: 50,
                                        minLength: 8,
                                        pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/,
                                    }}
                                    render={({field: {onChange, value}}) => (
                                        <Input
                                            onClick={() => setCurrentUsingInput("password")}
                                            onChange={(e) => {
                                                onChange(e);
                                                setPassword(e.target.value)
                                            }}
                                            value={value}
                                            type="password"
                                            name="password"
                                            id="password"
                                            placeholder={"Enter new password..."}
                                            className={styles.resetPasswordModalInput}
                                        />
                                    )}
                                />
                                {errors.password?.type === 'required' &&
                                    <div className={styles.formError}>Password is required field</div>}
                            </label>
                            <label htmlFor="confirmPassword" className={styles.resetPasswordModalLabel}>
                                Repeat password
                                <Controller
                                    control={control}
                                    name="confirmPassword"
                                    rules={{
                                        required: true,
                                        validate: {missMatch: checkIsMatchPassword}
                                    }}
                                    render={({field: {onChange, value}}) => (
                                        <Input
                                            onClick={() => setCurrentUsingInput("confirm")}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            value={value}
                                            type="password"
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            placeholder={"Confirm password..."}
                                            className={styles.resetPasswordModalInput}
                                            disabled={!allChecksPassed}
                                        />
                                    )}
                                />
                                {errors.confirmPassword?.type === 'missMatch' &&
                                    <div className={styles.formError}>Inputs don't match</div>}
                                {errors.confirmPassword?.type === 'required' &&
                                    <div className={styles.formError}>Confirm password is required field</div>}
                            </label>
                            <div className={styles.visualPasswordHelper}>
                                {(currentUsingInput === "password" || currentUsingInput === "confirm") &&
                                    <VisualPasswordHelper
                                        title={"Password"}
                                        passwordValue={password}
                                        setValidationState={setValidationState}
                                        validationState={validationState}
                                    />}
                            </div>
                        </div>
                        <div className={styles.buttons}>
                            <button
                                onClick={redirectToLogin}
                                className={styles.cancelButton}>
                                Cancel
                            </button>
                            <button
                                className={allChecksPassed && matched
                                    ?
                                    styles.submitButton
                                    :
                                    `${styles.submitButton} ${styles.submitButtonDisabled}`}
                                type="submit">
                                Reset
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;
