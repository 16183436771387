import React, {useEffect, useState} from "react";
import styles from "./index.module.scss";
import {useAppDispatch, useAppSelector} from "@hooks/hooks";
import {RootState} from "@store/index";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import {FiShoppingCart} from "react-icons/fi";
import externalStyles from "./RightsHoldersTiles/index.module.scss";
import FavoriteStar from "../../../../components/FavoriteStar/FavoriteStar";
import OperatorDefaultTable from "../../../../components/OperatorDefaultTable/OperatorDefaultTable";
import {columnsData} from "./columnsData";
import HeaderFilters from "../AvailableContent/HeaderFilters/HeaderFilters";
import {useNavigate} from "react-router-dom";
import backIcon from "../../../../img/headerItemMore.svg";
import {
    getFiltersOptions,
    getPortfolio, IRightHolderPortfolio,
    resetFilters,
} from "@store/operatorRightsOverview/operatorRightsOverviewSlice";
import {useSetFilters} from "./RightsHoldersTiles/helpers/onSetFilters";
import {generatePortfolioTitle} from "./RightsHoldersTiles/helpers/generatePortfolioTitle";
import {IUserItem} from "@store/iGameInit/iGameInitSlice";
import ErrorComponent from "../../../../components/ErrorComponent/ErrorComponent";
import {IInputOption} from "@store/operatorAccess/operatorAccessSlice";
import {ISidebarItem} from "../../CmsSideBarData/CmsSideBarData";
import {createFilterControls} from "./RightsHoldersTiles/helpers/createFilterControls";
import defaultLogo from "../../../../img/defaultUserLogo.svg";
import {Tooltip} from "react-tooltip";
import {addPropertyToCart, removeItemFromCart} from "@store/carts/operatorCart/operatorCartSlice";
import {useGetCartItems} from "@hooks/useGetCartItems";
import {useSortingAndFilters} from "./PortfolioHooks/useSortingAndFilters";
import {createRequestModel} from "./RightsHoldersTiles/helpers/createRequestModel";

export interface IOperatorPageProps {
    menuStructure: ISidebarItem;
    users?: IUserItem[];
    operatorId: string | null;
}

const RightsHolderPortfolio: React.FunctionComponent<IOperatorPageProps> = ({menuStructure, users, operatorId}) => {
    const dispatch = useAppDispatch();
    const setFiltersHandler = useSetFilters();
    const navigate = useNavigate();
    const {
        rightsOverviewPortfolio,
        loadingStatus,
        filters,
        dropdownOptions,
    } = useAppSelector((state: RootState) => state.operatorRightsOverview);
    const {setSorting, sorting} = useSortingAndFilters(operatorId, getPortfolio, filters);
    const [filteredRightHolders] = useState<IInputOption[]>([] as IInputOption[]);
    const filtersControls = createFilterControls(dropdownOptions);

    useGetCartItems(operatorId)

    useEffect(() => {
        if (operatorId && filters) {
            dispatch(getFiltersOptions(createRequestModel(filters, operatorId)));
            return
        }
        dispatch(getFiltersOptions(createRequestModel(filters, null)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetFilter = () => {
        return dispatch(resetFilters());
    };

    const handleAddToCartClick = (item: IRightHolderPortfolio) => {
        if (item.rightState !== 'Available') {
            dispatch(removeItemFromCart({
                cartItemId: item.rightId,
                operatorId
            }));
        } else {
            const payload = {
                propertyId: item.rightId,
                operatorId
            }

            dispatch(addPropertyToCart(payload))
        }
    }

    const renderActionCell = (rowItem: IRightHolderPortfolio): React.ReactNode => {
        if (rowItem.rightState === 'Available') {
            return (
                <button className={styles.cartBtn}>
                    <FiShoppingCart
                        className={styles.cartIcon}
                        onClick={() => handleAddToCartClick(rowItem)}
                    />
                </button>
            );
        } else return <div>Booked</div>;
    };

    const renderIndicator = (rowItem: IRightHolderPortfolio): React.ReactNode | null => {
        if (rowItem.isInCart) {
            return <span className={styles.indicator}></span>;
        } else {
            return null;
        }
    };

    const backToOperatorsHandler = () => {
        dispatch(resetFilters());
        navigate(-1);
    }

    const attachLogos = (rightHolders: IInputOption[]) => {
        return rightHolders.map((rightHolder) => {
                if (rightHolder.logo) {
                    return (
                        <div
                            data-tooltip-id={`${rightHolder.id}`}
                            className={styles.portfolioLogo} key={rightHolder.id}
                        >
                            <img src={rightHolder.logo} alt={rightHolder.name}/>
                            <Tooltip id={`${rightHolder.id}`}/>
                        </div>

                    )
                } else {
                    return (
                        <div
                            data-tooltip-id={`${rightHolder.id}`}
                            className={styles.portfolioLogo} key={rightHolder.id}
                        >
                            <img src={defaultLogo} alt={rightHolder.name}/>
                            <Tooltip id={`${rightHolder.id}`}/>
                        </div>
                    )
                }
            }
        );
    };

    return (
        <>
            <PageHeader
                menuStructure={menuStructure}
                users={users}
                title={"Rights Holders Portfolio"}
            />
            <div className={externalStyles.page}>
                <div className={styles.pageContainer}>
                    <div className={externalStyles.upperSection}>
                        <h2 className={externalStyles.title}>Rights Holders</h2>
                        <HeaderFilters
                            resetFilters={resetFilter}
                            filterControls={filtersControls}
                            setFilters={setFiltersHandler}
                            filterValues={filters}
                        />
                    </div>
                    <div className={styles.backButtonInner}>
                        <button className={styles.backButton} onClick={backToOperatorsHandler}>
                            <img className={styles.backToOperators} src={backIcon} alt="icon_back"/>
                            Back
                        </button>
                    </div>

                    <div className={styles.contentBox}>

                        <div className={styles.tableWrapper}>
                            <div className={styles.titleWrapper}>
                                <h3 className={styles.tableTitle}>
                                    {generatePortfolioTitle(filters)}
                                </h3>

                                <div className={styles.portfolioLogoWrapper}>
                                    {attachLogos(filteredRightHolders)}
                                </div>
                            </div>

                            <div className={styles.tableInfo}>
                                <div className={styles.tableInfoGroup}>
                                    <div className={styles.bookedRectangle}></div>
                                    <h6 className={styles.booked}>Booked</h6>
                                </div>
                                <div className={styles.tableInfoGroup}>
                                    <div className={styles.availableRectangle}></div>
                                    <h6>Available</h6>
                                </div>
                                <div className={styles.tableInfoGroup}>
                                    <div className={styles.star}>
                                        <FavoriteStar isActive={true} fontSize={19}/>
                                    </div>
                                    <h6>Favourite</h6>
                                </div>
                            </div>
                        </div>
                        <OperatorDefaultTable
                            data={rightsOverviewPortfolio}
                            sorting={sorting}
                            setSorting={setSorting}
                            columnsData={columnsData}
                            renderActionCell={renderActionCell}
                            height="40vh"
                            redirectionToPropertyDetailPage={true}
                            renderIndicator={renderIndicator}
                        />
                        {loadingStatus === "failed" && <ErrorComponent textColor={"white"}/>}
                    </div>
                </div>
            </div>
        </>
    );
};

export default RightsHolderPortfolio;
