import React, {useEffect, useState} from 'react';
import HeaderFilters from "./HeaderFilters/HeaderFilters";
import styles from "./index.module.scss"
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import FixturesChart from './FixturesChart/FixturesChart';
import FavoriteStar from "../../../../components/FavoriteStar/FavoriteStar";
import {
    clearFilter,
    getAvailableContentInit, geAvailableContentData,
    setFilters,
    setRemainingSelection,
    updatePropertyCartStatus
} from "../../../../store/contentAnalysis/contentAnalysisSlice";
import {formatDatePicker} from "../../../../helpers/dateFormatters";
import {client} from "../../../../services/api-servise";
import {toastr} from "react-redux-toastr";
import {
    convertToBookedAndAvailableDayView,
    convertToBookedDayView, convertToMonthView, convertToMonthViewWithAvailable, transformToFiveDayView,
    transformToFiveDayViewWithAvailable
} from "./helpers/chartFormaters";
import {useSorting} from "./helpers/useSorting";
import {useDeepCompareEffect} from "../../../../hooks/useDeepCompareEffect";
import SelectInput from "../../../../components/SelectInput/SelectInput";
import Loader from "../../../../components/Loader/Loader";
import {ContentItem, IContentAnalysisState} from "../../../../store/contentAnalysis/types";
import {validateAndSetDates} from "./helpers/validateAndSetDates";
import download from "../../../../img/download.png";
import {addPropertyToCart} from "@store/carts/operatorCart/operatorCartSlice";
import {useGetCartItems} from "../../../../hooks/useGetCartItems";
import {IOperatorPageProps} from "../RightsHoldersPortfolio/RightsHolderPortfolio";
import AvailableContentTable from "./AvailableContentTable/AvailableContentTable";
import Checkbox from '@components/Checkbox';
import {
    generateGetContentPayload,
    IAvailableContentParams
} from "@pages/Cms/OperatorView/AvailableContent/helpers/generateGetContentPayload";
import {addFixtureToBFCart} from "@store/carts/operatorBFCart/bookFixtureCartSlice";
import cart from "@img/cartEmpty.svg";
import bfCart from "@img/additionalCartIcon.svg";

enum InfoType {
    CHART = 0,
    TABLE = 1
}

enum ViewType {
    DAY = 0,
    FIVE_DAY = 1,
    MONTH = 2
}

enum ContentType {
    BOOKED = "BOOKED",
    AVAILABLE = "AVAILABLE",
}

export const defaultTimeZone = "14" //(UTC)

const AvailableContent: React.FC<IOperatorPageProps> = ({menuStructure, users, operatorId}) => {
        const dispatch = useAppDispatch()
        const [infoType, setInfoType] = useState(InfoType.CHART);
        const [chartDataType, setChartDataType] = useState(ViewType.DAY);
        const [favContentList, setFavContentList] = useState<ContentItem[]>([]);
        const {
            filters,
            contentList,
            availableContentInitOptions: {controls, timeZones},
            concurrencyChart,
            isLoading,
            favouriteFilters,
            availableContentCount,
            bookedContentCount
        } = useAppSelector(state => state.contentAnalysis);
        const [contentType, setContentType] = useState(ContentType.AVAILABLE);
        const [isFavourite, setIsFavourite] = useState(false);
        useGetCartItems(operatorId);

        const params = {
            startDate: formatDatePicker(filters.startDate),
            endDate: formatDatePicker(filters.endDate),
            chartDataType,
            contentViewData: infoType,
            competitions: filters.property.map(item => item.value),
            showBookedOnly: contentType === ContentType.BOOKED,
            timeZone: filters.timeZone,
            sportIds: filters.sport.map(item => item.value),
            rightHolders: filters.rightHolder.map(item => item.value),
            operatorId: localStorage.getItem('client') as string,
        }
        const {setSorting, sorting} = useSorting(operatorId, geAvailableContentData, params, infoType === InfoType.TABLE);

        useEffect(() => {
            dispatch(getAvailableContentInit(null))
            if (favouriteFilters.sport) {
                dispatch(setFilters({value: favouriteFilters.sport, name: 'sport'}));
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useDeepCompareEffect(() => {
            if (!filters.timeZone) return;

            const payload = generateGetContentPayload(params);
            dispatch(geAvailableContentData(payload));
        }, [filters, infoType, chartDataType, contentType]);

        const onSetFilters = (value: any, name: keyof IContentAnalysisState["filters"]) => {
            dispatch(setFilters({value, name}));

            if (name === "startDate" || name === "endDate") {
                validateAndSetDates(value, name, filters, dispatch);
            }
        }

        const resetFilters = () => {
            dispatch(clearFilter())
        }

        const changeContentType = (contentType: ContentType) => {
            return contentType === ContentType.AVAILABLE ? setContentType(ContentType.BOOKED)
                : setContentType(ContentType.AVAILABLE)
        }


        const renderActionCell = (fixture: ContentItem): React.ReactNode => {
            if (fixture.isBooked) {
                return (
                    <button
                        className={styles.bookedStatus}
                        onClick={() => handleAddPropertyToCart(fixture)}>
                    Booked
                </button>
                )
            } else {
                return renderAdditionalCart(fixture);
            }
        }

        const renderAdditionalCart = (fixture: ContentItem): React.ReactNode => {
            if (!fixture.remainingSelections) {
                return (
                    <button className={styles.addToCartButton} onClick={() => handleAddPropertyToCart(fixture)}>
                        <img className={styles.addToCartIcon} src={cart} alt="cart"/>
                    </button>
                )
            } else {
                return (
                    <button className={styles.addToCartButton} onClick={() => addFixturesToAdditionalCart(fixture)}>
                        <img className={styles.addToCartIcon} src={bfCart} alt="cart"/>
                    </button>
                )
            }
        }

        const handleAddPropertyToCart = (property: ContentItem) => {
            if (property.isBooked) {
                // dispatch(removeItemFromCart({
                //     operatorId,
                //     cartItemId: property.fixtureId,
                // }))
            } else {
                dispatch(addPropertyToCart({
                        operatorId,
                        propertyId: property.fixtureId,
                        updatePropertyFunc: () => dispatch(updatePropertyCartStatus({
                            fixtureId: property.fixtureId,
                            isBooked: true
                        }))
                    }
                ))
            }
        }

        const addFixturesToAdditionalCart = (fixture: ContentItem) => {
            const payload = {
                fixtureId: fixture.fixtureId,
                operatorId
            }

            dispatch(addFixtureToBFCart({
                payload,
                setRemainingSelection: () => dispatch(setRemainingSelection(fixture.fixtureId))
            }))
        }

        useEffect(() => {
            const favContentList = contentList.filter(item => item.isFavourite)
            setFavContentList(favContentList)
        }, [contentList]);

        const downloadSCVFile = async () => {
            try {
                const params: IAvailableContentParams = {
                    startDate: formatDatePicker(filters.startDate),
                    endDate: formatDatePicker(filters.endDate),
                    chartDataType,
                    contentViewData: infoType,
                    competitions: filters.property.map(item => item.value),
                    showBookedOnly: contentType === ContentType.BOOKED,
                    timeZone: filters.timeZone,
                    sportIds: filters.sport.map(item => item.value),
                    rightHolders: filters.rightHolder.map(item => item.value),
                    operatorId: localStorage.getItem('client') as string,
                }
                const payload = generateGetContentPayload(params);
                const response = await client.post(`api/ContentAnalysis/content/analysis/available-content/csv`, payload)
                const url = window.URL.createObjectURL(new Blob([response.data]));

                const link = document.createElement('a');
                link.href = url;
                link.download = 'Available_content.csv';
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url)

            } catch (e) {
                toastr.error("IGAME", "Something went wrong")
            }
        }

        const getLeftBtnStyles = (isActive: boolean) => {
            if (isActive) {
                return `${styles.btnLRadius} ${styles.activeBtn}`
            } else {
                return `${styles.btnLRadius} ${styles.notActive}`
            }
        }

        const getMiddleBtnStyles = (isActive: boolean) => {
            if (isActive) {
                return `${styles.btnMiddle} ${styles.activeBtn}`
            } else {
                return `${styles.btnMiddle} ${styles.notActive}`
            }
        }

        const getRightBtnStyles = (isActive: boolean) => {
            if (isActive) {
                return `${styles.btnRRadius} ${styles.activeBtn}`
            } else {
                return `${styles.btnRRadius} ${styles.notActive}`
            }
        }


        return (
            <>
                <PageHeader
                    menuStructure={menuStructure}
                    users={users}
                    title={"Available Content"}>
                    <div className={styles.csvWrapper}>
                        <button
                            onClick={downloadSCVFile}
                            className={styles.csvBtn}>CSV
                            <img src={download} alt="csv"/>
                        </button>
                    </div>
                </PageHeader>

                <div className={styles.page}>
                    <div className={styles.pageContainer}>
                        <div className={styles.header}>
                            <h1 className={styles.title}>Available Content</h1>
                            <HeaderFilters
                                resetFilters={resetFilters}
                                filterControls={controls.filter(value => value.label !== "TimeZone")}
                                setFilters={onSetFilters}
                                filterValues={filters}/>
                        </div>

                        <div className={styles.favourite}>
                            <p>{(!!contentList.length || !!concurrencyChart.length) && 'Show favourites'}</p>
                            {(!!contentList.length || !!concurrencyChart.length) &&
                                <button
                                    onClick={() => setIsFavourite(!isFavourite)}
                                    className={styles.starButton}>
                                    <FavoriteStar isActive={isFavourite} fontSize={24}/>
                                </button>
                            }
                        </div>

                        <div className={styles.chartsControls}>
                            <div className={styles.leftControls}>
                                <button
                                    onClick={() => setInfoType(InfoType.CHART)}
                                    className={getLeftBtnStyles(infoType === InfoType.CHART)}
                                >
                                    Concurrency Chart
                                </button>
                                <button
                                    onClick={() => setInfoType(InfoType.TABLE)}
                                    className={getRightBtnStyles(infoType === InfoType.TABLE)}
                                >
                                    Content List
                                </button>
                            </div>
                            <div className={styles.rightControls}>
                                <button
                                    onClick={() => setChartDataType(ViewType.DAY)}
                                    className={getLeftBtnStyles(chartDataType === ViewType.DAY)}
                                >
                                    Day View
                                </button>
                                <button
                                    onClick={() => setChartDataType(ViewType.FIVE_DAY)}
                                    className={getMiddleBtnStyles(chartDataType === ViewType.FIVE_DAY)}
                                >
                                    5-Day view
                                </button>
                                <button
                                    onClick={() => setChartDataType(ViewType.MONTH)}
                                    className={getRightBtnStyles(chartDataType === ViewType.MONTH)}
                                >
                                    Month view
                                </button>
                            </div>
                        </div>
                        <div className={styles.chartSection}>
                            {infoType === InfoType.CHART && !!concurrencyChart.length &&
                                <h6 className={styles.leftSideLabel}>Number of Matches</h6>}
                            <div className={styles.sectionHeader}>
                                <h6 className={styles.title}>
                                    {chartDataType === ViewType.DAY && "Day View"}
                                    {chartDataType === ViewType.FIVE_DAY && "5-Day View"}
                                    {chartDataType === ViewType.MONTH && "Month View"}
                                </h6>


                                <div className={styles.headerLabels}>
                                    <div className={`${styles.label} ${styles.primary}`}>
                                        <span>{bookedContentCount}</span><br/>{ContentType.BOOKED}
                                    </div>

                                    {contentType === ContentType.AVAILABLE &&
                                        <div className={`${styles.label} ${styles.secondary}`}>
                                            <span>{availableContentCount}</span><br/>{ContentType.AVAILABLE}
                                        </div>}

                                    {
                                        <div className={styles.typeBtn}>
                                            <p className={styles.typeBtnLabel}>Show Booked only</p>
                                            <Checkbox
                                                onChangeHandler={() => changeContentType(contentType)}
                                                isChecked={contentType === ContentType.BOOKED}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>

                            {
                                isLoading ?
                                    <Loader/>
                                    :
                                    <>
                                        {concurrencyChart?.length ?
                                            <>
                                                {infoType === InfoType.CHART && <div className={styles.chartBody}>
                                                    {contentType === ContentType.BOOKED && chartDataType === ViewType.DAY &&
                                                        concurrencyChart.length > 0 &&
                                                        <FixturesChart
                                                            isStacked={false}
                                                            barColor={['#FFF']}
                                                            chartData={convertToBookedDayView(concurrencyChart)}
                                                        />
                                                    }
                                                    {contentType === ContentType.AVAILABLE && chartDataType === ViewType.DAY &&
                                                        <FixturesChart
                                                            isStacked={true}
                                                            barColor={['#FFF', '#B2B2B2']}
                                                            chartData={convertToBookedAndAvailableDayView(concurrencyChart)}
                                                        />
                                                    }
                                                    {contentType === ContentType.AVAILABLE && chartDataType === ViewType.FIVE_DAY &&
                                                        concurrencyChart.length > 0 &&
                                                        <FixturesChart
                                                            isStacked={true}
                                                            barColor={['#FFF', '#B2B2B2']}
                                                            chartData={transformToFiveDayViewWithAvailable(concurrencyChart)}
                                                        />
                                                    }
                                                    {contentType === ContentType.BOOKED && chartDataType === ViewType.FIVE_DAY &&
                                                        concurrencyChart.length > 0 &&
                                                        <FixturesChart
                                                            isStacked={false}
                                                            barColor={['#FFF']}
                                                            chartData={transformToFiveDayView(concurrencyChart)}
                                                        />
                                                    }
                                                    {contentType === ContentType.BOOKED && chartDataType === ViewType.MONTH &&
                                                        concurrencyChart.length > 0 &&
                                                        <FixturesChart
                                                            isStacked={false}
                                                            barColor={['#FFF']}
                                                            chartData={convertToMonthView(concurrencyChart)}
                                                        />
                                                    }
                                                    {contentType === ContentType.AVAILABLE && chartDataType === ViewType.MONTH &&
                                                        concurrencyChart.length > 0 &&
                                                        <FixturesChart
                                                            isStacked={true}
                                                            barColor={['#FFF', '#B2B2B2']}
                                                            chartData={convertToMonthViewWithAvailable(concurrencyChart)}
                                                        />
                                                    }
                                                </div>
                                                }
                                            </>
                                            :
                                            infoType === InfoType.CHART &&
                                            <div className={styles.tableInner}>
                                                <div className={styles.noDataTemplate}>No data available for these dates
                                                </div>
                                            </div>
                                        }
                                    </>
                            }


                            {infoType === InfoType.TABLE &&
                                <div className={styles.tableInner}>
                                    {contentList.length ?
                                        <AvailableContentTable
                                            data={isFavourite ? favContentList : contentList}
                                            sorting={sorting}
                                            setSorting={setSorting}
                                            renderActionCell={renderActionCell}
                                        />
                                        :
                                        <div className={styles.noDataTemplate}>No data available for these dates</div>
                                    }
                                </div>
                            }
                            {infoType === InfoType.CHART &&
                                !!concurrencyChart.length &&
                                <div className={styles.timezoneBlock}>
                                    <div className={styles.timezoneInput}>
                                        <SelectInput
                                            onChange={(e) => onSetFilters(e, "timeZone")}
                                            menuPlacement={"top"}
                                            maxMenuHeight={321}
                                            options={timeZones}
                                            isMulti={false}
                                            placeholder={"Select timezone"}
                                            value={filters.timeZone}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
;

export default AvailableContent;
