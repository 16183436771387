import React, {useEffect, useMemo, useState} from 'react';
import styles from './index.module.scss'
import SelectInput from "../../../../../components/SelectInput/SelectInput";
import {useAppDispatch, useAppSelector} from "@hooks/hooks";
import {
    createNewPricing,
    getPricingInitOptions,
    getRightsOptionsByOperator, ICreateNewPricingBody
} from "@store/operatorPricing/operatorPricingSlice";
import {formatSelectOptions} from "@helpers/formatSelectOptions";
import {SingleValue} from "react-select";
import {useForm, Controller} from "react-hook-form";
import Input from "../../../../../components/Input/Input";
import {IDropdownOption} from "@components/AnalyticsDashboard/types";


interface IPricingFormProps {
    closeForm: () => void;
    rightHolderId: string | null;
    testSubmitFunction?: (payload: {
        rightHolderId: string | null,
        rightPricingCreateItems: [{
            operatorId: string,
            rightId: string,
            minPPF: number,
            maxPPF: number,
            adHoc: number
        }]
    }) => void;
}

interface IFormInput {
    operator: { label: string; value: string };
    right: { label: string; value: string };
    minPPF: number | string;
    maxPPF: number | string;
    adHoc: number | string;
}

const PricingForm: React.FC<IPricingFormProps> = React.memo(({closeForm, rightHolderId, testSubmitFunction}) => {
    const dispatch = useAppDispatch()
    const [operator, setOperator] = useState<SingleValue<{ value: string, label: string } | undefined>>(undefined);
    const [right, setRight] = useState<SingleValue<{ value: string, label: string } | undefined>>(undefined);
    const [minPricing, setMinPricing] = useState<number | string>('');
    const [maxPricing, setMaxPricing] = useState<number | string>('');
    const [hocPricing, setHocPricing] = useState<number | string>('');
    const {rightsInpOptions, operatorInpOptions} = useAppSelector(state => state.operatorPricing)
    const {handleSubmit, control, formState: {errors}} = useForm<IFormInput>();

    useEffect(() => {
        dispatch(getPricingInitOptions(rightHolderId))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const handleSetOperator = (option: SingleValue<IDropdownOption>, onChange: (...event: any[]) => void) => {
        if (option) {
            dispatch(getRightsOptionsByOperator({rightHolder: rightHolderId, operator: option.value}))
            setOperator(option)
            onChange(option)
        }
    }
    const handleSetRight = (option: SingleValue<IDropdownOption>, onChange: (...event: any[]) => void) => {
        if (option) {
            setRight(option)
            onChange(option)
        }
    }
    const onCreateNewPricing = () => {
        const newPricing = {
            operatorId: operator!.value,
            rightId: right!.value,
            minPPF: minPricing ? Number(minPricing) : 0,
            maxPPF: maxPricing ? Number(maxPricing) : 0,
            adHoc: hocPricing ? Number(hocPricing) : 0,
        };
        const body: ICreateNewPricingBody = {
            payload: {
                rightHolderId,
                rightPricingCreateItems: [newPricing],
            },
            closeForm
        }
        testSubmitFunction && testSubmitFunction({
            rightHolderId,
            rightPricingCreateItems: [newPricing],
        })
        dispatch(createNewPricing(body))
    }

    const formatSelectOperators = useMemo(() => (
        formatSelectOptions(operatorInpOptions)
    ), [operatorInpOptions]);

    const formatSelectRights = useMemo(() => (
        formatSelectOptions(rightsInpOptions)
    ), [rightsInpOptions]);
    return (
        <div className={styles.overlay} data-testid="pricing-form">
            <form onClick={(e) => e.stopPropagation()}
                  onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                  onSubmit={handleSubmit(onCreateNewPricing)}
                  className={styles.form}>
                <h3 className={styles.title}>Create Pricing</h3>
                <div className={styles.content}>
                    <div>
                        <label className={styles.label}>Operator
                            <Controller
                                name="operator"
                                control={control}
                                rules={{required: true}}
                                render={({field: {onChange, value: operator}}) => <SelectInput
                                    value={operator}
                                    onChange={(e) => handleSetOperator(e, onChange)}
                                    options={formatSelectOperators}
                                    placeholder={"Choose an option"}
                                />}
                            />
                            {errors.operator &&
                            <div className={styles.formErrorBlock} data-testid="operator-required-error">Operator is
                                required field</div>}
                        </label>
                    </div>
                    <div>
                        <label className={styles.label}>Right
                            <Controller
                                name="right"
                                control={control}
                                rules={{required: true}}
                                render={({field: {onChange, value: right}}) => <SelectInput
                                    value={right}
                                    onChange={(e) => handleSetRight(e, onChange)}
                                    options={formatSelectRights}
                                    placeholder={"Choose an option"}
                                />}
                            />
                            {errors.right &&
                            <div className={styles.formErrorBlock} data-testid="right-required-error">Right is required
                                field</div>}
                        </label>
                    </div>
                    <div>
                        <label className={styles.label}>Minimum Price Per Fixture (£)
                            <Controller
                                control={control}
                                name="minPPF"
                                rules={{
                                    required: true,
                                    validate: value => Number(value) > 0
                                }}
                                render={({field: {onChange}}) => (
                                    <Input
                                        onChange={(e) => {
                                            onChange(e);
                                            setMinPricing(e.target.value === '' ? '' : Number(e.target.value));
                                        }}
                                        value={minPricing}
                                        type="number"
                                        placeholder={"Enter minimum price..."}
                                    />
                                )}
                            />
                            {errors?.minPPF?.type === "required" &&
                                <div className={styles.formErrorBlock} data-testid="minPPF-required-error">MinPPF is
                                    required field</div>}
                            {errors?.minPPF?.type === "validate" &&
                                <div className={styles.formErrorBlock}
                                     data-testid="minPPF-must-be-positive-error">MinPPF should be positive number</div>}
                        </label>
                    </div>
                    <div>
                        <label className={styles.label}>Maximum Price Per Fixture (£)
                            <Controller
                                control={control}
                                name="maxPPF"
                                rules={{
                                    required: true,
                                    validate: value => Number(value) > 0
                                }}
                                render={({field: {onChange}}) => (
                                    <Input
                                        onChange={(e) => {
                                            onChange(e);
                                            setMaxPricing(e.target.value === '' ? '' : Number(e.target.value));
                                        }}
                                        value={maxPricing}
                                        type="number"
                                        placeholder={"Enter maximum price..."}
                                    />
                                )}
                            />
                            {errors?.maxPPF?.type === "required" &&
                                <div className={styles.formErrorBlock} data-testid="maxPPF-required-error">MaxPPF is
                                    required field</div>}
                            {errors?.maxPPF?.type === "validate" &&
                                <div className={styles.formErrorBlock}
                                     data-testid="maxPPF-must-be-positive-error">MaxPPF should be positive number</div>}
                        </label>
                    </div>
                    <div>
                        <label className={styles.label}>Ad Hoc Pricing (£)
                            <Controller
                                control={control}
                                name="adHoc"
                                rules={{
                                    required: true,
                                    validate: value => Number(value) > 0
                                }}
                                render={({field: {onChange}}) => (
                                    <Input
                                        onChange={(e) => {
                                            onChange(e);
                                            setHocPricing(e.target.value === '' ? '' : Number(e.target.value));
                                        }}
                                        value={hocPricing}
                                        type="number"
                                        placeholder={"Enter Ad Hoc pricing..."}
                                    />
                                )}
                            />
                            {errors?.adHoc?.type === "required" &&
                                <div className={styles.formErrorBlock} data-testid="ad-hoc-required-error">Ad Hoc is
                                    required field</div>}
                            {errors?.adHoc?.type === "validate" &&
                                <div className={styles.formErrorBlock} data-testid="ad-hoc-must-be-positive-error">Ad
                                    Hoc should be positive number</div>}
                        </label>
                    </div>
                </div>
                <div className={styles.buttons}>
                    <button onClick={closeForm} className={styles.cancelButton}>Cancel</button>
                    <button
                        className={styles.submitButton}
                        type="submit">Confirm
                    </button>
                </div>
            </form>
        </div>
    );
});

export default PricingForm;
PricingForm.displayName = "PricingForm"
