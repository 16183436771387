import React from "react";
import styles from "./index.module.scss";
import {IconContext} from "react-icons";
import {MdOutlineSearchOff} from "react-icons/md";

export interface INoDataFoundStubProps {
    textColor?: string;
    message?: string | null;
}

const NoDataFoundStub: React.FunctionComponent<INoDataFoundStubProps> = ({textColor, message}) => {
    return <div data-testid="no-data-found-stub">
        <div className={styles.noDataComponent}>
            <IconContext.Provider value={{color: "#FF3466", size: "70px"}}>
                <MdOutlineSearchOff/>
            </IconContext.Provider>
            <p className={styles.noDataComponentText}
               style={{color: `${textColor}`}}>{message ?? "Sorry! No data found for your request"}</p>
        </div>
    </div>
}

NoDataFoundStub.displayName = "NoDataFoundStub"
export default NoDataFoundStub;
