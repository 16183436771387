import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {client} from "../../services/api-servise";
import {toastr} from "react-redux-toastr";
import {appendDataHelper, prependDataHelper} from "../../helpers/reducerHelpers";

export interface IAuditTrailsRequest {
    auditRecordType: number;
    recordId: string;
    pageNumber: number;
    itemsPerPage: number;
    scrollPosition?: string;
}

export interface IRightsRestrictionsATReq {
    rightHolderId: string;
    competitionId: string;
    pageNumber: number;
    itemsPerPage: number;
    scrollPosition?: string;
}

export interface IAuditTrailsDataResponse {
    action: string;
    affectedColumn: string | null;
    oldValue: string | null;
    newValue: string | null;
    customer?: string;
    type?: string;
    userEmail: string;
    modifiedAt: string;
}

export interface IAuditTrailsState {
    trails: IAuditTrailsDataResponse[];
    loading: boolean;
    totalCount: number;
    trailsItemsPerPage: number;
    trailsPageNumber: number;
    scrollLoader: boolean;
    maxItemsInTheTable: number;
}

const initialState: IAuditTrailsState = {
    trails: [],
    loading: false,
    totalCount: 0,
    trailsItemsPerPage: 15,
    trailsPageNumber: 0,
    scrollLoader: false,
    maxItemsInTheTable: 45,
}

export const getAuditTrails = createAsyncThunk<IAuditTrailsDataResponse[], IAuditTrailsRequest, {
    rejectValue: string
}>(
    'audit-trails/common',
    async ({
               recordId,
               auditRecordType,
               pageNumber,
               itemsPerPage,
               scrollPosition
           }, {rejectWithValue, dispatch}) => {
        try {
            const {
                data,
                headers
            } = await client.post("api/auditTrails", {
                recordId,
                auditRecordType,
                pageNumber,
                itemsPerPage,
            })
            const totalCount = headers['x-total-count']

            if (totalCount) {
                dispatch(setTotalCount(Number(totalCount)));
            }
            if (scrollPosition && scrollPosition === "down") {
                dispatch(appendData(data));
                return;
            }
            if (scrollPosition && scrollPosition === "up") {
                dispatch(prependData(data));
                return;
            }
            return data;
        } catch (e: any) {
            toastr.error('Error!', `${e.response.data}`)
            return rejectWithValue(e.response.data)
        }
    }
)

export const getRightsRestrictionsTrails = createAsyncThunk<IAuditTrailsDataResponse[], IRightsRestrictionsATReq, {
    rejectValue: string
}>(
    'audit-trails/rights-restrictions-trails',
    async ({
               itemsPerPage,
               pageNumber,
               scrollPosition,
               rightHolderId,
               competitionId
           }, {rejectWithValue, dispatch}) => {
        try {
            const {
                data,
                headers
            } = await client.post("api/auditTrails/restriction", {
                itemsPerPage,
                pageNumber,
                rightHolderId,
                competitionId
            })
            const totalCount = headers['x-total-count']

            if (totalCount) {
                dispatch(setTotalCount(Number(totalCount)));
            }
            if (scrollPosition && scrollPosition === "down") {
                dispatch(appendData(data));
                return;
            }
            if (scrollPosition && scrollPosition === "up") {
                dispatch(prependData(data));
                return;
            }
            return data;
        } catch (e: any) {
            toastr.error('Error!', `${e.response.data}`)
            return rejectWithValue(e.response.data)
        }
    }
)

export const auditTrailsCommonSlice = createSlice({
    name: 'audit-trails-bookings',
    initialState,
    reducers: {
        cleanAuditTrailsList: (state) => {
            state.trails = [];
        },
        setPageNumber: (state, action: PayloadAction<number>) => {
            state.trailsPageNumber = action.payload
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload
        },
        appendData(state, action) {
            appendDataHelper(state, action.payload, 'trails');
        },
        prependData(state, action) {
            prependDataHelper(state, action.payload, 'trails');
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAuditTrails.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAuditTrails.fulfilled, (state, action) => {
                if (action.payload !== undefined) {
                    state.trails = action.payload;
                    state.trailsPageNumber = 1;
                }
                state.loading = false;
            })
            .addCase(getAuditTrails.rejected, (state) => {
                state.loading = false;
            })

            .addCase(getRightsRestrictionsTrails.pending, (state) => {
                state.loading = true;
            })
            .addCase(getRightsRestrictionsTrails.fulfilled, (state, action) => {
                if (action.payload !== undefined) {
                    state.trails = action.payload;
                    state.trailsPageNumber = 1;
                }
                state.loading = false;
            })
            .addCase(getRightsRestrictionsTrails.rejected, (state) => {
                state.loading = false;
            })
    },
})

export const {
    cleanAuditTrailsList,
    setTotalCount,
    setPageNumber,
    appendData,
    prependData
} = auditTrailsCommonSlice.actions
export default auditTrailsCommonSlice.reducer
