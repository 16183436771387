import React from 'react';
import styles from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/children/IngestForm/index.module.scss";
import UploadComponent
    from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/children/UploadComponent/UploadComponent";
import uuid from "react-uuid";
import IngestValidate
    from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/children/IngestForm/helpers/IngestValidate";
import {toastr} from "react-redux-toastr";
import {useAppSelector} from "@hooks/hooks";
import {RootState} from "@store/index";
import {IUploadedFiles} from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/children/IngestForm/types/types";
import IngestRender
    from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/children/IngestForm/helpers/IngestRender";
import {IngestTabs} from "@enums/ingest";
import {FieldValues, UseFormGetValues} from "react-hook-form";

interface IIngestFileUploader {
    setUploadedFiles: React.Dispatch<React.SetStateAction<IUploadedFiles>>
    uploadedFiles: IUploadedFiles;
    currentTabState: IngestTabs;
    getValues: UseFormGetValues<FieldValues>
}

const IngestFileUploader= ({setUploadedFiles, uploadedFiles, currentTabState, getValues}: IIngestFileUploader) => {
    const {details, backupDetails, isActivateBackup} = useAppSelector((state: RootState) => state.rightHolderBooking);

    const supportedFileTypes = ['.eml', '.txt', '.rtf', '.olm', '.msg'];


    const handleUploadingOnClick = (e: React.DragEvent<HTMLInputElement>) => {
        e.preventDefault();
        const input = e.target as HTMLInputElement;
        if (!input.files) {
            return;
        }

        const addedFiles = Object.values(input.files);

        if (IngestValidate.detectInvalidFileType(addedFiles, supportedFileTypes)) {
            return toastr.error('Invalid file type', `There are available file types: ${supportedFileTypes.map(file => file).join(', ')}`);
        }

        if (isActivateBackup) {
            return setUploadedFiles({...uploadedFiles, backUpFiles: uploadedFiles.backUpFiles.concat(addedFiles)});
        }

        setUploadedFiles({...uploadedFiles, primaryFiles: uploadedFiles.primaryFiles.concat(addedFiles)});

    };

    const IngestFileUploaderJSX = (
        <div>
            <div className={styles.uploadInner}>
                <UploadComponent
                    handleUploadingOnClick={handleUploadingOnClick}
                    title={isActivateBackup ? "BT Tower Backup" : "BT Tower"}
                />
            </div>

            {uploadedFiles.primaryFiles.length ?
                <div className={styles.uploadInner}> Uploaded files:
                    {uploadedFiles.primaryFiles.map(file => (
                        <div className={styles.file}
                             key={uuid()}>{file.name}</div>
                    ))}
                </div>
                :
                null
            }

            {uploadedFiles.backUpFiles.length ?
                <div className={styles.uploadInner}> Uploaded files:
                    {uploadedFiles.backUpFiles.map(file => (
                        <div className={styles.file}
                             key={uuid()}>{file.name}</div>
                    ))}
                </div>
                :
                null
            }

            {details.btTower &&
                <>
                    {details.btTower.filesUrls.map(file => (
                        <button
                            className={`${styles.file} ${styles.fileUrl}`}
                            key={uuid()}
                            onClick={() => window?.open(file, '_blank')}
                        >
                            {file}
                        </button>
                    ))}
                </>
            }
            {backupDetails.btTowerFixture &&
                <>
                    {backupDetails.btTowerFixture.filesUrls.map(file => (
                        <button
                            className={`${styles.file} ${styles.fileUrl}`}
                            key={uuid()}
                            onClick={() => window?.open(file, '_blank')}
                        >
                            {file}
                        </button>
                    ))}
                </>
            }
        </div>
    )
    return (IngestRender.FileUploader(IngestFileUploaderJSX, currentTabState, getValues))
};

export default IngestFileUploader;