import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {toastr} from "react-redux-toastr";
import {client} from "../../services/api-servise";
import {AxiosError} from "axios";
import {IInputOption} from "../operatorAccess/operatorAccessSlice";
import {formClient} from "../../services/multiPartForm-service";
import {ISelectItem} from "../userManagement/userManagementSlice";

export interface IReport {
    date: string,
    lineUp: string,
    startUtc: string,
    sport: string,
    competition: string,
    fixture: string,
    isVerified: boolean,
    setup: boolean,
    isSatelite: boolean,
    fixtureId: string,
    rightHolderId: string
    ingestType: number
}

export interface IGetReportsResponse {
    fixtureReportItems: IReport[]
    satellites: {
        items: IInputOption[]
    }
}

export interface IReportItem {
    id: number,
    fault: string,
    description: string,
    createdDate: string
}

export interface IReportFormResponse {
    rightHolder: string,
    sport: string,
    competition: string,
    technicalProvider: string,
    reportItems: IReportItem[],
    fixtureEndDate: string,
    faults: {
        items: IInputOption[]
    },
    affectedPlatforms: {
        items: IInputOption[]
    }
}

export interface IMainReportInfo {
    rightHolder: string,
    sport: string,
    competition: string,
    technicalProvider: string,
    fixtureEndDate: string
}

export interface IReportFormInitState {
    mainReportInfo: IMainReportInfo
    faults: IInputOption[]
    affectedPlatforms: IInputOption[]
    details: IInputOption[]
}

export interface ISatelliteDetails {
    fixtureId: string
    startDate: string
    endDate: string
    satelliteId: string
    bissCode: string
    symbolRate: number
    downloadFrequency: number
    downloadPolarity: string
    audio: string
    fileName: string
    fileUrl: string
}

export interface IBTTowerDetails {
    fixtureId: string,
    source: string
}

export interface IIPDetails {
    fixtureId: string,
    channelId: number
}

export interface IIngestDetailInitResponse {
    rightHolderId: number,
    rightHolder: string,
    sportName: string,
    technicalProvider: string,
    ingestMethod: "BTTower" | "IP" | "Satellite" | ""
    ingetsTypes: {
        items: IInputOption[]
    },
    attachments: {
        items: IInputOption[]
    },
    satellite: ISatelliteDetails | null
    btTower: IBTTowerDetails | null
    ip: IIPDetails | null
}

export interface IPChannel {
    id: number
    name: string
    type: number
    server: string
    rightHolderId: number
}

export interface IReportingState {
    reports: IReport[]
    IPChannels: IPChannel[]
    reportsDetails: IReportDetail[]
    satellites: {
        items: IInputOption[]
    },
    reportFormInit: IReportFormInitState
    ingestFormInit: IIngestDetailInitResponse
    streamTypeOptions: ISelectItem[]
}

export interface IReportDetail {
    id: number,
    description: string,
    reportTime: string,
    fault: string,
    isConfirmed: boolean
    affectedPlatform: 0,
    issueStartTime: string,
    issueEndTime: string,
}

export interface IFixtureDetailBody {
    fixtureId: string,
    isVerified: boolean | null,
    isSetup: boolean | null,
}

export interface IUpdateBTTowerBody {
    rightHolderId: number,
    fixtureId: string,
    fixture: {
        fixtureId: string,
        source: string
    }
}

export interface IUpdateIPBody {
    rightHolderId: number,
    fixtureId: string,
    fixture: {
        fixtureId: string,
        channelId: number
    }
}

export interface IUpdateSatelliteBody {
    fixtureId: string,
    fixture: {
        fixtureId: string,
        startDate: string
        endDate: string
        satelliteId: string,
        bissCode: string
        bissType: number,
        nS3_NS4: boolean | undefined;
        symbolRate: number,
        downloadFrequency: number,
        downloadPolarity: string
        audio: string
    }
}

export interface IUpdatedReportDetails {
    id: number | string
    fixtureId: string,
    fault: number,
    description: string,
    issueStartTime: string,
    issueEndTime: string,
    affectedPlatform: number,
    streamType: number,
    unresolved: boolean
}

export interface ICreatedReport {
    fixtureId: string
    fault: number,
    description: string,
    issueStartTime: string,
    issueEndTime: string,
    affectedPlatform: number,
    streamType: number,
    unresolved: boolean,
    severity: number,
    detail: number,
}

interface IGeneralIssueDetails {
    items: IInputOption[]
}

const initialState: IReportingState = {
    reports: [],
    reportsDetails: [],
    IPChannels: [],
    satellites: {
        items: []
    },
    reportFormInit: {
        affectedPlatforms: [],
        faults: [],
        details: [],
        mainReportInfo: {
            technicalProvider: "",
            rightHolder: "",
            sport: "",
            competition: "",
            fixtureEndDate: "",
        }
    },
    ingestFormInit: {
        rightHolderId: 0,
        rightHolder: "",
        sportName: "",
        technicalProvider: "",
        ingestMethod: "",
        ingetsTypes: {
            items: []
        },
        attachments: {
            items: []
        },
        btTower: null,
        ip: null,
        satellite: null

    },
    streamTypeOptions: []
}

export const getReportFixtureDetails = createAsyncThunk<IGetReportsResponse, {
    startDate: string | undefined,
    endDate: string | undefined
}, { rejectValue: string }>(
    'reporting/get-reports',
    async ({endDate, startDate}, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Reports/Fixture-details?startDate=${startDate}&endDate=${endDate}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getIPChannels = createAsyncThunk<IPChannel[], string, { rejectValue: string }>(
    'reporting/get-IP-channels',
    async (rightHolderId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/IngestResources/fixture/qAU8I8Do8kKlWJyiJZAHww`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getReportFormInit = createAsyncThunk<IReportFormResponse, string, { rejectValue: string }>(
    'reporting/get-reports-form-init',
    async (fixtureId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`/api/Reports/init?fixtureId=${fixtureId}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getIssueDetails = createAsyncThunk<IGeneralIssueDetails, number, { rejectValue: string }>(
    'reporting/get-issue-details',
    async (issueSource, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`/api/Reports/issue-source/${issueSource}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getStreamTypeOptions = createAsyncThunk<ISelectItem[], string, { rejectValue: string }>(
    'reporting/get-stream-type-options',
    async (fixtureId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`/api/Reports/stream-type-options?fixtureId=${fixtureId}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getIngestDetail = createAsyncThunk<IIngestDetailInitResponse, string, { rejectValue: string }>(
    'reporting/get-ingest-form-init',
    async (fixtureId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`/api/Reports/ingest-details?fixtureId=${fixtureId}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const sendIngest = createAsyncThunk<any, FormData, { rejectValue: string }>(
    'reporting/send-ingest',
    async (ingest, {rejectWithValue}) => {
        try {
            const {data} = await formClient.post(`/api/Reports/upload`, ingest);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const updateBTTower = createAsyncThunk<any, IUpdateBTTowerBody, { rejectValue: string }>(
    'reporting/update-BTTower',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`api/Fixtures/right-holder/ingest/bt-tower`, body);
            toastr.success('IGame', "BTTower details has been updated")
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const updateIP = createAsyncThunk<any, IUpdateIPBody, { rejectValue: string }>(
    'reporting/update-IP',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`api/Fixtures/right-holder/ingest/ip`, body);
            toastr.success('IGame', "IP details has been updated")
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const updateSatellite = createAsyncThunk<any, IUpdateSatelliteBody, { rejectValue: string }>(
    'reporting/update-satellite',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`api/Fixtures/right-holder/ingest/satellite`, body);
            toastr.success('IGame', "Satellite details has been updated")
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)


export const createReports = createAsyncThunk<IReportDetail, ICreatedReport, { rejectValue: string }>(
    'reporting/update-report',
    async (report, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.post(`/api/Reports`, report);
            dispatch(getReportsDetails(report.fixtureId))
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const updateReport = createAsyncThunk<IReportDetail, IUpdatedReportDetails, { rejectValue: string }>(
    'reporting/update-report',
    async (report, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`/api/Reports`, report);
            toastr.success('IGame', `Report  has been updated`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const deleteReport = createAsyncThunk<null, number, { rejectValue: string }>(
    'reporting/delete-report',
    async (reportId, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`api/Reports?reportId=${reportId}`);
            toastr.success('IGame', `Report  has been deleted`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)


export const sendSatelliteFile = createAsyncThunk<any, FormData, { rejectValue: string }>(
    'rightHolderBooking/send-files',
    async (file, {rejectWithValue}) => {
        try {
            const {data} = await formClient.post(`api/Fixtures/right-holder/satellite/upload-file`, file);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getReportsDetails = createAsyncThunk<IReportDetail[], string, { rejectValue: string }>(
    'rightHolderBooking/send-files',
    async (fixtureId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Reports/?fixtureId=${fixtureId}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)


export const iGameReportingSlice = createSlice({
    name: 'iGame-reporting',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getReportFixtureDetails.fulfilled, (state, action) => {
                state.reports = action.payload.fixtureReportItems
                state.satellites.items = action.payload.satellites.items
            })
            .addCase(getStreamTypeOptions.fulfilled, (state, action) => {
                state.streamTypeOptions = action.payload;
            })
            .addCase(getIPChannels.fulfilled, (state, action) => {
                state.IPChannels = action.payload
            })
            .addCase(getReportsDetails.fulfilled, (state, action) => {
                state.reportsDetails = action.payload
            })
            .addCase(updateReport.fulfilled, (state, action) => {
                const updatedReport = action.payload
                state.reportsDetails = state.reportsDetails.map(report => report.id === updatedReport.id ? updatedReport : report)

            })
            .addCase(deleteReport.fulfilled, (state, action) => {
                state.reportsDetails = state.reportsDetails.filter(report => report.id !== action.meta.arg)
            })
            .addCase(getReportFormInit.fulfilled, (state, action) => {
                const {technicalProvider, rightHolder, competition, sport, fixtureEndDate} = action.payload
                state.reportFormInit.mainReportInfo = {
                    technicalProvider,
                    sport,
                    rightHolder,
                    competition,
                    fixtureEndDate
                }
                state.reportFormInit.faults = action.payload.faults.items
                state.reportFormInit.affectedPlatforms = action.payload.affectedPlatforms.items
            })
            .addCase(getIngestDetail.fulfilled, (state, action) => {
                state.ingestFormInit = action.payload
            })
            .addCase(getIssueDetails.fulfilled, (state, action) => {
                state.reportFormInit.details = action.payload.items;
            })

    },
})

export default iGameReportingSlice.reducer
