import React, {useRef} from 'react';
import styles from "./index.module.scss";
import {DateTime} from "luxon";
import AppTableWhite from "../../../AppTableWhite/AppTableWhite";
import {AppInfiniteScroll} from "../../../AppInfiniteScroll/AppInfiniteScroll";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {
    getAuditTrails,
    getRightsRestrictionsTrails,
    setPageNumber
} from "../../../../store/auditTrailsCommon/auditTrailsCommonSlice";

interface IAuditTrailsTableProps {
    extended?: boolean;
    height?: string;
    type?: boolean;
    auditRecordType?: number;
    recordId?: string;
    rightHolderId?: string;
    competitionId?: string;
}

const AuditTrailsTable: React.FC<IAuditTrailsTableProps> = ({
                                                                extended,
                                                                height,
                                                                type,
                                                                auditRecordType,
                                                                recordId,
                                                                rightHolderId,
                                                                competitionId
                                                            }) => {
    const dispatch = useAppDispatch();
    const containerRef = useRef<HTMLDivElement>(null);
    const {
        trails,
        trailsPageNumber,
        trailsItemsPerPage,
        totalCount,
        scrollLoader,
        maxItemsInTheTable,
    } = useAppSelector(state => state.auditTrailsCommon);

    const fetchData = (page: number, pageSize: number, scrollPosition: string) => {
        if (auditRecordType && recordId) {
            dispatch(getAuditTrails({
                auditRecordType,
                recordId,
                pageNumber: page,
                itemsPerPage: pageSize,
                scrollPosition
            }));
        }
        if (rightHolderId && competitionId) {
            dispatch(getRightsRestrictionsTrails({
                rightHolderId,
                competitionId,
                pageNumber: page,
                itemsPerPage: pageSize,
                scrollPosition
            }))
        }
    }

    const setPage = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber));
    }

    return (
        <div className={styles.tableContainer}>
            <div className={styles.tableWrapper} style={{height}}>
                <AppInfiniteScroll
                    containerRef={containerRef}
                    fetchData={fetchData}
                    pageSize={trailsItemsPerPage}
                    totalItems={totalCount}
                    currentPage={trailsPageNumber}
                    setPage={setPage}
                    isLoading={scrollLoader}
                    scrollingContainerHeight={height ?? "40vh"}
                    scrollBounce={200}
                    maxItemsInTheTable={maxItemsInTheTable}
                    tableDataLength={trails.length}>
                    <AppTableWhite>
                        <thead>
                        <tr>
                            <th>Action</th>
                            {
                                type &&
                                <th>Type</th>
                            }
                            <th>Affected Column</th>
                            {
                                extended &&
                                <th>Customer</th>
                            }
                            <th>Old Value</th>
                            <th>New Value</th>
                            <th>User</th>
                            <th>Modified</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            trails.length
                                ?
                                trails.map((item: any, index) => (
                                    <tr key={index}>
                                        <td>{item.action}</td>
                                        {
                                            type &&
                                            <td>{item.type}</td>
                                        }
                                        <td>{item.affectedColumn}</td>
                                        {
                                            extended &&
                                            <td>{item.customer}</td>
                                        }
                                        <td className={styles.cellLimited}>{item.oldValue}</td>
                                        <td className={styles.cellLimited}>{item.newValue}</td>
                                        <td>{item.userEmail}</td>
                                        <td>{DateTime.fromISO(item.modifiedAt, {zone: "utc"}).toLocal().toFormat("yyyy/MM/dd HH:mm")}</td>
                                    </tr>
                                ))
                                :
                                <tr>
                                    <td className={styles.noSpaceCell}>
                                        No data found
                                    </td>
                                </tr>
                        }
                        </tbody>
                    </AppTableWhite>
                </AppInfiniteScroll>
            </div>
        </div>
    );
};

AuditTrailsTable.displayName = "AuditTrailsTable"
export default AuditTrailsTable;
