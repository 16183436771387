interface IAppPages {
    [key: number]: string;
}

export const appPages: IAppPages = {
    1: "rightHolder-book-fixtures",
    2: "rightHolder-upcoming-fixtures",
    3: "right-holder-geo-blocked",
    4: "right-holder-geo-allowed",
    5: "rightHolder-analytics",
    6: "rightHolder-billing-operator",
    7: "rightHolder-settings",
    8: "rightHolder-operator-access",
    9: "rightHolder-pricing",
    10: "operator-additional-fixtures",
    11: "operator-upcoming-fixtures",
    13: "operator-analytics",
    14: "operator-billing",
    15: "operator-settings",
    16: "available-content",
    17: "favourite-content",
    18: "igame-billing",
    19: "igame-analytics",
    20: "user-management",
    21: "igame-operators",
    22: "igame-rightHolders",
    23: "publishing",
    24: "rights-assignment",
    25: "rightHolder-billing-igame",
    26: "Reporting",
    27: "technicalProvider-upcoming-fixtures",
    28: "IngestResource",
    29: "audit-trails",
    30: "reporting-issues",
    31: "operator-portfolio",
    32: "rightHolder-cart",
    33: "operator-rights-holders",
    34: "operator-rights-holders-portfolio",
    35: "operator-booked-content",
    36: "property-detail",
    37: "operator-cart",
    38: "book-fixture-cart",
    40: "rh-reporting"
};

export const pagesAllowedByFrontEnd = [31, 32, 33, 34, 35, 36, 37, 38, 40];
