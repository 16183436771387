import React from 'react';
import styles from './index.module.scss';
import DatePicker from "../../DatePicker/DatePicker";
import {
    IDate
} from "../../../pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/children/AddFixtureForm/AddFixtureForm";


interface IBillingCalendar {
    setStartDate: (arg: IDate) => void,
    startDate: IDate,
    setEndDate: (arg: IDate) => void,
    endDate: IDate,
    isValidEndDate: boolean
}

const BillingCalendar: React.FC<IBillingCalendar> = React.memo((
    {
        setStartDate,
        startDate,
        setEndDate,
        endDate,
        isValidEndDate
    }) => {
    return (
        <div className={styles.calendarWrapper}>
            <div className={styles.calendar}>Start Time and Date
                <div className={styles.calendarInputWrapper}>
                    <DatePicker
                        onChange={(date) => {
                            setStartDate(date);
                        }}
                        initValue={startDate}
                    />
                </div>
            </div>

            <div className={styles.calendar}>End Time and Date
                <div className={styles.calendarInputWrapper}>
                    <DatePicker
                        onChange={date => {
                            setEndDate(date);
                        }}
                        modalPositioningClass={'calendar-modal-down-left'}
                        initValue={endDate}
                    />
                </div>
                {
                    !isValidEndDate &&
                    <p className={styles.error}>End date can't be earlier than start date</p>
                }
            </div>
        </div>
    );
});

export default BillingCalendar;
