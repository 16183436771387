import {useEffect, useState} from "react";


export const useControlAdditionalForms = (ingestMethod: string | null | undefined) => {
    const [additionalIsOpenFormsState, setAdditionalIsOpenFormsState] = useState({
        BTTower: false,
        Satellite: false,
        IP: false
    });
    const setIOpenAdditionalForm = (formName: "BTTower" | "IP" | "Satellite", isOpen: boolean) => {
        setAdditionalIsOpenFormsState({...additionalIsOpenFormsState, [formName]: isOpen})
    }
    const setNewFormState = () => {
        const formState = {
            BTTower: false,
            Satellite: false,
            IP: false
        }
        if (ingestMethod) {
            // @ts-ignore
            formState[ingestMethod] = true
        }
        return formState
    }
    useEffect(() => {
        setAdditionalIsOpenFormsState(setNewFormState())
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ingestMethod]);

    return {additionalIsOpenFormsState, setIOpenAdditionalForm}
}
