import {useState} from "react";
import {ISortingField} from "../children/Sorting/Sorting";

export enum usersSorting {
    EMAIL = "email",
    LAST_LOGIN_DATE = "lastLoginDate",
    ROLE_NAME = "roleName",
    TECHNICAL_PROVIDER = "technicalProvider",
}

export const useUsersSorting = (sortUsers: (field: string, isAscending: boolean) => void) => {
    const [sortingState, setSortingState] = useState<ISortingField[]>([
        {field: usersSorting.EMAIL, isAscending: true},
        {field: usersSorting.LAST_LOGIN_DATE, isAscending: false},
        {field: usersSorting.ROLE_NAME, isAscending: true},
        {field: usersSorting.TECHNICAL_PROVIDER, isAscending: true},
    ]);

    const onSortUsers = (field: string) => {
        const newSortingState = sortingState.map(state => {
            if (state.field === field) {
                state.isAscending = !state.isAscending;
            }
            return state
        })

        setSortingState(newSortingState);

        let currentDirection = newSortingState.find(state => state.field === field);
        sortUsers(field, currentDirection!.isAscending);
    }

    return {
        onSortUsers,
        sortingState
    }
}
