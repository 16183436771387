import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import SelectInput from "../../../../../components/SelectInput/SelectInput";
import Input from "../../../../../components/Input/Input";
import styles from './style.module.scss'
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {MultiValue, SingleValue} from "react-select";
import {formatSelectOptions} from "../../../../../helpers/formatSelectOptions";
import {
    updateConfirmedAssignment,
} from "../../../../../store/rightAssignment/rightAssignmentSlice";
import LogoUploader from "../../../../../components/LogoUploader/LogoUploader";
import UploadedLogoViewer from "../../../../../components/UploadedLogoViewer/UploadedLogoViewer";
import {IDate} from "../../../RightsHolderView/ScheduleIngest/BookFixtures/children/AddFixtureForm/AddFixtureForm";
import {getPickerDateFormat} from "../../../../../helpers/dateFormatters";
import DatePicker from "../../../../../components/DatePicker/DatePicker";
import {
    formatDate
} from "../../../RightsHolderView/ScheduleIngest/BookFixtures/children/SatelliteForm/helpers/formatDate";
import {validateDates} from "../helpers/validateDates";

interface IFormInput {
    rightHolder: SingleValue<{ label: string; value: string }>
    sports: SingleValue<{ label: string; value: string }>
    rights: SingleValue<{ label: string; value: string }>
    dataSource: SingleValue<{ label: string; value: string }>
    providers: MultiValue<{ label: string; value: string }>,
    onePlatformPropertyName?: string
    fixtures: number | string
    providerEmail: string
    startDate: IDate
    endDate: IDate
}

interface IUpdateExistingAssignmentFormProps {
    closeForm: () => void;
    updatingExistingAssignment: { rightHolder: string, id: number };
    setUpdatingExistingAssignment: Dispatch<SetStateAction<{ rightHolder: string, id: number }>>;
}

const UpdateExistingAssignmentForm: React.FC<IUpdateExistingAssignmentFormProps> = (
    {
        closeForm,
        updatingExistingAssignment,
        setUpdatingExistingAssignment
    }) => {
    const dispatch = useAppDispatch()
    const {
        initOptions: {competitions, rightHolders, sports},
        confirmedAssignment
    } = useAppSelector(state => state.rightAssignment)
    const {handleSubmit, control, setValue, formState: {errors}, setError} = useForm<IFormInput>();
    const [logo, setLogo] = useState("");
    const [editingLogo, setEditingLogo] = useState("");
    const [startDate, setStartDate] = useState<IDate | null>(null);
    const [endDate, setEndDate] = useState<IDate | null>(null);

    useEffect(() => {
        if (updatingExistingAssignment) {
            const currentRightHolderData = confirmedAssignment.find(assignment => assignment.provider === updatingExistingAssignment.rightHolder);
            if (currentRightHolderData) {
                const assignment = currentRightHolderData.rightHolderAssignments.find(assignment => assignment.id === updatingExistingAssignment.id);

                if (assignment) {
                    const rightHolder = formatSelectOptions(rightHolders).find(rightHolder => rightHolder.value === assignment.rightHolderId)
                    setEditingLogo(assignment.logo)
                    if (rightHolder && assignment.competitionId) {
                        setValue("rightHolder", rightHolder);
                        setValue("rights", {value: assignment.competitionId, label: assignment.competitionName});
                        setValue("sports", {value: assignment.sportId, label: assignment.sportName});
                        if (assignment.onePlatformPropertyName) {
                            setValue("onePlatformPropertyName", assignment.onePlatformPropertyName);
                        }
                    }
                    setValue("fixtures", assignment.availableFixtures)
                    if (assignment.startDate) {
                        setStartDate(getPickerDateFormat(assignment.startDate))
                    }
                    if (assignment.endDate) {
                        setEndDate(getPickerDateFormat(assignment.endDate))
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatingExistingAssignment]);

    const onUpdateAssignment = (assignment: IFormInput) => {
        if (validateDates(assignment, setError)) {

            const payload = {
                id: updatingExistingAssignment.id,
                availableFixtures: Number(assignment.fixtures),
            }
            const formData = new FormData();
            formData.append('id', payload.id.toString());
            formData.append('availableFixtures', payload.availableFixtures.toString());
            formData.append('startDate', formatDate(assignment.startDate));
            formData.append('endDate', formatDate(assignment.endDate));
            if (logo) {
                formData.append('logo', logo);
            }
            dispatch(updateConfirmedAssignment(formData));
            setUpdatingExistingAssignment({rightHolder: "", id: 0});
            closeForm();
        }
    }

    const onCloseForm = () => {
        setUpdatingExistingAssignment({rightHolder: "", id: 0});
        closeForm();
    }

    return (
        <div className={styles.overlay}>
            <form
                onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                onClick={(e) => e.stopPropagation()}
                onSubmit={handleSubmit(onUpdateAssignment)}
                className={styles.form}>
                <div className={styles.content}>
                    <h6 className={styles.title}>Editing Assignment</h6>
                    <div className={styles.formContent}>
                        <div className={styles.dateInputSection}>
                            <div className={styles.calendar}>Start Time and Date
                                <div className={styles.calendarInputWrapper}>
                                    <Controller
                                        control={control}
                                        name="startDate"
                                        rules={{required: true}}
                                        render={({
                                                     field: {onChange},
                                                 }) => (
                                            <DatePicker
                                                onChange={date => {
                                                    onChange(date)
                                                }}
                                                initValue={startDate}
                                                modalPositioningClass="calendar-modal-down"
                                            />
                                        )}
                                    />
                                </div>
                                {
                                    errors.startDate && errors.startDate.type === "required"
                                    && <span className={styles.formErrorBlock}>Start Date is required</span>
                                }
                                {
                                    errors.startDate && errors.startDate.type === "date"
                                    && <span className={styles.formErrorBlock}>{errors.startDate.message}</span>
                                }

                            </div>
                            <div className={styles.calendar}>End Time and Date
                                <div className={styles.calendarInputWrapper}>
                                    <Controller
                                        control={control}
                                        name="endDate"
                                        rules={{required: true}}
                                        render={({
                                                     field: {onChange},
                                                 }) => (
                                            <DatePicker
                                                onChange={date => {
                                                    onChange(date)
                                                }}
                                                initValue={endDate}
                                                modalPositioningClass="calendar-modal-down"
                                            />
                                        )}
                                    />
                                </div>
                                {
                                    errors.endDate && errors.endDate.type === "required"
                                    && <span className={styles.formErrorBlock} data-testid={"endDateRequiredError"}>
                                        End date is required
                                    </span>
                                }
                                {
                                    errors.endDate?.type === "date"
                                    && <span
                                        className={styles.formErrorBlock}>{"End date can't be earlier than start date"}
                                    </span>
                                }
                            </div>
                        </div>
                        <div className={styles.inputInner}>
                            <label className={styles.label}>Right Holder
                                <Controller
                                    name="rightHolder"
                                    defaultValue={null}
                                    control={control}
                                    render={({field: {value}}) =>
                                        <SelectInput
                                            disabled={true}
                                            value={value}
                                            options={formatSelectOptions(rightHolders)}
                                        />}
                                />
                            </label>
                        </div>
                        <div className={styles.inputInner}>
                            <label className={styles.label}>Sport
                                <Controller
                                    name="sports"
                                    defaultValue={null}
                                    control={control}
                                    render={({field: {value}}) =>
                                        <SelectInput
                                            disabled={true}
                                            value={value}
                                            options={formatSelectOptions(sports)}
                                        />}
                                />
                            </label>
                            {errors.rights &&
                                <div className={styles.formErrorBlock}>Sport is required field</div>
                            }
                        </div>
                        <div className={styles.inputInner}>
                            <label className={styles.label}>Rights
                                <Controller
                                    name="rights"
                                    defaultValue={null}
                                    control={control}
                                    render={({field: {value}}) =>
                                        <SelectInput
                                            disabled={true}
                                            value={value}
                                            options={formatSelectOptions(competitions)}
                                        />}
                                />
                            </label>
                        </div>
                        <div>
                            <div className={styles.inputInner}>
                                <label className={styles.label}>ONEPlatform Property Name
                                    <Controller
                                        control={control}
                                        defaultValue={""}
                                        name="onePlatformPropertyName"
                                        render={({field: {value}}) => (
                                            <Input
                                                disabled={true}
                                                value={value}
                                                type="text"
                                            />
                                        )}
                                    />
                                </label>
                            </div>
                        </div>
                        <div>
                            <div className={styles.inputInner}>
                                <label className={styles.label}>Available Fixtures
                                    <Controller
                                        control={control}
                                        defaultValue={""}
                                        name="fixtures"
                                        rules={{
                                            max: 50000,
                                            required: true,
                                            validate: value => Number(value) > 0 || Number(value) === 0
                                        }}
                                        render={({field: {onChange, value}}) => (
                                            <Input
                                                onChange={(e) => {
                                                    onChange(e)
                                                }}
                                                value={value}
                                                type="number"
                                                placeholder={"Number of fixtures"}
                                            />
                                        )}
                                    />
                                    {errors?.fixtures?.type === "required" &&
                                        <div className={styles.formErrorBlock}>
                                            Number of fixtures is required field
                                        </div>
                                    }
                                    {errors?.fixtures?.type === "validate" &&
                                        <div className={styles.formErrorBlock}>
                                            Number of fixtures should be positive
                                        </div>
                                    }
                                    {errors?.fixtures?.type === "max" &&
                                        <div className={styles.formErrorBlock}>
                                            Number of fixtures could not be bigger than 50000
                                        </div>}
                                </label>
                            </div>
                        </div>
                    </div>
                    <LogoUploader setLogo={setLogo}/>
                    <UploadedLogoViewer isEditMode={true} logo={editingLogo}/>
                </div>
                <div className={styles.buttons}>
                    <button
                        onClick={onCloseForm}
                        className={styles.cancelButton}>Cancel
                    </button>
                    <button
                        className={styles.submitButton}
                        type="submit">
                        Update
                    </button>
                </div>
            </form>
        </div>
    );
};

UpdateExistingAssignmentForm.displayName = "UpdateExistingAssignmentForm"
export default UpdateExistingAssignmentForm;
