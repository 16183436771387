import {IFormInput} from "../RightsAssignmentForm/RightAssignmentForm";
import {DateTime} from "luxon";
import {
    formatDate
} from "../../../RightsHolderView/ScheduleIngest/BookFixtures/children/SatelliteForm/helpers/formatDate";
import {ErrorOption} from "react-hook-form";

export const validateDates = (
    assignment: IFormInput,
    setError: (name: keyof IFormInput, error: ErrorOption, options?: { shouldFocus: boolean }) => void
) => {
    const startDate = DateTime.fromISO(formatDate(assignment.startDate));
    const endDate = DateTime.fromISO(formatDate(assignment.endDate));
    const now = DateTime.now();

    if (now > startDate) {
        setError('startDate', {type: 'date', message: "Date can't be set earlier than current date"});
        return false;
    }

    if (startDate > endDate) {
        setError('endDate', {type: 'date', message: "End date can't be earlier than start date"});
        return false;
    }
    return true;
};