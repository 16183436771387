import {AnyAction, createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {toastr} from "react-redux-toastr";
import {client} from "../../services/api-servise";
import {
    IBandwidth,
    IBillingResponse,
    IIngest,
    IOperator,
    IStream, RHBillingTotalData
} from "../rightsHolderBilling/iGameBillingSlice";
import {AxiosError} from "axios";

export interface IGetRightHolderBillingBody {
    rightHolderId: string,
    startDate: string,
    endDate: string
}

export interface IGameBillingsState {
    totalData: RHBillingTotalData,
    streams: IStream[],
    ingest: IIngest[],
    operators: IOperator[],
    bandwidth: IBandwidth[],
    rightHolderCSV: string,
    csvFileName: string
    error: string,
    loading: boolean
}

const initialState: IGameBillingsState = {
    totalData: {
        activeOperators: 0,
        bandwidthOverage: 0,
        fixturesCount: 0,
        ingestHours: 0,
        playersView: 0,
        userName: '',
        logo: null
    },
    streams: [],
    ingest: [],
    operators: [],
    bandwidth: [],
    rightHolderCSV: '',
    csvFileName: "",
    error: '',
    loading: false
}


export const getRightHolderBilling = createAsyncThunk<IBillingResponse, IGetRightHolderBillingBody, {
    rejectValue: string
}>(
    'iGameRHBilling/getRightHolderBilling',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.post(`api/Billing/igame`, body);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)


export const getRightHolderCSV = createAsyncThunk<string, IGetRightHolderBillingBody, { rejectValue: string }>(
    'iGameRHBilling/getRightHolderCSV',
    async (body, {rejectWithValue, dispatch}) => {
        try {
            const {data, headers} = await client.post(`api/Billing/igame/download`, body)
            if (headers['content-disposition']) {
                const contentDisposition = headers['content-disposition'];
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(contentDisposition);
                const filename = matches ? matches[1].replace(/['"]/g, '') : null;
                if (filename) {
                    dispatch(setCSVFileName(filename))
                }
            }
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)


export const IGameRHBillingSlice = createSlice({
    name: 'iGameRHBilling',
    reducers: {
        setCSVFileName: (state, action: PayloadAction<string>) => {
            state.csvFileName = action.payload
        }
    },
    initialState,

    extraReducers: (builder) => {
        builder
            .addCase(getRightHolderBilling.fulfilled, (state, action) => {
                state.totalData.activeOperators = action.payload.activeOperators;
                state.totalData.bandwidthOverage = action.payload.bandwidthOverage;
                state.totalData.fixturesCount = action.payload.fixturesCount;
                state.totalData.ingestHours = action.payload.ingestHours;
                state.totalData.playersView = action.payload.playersView;
                state.totalData.userName = action.payload.userName;
                state.totalData.logo = action.payload.logo

                state.streams = action.payload.streams;
                state.ingest = action.payload.ingests;
                state.operators = action.payload.operators;
                state.bandwidth = action.payload.bandwidths;
            })
            .addCase(getRightHolderCSV.fulfilled, (state, action) => {
                state.rightHolderCSV = action.payload;
            })
            .addMatcher(isError, (state, action: PayloadAction<string>) => {
                state.error = action.payload;
            })
    }
})
export const {setCSVFileName} = IGameRHBillingSlice.actions
export default IGameRHBillingSlice.reducer


function isError(action: AnyAction) {
    return action.type.endsWith("rejected");
}
