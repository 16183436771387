import React, {useEffect} from "react";
import styles from "./index.module.scss";
import SelectInput from "../../../SelectInput/SelectInput";
import DonutChart from "../DonutChart/DonutChart";
import {IDropdownOptions, IMinorFilters} from "../../types";
import NoDataFoundStub from "../../../NoDataFoundStub/NoDataFoundStub";

export interface IDonutViewProps {
    setDonutMode: (value: boolean) => void,
    setFilters: (filters: IMinorFilters) => void,
    dropdownOptions: IDropdownOptions,
    minorFilters: IMinorFilters,
    colors: string[],
    data: any;
    loading: boolean;
    viewType: string
}

const DonutView: React.FunctionComponent<IDonutViewProps> = (
    {
        setDonutMode,
        setFilters,
        dropdownOptions,
        minorFilters,
        colors,
        data,
        viewType
    }) => {

    useEffect(() => {
        if (minorFilters.donutChartAndTopTenViewType === "Fixture") {
            setFilters({
                ...minorFilters,
                donutChartAndTopTenViewType: "Sport"
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={styles.unfoldedUpper}>
                <div className={styles.header}>
                    <h6>Top ten</h6>
                    <div className={styles.optionsDropdown}>
                        <SelectInput
                            options={dropdownOptions.donutChartViewTypes}
                            onChange={(x) => setFilters({
                                ...minorFilters,
                                donutChartAndTopTenViewType: x!.label
                            })}
                            placeholder="View by"
                            value={{
                                value: minorFilters.donutChartAndTopTenViewType.toLowerCase(),
                                label: minorFilters.donutChartAndTopTenViewType
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.leftSideModeButtons}>
                <button className={styles.leftBtn}>Donut</button>
                <button className={styles.rightBtn} onClick={() => setDonutMode(false)}>
                    List
                </button>
            </div>
            <div className={styles.donutChart}>
                {
                    data && data.graph[0].data.length !== 0
                        ?
                        <DonutChart
                            viewType={viewType.replace(/([A-Z])/g, ' $1').trim()}
                            data={data!.graph}
                            total={data!.total}
                            minorFilters={minorFilters}
                            colors={colors}
                        />
                        :
                        <div className={styles.noData}>
                            <NoDataFoundStub textColor={"#FFFFFF"}/>
                        </div>

                }
            </div>
        </>
    )
}
DonutView.displayName = "DonutView"
export default DonutView;
