import React, {ChangeEvent, FunctionComponent, useEffect, useRef, useState} from 'react';
import styles from './index.module.scss';
import {IIngestFormData, ITableRowData} from "../BookFixtures/BookFixtures";
import AddFixtureForm from "../BookFixtures/children/AddFixtureForm/AddFixtureForm";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import PageHeader from "../../../../../components/PageHeader/PageHeader";
import json from "../../../../../img/json.svg";
import download from "../../../../../img/download.png";
import {
    cancelBooking,
    clearTable,
    downloadCsv,
    downloadJson, getBackupDetails,
    getBtTowerDetails,
    getContentSuppliers,
    getFilterValues,
    getFixtures, getFixturesRightHolderIngestType, getIpDetails, getSatelliteDetails,
    IFixtureResponse, IGetFixturesPayload,
    IUpdateFixturePayload,
    updateFixture,
} from "@store/rightHolderBooking/rightHolderBookingSlice";
import {toastr} from "react-redux-toastr";
import {getInitInfo, getIpChannels} from "@store/init/initSlice";
import {useSortingAndSearch} from "../SheduleIngestHooks/useSortingAndSearch";
import {IUserItem} from "@store/iGameInit/iGameInitSlice";
import {useFormatUpcomingTableData} from "./hooks/useFormatUpcomingTableData";
import {IMenuStructure} from "../../../AppRoutes/routes/RouteInterface";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import ConfirmModal from "@components/ConfirmModal/ConfirmModal";
import SelectInput from "@components/SelectInput/SelectInput";
import {formatSelectOptions} from "@helpers/formatSelectOptions";
import Input from "@components/Input/Input";
import searchWhite from "@img/searchWhite.svg";
import Loader from "@components/Loader/Loader";
import NoDataFoundStub from "@components/NoDataFoundStub/NoDataFoundStub";
import ErrorComponent from "@components/ErrorComponent/ErrorComponent";
import trash from "@img/trashWhite.svg";
import {RootState} from "@store/index";
import IngestForm from "../BookFixtures/children/IngestForm/IngestForm";
import UpcomingBookFixturesTable from "./children/UpcomingBookFixturesTable/UpcomingBookFixturesTable";
import {IngestOptionId} from "@enums/ingest";
import {useGetCartItems} from "@hooks/useGetCartItems";
import {DateTime} from "luxon";

export interface IUpcomingBookedFixturesProps {
    menuStructure: IMenuStructure;
    rightHolderId: string | null;
    users?: IUserItem[];
}

const UpcomingBookedFixtures: FunctionComponent<IUpcomingBookedFixturesProps> = React.memo((
    {
        menuStructure,
        rightHolderId,
        users
    }) => {
    const dispatch = useAppDispatch();
    const query = new URLSearchParams(useLocation().search);
    const queryPageNumber = query.get("pageNumber");
    const loadingStatus = useAppSelector((state: RootState) => state.rightHolderBooking.loadingStatus);
    const sports = useAppSelector((state: RootState) => state.rightHolderBooking.filterValues.items);
    const {itemsPerPage} = useAppSelector((state: RootState) => state.rightHolderBooking);
    const [editFixtureFormVisible, setEditFixtureFormVisible] = useState(false);
    const [editedFixture, setEditedFixture] = useState<ITableRowData | null>(null);
    const [ingestFormVisible, setIngestFormVisible] = useState(false);
    const [ingestFormData, setIngestFormData] = useState<IIngestFormData>({
        type: 0,
        backupIngestType: 0,
        fixture: null
    });

    const {tableData} = useFormatUpcomingTableData();
    const {
        setSorting,
        sorting,
        setSearchWord,
        searchWord,
        setSport,
        sport,
        initial
    } = useSortingAndSearch(rightHolderId, getFixtures, true, itemsPerPage, queryPageNumber);
    const [fixtureIdToDelete, setFixtureIdToDelete] = useState<string | null>(null);
    let {id} = useParams();
    useGetCartItems(id);

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (containerRef && containerRef.current) {
            containerRef.current.scrollTop = 0
        }
    }, [sport]);

    useEffect(() => {
        if (queryPageNumber && +queryPageNumber > 0) {
            let payload: IGetFixturesPayload = {
                rightHolderId: rightHolderId ?? null,
                searchText: searchWord,
                sorting: {
                    property: sorting.criteria ? sorting.criteria : "",
                    isAscending: sorting.isAscending
                },
                sportIds: sport.map(item => item.value),
                upcoming: true,
                pageNumber: +queryPageNumber,
                itemsPerPage
            }

            dispatch(getFixtures({payload}));
        } else {
            navigate(window.location.pathname, {replace: true});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryPageNumber]);

    useEffect(() => {
        if (id) {
            dispatch(getInitInfo({rightHolderId}));
            dispatch(getFilterValues(id));
        } else {
            dispatch(getInitInfo({rightHolderId: null}));
            dispatch(getFilterValues(null));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, dispatch])

    useEffect(() => {
        return () => {
            dispatch(clearTable());
        };
    }, [dispatch]);

    const fetchData = (page: number, pageSize: number, scrollPosition: string) => {
        let payload: IGetFixturesPayload = {
            rightHolderId: rightHolderId ?? null,
            searchText: searchWord,
            sorting: {
                property: sorting.criteria ? sorting.criteria : "",
                isAscending: sorting.isAscending
            },
            sportIds: sport.map(item => item.value),
            upcoming: true,
            pageNumber: page,
            itemsPerPage: pageSize
        }

        dispatch(getFixtures({payload, scrollPosition}));
    }

    const handleEditFixtureClick = (fixture: ITableRowData) => {
        setEditFixtureFormVisible(true);
        setEditedFixture(fixture);
    }

    const handleExtendTimeClick = (fixture: ITableRowData) => {
        const updatedFixtureModel = {
            rightHolderId,
            title: fixture.title,
            description: fixture.description,
            competitionId: fixture.competitionId,
            sportId: fixture.sportId,
            homeRegionId: fixture.homeRegionId,
            awayRegionId: fixture.awayRegionId,
            startDate: fixture.startDate,
            endDate: DateTime.fromISO(fixture.endDate, {zone: 'utc'})
                .plus({minutes: 15})
                .toISO() ?? "",
            geoBlockCountries: fixture.geoBlockCountries,
            extraTime: fixture.extraTime,
            isHomeAwayNationsGeoBlocked: fixture.isHomeAwayNationsGeoBlocked
        }

        const payload: IUpdateFixturePayload = {
            fixture: updatedFixtureModel,
            id: fixture.id,
            ingestType: fixture.ingestType,
            closeForm: null
        }
        dispatch(updateFixture(payload));
    }

    const fetchIngestDetails = (fixture: ITableRowData) => {
        if (fixture) {
            dispatch(getBackupDetails(fixture.id));

            if (fixture.ingestType === IngestOptionId.BTTower) {
                dispatch(getBtTowerDetails({id: fixture.id}));
            }
            if (fixture.ingestType === IngestOptionId.Satellite) {
                dispatch(getSatelliteDetails({id: fixture.id}));
            }
            if (fixture.ingestType === IngestOptionId.IP) {
                dispatch(getIpDetails({id: fixture.id, ingestType: IngestOptionId.IP}));
            }
        }
    }

    const fetchIngestDropdownData = (fixture: ITableRowData) => {
        if (fixture) {
            dispatch(getContentSuppliers(fixture.id));
            dispatch(getFixturesRightHolderIngestType(fixture.id))
            dispatch(getIpChannels({fixtureId: fixture.id, ingestType: fixture.ingestType}));
        }

    }

    const startSetIngest = (fixture: ITableRowData) => {
        if (fixture) {
            fetchIngestDropdownData(fixture)
            fetchIngestDetails(fixture);

            setIngestFormData({
                ...ingestFormData,
                type: fixture.ingestType,
                fixture,
                backupIngestType: fixture.backupIngestType
            })
            setIngestFormVisible(true);
        }
    }

    const setBookingForDelete = (fixture: ITableRowData) => {
        setFixtureIdToDelete(fixture.id);
    }

    const handleRemoveFixtureClick = () => {
        if (fixtureIdToDelete) {
            dispatch(cancelBooking({
                rightHolderId,
                fixtureId: fixtureIdToDelete
            }));
        }
    }

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchWord(e.currentTarget.value);
    }

    const handleDownloadJson = () => {
        dispatch(downloadJson({rightHolderId}))
            .then(data => {
                const jsonData = JSON.stringify(data.payload!.events, null, 2);
                const blob = new Blob([jsonData], {type: 'application/json'});
                const blobUrl = URL.createObjectURL(blob);
                window.open(blobUrl);
            });
    }

    const handleDownloadCsv = () => {
        dispatch(downloadCsv({
            rightHolderId,
            searchText: searchWord,
            sorting: {
                property: sorting.criteria ? sorting.criteria : "",
                isAscending: sorting.isAscending
            },
            upcoming: true
        }))
            .then(response => {
                if ('error' in response) {
                    toastr.error("Error!", "Problem with getting CSV");
                } else {
                    const blob = new Blob([response.payload], {type: 'text/csv'});
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'upcoming_fixtures.csv');
                    document.body.appendChild(link);
                    link.click();
                    toastr.info("Success!", "CSV is downloaded");
                }
            })
    }

    const navigate = useNavigate();

    const goToAuditTrail = (fixture: IFixtureResponse) => {
        const encodedTitle = encodeURIComponent(fixture.title);
        const encodedCompetition = encodeURIComponent(fixture.competition);
        const encodedTechnicalProvider = encodeURIComponent(fixture.contentSupplier);
        navigate(`/cms/audit-trails-bookings?id=${fixture.id}&competition=${encodedCompetition}&startTime=${fixture.startDate}&technicalProvider=${encodedTechnicalProvider}&title=${encodedTitle}&isExternal=${true}`);
    }

    const resetFilters = () => {
        setSport([]);
    }

    const closeIngestForm = () => {
        setIngestFormVisible(false)
    }

    return (
        <>
            <PageHeader menuStructure={menuStructure} title={"Upcoming Booked Fixtures"} users={users}>
                <div className={styles.downloads}>
                    <button onClick={handleDownloadJson}>
                        JSON
                        <img src={json} alt="json"/>
                    </button>
                    <button onClick={handleDownloadCsv}>
                        CSV
                        <img src={download} alt="csv"/>
                    </button>
                </div>
            </PageHeader>
            <div className={styles.upcomingPage}>
                <div className={styles.upcomingPageContainer}>
                    <div className={styles.upperSection}>
                        <h2 className={styles.title}>Upcoming Booked Fixtures</h2>
                        <div className={styles.filters}>
                            <button className={styles.resetFiltersButton} onClick={() => resetFilters()}>Reset Filters
                            </button>
                            <div className={styles.inputWrapper}>
                                <label className={styles.label}>Sport
                                    <SelectInput
                                        onChange={(e) => setSport(e)}
                                        closeMenuOnSelect={false}
                                        options={formatSelectOptions(sports)}
                                        placeholder={"Multi selection"}
                                        value={sport}
                                        isMulti={true}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className={styles.middleSection}>
                        <div className={styles.searchInput}>
                            <Input
                                type="text"
                                placeholder="SEARCH"
                                value={searchWord}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleSearchChange(e)}
                                autoFocus={true}
                            />
                            <img src={searchWhite} alt="search"/>
                        </div>
                    </div>

                    <div className={styles.tableSectionWrapper}>
                        <div className={styles.tableSection}>
                            {
                                loadingStatus === "loading" && initial
                                &&
                                <Loader data-testid="loader"/>
                            }
                            {
                                loadingStatus === "idle" && tableData.length === 0
                                &&
                                <NoDataFoundStub
                                    textColor="white"
                                />
                            }
                            {
                                tableData.length !== 0
                                &&
                                <UpcomingBookFixturesTable
                                    containerRef={containerRef}
                                    tableData={tableData}
                                    fetchData={fetchData}
                                    sorting={sorting}
                                    setSorting={setSorting}
                                    handleEditFixtureClick={handleEditFixtureClick}
                                    startSetIngest={startSetIngest}
                                    setBookingForDelete={setBookingForDelete}
                                    handleExtendTimeClick={handleExtendTimeClick}
                                    goToAuditTrail={goToAuditTrail}
                                />
                            }
                            {
                                loadingStatus === "failed"
                                &&
                                <ErrorComponent
                                    textColor="white"
                                />
                            }
                        </div>
                    </div>
                    {
                        editFixtureFormVisible &&
                        <AddFixtureForm
                            isUpcoming={true}
                            closeForm={() => setEditFixtureFormVisible(false)}
                            editedFixture={editedFixture}
                            setEditedFixture={setEditedFixture}
                            rightHolderId={rightHolderId}
                            searchWord={searchWord}
                            sorting={sorting}
                            sport={sport}
                        />
                    }
                    {
                        fixtureIdToDelete &&
                        <ConfirmModal
                            message={"Are you sure you want to delete this booking?"}
                            closeForm={() => setFixtureIdToDelete(null)}
                            action={handleRemoveFixtureClick}
                            title="Delete Fixture"
                        >
                            <img
                                className={styles.deleteIconBig}
                                src={trash}
                                alt="trash"/>
                        </ConfirmModal>
                    }
                    {
                        ingestFormVisible &&
                        <IngestForm
                            rightHolderId={rightHolderId}
                            closeForm={closeIngestForm}
                            setIngestFormData={setIngestFormData}
                            ingestFormData={ingestFormData}
                        />
                    }
                </div>
            </div>
        </>
    )
})

export default UpcomingBookedFixtures;
