import React from "react";
import AnalyticsDashboard from "../../../../components/AnalyticsDashboard/AnalyticsDashboard";
import {
    createFiltersBookmark,
    deleteFiltersBookmark,
    downloadIgameAnalyticsCsv,
    getIgameAnalytics,
    getInitFiltersOptions,
    setFiltersByBookmarks,
    IIgameAnalyticsState, resetFilters,
    setFilters,
    setSelectedBookmark,
    updateFiltersBookmark, IGameAnalyticFilter
} from "../../../../store/igameAnalytics/igameAnalyticsSlice";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {RootState} from "../../../../store";
import {IPageMenuProps} from "../../AppRoutes/routes/RouteInterface";

const AnalyticsPage: React.FunctionComponent<IPageMenuProps> = ({menuStructure}) => {
    const dispatch = useAppDispatch()
    const {initData, controls, filters} = useAppSelector((state: RootState) => state.igameAnalytics);
    const data = useAppSelector((state: RootState) => state.igameAnalytics.mainData);
    const loading = useAppSelector((state: RootState) => state.igameAnalytics.loading);

    const onSetFilters = (value: IGameAnalyticFilter, name: keyof IIgameAnalyticsState["filters"]) => {
        dispatch(setFilters({value, name}))
    }
    const onResetFilters = () => {
        dispatch(resetFilters())
    }
    const onSetFiltersByBookmarks = (filters: IGameAnalyticFilter[]) => {
        dispatch(setFiltersByBookmarks(filters))
    }
    return (
        <>
            <AnalyticsDashboard
                setFiltersByBookmarks={onSetFiltersByBookmarks}
                resetFilters={onResetFilters}
                setFilters={onSetFilters}
                filters={filters}
                controls={controls}
                menuStructure={menuStructure}
                getInitData={getInitFiltersOptions}
                initData={initData}
                getData={getIgameAnalytics}
                data={data}
                createBookmark={createFiltersBookmark}
                updateBookmark={updateFiltersBookmark}
                deleteBookmark={deleteFiltersBookmark}
                setSelectedBookmark={setSelectedBookmark}
                downloadCsv={downloadIgameAnalyticsCsv}
                client={["dataProviders", "customers"]}
                loading={loading}
            />
        </>
    )
}

AnalyticsPage.displayName = "AnalyticsPage"
export default AnalyticsPage;
