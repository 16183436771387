import React from "react";
import {DtPicker} from "react-calendar-datetime-picker";
import {
    IDate
} from "../../pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/children/AddFixtureForm/AddFixtureForm";
import calendar from "../../img/calendar.svg";
import styles from "./index.module.scss";
import './index.scss';
import {ICalendarDate} from "../../helpers/dateFormatters";

export interface IDatePickerProps {
    onChange: (date: IDate) => void;
    initValue?: IDate | ICalendarDate | null;
    modalPositioningClass?: string;
    placeholder?: string;
    withTime?: boolean
    inputClass?: string
    isDisabled?: boolean;
    autoClose?: boolean;
}

const DatePicker: React.FunctionComponent<IDatePickerProps> = React.memo((props) => {

    return (
        <div className={styles.datePicker}>
            <DtPicker
                isDisabled={props.isDisabled}
                placeholder={props.placeholder || "Select date..."}
                calenderModalClass={props.modalPositioningClass}
                inputClass={props.inputClass ? props.inputClass : "date-input"}
                headerClass="calendar-header"
                timeClass="calendar-time"
                daysClass="calendar-day"
                monthsClass="custom-months"
                yearsClass="custom-years"
                autoClose={!!props.autoClose}
                showTimeInput
                showWeekend
                withTime={props.withTime ? props.withTime : true}
                {...props}
            />
            <div className={styles.calendarIcon}><img src={calendar} alt="time"/></div>
        </div>
    )
})
DatePicker.displayName = "DatePicker"
export default DatePicker;
