import {DateTime} from "luxon";
import {toastr} from "react-redux-toastr";
import {IDate} from "../pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/children/AddFixtureForm/AddFixtureForm";

export const validateDates = (startDate: IDate, endDate: IDate): boolean => {
    const formattedStartDate = DateTime.fromObject(startDate);
    const formattedEndDate = DateTime.fromObject(endDate);

    if ((startDate && endDate) && (formattedStartDate < formattedEndDate)) return true;

    if (!startDate || !endDate) return false;

    if (formattedStartDate > formattedEndDate) {
        toastr.error('IGame', `End date can't be earlier than start date`)
        return false;
    } else {
        return true;
    }
}
