import React, {useState} from 'react';
import styles from './index.module.scss';
import {IMenuStructure} from "../../AppRoutes/routes/RouteInterface";
import {IUserItem} from "../../../../store/iGameInit/iGameInitSlice";
import UpdatePricingForm from "./UpdatePricingForm/UpdatePricingForm";
import PricingForm from "./PricingForm/PricingForm";
import ConfirmModal from "../../../../components/ConfirmModal/ConfirmModal";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import PrimaryButton from "../../../../components/PrimaryButton/PrimaryButton";
import {useAppDispatch} from "../../../../hooks/hooks";
import {
    deletePricing,
    deleteUnconfirmedPricing,
    IRightPricingItem
} from "../../../../store/operatorPricing/operatorPricingSlice";
import {useDisableScroll} from "../../../../hooks/useDisableScroll";
import CreatePriceTable from "./CreatePriceTable/CreatePriceTable";
import ExistingPricingTable from "./ExistingPricingTable/ExistingPricingTable";
import trash from "../../../../img/trashWhite.svg";
import {useParams} from "react-router-dom";
import {useGetCartItems} from "../../../../hooks/useGetCartItems";

export interface IPricingProps {
    menuStructure: IMenuStructure;
    users?: IUserItem[];
    rightHolderId: string | null;
}

export enum PricingTablesNames {
    CREATE_PRICING = 'Create Pricing',
    EXISTING_PRICING = 'Existing Pricing'
}

const Pricing: React.FC<IPricingProps> = React.memo(({menuStructure, users, rightHolderId}) => {
    const dispatch = useAppDispatch()
    const [isShowForm, setIsShowForm] = useState(false);
    const [isShowUpdateForm, setIShowUpdateForm] = useState(false);
    const [updatingPricingId, setUpdatingPricingId] = useState<string>();
    const [isOpenConfirmForCreatedPricing, setIsOpenConfirmForCreatedPricing] = useState(false);
    const [isOpenConfirmForExistingPricing, setIsOpenConfirmForExistingPricing] = useState(false);
    const [deletingPricingInfo, setDeletingPricingInfo] = useState<IRightPricingItem>({} as IRightPricingItem);
    const {id} = useParams();
    useGetCartItems(id);

    const closeForm = () => setIsShowForm(false);
    const closeUpdateForm = () => setIShowUpdateForm(false);

    const openUpdateForm = (pricingId: string) => {
        setUpdatingPricingId(pricingId)
        setIShowUpdateForm(true)
    }
    const openConfirmModal = (price: IRightPricingItem, tableName: string): void => {
        setDeletingPricingInfo(price)

        if (tableName === PricingTablesNames.EXISTING_PRICING) {
            setIsOpenConfirmForExistingPricing(true)
        } else {
            setIsOpenConfirmForCreatedPricing(true)
        }
    }

    const onDeleteCreatedPricing = (): void => {
        dispatch(deleteUnconfirmedPricing(deletingPricingInfo));
    }

    const onDeleteExistingPricing = () => {
        const body = {
            operatorId: deletingPricingInfo.operatorId,
            rightId: deletingPricingInfo.rightId,
            priceId: deletingPricingInfo.id
        }
        dispatch(deletePricing(body))
    }
    useDisableScroll(isShowForm, isShowUpdateForm)


    return (
        <>
            {isShowUpdateForm &&
                <UpdatePricingForm
                    updatingPricingId={updatingPricingId}
                    closeForm={closeUpdateForm}
                    rightHolderId={rightHolderId}
                />}

            {isShowForm &&
                <PricingForm
                    closeForm={closeForm}
                    rightHolderId={rightHolderId}
                />}

            {isOpenConfirmForCreatedPricing &&
                <ConfirmModal
                    title={'Delete Pricing'}
                    action={onDeleteCreatedPricing}
                    closeForm={() => setIsOpenConfirmForCreatedPricing(false)}
                    message={"Are you sure you want to delete pricing?"}>
                    <img className={styles.deleteIconBig} src={trash} alt="trash"/>
                </ConfirmModal>
            }

            {isOpenConfirmForExistingPricing &&
                <ConfirmModal
                    title={'Delete Pricing'}
                    action={onDeleteExistingPricing}
                    closeForm={() => setIsOpenConfirmForExistingPricing(false)}
                    message={"Are you sure you want to delete pricing?"}>
                    <img className={styles.deleteIconBig} src={trash} alt="trash"/>
                </ConfirmModal>
            }

            <PageHeader menuStructure={menuStructure} title={"Pricing"} users={users}/>
            <div className={styles.pageInner}>
                <div className={styles.pageContainer}>
                    <div className={styles.titleWrapper}>
                        <h2 className={styles.title}>Pricing</h2>
                        <div className={styles.addBtn}>
                            <PrimaryButton onClick={() => setIsShowForm(true)}>
                                <span>Add Pricing +</span>
                            </PrimaryButton>
                        </div>
                    </div>

                    <div className={styles.tablesWrapper}>
                        <CreatePriceTable
                            openConfirm={openConfirmModal}
                            openUpdateForm={openUpdateForm}
                            rightHolderId={rightHolderId}
                        />
                        <ExistingPricingTable
                            openConfirm={openConfirmModal}
                            rightHolderId={rightHolderId}
                            users={users}
                        />
                    </div>
                </div>
            </div>
        </>
    );
});

Pricing.displayName = "Pricing"
export default Pricing;
