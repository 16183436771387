import React, {useEffect, useState} from 'react';
import styles from "../../index.module.scss";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import moment from "moment/moment";
import Input from "../../../../Input/Input";
import {TfiTrash} from "react-icons/tfi";
import {
    IGroupedCartItem,
    IPropertyCartItem,
    ISportCartItem,
} from "@store/carts/operatorCart/operatorCartSlice";
import {useAppSelector} from "../../../../../hooks/hooks";
import {RootState} from "../../../../../store";
import {useHandleOperatorCart} from "@components/Carts/OperatorCart/hooks/useHandleOperatorCart";
import {useHandleBFCart} from "@components/Carts/OperatorCart/hooks/useHandleBFCart";

interface ICartExpandTableRowsProps {
    propertyIndex: number,
    sport: ISportCartItem,
    sportIndex: number,
    providerItem: IGroupedCartItem,
    property: IPropertyCartItem,
    operatorId: string | null,
    isOperatorCartActive: boolean
}


const CartExpandTableRows: React.FC<ICartExpandTableRowsProps> = (
    {
        propertyIndex,
        sport,
        sportIndex,
        providerItem,
        property,
        operatorId,
        isOperatorCartActive
    }) => {

    const [expandedProperties, setExpandedProperties] = useState<string[]>([]);
    const {
        handleInputChange,
        handleDeleteTableItem,
        changeRequestedFixturesCount,
        requestedFixtureCounts,
        setRequestedFixtureCounts,
        handleRequestedFixtures
    } = useHandleOperatorCart(operatorId);
    const {handleDeleteBookedFixture} = useHandleBFCart(operatorId)
    const {cartItems} = useAppSelector((state: RootState) => state.operatorCart);


    useEffect(() => {
        if (isOperatorCartActive) {
            const initialCounts = handleRequestedFixtures(cartItems);
            setRequestedFixtureCounts(initialCounts);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartItems]);


    const toggleExpand = (propertyId: string) => {
        if (expandedProperties.includes(propertyId)) {
            const updatedData = expandedProperties.filter(id => id !== propertyId);
            setExpandedProperties(updatedData);
        } else {
            setExpandedProperties([...expandedProperties, propertyId])
        }
    };

    return (
        <React.Fragment key={propertyIndex}>
            <tr className={
                propertyIndex === sport.properties.length - 1 &&
                sportIndex === providerItem.sports.length - 1
                    ? styles.providerRowEnd
                    : ""
            }>
                {sportIndex === 0 && propertyIndex === 0 &&
                    <td rowSpan={providerItem.sports.reduce(
                        (sum, s) => sum + s.properties.length, 0)}
                        className={styles.providerInner}>
                        {providerItem.provider}
                    </td>
                }

                {propertyIndex === 0 &&
                    <td rowSpan={sport.properties.length}
                        className={isOperatorCartActive ? styles.sportInner : styles.sportInnerBF}
                    >{sport.sport}
                    </td>
                }
                <td className={isOperatorCartActive ? styles.propertyInner : styles.propertyInnerBF}>
                    <button
                        onClick={() => toggleExpand(property.propertyId)}
                        className={styles.propertyBtn}>

                        {property.property}
                        {property.fixtures.length > 0 &&
                            <>
                                {expandedProperties.includes(property.propertyId) ?
                                    <IoIosArrowUp
                                        className={styles.expandIcon}/>
                                    :
                                    <IoIosArrowDown
                                        className={styles.expandIcon}/>
                                }
                            </>
                        }
                    </button>

                    {expandedProperties.includes(property.propertyId) &&
                        <div>
                            {property.fixtures.map(fixture => (
                                <div key={fixture.fixtureId}
                                     className={styles.fixtureData}>
                                    {fixture.fixtureTitle}
                                </div>
                            ))}
                        </div>
                    }
                </td>
                <td className={styles.availableFixturesInner}>
                    {property.availableFixtures}

                    {expandedProperties.includes(property.propertyId) &&
                        <div>
                            {property.fixtures.map(fixture => (
                                <div key={fixture.fixtureId}
                                     className={styles.fixtureDataInner}>
                                    <div>{moment(fixture.startTime).format('DD/MM/YYYY')}</div>
                                    <div>{fixture.day}</div>
                                    <div>{moment(fixture.startTime).format('HH:mm')}</div>
                                </div>
                            ))}
                        </div>
                    }
                </td>

                {isOperatorCartActive && <td className={styles.fixtureCountInner}>
                    <div className={styles.countInputInner}>
                        <Input
                            type='number'
                            value={requestedFixtureCounts[property.propertyId] || ''}
                            onChange={(e) => handleInputChange(property.propertyId, e.target.value)}
                            onBlur={() => changeRequestedFixturesCount(property)}
                        />
                    </div>
                </td>
                }
                {isOperatorCartActive ?
                    <td className={styles.deleteCol}>
                        {expandedProperties.includes(property.propertyId)
                        && property.fixtures.length > 0
                            ?
                            <div className={styles.deleteBtn}>
                                {property.fixtures.map(fixture =>
                                    <button
                                        key={fixture.fixtureId}
                                        className={styles.deleteInner}
                                        onClick={() => handleDeleteTableItem(fixture.cartItemId)}>
                                        <TfiTrash
                                            className={`${styles.deleteIcon} ${styles.deleteMargin}`}/>
                                    </button>
                                )}
                            </div>
                            :
                            <button
                                className={styles.deleteInner}
                                onClick={() => handleDeleteTableItem(property.cartItemId)}>
                                <TfiTrash
                                    className={styles.deleteIcon}/>
                            </button>

                        }
                    </td>
                    :
                    <td className={styles.deleteCol}>
                        {expandedProperties.includes(property.propertyId)
                            && property.fixtures.length > 0 &&
                            <div className={styles.deleteFixtureBtn}>
                                {property.fixtures.map(fixture =>
                                    <button
                                        key={fixture.fixtureId}
                                        className={styles.deleteInner}
                                        onClick={() => handleDeleteBookedFixture(fixture.cartItemId)}>
                                        <TfiTrash
                                            className={`${styles.deleteIcon} ${styles.deleteFixtureMargin}`}/>
                                    </button>
                                )}
                            </div>
                        }
                    </td>
                }
            </tr>
        </React.Fragment>
    );
};

export default CartExpandTableRows;
