import React from 'react';
import styles from './index.module.scss';
import {
    getIngestResources,
    IIngestResource,
    setPageNumber
} from "../../../../../../../store/ingestResources/ingestResourcesSlice";
import {Tooltip} from "react-tooltip";
import {useNavigate} from "react-router-dom";
import AppTableWhite from "../../../../../../../components/AppTableWhite/AppTableWhite";
import edit from "../../../../../../../img/editWhite.svg";
import trash from "../../../../../../../img/trashWhite.svg";
import audit from "../../../../../../../img/auditWhite.svg";
import tickWhite from "../../../../../../../img/tickWhite.png";
import {AppInfiniteScroll} from "../../../../../../../components/AppInfiniteScroll/AppInfiniteScroll";
import {useAppDispatch, useAppSelector} from "../../../../../../../hooks/hooks";
import {checkAuditPageAllowance} from "../../../../../../../helpers/checkAuditPageAllowance";

interface IIngestResourcesTableProps {
    editResourceHandler: (resourceId: string) => void;
    deleteResourceHandler: (resource: IIngestResource) => void;
    searchValue: string
    containerRef: React.RefObject<HTMLDivElement>;
}

const IngestResourcesTable: React.FC<IIngestResourcesTableProps> = React.memo((
    {
        editResourceHandler,
        deleteResourceHandler,
        searchValue,
        containerRef
    }) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const {
        pageNumber,
        itemsPerPage,
        totalCount,
        ingestResources,
        scrollLoader,
        maxItemsInTheTable,
    } = useAppSelector(state => state.ingestResources)

    const fetchData = (page: number, pageSize: number, scrollPosition: string) => {
        if (containerRef && containerRef.current) {
            containerRef.current.scrollTop = 0
        }

        dispatch(getIngestResources({
            searchValue,
            pageNumber: page,
            itemsPerPage: pageSize,
            scrollPosition
        }));
    }


    const goToAuditTrail = (id: string, rightHolder: string, competition: string) => {
        const encodedRightHolder = encodeURIComponent(rightHolder);
        const encodedCompetition = encodeURIComponent(competition);

        navigate(`/cms/audit-trails-ingest?id=${id}&rightHolder=${encodedRightHolder}&competition=${encodedCompetition}&isExternal=${true}`)
    }

    const setPage = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber))
    }

    return (
        <div className={styles.tableContainer}>
            <AppInfiniteScroll
                containerRef={containerRef}
                fetchData={fetchData}
                pageSize={itemsPerPage}
                totalItems={totalCount}
                currentPage={pageNumber}
                setPage={setPage}
                isLoading={scrollLoader}
                scrollingContainerHeight="49vh"
                scrollBounce={200}
                maxItemsInTheTable={maxItemsInTheTable}
                tableDataLength={maxItemsInTheTable}
            >
                <AppTableWhite>
                    <thead>
                    <tr>
                        <th>Rights Holder</th>
                        <th>Technical Provider</th>
                        <th>Property</th>
                        <th>Delivery Method</th>
                        <th>URL</th>
                        <th>Ports</th>
                        <th>Passphrase</th>
                        <th>Push Login</th>
                        <th>Application</th>
                        <th>Stream</th>
                        <th>Channel</th>
                        <th>BORO</th>
                        <th></th>
                    </tr>
                    </thead>

                    <tbody>
                    {ingestResources?.map(resource => {
                        return (
                            <tr key={resource.id} className={styles.separateRow}>
                                <td>{resource.rightHolderName}</td>
                                <td>{resource.technicalProviderName}</td>
                                <td>{resource.competitionName}</td>
                                <td>{resource.deliveryMethod}</td>
                                <td>{resource.url}</td>
                                <td>{resource.port}</td>
                                <td>{resource.passPhrase}</td>
                                <td>{resource.pushLogin}</td>
                                <td>{resource.application}</td>
                                <td>{resource.stream}</td>
                                <td>{resource.igmChannel}</td>
                                <td className={styles.checkboxCell}>
                                    {
                                        resource.boro && <img src={tickWhite} alt="tick"/>
                                    }
                                </td>

                                <td>
                                    <div className={styles.actions}>
                                        <button onClick={() => editResourceHandler(resource.id)}>
                                            <Tooltip className={styles.userTableTooltip} id="update-tooltip"/>
                                            <img
                                                src={edit}
                                                alt={"edit"}
                                                className={styles.actionEnabled}
                                                data-tooltip-id="update-tooltip"
                                                data-tooltip-content={"Update"}/>
                                        </button>

                                        <button onClick={() => deleteResourceHandler(resource)}>
                                            <Tooltip className={styles.userTableTooltip} id="delete-tooltip"/>
                                            <img
                                                src={trash}
                                                alt={"delete"}
                                                className={styles.actionEnabled}
                                                data-tooltip-id="delete-tooltip"
                                                data-tooltip-content={"Delete"}
                                            />
                                        </button>

                                        {
                                            checkAuditPageAllowance() &&
                                            <button
                                                onClick={() => goToAuditTrail(resource.id, resource.rightHolderName, resource.competitionName)}>
                                                <Tooltip className={styles.userTableTooltip} id="audit-trail-tooltip"/>
                                                <img
                                                    src={audit}
                                                    alt={"audit"}
                                                    className={styles.actionEnabled}
                                                    data-tooltip-id="audit-trail-tooltip"
                                                    data-tooltip-content={"Go to Audit trails"}/>
                                            </button>
                                        }
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </AppTableWhite>
            </AppInfiniteScroll>
        </div>
    );
});

export default IngestResourcesTable;
