import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import styles from "./index.module.scss";
import {IBookmarkPayload, IBookmarkUpdatePayload} from "../../../../store/operatorAnalytics/operatorAnalyticsSlice";
import BookmarkModal from "../BookmarkModal/BookmarkModal";
import {IBookMarkModalState} from "../../types";
import {AppThunk} from "../../../../store";
import _ from "lodash";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {AnyAction} from "@reduxjs/toolkit";
import {IGameAnalyticFilter} from "../../../../store/igameAnalytics/igameAnalyticsSlice";
import {ISelectOption} from "../../../../store/operatorBookedContent/operatorBookedContentSlice";
import {formatSelectOptions} from "../../../../helpers/formatSelectOptions";
import {makeOptionsByStringArr} from "../../helpers/makeOptionsByStringArr";
import FavoriteStar from "../../../FavoriteStar/FavoriteStar";
import BookmarkList from "./children/BookmarkList/BookmarkList";

export interface IBookmarksProps {
    bookmarks: IBookmarkPayload[];
    filters: IGameAnalyticFilter;
    setFilters: (filters: any) => void
    client: string[];
    createBookmark: (bookmark: IBookmarkPayload) => AppThunk;
    updateBookmark: (bookmark: IBookmarkUpdatePayload) => AppThunk;
    deleteBookmark: (bookmark: string) => AppThunk;
    setSelectedBookmark: (payload: IBookmarkPayload | null) => AnyAction;
    bookmarkInUsage: string
    setBookmarkInUsage: Dispatch<SetStateAction<string>>
}

const Bookmarks: React.FunctionComponent<IBookmarksProps> = (
    {
        bookmarks,
        filters,
        setFilters,
        client,
        createBookmark,
        updateBookmark,
        deleteBookmark,
        setSelectedBookmark,
        bookmarkInUsage,
        setBookmarkInUsage
    }) => {

    const dispatch = useAppDispatch();
    const {bookmarkClicked} = useAppSelector(state => state.igameAnalytics);
    const [showBookmarksList, setShowBookmarksList] = useState(false);
    const [bookMarkModalState, setBookMarkModalState] = useState<IBookMarkModalState>({
        show: false,
        id: "",
        valueToEdit: ""
    });

    const clickTimeout = useRef<number | undefined>();

    useEffect(() => {
        if (bookmarkClicked) {
            const currentBookmarkFilters = getFiltersFromBookmark(bookmarkClicked);
            const currentFilters = {
                sports: filters.sports,
                competitions: filters.competitions,
                countries: filters.countries,
                customers: filters.customers,
                dataProviders: filters.dataProviders,
                dataTypes: filters.dataType,
                streamFormats: filters.streamFormats,
            }

            if (!_.isEqual(currentBookmarkFilters, currentFilters)) {
                dispatch(setSelectedBookmark(null));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const getFiltersFromBookmark = (bookmark: IBookmarkPayload) => {
        return {
            sports: formatSelectOptions(bookmark.sports.items),
            competitions: makeOptionsByStringArr(bookmark.competitions),
            countries: makeOptionsByStringArr(bookmark.countries),
            customers: formatSelectOptions(bookmark.customers.items),
            dataProviders: formatSelectOptions(bookmark.dataProviders.items),
            dataTypes: bookmark.dataTypes.items[0],
            streamFormats: makeOptionsByStringArr(bookmark.streamFormats),
        }
    }

    const createBookmarkName = (): string => {
        let bookmarkName: string[] = [];
        const propertiesToSave = ["competitions", "sports", "countries", ...client, "dataType", "streamFormats"];

        for (const key in filters) {
            if (propertiesToSave.includes(key)) {
                const filterValue = filters[key as keyof IGameAnalyticFilter];

                if (Array.isArray(filterValue)) {
                    if (filterValue.length > 1) {
                        const multiChoice = filterValue.map((x: ISelectOption) => x.label).join(" + ").concat(" / ");
                        bookmarkName.push(multiChoice);
                    } else if (filterValue.length === 1) {
                        const singleChoice = filterValue[0].label.concat(" / ");
                        bookmarkName.push(singleChoice);
                    }
                } else {
                    if ('label' in filterValue) {
                        bookmarkName.push(filterValue.label.concat(" / "));
                    } else if ('year' in filterValue) {
                        const dateString = `${filterValue.year}-${filterValue.month}-${filterValue.day} ${filterValue.hour}:${filterValue.minute}`;
                        bookmarkName.push(dateString.concat(" / "));
                    }
                }
            }
        }

        const bookmarkString = bookmarkName.join("");
        return bookmarkString.slice(0, -2);
    };


    useEffect(() => {
        const clickedBookmarkUpdated = bookmarks.find(x => x.id === bookmarkClicked?.id);
        if (clickedBookmarkUpdated) {
            dispatch(setSelectedBookmark(clickedBookmarkUpdated));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookmarks]);

    const handleSingleBookmarkClick = (bookmark: IBookmarkPayload) => {
        if (clickTimeout.current) {
            clearTimeout(clickTimeout.current);
        }
        clickTimeout.current = window.setTimeout(() => {
            const filtersUpdated: IGameAnalyticFilter = {
                ...filters,
                ...getFiltersFromBookmark(bookmark)
            }
            setFilters(filtersUpdated);
            dispatch(setSelectedBookmark(bookmark));
            setBookmarkInUsage(bookmark.id);
        }, 250);
        setShowBookmarksList(false);
    }

    const handleEditBookMark = (item: { id: string, name: string }) => {
        clearTimeout(clickTimeout.current);
        setBookMarkModalState(
            {
                show: true,
                id: item.id,
                valueToEdit: item.name
            }
        )
    }

    return (
        <div className={styles.bookmarks} data-testid="bookmarks">
            <div className={styles.bookmarksControls}>
                <div className={styles.bookmarksTitle}>
                    <button
                        onClick={() => setShowBookmarksList(true)}
                        className={styles.createBookmarkButton}
                        data-testid="show-bookmarks-list">
                        <h3 className={styles.bookmarksTitleText}>View All Bookmarks</h3>
                        <FavoriteStar color={"#FFFFFF"} isActive={true} fontSize={20}/>
                    </button>
                </div>
                <div className={styles.bookmarksTitle}>
                    <button className={styles.createBookmarkButton}
                            onClick={() => setBookMarkModalState(
                                {
                                    show: true,
                                    id: "",
                                    valueToEdit: ""
                                }
                            )}
                            data-testid="create-bookmark-button">
                        <h3 className={styles.bookmarksTitleText}>Bookmark Selection</h3>
                        <FavoriteStar color={"#FFFFFF"} isActive={false} fontSize={24}/>
                    </button>
                </div>
            </div>
            {showBookmarksList &&
                <BookmarkList
                    bookmarkInUsage={bookmarkInUsage}
                    deleteBookmark={deleteBookmark}
                    onEditBookmark={handleEditBookMark}
                    onBookmarkClick={handleSingleBookmarkClick}
                    closeBookmark={() => setShowBookmarksList(false)}
                    bookmarks={bookmarks}
                />}
            {
                bookMarkModalState.show &&
                <BookmarkModal
                    bookmarks={bookmarks}
                    setBookMarkModalState={setBookMarkModalState}
                    bookMarkModalState={bookMarkModalState}
                    filters={filters}
                    createBookmark={createBookmark}
                    updateBookmark={updateBookmark}
                    bookmarkName={bookmarkClicked?.name || ""}
                    createBookmarkName={createBookmarkName}
                />
            }
        </div>
    )
}
Bookmarks.displayName = "Bookmarks"
export default Bookmarks;
