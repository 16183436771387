import {AnyAction, createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {toastr} from "react-redux-toastr";
import {client} from "../../services/api-servise";
import {AxiosError} from "axios";
import {ISelectItem} from "../userManagement/userManagementSlice";
import {IConfirmResp} from "../settings/settingsSlice";


export interface IPublishBodyItem {
    rightHolderRightId: string,
    publishPlatformId: string
}

export interface IPublishBody {
    payload: IPublishBodyItem[],
    searchValue: string | undefined
}

export interface IRightHolderPublishItem {
    id: string,
    rightName: string,
    platformName: string,
    sport: string
}

export interface IRightHolder {
    rightHolderName: string,
    rightHolderPublishItems: IRightHolderPublishItem[]
}

export interface IGetPublishingResp {
    groupedRightHolders: IRightHolder[],
    publishPlatforms: {
        items: ISelectItem[]
    }
}

type IGetPublishingQuery = string | null;

export interface IPublishingState {
    groupedRightHolders: IRightHolder[],
    publishPlatforms: ISelectItem[],
    error: string,
}

const initialState: IPublishingState = {
    groupedRightHolders: [],
    publishPlatforms: [],
    error: '',
}


export const getPublishing = createAsyncThunk<IGetPublishingResp, IGetPublishingQuery, { rejectValue: string }>(
    'publishing/getPublishing',
    async (searchWord, {rejectWithValue}) => {

        if (searchWord) {
            try {
                const {data} = await client.get(`api/IGames/settings/publish?searchText=${searchWord}`)
                return data
            } catch (e) {
                if (e instanceof AxiosError) {
                    toastr.error('IGame', `${e.response?.data}`)
                    return rejectWithValue(e.response?.data)
                }
            }
        } else try {
            const {data} = await client.get(`api/IGames/settings/publish`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)


export const publish = createAsyncThunk<IConfirmResp, IPublishBody, { rejectValue: string }>(
    'publishing/publish',
    async (body, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.post(`api/IGames/settings/publish`, body.payload)
            dispatch(getPublishing(body.searchValue ? body.searchValue : null))
            toastr.success('IGame', `Selection Confirmed`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const publishingSlice = createSlice({
    name: 'publishing',

    reducers: {},

    initialState,

    extraReducers: (builder) => {
        builder
            .addCase(getPublishing.fulfilled, (state, action) => {
                state.groupedRightHolders = action.payload.groupedRightHolders;
                state.publishPlatforms = action.payload.publishPlatforms.items;
            })
            .addMatcher(isError, (state, action: PayloadAction<string>) => {
                state.error = action.payload;
            })
    }
})

export default publishingSlice.reducer


function isError(action: AnyAction) {
    return action.type.endsWith("rejected");
}
