import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

export const useRecordDetailsFromQueryString = () => {
    const [recordData, setRecordData] = useState<{ rightHolder: string | null, competition: string | null } | null>({
        rightHolder: null,
        competition: null,
    });

    const query = new URLSearchParams(useLocation().search);
    const rightHolder = query.get("rightHolder");
    const competition = query.get("competition");

    useEffect(() => {
        if (rightHolder || competition) {
            setRecordData({rightHolder, competition})
        }
    }, [rightHolder, competition]);

    return {setRecordData, recordData}
}
