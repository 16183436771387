import React, {Dispatch, SetStateAction} from 'react';
import styles from "./index.module.scss"
import {IFormTab} from "../../AddFixtureForm";
import {Tooltip} from "react-tooltip";

interface IFormTabsProps {
    setTabsState: Dispatch<SetStateAction<IFormTab>>
    tabsState: IFormTab
}

const FormTabs: React.FC<IFormTabsProps> = ({tabsState, setTabsState}) => {
    return (
        <div className={styles.tabInner}>
            <div
                onClick={() => setTabsState({...tabsState, createFixtureForm: true, uploadSCVForm: false})}
                className={tabsState.createFixtureForm ? styles.tabActive : styles.tab}>
                Create Fixture
            </div>
            <div
                data-tooltip-id={"csv"}
                data-tooltip-content={"Create multiple events simultaneously by using the CSV Upload"}
                data-tooltip-place="bottom"
                onClick={() => setTabsState({uploadSCVForm: true, createFixtureForm: false})}
                className={tabsState.uploadSCVForm ? styles.tabActive : styles.tab}>
                CSV Upload
            </div>
            <Tooltip className={styles.csvTooltip} id="csv"/>
        </div>
    );
};

export default FormTabs;
