import {IReportDetail} from "@store/iGameReporting/iGameReportingSlice";

export const createDateRangeValue = (currentReport: IReportDetail, isDateFrom: boolean) => {
    if (isDateFrom) {
        return {
            year: new Date(currentReport.issueStartTime).getFullYear(),
            month: new Date(currentReport.issueStartTime).getMonth() + 1,
            day: new Date(currentReport.issueStartTime).getDate(),
            hour: new Date(currentReport.issueStartTime).getHours(),
            minute: new Date(currentReport.issueStartTime).getMinutes()
        }
    }
    return {
        year: new Date(currentReport.issueEndTime).getFullYear(),
        month: new Date(currentReport.issueEndTime).getMonth() + 1,
        day: new Date(currentReport.issueEndTime).getDate(),
        hour: new Date(currentReport.issueEndTime).getHours(),
        minute: new Date(currentReport.issueEndTime).getMinutes()
    }
}