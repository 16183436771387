import {IFixturesReportPayload, IReportingFilters} from "@store/reportingFixtures/types";
import {ISorting} from "@pages/Cms/RightsHolderView/ScheduleIngest/SheduleIngestHooks/useSortingAndSearch";
import {formatRangeDate} from "@helpers/dateFormatters";

export const createReportingPayload = (
    searchValue: string | undefined,
    pageNumber: number,
    itemsPerPage: number,
    sorting: ISorting,
    filters: IReportingFilters,
    isScroll: boolean,
    scrollPosition?: string
): IFixturesReportPayload => {

    let searchPayload = '';

    if (searchValue) {
        searchPayload = searchValue
    }

    return {
        payload: {
            searchValue: searchPayload,
            pageNumber,
            itemsPerPage,
            sorting: {
                property: sorting.criteria,
                isAscending: sorting.isAscending
            },
            ingestType: +filters.ingestType.value === 4 ? null : +filters.ingestType.value,
            dateFrom: formatRangeDate(filters.startDate),
            dateTo: formatRangeDate(filters.endDate)
        },
        isScroll,
        scrollPosition
    }
}
