import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import styles from './index.module.scss'
import {BiEditAlt} from "react-icons/bi";
import {RiDeleteBinLine} from "react-icons/ri";
import AppTable from "../../../../../../components/AppTable/AppTable";
import Input from "../../../../../../components/Input/Input";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/hooks";
import {
    deleteReport,
    getReportFormInit,
    getReportsDetails, getStreamTypeOptions
} from "../../../../../../store/iGameReporting/iGameReportingSlice";

import moment from "moment/moment";
import ConfirmModal from "../../../../../../components/ConfirmModal/ConfirmModal";
import trash from "../../../../../../img/trashWhite.svg";
import audit from "../../../../../../img/auditWhite.svg";
import {useNavigate} from "react-router-dom";
import {setIssuesDetails} from "../../../../../../store/auditTrailsIssues/auditTrailsIssuesSlice";

interface IReportFormProps {
    fixtureId: string
    openFaultForm: () => void;
    closeReportForm: () => void
    setUpdatingReportId: Dispatch<SetStateAction<string | number>>
}

const ReportForm: React.FC<IReportFormProps> = React.memo((
    {
        fixtureId,
        openFaultForm,
        closeReportForm,
        setUpdatingReportId
    }) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const {reportFormInit, reportsDetails} = useAppSelector(state => state.iGameReportingSlice)
    const [isOpenConfirm, setIsOpenConfirm] = useState(false);
    const [deletedReport, setDeletedReport] = useState<number>(0);

    useEffect(() => {
        if (fixtureId) {
            dispatch(getReportFormInit(fixtureId));
            dispatch(getReportsDetails(fixtureId));
            dispatch(getStreamTypeOptions(fixtureId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fixtureId]);

    const setReportIdForUpdate = (e: React.MouseEvent, reportId: string | number) => {
        setUpdatingReportId(reportId)
        openFaultForm()
    }

    const deleteReportFromDB = () => {
        dispatch(deleteReport(deletedReport))
    }

    return (
        <div onClick={() => closeReportForm()} className={styles.overlay}>
            {isOpenConfirm &&
                <ConfirmModal
                    title={"Delete Report"}
                    message={"Are you sure you want to delete?"}
                    closeForm={() => setIsOpenConfirm(false)}
                    action={deleteReportFromDB}>
                    <img className={styles.deleteIconBig} src={trash} alt="trash"/>
                </ConfirmModal>
            }
            <form onClick={(e) => e.stopPropagation()}
                  onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                  className={styles.form}>
                <div className={styles.titleInner}>
                    <h6 className={styles.title}>Report</h6>
                </div>
                <div className={styles.content}>
                    <div className={styles.inputs}>
                        <div className={styles.inputInner}>
                            <label className={styles.label}>Rights Holder
                                <Input
                                    value={reportFormInit.mainReportInfo.rightHolder}
                                    readOnly={true}
                                />
                            </label>
                        </div>
                        <div className={styles.inputInner}>
                            <label className={styles.label}>Sport
                                <Input
                                    value={reportFormInit.mainReportInfo.sport}
                                    readOnly={true}
                                />
                            </label>
                        </div>
                        <div className={styles.inputInner}>
                            <label className={styles.label}>Competition
                                <Input
                                    value={reportFormInit.mainReportInfo.competition}
                                    readOnly={true}
                                />
                            </label>
                        </div>
                        <div className={styles.inputInner}>
                            <label className={styles.label}>Technical Provider
                                <Input
                                    value={reportFormInit.mainReportInfo.technicalProvider}
                                    readOnly={true}
                                />
                            </label>
                        </div>
                    </div>
                    <div className={styles.tableInner}>
                        <AppTable>
                            <thead>
                            <tr>
                                <th>Issue</th>
                                <th>Description</th>
                                <th>Report Time</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {reportsDetails.map(report => {
                                return (
                                    <tr key={report.id}>
                                        <td>
                                            <div className={styles.firstCellInner}>
                                                <span>{report.fault}</span>
                                            </div>
                                        </td>
                                        <td>{report.description}</td>
                                        <td className={styles.timeCell}>{moment(report.reportTime).format("DD-MM-YYYY HH:mm")}</td>
                                        <td className={styles.controlCell}>
                                            <BiEditAlt
                                                onClick={(e) => setReportIdForUpdate(e, report.id)}
                                                className={styles.actionIcon}
                                            />
                                            <RiDeleteBinLine
                                                onClick={() => {
                                                    setDeletedReport(report.id)
                                                    setIsOpenConfirm(true)

                                                }}
                                                className={styles.actionIcon}
                                            />
                                            <button className={`${styles.tableButton} ${styles.actionIcon}`}
                                                    onClick={() => {
                                                        navigate("/cms/audit-trails-issues")
                                                        dispatch(setIssuesDetails({
                                                            id: report.id,
                                                            rightHolder: reportFormInit.mainReportInfo.rightHolder,
                                                            technicalProvider: reportFormInit.mainReportInfo.technicalProvider,
                                                            competition: reportFormInit.mainReportInfo.competition
                                                        }))
                                                    }}>
                                                <img
                                                    className={styles.auditIcon}
                                                    src={audit}
                                                    alt={"audit"}
                                                />
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </AppTable>
                    </div>
                </div>
                <div className={styles.buttons}>
                    <button
                        onClick={closeReportForm}
                        className={styles.cancelButton}>Close
                    </button>
                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            openFaultForm()
                        }}
                        className={styles.submitButton}>
                        Add issue +
                    </button>
                </div>
            </form>
        </div>
    );
})

ReportForm.displayName = "ReportForm"
export default ReportForm;
