import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';
import {useAppDispatch, useAppSelector} from "@hooks/hooks";
import notification from "../../../../img/notification.svg";
import {IAlert, markAlertAsSeen} from "@store/alerts/alertsSlice";
import {useNavigate} from "react-router-dom";
import close from "../../../../img/close.svg";
import {useGetProperPage} from "../../hooks/useGetProperPage";
import {navigateToTable} from "../../helpers/navigateToTable";
import OutsideClickHandler from "react-outside-click-handler";
import {AuditAction} from "@enums/auditActions";

const AlertsListModal: React.FC<{ closePopup: () => void }> = React.memo(({closePopup}) => {
    const dispatch = useAppDispatch();
    const [selected, setSelected] = useState<IAlert | null>(null);
    const {records} = useAppSelector(state => state.alerts);
    const {role} = useAppSelector(state => state.userInfo);
    const {itemsPerPage} = useAppSelector(state => state.rightHolderBooking);
    const navigate = useNavigate();
    const {fetchFixturePageNumber} = useGetProperPage();

    useEffect(() => {
        records.forEach(record => {
            if (!record.isSeen) {
                dispatch(markAlertAsSeen({alertId: record.id}));
            }
        });
    }, [dispatch, records]);

    useEffect(() => {
        if (selected) {
            fetchFixturePageNumber(selected.entityId, itemsPerPage, selected.groupId)
                .then(pageNumber => {
                    if (pageNumber) {
                        navigateToTable(navigate, role, pageNumber, selected.entityId, selected.groupId);
                    }
                });
            closePopup();
            setSelected(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, fetchFixturePageNumber, itemsPerPage, role, navigate]);

    return (
        <div className={styles.overlay}>
            <OutsideClickHandler onOutsideClick={closePopup}>
                <div className={styles.alertsListModalWrapper}>
                    <button className={styles.closeButton} onClick={closePopup}>
                        <img src={close} alt="close"/>
                    </button>
                    <div className={styles.alertsListModal}>
                        <h2 className={styles.title}>Notification Center</h2>
                        <div className={styles.decor}>
                            <img className={styles.bellIconBig} src={notification} alt="notification"/>
                        </div>
                        {records.map(alert => (
                            <div className={styles.content} key={alert.id}>
                                <p className={styles.textTime}>{alert.date}</p>
                                <p className={styles.text}>{alert.topText}</p>
                                <p className={styles.textLarge}>{alert.description}</p>
                                <p className={styles.textLarge}>{alert.bottomText}</p>
                                <div className={styles.buttons}>
                                    {
                                        alert?.auditAction !== AuditAction.Delete &&
                                        <button onClick={() => setSelected(alert)} className={styles.submitButton}
                                                type="button">
                                            Go to Fixture
                                        </button>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </OutsideClickHandler>
        </div>
    );
});

export default AlertsListModal;
