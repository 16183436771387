import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {getAllUsers, IUserItem} from "../../../../store/iGameInit/iGameInitSlice";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import Loader from "../../../../components/Loader/Loader";
import {useLocation, useNavigate} from "react-router-dom";
import {clearCartState} from "../../../../store/rightHolderBooking/rightHolderBookingSlice";
import {Group} from "../../../../enums/group";
import {IPageMenuProps} from "@pages/Cms/AppRoutes/routes/RouteInterface";


enum pagesWithTiles {
    RIGHTS_HOLDERS = 'Rights Holders',
}

const UserTypesTiles: React.FC<IPageMenuProps> = React.memo(({menuStructure}) => {
    const [currentUsers, setCurrentUsers] = useState<IUserItem[]>([]);
    const {users, usersStatus} = useAppSelector(state => state.iGameInit);
    const {pathname} = useLocation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getAllUsers(null));
    }, [dispatch]);

    useEffect(() => {
        if (pathname === '/cms/igame-rightHolders') {
            setCurrentUsers(users.rightHolders);
        } else {
            setCurrentUsers(users.operators);
        }
    }, [pathname, users]);

    const handleClientInfo = (clientId: string, currentUserRole: string) => {
        if (menuStructure.title === pagesWithTiles.RIGHTS_HOLDERS) {
            navigate(`/cms/igame-rightHolders/${clientId}/rightHolder-book-fixtures`)
        } else {
            navigate(`/cms/igame-operators/${clientId}/operator-booked-content`)
        }

        localStorage.setItem("currentUserRole", currentUserRole.toString());
        const currentUser = localStorage.getItem("client");

        if (currentUser && currentUser === clientId) return;

        localStorage.setItem("client", clientId);
        dispatch(clearCartState());
    };

    return (
        <>
            <PageHeader menuStructure={menuStructure}/>
            <div className={styles.pageInner}>
                <div className={styles.pageContainer}>
                    <h1 className={styles.title}>{menuStructure.title}</h1>
                    {usersStatus === "loading" ? <Loader/> :
                        <div className={styles.tilesInner}>
                            <div className={styles.tableScroll}>
                                <div className={styles.content}>
                                    {currentUsers.map(user => (
                                        <button
                                            key={user.id}
                                            onClick={() => handleClientInfo(user.id,
                                                menuStructure.title === pagesWithTiles.RIGHTS_HOLDERS ?
                                                    Group.RightHolder : Group.Operator
                                            )}
                                            className={styles.tileWrapper}>
                                            <div className={styles.tileContent}>
                                                <p>{user.name}</p>
                                                <p>
                                                    {user.logo &&
                                                        <img src={user.logo} alt="logo" className={styles.logo}/>}
                                                </p>
                                            </div>
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
});

export default UserTypesTiles;
