import React, {LegacyRef} from "react";
import styles from "./index.module.scss";

export interface IAppTableProps {
    children: React.ReactNode;
    reference?: undefined | LegacyRef<HTMLTableElement>;
}

const AppTable: React.FunctionComponent<IAppTableProps> = ({children, reference}) => {
    return (
        <table className={styles.appTable} ref={reference} data-testid="app-table">
            {children}
        </table>
    )
}
AppTable.displayName = "AppTable"
export default AppTable;
