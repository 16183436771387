import React, {useEffect, useRef} from "react";
import videojs from "video.js";
import "./index.css";
import "video.js/dist/video-js.css";
import Player from "video.js/dist/types/player";

export interface IVideoPlayerProps {
    options: any;
}

export const VideoPlayer: React.FunctionComponent<IVideoPlayerProps> = ({options}) => {
    const videoRef = useRef<HTMLDivElement | null>(null);
    const playerRef = useRef<Player | null>(null);

    useEffect(() => {
        if (playerRef.current) {
            const player: any = playerRef.current;

            player.autoplay(options.autoplay);
            player.src(options.sources);
        } else {
            const videoElement = document.createElement("video-js");

            videoElement.classList.add("vjs-big-play-centered");
            videoRef.current?.appendChild(videoElement);
            playerRef.current = videojs(videoElement, options, () => {
            })
        }
    }, [options, videoRef]);

    useEffect(() => {
        const player: any = playerRef.current;

        return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    return (
        <div data-vjs-player className={'videoPlayer'}>
            <div ref={videoRef}/>
        </div>
    );
};

export default VideoPlayer;

