import {IColumnItem} from "../../../../components/OperatorSortingHeader/OperatorSortingHeader";

export const columnsData: IColumnItem[] = [
    {
        title: "Sport",
        criteria: "Sport",
        key: "sport"
    },
    {
        title: "Right Holder",
        criteria: "RightHolder",
        key: "rightHolder"
    },
    {
        title: "Property",
        criteria: "Property",
        key: "property",
        favorite: true
    },
    {
        title: "",
        criteria: null,
        key: "indicator",
    },
    {
        title: "Geoblocking",
        criteria: "GeoBlocking",
        key: "geoBlocking",
    },
    {
        title: "No of Matches",
        criteria: "NumberOfMatches",
        key: "numberOfMatches",
    },
    {
        title: "Rights Start Date",
        criteria: "RightStartDate",
        key: "rightStartDate",
    },
    {
        title: "Rights End Date",
        criteria: "RightEndDate",
        key: "rightEndDate",
    },
    {
        title: "Add to Cart",
        criteria: null,
        key: "rightState",
    }
];

