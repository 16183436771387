import React, {useState} from 'react';
import {IoMdClose} from "react-icons/io";
import styles from "./index.module.scss"

interface IUploadedLogoViewer {
    isEditMode: boolean
    logo: string | undefined
}

const UploadedLogoViewer: React.FC<IUploadedLogoViewer> = ({isEditMode, logo}) => {
    const [isShowFullSize, setIsShowFullSize] = useState(false);

    const renderUploadedLogo = () => {
        if (isEditMode && logo) {
            return <div>
                <button
                    className={styles.btn}
                    onClick={(e) => {
                        e.preventDefault()
                        setIsShowFullSize(true)
                    }}>
                    Show editing logo
                </button>
                {isShowFullSize &&
                    <div className={styles.logoWrapper}>
                        <div className={styles.logo}>
                            <div className={styles.imgInner}>
                                <IoMdClose
                                    onClick={() => setIsShowFullSize(false)}
                                    className={styles.closeIcon}
                                />
                                <img src={logo} alt="logo"/>
                            </div>
                        </div>
                    </div>
                }
            </div>
        } else {
            return null
        }
    }

    return (
        <>
            {renderUploadedLogo()}
        </>
    );
};

export default UploadedLogoViewer;
