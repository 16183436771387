import {AnyAction, createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {toastr} from "react-redux-toastr";
import {client} from "../../services/api-servise";
import {AxiosError} from "axios";

export interface ICompetitionBilling {
    title: string,
    numberOfBookings: number,
    numberOfStreams: number,
    numberOfAdHocBookings: number,
    total: number
}

export interface IProviderBilling {
    competition: string,
    deliveredContentMinutes: number,
    numberOfBookings: number,
    numberOfStreams: number,
    numberOfAdHocBookings: number,
    total: number,
    competitionBillingItems: ICompetitionBilling[]
}

export interface IOperatorBilling {
    provider: string,
    deliveredContentMinutes: number,
    numberOfBookings: number,
    numberOfStreams: number,
    numberOfAdHocBookings: number,
    total: number,
    providerBillingItems: IProviderBilling[]
}

export interface IGetBillingResp {
    operatorName: string,
    totalFixtures: number,
    streamDelivered: number,
    providers: number,
    adHocBookings: number,
    deliveredContentHours: number,
    operatorBillingItems: IOperatorBilling[],
    logo: string | null
}

export interface IGetBillingReqBody {
    rightHolderId?: string,
    operatorId?: string,
    startDate: string,
    endDate: string
}

export interface IOperatorBillingTotalData {
    totalFixtures: number,
    streamDelivered: number,
    providers?: number,
    adHocBookings: number,
    deliveredContentHours?: number,
    activeOperators?: number,
    logo: string | null
}

interface IOperatorBillingsState {
    totalData: IOperatorBillingTotalData,
    operatorName: string,
    operatorBillingData: IOperatorBilling[],
    error: string,
    billingCSV: string,
    csvFileName: string
}

const initialState: IOperatorBillingsState = {
    totalData: {
        totalFixtures: 0,
        streamDelivered: 0,
        providers: 0,
        adHocBookings: 0,
        deliveredContentHours: 0,
        logo: null
    },
    operatorName: '',
    operatorBillingData: [],
    error: '',
    billingCSV: '',
    csvFileName: ""
}

export const getBilling = createAsyncThunk<IGetBillingResp, IGetBillingReqBody, { rejectValue: string }>(
    'iGameOperatorBilling/getBilling',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.post(`api/Billing/operator`, body)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getBillingCSV = createAsyncThunk<string, IGetBillingReqBody, { rejectValue: string }>(
    'iGameOperatorBilling/getBillingCSV',
    async (body, {rejectWithValue, dispatch}) => {
        try {
            const {data, headers} = await client.post(`api/Billing/operator/download`, body)
            if (headers['content-disposition']) {
                let contentDisposition = headers['content-disposition'];
                contentDisposition = contentDisposition.replace(/filename\*=UTF-8''/, '');
                let matches = /filename=([^;]*)/.exec(contentDisposition);
                let filename = matches ? matches[1] : null;
                if (filename) {
                    dispatch(setCSVFileName(filename))
                }
            }
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const iGameOperatorBillingSlice = createSlice({
    name: 'iGameOperatorBilling',
    reducers: {
        setCSVFileName: (state, action: PayloadAction<string>) => {
            state.csvFileName = action.payload
        }
    },
    initialState,

    extraReducers: (builder) => {
        builder
            .addCase(getBilling.fulfilled, (state, action) => {
                state.operatorName = action.payload.operatorName;
                state.totalData.totalFixtures = action.payload.totalFixtures;
                state.totalData.streamDelivered = action.payload.streamDelivered;
                state.totalData.providers = action.payload.providers;
                state.totalData.adHocBookings = action.payload.adHocBookings;
                state.totalData.deliveredContentHours = action.payload.deliveredContentHours;
                state.totalData.logo = action.payload.logo;

                state.operatorBillingData = action.payload.operatorBillingItems;
            })
            .addCase(getBillingCSV.fulfilled, (state, action) => {
                state.billingCSV = action.payload;
            })
            .addMatcher(isError, (state, action: PayloadAction<string>) => {
                state.error = action.payload;
            })
    }
})

export const {setCSVFileName} = iGameOperatorBillingSlice.actions
export default iGameOperatorBillingSlice.reducer


function isError(action: AnyAction) {
    return action.type.endsWith("rejected");
}
