import React, {useEffect, useRef, useState} from 'react';
import styles from "./index.module.scss";
import PageHeader from "../../../../../components/PageHeader/PageHeader";
import DownloadBlock from "../../../../../components/DownloadBlock/DownloadBlock";
import IGameSubHeader from "../../../../../components/IGameSubHeader/IGameSubHeader";
import {pdfFormatter} from "../../../../../helpers/pdfFormatter";
import {
    formatRangeDate,
    getCurrentMonth,
    getPreviousMonth
} from "../../../../../helpers/dateFormatters";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {NavLink, useParams} from "react-router-dom";
import {Route, Routes} from "react-router";
import {getRightHolderBilling, getRightHolderCSV} from "../../../../../store/iGameRHBilling/iGameRHBillingSlice";
import Loader from "../../../../../components/Loader/Loader";
import {getUserType} from "../../../../../store/userManagement/userManagementSlice";
import {IPageMenuProps} from "../../../AppRoutes/routes/RouteInterface";
import BillingTable from "../../../../../components/Billing/BillingTable/BillingTable";
import RightHolderUser from "../../../../../components/Billing/RightHolderUser/RightHolderUser";
import {IDate} from "../../../../../components/AnalyticsDashboard/types";
import BillingCalendar from "../../../../../components/Billing/BillingCalendar/BillingCalendar";
import {validateDates} from "../../../../../helpers/validateDates";

export enum BillingTabs {
    Streams = 'Streams',
    Ingest = 'Ingest',
    Operators = 'Operators',
    Bandwidth = 'Bandwidth'
}

const RightHolderBilling: React.FC<IPageMenuProps> = React.memo(({menuStructure}) => {
    const {id} = useParams();
    const {
        loading,
        rightHolderCSV,
        totalData,
        csvFileName,
        streams,
        ingest,
        bandwidth,
        operators
    } = useAppSelector(state => state.iGameRHBilling);
    const {rightHoldersCount, operatorsCount} = useAppSelector(state => state.userManagement);

    const initialStartDate = getPreviousMonth();
    const initialEndDate = getCurrentMonth();
    const [startDate, setStartDate] = useState<IDate>(initialStartDate);
    const [endDate, setEndDate] = useState<IDate>(initialEndDate);
    const [isValidEndDate, setIsValidEndDate] = useState<boolean>(true);

    const pdfLayout = useRef(null);
    const dispatch = useAppDispatch();


    useEffect(() => {
        dispatch(getUserType(null));
    }, [dispatch]);

    useEffect(() => {
        const isValidDates = validateDates(startDate, endDate);
        setIsValidEndDate(isValidDates);
        if (!isValidDates) return;

        const body = {
            rightHolderId: id ? id : "",
            startDate: formatRangeDate(startDate),
            endDate: formatRangeDate(endDate)
        }
        dispatch(getRightHolderBilling(body))
        dispatch(getRightHolderCSV(body));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, startDate, endDate]);


    const pdfHandler = () => {
        const layout: any = pdfLayout.current;
        pdfFormatter(layout);
    }

    return (
        <div ref={pdfLayout}>
            {loading && <Loader/>}
            {!loading && <>
                <PageHeader menuStructure={menuStructure}>
                    <DownloadBlock
                        fileName={csvFileName}
                        csvFile={rightHolderCSV}
                        pdfHandler={pdfHandler}
                        isActiveLink={true}
                    />
                </PageHeader>

                <div className={styles.pageInner}>
                    <IGameSubHeader
                        rightsHoldersCount={rightHoldersCount}
                        operatorsCount={operatorsCount}
                        hasOptions={true}
                        title={'Billing'}>

                        <BillingCalendar
                            startDate={startDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            setStartDate={setStartDate}
                            isValidEndDate={isValidEndDate}/>
                    </IGameSubHeader>


                    <div className={styles.pageContainer}>
                        <div className={styles.billingWrapper}>
                            <RightHolderUser totalData={totalData}/>
                            <div className={styles.tableWrapper}>
                                <div className={styles.tabsWrapper}>
                                    <NavLink
                                        to={`/cms/igame-billing/rightHolders/${id}`}
                                        className={({isActive, isPending}) =>
                                            isPending ? "pending" : isActive ? styles.activeTab : styles.notActiveTab}
                                        end>
                                        {BillingTabs.Streams}
                                    </NavLink>

                                    <NavLink
                                        to={'ingest'}
                                        className={({isActive, isPending}) =>
                                            isPending ? "pending" : isActive ? styles.activeTab : styles.notActiveTab
                                        }>{BillingTabs.Ingest}
                                    </NavLink>
                                    <NavLink
                                        to={'operators'}
                                        className={({isActive, isPending}) =>
                                            isPending ? "pending" : isActive ? styles.activeTab : styles.notActiveTab}
                                        end>{BillingTabs.Operators}
                                    </NavLink>

                                    <NavLink
                                        to={'bandwidth'}
                                        className={({isActive, isPending}) =>
                                            isPending ? "pending" : isActive ? styles.activeTab : styles.notActiveTab
                                        }>{BillingTabs.Bandwidth}
                                    </NavLink>
                                </div>

                                <Routes>
                                    <Route
                                        path={'/'}
                                        element={<BillingTable currentTab={BillingTabs.Streams} streams={streams}/>}
                                    />
                                    <Route
                                        path={'ingest'}
                                        element={<BillingTable currentTab={BillingTabs.Ingest} ingest={ingest}/>}
                                    />
                                    <Route
                                        path={'operators'}
                                        element={<BillingTable currentTab={BillingTabs.Operators}
                                                               operators={operators}/>}
                                    />
                                    <Route
                                        path={'bandwidth'}
                                        element={<BillingTable currentTab={BillingTabs.Bandwidth}
                                                               bandwidth={bandwidth}/>}
                                    />
                                </Routes>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
        </div>
    );
});

RightHolderBilling.displayName = "RightHolderBilling"
export default RightHolderBilling;
