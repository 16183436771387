import React, {useEffect, useRef} from 'react';
import {useAppDispatch, useAppSelector, useSearchUsers} from "../../../../../hooks/hooks";
import {getUserType} from "../../../../../store/userManagement/userManagementSlice";
import styles from "./index.module.scss";
import PageHeader from "../../../../../components/PageHeader/PageHeader";
import DownloadBlock from "../../../../../components/DownloadBlock/DownloadBlock";
import IGameSubHeader from "../../../../../components/IGameSubHeader/IGameSubHeader";
import {NavLink} from "react-router-dom";
import SearchInput from "../../../../../components/SearchInput/SearchInput";
import {Route, Routes} from "react-router";
import BillingUsersTable from "./children/BillingUsersTable/BillingUsersTable";
import {Group} from "../../../../../enums/group";
import {IPageMenuProps} from "../../../AppRoutes/routes/RouteInterface";


const UsersTiles: React.FC<IPageMenuProps> = React.memo(({menuStructure}) => {
    const dispatch = useAppDispatch();
    const {searchValue, onSearch} = useSearchUsers(getUserType);
    const pdfLayout = useRef(null);
    const {rightHoldersCount, operatorsCount, rightHolders, operators} = useAppSelector(state => state.userManagement);

    useEffect(() => {
        dispatch(getUserType(null));
    }, [dispatch])

    return (
        <div className={styles.pageInner} ref={pdfLayout}>
            <PageHeader menuStructure={menuStructure}>
                <DownloadBlock fileName={""}/>
            </PageHeader>

            <IGameSubHeader
                rightsHoldersCount={rightHoldersCount}
                operatorsCount={operatorsCount}
                title={'Billing'}>

                <div className={styles.controlOverlay}>
                    <div className={styles.controlInner}>
                        <div className={styles.linksInner}>
                            <NavLink
                                to={'/cms/igame-billing'}
                                className={({isActive, isPending}) =>
                                    isPending ? "pending" : isActive ? styles.activeTab : styles.notActiveTab
                                } end>Rights Holders
                            </NavLink>
                            <NavLink
                                to={'operators'}
                                className={({isActive, isPending}) =>
                                    isPending ? "pending" : isActive ? styles.activeTab : styles.notActiveTab
                                }>Operators
                            </NavLink>
                        </div>

                        <div className={styles.searchInput}>
                            <SearchInput searchValue={searchValue} onChange={onSearch}/>
                        </div>
                    </div>
                </div>
            </IGameSubHeader>

            <Routes>
                <Route
                    path={'/'}
                    element={<BillingUsersTable users={rightHolders} role={Group.RightHolder}/>}
                />

                <Route
                    path={'operators'}
                    element={<BillingUsersTable users={operators} role={Group.Operator}/>}
                />
            </Routes>
        </div>
    );
});

export default UsersTiles;
