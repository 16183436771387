import React, {ChangeEvent, FunctionComponent, useEffect, useRef, useState} from 'react';
import styles from './index.module.scss';
import AddFixtureForm from "./children/AddFixtureForm/AddFixtureForm";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {
    addFixtureToCart,
    clearTable,
    deleteFixtureFromCart,
    getFilterValues,
    getFixtures,
    IFixtureResponse,
    IGetFixturesPayload,
    removeFixture
} from "@store/rightHolderBooking/rightHolderBookingSlice";
import {getInitInfo} from "@store/init/initSlice";
import {useSortingAndSearch} from "../SheduleIngestHooks/useSortingAndSearch";
import {IUserItem} from "@store/iGameInit/iGameInitSlice";
import {useFormatTableData} from "./hooks/useFormatTableData";
import {IMenuStructure} from "../../../AppRoutes/routes/RouteInterface";
import {useParams} from "react-router-dom";
import PageHeader from "../../../../../components/PageHeader/PageHeader";
import SelectInput from "../../../../../components/SelectInput/SelectInput";
import Input from "../../../../../components/Input/Input";
import searchWhite from "../../../../../img/searchWhite.svg";
import {RootState} from "@store/index";
import Loader from "../../../../../components/Loader/Loader";
import NoDataFoundStub from "../../../../../components/NoDataFoundStub/NoDataFoundStub";
import ErrorComponent from "../../../../../components/ErrorComponent/ErrorComponent";
import {formatSelectOptions} from "@helpers/formatSelectOptions";
import ConfirmModal from "../../../../../components/ConfirmModal/ConfirmModal";
import trash from "../../../../../img/trashWhite.svg";
import BookFixturesTable from "./children/BookFixturesTable/BookFixturesTable";
import {Group} from "@enums/group";
import {useGetCartItems} from "@hooks/useGetCartItems";

export interface IBookFixturesProps {
    menuStructure: IMenuStructure;
    rightHolderId: string | null;
    users?: IUserItem[];
}

export interface ITableRowData extends IFixtureResponse {
    homeRegion: string;
    awayRegion: string;
    ingestDetails?: ITableRowData[] | null;
}

export interface ISorting {
    criteria: string | null;
    isAscending: boolean;
}

export interface IIngestFormData {
    type: number,
    backupIngestType: number,
    fixture: ITableRowData | null
}

const BookFixtures: FunctionComponent<IBookFixturesProps> = React.memo((
    {
        menuStructure,
        rightHolderId,
        users
    }) => {
    const dispatch = useAppDispatch();
    const loadingStatus = useAppSelector((state: RootState) => state.rightHolderBooking.loadingStatus);
    const sports = useAppSelector((state: RootState) => state.rightHolderBooking.filterValues.items);
    const {
        itemsPerPage,
        rightsHolderCartFixtures
    } = useAppSelector((state: RootState) => state.rightHolderBooking);
    const {role} = useAppSelector((state: RootState) => state.userInfo);
    const [addFixtureFormVisible, setAddFixtureFormVisible] = useState(false);
    const [editedFixture, setEditedFixture] = useState<ITableRowData | null>(null);
    const [fixtureToDelete, setFixtureToDelete] = useState<ITableRowData | null>(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const {tableData} = useFormatTableData();
    const {
        setSorting,
        sorting,
        setSearchWord,
        searchWord,
        getSortedItems,
        setSport,
        sport,
        initial
    } = useSortingAndSearch(rightHolderId, getFixtures, false, itemsPerPage);
    const {id} = useParams();
    useGetCartItems(id);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (containerRef && containerRef.current) {
            containerRef.current.scrollTop = 0
        }
    }, [sports]);


    useEffect(() => {
        if (id) {
            dispatch(getInitInfo({rightHolderId: id}));
            dispatch(getFilterValues(id));
        } else {
            dispatch(getInitInfo({rightHolderId: null}));
            dispatch(getFilterValues(null));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        return () => {
            dispatch(clearTable());
        };
    }, [dispatch]);

    const fetchData = (page: number, pageSize: number, scrollPosition: string) => {
        let payload: IGetFixturesPayload = {
            rightHolderId: rightHolderId ?? null,
            searchText: searchWord,
            sorting: {
                property: sorting.criteria ? sorting.criteria : "",
                isAscending: sorting.isAscending
            },
            sportIds: sport.map(item => item.value),
            upcoming: false,
            pageNumber: page,
            itemsPerPage: pageSize
        }

        dispatch(getFixtures({payload, scrollPosition}));
    }

    const handleAddFixtureClick = () => {
        setAddFixtureFormVisible(true);
    }

    const handleEditFixtureClick = (fixture: ITableRowData) => {
        setAddFixtureFormVisible(!addFixtureFormVisible);
        setEditedFixture(fixture);
    }

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchWord(e.currentTarget.value);
    }

    const handleAddToCartClick = (item: ITableRowData) => {
        const itemInCart = rightsHolderCartFixtures.find(fixture => fixture.fixtureId === item.id);

        if (role === Group.RightHolder) {
            if (itemInCart) {
                dispatch(deleteFixtureFromCart(itemInCart.id));
            } else {
                dispatch(addFixtureToCart({fixtureId: item.id}))
            }
        } else if (!itemInCart && id) {
            dispatch(addFixtureToCart({
                rightHolderId: id,
                operatorId: null,
                fixtureId: item.id
            }))
        } else if (itemInCart) {
            dispatch(deleteFixtureFromCart(itemInCart.id));
        }
    }

    const handleRemoveFixtureClick = (item: ITableRowData) => {
        setFixtureToDelete(item);
        setIsDeleteModalOpen(true);
    }

    const removeFixtureFromTable = () => {
        if (fixtureToDelete) {
            const itemInCart = rightsHolderCartFixtures.find(fixture => fixture.fixtureId === fixtureToDelete.id);
            dispatch(removeFixture(fixtureToDelete.id));

            if (itemInCart) {
                dispatch(deleteFixtureFromCart(itemInCart.id));
            }
        }
    }

    const resetFilters = () => {
        setSport([]);
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Enter" && addFixtureFormVisible) {
            event.preventDefault();
        }
    }

    return (
        <>
            <PageHeader menuStructure={menuStructure} title={"Book Fixtures"} users={users}/>
            <div className={styles.page} onKeyDown={handleKeyDown}>
                <div className={styles.pageContainer}>
                    <div className={styles.upperSection}>
                        <h2 className={styles.title}>Book Fixtures</h2>
                        <div className={styles.filters}>
                            <button className={styles.resetFiltersButton} onClick={() => resetFilters()}>Reset Filters
                            </button>
                            <div className={styles.inputWrapper}>
                                <label className={styles.label}>Sport
                                    <SelectInput
                                        onChange={(e) => setSport(e)}
                                        closeMenuOnSelect={false}
                                        options={formatSelectOptions(sports)}
                                        placeholder={"Multi selection"}
                                        value={sport}
                                        isMulti={true}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className={styles.middleSection}>
                        <div className={styles.searchInput}>
                            <Input
                                type="text"
                                placeholder="Search"
                                value={searchWord}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleSearchChange(e)}
                                autoFocus={true}
                            />
                            <img src={searchWhite} alt="search"/>
                        </div>
                        <button className={styles.addFixtureButton} onClick={handleAddFixtureClick}>
                            Add New Fixture
                        </button>
                    </div>

                    <div>
                        <div className={styles.tableSection}>
                            {
                                loadingStatus === "loading" && initial
                                &&
                                <Loader data-testid="loader"/>
                            }
                            {
                                loadingStatus === "idle" && tableData.length === 0
                                &&
                                <NoDataFoundStub
                                    message={sport.length ? "No fixtures found matching the given filter criteria" : null}
                                    textColor="white"
                                />
                            }
                            {
                                tableData.length !== 0
                                &&
                                <BookFixturesTable
                                    containerRef={containerRef}
                                    tableData={tableData}
                                    sorting={sorting}
                                    setSorting={setSorting}
                                    handleRemoveFixtureClick={handleRemoveFixtureClick}
                                    handleEditFixtureClick={handleEditFixtureClick}
                                    handleAddToCartClick={handleAddToCartClick}
                                    fetchData={fetchData}
                                />
                            }
                            {
                                loadingStatus === "failed"
                                &&
                                <ErrorComponent
                                    textColor="white"
                                />
                            }
                        </div>
                    </div>
                    {
                        addFixtureFormVisible &&
                        <AddFixtureForm
                            isUpcoming={false}
                            getSortedItems={getSortedItems}
                            closeForm={() => setAddFixtureFormVisible(false)}
                            editedFixture={editedFixture}
                            setEditedFixture={setEditedFixture}
                            rightHolderId={rightHolderId}
                            searchWord={searchWord}
                            sorting={sorting}
                            sport={sport}
                        />
                    }
                    {
                        isDeleteModalOpen &&
                        <ConfirmModal
                            action={removeFixtureFromTable}
                            title="Delete Fixture"
                            message={"Are you sure you want to delete this fixture?"}
                            closeForm={() => setIsDeleteModalOpen(false)}
                        >
                            <img
                                className={styles.deleteIconBig}
                                src={trash}
                                alt="trash"/>
                        </ConfirmModal>
                    }
                </div>
            </div>
        </>
    )
})

export default BookFixtures;
