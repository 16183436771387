import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {client} from "../../services/api-servise";
import {toastr} from "react-redux-toastr";
import moment from "moment";
import {appendDataHelper, prependDataHelper} from "../../helpers/reducerHelpers";

export interface IFixturesRequest {
    rightHolderId?: number;
    searchText: string;
    pageNumber: number;
    itemsPerPage: number;
    scrollPosition?: string;
}

export interface IFixturesResponse {
    id: string;
    title: string;
    description: string;
    competitionId: string;
    competition: string;
    contentSupplier: string;
    sportId: string;
    sport: string;
    startDate: string;
    endDate: string;
}

export interface IAuditTrailsBookingsState {
    fixtures: IFixturesResponse[]
    loading: boolean;
    totalCount: number;
    itemsPerPage: number;
    pageNumber: number;
    scrollLoader: boolean;
    maxItemsInTheTable: number;
}

const initialState: IAuditTrailsBookingsState = {
    fixtures: [],
    loading: false,
    totalCount: 0,
    itemsPerPage: 15,
    pageNumber: 0,
    scrollLoader: false,
    maxItemsInTheTable: 45,
}

export const getFixturesBookings = createAsyncThunk<IFixturesResponse[], IFixturesRequest, { rejectValue: string }>(
    'audit-trails/bookings-fixtures',
    async ({searchText, itemsPerPage, pageNumber, scrollPosition}, {rejectWithValue, dispatch}) => {
        try {
            const {
                data,
                headers
            } = await client.post("api/fixtures/right-holder/audit", {searchText, itemsPerPage, pageNumber})

            const totalCount = headers['x-total-count']
            if (totalCount) {
                dispatch(setTotalCount(Number(totalCount)));
            }
            if (scrollPosition && scrollPosition === "down") {
                dispatch(appendData(data));
                return;
            }
            if (scrollPosition && scrollPosition === "up") {
                dispatch(prependData(data));
                return;
            }
            return data;
        } catch (e: any) {
            toastr.error('Error!', `${e.response.data}`)
            return rejectWithValue(e.response.data)
        }
    }
)

export const auditTrailsBookingsSlice = createSlice({
    name: 'audit-trails-bookings',
    initialState,
    reducers: {
        skipBookingsSearch: (state) => {
            state.fixtures = [];
        },
        setPageNumber: (state, action: PayloadAction<number>) => {
            state.pageNumber = action.payload
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload
        },
        appendData(state, action) {
            appendDataHelper(state, action.payload, 'fixtures');
        },
        prependData(state, action) {
            prependDataHelper(state, action.payload, 'fixtures');
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFixturesBookings.pending, (state) => {
                state.loading = true;
            })
            .addCase(getFixturesBookings.fulfilled, (state, action) => {
                if (action.payload !== undefined) {
                    state.fixtures = action.payload.map(fixture => ({
                        ...fixture,
                        startDate: moment.utc(fixture.startDate).local().toISOString(),
                        endDate: moment.utc(fixture.endDate).local().toISOString(),
                    }));
                    state.pageNumber = 1;
                }
                state.loading = false;
            })
            .addCase(getFixturesBookings.rejected, (state) => {
                state.loading = false;
            })
    },
})

export const {
    skipBookingsSearch,
    setTotalCount,
    setPageNumber,
    appendData,
    prependData
} = auditTrailsBookingsSlice.actions
export default auditTrailsBookingsSlice.reducer
