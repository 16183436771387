import React, {Dispatch, SetStateAction, useState} from 'react';
import {FaChevronDown} from "react-icons/fa";
import styles from './style.module.scss'
import {IRightAssignment} from "../../../../../../store/rightAssignment/rightAssignmentSlice";
import {Tooltip} from "react-tooltip";
import {useNavigate} from "react-router-dom";
import edit from "../../../../../../img/editWhite.svg";
import trash from "../../../../../../img/trashWhite.svg";
import audit from "../../../../../../img/auditWhite.svg";
import uuid from "react-uuid";
import {checkAuditPageAllowance} from "../../../../../../helpers/checkAuditPageAllowance";

interface IAssignmentAccordionProps {
    providerName: string;
    assignments: IRightAssignment[];
    openConfirm: (assignmentId: number) => void;
    setUpdatingExistingAssignment: Dispatch<SetStateAction<{ rightHolder: string, id: number }>>;
    openUpdateExistingForm: Dispatch<SetStateAction<boolean>>;
}

const AssignmentAccordion: React.FC<IAssignmentAccordionProps> = (
    {
        providerName,
        assignments,
        openConfirm,
        setUpdatingExistingAssignment,
        openUpdateExistingForm,
    }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate();

    const goToAuditTrail = (id: number, rightHolder: string, competition: string) => {
        const encodedRightHolder = encodeURIComponent(rightHolder);
        const encodedCompetition = encodeURIComponent(competition);

        navigate(`/cms/audit-trails-rights-assignment?id=${id}&rightHolder=${encodedRightHolder}&competition=${encodedCompetition}&isExternal=${true}`);
    }

    const onEditAssignment = (assignmentId: number) => {
        setUpdatingExistingAssignment({
            rightHolder: providerName,
            id: assignmentId
        })
        openUpdateExistingForm(true)
    }

    return (
        <>
            <tbody>
            <tr onClick={() => setIsExpanded(!isExpanded)}>
                <td className={styles.accordionCell}>
                    <div
                        className={!isExpanded ? styles.tableChevron : `${styles.tableChevron} ${styles.tableChevronTop}`}>
                        <FaChevronDown size={14}/>
                    </div>
                    {providerName}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td className={styles.actions}></td>
            </tr>
            </tbody>
            {isExpanded && <tbody>
            {assignments.map((assignment) => {
                return (
                    <tr key={uuid()} className={styles.accordionRow}>
                        <td></td>
                        <td className={styles.limitedCell}>{assignment.competitionName}</td>
                        <td className={styles.limitedCell}>{assignment.sportName}</td>
                        <td className={styles.centredCell}>{assignment.availableFixtures}</td>
                        <td className={styles.actions}>
                            <button
                                onClick={() => onEditAssignment(assignment.id)}
                                className={styles.actionBtn}
                                type="button">
                                <img
                                    src={edit}
                                    alt={"edit"}
                                />
                            </button>
                            <button
                                onClick={() => openConfirm(assignment.id)}
                                className={styles.actionBtn}
                                type="button">
                                <img
                                    src={trash}
                                    alt={"delete"}
                                />
                            </button>
                            {
                                checkAuditPageAllowance() &&
                                <button
                                    className={styles.actionBtn}
                                    onClick={() => goToAuditTrail(assignment.id, assignment.rightHolderName, assignment.competitionName)}
                                    type="button">
                                    <Tooltip className={styles.userTableTooltip} id="audit-trail-tooltip"/>
                                    <img
                                        src={audit}
                                        alt={"audit"}
                                        data-tooltip-id="audit-trail-tooltip"
                                        data-tooltip-content={"Go to Audit trails"}/>
                                </button>
                            }
                        </td>
                    </tr>
                )
            })}
            </tbody>}
        </>
    );
};

AssignmentAccordion.displayName = "AssignmentAccordion"
export default AssignmentAccordion;
