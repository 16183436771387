import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {client} from "../../services/api-servise";
import {toastr} from "react-redux-toastr";
import {appendDataHelper, prependDataHelper} from "../../helpers/reducerHelpers";

export interface IUserManagementDetailsResponse {
    userGroup: string;
    user: string;
    userId: string;
}

export interface IUsersRequest {
    rightHolderId?: number;
    searchValue: string;
    pageNumber: number;
    itemsPerPage: number;
    scrollPosition?: string;
}

export interface IAuditTrailsState {
    usersList: IUserManagementDetailsResponse[];
    loading: boolean;
    isDeleted: boolean;
    totalCount: number;
    itemsPerPage: number;
    pageNumber: number;
    scrollLoader: boolean;
    maxItemsInTheTable: number;
}

const initialState: IAuditTrailsState = {
    usersList: [],
    loading: false,
    isDeleted: false,
    totalCount: 0,
    itemsPerPage: 15,
    pageNumber: 0,
    scrollLoader: false,
    maxItemsInTheTable: 45,
}

export const getUsers = createAsyncThunk<IUserManagementDetailsResponse[], IUsersRequest, { rejectValue: string }>(
    'audit-trails-user-management/details',
    async ({searchValue, itemsPerPage, pageNumber, scrollPosition}, {rejectWithValue, dispatch}) => {
        try {
            const {
                data,
                headers
            } = await client.post(`api/Users/audit`, {searchValue, itemsPerPage, pageNumber})

            const totalCount = headers['x-total-count']
            if (totalCount) {
                dispatch(setTotalCount(Number(totalCount)));
            }
            if (scrollPosition && scrollPosition === "down") {
                dispatch(appendData(data));
                return;
            }
            if (scrollPosition && scrollPosition === "up") {
                dispatch(prependData(data));
                return;
            }
            return data;
        } catch (e: any) {
            if (e.response.status === 400) {
                dispatch(setIsDeleted(true));
            } else {
                toastr.error('Error!', `${e.response.data}`);
            }
            return rejectWithValue(e.response.data);
        }
    }
)

export const auditTrailsUserManagementSlice = createSlice({
    name: 'audit-trails-user-management',
    initialState,
    reducers: {
        setIsDeleted: (state, action) => {
            state.isDeleted = action.payload;
        },
        cleanState: (state) => {
            state.usersList = [];
        },
        setPageNumber: (state, action: PayloadAction<number>) => {
            state.pageNumber = action.payload
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload
        },
        appendData(state, action) {
            appendDataHelper(state, action.payload, 'usersList');
        },
        prependData(state, action) {
            prependDataHelper(state, action.payload, 'usersList');
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                if (action.payload !== undefined) {
                    state.usersList = action.payload;
                    state.pageNumber = 1;
                }
                state.loading = false;
                state.isDeleted = false;
            })
            .addCase(getUsers.rejected, (state) => {
                state.loading = false;
                state.usersList = [];
                state.isDeleted = false;
            })
    },
})

export const {
    setIsDeleted,
    cleanState,
    setTotalCount,
    setPageNumber,
    appendData,
    prependData
} = auditTrailsUserManagementSlice.actions
export default auditTrailsUserManagementSlice.reducer
