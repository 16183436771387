import {
    IGroupedCartItem,
    IPropertyCartItem, ISportCartItem,
    removeItemFromCart,
    updateFixtureCount
} from "@store/carts/operatorCart/operatorCartSlice";
import {useAppDispatch} from "@hooks/hooks";
import {useState} from "react";


export const useHandleOperatorCart = (operatorId: string | null) => {
    const [requestedFixtureCounts, setRequestedFixtureCounts] = useState<{ [key: string]: string }>({});
    const dispatch = useAppDispatch();


    const handleInputChange = (propertyId: string, value: string) => {
        setRequestedFixtureCounts(prev => ({
            ...prev,
            [propertyId]: value,
        }));
    };


    const changeRequestedFixturesCount = (property: IPropertyCartItem) => {
        const newValue = requestedFixtureCounts[property.propertyId];
        if (property.requestedFixtureCount === +newValue) return;

        dispatch(updateFixtureCount({
            operatorId,
            cartItemId: property.cartItemId,
            requestedFixtureCount: +newValue
        }))
    };

    const handleDeleteTableItem = (cartItemId: string) => {
        dispatch(removeItemFromCart({
            cartItemId,
            operatorId
        }))
    }

    const handleRequestedFixtures = (cartItems: IGroupedCartItem[]) => {
        const allSports: ISportCartItem[] = [];
        cartItems.forEach(providerItem => {
            allSports.push(...providerItem.sports);
        });

        const allProperties: IPropertyCartItem[] = [];
        allSports.forEach(sport => {
            allProperties.push(...sport.properties);
        });

        const initialCounts: { [key: string]: string } = {};
        allProperties.forEach(property => {
            initialCounts[property.propertyId] = property.requestedFixtureCount.toString()

        });

        return initialCounts;
    }

    return {
        handleInputChange,
        changeRequestedFixturesCount,
        handleDeleteTableItem,
        requestedFixtureCounts,
        setRequestedFixtureCounts,
        handleRequestedFixtures
    }
}
