import {IDate} from "@components/AnalyticsDashboard/types";

export const convertDateForCalendar = (date: string) => {
    const dateObj: {
        day: number;
        hour: number;
        minute: number;
        month: number;
        year: number;
    } = {} as IDate;

    const newDate = new Date(date);

    dateObj.day = newDate.getDate();
    dateObj.hour = newDate.getHours();
    dateObj.minute = newDate.getMinutes();
    dateObj.month = newDate.getMonth() + 1;
    dateObj.year = newDate.getFullYear();

    return dateObj
}
