import {defaultTimeZone} from "../AvailableContent";
import {ISelectOption} from "../../../../../store/operatorBookedContent/operatorBookedContentSlice";
import {IAvailableContentDataReq} from "@store/contentAnalysis/types";


export interface IAvailableContentParams {
    startDate: string;
    endDate: string;
    chartDataType: number;
    contentViewData: number;
    showBookedOnly: boolean;
    timeZone?: ISelectOption;
    sportIds?: string[];
    rightHolders?: string[];
    operatorId?: string;
    competitions?: string[]
    sorting?: {
        property: string;
        isAscending: boolean;
    };
    userId?: string
}

export const generateGetContentPayload = (params: IAvailableContentParams) => {
    const payload: IAvailableContentDataReq = {
        showBookedOnly: params.showBookedOnly,
        startDate: params.startDate,
        endDate: params.endDate,
        timeZone: '',
        chartDataType: params.chartDataType,
        contentViewData: params.contentViewData,
    }

    if (params.timeZone !== undefined) {
        payload.timeZone = params.timeZone.value;
    } else {
        payload.timeZone = defaultTimeZone;
    }

    if (params.sportIds && params.sportIds.length > 0) {
        payload.sportIds = params.sportIds;
    }

    if (params.rightHolders && params.rightHolders.length > 0) {
        payload.rightHolders = params.rightHolders;
    }

    if (params.operatorId) {
        payload.operatorId = params.operatorId;
    }

    if (params.competitions && params.competitions.length > 0) {
        payload.competitions = params.competitions;
    }

    if (params.sorting) {
        payload.sortingDto = {
            property: params.sorting.property,
            isAscending: params.sorting.isAscending
        }
    }

    if (params.userId) {
        payload.userId = params.userId;
    }

    return payload;
};
