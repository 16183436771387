import React, {useEffect, useState} from 'react';
import commonStyles from "../children/AuditTrailPageInner/index.module.scss";
import {useAuditingEntitiesSearch} from "../hooks/useAuditingEntitiesSearch";
import {useLocation, useNavigate} from "react-router-dom";
import {IMenuStructure} from "../../../pages/Cms/AppRoutes/routes/RouteInterface";
import {
    getOperatorAccessAudit, IAuditOperatorAccess,
    skipOperatorAccessSearch
} from "../../../store/auditTrailsOperatorAccess/auditTrailsOperatorAccess";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {usePricingAndAccessDetailsFromQueryString} from "../hooks/usePricingAndAccessDetailsFromQueryString";
import AuditTrailPageInner from "../children/AuditTrailPageInner/AuditTrailPageInner";
import AuditTrailsTable from "../children/AuditTrailsTable/AuditTrailsTable";
import OperatorAccessTable from "./children/OperatorAccessTable/OperatorAccessTable";
import SearchByTextInput from "../../SearchByTextInput/SearchByTextInput";
import {getAuditTrails} from "../../../store/auditTrailsCommon/auditTrailsCommonSlice";
import {RecordTypes} from "../../../enums/recordTypes";

export interface IAuditTrailsOperatorAccessProps {
    menuStructure: IMenuStructure;
}

const AuditTrailsOperatorAccess: React.FC<IAuditTrailsOperatorAccessProps> = ({menuStructure}) => {
    const [initial, setInitial] = useState(true);
    const [accessId, setAccessId] = useState<string | null>(null);
    const {pageNumber, itemsPerPage} = useAppSelector(state => state.auditTrailsOperatorAccess);
    const {searchText, setSearchText} = useAuditingEntitiesSearch(
        getOperatorAccessAudit,
        pageNumber,
        itemsPerPage,
        skipOperatorAccessSearch,
        initial,
        setInitial,
        accessId,
        setAccessId
    );
    const {setRecordData, recordData} = usePricingAndAccessDetailsFromQueryString();
    const {trailsItemsPerPage} = useAppSelector(state => state.auditTrailsCommon);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const query = new URLSearchParams(useLocation().search);
    const queryId = query.get("id");
    const isExternal = !!query.get("isExternal");

    useEffect(() => {
        dispatch(skipOperatorAccessSearch());
    }, [dispatch]);

    useEffect(() => {
        setAccessId(queryId);
    }, [queryId]);

    useEffect(() => {
        if (accessId) {
            dispatch(getAuditTrails({
                auditRecordType: RecordTypes.Access,
                recordId: accessId,
                pageNumber: 1,
                itemsPerPage: trailsItemsPerPage,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessId]);

    const handleAccessSelection = (access: IAuditOperatorAccess) => {
        setAccessId(access.id);
        setRecordData({
            rightHolder: access.rightHolder,
            operator: access.operator,
            competition: access.competition
        })
    }

    const handleTableDataSearch = (text: string) => {
        setRecordData(null);
        setSearchText(text);
    }

    const handleGoBack = () => {
        navigate(-1);
    }

    return (
        <AuditTrailPageInner
            pageTitle={"Audit Trails"}
            pageSubTitle={"Content Access"}
            pageHeader={"Audit Trails - Content Access"}
            menuStructure={menuStructure}
            backButtonAction={handleGoBack}
            isExternal={isExternal}>
            {
                recordData && accessId &&
                <div className={commonStyles.recordInfo} key="details">
                    <p className={commonStyles.recordInfoItem}><span>Rights Holder: </span>{recordData?.rightHolder}</p>
                    <p className={commonStyles.recordInfoItem}><span>Operator: </span>{recordData?.operator}</p>
                    <p className={commonStyles.recordInfoItem}><span>Property: </span>{recordData?.competition}</p>
                </div>
            }
            <div className={commonStyles.searchInput} key="inputs-container">
                <SearchByTextInput
                    value={searchText}
                    onChange={(e) => handleTableDataSearch(e)}/>
            </div>
            {
                accessId
                    ?
                    <AuditTrailsTable
                        height="38vh"
                        auditRecordType={RecordTypes.Access}
                        recordId={accessId}
                    />
                    :
                    <OperatorAccessTable
                        handleAccessSelection={handleAccessSelection}
                        searchText={searchText}
                    />
            }
        </AuditTrailPageInner>
    );
}

AuditTrailsOperatorAccess.displayName = "AuditTrailsOperatorAccess"
export default AuditTrailsOperatorAccess;
