import {IMainAnalyticsDataResponse} from "../../../../store/igameAnalytics/igameAnalyticsSlice";
import React from "react";
import styles from './index.module.scss';

interface IBreakDownInfoProps {
    info: IMainAnalyticsDataResponse | null
}

const BreakDownInfo: React.FC<IBreakDownInfoProps> = ({info}) => {
    return (
        <div className={styles.background}>
            <div className={styles.header}>
                <h6>Operating System</h6>
                <h6>Device</h6>
                <h6>Unique Users</h6>
                <h6>Total Views</h6>
                <h6>Views Per Event</h6>
                <h6>Country</h6>
            </div>
            <div className={styles.statsGrid}>
                <div className={styles.statsCell}>
                    {info && Object.entries(info.osBreakdown).map(([key, value]) => (
                        <div key={key} className={styles.stats}>
                            <div className={styles.name}>{key}</div>
                            <div className={styles.value}>{value.toLocaleString("en-US")}</div>
                        </div>
                    ))}
                </div>
                <div className={styles.statsCell}>
                    {info && Object.entries(info.deviceBreakdown).map(([key, value]) => (
                        <div key={key} className={styles.stats}>
                            <div className={styles.name}>{key}</div>
                            <div className={styles.value}>{value.toLocaleString("en-US")}</div>
                        </div>
                    ))}
                </div>
                {info &&
                    <>
                        <div className={styles.statsCell}>
                            <div
                                className={styles.oneStat}>{info.mapBreakdown.uniqueUsers.toLocaleString("en-US")}</div>
                        </div>
                        <div className={styles.statsCell}>
                            <div className={styles.oneStat}>{info.mapBreakdown.totalViews.toLocaleString("en-US")}</div>
                        </div>
                        <div className={styles.statsCell}>
                            <div
                                className={styles.oneStat}>{info.mapBreakdown.viewPerEvent.toLocaleString("en-US")}</div>
                        </div>
                        <div className={styles.statsCell}>
                            <div className={styles.oneStat}>{info.mapBreakdown.topCountry}</div>
                        </div>
                    </>
                }

            </div>
        </div>
    );
};

export default BreakDownInfo;
