import {IFixturesState, IIngestOperationResponse} from "../store/rightHolderBooking/rightHolderBookingSlice";
import {IngestOptionId} from "../enums/ingest";

export const appendDataHelper = (state: any, data: any[], key: string) => {
    if (data.length > 0 && state[key].length >= state.maxItemsInTheTable) {
        state[key] = state[key].slice(state.itemsPerPage);
        state[key] = [...state[key], ...data];
    } else {
        state[key] = [...state[key], ...data];
    }
    state.scrollLoader = false;
};

export const prependDataHelper = (state: any, data: any[], key: string) => {
    const fromLastPage = Math.ceil(state.totalCount / state.itemsPerPage) - state.maxItemsInTheTable / state.itemsPerPage === state.pageNumber;

    if (fromLastPage) {
        state[key] = [...data, ...state[key]];
        const rest = state.totalCount % state.itemsPerPage;
        if (rest === 0) {
            state[key] = state[key].slice(0, -data.length);
        } else {
            state[key] = state[key].slice(0, -rest);
        }
    } else if (data.length > 0 && state[key].length >= state.maxItemsInTheTable) {
        state[key] = [...data, ...state[key]];
        state[key] = state[key].slice(0, -data.length);
    } else {
        state[key] = [...data, ...state[key]];
    }
    state.scrollLoader = false;
};

export const updateIngestState = (
    state: IFixturesState,
    payload: IIngestOperationResponse[],
    ingestType: IngestOptionId
) => {
    const ingestMap = new Map(payload.map(ingest => [ingest.fixtureId, ingest]));

    state.source = state.source.map(fixture => {
        const ingestOperation = ingestMap.get(fixture.id);

        if (ingestOperation) {
            fixture.ingestTypeDetails = {
                ingestType: ingestOperation.ingestType,
                details: ingestOperation.details,
            };
            fixture.ingestType = ingestType;
        }

        return fixture;
    });
}