import {
    IBTTowerDetailsControls,
    IHandleIpDetailsProps, IIngestDetailsProps, IMethodPayloads,
    ISelectedMethods
} from "../types/types";
import {Ingest, IngestFormController, IngestOptionId} from "@enums/ingest";
import {ingestOptions} from "../IngestForm";
import React from "react";
import {
    IIngestBackUpResponse,
    IIngestDetails,
} from "@store/rightHolderBooking/rightHolderBookingSlice";
import {formatSelectOptions} from "@helpers/formatSelectOptions";
import {hasNestedObjectData} from "@helpers/hasNestedObjectData";
import {IIpChannel} from "@store/init/initSlice";
import {FieldValues, UseFormSetValue} from "react-hook-form";
import {IIngestFormData} from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/BookFixtures";

class IngestDetails {
    handleDetails(props: IIngestDetailsProps) {
        const {backupDetails, setIngestType, setValue, details, setMethodPayloads} = props;
        this.handleIP({details, backupDetails, setIngestType, setValue})
        this.handleBTTower({setMethodPayloads, setIngestType, setValue}, details, backupDetails)
        this.handleSatellite(props);
    }

    handleIP(props: IHandleIpDetailsProps) {
        const {details, setIngestType, backupDetails, setValue} = props;

        if (details.ip || backupDetails.ipFixture) {
            setIngestType({BTTower: false, satellite: false, IP: true});
        }
        if (details.ip) {
            setValue(IngestFormController.PrimaryMethod, {value: IngestOptionId.IP, label: Ingest.IP})
        }
        if (backupDetails.ipFixture) {
            setValue(IngestFormController.BackupMethod, {value: IngestOptionId.IP, label: Ingest.IP})
        }
    }

    handleBTTower(props: IBTTowerDetailsControls, details: IIngestDetails, backupDetails: IIngestBackUpResponse) {
        const {setIngestType, setMethodPayloads, setValue} = props;

        if (backupDetails.btTowerFixture) {
            setIngestType({BTTower: true, satellite: false, IP: false})
            setValue(IngestFormController.BackupMethod, {
                value: String(IngestOptionId.BTTower),
                label: Ingest.BTTower
            });
            setValue(IngestFormController.BackupSource, backupDetails.btTowerFixture.source);
            setMethodPayloads(prevPayloads => (
                {
                    ...prevPayloads,
                    BTTowerBackup: {fixture: backupDetails.btTowerFixture}
                }
            ));
        }
        if (details.btTower) {
            setIngestType({BTTower: true, satellite: false, IP: false});
            setValue(IngestFormController.PrimaryMethod, {
                value: String(IngestOptionId.BTTower),
                label: Ingest.BTTower
            });
            setValue(IngestFormController.PrimarySource, details.btTower.source);
        }
    }

    handleSatellite(props: IIngestDetailsProps) {
        const {backupDetails, setMethodPayloads, details, setIngestType, setValue} = props;

        if (hasNestedObjectData(details.satellite) || hasNestedObjectData(backupDetails.satelliteFixture)) {
            setIngestType({BTTower: false, satellite: true, IP: false});
        }
        if (details.satellite && details) {
            setValue(IngestFormController.PrimaryMethod, {
                value: String(IngestOptionId.Satellite),
                label: Ingest.Satellite
            })
            setMethodPayloads((prevPayloads: IMethodPayloads) => (
                {
                    ...prevPayloads,
                    Satellite: {fixture: details.satellite!}
                }
            ));
        }
        if (hasNestedObjectData(backupDetails.satelliteFixture)) {
            setValue(IngestFormController.BackupMethod, {
                value: String(IngestOptionId.Satellite),
                label: Ingest.Satellite
            })
        }
    }

    setDefaultSelectedMethod(ingestFormData: IIngestFormData, setSelectedMethods: React.Dispatch<React.SetStateAction<ISelectedMethods>>) {
        if (ingestFormData.fixture) {
            const method = ingestOptions.find(opt => opt.value === ingestFormData.fixture?.ingestType)
            const backupMethod = ingestOptions.find(opt => opt.value === ingestFormData.fixture?.backupIngestType)
            if (!method && backupMethod) {
                return setSelectedMethods({
                    primaryType: null,
                    backupType: backupMethod,
                });
            }

            if (method && !backupMethod) {
                return setSelectedMethods({
                    primaryType: method,
                    backupType: null,
                });
            }

            if (method && backupMethod) {
                setSelectedMethods({primaryType: method, backupType: backupMethod});
            }
        }
    }

    setPrimaryIpChannel(details: IIngestDetails, channels: IIpChannel[], setValue: UseFormSetValue<FieldValues>) {
        const primaryChannel = formatSelectOptions(channels).find(channel => channel.value === details.ip?.ingestResourceId);
        if (primaryChannel) {
            setValue(IngestFormController.PrimaryIPChannels, primaryChannel);
        }
    }

    setBackupIPChannels(details: IIngestBackUpResponse, channels: IIpChannel[], setValue: UseFormSetValue<FieldValues>) {
        const backupChannel = formatSelectOptions(channels).find(channel => channel.value === details.ipFixture.ingestResourceId);
        if (backupChannel) {
            setValue(IngestFormController.BackupIPChannels, backupChannel);
        }
    }
}

const ingestDetailsInstance = new IngestDetails();
export default ingestDetailsInstance;
