import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface IState {
    cartIsOpen: boolean
}

const initialState: IState = {
    cartIsOpen: false
}

export const headerActionsSlice = createSlice({
    name: "header-actions",
    initialState,
    reducers: {
        setCartVisibilityState: (state, action: PayloadAction<boolean>) => {
            state.cartIsOpen = action.payload;
        }
    }
})

export const {
    setCartVisibilityState,
} = headerActionsSlice.actions;
export default headerActionsSlice.reducer;
