import React, {ChangeEvent} from "react";
import styles from "./index.module.scss";
import {IoCartOutline} from "react-icons/io5";
import search from "../../img/search.svg";
import Input from "../Input/Input";

interface IMenuItem {
    name: string,
    action: () => void
}

export interface IBookingComponentHeaderProps {
    cartIsNotEmpty?: boolean;
    data: any[];
    searchWord: string;
    handleSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleCartClick?: () => void;
    title: string;
    menu?: IMenuItem[];
    upcoming: boolean;
    isAuditBookingAuditTrails?: boolean;
}

const BookingComponentHeader: React.FunctionComponent<IBookingComponentHeaderProps> = (
    {
        cartIsNotEmpty,
        data,
        handleSearchChange,
        handleCartClick,
        searchWord,
        title,
        menu,
        upcoming,
        isAuditBookingAuditTrails
    }) => {

    const bookingAuditTrailsTitleStyles = {
        marginBottom: isAuditBookingAuditTrails ? '45px' : 'initial'
    };

    const generateInput = () => {
        return (
            <div className={styles.searchInput}>
                <Input
                    type="text"
                    style={{border: "1px solid #E0E0E0"}}
                    placeholder="What are you looking for today?"
                    value={searchWord}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleSearchChange(e)}
                    autoFocus={true}
                />
                <img src={search} alt="search"/>
            </div>
        )
    }

    return (
        <>
            <h1 style={bookingAuditTrailsTitleStyles} className={styles.title}>{title}</h1>
            <div className={styles.options}>
                {
                    isAuditBookingAuditTrails ?
                        <div className={styles.searchBar}>{generateInput()}</div>
                        :
                        generateInput()
                }

                {
                    !upcoming &&
                    <IoCartOutline onClick={handleCartClick}/>
                }
                {
                    cartIsNotEmpty &&
                    <div className={styles.cartMark}></div>
                }
            </div>
            {
                !upcoming &&
                <div className={styles.menu}>
                    <div>{data.filter(x => x.checked).length} items selected</div>
                    {
                        menu!.map((x, index) => (
                            <div className={styles.menuButton} onClick={x.action} key={index}>{x.name}</div>
                        ))
                    }
                </div>
            }
        </>
    )
}

BookingComponentHeader.displayName = "BookingComponentHeader"
export default BookingComponentHeader;
