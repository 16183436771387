import {ISelectItem} from "../../../../../../store/userManagement/userManagementSlice";


export const formatPortsOptions = (ports: ISelectItem[]) => {
    let dropdownPorts = ports.map(port => {
        return {
            value: port.id.toString(),
            label: port.name
        }
    })

    dropdownPorts.unshift({
        value: '0',
        label: ''
    })

    return dropdownPorts
}
