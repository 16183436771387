import React, {useEffect, useRef, useState} from 'react';
import styles from './index.module.scss';
import DescriptionHeader from "../../RightsHolderView/Billing/components/DescriptionHeader/DescriptionHeader";
import RangePicker from "../../../../components/RangePicker/RangePicker";
import BillingAccordion from "../../IGameView/Billing/OperatorBilling/children/BillingAccordion/BillingAccordion";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {getBilling, getBillingCSV} from "../../../../store/iGameOperatorBilling/iGameOperatorBillingSlice";
import {getMonthRange, formatRangeDate} from '../../../../helpers/dateFormatters';
import {pdfFormatter} from "../../../../helpers/pdfFormatter";
import {IPageMenuProps} from "../../AppRoutes/routes/RouteInterface";
import {IRangeDate} from "../ConcurrencyInspector/ConcurrencyTable/ConcurrencyTable";

const totalDataTitles = ['Total Fixtures', 'Stream Delivered', 'Ad Hoc Bookings', 'Providers', 'Hours Delivered'];

const Billing: React.FC<IPageMenuProps> = ({menuStructure}) => {
    const initialDate = getMonthRange();
    const [date, setDate] = useState<IRangeDate>(initialDate);
    const {
        operatorBillingData,
        totalData,
        billingCSV,
        csvFileName
    } = useAppSelector(state => state.iGameOperatorBilling);
    const [isExpandedAccordion, setIsExpandedAccordion] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const pdfLayout = useRef(null);


    useEffect(() => {
        if (!date.from || !date.to) {
            return;
        }

        const body = {
            startDate: formatRangeDate(date.from),
            endDate: formatRangeDate(date.to)
        }
        dispatch(getBilling(body));
        dispatch(getBillingCSV(body));
    }, [date, dispatch]);


    const pdfHandler = () => {
        setIsExpandedAccordion(true);

        setTimeout(() => {
            const layout: any = pdfLayout.current;
            pdfFormatter(layout);

            setIsExpandedAccordion(false);
        })
    }
    return (
        <div className={styles.pageWrapper} ref={pdfLayout}>
            <DescriptionHeader
                csvFileName={csvFileName}
                pageTitle={'Billing'}
                headerTitle={'Billing'}
                totalDataTitles={totalDataTitles}
                totalData={totalData}
                cvsFile={billingCSV}
                pdfHandler={pdfHandler}
                menuStructure={menuStructure}
            />

            <div className={styles.pageContainer}>
                <div className={styles.tableContainer}>

                    <div className={styles.rangeContainer}>
                        <div className={styles.range}>
                            <RangePicker
                                onChange={setDate}
                                initValue={date}
                                isCalendarModalDown={true}
                            />
                        </div>
                    </div>

                    <table className={styles.billingTable}>
                        <thead>
                        <tr>
                            <th>Provider</th>
                            <th>Property</th>
                            <th>Title</th>
                            <th>Number of Bookings</th>
                            <th>Number of Streams</th>
                            <th>Number of Ad Hoc Bookings</th>
                            <th>Total (£)</th>
                            <th></th>
                        </tr>
                        </thead>

                        <tbody>
                        {
                            operatorBillingData.map(item => (
                                <BillingAccordion
                                    billingData={item}
                                    isExpandedAccordion={isExpandedAccordion}
                                />
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Billing;
