import {AnyAction, createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {toastr} from "react-redux-toastr";
import {client} from "@services/api-servise";
import {AxiosError} from "axios";
import {IGroupedCartItem} from "@store/carts/operatorCart/operatorCartSlice";
import {IUpcomingFixturesPayload} from "@store/propertyDetail/propertyDetail";


interface IRemoveFixtureReq {
    operatorId: string | null,
    cartItemId: string
}

interface IAddFixtureReq {
    payload: {
        fixtureId: string,
        operatorId: string | null
    },
    propertyDetailsPayload?: IUpcomingFixturesPayload,
    setRemainingSelection: () => void
}

export interface IBFCartState {
    bfCartItems: IGroupedCartItem[],
    isLoading: boolean;
    error: string;
}

const initialState: IBFCartState = {
    bfCartItems: [],
    isLoading: false,
    error: "",
}

export const getBFCartItems = createAsyncThunk<IGroupedCartItem[], string | null, { rejectValue: string }>(
    'book-fixture-cart/get-bf-cart-items',
    async (operatorId, {rejectWithValue}) => {
        try {
            if (operatorId) {
                const {data} = await client.get(`api/shopping-cart/balance-cart/operator?operatorId=${operatorId}`)
                return data
            } else {
                const {data} = await client.get(`api/shopping-cart/balance-cart/operator`)
                return data
            }
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const addFixtureToBFCart = createAsyncThunk<string, IAddFixtureReq, { rejectValue: string }>(
    'book-fixture-cart/add-fixture-to-cart',
    async (body, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.post(`api/shopping-cart/add-to-balance-cart`, body.payload)
            dispatch(getBFCartItems(body.payload.operatorId ? body.payload.operatorId : null))
            body.setRemainingSelection();
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const removeFixtureFromCart = createAsyncThunk<any, IRemoveFixtureReq, { rejectValue: string }>(
    'book-fixture-cart/remove-fixture-from-cart',
    async (payload, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.delete(`api/shopping-cart/balance-cart/remove/${payload.cartItemId}`)
            dispatch(getBFCartItems(payload.operatorId))
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const bookFixtureCartSlice = createSlice({
    name: 'book-fixture-cart',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getBFCartItems.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getBFCartItems.fulfilled, (state, action) => {
                state.isLoading = false
                state.bfCartItems = action.payload
            })
            .addMatcher(isError, (state, action: PayloadAction<string>) => {
                state.error = action.payload;
            })
    },
})

export default bookFixtureCartSlice.reducer

function isError(action: AnyAction) {
    return action.type.endsWith("rejected")
}
