import axios from "axios";

export const formClient = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT
})
formClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('book-token');
        if (token) { // @ts-ignore
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
)

formClient.interceptors.response.use(
    (config) => {
        return config;
    },
    async (error) => {
        const originalRequest = error.config
        if (error.response.status === 401 && !error.config._isRetry) {
            originalRequest._isRetry = true
            const accessToken = await refreshToken();
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            return formClient.request(originalRequest);
        }
        return Promise.reject(error);
    }
)


const refreshToken = async () => {
    const refreshToken = localStorage.getItem("book-refresh");

    try {
        const refreshResp = await formClient.post(`api/Auth/refresh`, {refreshToken})

        if (refreshResp.status === 200) {
            localStorage.setItem('book-token', refreshResp.data.accessToken);
            localStorage.setItem('book-refresh', refreshResp.data.refreshToken);
            return refreshResp.data.accessToken;
        }
        if (refreshResp.status === 400) {
            localStorage.removeItem('book-token');
            localStorage.removeItem('book-refresh');
            window.location.href = `/`
        }

    } catch (e) {
        localStorage.removeItem('book-token');
        localStorage.removeItem('book-refresh');
        window.location.href = `/`
    }
}


