import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {client} from "../../services/api-servise";
import {toastr} from "react-redux-toastr";
import {appendDataHelper, prependDataHelper} from "../../helpers/reducerHelpers";

export interface IAuditPricingRecordsResponse {
    records: IAuditPricingRecord[];
    totalCount: number;
}

export interface IAuditPricingRecord {
    id: string;
    rightHolder: string,
    competition: string,
    operator: string
}

export interface IPricingRecordsRequest {
    rightHolderId?: number;
    searchText: string;
    pageNumber: number;
    itemsPerPage: number;
    scrollPosition?: string;
}

interface IAuditTrailsPricingState {
    pricingAuditList: IAuditPricingRecord[];
    isLoadingPricing: boolean;
    isLoadingPricingDetails: boolean;
    totalCount: number;
    itemsPerPage: number;
    pageNumber: number;
    scrollLoader: boolean;
    maxItemsInTheTable: number;
}

const initialState: IAuditTrailsPricingState = {
    pricingAuditList: [],
    isLoadingPricing: false,
    isLoadingPricingDetails: false,
    totalCount: 0,
    itemsPerPage: 15,
    pageNumber: 0,
    scrollLoader: false,
    maxItemsInTheTable: 45,
}

export const getPricingAudit = createAsyncThunk<IAuditPricingRecordsResponse, IPricingRecordsRequest, {
    rejectValue: string
}>(
    'get-pricing-audit',
    async ({searchText, itemsPerPage, pageNumber, scrollPosition}, {rejectWithValue, dispatch}) => {
        try {
            const {
                data,
                headers
            } = await client.post("api/RightsManagement/audit/operator-pricings", {
                searchText,
                itemsPerPage,
                pageNumber
            });

            const totalCount = headers['x-total-count']
            if (totalCount) {
                dispatch(setTotalCount(Number(totalCount)));
            }
            if (scrollPosition && scrollPosition === "down") {
                dispatch(appendData(data));
                return;
            }
            if (scrollPosition && scrollPosition === "up") {
                dispatch(prependData(data));
                return;
            }
            return data;
        } catch (e: any) {
            toastr.error('Error!', `${e.response.data}`)
            return rejectWithValue(e.response.data)
        }
    }
)

export const auditTrailsPricingSlice = createSlice({
    name: 'audit-trails-pricing',
    initialState,
    reducers: {
        skipPricingAuditSearch: (state) => {
            state.pricingAuditList = [];
        },
        setPageNumber: (state, action: PayloadAction<number>) => {
            state.pageNumber = action.payload
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload
        },
        appendData(state, action) {
            appendDataHelper(state, action.payload, 'pricingAuditList');
        },
        prependData(state, action) {
            prependDataHelper(state, action.payload, 'pricingAuditList');
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPricingAudit.pending, (state) => {
                state.isLoadingPricing = true;
            })
            .addCase(getPricingAudit.fulfilled, (state, action) => {
                if (action.payload !== undefined) {
                    state.pricingAuditList = action.payload.records;
                    state.pageNumber = 1;
                }
                state.isLoadingPricing = false;
            })
    },
})


export const {
    skipPricingAuditSearch,
    appendData,
    prependData,
    setPageNumber,
    setTotalCount
} = auditTrailsPricingSlice.actions
export default auditTrailsPricingSlice.reducer
