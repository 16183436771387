import {
    homeAwayNationsCode
} from "../pages/Cms/RightsHolderView/GeoRestrictions/OperatorGeoRestrictions/OperatorGeoRestrictions";


export const formatRestrictedCountries = (options: any[]) => {
    if (options) {
        let countries = options;
        countries.unshift({
            value: homeAwayNationsCode,
            label: 'Home/Away Nations'
        })

        return countries
    } else {
        return []
    }
}
