import React from 'react';
import styles from "./index.module.scss"

interface IFormLabelProps {
    text: string
}

const FormLabel: React.FC<IFormLabelProps> = ({text}) => {
    return (
        <div className={styles.label} data-testid={"formLabel"}>
            {
                text
            }
        </div>
    );
};

export default FormLabel;
