import React, {useState} from 'react';
import {IRightPricingItem} from "../../../../../../store/operatorPricing/operatorPricingSlice";
import {IUserItem} from "../../../../../../store/iGameInit/iGameInitSlice";
import {useNavigate} from "react-router-dom";
import styles from "./index.module.scss";
import {FaChevronDown, FaRegTrashAlt} from "react-icons/fa";
import {Tooltip} from "react-tooltip";
import {PricingTablesNames} from "../../Pricing";
import audit from "../../../../../../img/auditWhite.svg";
import {checkAuditPageAllowance} from "../../../../../../helpers/checkAuditPageAllowance";

interface ITableAccordionProps {
    operatorName: string
    priceItems: IRightPricingItem[]
    openConfirm: (price: IRightPricingItem, tableName: string) => void
    rightHolderId: string | null
    users?: IUserItem[];
}

const TableAccordion: React.FC<ITableAccordionProps> = React.memo((
    {
        operatorName,
        priceItems,
        openConfirm,
        rightHolderId,
        users
    }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate();

    const goToAuditTrail = (id: string, operator: string, competition: string): void => {
        if (users) {
            const rightHolder = users.find(item => item.id === rightHolderId);
            const encodedRightHolder = rightHolder ? encodeURIComponent(rightHolder.name) : "";
            const encodedOperator = encodeURIComponent(operator);
            const encodedCompetition = encodeURIComponent(competition);
            navigate(`/cms/audit-trails-pricing?id=${id}&rightHolder=${encodedRightHolder}&operator=${encodedOperator}&competition=${encodedCompetition}&isExternal=${true}`);
        } else {
            navigate(`/cms/audit-trails-pricing?isExternal=${true}`)
        }
    }

    return (
        <>
            <tbody>
            <tr onClick={() => setIsExpanded(!isExpanded)}>
                <td className={styles.accordionCell}>
                    <div
                        className={!isExpanded ? styles.tableChevron : `${styles.tableChevron} ${styles.tableChevronTop}`}>
                        <FaChevronDown size={14}/>
                    </div>
                    {operatorName}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className={styles.actions}></td>
            </tr>
            </tbody>

            {isExpanded && <tbody>
            {priceItems?.map(price => {
                return (
                    <tr key={price.id} className={styles.accordionRow}>
                        <td></td>
                        <td>{price.rightName}</td>
                        <td>{price.minPPF}</td>
                        <td>{price.maxPPF}</td>
                        <td>{price.adHoc}</td>
                        <td>
                            <div className={styles.actions}>
                                <button onClick={() => openConfirm(price, PricingTablesNames.EXISTING_PRICING)}
                                        className={styles.deleteBtn}
                                        type="button">
                                    <FaRegTrashAlt color={'FFFFFF'}
                                                   size={15}/>
                                </button>
                                {
                                    checkAuditPageAllowance() &&
                                    <button
                                        className={styles.deleteBtn}
                                        onClick={() => goToAuditTrail(price.id, operatorName, price.rightName)}
                                        type="button">
                                        <Tooltip className={styles.userTableTooltip} id="audit-trail-tooltip"/>
                                        <img
                                            src={audit}
                                            alt={"audit"}
                                            data-tooltip-id="audit-trail-tooltip"
                                            data-tooltip-content={"Audit Trail"}/>
                                    </button>
                                }
                            </div>
                        </td>
                    </tr>
                )
            })}
            </tbody>}
        </>
    );
});

export default TableAccordion;
TableAccordion.displayName = "TableAccordion"
