import {
    IColumnHeaderItem
} from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/children/SortingHeader/SortingHeader";

export enum OrderByProperty {
    Date = "Date",
    Sport = "Sport",
    Property = "Property",
    Title = "Title"
}

export const headerControls: IColumnHeaderItem[] = [
    {
        title: "Start Date",
        criteria: OrderByProperty.Date,
        textAlign: "left"
    },
    {
        title: "Line-Up UTC",
        criteria: null,
        textAlign: "left"
    },
    {
        title: "Sport",
        criteria: OrderByProperty.Sport,
        textAlign: "left"
    },
    {
        title: "Property",
        criteria: OrderByProperty.Property,
        textAlign: "left"
    },
    {
        title: "Title",
        criteria: OrderByProperty.Title,
        textAlign: "left"
    },
    {
        title: "",
        criteria: null,
        textAlign: "left"
    },
    {
        title: "Verified",
        criteria: null,
        textAlign: "center"

    },
    {
        title: "Setup",
        criteria: null,
        textAlign: "center"
    }
]
