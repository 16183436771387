import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import Checkbox from "../../../../../components/Checkbox";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import styles from './style.module.scss'
import {
    confirmAssignments,
    deleteAssignment,
    getUnconfirmedAssignments
} from "../../../../../store/rightAssignment/rightAssignmentSlice";
import {toastr} from "react-redux-toastr";
import trash from "../../../../../img/trashWhite.svg";
import edit from "../../../../../img/editWhite.svg";
import ConfirmModal from "../../../../../components/ConfirmModal/ConfirmModal";

interface ICreateAssignmentTableProps {
    setUpdatingAssignment: Dispatch<SetStateAction<number>>
    openAssignmentForm: Dispatch<SetStateAction<boolean>>
}

const CreateAssignmentTable: React.FC<ICreateAssignmentTableProps> = ({setUpdatingAssignment, openAssignmentForm}) => {
    const dispatch = useAppDispatch()
    const {unconfirmedAssignments} = useAppSelector(state => state.rightAssignment)
    const [selectedAssignments, setSelectedAssignments] = useState<number[]>([]);
    const [isOpenConfirm, setIsOpenConfirm] = useState(false);
    const [deletedAssignment, setDeletedAssignment] = useState(0);

    useEffect(() => {
        dispatch(getUnconfirmedAssignments())
    }, [dispatch]);

    const selectPricing = (e: React.MouseEvent<HTMLDivElement>, assignmentId: number) => {
        e.preventDefault()
        e.stopPropagation()
        const isExistAssignmentInList = selectedAssignments.find(assignment => assignment === assignmentId)
        if (isExistAssignmentInList) {
            const allAssignment = selectedAssignments.filter(assignment => assignment !== assignmentId)
            setSelectedAssignments(allAssignment)
        } else {
            const onSelectedAssignment = unconfirmedAssignments.find(assignment => assignment.id === assignmentId)
            if (onSelectedAssignment) {
                setSelectedAssignments([...selectedAssignments, onSelectedAssignment.id])
            }
        }
    }

    const checkCheckbox = (assignmentId: number) => {
        const isChecked = selectedAssignments.find(assignment => assignment === assignmentId)
        return !!isChecked
    }

    const onSetAssignment = (assignmentId: number) => {
        setUpdatingAssignment(assignmentId)
        openAssignmentForm(true)
    }

    const onConfirmAssignments = () => {
        if (selectedAssignments.length > 0) {
            dispatch(confirmAssignments(selectedAssignments))
            setSelectedAssignments([])
        } else {
            return toastr.warning("IGame", "There are no chosen assignments")
        }
    }

    const cancelSelectedAssignments = () => {
        if (selectedAssignments.length > 0) {
            setSelectedAssignments([])
        } else {
            return toastr.warning("IGame", "There are no changes to cancel")
        }
    }

    const deleteUnconfirmedAssignment = () => {
        const allAssignmentsInList = selectedAssignments.filter(assignment => assignment !== deletedAssignment)
        setSelectedAssignments(allAssignmentsInList)
        dispatch(deleteAssignment(deletedAssignment))
    }

    const selectAllAssignments = () => {
        const allAssignment = unconfirmedAssignments.map(assignment => assignment.id)
        if (allAssignment.length === selectedAssignments.length) {
            return toastr.warning("IGame", "All assignments are already selected")
        }
        setSelectedAssignments(allAssignment)
    }

    return (
        <div className={styles.tableOverlay}>
            {isOpenConfirm && <ConfirmModal
                title={"Delete unconfirmed assignment"}
                message={"Are you sure you want to delete assignment"}
                closeForm={() => setIsOpenConfirm(false)}
                action={deleteUnconfirmedAssignment}>
                <img className={styles.deleteIconBig} src={trash} alt="trash"/>
            </ConfirmModal>}
            <h3 className={styles.tableTitle}>Create Assignment</h3>
            <div className={styles.tableMenu}>
                <div>{`${unconfirmedAssignments.length} Rights Granted`}</div>
                <div className={styles.controlBox}>
                    <button
                        onClick={onConfirmAssignments}
                        className={selectedAssignments.length > 0 ? styles.menuButton : `${styles.menuButton} ${styles.notActiveMenuBtn}`}
                        disabled={selectedAssignments.length === 0}
                    >
                        Confirm +
                    </button>
                    <button
                        onClick={selectAllAssignments}
                        className={
                            selectedAssignments.length !== unconfirmedAssignments.length
                                ?
                                styles.menuButton
                                :
                                `${styles.menuButton} ${styles.notActiveMenuBtn}`
                        }
                        disabled={selectedAssignments.length === unconfirmedAssignments.length}
                    >
                        Select all
                    </button>
                    <button
                        onClick={cancelSelectedAssignments}
                        className={selectedAssignments.length > 0 ? styles.menuButton : `${styles.menuButton} ${styles.notActiveMenuBtn}`}
                        disabled={selectedAssignments.length === 0}
                    >
                        Cancel
                    </button>
                </div>
            </div>
            <div className={styles.tableScroll}>
                <div className={styles.tableWrapper}>
                    <table className={styles.assignmentTable}>
                        <thead>
                        <tr className={styles.headerRow}>
                            <th>
                                Rights Holders
                            </th>
                            <th>
                                Rights
                            </th>
                            <th>
                                Sport
                            </th>
                            <th>
                                Available <br/> Fixtures
                            </th>
                            <th>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {unconfirmedAssignments?.map((right) => {
                            return (
                                <tr key={right.id}>
                                    <td>
                                        <div className={styles.cellWithCheckbox}>
                                            <div
                                                onClick={(e) => selectPricing(e, right.id)}
                                                className={styles.checkbox}
                                            >
                                                <Checkbox isChecked={checkCheckbox(right.id)}/>
                                            </div>
                                            <p>{right.rightHolderName}</p>
                                        </div>
                                    </td>
                                    <td className={styles.limitedCell}>
                                        {right.competitionName}
                                    </td>
                                    <td className={styles.limitedCell}>
                                        {right.sportName}
                                    </td>
                                    <td>
                                        {right.availableFixtures}
                                    </td>
                                    <td>
                                        <div className={styles.tableControl}>
                                            <button
                                                onClick={() => onSetAssignment(right.id)}
                                                className={styles.deleteBtn}>
                                                <img
                                                    className={styles.editIcon}
                                                    src={edit}
                                                    alt={"edit"}
                                                />
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setDeletedAssignment(right.id)
                                                    setIsOpenConfirm(true)
                                                }}
                                                className={styles.deleteBtn}>
                                                <img
                                                    className={styles.deleteIcon}
                                                    src={trash}
                                                    alt={"delete"}
                                                />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    );
};

CreateAssignmentTable.displayName = "CreateAssignmentTable"
export default CreateAssignmentTable;
