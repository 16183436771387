import React, {useEffect, useMemo, useState} from 'react';
import styles from './index.module.scss'
import SelectInput from "../../../../../../components/SelectInput/SelectInput";
import {formatSelectOptions} from "../../../../../../helpers/formatSelectOptions";
import {SingleValue} from "react-select";
import {Controller, useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/hooks";
import {
    addOperatorUser,
    addRightHolderUser, getOperatorPages, getRightHolderPages,
    IGetUserDetailsResp, updateUser
} from "../../../../../../store/settings/settingsSlice";
import Input from "../../../../../../components/Input/Input";
import Checkbox from "../../../../../../components/Checkbox";
import Loader from "../../../../../../components/Loader/Loader";
import {
    formatSuperAdminLabel
} from "../../../../IGameView/Settings/UserManagement/children/UsersPages/helpers/formatSuperAdminLabel";

interface ISettingsFormProps {
    closeForm: () => void;
    userDetails?: IGetUserDetailsResp;
    roleName: string;
}

const SettingsForm: React.FC<ISettingsFormProps> = React.memo(({closeForm, userDetails, roleName}) => {

    const [email, setEmail] = useState<string>('');
    const [userRole, setUserRole] = useState<{ value: string, label: string } | undefined>(undefined);
    const [selectedPages, setSelectedPages] = useState<number[]>([]);
    const {handleSubmit, control, formState: {errors}} = useForm();
    const dispatch = useAppDispatch();

    const {roles, pages, pagesLoading, formLoading} = useAppSelector(state => state.settings);

    useEffect(() => {
        setUserFormDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDetails])

    const setUserFormDetails = () => {
        if (userDetails) {
            setEmail(userDetails.email);
            const currentRole = roles.find(item => item.id === userDetails.roleId);

            if (currentRole) {
                const formatRole = {
                    label: currentRole.name === "SuperAdmin" ? "Super Admin" : currentRole.name,
                    value: currentRole.id
                }
                setUserRole(formatRole);
            }
        }
    }

    useEffect(() => {
        setSelectedPages(pages.map(page => +page.id));
    }, [pages]);

    useEffect(() => {
        if (roleName === 'rightHolder' && userRole) {
            dispatch(getRightHolderPages(userRole.value))
        } else if (roleName === 'operator' && userRole) {
            dispatch(getOperatorPages(userRole.value))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userRole])


    const handleRole = (option: SingleValue<{ value: string, label: string }>, onChange: (...event: any[]) => void) => {
        if (option) {
            setUserRole(option);
            onChange(option);
        }
    }

    const handleSelectedPages = (pageId: number, onChange: (...event: any[]) => void) => {
        if (selectedPages && selectedPages.includes(pageId)) {
            const newData = selectedPages.filter(item => item !== pageId);
            setSelectedPages(newData);
            onChange(newData)
        } else {
            const newData = [...selectedPages, pageId];
            setSelectedPages(newData);
            onChange(newData)
        }
    }

    const formatSelectRoles = useMemo(() => (
        formatSuperAdminLabel(formatSelectOptions(roles))
    ), [roles]);

    const createUserData = () => {
        if (userDetails && userRole) {
            const editUser = {
                payload: {
                    userId: userDetails.id,
                    roleId: userRole.value,
                    pages: selectedPages
                },
                closeForm
            }

            dispatch(updateUser(editUser))

        } else if (userRole) {
            const newUser = {
                payload: {
                    email,
                    roleId: userRole.value,
                    pages: selectedPages,
                    clientId: null
                },
                closeForm
            }
            if (roleName === 'rightHolder') {
                dispatch(addRightHolderUser(newUser));
            } else if (roleName === 'operator') {
                dispatch(addOperatorUser(newUser));
            }
        }
    }

    return (
        <div onClick={() => closeForm()} className={styles.overlay}>

            {formLoading && <Loader/>}
            {!formLoading &&
                <form
                    onClick={(e) => e.stopPropagation()}
                    onSubmit={handleSubmit(createUserData)}
                    className={styles.form}
                    data-testid="settings-form"
                >
                    <div className={styles.contentWrapper}>
                        <h6 className={styles.title}>{userDetails ? 'Edit User' : 'New User'}</h6>
                        <div className={styles.inpWrapper}>
                            <div className={styles.inputInner}>
                                <label className={styles.label}>Email
                                    <Controller
                                        control={control}
                                        name="email"
                                        rules={{
                                            required: !userDetails,
                                            maxLength: 40,
                                            minLength: 10,
                                            pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                        }}
                                        render={({field: {onChange}}) => (
                                            <Input
                                                onChange={(e) => {
                                                    onChange(e);
                                                    setEmail(e.target.value)
                                                }}
                                                value={email ? email : ''}
                                                type="text"
                                                placeholder={"user@domain.ext"}
                                                disabled={!!userDetails}
                                            />
                                        )}
                                    />
                                    {errors?.email?.type === "required" &&
                                        <div className={styles.formErrorBlock} data-testid="email-required-error">Email
                                            is required field</div>}
                                    {errors?.email?.type === "minLength" &&
                                        <div className={styles.formErrorBlock} data-testid="email-too-short-error">Email
                                            should contain at least 10
                                            characters</div>}
                                    {errors?.email?.type === "maxLength" &&
                                        <div className={styles.formErrorBlock} data-testid="email-too-long-error">Email
                                            should contain max 40
                                            characters</div>}
                                    {errors?.email?.type === "pattern" &&
                                        <div className={styles.formErrorBlock}
                                             data-testid="email-does-not-match-pattern-error">Email should be like
                                            example@xxx.xx</div>}
                                    {errors?.email?.type === "validate" &&
                                        <div className={styles.formErrorBlock}>User with this email already
                                            exists</div>}
                                </label>
                            </div>
                        </div>

                        <div className={styles.inpWrapper}>
                            <div className={styles.inputInner}>
                                <label className={styles.label}>Role
                                    <Controller
                                        name="role"
                                        control={control}
                                        rules={{
                                            required: !userRole
                                        }}
                                        render={({field: {onChange}}) =>
                                            <SelectInput
                                                value={userRole}
                                                onChange={(option) => {
                                                    handleRole(option, onChange);
                                                }}
                                                options={formatSelectRoles}
                                                aria-label={"user-role"}
                                                placeholder={"Choose an option"}
                                            />
                                        }
                                    />
                                </label>
                                {errors.role &&
                                    <div className={styles.formErrorBlock}>Role is required field</div>}
                            </div>
                        </div>

                        {pagesLoading && <Loader/>}
                        {!pagesLoading && pages && userRole &&
                            <div className={styles.inpWrapper}>
                                <div className={styles.label}
                                     data-testid="page-visibility">Page Visibility
                                    {pages.length ? <Controller
                                            control={control}
                                            name="pageVisibility"
                                            render={({field: {onChange}}) => (
                                                <div className={styles.checkboxes}>
                                                    {pages.map(item => (
                                                        <div key={item.id}>
                                                            <Checkbox
                                                                isChecked={selectedPages.includes(+item.id)}
                                                                onChangeHandler={() => handleSelectedPages(+item.id, onChange)}
                                                                filled={true}
                                                            />
                                                            <span>{item.name}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        />
                                        :
                                        <p className={styles.pageVisibilityText}> There aren't pages to choose</p>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <div className={styles.buttons}>
                        <button onClick={closeForm}
                                className={styles.cancelButton}
                                data-testid="cancel-button"
                        >Cancel
                        </button>
                        <button
                            className={styles.submitButton}
                            type="submit">Confirm
                        </button>
                    </div>
                </form>
            }
        </div>
    );
});

export default SettingsForm;
