export const validateFileSize = (file: File, width: number, height: number): Promise<string | false> => {
    return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
            if (img.width < width || img.height < height) {
                resolve("File dimensions are too small");
            } else {
                resolve(false);
            }
        };
        img.onerror = () => {
            resolve("Error loading image");
        };
        img.src = URL.createObjectURL(file);
    });
}