import React, {Dispatch, SetStateAction, useEffect} from 'react';
import styles from './index.module.scss'
import {IValidationState} from "../../ResetPassword";

interface IVisualPasswordHelperProps {
    passwordValue: string;
    title: string;
    setValidationState: Dispatch<SetStateAction<IValidationState>>
    validationState: IValidationState;
}

const VisualPasswordHelper: React.FC<IVisualPasswordHelperProps> = ({
                                                                        passwordValue,
                                                                        title,
                                                                        validationState,
                                                                        setValidationState
                                                                    }) => {
    const validatePassword = (password: string) => {
        const uppercaseRegex = /[A-Z]/;
        const lowercaseRegex = /[a-z]/;
        const numberRegex = /[0-9]/;
        // eslint-disable-next-line
        const specialSymbolRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\\/\-=|]/;

        const iOneUppercase = uppercaseRegex.test(password);
        const isOneLowercase = lowercaseRegex.test(password);
        const isOneNumber = numberRegex.test(password);
        const isOneSpecialSymbol = specialSymbolRegex.test(password);
        const correctLength = password.length >= 8

        setValidationState({
            iOneUppercase,
            isOneLowercase,
            isOneNumber,
            isOneSpecialSymbol,
            correctLength
        });
    };

    useEffect(() => {
        validatePassword(passwordValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [passwordValue]);

    return (
        <div className={styles.passwordHelper}>
            <div className={styles.helperContentInner}>
                <h6 className={styles.title}>{title} must contain:</h6>
                <div className={styles.validationState}>
                    <div>{validationState.iOneUppercase
                        ?
                        <span className={styles.stateMark}>&#10004;</span>
                        :
                        <span className={`${styles.stateMark} ${styles.stateMarkInvalid}`}>&#10540;</span>}1 Uppercase
                    </div>
                    <div>{validationState.isOneLowercase
                        ?
                        <span className={styles.stateMark}>&#10004;</span>
                        :
                        <span className={`${styles.stateMark} ${styles.stateMarkInvalid}`}>&#10540;</span>}1 Lowercase
                    </div>
                    <div>{validationState.isOneNumber
                        ?
                        <span className={styles.stateMark}>&#10004;</span>
                        :
                        <span className={`${styles.stateMark} ${styles.stateMarkInvalid}`}>&#10540;</span>}1 Number
                    </div>
                    <div>{validationState.isOneSpecialSymbol
                        ?
                        <span className={styles.stateMark}>&#10004;</span>
                        :
                        <span className={`${styles.stateMark} ${styles.stateMarkInvalid}`}>&#10540;</span>}1 Special
                        Symbol
                    </div>
                    <div>{validationState.correctLength
                        ?
                        <span className={styles.stateMark}>&#10004;</span>
                        :
                        <span className={`${styles.stateMark} ${styles.stateMarkInvalid}`}>&#10540;</span>}Minimum 8
                        characters
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VisualPasswordHelper;
