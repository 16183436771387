import React from "react";
import styles from "./index.module.scss";
import {IDonutChartBasicData} from "../DonutChart/DonutChart";
import {Tooltip} from "react-tooltip";

export interface IDonutChartLegendProps {
    data: any;
    minorFilters: any;
    colors: string[];
}

const DonutChartLegend: React.FC<IDonutChartLegendProps> = ({data, minorFilters, colors}) => {
    return (
        <div className={styles.donutChartLegend}>
            <ul className={styles.donutChartLegendItemsWrapper}>
                {
                    minorFilters.donutChartViewType !== "" &&
                    data[0].data.map((x: IDonutChartBasicData, index: number) => (
                        <li className={styles.legendItem}
                            data-tooltip-id={x.name}
                            data-tooltip-content={x.name}
                            data-tooltip-position-strategy="fixed"
                            key={index}
                        >
                            <span>{x.name}</span>
                            {
                                <Tooltip id={x.name}/>
                            }
                            <div className={styles.legendItemBox}
                                 style={{backgroundColor: colors[index]}}>
                            </div>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default DonutChartLegend;
