import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';
import menu from "../../../../img/menu.svg";
import {useAppSelector} from "../../../../hooks/hooks";
import {RootState} from "../../../../store";
import {Group} from "../../../../enums/group";
import DropDownMenu from "./children/DropDownMenu";
import OutsideClickHandler from "react-outside-click-handler";
import bell from "../../../../img/notification.svg";
import cartIcon from "../../../../img/cartEmpty.svg";
import additionalCartIcon from "../../../../img/additionalCartIcon.svg";
import {useLocation, useNavigate} from "react-router-dom";
import {handleCartNavigation} from "./helpers/handleCartNavigation";
import AlertsListModal from "../../../../components/AlertSystem/children/AlertsListModal/AlertsListModal";
import {IGroupedCartItem} from "@store/carts/operatorCart/operatorCartSlice";

export interface ICmsHeader {
    onClickHandler: () => void;
}

const Header: React.FC<ICmsHeader> = ({onClickHandler}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {email, organization, role} = useAppSelector((state: RootState) => state.userInfo);
    const {rightsHolderCartFixtures} = useAppSelector((state: RootState) => state.rightHolderBooking);
    const {cartItems, isOperatorCartOpen} = useAppSelector((state: RootState) => state.operatorCart);
    const {bfCartItems} = useAppSelector((state: RootState) => state.bookFixtureCart);
    const {records} = useAppSelector((state: RootState) => state.alerts);
    const [isOpenUserMenu, setIsOpenUserMenu] = useState(false);
    const [cartLength, setCartLength] = useState(0);
    const [bfCartLength, setBFCartLength] = useState(0);
    const [alertsModalVisible, setAlertsModalVisible] = useState(false);
    const [unreadAlertsLength, setUnreadAlertsLength] = useState<number>(0);
    const currentUser = localStorage.getItem("currentUserRole");
    const countOperatorCartItems = (cartItems: IGroupedCartItem[]) => {
        let totalLenght = 0
        cartItems.forEach(item => totalLenght += item.sports.length)
        return totalLenght
    }
    useEffect(() => {
        if (role === Group.RightHolder || currentUser === Group.RightHolder) {
            setCartLength(rightsHolderCartFixtures.length)
        }
        if (role === Group.Operator || currentUser === Group.Operator) {
            setCartLength(countOperatorCartItems(cartItems));
            setBFCartLength(countOperatorCartItems(bfCartItems))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rightsHolderCartFixtures, cartItems, currentUser, bfCartItems]);

    useEffect(() => {
        const unread = records.filter(alert => !alert.isSeen).length;
        setUnreadAlertsLength(unread);
    }, [records]);

    useEffect(() => {
        if ((!location.pathname.includes("rightHolders") || location.pathname === "/cms/igame-rightHolders") &&
            role !== Group.RightHolder) {
            setCartLength(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);


    const onOutsideMenuClick = (e: MouseEvent) => {
        if (e.target instanceof HTMLElement) {
            const targetElementClassList = e.target.classList;
            if (isOpenUserMenu && targetElementClassList.length > 0) {
                setIsOpenUserMenu(false)
            }
        }
    }

    const navigateToCart = () => {
        if (isOperatorCartOpen || location.pathname.includes('rightHolder-cart')) return;

        const parts = location.pathname.split('/');
        let id = parts[3];

        handleCartNavigation({id, currentUser, role, navigate});
    };

    const navigateToAdditionalCart = () => {
        if (isOperatorCartOpen) return;

        const parts = location.pathname.split('/');
        let id = parts[3];

        if (role === Group.IGame && currentUser === Group.Operator) {
            navigate(`/cms/igame-rightHolders/${id}/book-fixture-cart`);
        } else if (role === Group.Operator) {
            navigate(`cms/book-fixture-cart`);
        }
    }

    const checkIsShowAdditionalCart = () => {
        return role === Group.Operator || currentUser === Group.Operator
    }

    return (
        <header className={styles.cmsHeader}>
            {
                role !== Group.TechnicalProvider &&
                <img className={styles.menuButton} src={menu} alt="menu" onClick={onClickHandler}/>
            }
            <div className={styles.headerTitle}>ONEMarketplace <span className={styles.pinkTitle}>CMS</span></div>
            <div className={styles.headerNotification}>
            </div>
            <div className={styles.actionsMenu}>
                {
                    role !== Group.Operator &&
                    <button className={styles.actionsMenuButton} onClick={() => setAlertsModalVisible(true)}>
                        {unreadAlertsLength > 0 &&
                            <div className={`${styles.cartItem} ${styles.adjusted}`}><p>{unreadAlertsLength}</p></div>}
                        <img className={styles.actionsMenuIcon} src={bell} alt="notification"/>
                    </button>
                }
                {checkIsShowAdditionalCart() &&
                    <button className={styles.additionalCartBtn} onClick={navigateToAdditionalCart}>
                        {bfCartLength > 0 && <div className={styles.cartItem}><p>{bfCartLength}</p></div>}
                        <img className={`${styles.actionsMenuIcon} ${styles.cartIcon}`} src={additionalCartIcon}
                             alt="cart"/>
                    </button>}
                <button className={styles.actionsMenuButton} onClick={navigateToCart}>
                    {cartLength > 0 && <div className={styles.cartItem}><p>{cartLength}</p></div>}
                    <img className={`${styles.actionsMenuIcon} ${styles.cartIcon}`} src={cartIcon} alt="cart"/>
                </button>
            </div>
            <div className={styles.userInfo}>
                <div className={styles.userName}>{email}</div>
                <p className={styles.organization}>{organization}</p>
            </div>
            {
                isOpenUserMenu &&
                <OutsideClickHandler onOutsideClick={(e) => onOutsideMenuClick(e)}>
                    <div>
                        <DropDownMenu/>
                    </div>
                </OutsideClickHandler>
            }
            <div onClick={() => setIsOpenUserMenu(!isOpenUserMenu)} className={styles.rightCorner}>
                <div></div>
            </div>
            {
                alertsModalVisible &&
                <AlertsListModal
                    closePopup={() => setAlertsModalVisible(false)}
                />
            }
        </header>
    );
};

Header.displayName = "Header"
export default Header;
