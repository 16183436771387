import React, {useEffect, useRef, useState} from 'react';
import styles from './index.module.scss';
import {IPageMenuProps} from "../../../AppRoutes/routes/RouteInterface";
import Loader from "../../../../../components/Loader/Loader";
import PageHeader from "../../../../../components/PageHeader/PageHeader";
import DownloadBlock from "../../../../../components/DownloadBlock/DownloadBlock";
import BillingCalendar from "../../../../../components/Billing/BillingCalendar/BillingCalendar";
import {NavLink, useParams} from "react-router-dom";
import {Route, Routes} from "react-router";
import BillingTable from "../../../../../components/Billing/BillingTable/BillingTable";
import {BillingTabs} from "../../../IGameView/Billing/RightHolderBilling/RightHolderBilling";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {formatRangeDate, getCurrentMonth, getPreviousMonth} from "../../../../../helpers/dateFormatters";
import {IDate} from "../../../../../components/AnalyticsDashboard/types";
import {pdfFormatter} from "../../../../../helpers/pdfFormatter";
import RightHolderUser from "../../../../../components/Billing/RightHolderUser/RightHolderUser";
import {
    getIGameData,
    getIGameDataCSV,
    IBillingReqBody
} from "../../../../../store/rightsHolderBilling/iGameBillingSlice";
import {validateDates} from "../../../../../helpers/validateDates";


const IGameMediaBilling: React.FC<IPageMenuProps> = React.memo(({menuStructure}) => {
    const initialStartDate = getPreviousMonth();
    const initialEndDate = getCurrentMonth();
    const [startDate, setStartDate] = useState<IDate>(initialStartDate);
    const [endDate, setEndDate] = useState<IDate>(initialEndDate);
    const [isValidEndDate, setIsValidEndDate] = useState<boolean>(true);
    const {id} = useParams();
    const pdfLayout = useRef(null);
    const dispatch = useAppDispatch();
    const {
        totalData,
        csvFileName,
        IGameCSV,
        loading,
        streams,
        operators,
        ingests,
        bandwidths
    } = useAppSelector(state => state.iGameBilling);

    useEffect(() => {
        const isValidDates = validateDates(startDate, endDate);
        setIsValidEndDate(isValidDates);
        if (!isValidDates) return;

        const payload: IBillingReqBody = {
            startDate: formatRangeDate(startDate),
            endDate: formatRangeDate(endDate)
        }
        dispatch(getIGameDataCSV(payload))
        dispatch(getIGameData(payload));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, formatRangeDate(startDate), formatRangeDate(endDate)]);


    const pdfHandler = () => {
        const layout: any = pdfLayout.current;
        pdfFormatter(layout);
    }

    const getNavLinkClass = (isActive: boolean, isPending: boolean): string => {
        if (isPending) return "pending"

        if (isActive) {
            return styles.activeTab
        } else {
            return styles.notActiveTab
        }
    };

    return (
        <div className={styles.pageInner} ref={pdfLayout}>


            <PageHeader menuStructure={menuStructure} title={'IGame Media'}>
                <DownloadBlock
                    fileName={csvFileName}
                    csvFile={IGameCSV}
                    pdfHandler={pdfHandler}
                    isActiveLink={true}
                />
            </PageHeader>

            <div className={styles.pageContainer}>
                <div className={styles.titleInner}>
                    <h1 className={styles.title}>Billing</h1>

                    <BillingCalendar
                        startDate={startDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        setStartDate={setStartDate}
                        isValidEndDate={isValidEndDate}
                    />
                </div>


                <div className={styles.billingWrapper}>
                    <RightHolderUser totalData={totalData}/>

                    <div className={styles.tableWrapper}>


                        <div className={styles.tabsWrapper}>
                            <NavLink to={`/cms/billing-igame`}
                                     className={({isActive, isPending}) => getNavLinkClass(isActive, isPending)}
                                     end>
                                {BillingTabs.Streams}
                            </NavLink>

                            <NavLink to={'ingest'}
                                     className={({isActive, isPending}) => getNavLinkClass(isActive, isPending)}>
                                {BillingTabs.Ingest}
                            </NavLink>

                            <NavLink to={'operators'}
                                     className={({isActive, isPending}) => getNavLinkClass(isActive, isPending)}
                                     end>
                                {BillingTabs.Operators}
                            </NavLink>

                            <NavLink to={'bandwidth'}
                                     className={({isActive, isPending}) => getNavLinkClass(isActive, isPending)}>
                                {BillingTabs.Bandwidth}
                            </NavLink>
                        </div>

                        {loading && <Loader/>}
                        {!loading &&
                            <Routes>
                                <Route path={'/'}
                                       element={
                                           <BillingTable
                                               currentTab={BillingTabs.Streams}
                                               streams={streams}
                                           />}
                                />
                                <Route path={'ingest'}
                                       element={
                                           <BillingTable
                                               currentTab={BillingTabs.Ingest}
                                               ingest={ingests}
                                           />}
                                />
                                <Route path={'operators'}
                                       element={
                                           <BillingTable
                                               currentTab={BillingTabs.Operators}
                                               operators={operators}
                                           />}
                                />
                                <Route path={'bandwidth'}
                                       element={
                                           <BillingTable
                                               currentTab={BillingTabs.Bandwidth}
                                               bandwidth={bandwidths}
                                           />}
                                />
                            </Routes>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
});


IGameMediaBilling.displayName = "IGameMediaBilling"
export default IGameMediaBilling;
