import {
    IGetRightHolderDetails,
    ISelectItem,
    IUserRole
} from "../../../../../../../../store/userManagement/userManagementSlice";

export const checkIsUniqRightHolderName = (name: string, editUser: IGetRightHolderDetails | undefined, rightHolders: IUserRole[]) => {
    if (editUser) {
        const originalName = editUser.name
        if (originalName === name) {
            return true
        } else {
            const isUniqName = rightHolders.find(rightHolder => rightHolder.name === name)
            return !isUniqName
        }

    } else {
        const isUniqName = rightHolders.find(rightHolder => rightHolder.name === name)
        return !isUniqName
    }
}

export const checkAreEmailValuesValid = (currentValue: string, storedValue: string): boolean => {
    const emailRegex = /^\S+@[a-z0-9.-]+\.[a-z]{2,4}$/i;
    const testedValue = currentValue ?? storedValue;
    const emails = testedValue.split(/[,;]/).map(email => email.trim());

    return emails.every(email => emailRegex.test(email));
}

export const checkAllValuesContainCharacters = (currentValue: string, storedValue: string): boolean => {
    const MIN_LENGTH = 4;
    const testedValue = currentValue ?? storedValue;
    const contacts = testedValue.split(/[,;]/).map(contact => contact.trim());

    return contacts.every(contact => contact.length >= MIN_LENGTH);
}

export const checkIsValuesNumberEqual = (fieldCurrent: string, fieldStored: string, fieldCompareWith: string): boolean => {
    const testedValue = fieldCurrent ?? fieldStored;

    if (!testedValue || !fieldCompareWith) return false;

    const xFieldValues = testedValue.split(/[,;]/).filter(value => !!value.trim());
    const yFieldValues = fieldCompareWith.split(/[,;]/).filter(value => !!value.trim());

    return xFieldValues.length === yFieldValues.length;
}

export const setProviderValue = (
    editUser: IGetRightHolderDetails | undefined,
    dataProviders: ISelectItem[],
    rightHoldersInUse?: ISelectItem[]
) => {
    if (rightHoldersInUse && editUser) {
        const allProviders = dataProviders.concat(rightHoldersInUse)
        const currentProvider = allProviders.find(provider => provider.id === editUser.dataProviderId.toString());
        if (currentProvider) {
            return {
                value: `${editUser.dataProviderId}`,
                label: `${editUser.dataProviderId} (${currentProvider.name})`
            }
        }
    } else {
        return {value: '', label: ''}
    }

    if (editUser) {
        const currentProvider = dataProviders.find(provider => provider.id === editUser.dataProviderId.toString());
        if (currentProvider) {
            return {
                value: `${editUser.dataProviderId}`,
                label: `${editUser.dataProviderId} (${currentProvider.name})`
            }
        }
    } else {
        return {value: '', label: ''}
    }
}
