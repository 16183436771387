import React from "react";
import styles from "./index.module.scss";
import {formatNumberWithCommas} from "../AnalyticsDashboard/helpers/formatNumberWithCommas";

export interface IChartLabelProps {
    labelData: any;
    colors: string[];
    forMainChart: boolean;
}

const ChartLabel: React.FunctionComponent<IChartLabelProps> = ({labelData, forMainChart}) => {

    const getContrastColor = (hexColor: string) => {
        if (hexColor.slice(0, 1) === '#') {
            hexColor = hexColor.slice(1);
        }

        const r = parseInt(hexColor.substr(0, 2), 16);
        const g = parseInt(hexColor.substr(2, 2), 16);
        const b = parseInt(hexColor.substr(4, 2), 16);

        const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

        return (yiq >= 128) ? 'black' : 'white';
    };
    const formatLabelDate = (date: string) => {
        return date.replace(/:/g, "-")
    }
    return (
        forMainChart
            ?
            <div className={styles.chartLabelData} style={{backgroundColor: labelData.color}}>
                <p className={styles.chartLabelText} style={{color: getContrastColor(labelData.color)}}>
                    <span>Date:</span>{` ${formatLabelDate(labelData.name)}`}
                    <br/>
                    {
                        Array.isArray(labelData.categoryItem)
                            ?
                            labelData.categoryItem.map((item: string) => (
                                <>
                                    <span>{item}</span>{`: ${formatNumberWithCommas(labelData.y)}`}<br/>
                                </>
                            ))
                            :
                            <>
                                <span>{labelData.categoryItem}</span>{`: ${formatNumberWithCommas(labelData.y)}`}
                            </>
                    }
                </p>
            </div>
            :
            <div className={styles.chartLabelData} style={{backgroundColor: labelData.color}}>
                <p className={styles.chartLabelText} style={{color: getContrastColor(labelData.color)}}>
                    <span>{labelData.name}</span>{`: ${formatNumberWithCommas(labelData.y)}`}
                </p>
            </div>
    );
};

ChartLabel.displayName = "ChartLabel"
export default ChartLabel;

