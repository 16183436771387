import React, {useState} from 'react';
import PageHeader from "../../../../../components/PageHeader/PageHeader";
import styles from './index.module.scss'
import editIcon from '../../../../../img/editWhite.svg';
import {IPageMenuProps} from "../../../AppRoutes/routes/RouteInterface";
import DownloadCsv from "./DownloadCSV/DownloadCSV";
import {useAppDispatch, useAppSelector} from "@hooks/hooks";
import Loader from "../../../../../components/Loader/Loader";
import {
    clearAvailablePorts,
    getAvailablePorts,
    getBookings, IGetBookingsPayload,
    updateDestinationPort
} from "@store/btBookings/btBookingsSlice";
import {useDeepCompareEffect} from "@hooks/useDeepCompareEffect";
import {SingleValue} from "react-select";
import SelectInput from "../../../../../components/SelectInput/SelectInput";
import {formatPortsOptions} from "./helpers/formatPortsOptions";
import moment from "moment";
import SearchInput from "../../../../../components/SearchInput/SearchInput";
import {useDebounce} from "@helpers/useDebounce";
import {BsArrowDown, BsArrowUp} from "react-icons/bs";
import AppTableWhite from "../../../../../components/AppTableWhite/AppTableWhite";
import NoDataFoundStub from "../../../../../components/NoDataFoundStub/NoDataFoundStub";
import {useBookingsSorting} from "./hooks/useBookingsSorting";
import {ISelectOption} from "@store/operatorBookedContent/operatorBookedContentSlice";


const BTBookings: React.FC<IPageMenuProps> = React.memo(({menuStructure}) => {
    const {isLoading, bookings, availablePorts} = useAppSelector(state => state.btBookings);
    const [editableFixtureId, setEditableFixtureId] = useState<string | null>(null);
    const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
    const {headerControls, generateTableHeaderStyles, handleSorting, sortingCriteria} = useBookingsSorting(searchValue);
    const dispatch = useAppDispatch();

    useDeepCompareEffect(() => {
        const payload: IGetBookingsPayload = {
            searchValue,
            sorting: {
                property: sortingCriteria.criteria,
                isAscending: sortingCriteria.isAscending
            }
        };
        dispatch(getBookings(payload));
    }, []);

    const onSearch = () => {
        if (searchValue === undefined) {
            return;
        }

        const payload: IGetBookingsPayload = {
            searchValue,
            sorting: {
                property: sortingCriteria.criteria,
                isAscending: sortingCriteria.isAscending
            }
        };
        dispatch(getBookings(payload))
    }

    useDebounce(onSearch, searchValue, 500)

    const onClickEditPort = (fixtureId: string) => {
        setEditableFixtureId(fixtureId);
        dispatch(getAvailablePorts(fixtureId));
    }

    const onChangePort = (port: SingleValue<ISelectOption>, fixtureId: string) => {
        if (port) {
            const payload = {
                destinationPortId: port.value ? +port.value : null,
                btTowerFixtureId: fixtureId
            }
            dispatch(updateDestinationPort(payload));
            setEditableFixtureId(null);
            dispatch(clearAvailablePorts());
        }
    }

    const handleSortingArrow = (isAscending: boolean) => {
        if (isAscending) {
            return <BsArrowUp/>
        } else {
            return <BsArrowDown/>
        }
    }
    //Format HTML!
    return (
        <>
            <PageHeader
                menuStructure={menuStructure}
                title={"Reporting"}
                subTitle={'BT Tower Bookings'}>
                <DownloadCsv
                    searchValue={searchValue}
                    sorting={{
                        property: sortingCriteria.criteria,
                        isAscending: sortingCriteria.isAscending
                    }}/>
            </PageHeader>

            <div className={styles.page}>
                <div className={styles.pageContainer}>
                    <div className={styles.upperSection}>
                        <h4 className={styles.title}>BT Bookings</h4>
                    </div>
                    <div className={styles.mainContentWrapper}>
                        <div className={styles.search}>
                            <SearchInput
                                searchValue={searchValue}
                                onChange={(e) => setSearchValue(e)}
                            />
                        </div>
                        <div className={styles.tableContainer}>
                            {
                                isLoading && <Loader/>
                            }
                            {
                                !isLoading && bookings.length === 0
                                &&
                                <NoDataFoundStub
                                    message={"No information about BT Bookings found"}
                                    textColor="white"
                                />
                            }
                            {
                                !isLoading && bookings.length > 0 &&
                                <div className={styles.tableWrapper}>
                                    <AppTableWhite>
                                        <thead>
                                        <tr>
                                            {headerControls.map(column => {
                                                if (column.criteria === null) {
                                                    return (
                                                        <th key={column.id} className={styles.headerClickable}>
                                                            {column.title}
                                                        </th>
                                                    )
                                                } else {
                                                    return (
                                                        <th className={generateTableHeaderStyles(column)}
                                                            onClick={() => handleSorting(column.criteria)}
                                                            key={column.id}>
                                                            {column.title}

                                                            {sortingCriteria.criteria === column.criteria
                                                                ? handleSortingArrow(sortingCriteria.isAscending) : null
                                                            }
                                                        </th>
                                                    )
                                                }
                                            })
                                            }
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {bookings.map(fixture => {
                                            return (
                                                <tr key={fixture.id}
                                                    className={editableFixtureId &&
                                                    editableFixtureId === fixture.id ? styles.editRow : ''}>
                                                    <td>{moment(fixture.date).format("DD/MM/YYYY")}</td>
                                                    <td>{fixture.startTime}</td>
                                                    <td>{fixture.endTime}</td>
                                                    <td>{fixture.competition}</td>
                                                    <td>{fixture.fixture}</td>
                                                    <td>{fixture.source}</td>
                                                    {editableFixtureId && editableFixtureId === fixture.id && availablePorts.length ?
                                                        <td className={styles.selectCol}>
                                                            <div className={styles.selectPort}>
                                                                <SelectInput
                                                                    options={formatPortsOptions(availablePorts)}
                                                                    onChange={port => onChangePort(port, fixture.id)}
                                                                    value={fixture.destinationPort ? {
                                                                            label: fixture.destinationPort,
                                                                            value: fixture.destinationPortId.toString()
                                                                        } :
                                                                        null
                                                                    }
                                                                />
                                                            </div>
                                                        </td>
                                                        :
                                                        <td onClick={() => onClickEditPort(fixture.id)}>
                                            <span className={styles.destinationCol}>
                                                 {fixture.destinationPort ? fixture.destinationPort : "---------"}
                                                <img src={editIcon} alt="editIcon" className={styles.editIcon}/>
                                            </span>
                                                        </td>
                                                    }
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </AppTableWhite>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

BTBookings.displayName = "BTBookings"
export default BTBookings;
