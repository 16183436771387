import {Group} from "../enums/group";
import {
    getCartFixtures,
    getCartFixturesForIgameRightsHolder
} from "../store/rightHolderBooking/rightHolderBookingSlice";
import {getGroupedCartItems} from "@store/carts/operatorCart/operatorCartSlice";
import {RootState} from "../store";
import {useAppDispatch, useAppSelector} from "./hooks";
import {useEffect} from "react";
import {getBFCartItems} from "@store/carts/operatorBFCart/bookFixtureCartSlice";

export const useGetCartItems = (userId: string | undefined | null) => {
    const {role, currentIgameUserGroup} = useAppSelector((state: RootState) => state.userInfo);
    const dispatch = useAppDispatch();


    useEffect(() => {

        if (role === Group.Operator) {
            dispatch(getGroupedCartItems(null));
            dispatch(getBFCartItems(null));

        } else if (currentIgameUserGroup === Group.Operator && userId) {
            dispatch(getGroupedCartItems(userId));
            dispatch(getBFCartItems(userId));

        } else if (role === Group.RightHolder) {
            dispatch(getCartFixtures(null));

        } else if (currentIgameUserGroup === Group.RightHolder && userId) {
            dispatch(getCartFixturesForIgameRightsHolder(userId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}
