import React, {ChangeEvent} from 'react';
import styles from "../index.module.scss";
import {Control, Controller, FieldErrors} from "react-hook-form";
import {
    checkAllValuesContainCharacters,
    checkAreEmailValuesValid,
    checkIsUniqRightHolderName,
    checkIsValuesNumberEqual
} from "../helpers";
import Input from "../../../../../../../../../components/Input/Input";
import {checkIsUniqueEmail, formatSelectFormOptions} from "../../helpers";
import SelectInput from "../../../../../../../../../components/SelectInput/SelectInput";
import {IStepOneFormData} from "../../../../UserManagementHooks/useRightHolderFormData";
import {
    IGetRightHolderDetails,
    ISelectItem,
    IUserRole
} from "../../../../../../../../../store/userManagement/userManagementSlice";
import {SingleValue} from "react-select";
import {IDropdownOption} from "../../../../../../../../../components/AnalyticsDashboard/types";


interface IStepOneFormProps {
    control: Control,
    errors: FieldErrors,
    stepOneFormData: IStepOneFormData,
    onChangeStepOne: (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void,
    rightsHolderDetails: IGetRightHolderDetails | undefined,
    rightHolders: IUserRole[],
    existingEmails: string[],
    dataProvidersInUse: ISelectItem[],
    handleProviderId: (option: SingleValue<IDropdownOption>, onChange: (...event: any[]) => void) => void,
    dataProviders: ISelectItem[]
}

const StepOneForm: React.FC<IStepOneFormProps> = React.memo(({
                                                                 control,
                                                                 errors,
                                                                 stepOneFormData,
                                                                 onChangeStepOne,
                                                                 rightsHolderDetails,
                                                                 rightHolders,
                                                                 existingEmails,
                                                                 dataProvidersInUse,
                                                                 handleProviderId,
                                                                 dataProviders
                                                             }) => {

    return (
        <div className={styles.formContent}>
            <div className={styles.twoInputsContainer}>
                <label className={styles.label}>Name <span>*</span>
                    <Controller
                        control={control}
                        name="name"
                        rules={{
                            required: !stepOneFormData.name,
                            maxLength: 40,
                            minLength: 4,
                            validate: {
                                isUniqName: (name) => checkIsUniqRightHolderName(name, rightsHolderDetails, rightHolders),
                                isOnlyWhiteSpaces: (name) => {
                                    if (name) {
                                        return name.trim().length > 3
                                    }
                                }
                            }
                        }}
                        render={({field: {onChange}}) => (
                            <Input
                                onChange={(e) => {
                                    onChange(e);
                                    onChangeStepOne(e)
                                }}
                                value={stepOneFormData.name}
                                type="text"
                                name="name"
                                placeholder={"Name"}
                            />
                        )}
                    />
                    {errors?.name?.type === "required" &&
                        <p className={styles.formErrorBlock}>Required Field</p>}
                    {errors?.name?.type === "minLength" &&
                        <p className={styles.formErrorBlock}>
                            Name should contain at least 4
                            characters
                        </p>
                    }
                    {errors?.name?.type === "maxLength" &&
                        <p className={styles.formErrorBlock}>Name should contain max 40 characters</p>
                    }
                    {errors?.name?.type === "isUniqName" &&
                        <p className={styles.formErrorBlock}>
                            This right holder is already exists
                        </p>
                    }
                    {errors?.name?.type === "isOnlyWhiteSpaces" &&
                        <p className={styles.formErrorBlock}>
                            Name should contain at least 4 characters
                        </p>
                    }
                </label>

                <label className={styles.selectLabel}>ONEPlatform ID<span>*</span>
                    <Controller
                        name="dataProviderId"
                        control={control}
                        rules={{
                            required: !stepOneFormData.dataProviderId,
                        }}
                        render={({field: {onChange}}) =>
                            <SelectInput
                                value={stepOneFormData.dataProviderId}
                                onChange={(option) => {
                                    handleProviderId(option, onChange);
                                }}
                                options={formatSelectFormOptions(dataProviders, dataProvidersInUse, rightsHolderDetails)}
                                placeholder={"ONEPlatform ID"}
                            />
                        }
                    />
                    {errors?.dataProviderId?.type === "required" &&
                        <p className={styles.formErrorBlock}>ONEPlatform ID is required Field</p>
                    }
                </label>
            </div>

            <div className={styles.twoInputsContainer}>
                <label className={styles.label}>Address
                    <Controller
                        control={control}
                        name="address"
                        rules={{
                            maxLength: 40,
                            minLength: 4,
                            validate: {
                                isOnlyWhiteSpaces: (name) => {
                                    if (name) {
                                        return name.trim().length > 3
                                    }
                                }
                            }
                        }}
                        render={({field: {onChange}}) => (
                            <Input
                                onChange={(e) => {
                                    onChange(e);
                                    onChangeStepOne(e)
                                }}
                                value={stepOneFormData.address}
                                type="text"
                                name="address"
                                placeholder={"Address"}
                            />
                        )}
                    />
                    {errors?.address?.type === "minLength" &&
                        <p className={styles.formErrorBlock}>
                            Address should contain at least 4 characters
                        </p>
                    }
                    {errors?.address?.type === "maxLength" &&
                        <p className={styles.formErrorBlock}>
                            Address should contain max 40 characters
                        </p>
                    }
                    {errors?.address?.type === "isOnlyWhiteSpaces" &&
                        <p className={styles.formErrorBlock}>
                            Address should contain at least 4
                            characters
                        </p>
                    }
                </label>
            </div>

            <div className={styles.twoInputsContainer}>
                <label className={styles.label}>Client Admin
                    <Controller
                        control={control}
                        name="clientAdmin"
                        rules={{
                            maxLength: 40,
                            minLength: 4,
                            validate: {
                                isOnlyWhiteSpaces: (name) => {
                                    if (name) {
                                        return name.trim().length > 3
                                    }
                                }
                            }
                        }}
                        render={({field: {onChange}}) => (
                            <Input
                                onChange={(e) => {

                                    onChange(e);
                                    onChangeStepOne(e)
                                }}
                                value={stepOneFormData.clientAdmin}
                                type="text"
                                name="clientAdmin"
                                placeholder={"Client Admin"}
                            />
                        )}
                    />
                    {errors?.clientAdmin?.type === "minLength" &&
                        <p className={styles.formErrorBlock}>
                            Client Admin should contain at least 4 characters
                        </p>
                    }
                    {errors?.clientAdmin?.type === "maxLength" &&
                        <p className={styles.formErrorBlock}>
                            Client Admin should contain max 40 characters
                        </p>
                    }
                    {errors?.clientAdmin?.type === "isOnlyWhiteSpaces" &&
                        <p className={styles.formErrorBlock}>
                            Client Admin should contain at least 4 characters
                        </p>
                    }
                </label>

                <label className={styles.label}>Admin Email <span>*</span>
                    <Controller
                        control={control}
                        name="adminEmail"
                        rules={{
                            required: !stepOneFormData.adminEmail,
                            maxLength: 40,
                            minLength: 10,
                            pattern: /^\S+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                            validate: {
                                uniqueEmail: (e) => checkIsUniqueEmail(e, existingEmails)
                            }
                        }}
                        render={({field: {onChange}}) => (
                            <Input
                                onChange={(e) => {
                                    onChange(e);
                                    onChangeStepOne(e)
                                }}
                                value={stepOneFormData.adminEmail}
                                type="text"
                                name="adminEmail"
                                placeholder={"max.sheamans@infront.com"}
                            />
                        )}
                    />
                    {errors?.adminEmail?.type === "required" &&
                        <p className={styles.formErrorBlock}>Required Field</p>}
                    {errors?.adminEmail?.type === "minLength" &&
                        <p className={styles.formErrorBlock}>
                            Admin Email should contain at least 10 characters
                        </p>
                    }
                    {errors?.adminEmail?.type === "maxLength" &&
                        <p className={styles.formErrorBlock}>
                            Admin Email should contain max 40
                            characters
                        </p>
                    }
                    {errors?.adminEmail?.type === "pattern" &&
                        <p className={styles.formErrorBlock}>
                            Admin Email should be like example@xxx.xx
                        </p>
                    }
                    {errors?.adminEmail?.type === "uniqueEmail" &&
                        <p className={styles.formErrorBlock}>
                            Admin Email with this email already exists
                        </p>
                    }
                </label>
            </div>

            <div className={styles.twoInputsContainer}>
                <label className={styles.label}>Commercial Contact
                    <Controller
                        control={control}
                        name="commercialContact"
                        rules={{
                            maxLength: 40,
                            minLength: 4,
                            validate: {
                                isOnlyWhiteSpaces: (name) => {
                                    if (name) {
                                        return name.trim().length > 3
                                    }
                                }
                            }
                        }}
                        render={({field: {onChange}}) => (
                            <Input
                                onChange={(e) => {
                                    onChange(e);
                                    onChangeStepOne(e)
                                }}
                                value={stepOneFormData.commercialContact}
                                type="text"
                                name="commercialContact"
                                placeholder={"Commercial Contact"}
                            />
                        )}
                    />
                    {errors?.commercialContact?.type === "minLength" &&
                        <p className={styles.formErrorBlock}>
                            Commercial Contact should contain at least 4 characters
                        </p>
                    }
                    {errors?.commercialContact?.type === "maxLength" &&
                        <p className={styles.formErrorBlock}>
                            Commercial Contact should contain max 40 characters
                        </p>
                    }
                    {errors?.commercialContact?.type === "isOnlyWhiteSpaces" &&
                        <p className={styles.formErrorBlock}>
                            Commercial Contact should contain at least 4
                            characters
                        </p>}
                </label>

                <label className={styles.label}>Commercial Email <span>*</span>
                    <Controller
                        control={control}
                        name="commercialEmail"
                        rules={{
                            required: !stepOneFormData.commercialEmail,
                            maxLength: 40,
                            minLength: 10,
                            pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                            validate: {
                                uniqueEmail: (e) => checkIsUniqueEmail(e, existingEmails)
                            }
                        }}
                        render={({field: {onChange}}) => (
                            <Input
                                onChange={(e) => {
                                    onChange(e);
                                    onChangeStepOne(e)
                                }}
                                value={stepOneFormData.commercialEmail}
                                type="text"
                                name="commercialEmail"
                                placeholder={"max.sheamans@infront.com"}
                            />
                        )}
                    />
                    {errors?.commercialEmail?.type === "required" &&
                        <p className={styles.formErrorBlock}>Required Field</p>}
                    {errors?.commercialEmail?.type === "minLength" &&
                        <p className={styles.formErrorBlock}>Commercial Email should contain at least 10
                            characters</p>}
                    {errors?.commercialEmail?.type === "maxLength" &&
                        <p className={styles.formErrorBlock}>Commercial Email should contain max 40
                            characters</p>}
                    {errors?.commercialEmail?.type === "pattern" &&
                        <p className={styles.formErrorBlock}>Commercial Email should be like
                            example@xxx.xx</p>}
                    {errors?.commercialEmail?.type === "uniqueEmail" &&
                        <p className={styles.formErrorBlock}>Commercial Email with this email already
                            exists</p>}
                </label>
            </div>

            <div className={styles.twoInputsContainer}>
                <label className={styles.label}>Scheduling Contact <span>*</span>
                    <Controller
                        control={control}
                        name="schedulingContact"
                        rules={{
                            required: !stepOneFormData.schedulingContact,
                            minLength: 4,
                            validate: {
                                isOnlyWhiteSpaces: (val) => checkAllValuesContainCharacters(val, stepOneFormData.schedulingContact),
                                valuesNumber: (val) => checkIsValuesNumberEqual(val, stepOneFormData.schedulingContact, stepOneFormData.schedulingEmail),
                            }
                        }}
                        render={({field: {onChange}}) => (
                            <textarea
                                className={styles.schedulingTextarea}
                                onChange={(e) => {
                                    onChange(e);
                                    onChangeStepOne(e)
                                }}
                                value={stepOneFormData.schedulingContact}
                                name="schedulingContact"
                                placeholder={"You can enter several scheduling contacts here. Separators should be a comma or semi colon"}
                            />
                        )}
                    />
                    {errors?.schedulingContact?.type === "required" &&
                        <p className={styles.formErrorBlock}>Required Field</p>}
                    {errors?.schedulingContact?.type === "minLength" &&
                        <p className={styles.formErrorBlock}>
                            Scheduling Contact should contain at least 4 characters
                        </p>
                    }
                    {errors?.schedulingContact?.type === "valuesNumber" &&
                        <p className={styles.formErrorBlock}>
                            The number of Scheduling Contacts must correspond to the number of Scheduling Emails
                        </p>
                    }
                    {errors?.schedulingContact?.type === "isOnlyWhiteSpaces" &&
                        <p className={styles.formErrorBlock}>Scheduling Contact should contain at least
                            4
                            characters</p>}
                </label>

                <label className={styles.label}>Scheduling Email <span>*</span>
                    <Controller
                        control={control}
                        name="schedulingEmail"
                        rules={{
                            required: !stepOneFormData.schedulingEmail,
                            minLength: 10,
                            validate: {
                                uniqueEmail: (val) => checkIsUniqueEmail(val, existingEmails),
                                valuesAreValid: (val) => checkAreEmailValuesValid(val, stepOneFormData.schedulingEmail),
                                valuesNumber: (val) => checkIsValuesNumberEqual(val, stepOneFormData.schedulingEmail, stepOneFormData.schedulingContact),
                            }
                        }}
                        render={({field: {onChange}}) => (
                            <textarea
                                className={styles.schedulingTextarea}
                                onChange={(e) => {
                                    onChange(e);
                                    onChangeStepOne(e)
                                }}
                                value={stepOneFormData.schedulingEmail}
                                name="schedulingEmail"
                                placeholder={"You can enter several scheduling emails here. Separators should be a comma or semi colon"}
                            />
                        )}
                    />
                    {errors?.schedulingEmail?.type === "required" &&
                        <p className={styles.formErrorBlock}>Required Field</p>}
                    {errors?.schedulingEmail?.type === "minLength" &&
                        <p className={styles.formErrorBlock}>
                            Scheduling Email should contain at least 10 characters
                        </p>
                    }
                    {errors?.schedulingEmail?.type === "maxLength" &&
                        <p className={styles.formErrorBlock}>
                            Scheduling Email should contain max 40 characters
                        </p>}
                    {errors?.schedulingEmail?.type === "valuesAreValid" &&
                        <p className={styles.formErrorBlock}>Scheduling Email should be like example@xxx.xx</p>
                    }
                    {errors?.schedulingEmail?.type === "valuesNumber" &&
                        <p className={styles.formErrorBlock}>
                            The number of Scheduling Emails must correspond to the number of Scheduling Contacts
                        </p>
                    }
                    {errors?.schedulingEmail?.type === "uniqueEmail" &&
                        <p className={styles.formErrorBlock}>
                            Scheduling Email with this email already
                            exists
                        </p>
                    }
                </label>
            </div>

            <div className={styles.twoInputsContainer}>
                <label className={styles.label}>Live Issues Contact
                    <Controller
                        control={control}
                        name="liveIssuesContact"
                        rules={{
                            maxLength: 40,
                            minLength: 4,
                            validate: {
                                isOnlyWhiteSpaces: (name) => {
                                    if (name) {
                                        return name.trim().length > 3
                                    }
                                }
                            }
                        }}
                        render={({field: {onChange}}) => (
                            <Input
                                onChange={(e) => {
                                    onChange(e);
                                    onChangeStepOne(e)
                                }}
                                value={stepOneFormData.liveIssuesContact}
                                type="text"
                                name="liveIssuesContact"
                                placeholder={"Live Issues Contact"}
                            />
                        )}
                    />
                    {errors?.liveIssuesContact?.type === "minLength" &&
                        <p className={styles.formErrorBlock}>Live Issues Contact should contain at least
                            4
                            characters</p>}
                    {errors?.liveIssuesContact?.type === "maxLength" &&
                        <p className={styles.formErrorBlock}>Live Issues Contact should contain max 40
                            characters</p>}
                    {errors?.liveIssuesContact?.type === "isOnlyWhiteSpaces" &&
                        <p className={styles.formErrorBlock}>Live Issues Contact should contain at least
                            4
                            characters</p>}
                </label>

                <label className={styles.label}>Live Issues Email <span>*</span>
                    <Controller
                        control={control}
                        name="liveIssuesContactEmail"
                        rules={{
                            required: !stepOneFormData.liveIssuesContactEmail,
                            maxLength: 40,
                            minLength: 10,
                            pattern: /^\S+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                            validate: {
                                uniqueEmail: (e) => checkIsUniqueEmail(e, existingEmails)
                            }
                        }}
                        render={({field: {onChange}}) => (
                            <Input
                                onChange={(e) => {
                                    onChange(e);
                                    onChangeStepOne(e)
                                }}
                                value={stepOneFormData.liveIssuesContactEmail}
                                type="text"
                                name="liveIssuesContactEmail"
                                placeholder={"max.sheamans@infront.com"}
                            />
                        )}
                    />
                    {errors?.liveIssuesContactEmail?.type === "required" &&
                        <p className={styles.formErrorBlock}>Required Field</p>}
                    {errors?.liveIssuesContactEmail?.type === "minLength" &&
                        <p className={styles.formErrorBlock}>Live Issues Email should contain at least
                            10
                            characters</p>}
                    {errors?.liveIssuesContactEmail?.type === "maxLength" &&
                        <p className={styles.formErrorBlock}>Live Issues Email should contain max 40
                            characters</p>}
                    {errors?.liveIssuesContactEmail?.type === "pattern" &&
                        <p className={styles.formErrorBlock}>Live Issues Email should be like
                            example@xxx.xx</p>}
                    {errors?.liveIssuesContactEmail?.type === "uniqueEmail" &&
                        <p className={styles.formErrorBlock}>Live Issues Email with this email already
                            exists</p>}
                </label>
            </div>
        </div>
    );
});

export default StepOneForm;
