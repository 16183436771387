import {NavigateFunction} from "react-router-dom";
import {Group} from "../../../enums/group";

export const navigateToTable = (
    navigate: NavigateFunction,
    role: string,
    pageNumber: number,
    fixtureId: string | null,
    groupId?: string
) => {
    if (role === Group.RightHolder) {
        navigate(`cms/upcoming-fixtures?pageNumber=${pageNumber}&fixtureId=${fixtureId}`);
    } else {
        navigate(`cms/igame-rightHolders/${groupId}/rightHolder-upcoming-fixtures?pageNumber=${pageNumber}&fixtureId=${fixtureId}`);
    }
};
