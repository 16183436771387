import React from 'react';
import styles from "./index.module.scss";
import searchIcon from "../../img/searchWhite.svg";
import Input from "../Input/Input";

interface ISearchByTextInputProps {
    value: string | undefined;
    onChange: (targetValue: string) => void;
    width?: number;
}

const SearchByTextInput = (props: ISearchByTextInputProps) => {
    const {value, width, onChange} = props;
    return (
        <div className={styles.container}>
            <Input
                style={{width}}
                placeholder={"search"}
                className={styles.input}
                value={value}
                onChange={(e) => onChange(e.target.value)}
            />
            <img src={searchIcon} alt={'search input'}/>
        </div>
    );
};

export default SearchByTextInput;
SearchByTextInput.displayName = "SearchByTextInput";
