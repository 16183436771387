import React, {useEffect} from 'react';
import {SingleValue} from 'react-select';
import {useAppDispatch, useAppSelector} from "@hooks/hooks";
import {Ingest, IngestFormController, IngestOptionId, IngestTabs} from "@enums/ingest";
import {RootState} from "@store/index";
import {Control, Controller, FieldErrors, FieldPath, FieldValues, UseFormRegister} from "react-hook-form";
import SelectInput from "@components/SelectInput/SelectInput";
import {
    ISelectedMethods
} from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/children/IngestForm/types/types";
import {IIngestFormData} from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/BookFixtures";
import {setIsShowIngetsMethod} from "@store/rightHolderBooking/rightHolderBookingSlice";
import {
    generateRegisterValue
} from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/children/IngestForm/helpers/generateRegisterValue";

type SingleValueType = SingleValue<{ value: number | string; label: string; }>;

interface IIngestOption {
    value: IngestOptionId;
    label: Ingest;
}

interface MethodSelectorProps {
    currentTabState: IngestTabs;
    selectedMethods: { primaryType: SingleValueType | null; backupType: SingleValueType | null };
    setSelectedMethods: React.Dispatch<React.SetStateAction<ISelectedMethods>>;
    selectHandler: (method: SingleValueType) => void;
    setIngestFormData: React.Dispatch<React.SetStateAction<IIngestFormData>>;
    ingestOptions: IIngestOption[];
    setValue: (name: string, value: SingleValueType) => void;
    errors: FieldErrors;
    clearErrors: (name?: FieldPath<FieldValues> | FieldPath<FieldValues>[]) => void;
    register: UseFormRegister<FieldValues>;
    control: Control;
}

const IngestMethodSelector: React.FC<MethodSelectorProps> = ({
                                                                 setValue,
                                                                 selectedMethods,
                                                                 selectHandler,
                                                                 ingestOptions,
                                                                 currentTabState,
                                                                 control,
                                                                 errors,
                                                                 setIngestFormData,
                                                                 clearErrors,
                                                                 setSelectedMethods,
                                                                 register
                                                             }) => {

    const {isShowIngestMethod, availableIngestTypes} = useAppSelector((state: RootState) => state.rightHolderBooking);
    const dispatch = useAppDispatch();
    const filterOptions = () => {
        return availableIngestTypes?.length
            ? ingestOptions.filter(option => availableIngestTypes.includes(option.value))
            : ingestOptions;
    };

    useEffect(() => {
        selectExistingIngest();
        clearErrors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTabState]);

    const selectExistingIngest = () => {
        const {primaryType, backupType} = selectedMethods;
        if (currentTabState === IngestTabs.Primary) {
            if (primaryType?.value === IngestOptionId.Satellite) {
                return setValue(IngestFormController.PrimaryMethod, {
                    value: IngestOptionId.Satellite,
                    label: Ingest.Satellite
                });
            }
            if (primaryType?.value === IngestOptionId.BTTower) {
                return setValue(IngestFormController.PrimaryMethod, {
                    value: IngestOptionId.BTTower,
                    label: Ingest.BTTower
                });
            }
            if (primaryType?.value === IngestOptionId.IP) {
                return setValue(IngestFormController.PrimaryMethod, {
                    value: IngestOptionId.IP,
                    label: Ingest.IP
                });
            }
        }

        if (currentTabState === IngestTabs.BackUP) {
            if (backupType?.value === IngestOptionId.Satellite) {
                setValue(IngestFormController.BackupMethod, {
                    value: IngestOptionId.Satellite,
                    label: Ingest.Satellite
                });
            }
            if (backupType?.value === IngestOptionId.IP) {
                setValue(IngestFormController.BackupMethod, {
                    value: IngestOptionId.IP,
                    label: Ingest.IP
                });
            }
            if (backupType?.value === IngestOptionId.BTTower) {
                setValue(IngestFormController.BackupMethod, {
                    value: IngestOptionId.BTTower,
                    label: Ingest.BTTower
                });
            }
        }
    };

    const setMainIngest = (e: SingleValueType) => {
        if (!e) return;

        if (currentTabState === IngestTabs.BackUP) {
            setIngestFormData(prevData => ({
                ...prevData,
                backupIngestType: Number(e.value)
            }));
            setSelectedMethods(prevMethods => ({
                ...prevMethods,
                backupType: e
            }));
        } else {
            setIngestFormData(prevData => ({
                ...prevData,
                type: Number(e.value)
            }));
            setSelectedMethods(prevMethods => ({
                ...prevMethods,
                mainType: e
            }));
        }
    };

    const changeIngestMethodHandler = (e: SingleValueType) => {
        dispatch(setIsShowIngetsMethod(true))
        selectHandler(e);
        setMainIngest(e);
        clearErrors();
    };

    return isShowIngestMethod ? (
        <label>
            {currentTabState === IngestTabs.Primary ? "Ingest Method" : "Backup Ingest Method"}
            <Controller
                control={control}
                name={
                    currentTabState === IngestTabs.Primary
                        ? IngestFormController.PrimaryMethod
                        : IngestFormController.BackupMethod
                }
                rules={{
                    required:
                        currentTabState === IngestTabs.Primary
                            ? !selectedMethods?.primaryType
                            : !selectedMethods?.backupType
                }}
                render={({field: {onChange, value}}) => (
                    <SelectInput
                        {...register(generateRegisterValue(currentTabState))}
                        value={value}
                        onChange={e => {
                            onChange(e);
                            changeIngestMethodHandler(e);
                        }}
                        options={filterOptions()}
                    />
                )}
            />
            {errors.PrimaryMethod && errors.PrimaryMethod.type === "required" && (
                <span>{errors.PrimaryMethod.message && String(errors.PrimaryMethod.message)}</span>
            )}
            {errors.BackupMethod && errors.BackupMethod.type === "required" && (
                <span>{errors.BackupMethod.message && String(errors.BackupMethod.message)}</span>
            )}
        </label>
    ) : null;
};

export default IngestMethodSelector;
