import {AnyAction, createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {toastr} from "react-redux-toastr";
import {client} from "@services/api-servise";
import {AxiosError} from "axios";
import {IInputOption} from "../operatorAccess/operatorAccessSlice";
import {getPickerDateFormat, ICalendarDate} from "@helpers/dateFormatters";
import {DateTime} from "luxon";
import {RootState} from "../index";
import {IUserItem} from "../iGameInit/iGameInitSlice";

export interface IGetBookedContentPayload {
    startDate: string;
    endDate: string;
    providersIds: number[];
    sportsIds: number[];
    properties: string[];
    sorting: {
        property: string;
        isAscending: boolean;
    };
    operatorId: string | null;
}

export interface IBookedFixture {
    eventId: string;
    date: string;
    day: string;
    time: string;
    provider: string;
    sport: string;
    property: string;
    propertyId: string;
    fixture: string;
    carveOuts: string[];
    isLive: boolean;
}

export interface IGetBookedContentResponse {
    bookedEvents: IBookedFixture[];
    count: number;
}

export interface IFiltersRequestPayload {
    startDate: string;
    endDate: string;
    providersIds: [];
    sportsIds: [];
    properties: [];
    operatorId: string | null;
}

interface IFiltersResponse {
    providers: {
        items: IInputOption[]
    };
    sports: {
        items: IInputOption[]
    };
    properties: string[];
}

export interface IDropdownOptions {
    providers: IInputOption[];
    sports: IInputOption[];
    properties: string[];
}

export interface ISelectOption {
    value: string,
    label: string
}

export interface IFiltersState {
    startDate: ICalendarDate;
    endDate: ICalendarDate;
    providers: ISelectOption[];
    sports: ISelectOption[];
    properties: ISelectOption[];
}

export interface IStreamLinksRequestPayload {
    operatorId: string | null;
    eventId: string;
}

export interface IStreamLinksResponse {
    hlsStreamLink: string;
    ullStreamLink: string;
}

export interface IBookedContentState {
    contentInfo: IGetBookedContentResponse;
    filters: IFiltersState;
    dropdownOptions: IDropdownOptions;
    bookContentCsv: string;
    csvFileName: string;
    hlsStreamLink: string;
    ullStreamLink: string;
    loadingStatus: "idle" | "loading" | "failed",
    error: string,
}

const initialState: IBookedContentState = {
    contentInfo: {
        bookedEvents: [],
        count: 0
    },
    filters: {
        startDate: getPickerDateFormat(DateTime.now().toISO(),true),
        endDate: getPickerDateFormat(DateTime.now().plus({days: 4}).toISO(),true),
        providers: [],
        sports: [],
        properties: [],
    },
    dropdownOptions: {
        providers: [],
        sports: [],
        properties: []
    },
    bookContentCsv: "",
    hlsStreamLink: "",
    ullStreamLink: "",
    csvFileName: "",
    loadingStatus: "idle",
    error: "",
}

export const getBookedContent = createAsyncThunk<IGetBookedContentResponse, IGetBookedContentPayload, {
    rejectValue: string
}>(
    "operator-booked-content/get-fixtures",
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.post("api/Fixtures/operator/booked-content", payload);
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error("IGame", `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getFiltersOptions = createAsyncThunk<IFiltersResponse, IFiltersRequestPayload, { rejectValue: string }>(
    "operator-booked-content/filter-items",
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.post("api/Fixtures/operator/booked-content/filter-items", payload);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error("IGame", `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getBookedContentCSV = createAsyncThunk<string, IGetBookedContentPayload, { rejectValue: string }>(
    "operator-booked-content/get-csv",
    async (body, {rejectWithValue, getState}) => {
        try {
            const {data} = await client.post("api/Fixtures/operator/booked-content/download", body);

            const state = getState() as RootState;
            const operators = state.iGameInit.users.operators;
            const operator = operators.find((item: IUserItem) => item.id === body.operatorId);
            const operatorName = operator ? operator.name : "";
            const filename = `bookedFixtures_${operatorName}_${DateTime.fromISO(body.startDate).toFormat("dd-MM-yyyy")}--${DateTime.fromISO(body.endDate).toFormat("dd-MM-yyyy")}.csv`;

            const blob = new Blob([data], {type: "text/csv"});
            const url = URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename || "download.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);

            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error("IGame", `${e.response?.data}`);
                return rejectWithValue(e.response?.data);
            }
        }
    }
);

export const getStreamLinks = createAsyncThunk<IStreamLinksResponse, IStreamLinksRequestPayload, {
    rejectValue: string
}>(
    "operator-booked-content/stream-links",
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Fixtures/operator/${payload.operatorId}/stream-link?eventId=${payload.eventId}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error("IGame", `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const operatorBookedContentSlice = createSlice({
    name: 'operator-booking',
    initialState,
    reducers: {
        setFilters: (state, action: PayloadAction<{ value: any; name: keyof IBookedContentState["filters"] }>) => {
            state.filters[action.payload.name] = action.payload.value;
        },
        resetFilters: (state) => {
            state.filters.providers = [];
            state.filters.sports = [];
            state.filters.properties = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBookedContent.pending, (state) => {
                state.error = "";
                state.loadingStatus = "loading";
            })
            .addCase(getBookedContent.fulfilled, (state, action: PayloadAction<IGetBookedContentResponse>) => {
                state.loadingStatus = "idle";
                state.contentInfo = action.payload;
            })
            .addCase(getBookedContent.rejected, (state) => {
                state.loadingStatus = "failed";
            })
            .addCase(getFiltersOptions.fulfilled, (state, action) => {
                state.dropdownOptions.providers = action.payload.providers.items;
                state.dropdownOptions.sports = action.payload.sports.items;
                state.dropdownOptions.properties = action.payload.properties;
            })
            .addCase(getStreamLinks.pending, (state) => {
                state.hlsStreamLink = "";
                state.ullStreamLink = "";
            })
            .addCase(getStreamLinks.fulfilled, (state, action: PayloadAction<IStreamLinksResponse>) => {
                state.hlsStreamLink = action.payload.hlsStreamLink;
                state.ullStreamLink = action.payload.ullStreamLink;
            })
            .addCase(getStreamLinks.rejected, (state) => {
                state.hlsStreamLink = "";
                state.ullStreamLink = "";
            })
            .addMatcher(isError, (state, action: PayloadAction<string>) => {
                state.error = action.payload;
            })
    },
})

export const {
    setFilters,
    resetFilters
} = operatorBookedContentSlice.actions
export default operatorBookedContentSlice.reducer

function isError(action: AnyAction) {
    return action.type.endsWith("rejected")
}
