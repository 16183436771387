import {
    IFiltersState,
    IGetPortfolioPayload
} from "../../../../../../store/operatorRightsOverview/operatorRightsOverviewSlice";
import {ISorting} from "../../../../../../components/OperatorDefaultTable/OperatorDefaultTable";

export const createRequestModel = (filters: IFiltersState, operatorId: string | null, sorting?: ISorting): IGetPortfolioPayload => {
    let sortingBody = {
        property: '',
        isAscending: false
    }

    if (sorting) {
        sortingBody.property = sorting.property;
        sortingBody.isAscending = sorting.isAscending;
    }

    return {
        operatorId,
        rightHoldersIds: filters.rightHolders.map(rightHolder => rightHolder.value),
        sportsIds: filters.sports.map(sport => sport.value),
        countriesIds: filters.countries.map(country => country.value),
        sorting: sortingBody
    }
};
