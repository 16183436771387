import {useState} from "react";
import {useAppDispatch} from "../../../../../hooks/hooks";
import {useDebounce} from "../../../../../helpers/useDebounce";
import {AppThunk} from "../../../../../store";
import {
    IFiltersState,
    IGetBookedContentPayload
} from "../../../../../store/operatorBookedContent/operatorBookedContentSlice";
import {validateDateRange} from "../helpers/validateDateRange";
import {ISorting} from "../../../../../components/OperatorDefaultTable/OperatorDefaultTable";

export const useSortingAndFilters = (operatorId: string | null, request: (payload: IGetBookedContentPayload) => AppThunk, filters: IFiltersState) => {
    const [sorting, setSorting] = useState<ISorting>({
        property: "",
        isAscending: true
    });

    const dispatch = useAppDispatch();

    const getSortedItems = () => {
        if (validateDateRange(filters.startDate, filters.endDate)) {
            dispatch(request({
                // startDate: formatDatePicker(filters.startDate),
                // endDate: formatDatePicker(filters.endDate),
                startDate: "2024-01-28T17:43:11.640Z",
                endDate: "2024-02-15T17:43:11.640Z",
                providersIds: filters.providers.map(provider => +provider.value),
                sportsIds: filters.sports.map(sport => +sport.value),
                properties: filters.properties.map(property => property.value),
                sorting: {
                    property: sorting.property,
                    isAscending: sorting.isAscending
                },
                operatorId,
            }));
        }
    };

    useDebounce(getSortedItems, [sorting, filters], 500);

    return {setSorting, sorting};
};
