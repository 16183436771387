import moment from "moment";
import {IDataPoint} from "../../../../../store/contentAnalysis/types";

export const transformToFiveDayView = (dataPoints: IDataPoint[]) => {
    const transformedData = [['Time', 'Booked', {role: 'annotation'}]];

    dataPoints.forEach((dataPoint, index) => {
        const dayLabel = moment(dataPoint.time).format('ddd D MMMM YY');
        if (index % 5 === 0) {
            transformedData.push([dayLabel, dataPoint.bookedCount, '']);
        } else {
            transformedData.push(['', dataPoint.bookedCount, '']);
        }

        if ((index + 1) % 5 === 0) {
            // @ts-ignore
            transformedData.push(['', null, null]);
        }
    });
    return transformedData;
};

export const transformToFiveDayViewWithAvailable = (dataPoints: IDataPoint[]) => {
    const transformedData = [['Time', 'Booked', 'Available', {role: 'annotation'}]];

    dataPoints.forEach((dataPoint, index) => {
        const dayLabel = moment(dataPoint.time).format('ddd D MMMM YY');
        if (index % 5 === 0) {
            transformedData.push([dayLabel, dataPoint.bookedCount, dataPoint.availableCount, '']);
        } else {
            transformedData.push(['', dataPoint.bookedCount, dataPoint.availableCount, '']);
        }

        if ((index + 1) % 5 === 0) {
            // @ts-ignore
            transformedData.push(['', null, null, '']);
        }
    });
    return transformedData;
};

export const convertToMonthView = (data: IDataPoint[]) => {
    const monthViewAvailable = [['Month', 'Booked']];
    data.forEach((item) => {
        const date = new Date(item.time);
        const month = (date.getMonth() + 1).toString()
        const booked = item.bookedCount;
        monthViewAvailable.push([month, booked]);
    });
    return monthViewAvailable;
};

export const convertToMonthViewWithAvailable = (data: IDataPoint[]) => {
    const monthViewAvailable = [['Month', 'Booked', 'Available']];
    data.forEach((item) => {
        const date = new Date(item.time);
        const month = (date.getMonth() + 1).toString()
        const booked = item.bookedCount;
        const available = item.availableCount;
        monthViewAvailable.push([month, booked, available]);
    });
    return monthViewAvailable;
};
export const convertToBookedAndAvailableDayView = (data: IDataPoint[]) => {
    const chartData = [['Time', 'Booked', 'Available']];
    data.forEach((item) => {
        const time = moment(item.time).format("HH:mm")
        const booked = item.bookedCount;
        const available = item.availableCount;
        chartData.push([time, booked, available]);
    });
    return chartData;
};

export const convertToBookedDayView = (data: IDataPoint[]) => {
    const chartData = [['Time', 'Booked']];
    data.forEach((item) => {
        const time = moment(item.time).format("HH:mm")
        const booked = item.bookedCount;
        chartData.push([time, booked]);
    });

    return chartData;
};
