import React, {useEffect, useState} from 'react';
import PrimaryButton from "../../../../components/PrimaryButton/PrimaryButton";
import CreateAssignmentTable from "./CreateAssignmentTable/CreateAssighmentTable";
import ExistingAssignmentTable from "./ExistingAssignmentTable/ExistingAssignmentTable";
import {useAppDispatch} from "../../../../hooks/hooks";
import {
    deleteConfirmedAssignment,
    getAssignmentInit,
    getConfirmedAssignment
} from "../../../../store/rightAssignment/rightAssignmentSlice";
import ConfirmModal from "../../../../components/ConfirmModal/ConfirmModal";
import styles from './style.module.scss'
import RightAssignmentForm from "./RightsAssignmentForm/RightAssignmentForm";
import CompetitionForm from "./CompetitionForm/CompetitionForm";
import {IPageMenuProps} from "../../AppRoutes/routes/RouteInterface";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import {useDisableScroll} from "../../../../hooks/useDisableScroll";
import UpdateExistingAssignmentForm from "./UpdateExistingAssignmentForm/UpdateExistingAssignmentForm";
import trash from "../../../../img/trashWhite.svg";

const RightAssignment: React.FC<IPageMenuProps> = ({menuStructure}) => {
    const dispatch = useAppDispatch()
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [isOpenCompetitionForm, setIsOpenCompetitionForm] = useState(false);
    const [isOpenUpdateExistingForm, setIsOpenUpdateExistingForm] = useState(false);
    const [updatingAssignment, setUpdatingAssignment] = useState(0);
    const [updatingExistingAssignment, setUpdatingExistingAssignment] = useState({
        rightHolder: "",
        id: 0
    });
    const [deletingAssignment, setDeletingAssignment] = useState(0);
    const [isOpenConfirm, setIsOpenConfirm] = useState(false);
    useDisableScroll(isOpenForm, isOpenCompetitionForm);

    const closeForm = () => {
        setIsOpenForm(false);
        setIsOpenUpdateExistingForm(false);
    }

    const closeCompetitionForm = () => {
        setIsOpenCompetitionForm(false)
    }

    useEffect(() => {
        dispatch(getAssignmentInit())
        dispatch(getConfirmedAssignment())
    }, [dispatch]);

    const onDeleteAssignment = () => {
        dispatch(deleteConfirmedAssignment(deletingAssignment))
    }

    const openConfirmModal = (assignmentId: number) => {
        setDeletingAssignment(assignmentId)
        setIsOpenConfirm(true)
    }

    return (
        <>
            {isOpenForm &&
                <RightAssignmentForm
                    setUpdatingAssignment={setUpdatingAssignment}
                    updatingAssignment={updatingAssignment}
                    closeForm={closeForm}
                />}
            {isOpenUpdateExistingForm &&
                <UpdateExistingAssignmentForm
                    setUpdatingExistingAssignment={setUpdatingExistingAssignment}
                    updatingExistingAssignment={updatingExistingAssignment}
                    closeForm={closeForm}
                />}
            {isOpenCompetitionForm &&
                <CompetitionForm
                    closeForm={closeCompetitionForm}
                />}
            {isOpenConfirm &&
                <ConfirmModal
                    action={onDeleteAssignment}
                    closeForm={() => setIsOpenConfirm(false)}
                    title={"Delete Assignment"}
                    message={"Are you sure you want to delete assignment?"}>
                    <img className={styles.deleteIconBig} src={trash} alt="trash"/>
                </ConfirmModal>
            }
            <PageHeader menuStructure={menuStructure} title={"Rights Assignment"}/>
            <div className={styles.pageInner}>
                <div className={styles.pageContainer}>
                    <div className={styles.titleWrapper}>
                        <h4 className={styles.title}>{"Rights Assignment"}</h4>
                        <div className={styles.formBtnInner}>
                            <div className={styles.addBtn}>
                                <PrimaryButton
                                    onClick={() => setIsOpenForm(true)}
                                >
                                    <span className={styles.addBtnText}>{"Add Assignment"}</span>
                                    <span className={styles.plusIcon}>+</span>
                                </PrimaryButton>
                            </div>
                            <div className={`${styles.addBtn} ${styles.competitionBtn}`}>
                                <PrimaryButton
                                    onClick={() => setIsOpenCompetitionForm(true)}
                                >
                                    <span className={styles.addBtnText}>{"Add Property"}</span>
                                    <span className={styles.plusIcon}>+</span>
                                </PrimaryButton>
                            </div>
                        </div>
                    </div>
                    <div className={styles.tablesWrapper}>
                        <CreateAssignmentTable
                            openAssignmentForm={setIsOpenForm}
                            setUpdatingAssignment={setUpdatingAssignment}
                        />
                        <ExistingAssignmentTable
                            openConfirm={openConfirmModal}
                            openUpdateExistingForm={setIsOpenUpdateExistingForm}
                            setUpdatingExistingAssignment={setUpdatingExistingAssignment}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

RightAssignment.displayName = "RightAssignment"
export default RightAssignment;
