import React from "react";
import AnalyticsDashboard from "../../../../components/AnalyticsDashboard/AnalyticsDashboard";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {
    createFiltersBookmark,
    deleteFiltersBookmark,
    downloadRightHolderAnalyticsCsv,
    getInitFiltersOptions,
    getRightHolderAnalytics,
    setSelectedBookmark,
    updateFiltersBookmark,
    setFiltersByBookmarks,
    setFilters,
    resetFilters,
    IRightHolderAnalyticsState
} from "../../../../store/rightHolderAnalytics/rightHolderAnalyticsSlice";
import {RootState} from "../../../../store";
import {IPageMenuProps} from "../../AppRoutes/routes/RouteInterface";
import {ISelectOption} from "@store/operatorBookedContent/operatorBookedContentSlice";

const AnalyticsPage: React.FunctionComponent<IPageMenuProps> = ({menuStructure}) => {
    const dispatch = useAppDispatch()
    const {
        initData,
        mainData,
        loading,
        controls,
        filters
    } = useAppSelector((state: RootState) => state.rightHolderAnalytics);

    const onSetFilters = (value: ISelectOption, name: keyof IRightHolderAnalyticsState["filters"]) => {
        dispatch(setFilters({value, name}))
    }
    const onResetFilters = () => {
        dispatch(resetFilters())
    }
    const onSetFiltersByBookmarks = (filters: ISelectOption) => {
        dispatch(setFiltersByBookmarks(filters))
    }
    return (
        <>
            <AnalyticsDashboard
                setFiltersByBookmarks={onSetFiltersByBookmarks}
                filters={filters}
                setFilters={onSetFilters}
                resetFilters={onResetFilters}
                controls={controls}
                menuStructure={menuStructure}
                getInitData={getInitFiltersOptions}
                initData={initData}
                getData={getRightHolderAnalytics}
                data={mainData}
                createBookmark={createFiltersBookmark}
                updateBookmark={updateFiltersBookmark}
                deleteBookmark={deleteFiltersBookmark}
                setSelectedBookmark={setSelectedBookmark}
                downloadCsv={downloadRightHolderAnalyticsCsv}
                client={["customers"]}
                loading={loading}
            />
        </>
    )
}

export default AnalyticsPage;
