import {Items} from "../../../../../../../store/generalIssues/generalIssuesSlice";

export const findOptions = (options: Items[], id: number | string[]) => {
    if (Array.isArray(id)) {
        return options
            .filter(item => id.includes(item.id as string))
            .map(item => ({value: item.id, label: item.name}));
    }
    const option = options?.find((option: Items) => option?.id === String(id));
    return option ? {value: option.id, label: option.name} : undefined;
};
