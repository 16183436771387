import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {client} from "../../services/api-servise";
import {toastr} from "react-redux-toastr";

export interface IIssueDetailsResponse {
    id: string;
    rightHolder: string;
    technicalProvider: string;
    competition: string;
}

export interface IAuditTrailsState {
    issueDetails: IIssueDetailsResponse | null;
    loading: boolean;
}

const initialState: IAuditTrailsState = {
    issueDetails: null,
    loading: false,
}

export const getIssueDetails = createAsyncThunk<IIssueDetailsResponse, string, { rejectValue: string }>(
    'audit-trails/issues-details',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.post("api/Issues/audit-details", {issueId: id})
            return data;
        } catch (e: any) {
            toastr.error('Error!', `${e.response.data}`)
            return rejectWithValue(e.response.data)
        }
    }
)

export const auditTrailsIssuesSlice = createSlice({
    name: 'audit-trails-issues',
    initialState,
    reducers: {
        setIssuesDetails: (state, action) => {
            state.issueDetails = action.payload;
        },
        resetIssuesDetails: (state) => {
            state.issueDetails = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getIssueDetails.pending, (state) => {
                state.loading = true;
            })
            .addCase(getIssueDetails.fulfilled, (state, action) => {
                state.issueDetails = action.payload;
                state.loading = false;
            })
            .addCase(getIssueDetails.rejected, (state) => {
                state.loading = false;
            })
    },
})

export const {setIssuesDetails} = auditTrailsIssuesSlice.actions
export default auditTrailsIssuesSlice.reducer
