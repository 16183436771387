import {Tooltip} from "react-tooltip";
import React from "react";
import "./index.scss";

export interface DropdownTooltipProps {
    id: string;
    text: string;
}

const DropdownTooltip: React.FunctionComponent<DropdownTooltipProps> = ({id, text}) => {
    return (
        <div className="tooltip-wrapper">
            <Tooltip id={id}>
                {text}
            </Tooltip>
            <div className="dropdown-tooltip"
                 data-tooltip-content={text}
                 data-tooltip-id={id}
                 data-tooltip-place="top">
                {text}
            </div>
        </div>
    );
}
DropdownTooltip.displayName = "DropdownTooltip"
export default DropdownTooltip;
