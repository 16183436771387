import React from 'react';
import styles from '../index.module.scss';
import {IFixtures, selectFixture} from '../../../../../../../../store/generalIssues/generalIssuesSlice';
import {useAppDispatch, useAppSelector} from '../../../../../../../../hooks/hooks';
import uuid from "react-uuid";
import CustomCheckbox from "../../../../../../../../components/CustomCheckbox/CustomCheckbox";
import {Tooltip} from "react-tooltip";

interface IGeneralIssueTable {
    searchedFixtures: IFixtures[];
}

const FixturesTable: React.FC<IGeneralIssueTable> = ({searchedFixtures}) => {
    const dispatch = useAppDispatch();
    const {selectedFixtures} = useAppSelector(state => state.reportingGeneralIssues);

    const isFixtureSelected = (fixture: IFixtures) => {
        if (selectedFixtures) {
            return selectedFixtures.some(selectedFixture => selectedFixture.fixtureId === fixture.fixtureId);
        }
    };

    const handleCheckboxChange = (fixture: IFixtures) => {
        dispatch(selectFixture(fixture));
    };

    return (
        <table className={styles.fixtureTable}>
            <thead>
            <tr>
                <th>Start time</th>
                <th>Title</th>
                <th>Select</th>
            </tr>
            </thead>
            <tbody>
            {searchedFixtures && searchedFixtures.length > 0 ? (
                searchedFixtures.map((fixture) => (
                    <tr
                        key={uuid()}
                        className={isFixtureSelected(fixture) ? styles.fixtureSelectedRow : styles.fixtureRow}
                    >
                        <td>{fixture.startTime}</td>
                        <td>
                            <div
                                className={styles.tableRowTitle}
                                data-tooltip-id={fixture.fixtureId}
                                data-tooltip-content={fixture.title}
                                id={fixture.fixtureId}
                            >
                                {fixture.title}
                            </div>

                            <Tooltip className={styles.titleTooltip} id={fixture.fixtureId}/>

                        </td>
                        <td className={styles.fixtureTableCheckbox}>
                            <CustomCheckbox
                                id={`${fixture.fixtureId}&affected`}
                                onChangeHandler={() => handleCheckboxChange(fixture)}
                                checked={isFixtureSelected(fixture)}
                            />
                        </td>
                    </tr>
                ))
            ) : (
                <tr>
                    <td colSpan={3}>There are no available events</td>
                </tr>
            )}
            </tbody>
        </table>
    );
};

export default FixturesTable;
