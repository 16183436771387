import React, {ChangeEvent, useRef, useState} from 'react';
import ReportingTable from "./ReportingTable/ReportingTable";
import {IPageMenuProps} from "../../../AppRoutes/routes/RouteInterface";
import PageHeader from "../../../../../components/PageHeader/PageHeader";
import {useAppDispatch, useAppSelector} from "@hooks/hooks";
import {
    clearFilter,
    getFixturesReport, setFilters,
    setHasMore,
} from "@store/reportingFixtures/reportingFixturesSlice";
import {useDebounce} from "@helpers/useDebounce";
import GeneralIssueForm from "./GeneralIssueForm/GeneralIssueForm";
import {IFixturesState, IReportingFilters} from "@store/reportingFixtures/types";
import {formatRangeDate} from "@helpers/dateFormatters";
import {HiOutlineDownload} from "react-icons/hi";
import {useDisableScroll} from "@hooks/useDisableScroll";
import {handleDownloadCSV} from "./ReportingTable/helpers/handleDownloadSCV";
import {useDeepCompareEffect} from "@hooks/useDeepCompareEffect";
import Input from "../../../../../components/Input/Input";
import searchWhite from "../../../../../img/searchWhite.svg";
import HeaderFilters from "../../../OperatorView/AvailableContent/HeaderFilters/HeaderFilters";
import styles from './index.module.scss'
import {createReportingPayload} from "@pages/Cms/IGameView/Settings/Reporting/helpers/createReportingPayload";
import {RootState} from "@store/index";


const Reporting: React.FC<IPageMenuProps> = React.memo(({menuStructure}) => {
    const dispatch = useAppDispatch();
    const [isOpenGeneralIssue, setIsOpenGeneralIssue] = useState(false);
    const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
    const {
        pageNumber,
        itemsPerPage,
        isLoading,
        controls,
        filters,
        sorting
    } = useAppSelector((state: RootState) => state.reportingFixtures)

    const containerRef = useRef<HTMLDivElement>(null);

    useDisableScroll(isOpenGeneralIssue);

    useDeepCompareEffect(() => {
        if (!filters.startDate || !filters.endDate || isLoading) {
            return
        }
        dispatch(setHasMore(true))
        const payload = createReportingPayload(searchValue, 1, itemsPerPage, sorting, filters, false)
        dispatch(getFixturesReport(payload))

    }, [filters])


    const onSearch = () => {
        dispatch(setHasMore(true))
        const payload = createReportingPayload(searchValue, 1, itemsPerPage, sorting, filters, false)
        dispatch(getFixturesReport(payload))

        if (containerRef && containerRef.current) {
            containerRef.current.scrollTop = 0
        }
    }

    useDebounce(onSearch, [searchValue], 500);

    const onSetFilters = (value: IReportingFilters, name: keyof IFixturesState["filters"]) => {
        dispatch(setFilters({value, name}))
    }

    const resetFilters = () => dispatch(clearFilter())
    return (
        <>
            <PageHeader
                menuStructure={menuStructure}
                title={"Reporting"}
                subTitle={'Reporting'}
            >
                <div className={styles.topButtons}>
                    <button
                        onClick={() => handleDownloadCSV(
                            'booking-sheet',
                            searchValue,
                            pageNumber,
                            itemsPerPage,
                            filters.startDate,
                            filters.endDate,
                            filters.ingestType!.label
                        )}>
                        Booking sheet:
                        <HiOutlineDownload size={'18px'}/>
                    </button>
                    <button
                        onClick={() => handleDownloadCSV(
                            'ingest-details',
                            searchValue,
                            pageNumber,
                            itemsPerPage,
                            filters.startDate,
                            filters.endDate,
                            filters.ingestType!.label
                        )}>
                        Ingest Details
                        <HiOutlineDownload size={'18px'}/>
                    </button>
                    <button
                        onClick={() => setIsOpenGeneralIssue(true)}>
                        General Issue
                    </button>
                </div>
            </PageHeader>

            <div className={styles.pageInner}>
                <div className={styles.container}>
                    <div className={styles.header}>
                        <h1 className={styles.title}>Reporting</h1>
                        <HeaderFilters
                            resetFilters={resetFilters}
                            filterControls={controls}
                            setFilters={onSetFilters}
                            filterValues={filters}/>
                    </div>

                    <div className={styles.contentWrapper}>
                        <div className={styles.tableControls}>
                            <div className={styles.searchInput}>
                                <Input
                                    type="text"
                                    style={{border: "1px solid #E0E0E0"}}
                                    placeholder="Search"
                                    value={searchValue === undefined ? "" : searchValue}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
                                    autoFocus={true}
                                />
                                <img src={searchWhite} alt="search"/>
                            </div>
                        </div>
                        <ReportingTable
                            containerRef={containerRef}
                            searchValue={searchValue}
                        />
                    </div>
                </div>
            </div>
            {
                isOpenGeneralIssue &&
                <GeneralIssueForm
                    searchEventStartTime={formatRangeDate(filters.startDate)}
                    searchText={searchValue}
                    formHandler={setIsOpenGeneralIssue}
                />
            }
        </>
    );
});

Reporting.displayName = "Reporting"
export default Reporting;
