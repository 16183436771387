import React, {CSSProperties} from "react";
import style from "./index.module.scss";
import {GridLoader} from "react-spinners";

export interface ILoaderProps {
    width?: string;
    height?: string;
    backgroundColor?: string;
}

const Loader: React.FunctionComponent<ILoaderProps> = ({width, height, backgroundColor, ...props}) => {

    const basicStyle: CSSProperties = {
        margin: 50
    }

    return (
        <div style={{width, height, backgroundColor}} className={style.loader}>
            <GridLoader
                color={"#FF3466"}
                loading={true}
                size={20}
                cssOverride={basicStyle}
                aria-label="Loading Spinner"
                data-testid="loader"
                {...props}
            />
        </div>
    )
}

Loader.displayName = "Loader"
export default Loader;