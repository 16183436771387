import React, {useEffect, useState} from 'react';
import styles from "./index.module.scss";
import {IPageMenuProps} from "../../../AppRoutes/routes/RouteInterface";
import {useAppDispatch, useAppSelector, useSearchUsers} from "../../../../../hooks/hooks";
import {getPublishing} from "../../../../../store/publishing/publishingSlice";
import PageHeader from "../../../../../components/PageHeader/PageHeader";
import IGameSubHeader from "../../../../../components/IGameSubHeader/IGameSubHeader";
import {getUserType} from "../../../../../store/userManagement/userManagementSlice";
import SearchByTextInput from "../../../../../components/SearchByTextInput/SearchByTextInput";
import PublishingAccordion from "./children/PublishingAccordion/PublishingAccordion";


const Publishing: React.FC<IPageMenuProps> = React.memo(({menuStructure}) => {
    const dispatch = useAppDispatch();
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const {searchValue, onSearch} = useSearchUsers(getPublishing);
    const {groupedRightHolders} = useAppSelector(state => state.publishing);
    const {rightHoldersCount, operatorsCount} = useAppSelector(state => state.userManagement);

    useEffect(() => {
        dispatch(getPublishing(""))
        dispatch(getUserType(null));
    }, [dispatch])

    return (
        <>
            <PageHeader menuStructure={menuStructure} title={'Publishing'}/>
            <div className={styles.pageInner}>
                <IGameSubHeader
                    rightsHoldersCount={rightHoldersCount}
                    operatorsCount={operatorsCount}
                    title={'Publishing'}
                />

                <div className={styles.pageContainer}>
                    <div className={styles.tableOverlay}>
                        <div className={styles.tableInner}>
                            <div className={styles.options}>
                                <div className={styles.searchInput}>
                                    <SearchByTextInput value={searchValue} onChange={onSearch}/>
                                </div>
                            </div>

                            <table className={styles.publishingTable}>
                                <thead>
                                <tr>
                                    <th>Rights Holder</th>
                                    <th>Sport</th>
                                    <th>Property</th>
                                    <th>
                                        <div className={styles.lastCol}>
                                            <p>Publishing</p>
                                            <p className={!isAccordionOpen && styles.displayNone}>Select all that
                                                apply</p>
                                        </div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {groupedRightHolders.map((item, index) => (
                                    <PublishingAccordion
                                        setIsAccordionOpen={setIsAccordionOpen}
                                        rightHolder={item}
                                        searchValue={searchValue}
                                        key={index}
                                    />
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

Publishing.displayName = "Publishing"
export default Publishing;
