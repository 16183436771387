import {IShowFormType} from "../types/types";
import {IngestFormController, IngestOptionId, IngestTabs} from "@enums/ingest";
import React, {JSX} from "react";
import styles from "../index.module.scss";
import CheckboxWithLabel from "@components/CheckboxWithLabel/CheckboxWithLabel";
import uuid from "react-uuid";
import {FieldErrors, FieldValues, UseFormGetValues} from "react-hook-form";

class IngestRender {

    isRenderBTTower(formTab: IngestTabs, mainMethod: IngestOptionId, backupMethod: IngestOptionId) {
        if (formTab === IngestTabs.Primary && mainMethod === IngestOptionId.BTTower) {
            return true
        }
        return formTab === IngestTabs.BackUP && backupMethod === IngestOptionId.BTTower;
    }

    isRenderSatellite(formTab: IngestTabs, mainMethod: IngestOptionId, backupMethod: IngestOptionId) {
        if (formTab === IngestTabs.Primary && mainMethod === IngestOptionId.Satellite) {
            return true
        }
        return formTab === IngestTabs.BackUP && backupMethod === IngestOptionId.Satellite;
    }

    isRenderFileUploader(formTab: IngestTabs, mainMethod: IngestOptionId, backupMethod: IngestOptionId) {
        if (formTab === IngestTabs.Primary && (mainMethod === IngestOptionId.BTTower || mainMethod === IngestOptionId.Satellite)) {
            return true
        }
        return formTab === IngestTabs.BackUP && (backupMethod === IngestOptionId.BTTower || backupMethod === IngestOptionId.Satellite);
    }

    iGameChannels(formTab: IngestTabs, channels: JSX.Element, getValues: UseFormGetValues<FieldValues>) {
        const {mainMethod, backupMethod} = this.getMethodValues(getValues);
        const isRenderChannels = this.isRenderBTTower(formTab, mainMethod, backupMethod) || this.isRenderSatellite(formTab, mainMethod, backupMethod)
        if (isRenderChannels) {
            return channels
        }
        return null
    }

    isRenderIPChannels(formTab: IngestTabs, mainMethod: IngestOptionId, backupMethod: IngestOptionId) {
        if (formTab === IngestTabs.Primary && mainMethod === IngestOptionId.IP) {
            return true
        }
        return formTab === IngestTabs.BackUP && backupMethod === IngestOptionId.IP;
    }

    IPChannels(channels: JSX.Element, formTab: IngestTabs, getValues: UseFormGetValues<FieldValues>) {
        const {mainMethod, backupMethod} = this.getMethodValues(getValues);
        if (this.isRenderIPChannels(formTab, mainMethod, backupMethod)) {
            return channels
        }
        return null
    }

    BTTower(btTowerJSX: JSX.Element, formTab: IngestTabs, getValues: UseFormGetValues<FieldValues>) {
        const {backupMethod, mainMethod} = this.getMethodValues(getValues);
        if (this.isRenderBTTower(formTab, mainMethod, backupMethod)) {
            return btTowerJSX
        }
        return null
    }

    FileUploader(fileUploaderJSX: JSX.Element, formTab: IngestTabs, getValues: UseFormGetValues<FieldValues>) {
        const {backupMethod, mainMethod} = this.getMethodValues(getValues);
        if (this.isRenderFileUploader(formTab, mainMethod, backupMethod)) {
            return fileUploaderJSX
        }
        return null
    }

    SatelliteDetailsButton(setShowFormType: React.Dispatch<React.SetStateAction<IShowFormType>>, currentTabState: IngestTabs, getValues: UseFormGetValues<FieldValues>) {
        const {mainMethod, backupMethod} = this.getMethodValues(getValues);
        if (this.isRenderSatellite(currentTabState, mainMethod, backupMethod)) {
            return (
                <div className={styles.satelliteBtnHandler}>
                    <button
                        onClick={() => setShowFormType({showIngest: false, showSatellite: true})}
                        className={styles.satelliteBtn}
                    >
                        Satellite Ingest Details
                    </button>
                </div>

            );
        }
    }

    FormControlButton(showFormType: IShowFormType, handleCloseForm: () => void) {
        return (!showFormType.showSatellite &&
            <div className={styles.buttons}>
                <button
                    className={styles.cancelButton}
                    onClick={handleCloseForm}
                    data-testid="ingest-form-close-button"
                >
                    Cancel
                </button>
                <button className={styles.submitButton} type="submit" data-testid={"ingestConfirm"}>
                    Confirm
                </button>
            </div>
        )
    }

    BackupCheckbox(tab: IngestTabs, isBackUp: boolean, backUpHandler: () => void, errors: FieldErrors<FieldValues>) {
        if (tab === IngestTabs.BackUP) {
            return (
                <div className={styles.backupCheckboxWrapper}>
                    <CheckboxWithLabel
                        onChange={backUpHandler}
                        checked={isBackUp}
                        labelText={'Activate Backup?'}
                        id={uuid()}
                    />
                </div>
            )
        }
    }

    private getMethodValues(getValues: UseFormGetValues<FieldValues>) {
        const mainMethod = Number(getValues(IngestFormController.PrimaryMethod)?.value);
        const backupMethod = getValues(IngestFormController.BackupMethod)?.value;
        return {mainMethod, backupMethod}
    }
}

const ingestRenderInstance = new IngestRender();
export default ingestRenderInstance;

