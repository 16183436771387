import React, {useRef} from 'react';
import styles from './index.module.scss';
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import commonStyles from "../../../children/AuditTrailsTable/index.module.scss";
import AppTableWhite from "../../../../AppTableWhite/AppTableWhite";
import auditIcon from "../../../../../img/auditWhite.svg";
import {
    getRightsAssignmentAudit,
    IAuditRightAssignment, setPageNumber
} from "../../../../../store/auditTrailsRightsAssignment/auditTrailsRightsAssignment";
import {AppInfiniteScroll} from "../../../../AppInfiniteScroll/AppInfiniteScroll";

export interface IRightsAssignmentsTableProps {
    handleRightAssignmentSelection: (rightAssignment: IAuditRightAssignment) => void;
    searchText: string;
}

const RightsAssignmentsTable: React.FC<IRightsAssignmentsTableProps> = ({
                                                                            handleRightAssignmentSelection,
                                                                            searchText
                                                                        }) => {
    const dispatch = useAppDispatch();
    const containerRef = useRef<HTMLDivElement>(null);
    const {
        rightsAssignmentAuditList,
        pageNumber,
        itemsPerPage,
        totalCount,
        scrollLoader,
        maxItemsInTheTable,
    } = useAppSelector(state => state.auditTrailsRightsAssignment);

    const fetchData = (page: number, pageSize: number, scrollPosition: string) => {
        dispatch(getRightsAssignmentAudit({
            searchText,
            pageNumber: page,
            itemsPerPage: pageSize,
            scrollPosition
        }));
    }

    const setPage = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber));
    }

    return (
        <div className={commonStyles.tableContainer}>
            <div className={commonStyles.searchTableWrapper}>
                <AppInfiniteScroll
                    containerRef={containerRef}
                    fetchData={fetchData}
                    pageSize={itemsPerPage}
                    totalItems={totalCount}
                    currentPage={pageNumber}
                    setPage={setPage}
                    isLoading={scrollLoader}
                    scrollingContainerHeight="55vh"
                    scrollBounce={200}
                    maxItemsInTheTable={maxItemsInTheTable}
                    tableDataLength={rightsAssignmentAuditList.length}>
                    <AppTableWhite>
                        <thead>
                        <tr>
                            <th>Rights Holder</th>
                            <th>Property</th>
                            <th>Technical Provider(s)</th>
                            <th className={styles.actionCell}>
                                Go to Audit Trails
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            rightsAssignmentAuditList.length ?
                                rightsAssignmentAuditList.map((rightsAssignment) => (
                                    <tr key={rightsAssignment.id}>
                                        <td>{rightsAssignment.rightHolder}</td>
                                        <td>{rightsAssignment.competition}</td>
                                        <td>{rightsAssignment.technicalProvider}</td>
                                        <td>
                                            <button onClick={() => handleRightAssignmentSelection(rightsAssignment)}
                                                    className={styles.actionBtn}>
                                                <img src={auditIcon} alt="audit"/>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                                :
                                <tr>
                                    <td>
                                        Use search to get a list of possible rights
                                    </td>
                                </tr>
                        }
                        </tbody>
                    </AppTableWhite>
                </AppInfiniteScroll>
            </div>
        </div>
    );
};

RightsAssignmentsTable.displayName = "RightsAssignmentsTable"
export default RightsAssignmentsTable;
