import React, {useEffect, useRef, useState} from 'react';
import styles from './index.module.scss';
import {IMenuStructure} from "../../../AppRoutes/routes/RouteInterface";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import ConfirmModal from "../../../../../components/ConfirmModal/ConfirmModal";
import IngestResourcesTable from "./children/IngestResourcesTable/IngestResourcesTable";
import {
    clearIngestResourceDetails,
    deleteIngestResource, getFormInitData, getIngestResourceDetails,
    getIngestResources,
    IIngestResource, setPageNumber
} from "../../../../../store/ingestResources/ingestResourcesSlice";
import IngestResourcesForm from "./children/IngestResourcesForm/IngestResourcesForm";
import Loader from "../../../../../components/Loader/Loader";
import PageHeader from "../../../../../components/PageHeader/PageHeader";
import SearchInput from "../../../../../components/SearchInput/SearchInput";
import {useDebounce} from "../../../../../helpers/useDebounce";
import {useDisableScroll} from "../../../../../hooks/useDisableScroll";
import trash from "../../../../../img/trashWhite.svg";
import NoDataFoundStub from "../../../../../components/NoDataFoundStub/NoDataFoundStub";

interface IIngestResourcesProps {
    menuStructure: IMenuStructure;
}

const IngestResources: React.FC<IIngestResourcesProps> = React.memo(({menuStructure}) => {
    const [isShowForm, setIsShowForm] = useState(false);
    const [selectedResource, setSelectedResource] = useState<IIngestResource | undefined>(undefined);
    const [isOpenConfirm, setIsOpenConfirm] = useState(false);
    const [searchValue, setSearchValue] = useState<string>("");
    const {
        loading,
        ingestResourceDetails,
        itemsPerPage,
        ingestResources
    } = useAppSelector(state => state.ingestResources);

    const dispatch = useAppDispatch();

    const containerRef = useRef<HTMLDivElement>(null);

    useDisableScroll(isShowForm)
    useEffect(() => {
        dispatch(getFormInitData(null));
    }, [dispatch]);

    const editResourceHandler = (resourceId: string): void => {
        dispatch(getIngestResourceDetails(resourceId.toString()));
        setIsShowForm(true);
    }

    const handleCloseForm = (): void => {
        setIsShowForm(false);
        setSelectedResource(undefined);
        dispatch(clearIngestResourceDetails());
    }

    const openConfirmModal = (removingResource: IIngestResource): void => {
        setSelectedResource(removingResource);
        setIsOpenConfirm(true);
    }

    const onDeleteIngestResource = (): void => {
        if (selectedResource) {
            dispatch(deleteIngestResource(selectedResource.id))
        }
    }

    const onSearch = () => {
        dispatch(setPageNumber(1))
        dispatch(getIngestResources({searchValue, pageNumber: 1, itemsPerPage}));
        if (containerRef && containerRef.current) {
            containerRef.current.scrollTop = 0
        }

    }

    useDebounce(onSearch, [searchValue], 500)

    return (
        <div>
            {isShowForm &&
                <IngestResourcesForm
                    closeForm={handleCloseForm}
                    title={ingestResourceDetails ? 'Edit Ingest Resource' : 'Add Ingest Resource'}
                />
            }

            {isOpenConfirm &&
                <ConfirmModal
                    action={onDeleteIngestResource}
                    closeForm={() => setIsOpenConfirm(false)}
                    title="Delete Ingest Resource"
                    message={"Are you sure you want to delete this Ingest Resource?"}>
                    <img
                        className={styles.deleteIconBig}
                        src={trash}
                        alt="trash"/>
                </ConfirmModal>
            }

            {loading && <Loader/>}

            {!loading && <>
                <PageHeader menuStructure={menuStructure} title={"Ingest Resources"}/>
                <div className={styles.page}>
                    <div className={styles.pageContainer}>
                        <h2 className={styles.title}>Ingest Resources</h2>
                        <div className={styles.upperSection}>
                            <div className={styles.search}>
                                <SearchInput
                                    searchValue={searchValue}
                                    onChange={(e) => setSearchValue(e)}
                                />
                            </div>
                            <button className={styles.addButton} onClick={() => setIsShowForm(true)}>
                                <p>Add Resource</p>
                                <p>+</p>
                            </button>
                        </div>
                        {
                            ingestResources?.length
                                ?
                                <IngestResourcesTable
                                    containerRef={containerRef}
                                    searchValue={searchValue}
                                    editResourceHandler={editResourceHandler}
                                    deleteResourceHandler={openConfirmModal}
                                />
                                :
                                <div className={styles.noDataFoundStubUnderlay}>
                                    <NoDataFoundStub textColor={"white"}/>
                                </div>
                        }
                    </div>
                </div>
            </>
            }
        </div>
    );
});

export default IngestResources;
