import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

type Props = {
    children: React.ReactNode;
};

export const DefaultPageSetter: React.FC<Props> = ({children}) => {
    const location = useLocation();
    const setDefaultPage = (path: string) => {
        localStorage.setItem('lastVisitedPage', path);
    };

    useEffect(() => {
        const currentPath = location.pathname;
        setDefaultPage(currentPath);
    }, [location]);

    return (
        <div>
            {children}
        </div>
    )
};

export default DefaultPageSetter;
