import React from "react";
import {IoIosClose} from "react-icons/io";
import {useAppDispatch, useAppSelector} from "../../../../../../../../hooks/hooks";
import styles from './index.module.scss'
import AppTableWhite from "../../../../../../../../components/AppTableWhite/AppTableWhite";
import CustomCheckbox from "../../../../../../../../components/CustomCheckbox/CustomCheckbox";
import {IFixtures, selectFixture} from "@store/generalIssues/generalIssuesSlice";

interface IOngoingGeneralIssues {
    closePopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const AffectedEventsTable: React.FC<IOngoingGeneralIssues> = ({closePopup}) => {
    const {selectedFixtures} = useAppSelector((state) => state.reportingGeneralIssues);
    const dispatch = useAppDispatch();

    const handleSelectChange = (fixture: IFixtures) => {
        dispatch(selectFixture(fixture));
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.tableInner}>
                <h3 className={styles.title}>Affected Events</h3>
                <IoIosClose onClick={() => closePopup(false)} className={styles.closePopup}/>
                <div className={styles.tableBackground}>
                    <AppTableWhite>
                        {
                            !!selectedFixtures?.length &&
                            <>
                                <th>Event Id</th>
                                <th>Start Time</th>
                                <th>Title</th>
                                <th></th>

                            </>
                        }
                        {
                            selectedFixtures ?
                                selectedFixtures?.map((fixture) => {
                                    return (
                                        <tr key={`${fixture.fixtureId}&affected`}>
                                            <td>{fixture.fixtureId}</td>
                                            <td>{fixture.startTime}</td>
                                            <td>{fixture.title}</td>

                                            <td className={styles.checkbox}>
                                                <CustomCheckbox
                                                    id={`${fixture.fixtureId}&affected`}
                                                    checked={selectedFixtures?.includes(fixture)}
                                                    onChangeHandler={() => handleSelectChange(fixture)}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <div>There is no available data</div>
                        }
                    </AppTableWhite>
                </div>
            </div>
        </div>
    );
};

export default AffectedEventsTable;
AffectedEventsTable.displayName = "AffectedEventsTable";
