import styles from './index.module.scss';
import React from 'react';

interface CustomCheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    onChangeHandler?: (val: string) => void;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({onChangeHandler, ...props}) => {

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        if (onChangeHandler && props.id) {
            onChangeHandler(props.id);
        }
        if (props.onChange) {
            props.onChange(e);
        }
    };

    return (
        <div className={styles.checkboxWrapper}>
            <input
                {...props}
                type="checkbox"
                id={props.id}
                className={styles.customCheckbox}
                onChange={onChange}  // Use the defined onChange handler
            />
            <label
                htmlFor={props.id}
                className={props.disabled ? styles.disabled : ''}>
            </label>
        </div>
    );
};

export default CustomCheckbox;
