import {Group} from "../../../enums/group";

export const getStartingPage = (
    role: string,
    lastVisitedPage: string | null,
    navigate: (path: string) => void,
    setMenuVisible: (isVisible: boolean) => void
) => {
    if (role === Group.IGame) {
        if (!lastVisitedPage || lastVisitedPage === "/cms" || lastVisitedPage === "/") {
            setMenuVisible(true)
            return navigate("/cms/igame-rightHolders")
        }
    }
    if (role === Group.RightHolder) {
        if (!lastVisitedPage || lastVisitedPage === "/cms" || lastVisitedPage === "/") {
            return navigate("/cms/book-fixtures")
        }
    }
    if (role === Group.Operator) {
        if (!lastVisitedPage || lastVisitedPage === "/cms" || lastVisitedPage === "/") {
            return navigate("/cms/operator-booked-content")
        }
    }
}
