import React, {useEffect, useRef} from "react";
import {loadGoogleCharts} from "./helpers/loadGoogleCharts";

interface GoogleGeoChartProps {
    data: (string | number)[][];
    options: any;
    width: string;
    height: string;
    onSelect: (region: (string | number)[] | null) => void;
}

const GoogleGeoChart: React.FC<GoogleGeoChartProps> = ({data, options, width, height, onSelect}) => {
    const chartRef = useRef<google.visualization.ChartWrapper | null>(null);

    useEffect(() => {
        const normalizedData = data.length > 1 ? data : [...data, ["No Data", 0, "No data available"]];

        loadGoogleCharts()
            .then((google) => {
                const dataTable = google.visualization.arrayToDataTable(normalizedData);

                if (chartRef.current) {
                    google.visualization.events.removeListener("select");

                    chartRef.current.setDataTable(dataTable);
                    chartRef.current.setOptions({...options, width, height});

                    google.visualization.events.addListener(chartRef.current, "select", () => {
                        const chart = chartRef.current?.getChart();
                        if (chart) {
                            const selection = chart.getSelection();
                            if (selection.length === 0) {
                                return;
                            }
                            if (selection) {
                                // @ts-ignore
                                const region = normalizedData[selection[0].row + 1];
                                onSelect(region);
                            }
                        }
                    });
                } else {
                    const geoChart = new google.visualization.ChartWrapper({
                        chartType: "GeoChart",
                        dataTable,
                        options: {...options, width, height},
                        containerId: "regions_div",
                    });

                    const handleSelect = () => {
                        const chart = geoChart.getChart();
                        if (chart) {
                            const selection = chart.getSelection();
                            if (selection.length === 0 || selection[0].row == null) {
                                return;
                            }
                            const rowIndex = selection[0].row;
                            const region = normalizedData[rowIndex + 1];
                            onSelect(region);
                        }
                    };

                    google.visualization.events.addListener(geoChart, "select", handleSelect);

                    chartRef.current = geoChart;
                }

                chartRef.current.draw();
            })
            .catch((error) => {
                console.error("Error loading Google Charts:", error);
            });
    }, [data, options, width, height, onSelect]);

    return <div id="regions_div" style={{width, height}}></div>;
};

export default GoogleGeoChart;
