import React, {useEffect, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import SelectInput from "../../../../../../components/SelectInput/SelectInput";
import {useAppSelector} from "../../../../../../hooks/hooks";
import {IPChannel} from "../../../../../../store/iGameReporting/iGameReportingSlice";
import {SingleValue} from "react-select";
import styles from '../BTTowerForm/index.module.scss'

interface IIPFormState {
    closeForm: () => void
    setIP: (channelId: number) => void
    currentChannel: number
}

interface IPInput {
    channel: SingleValue<{ value: number, label: string }>
}

const IPForm: React.FC<IIPFormState> = ({closeForm, setIP, currentChannel}) => {
    const {IPChannels} = useAppSelector(state => state.iGameReportingSlice)
    const {handleSubmit, formState: {errors}, control, getValues, setValue} = useForm<IPInput>();
    const [currentIP, setCurrentIP] = useState<SingleValue<{ value: number, label: string }> | undefined>(undefined);

    useEffect(() => {
        setDefaultValue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setDefaultValue = () => {
        const channel = IPChannels.find(channel => channel.id === currentChannel)
        if (channel) {
            setCurrentIP({value: channel.id, label: channel.name})
            setValue("channel", {value: channel.id, label: channel.name})
        }
    }

    const setIPMethod = () => {
        setCurrentIP(getValues("channel"))
        setIP(getValues("channel")!.value)
        closeForm()
    }

    const formatIPChannels = (IPChannels: IPChannel[]) => {
        return IPChannels.map(channel => {
            return {
                value: channel.id,
                label: channel.name
            }
        })
    }

    return (
        <div onClick={() => closeForm()} className={styles.overlay}>
            <form
                onClick={(e) => e.stopPropagation()}
                onSubmit={handleSubmit(setIPMethod)}
                className={styles.form}>
                <div className={styles.contentWrapper}>
                    <div className={styles.titleInner}>
                        <h1 className={styles.title}>Update Selected Ingest Method</h1>
                    </div>
                    <h6 className={styles.method}>IP</h6>
                    <label>Channel
                        <Controller
                            control={control}
                            name="channel"
                            rules={{required: true}}
                            render={({
                                         field: {onChange},
                                     }) => (
                                <SelectInput
                                    options={formatIPChannels(IPChannels)}
                                    onChange={x => {
                                        onChange(x)
                                        setCurrentIP(x)
                                    }}
                                    value={currentIP}
                                />
                            )}
                        />
                        {
                            errors.channel && errors.channel.type === "required"
                            && <span className={styles.error}>This field is required</span>
                        }
                    </label>
                </div>
                <div className={styles.buttons}>
                    <button onClick={() => closeForm()} className={styles.cancelButton}>Cancel</button>
                    <button
                        className={styles.submitButton}
                        type="submit">
                        Confirm
                    </button>
                </div>
            </form>
        </div>
    );
};

IPForm.displayName = "IPForm"
export default IPForm;
