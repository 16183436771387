import React, {useEffect, useState} from 'react';
import styles from "./index.module.scss";
import {IMenuStructure} from "../../AppRoutes/routes/RouteInterface";
import {IUserItem} from "../../../../store/iGameInit/iGameInitSlice";
import {
    deleteAccess, deleteUnconfirmedAccess,
    getAccesses,
    getDetailsForAccessUpdate,
    IDeleteAccessBody, ISavedAccess
} from "../../../../store/operatorAccess/operatorAccessSlice";
import {useAppDispatch} from "../../../../hooks/hooks";
import ConfirmModal from "../../../../components/ConfirmModal/ConfirmModal";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import PrimaryButton from "../../../../components/PrimaryButton/PrimaryButton";
import CreateAccessTable from "./CreateAccessTable/CreateAccessTable";
import ExistingAccessTable from "./ExistingAccessTable/ExistingAccessTable";
import CreateAccessForm from "./ContentAccessForm/CreateAccessForm";
import UpdateAccessForm from "./UpdateAccessForm/UpdateAccessForm";
import UpdateExistingAccessForm from "./UpdateExistingAcessForm/UpdateExistingAccessForm";
import trash from "../../../../img/trashWhite.svg";
import {useParams} from "react-router-dom";
import {useGetCartItems} from "../../../../hooks/useGetCartItems";


interface IOperatorAccessProps {
    menuStructure: IMenuStructure;
    users?: IUserItem[];
    rightHolderId: string | null;
}

export enum AccessTablesNames {
    EXISTING_ACCESS = 'Existing Access'
}

const ContentAccess: React.FC<IOperatorAccessProps> = React.memo(({menuStructure, users, rightHolderId}) => {
    const [isOpenForm, setIsOpenForm] = useState(false)
    const [isOpenUpdateForm, setIsOpenUpdateForm] = useState(false);
    const [isOpenExistingAccessUpdateForm, setIsOpenExistingAccessUpdateForm] = useState(false);
    const [isOpenConfirmForExistingAccess, setIsOpenConfirmForExistingAccess] = useState(false);
    const [isOpenConfirmForCreatedAccess, setIsOpenConfirmForCreatedAccess] = useState(false);
    const [deletingAccessInfo, setDeletingAccessInfo] = useState({} as IDeleteAccessBody);
    const [deletingUnconfirmedAccess, setDeletingUnconfirmedAccess] = useState<undefined | ISavedAccess>(undefined);
    const [updatingNewAccessId, setUpdatingNewAccessId] = useState<string | null>(null);
    const [updatingExistingAccessId, setUpdatingExistingAccessId] = useState<string | null>(null);
    const [rightName, setRightName] = useState("");
    const [selectedAccesses, setSelectedAccesses] = useState<string[]>([]);
    const dispatch = useAppDispatch();
    const {id} = useParams();
    useGetCartItems(id);

    useEffect(() => {
        dispatch(getAccesses(rightHolderId));
    }, [rightHolderId, dispatch]);

    const closeForm = () => setIsOpenForm(false);
    const closeUpdateForm = () => setIsOpenUpdateForm(false);
    const closeExistingAccessUpdateForm = () => setIsOpenExistingAccessUpdateForm(false);

    const setUpdatingExistingAccess = (accessId: string, rightName: string) => {
        setRightName(rightName);
        dispatch(getDetailsForAccessUpdate(accessId));
        setIsOpenExistingAccessUpdateForm(true);
        setUpdatingExistingAccessId(accessId);
    }

    const setUpdatingAccess = (accessId: string) => {
        setUpdatingNewAccessId(accessId);
        setIsOpenUpdateForm(true);
    }

    const openConfirmModalForCreatedAccess = (deletedAccess: ISavedAccess, selectedAccesses: string[]) => {
        setDeletingUnconfirmedAccess(deletedAccess);
        setIsOpenConfirmForCreatedAccess(true);
        setSelectedAccesses(selectedAccesses)
    }

    const openConfirmModalForExistingAccess = (operatorId: string, rightId: string) => {
        setDeletingAccessInfo({operatorRightId: operatorId, rightId});
        setIsOpenConfirmForExistingAccess(true)
    }

    const onDeleteExistingAccess = () => {
        dispatch(deleteAccess(deletingAccessInfo));
    }

    const onDeleteUnconfirmedAccess = () => {
        if (deletingUnconfirmedAccess) {
            const allAccessInList = selectedAccesses.filter(access => access !== deletingUnconfirmedAccess.id)
            setSelectedAccesses(allAccessInList)
            deleteCreatedAccess(deletingUnconfirmedAccess)
        }
    }

    const deleteCreatedAccess = (access: ISavedAccess) => {
        const body = {
            rightId: access.rightId,
            operatorRightId: access.id
        } as IDeleteAccessBody

        dispatch(deleteUnconfirmedAccess(body))
    }

    return (
        <>
            {
                updatingNewAccessId !== null && isOpenUpdateForm &&
                <UpdateAccessForm closeForm={closeUpdateForm}
                                  updatingAccessId={updatingNewAccessId}
                                  rightHolderId={rightHolderId}/>
            }

            {updatingExistingAccessId !== null && isOpenExistingAccessUpdateForm &&
                <UpdateExistingAccessForm closeForm={closeExistingAccessUpdateForm}
                                          updatingAccessId={updatingExistingAccessId}
                                          rightHolderId={rightHolderId}
                                          rightName={rightName}/>
            }

            {isOpenConfirmForCreatedAccess &&
                <ConfirmModal action={onDeleteUnconfirmedAccess}
                              title={'Delete Access'}
                              closeForm={() => setIsOpenConfirmForCreatedAccess(false)}
                              message={`Are you sure you want to delete Operator’s access to Right?`}>
                    <img className={styles.deleteIconBig} src={trash} alt="trash"/>
                </ConfirmModal>
            }

            {isOpenConfirmForExistingAccess &&
                <ConfirmModal action={onDeleteExistingAccess}
                              title={'Delete Access'}
                              closeForm={() => setIsOpenConfirmForExistingAccess(false)}
                              message={`Are you sure you want to delete Operator’s access to Right?`}>
                    <img className={styles.deleteIconBig} src={trash} alt="trash"/>
                </ConfirmModal>
            }

            {isOpenForm &&
                <CreateAccessForm closeForm={closeForm}
                                  rightHolderId={rightHolderId}/>
            }

            <PageHeader menuStructure={menuStructure} title={"Content Access"} users={users}/>

            <div className={styles.pageInner}>
                <div className={styles.pageContainer}>
                    <div className={styles.titleWrapper}>
                        <h2 className={styles.title}>Content Access</h2>
                        <div className={styles.addBtn}>
                            <PrimaryButton onClick={() => setIsOpenForm(true)}>
                                <span>Add Access +</span>
                            </PrimaryButton>
                        </div>
                    </div>

                    <div className={styles.tablesWrapper}>
                        <CreateAccessTable
                            openConfirmModal={openConfirmModalForCreatedAccess}
                            setUpdatingAccess={setUpdatingAccess}
                            rightHolderId={rightHolderId}
                        />

                        <ExistingAccessTable
                            setUpdatingExistingAccess={setUpdatingExistingAccess}
                            openConfirmModal={openConfirmModalForExistingAccess}
                            users={users}
                            rightHolderId={rightHolderId}
                        />
                    </div>
                </div>
            </div>
        </>
    );
});

export default ContentAccess;
ContentAccess.displayName = "ContentAccess"
