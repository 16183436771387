import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {clearBoard, getGeoInitOptions, ITerritory} from "../../../../../store/geoRestrictions/geoRestrictionsSlice";
import {IMenuStructure} from "../../../AppRoutes/routes/RouteInterface";
import {IUserItem} from "../../../../../store/iGameInit/iGameInitSlice";
import PageHeader from "../../../../../components/PageHeader/PageHeader";
import SelectInput from "../../../../../components/SelectInput/SelectInput";
import {formatSelectOptions} from "../../../../../helpers/formatSelectOptions";
import DragBoard from "../components/DragBoard/DragBoard";
import {FaSearch} from "react-icons/fa";
import Input from "../../../../../components/Input/Input";
import {useFiltersData} from "../hooks/useFiltersData";
import {Tooltip} from "react-tooltip";
import {GoQuestion} from "react-icons/go";
import {useParams} from "react-router-dom";
import {useGetCartItems} from "../../../../../hooks/useGetCartItems";

export const homeAwayNationsCode = 'HAN'

export interface IGeoRestrictionsProps {
    menuStructure: IMenuStructure;
    users?: IUserItem[];
    rightHolderId: string | null;
}

export interface IGeoFilterValues {
    allTerr: string,
    allowedTerr?: string,
    blockedTerr?: string
}

export interface IGeoFilteredCountries {
    allTerr: ITerritory[],
    allowedTerr?: ITerritory[],
    blockedTerr?: ITerritory[]
}

export interface IGeoBoard {
    id: number,
    boardName: string,
    items: ITerritory[]
}

const OperatorGeoRestrictions: React.FC<IGeoRestrictionsProps> = React.memo(({menuStructure, users, rightHolderId}) => {
    const dispatch = useAppDispatch()
    const {
        rights,
        operators,
        allowedTerritories,
        allTerritories
    } = useAppSelector(state => state.geoRestrictions)
    const {
        filtersValues,
        setFiltersValues,
        setFilterValue,
        filteredTerritories,
        setFilteredTerritories,
        onClearSearchInput,
        onConfirmGeoRestrictions,
        onCancelChanges,
        operator,
        onChangeOperator,
        right,
        onChangeRight,
        isShowConfirmBtn,
        setIsShowConfirmBtn,
        setUnconfirmedCountries,
        unconfirmedCountries
    } = useFiltersData(rightHolderId);

    const [boards, setBoards] = useState<IGeoBoard[]>(
        [
            {
                id: 1,
                boardName: "All Territories",
                items: allTerritories
            },
            {
                id: 2,
                boardName: "Allowed Countries",
                items: allowedTerritories
            }
        ]
    );

    const {id} = useParams();
    useGetCartItems(id);

    useEffect(() => {
        dispatch(clearBoard())
        dispatch(getGeoInitOptions(rightHolderId));
    }, [rightHolderId, dispatch]);

    return (
        <>
            <PageHeader menuStructure={menuStructure} title={"Operator Geo-Restrictions"} users={users}/>

            <div className={styles.pageInner}>
                <div className={styles.pageContainer}>
                    <div className={styles.titleWrapper}>
                        <h2 className={styles.title}>Operator Geo-Restrictions</h2>
                        <div className={styles.controls}>
                            {isShowConfirmBtn &&
                                <div className={styles.controlBtnInner}>
                                    <button onClick={onConfirmGeoRestrictions}
                                            className={styles.controlBtn}>
                                        Confirm
                                    </button>
                                    <button onClick={() => onCancelChanges(true)}
                                            className={styles.controlBtn}>
                                        Cancel
                                    </button>
                                </div>
                            }

                            <div className={styles.selectList}>
                                <label className={styles.label}>Operator
                                    <SelectInput
                                        defaultValue={null}
                                        isClearable
                                        value={operator}
                                        onChange={(e) => {
                                            if (e) {
                                                onChangeOperator(e, true)
                                            } else {
                                                onChangeOperator(e, true)
                                            }
                                        }}
                                        options={formatSelectOptions(operators)}/>
                                </label>
                            </div>

                            <div className={styles.selectList}>
                                <label className={styles.label}>Right
                                    <GoQuestion data-tooltip-id="select-tooltip"
                                                data-tooltip-content={"Select a Property and Operator to assign " +
                                                    "specific geo-restrictions rules for the selected pairing"}/>

                                    <Tooltip className={styles.tooltip} id="select-tooltip"
                                             classNameArrow={styles.tooltipArrow}
                                             place={'top'}/>
                                    <span>
                                        <SelectInput
                                            defaultValue={null}
                                            isClearable
                                            value={right}
                                            onChange={(e) => {
                                                onChangeRight(e, true)
                                            }}
                                            options={formatSelectOptions(rights)}/>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className={styles.titlesWrapper}>
                        <div className={styles.tableTitleInner}>
                            <h6 className={styles.searchTitle}>All Countries</h6>
                            <div className={styles.searchControl}>
                                <div className={styles.searchIcon}>
                                    <FaSearch/>
                                </div>
                                {filtersValues.allTerr && <button
                                    name={"allTerr"}
                                    onClick={(e) => onClearSearchInput(e)}
                                    className={styles.clearBtn}>
                                    x
                                </button>}
                                <Input
                                    value={filtersValues.allTerr}
                                    onChange={(e) => setFilterValue(e)}
                                    name={"allTerr"}
                                    placeholder={"SEARCH"}
                                />
                            </div>
                        </div>

                        <div className={styles.tableTitleInner}>
                            <h6 className={styles.searchTitle}>Allowed Countries</h6>
                            <div className={styles.searchControl}>
                                <div className={styles.searchIcon}>
                                    <FaSearch/>
                                </div>
                                {filtersValues.allowedTerr && <button
                                    name={"allowedTerr"}
                                    onClick={(e) => onClearSearchInput(e)}
                                    className={styles.clearBtn}>
                                    x
                                </button>}
                                <Input
                                    value={filtersValues.allowedTerr}
                                    onChange={(e) => setFilterValue(e)}
                                    name={"allowedTerr"}
                                    placeholder={"SEARCH"}
                                />
                            </div>
                        </div>
                    </div>

                    {allTerritories.length || allowedTerritories.length ?
                        <DragBoard
                            setFilteredTerritories={setFilteredTerritories}
                            setFiltersValue={setFiltersValues}
                            filteredTerritories={filteredTerritories}
                            setIsShowConfirmBtn={setIsShowConfirmBtn}
                            operator={operator}
                            competition={right}
                            boards={boards}
                            setBoards={setBoards}
                            setUnconfirmedCountries={setUnconfirmedCountries}
                            unconfirmedCountries={unconfirmedCountries}
                        />
                        :
                        null
                    }
                </div>
            </div>
        </>
    );
});

export default OperatorGeoRestrictions;
OperatorGeoRestrictions.displayName = "OperatorGeoRestrictions"
