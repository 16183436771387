import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';
import {
    createIngestResource, getFormInitData,
    IResourceReq, updateIngestResource
} from "@store/ingestResources/ingestResourcesSlice";
import {Controller, useForm} from "react-hook-form";
import SelectInput from "../../../../../../../components/SelectInput/SelectInput";
import Input from "../../../../../../../components/Input/Input";
import {useAppDispatch, useAppSelector} from "@hooks/hooks";
import Checkbox from "../../../../../../../components/Checkbox";
import {useFormatSelectOptions} from "../../hooks/useFormatSelectOptions";
import Loader from "../../../../../../../components/Loader/Loader";
import CreatableSelectInput from "../../../../../../../components/SelectInput/SelectInput";
import {IInputOption} from "@store/operatorAccess/operatorAccessSlice";
import ConfirmModal from "../../../../../../../components/ConfirmModal/ConfirmModal";
import {FaExclamation} from "react-icons/fa";
import {toastr} from "react-redux-toastr";
import {useSetRequiredField} from "../../hooks/useSetRequiredField";
import {SingleValue} from "react-select";

interface IIngestResourcesFormProps {
    title: string,
    closeForm: () => void;
}

export interface IFormInputs {
    rightsHolder: { label: string; value: string } | undefined;
    technicalProvider: { label: string; value: string } | undefined | string;
    sport: { label: string; value: string } | undefined;
    competition: { label: string; value: string } | undefined;
    deliveryMethod: { label: string; value: string } | undefined;
    url: string;
    port: number;
    pushLogin: string;
    application: string;
    stream: string;
    channel: string;
    passPhrase: string;
    boro: boolean;
}

const IngestResourcesForm: React.FC<IIngestResourcesFormProps> = React.memo((
    {
        title,
        closeForm
    }) => {
    const [isBoro, setIsBoro] = useState<boolean>(false);
    const [isConfirmExitModalOpen, setIsConfirmExitModalOpen] = useState(false);
    const [selectionMode, setSelectionMode] = useState(true);
    const {
        loadingFormData,
        ingestResourceDetails,
        rightHolders,
        technicalProviders,
        competitions,
        deliveryMethods,
        sports
    } = useAppSelector(state => state.ingestResources);
    const {
        formatSelectCompetitions,
        formatSelectDeliveryMethods,
        formatSelectSports,
        formatSelectRightsHolders,
        formatSelectTechnicalProviders,
    } = useFormatSelectOptions();
    const {handleSubmit, control, formState: {errors}, reset, watch} = useForm<IFormInputs>();
    const {isPortRequired, isStreamRequired} = useSetRequiredField(watch);
    const dispatch = useAppDispatch();
    const [sport, setSport] = useState<SingleValue<{ label: string; value: string; }> | undefined>(undefined);
    const [property, setProperty] = useState<SingleValue<{ label: string; value: string; }> | undefined>(undefined);

    const rightsHolder = watch("rightsHolder", {value: "", label: ""});


    useEffect(() => {
        if (rightsHolder?.value) {
            dispatch(getFormInitData({
                rightHolderId: rightsHolder.value,
                sportIds: sport ? sport.value : null
                // sportIds: sport!.value ?? null
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rightsHolder!.value, sport]);


    useEffect(() => {
        setFormInputsValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ingestResourceDetails]);

    const setFormInputsValues = () => {
        if (ingestResourceDetails) {
            reset({
                rightsHolder: setSelectValue(ingestResourceDetails.rightHolderId, rightHolders),
                technicalProvider: setSelectValue(ingestResourceDetails.technicalProviderId, technicalProviders),
                competition: setSelectValue(ingestResourceDetails.competitionId, competitions),
                deliveryMethod: setSelectValue(ingestResourceDetails.deliveryMethod, deliveryMethods),
                url: ingestResourceDetails.url,
                port: ingestResourceDetails.port,
                pushLogin: ingestResourceDetails.pushLogin,
                application: ingestResourceDetails.application,
                stream: ingestResourceDetails.stream,
                passPhrase: ingestResourceDetails.passPhrase,
                channel: ingestResourceDetails.igmChannel,
                boro: ingestResourceDetails.boro,
                sport: setSelectValue(ingestResourceDetails.sportId, sports)
            });
            setIsBoro(ingestResourceDetails.boro);
            setSport(setSelectValue(ingestResourceDetails.sportId, sports));
            setProperty(setSelectValue(ingestResourceDetails.competitionId, competitions));
        }
    }

    const setSelectValue = (optionId: string | number, optionsList: IInputOption[]) => {
        if (optionsList) {
            const currentOption = optionsList.find(opt => opt.id === optionId.toString());
            if (currentOption) {
                return {
                    value: currentOption.id.toString(),
                    label: currentOption.name
                }
            }
        }
    }

    const validatePort = (value: number | undefined): boolean => {
        if (value) {
            return Number(value) > 0
        } else {
            return true
        }
    }

    const submitFormHandler = (formData: IFormInputs): void => {
        if (formData.rightsHolder && formData.technicalProvider
            && formData.competition && formData.deliveryMethod && property) {
            let resourceBody: IResourceReq = {
                payload: {
                    deliveryMethod: +formData.deliveryMethod.value,
                    url: formData.url,
                    ports: +formData.port,
                    pushLogin: formData.pushLogin,
                    application: formData.application,
                    stream: formData.stream,
                    channel: formData.channel,
                    passPhrase: formData.passPhrase,
                    competitionId: property.value,
                    boro: isBoro
                },
                closeForm
            }

            if (typeof formData.technicalProvider === "string") {
                resourceBody.payload.technicalProvider = formData.technicalProvider;
            } else {
                resourceBody.payload.technicalProvider = formData.technicalProvider.value;
            }

            if (ingestResourceDetails) {
                resourceBody.payload.id = ingestResourceDetails.id;
                dispatch(updateIngestResource(resourceBody));
            } else {
                resourceBody.payload.rightHolderId = formData.rightsHolder.value;
                dispatch(createIngestResource(resourceBody));
            }
        }
    }

    const closeAllForms = () => {
        setIsConfirmExitModalOpen(false);
        closeForm();
    }

    const showWarning = () => {
        toastr.warning("Warning!", "All bookings using this ingest resource currently will be cancelled if the property value changes");
    }

    const showErrorMessageAtTheBottom = (): string | null => {
        const fieldsToShow = new Map<string, string>([
            ["rightsHolder", "Rights Holder"],
            ["technicalProvider", "Technical Provider"],
            ["sport", "Sport"],
            ["property", "Property"],
            ["deliveryMethod", "Delivery Method"],
        ]);

        const actualErrorsNames = Object.keys(errors).filter(name => fieldsToShow.has(name));
        const labels = actualErrorsNames.map(name => fieldsToShow.get(name));

        if (labels.length > 1) {
            return `Fields "${labels.join(", ")}" are required!`;
        }
        if (labels.length === 1) {
            return `Field "${labels[0]}" is required!`;
        }

        return null;
    };

    return (
        <div className={styles.overlay}>
            {loadingFormData && <Loader/>}

            {!loadingFormData && <>
                <form onClick={(e) => e.stopPropagation()} onSubmit={handleSubmit(submitFormHandler)}
                      className={styles.form}>
                    <div className={styles.content}>
                        <h6 className={styles.title}>{title}</h6>
                        <div className={styles.formInputWrapper}>
                            <label className={styles.label}>Rights Holder <span>*</span>
                                <Controller
                                    name="rightsHolder"
                                    control={control}
                                    rules={{required: true}}
                                    render={({field: {onChange, value}}) => <SelectInput
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        value={value}
                                        isDisabled={!!ingestResourceDetails}
                                        options={formatSelectRightsHolders}
                                        placeholder={"Choose an option"}
                                    />}
                                />
                            </label>
                            {errors.rightsHolder &&
                                <div className={styles.formErrorBlock}>Rights Holder is required field</div>}
                        </div>

                        <div className={styles.formInputWrapper}>
                            <label className={styles.label}>Technical Provider <span>*</span>
                                {
                                    selectionMode
                                        ?
                                        <Controller
                                            name="technicalProvider"
                                            control={control}
                                            rules={{required: true}}
                                            render={({field: {onChange, value}}) =>
                                                <CreatableSelectInput
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                    options={formatSelectTechnicalProviders}
                                                    placeholder={"Choose an option"}
                                                />}
                                        />
                                        :
                                        <Controller
                                            name="technicalProvider"
                                            control={control}
                                            rules={{required: true}}
                                            render={({field: {onChange, value}}) => (
                                                <Input
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                    value={(value && typeof value !== "string") ? value.label : value}
                                                    placeholder={"Enter Technical Provider"}
                                                />
                                            )}
                                        />
                                }
                            </label>
                            <div className={styles.inputModeButtons}>
                                <button
                                    className={selectionMode ? `${styles.inputModeButton} ${styles.inputModeButtonActive}` : styles.inputModeButton}
                                    onClick={() => setSelectionMode(true)}
                                    type="button"
                                >Select
                                </button>
                                <button
                                    className={selectionMode ? styles.inputModeButton : `${styles.inputModeButton} ${styles.inputModeButtonActive}`}
                                    onClick={() => setSelectionMode(false)}
                                    type="button"
                                >Type in
                                </button>
                            </div>
                            {errors.technicalProvider &&
                                <div className={styles.formErrorBlock}>Technical Provider is required field</div>}
                        </div>


                        <div className={styles.formInputWrapper}>
                            <label className={styles.label}>Sport <span>*</span>
                                <Controller
                                    name="sport"
                                    control={control}
                                    rules={{required: true}}
                                    render={({field: {onChange}}) => <SelectInput
                                        value={sport}
                                        onChange={(e) => {
                                            onChange(e);
                                            setSport(e);
                                            setProperty(undefined);
                                        }}
                                        options={formatSelectSports}
                                        placeholder={"Choose an option"}
                                        disabled={!rightsHolder!.value}
                                    />}
                                />
                            </label>
                            {errors.sport &&
                                <div className={styles.formErrorBlock}>Sport is required field</div>}
                        </div>


                        <div className={styles.formInputWrapper}>
                            <label className={styles.label}>Property <span>*</span>
                                <Controller
                                    name="competition"
                                    control={control}
                                    rules={{required: true}}
                                    render={({field: {onChange}}) => <SelectInput
                                        value={property}
                                        onChange={(e) => {
                                            showWarning();
                                            onChange(e);
                                            setProperty(e)
                                        }}
                                        options={formatSelectCompetitions}
                                        placeholder={"Choose an option"}
                                        disabled={!rightsHolder!.value}
                                    />}
                                />
                            </label>
                            {errors.competition &&
                                <div className={styles.formErrorBlock}>Property is required field</div>}
                        </div>


                        <div className={styles.formInputWrapper}>
                            <label className={styles.label}>Delivery Method <span>*</span>
                                <Controller
                                    name="deliveryMethod"
                                    control={control}
                                    rules={{required: true}}
                                    render={({field: {onChange, value: right}}) => <SelectInput
                                        value={right}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        options={formatSelectDeliveryMethods}
                                        placeholder={"Choose an option"}
                                    />}
                                />
                            </label>
                            {errors.deliveryMethod &&
                                <div className={styles.formErrorBlock}>Delivery Method is required field</div>}
                        </div>

                        <div className={styles.formInputWrapper}>
                            <label className={styles.label}>URL
                                <Controller
                                    control={control}
                                    name="url"
                                    render={({field: {onChange, value}}) => (
                                        <Input
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            value={value}
                                            placeholder={"Enter URL"}
                                        />
                                    )}
                                />
                            </label>
                        </div>

                        <div className={styles.formInputWrapper}>
                            <label className={styles.label}>Port {
                                isPortRequired && <span>*</span>
                            }
                                <Controller
                                    control={control}
                                    name="port"
                                    rules={{
                                        required: isPortRequired,
                                        validate: value => validatePort(value)
                                    }}
                                    render={({field: {onChange, value}}) => (
                                        <Input
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            value={value}
                                            type="number"
                                            placeholder={"Enter Port"}
                                        />
                                    )}
                                />
                                {errors?.port?.type === "required" && isPortRequired &&
                                    <div className={styles.formErrorBlock}>Port is required field</div>}
                                {errors?.port?.type === "validate" &&
                                    <div className={styles.formErrorBlock}>Port should be positive number</div>}
                            </label>
                        </div>

                        <div className={styles.formInputWrapper}>
                            <label className={styles.label}>Push Login
                                <Controller
                                    control={control}
                                    name="pushLogin"
                                    render={({field: {onChange, value}}) => (
                                        <Input
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            value={value}
                                            placeholder={"Enter Push Login"}
                                        />
                                    )}
                                />
                            </label>
                        </div>

                        <div className={styles.formInputWrapper}>
                            <label className={styles.label}>Application
                                <Controller
                                    control={control}
                                    name="application"
                                    render={({field: {onChange, value}}) => (
                                        <Input
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            value={value}
                                            placeholder={"Enter Application"}
                                        />
                                    )}
                                />
                            </label>
                        </div>

                        <div className={styles.formInputWrapper}>
                            <label className={styles.label}>Stream {
                                isStreamRequired && <span>*</span>
                            }
                                <Controller
                                    control={control}
                                    rules={{
                                        required: isStreamRequired,
                                    }}
                                    name="stream"
                                    render={({field: {onChange, value}}) => (
                                        <Input
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            value={value}
                                            placeholder={"Enter Stream"}
                                        />
                                    )}
                                />
                                {errors?.stream?.type === "required" && isStreamRequired &&
                                    <div className={styles.formErrorBlock}>Stream is required field</div>}
                            </label>
                        </div>

                        <div className={styles.formInputWrapper}>
                            <label className={styles.label}>Passphrase
                                <Controller
                                    control={control}
                                    name="passPhrase"
                                    render={({field: {onChange, value}}) => (
                                        <Input
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            value={value}
                                            placeholder={"Enter Passphrase"}
                                        />
                                    )}
                                />
                            </label>
                        </div>

                        <div className={styles.formInputWrapper}>
                            <label className={styles.label}>Channel <span>*</span>
                                <Controller
                                    control={control}
                                    rules={{required: true}}
                                    name="channel"
                                    render={({field: {onChange, value}}) => (
                                        <Input
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            value={value}
                                            placeholder={"Enter Channel"}
                                        />
                                    )}
                                />
                            </label>
                            {errors?.channel?.type === "required" &&
                                <div className={styles.formErrorBlock}>Channel is required field</div>}
                        </div>

                        <div className={styles.boro}>
                            <Controller
                                name="boro"
                                control={control}
                                render={() =>
                                    <Checkbox
                                        onChangeHandler={() => setIsBoro(!isBoro)}
                                        isChecked={isBoro}
                                        filled={true}
                                    />
                                }
                            />
                            <span>BORO</span>
                        </div>
                        <div className={styles.formErrorBottomBlock}>{showErrorMessageAtTheBottom()}</div>
                    </div>
                    <div className={styles.buttons}>
                        <button
                            data-testid="cancelBtn"
                            onClick={(e) => {
                                e.preventDefault()
                                setIsConfirmExitModalOpen(true)
                            }}
                            className={styles.cancelButton}>
                            Cancel
                        </button>
                        <button
                            className={styles.submitButton}
                            type="submit">Confirm
                        </button>
                    </div>
                </form>
            </>
            }
            {
                isConfirmExitModalOpen &&
                <ConfirmModal
                    message={"Are you sure you want to exit?"}
                    closeForm={() => setIsConfirmExitModalOpen(false)}
                    action={closeAllForms}
                    wide={true}>
                    <FaExclamation
                        color="white"
                        size="72px"/>
                </ConfirmModal>
            }
        </div>
    );
});

export default IngestResourcesForm;
