import React from 'react';
import Input from "../Input/Input";
import styles from "./index.module.scss";
import searchWhite from "../../img/searchWhite.svg";

interface ISearchInputProps {
    searchValue: string | undefined,
    onChange: (arg: string) => void
}

const SearchInput: React.FC<ISearchInputProps> = React.memo(({searchValue, onChange}) => {
    return (
        <div className={styles.searchInput}>
            <Input
                type="text"
                placeholder="SEARCH"
                value={searchValue}
                onChange={(e) => onChange(e.target.value)}
                style={{border: "1px solid #E0E0E0"}}
            />
            <img src={searchWhite} alt="search"/>
        </div>
    )
});

export default SearchInput;
