import {Dispatch, SetStateAction, useState} from "react";
import {ActionCreatorWithPayload, ThunkAction} from "@reduxjs/toolkit";
import {useAppDispatch} from "../../../hooks/hooks";
import {useDebounce} from "../../../helpers/useDebounce";
import {useNavigate} from "react-router-dom";

export const useAuditingEntitiesSearch = (
    request: (payload: any) => ThunkAction<any, any, any, any>,
    pageNumber: number,
    itemsPerPage: number,
    skipSearch: ActionCreatorWithPayload<any, any>,
    initial: boolean,
    setInitial: (value: boolean) => void,
    id?: string | null,
    setId?: Dispatch<SetStateAction<string | null>>,
) => {

    const navigate = useNavigate();
    const [searchText, setSearchText] = useState<string>("");
    const dispatch = useAppDispatch();

    const getSortedItems = () => {
        if (searchText === "" && initial) {
            return;
        } else if (searchText === "" && !initial) {
            dispatch(skipSearch(null));
        } else {
            setInitial(false);
            if (id && setId) setId(null);
            navigate({
                pathname: window.location.pathname,
                search: "",
            }, {replace: true})
            dispatch(request({
                searchText,
                pageNumber: 1,
                itemsPerPage
            }));
        }
    }

    useDebounce(getSortedItems, [searchText], 500);

    return {setSearchText, searchText}
}
