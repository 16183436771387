import React, {useEffect, useState} from "react";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {useAppDispatch} from "../../../../hooks/hooks";
import {AppThunk} from "../../../../store";
import {toastr} from "react-redux-toastr";
import {IBookmarkPayload, IBookmarkUpdatePayload} from "../../../../store/operatorAnalytics/operatorAnalyticsSlice";
import {IGameAnalyticFilter} from "../../../../store/igameAnalytics/igameAnalyticsSlice";
import {ISelectOption} from "../../../../store/operatorBookedContent/operatorBookedContentSlice";
import styles from "./index.module.scss";

export interface IBookmarkModalProps {
    setBookMarkModalState: (val: IBookmarkModalState) => void;
    bookMarkModalState: IBookmarkModalState;
    filters: IGameAnalyticFilter;
    createBookmark: (bookmark: IBookmarkPayload) => AppThunk;
    updateBookmark: (bookmark: IBookmarkUpdatePayload) => AppThunk;
    bookmarkName: string;
    createBookmarkName: () => string;
    bookmarks: IBookmarkPayload[];
    testCreateFunction?: (payload: IBookmarkPayload) => void;
    testUpdateFunction?: (payload: {
        id: string;
        name: string;
    }) => void;
}

export interface IBookmarkModalState {
    show: boolean;
    id: string;
    valueToEdit: string;
}

export interface IBookmarkModalInput {
    name: string;
}

const BookmarkModal: React.FunctionComponent<IBookmarkModalProps> = React.memo((
    {
        setBookMarkModalState,
        filters,
        bookMarkModalState,
        createBookmark,
        updateBookmark,
        createBookmarkName,
        bookmarks,
        testUpdateFunction
    }) => {
    const {handleSubmit, formState: {errors}, control, reset} = useForm<IBookmarkModalInput>();
    const [bookmarkName, setBookmarkName] = useState("");
    const dispatch = useAppDispatch();

    useEffect(() => {
        setBookmarkName(createBookmarkName());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (bookMarkModalState.valueToEdit.length > 0) {
            reset({
                name: bookMarkModalState.valueToEdit,
            });
        } else {
            reset({
                name: bookmarkName,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookmarkName, bookMarkModalState]);

    const onSubmit: SubmitHandler<IBookmarkModalInput> = (data) => {
        if (bookMarkModalState.valueToEdit.length === 0) {
            createNewBookmark(data.name);
        } else {
            dispatch(updateBookmark({
                id: bookMarkModalState.id,
                name: data.name
            }))
            testUpdateFunction && testUpdateFunction({
                id: bookMarkModalState.id,
                name: data.name
            })
        }
        handleMainModalClose();
    }
    const checkIsUniqBookmarkName = (bookmarkName: string) => {

        const isExistBookmark = bookmarks.find(bookmark => bookmark.name === bookmarkName)
        return !isExistBookmark
    }
    const createPayloadByFilterOptions = (options: ISelectOption[]) => {
        return options.map(option => {
            return {
                id: option.value,
                name: option.label
            }
        })
    }

    const createNewBookmark = (name: string) => {
        const bookmark = {
            id: "",
            name,
            customers: {
                items: createPayloadByFilterOptions(filters.customers) || []
            },
            dataProviders: {
                items: createPayloadByFilterOptions(filters.dataProviders) || []
            },
            competitions: filters.competitions.map(x => x.value),
            sports: {
                items: createPayloadByFilterOptions(filters.sports)
            },
            countries: filters.countries.map(x => x.value),
            dataTypes: {
                items: [{id: filters.dataType.value.toString(), name: filters.dataType.label}]
            },
            streamFormats: filters.streamFormats.map(x => x.value)
        };

        if (createBookmarkName().trim() === "") {
            toastr.error("Error!", "Filters were not selected. The bookmark can't be created!")
        } else {
            // @ts-ignore
            dispatch(createBookmark(bookmark));
        }
    }

    const handleMainModalClose = () => {
        setBookMarkModalState({
            show: false,
            id: "",
            valueToEdit: ""
        })
    }

    return (
        <div
            className={styles.overlay}
            data-testid="bookmark-modal">
            <form
                onClick={(e) => e.stopPropagation()}
                className={styles.bookmarkForm} action=""
                onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.contentWrapper}>
                    <h1>Bookmark</h1>
                    <label>Name
                        <Controller
                            control={control}
                            name="name"
                            rules={{
                                required: true,
                                minLength: 2,
                                pattern: /\S/,
                                validate: {
                                    isUniqMarkName: (e) => checkIsUniqBookmarkName(e)
                                }
                            }}
                            render={({
                                         field: {onChange, value},
                                         formState: {errors},
                                     }) => (
                                <input
                                    onChange={val => {
                                        onChange(val);
                                    }}
                                    type="text"
                                    aria-invalid={errors.name ? "true" : "false"}
                                    value={value}
                                />
                            )}
                        />
                        {
                            errors.name && errors.name.type === "required"
                            && <span className={styles.error}
                                     data-testid="name-required-error">This field is required</span>
                        }
                        {
                            errors.name && errors.name.type === "minLength"
                            &&
                            <span className={styles.error} data-testid="minimum-length-error">The field must contain at least two characters</span>
                        }
                        {
                            errors.name && errors.name.type === "pattern"
                            &&
                            <span className={styles.error} data-testid="only-spaces-error">The field can't contain only spaces</span>
                        }
                        {
                            errors.name && errors.name.type === "isUniqMarkName"
                            && <span className={styles.error} data-testid="already-exists-error">The bookmark is already exist with the same name</span>
                        }
                    </label>
                </div>
                <div className={styles.buttons}>
                    <button className={styles.cancelButton} onClick={handleMainModalClose}>Cancel</button>
                    <button
                        className={styles.submitButton}
                        type="submit">
                        Confirm
                    </button>
                </div>
            </form>
        </div>
    )
});
BookmarkModal.displayName = "BookmarkModal"
export default BookmarkModal;
