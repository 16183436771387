import React, {useState} from 'react';
import {IMenuStructure} from "../../../AppRoutes/routes/RouteInterface";
import styles from "./index.module.scss";
import PageHeader from "../../../../../components/PageHeader/PageHeader";
import {HiOutlineDownload} from "react-icons/hi";
import HeaderFilters from "../../../OperatorView/AvailableContent/HeaderFilters/HeaderFilters";
import Input from "../../../../../components/Input/Input";
import searchWhite from "../../../../../img/searchWhite.svg";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {IFixturesReportPayload, IFixturesState} from "../../../../../store/reportingFixtures/types";
import {
    getReports,
    resetFilter,
    setRHFilter,
    setHasMoreForScroll
} from "../../../../../store/rhReporting/rhReportingSlice";
import RHReportingTable from "./components/RHReportingTable/RHReportingTable";
import {useDebounce} from "../../../../../helpers/useDebounce";
import {formatDate, formatDatePicker} from "../../../../../helpers/dateFormatters";
import {useDeepCompareEffect} from "../../../../../hooks/useDeepCompareEffect";
import {handleDownloadCSV} from "../../../IGameView/Settings/Reporting/ReportingTable/helpers/handleDownloadSCV";

export interface RHReportingProps {
    menuStructure: IMenuStructure
}

const RHReporting: React.FC<RHReportingProps> = ({menuStructure}) => {
    const dispatch = useAppDispatch()
    const {
        RHfilters,
        controls,
        itemsPerPage,
        sorting,
        isLoading,
        pageNumber
    } = useAppSelector(state => state.rhReporting)
    const [search, setSearch] = useState<string | undefined>(undefined);

    const onSetRHFilter = (value: any, name: keyof IFixturesState["filters"]) => {
        dispatch(setRHFilter({value, name}))
    }

    const onResetFilter = () => dispatch(resetFilter())

    const onSearch = () => {
        if (!RHfilters.startDate || !RHfilters.endDate || isLoading) {
            return
        }
        dispatch(setHasMoreForScroll(true))
        const payload: IFixturesReportPayload = {
            payload: {
                searchValue: search ? search : '',
                pageNumber: 1,
                itemsPerPage,
                sorting: {
                    property: sorting.criteria,
                    isAscending: sorting.isAscending
                },
                ingestType: +RHfilters.ingestType!.value === 4 ? null : +RHfilters.ingestType!!.value,
                dateFrom: formatDate(RHfilters.startDate),
                dateTo: formatDatePicker(RHfilters.endDate)
            },
            isScroll: false
        };
        dispatch(getReports(payload))
    }

    useDeepCompareEffect(() => {
        dispatch(setHasMoreForScroll(true))
        const payload: IFixturesReportPayload = {
            payload: {
                searchValue: search ? search : '',
                pageNumber: 1,
                itemsPerPage,
                sorting: {
                    property: sorting.criteria,
                    isAscending: sorting.isAscending
                },
                ingestType: +RHfilters.ingestType!.value === 4 ? null : +RHfilters.ingestType!!.value,
                dateFrom: formatDate(RHfilters.startDate),
                dateTo: formatDatePicker(RHfilters.endDate)
            },
            isScroll: false
        };
        dispatch(getReports(payload))

    }, [RHfilters])

    useDebounce(onSearch, search, 500)

    return (
        <div className={styles.pageWrapper}>
            <PageHeader
                menuStructure={menuStructure}
                title={"Reporting"}
            >
                <div className={styles.topButtons}>
                    <button
                        onClick={() => handleDownloadCSV(
                            'booking-sheet',
                            search,
                            pageNumber,
                            itemsPerPage,
                            RHfilters.startDate,
                            RHfilters.endDate,
                            RHfilters.ingestType!.label
                        )}>
                        Booking sheet:
                        <HiOutlineDownload size={'18px'}/>
                    </button>
                    <button
                        onClick={() => handleDownloadCSV(
                            'ingest-details',
                            search,
                            pageNumber,
                            itemsPerPage,
                            RHfilters.startDate,
                            RHfilters.endDate,
                            RHfilters.ingestType!.label
                        )}>
                        Ingest Details
                        <HiOutlineDownload size={'18px'}/>
                    </button>
                </div>
            </PageHeader>

            <div className={styles.container}>
                <div className={styles.header}>
                    <h1 className={styles.title}>Reporting</h1>
                    <HeaderFilters
                        resetFilters={onResetFilter}
                        filterControls={controls}
                        setFilters={onSetRHFilter}
                        filterValues={RHfilters}/>
                </div>

                <div className={styles.contentWrapper}>
                    <div className={styles.tableControls}>
                        <div className={styles.searchInput}>
                            <Input
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                type="text"
                                style={{border: "1px solid #E0E0E0"}}
                                placeholder="Search"
                                autoFocus={true}
                            />
                            <img src={searchWhite} alt="search"/>
                        </div>
                    </div>
                    <RHReportingTable searchValue={search}/>
                </div>
            </div>
        </div>
    );
};

export default RHReporting;
