import React from 'react';
import styles from "./index.module.scss";
import {BillingTabs} from "../../../pages/Cms/IGameView/Billing/RightHolderBilling/RightHolderBilling";
import {
    BandwidthHeaders,
    IngestHeaders,
    OperatorsHeaders,
    StreamsHeaders
} from "../../../pages/Cms/IGameView/Billing/helpers/RightHoldersTableHeaders";
import moment from "moment";
import uuid from "react-uuid";
import {
    IBandwidth,
    IIngest,
    IOperator,
    IStream
} from "../../../store/rightsHolderBilling/iGameBillingSlice";


export interface INewTableProps {
    currentTab: string;
    streams?: IStream[],
    ingest?: IIngest[],
    operators?: IOperator[],
    bandwidth?: IBandwidth[],
}


const BillingTable: React.FC<INewTableProps> = React.memo((
    {
        currentTab,
        streams,
        ingest,
        bandwidth,
        operators
    }) => {

    const getHeaders = () => {
        switch (currentTab) {
            case BillingTabs.Streams:
                return StreamsHeaders;
            case BillingTabs.Ingest:
                return IngestHeaders;
            case BillingTabs.Operators:
                return OperatorsHeaders;
            case BillingTabs.Bandwidth:
                return BandwidthHeaders;
            default:
                return [];
        }
    };

    const headers = getHeaders();


    return (
        <div className={styles.billingTable}>
            <table>
                <thead>
                <tr>
                    {headers.map(header => (
                        <th key={header.id}>{header.title}</th>
                    ))}
                </tr>
                </thead>

                <tbody>
                {currentTab === BillingTabs.Streams &&
                    streams?.map(stream => (
                        <tr key={uuid()}>
                            <td>{stream.operator}</td>
                            <td>{stream.playersView}</td>
                            <td>{Math.round(stream.total * 100) / 100}</td>
                        </tr>
                    ))
                }

                {currentTab === BillingTabs.Ingest &&
                    ingest?.map(ingest => (
                        <tr key={uuid()}>
                            <td>{ingest.right}</td>
                            <td>{ingest.hours}</td>
                            <td>{Math.round(ingest.total * 100) / 100}</td>
                        </tr>
                    ))
                }

                {currentTab === BillingTabs.Operators &&
                    operators?.map(operator => (
                        <tr key={uuid()}>
                            <td>{operator.name}</td>
                            <td>{moment(operator.dateAdded).format('DD/MM/YYYY')}</td>
                            <td>{Math.round(operator.total * 100) / 100}</td>
                        </tr>
                    ))
                }

                {currentTab === BillingTabs.Bandwidth &&
                    bandwidth?.map(bandwidth => (
                        <tr key={uuid()}>
                            <td>{bandwidth.operator}</td>
                            <td>{bandwidth.overage}</td>
                            <td>{Math.round(bandwidth.total * 100) / 100}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    );
});

BillingTable.displayName = "BillingTable"
export default BillingTable;
