import {useState} from "react";
import {useAppDispatch} from "../../../../../hooks/hooks";
import {useDebounce} from "../../../../../helpers/useDebounce";
import {AppThunk} from "../../../../../store";
import {
    getFiltersOptions,
    IFiltersState,
    IGetPortfolioPayload
} from "../../../../../store/operatorRightsOverview/operatorRightsOverviewSlice";
import {createRequestModel} from "../RightsHoldersTiles/helpers/createRequestModel";
import {ISorting} from "../../../../../components/OperatorDefaultTable/OperatorDefaultTable";

export const useSortingAndFilters = (operatorId: string | null, request: (payload: IGetPortfolioPayload) => AppThunk, filters: IFiltersState) => {
    const [sorting, setSorting] = useState<ISorting>({
        property: "",
        isAscending: false
    });

    const dispatch = useAppDispatch();

    const payload = createRequestModel(filters, operatorId, sorting);

    const getSortedItems = () => {
        dispatch(request(payload));
        dispatch(getFiltersOptions(createRequestModel(filters, operatorId)));
    };

    useDebounce(getSortedItems, [sorting, filters], 500);

    return {setSorting, sorting};
};
