import {IFavFilters} from "../../../../../store/contentAnalysis/types";

interface IOptions {
    value: string;
    label: string;
}

export const generateQueryString = (params: IFavFilters, searchText: string) => {
    const queryParams = new URLSearchParams();

    Object.keys(params).forEach((key) => {
        //@ts-ignore
        params[key].forEach((item: IOptions) => {
            queryParams.append(`${key}Ids`, item.value);
        });
    });

    if (searchText) {
        queryParams.append('searchText', searchText);
    }

    return queryParams.size !== 0 ? `?${queryParams.toString()}` : ""
};


