import React, {ChangeEvent, useEffect, useState} from 'react';
import styles from './index.module.scss'
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import SelectInput from "../../../../../../components/SelectInput/SelectInput";
import DatePicker from "../../../../../../components/DatePicker/DatePicker";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/hooks";
import {RootState} from "../../../../../../store";
import {formatDate} from "../../../../../../helpers/dateFormatters";
import {formatSelectOptions} from "../../../../../../helpers/formatSelectOptions";
import {ISatelliteDetails} from "../IngestForm/IngestForm";
import {SingleValue} from "react-select";
import {sendSatelliteFile} from "../../../../../../store/iGameReporting/iGameReportingSlice";
import {convertDateForCalendar} from "./helpers/convertDateForCalendar";
import CheckboxWithLabel from "../../../../../../components/CheckboxWithLabel/CheckboxWithLabel";
import {BISSTypes} from "../../../../RightsHolderView/ScheduleIngest/BookFixtures/children/SatelliteForm/SatelliteForm";
import upload from "../../../../../../img/upload.png";
import {IDate} from "@components/AnalyticsDashboard/types";

interface ISatelliteFormProps {
    fixtureId: string
    closeForm: () => void
    setSatellite: (details: ISatelliteDetails) => void
    satelliteDetails: ISatelliteDetails | null
}

interface ISatelliteForm {
    fixtureId: string;
    startDate: IDate;
    endDate: IDate;
    satelliteId: SingleValue<{ value: string, label: string }>;
    bissCode: string;
    symbolRate: number;
    downloadFrequency: number;
    downloadPolarity: SingleValue<{ value: string, label: string }>;
    audio: SingleValue<{ value: string, label: string }>;
    bissType: number,
    nS3_NS4: boolean | undefined;

}

const SatelliteForm: React.FC<ISatelliteFormProps> = ({fixtureId, closeForm, setSatellite, satelliteDetails}) => {
    const {handleSubmit, setValue, formState: {errors}, control,} = useForm<ISatelliteForm>();
    const dispatch = useAppDispatch()
    const {satellites} = useAppSelector((state: RootState) => state.iGameReportingSlice);
    const polarityData = ["X", "Y"];
    const audioData = ["1/2", "3/4", "5/6", "7/8", "9/10"];
    const [dragActive, setDragActive] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [bissType, setBissType] = useState<number>(0);
    const [isNS3NS4, setIsNS3NS4] = useState<boolean | undefined>(false);

    useEffect(() => {
        setSatelliteFormData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [satelliteDetails]);

    const setSatelliteFormData = () => {
        if (satelliteDetails) {
            const currentSatellite = formatSelectOptions(satellites.items).find(sat => sat.value === satelliteDetails.satelliteId.toString())
            setValue("fixtureId", satelliteDetails.fixtureId)
            if (currentSatellite) {
                setValue("satelliteId", currentSatellite)
            }
            setValue("downloadFrequency", satelliteDetails.downloadFrequency)
            setValue("bissCode", satelliteDetails.bissCode)
            setValue("symbolRate", satelliteDetails.symbolRate)
            setValue("startDate", convertDateForCalendar(satelliteDetails.startDate))
            setValue("endDate", convertDateForCalendar(satelliteDetails.endDate))
            setBissType(satelliteDetails.bissType!)
            setIsNS3NS4(satelliteDetails.nS3_NS4)
            const currentPolarity = polarityData.find(polarity => polarity.toLowerCase() === satelliteDetails.downloadPolarity.toLowerCase())
            const currentAudio = audioData.find(audio => audio === satelliteDetails.audio)
            if (currentPolarity) {
                setValue("downloadPolarity", {value: currentPolarity, label: currentPolarity})
            }
            if (currentAudio) {
                setValue("audio", {value: currentAudio, label: currentAudio})
            }
        }
    }

    const polarityOptions = polarityData.map(x => ({
        value: x,
        label: x,
    }))

    const audioOptions = audioData.map(x => ({
        value: x,
        label: x,
    }))

    const createSatelliteIng: SubmitHandler<ISatelliteForm> = (data) => {
        const detailsModel = {
            fixtureId,
            startDate: formatDate(data.startDate),
            endDate: formatDate(data.endDate),
            satelliteId: data.satelliteId!.value,
            bissCode: data.bissCode || "",
            symbolRate: +data.symbolRate || 0,
            downloadFrequency: +data.downloadFrequency || 0,
            downloadPolarity: data.downloadPolarity!.value || "",
            audio: data.audio!.value || "",
            fileUrl: selectedFile ? selectedFile : "",
            bissType,
            nS3_NS4: isNS3NS4
        } as ISatelliteDetails
        setSatellite(detailsModel)
        onUpload()
        closeForm()
    }

    const handleDrag = <T extends HTMLElement>(e: React.DragEvent<T>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

    }

    const handleUploadingOnClick = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        setUploaded(true);
        setSelectedFile(file || null);
    }

    const onUpload = () => {
        let formData = new FormData()
        formData.append("fixtureId", fixtureId)
        if (selectedFile) {
            formData.append("file", selectedFile)
            dispatch(sendSatelliteFile(formData))
        }
    }

    const isCheckedBissType = () => {
        return bissType > 0
    }

    const selectBISSType = (e: React.MouseEvent<HTMLDivElement>, BISSType: number) => {
        e.stopPropagation()
        e.preventDefault()
        setBissType(BISSType)
    }

    const selectIsNS3NS4 = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        e.preventDefault()
        setIsNS3NS4(!isNS3NS4)
    }

    return (
        <div onClick={() => closeForm()} className={styles.overlay}>
            <form
                onClick={(e) => e.stopPropagation()}
                onSubmit={handleSubmit(createSatelliteIng)}
                className={styles.form}
                onDragEnter={handleDrag}
            >
                <h1 className={styles.title}>Satellite</h1>
                {dragActive && <div
                    className={styles.coverElement}
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}>

                </div>}
                <div className={styles.inputsWrapper}>
                    <div className={styles.uploadSection}>
                        <div className={styles.inputInner}>
                            <label className={uploaded
                                ? `${styles.uploadLabel} ${styles.uploaded}`
                                : dragActive
                                    ? `${styles.uploadLabel} ${styles.dragActive}`
                                    : styles.uploadLabel}
                                   htmlFor="file"
                            >
                                <img src={upload} alt="upload"/>
                                <div className={styles.uploadTextSection}>
                                    <h2 className={styles.formTitle} data-testid="satelliteTitle">Satellite</h2>
                                    <h2 className={styles.uploadTitle}>Upload files</h2>
                                    {
                                        uploaded
                                            ?
                                            <p>File uploaded!</p>
                                            :
                                            <>
                                                <p>Drag and drop file here to upload or click to find it on your
                                                    computer</p>
                                                <p className={styles.uploadDescription}>Supported file types are
                                                    .eml .txt .rft .olm and .msg.</p>
                                            </>
                                    }
                                </div>
                                <input
                                    onChange={handleUploadingOnClick}
                                    type="file"
                                    id="file"
                                    className={styles.uploadInput}
                                />
                            </label>
                        </div>
                    </div>
                    <div className={styles.inputInner}>
                        <label className={styles.selectList}>Satellite
                            <Controller
                                control={control}
                                name="satelliteId"
                                rules={{required: !uploaded}}
                                render={({
                                             field: {onChange, value},
                                         }) => (
                                    <SelectInput
                                        options={formatSelectOptions(satellites.items)}
                                        onChange={x => {
                                            onChange(x);
                                        }}
                                        value={value}
                                    />
                                )}
                            />
                            {
                                errors.satelliteId && errors.satelliteId.type === "required"
                                && <span className={styles.error}>This field is required</span>
                            }
                        </label>
                    </div>

                    <label> Biss Type
                        <span className={styles.requiredStar}> *</span>
                        <Controller
                            control={control}
                            name="bissType"
                            rules={{validate: isCheckedBissType}}
                            render={() => (
                                <>
                                    <div className={styles.checkboxGroup}>
                                        <div onClick={(e) => selectBISSType(e, 1)}>
                                            <CheckboxWithLabel
                                                checked={bissType === BISSTypes.BISSMode1}
                                                labelText={"BISS Mode 1"}
                                            />
                                        </div>
                                        <div onClick={(e) => selectBISSType(e, 2)}>
                                            <CheckboxWithLabel
                                                checked={bissType === BISSTypes.BISSE}
                                                labelText={"BISS-E"}
                                            />
                                        </div>
                                        <div onClick={(e) => selectBISSType(e, 3)}>
                                            <CheckboxWithLabel
                                                checked={bissType === BISSTypes.BISSCA}
                                                labelText={"BISS CA"}
                                            />
                                        </div>
                                        <div onClick={(e) => selectIsNS3NS4(e)} className={styles.satelliteCheckNs}>
                                            <div>
                                                <CheckboxWithLabel
                                                    checked={isNS3NS4}
                                                    labelText={"NS3/NS4"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        />
                        {
                            errors.bissType && errors.bissType.type === "validate"
                            && <span className={styles.error}>Biss Type is required</span>
                        }
                    </label>
                    <div className={styles.inputInner}>
                        <label>Biss Code
                            <Controller
                                control={control}
                                name="bissCode"
                                rules={{required: !uploaded}}
                                render={({
                                             field: {onChange, value},
                                         }) => (
                                    <input
                                        onChange={val => {
                                            onChange(val);
                                        }}
                                        value={value || ""}
                                        type="text"
                                        aria-invalid={errors.bissCode ? "true" : "false"}
                                    />
                                )}
                            />
                            {
                                errors.bissCode && errors.bissCode.type === "required"
                                && <span className={styles.error}>This field is required</span>
                            }
                        </label>
                    </div>
                    <div className={styles.inputInner}>
                        <label>Symbol Rate
                            <Controller
                                control={control}
                                name="symbolRate"
                                rules={{required: !uploaded}}
                                render={({
                                             field: {onChange, value},
                                         }) => (
                                    <input
                                        className={styles.inputShort}
                                        onChange={val => {
                                            onChange(val);
                                        }}
                                        value={value || ""}
                                        type="text"
                                        aria-invalid={errors.symbolRate ? "true" : "false"}
                                    />
                                )}
                            />
                            <span className={styles.units}>Msy/s</span>
                            {
                                errors.symbolRate && errors.symbolRate.type === "required"
                                && <span className={styles.error}>This field is required</span>
                            }
                        </label>
                    </div>
                    <div className={styles.inputInner}>
                        <label>Download Frequency
                            <Controller
                                control={control}
                                name="downloadFrequency"
                                rules={{required: !uploaded}}
                                render={({
                                             field: {onChange, value},
                                         }) => (
                                    <input
                                        onChange={val => {
                                            onChange(val);
                                        }}
                                        value={value || ""}
                                        type="text"
                                        aria-invalid={errors.downloadFrequency ? "true" : "false"}
                                    />
                                )}
                            />
                            {
                                errors.downloadFrequency && errors.downloadFrequency.type === "required"
                                && <span className={styles.error}>This field is required</span>
                            }
                        </label>
                    </div>
                    <div className={styles.inputInner}>
                        <label className={styles.selectList}>Download Polarity
                            <Controller
                                control={control}
                                name="downloadPolarity"
                                rules={{required: !uploaded}}
                                render={({
                                             field: {onChange, value},
                                         }) => (
                                    <SelectInput
                                        options={polarityOptions}
                                        onChange={x => {
                                            onChange(x);
                                        }}
                                        value={value}
                                    />
                                )}
                            />
                            {
                                errors.downloadPolarity && errors.downloadPolarity.type === "required"
                                && <span className={styles.error}>This field is required</span>
                            }
                        </label>
                    </div>
                    <div className={styles.inputInner}>
                        <label className={styles.selectList}>Audio
                            <Controller
                                control={control}
                                name="audio"
                                rules={{required: !uploaded}}
                                render={({
                                             field: {onChange, value},
                                         }) => (
                                    <SelectInput
                                        options={audioOptions}
                                        onChange={x => {
                                            onChange(x);
                                        }}
                                        value={value}
                                    />
                                )}
                            />
                            {
                                errors.audio && errors.audio.type === "required"
                                && <span className={styles.error}>This field is required</span>
                            }
                        </label>
                    </div>
                    <div className={styles.dateSection}>
                        <div className={styles.calendar}>Start Date
                            <div className={styles.calendarInputWrapper}>
                                <Controller
                                    control={control}
                                    name="startDate"
                                    rules={{required: !uploaded}}
                                    render={({
                                                 field: {onChange, value},
                                             }) => (
                                        <DatePicker
                                            onChange={date => {
                                                onChange(date)
                                            }}
                                            initValue={value}
                                        />
                                    )}
                                />
                            </div>
                            {
                                errors.startDate && errors.startDate.type === "required"
                                && <span className={styles.error}>This field is required</span>
                            }
                        </div>
                        <div className={styles.calendar}>End Date
                            <div className={styles.calendarInputWrapper}>
                                <Controller
                                    control={control}
                                    name="endDate"
                                    rules={{required: !uploaded}}
                                    render={({
                                                 field: {onChange, value},
                                             }) => (
                                        <DatePicker
                                            onChange={date => {
                                                onChange(date)
                                            }}
                                            initValue={value}
                                        />
                                    )}
                                />
                            </div>
                            {
                                errors.endDate && errors.endDate.type === "required"
                                && <span className={styles.error}>This field is required</span>
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.buttons}>
                    <button onClick={() => closeForm()} className={styles.cancelButton}>Cancel</button>
                    <button
                        className={styles.submitButton}
                        type="submit">
                        Confirm
                    </button>
                </div>
            </form>
        </div>
    );
};

SatelliteForm.displayName = "SatelliteFormReporting"
export default SatelliteForm;
