import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {toastr} from "react-redux-toastr";
import {client} from "../../services/api-servise";
import {AxiosError} from "axios";
import {ISelectItem} from "../userManagement/userManagementSlice";

interface IRestriction {
    id: number,
    competitionId: string,
    isFullScreenDisabled: boolean,
    isFormatRestricted: boolean,
    minimumResolution: number
}

interface IGetRestrictionsReq {
    rightId: string;
    rightHolderId?: string | null
}

interface IRestrictionsInitData {
    rights: {
        items: ISelectItem[]
    }
}

interface IOtherRestrictionsState {
    rights: ISelectItem[],
    restrictions: IRestriction | null,
    isLoading: boolean
}

const initialState: IOtherRestrictionsState = {
    rights: [],
    restrictions: null,
    isLoading: false
}


export const getRestrictionsInitData = createAsyncThunk<IRestrictionsInitData, string | null | undefined, {
    rejectValue: string
}>(
    'other-restrictions/get-restrictions-init-data',
    async (rightHolderId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/RightsManagement/right-holder/restrictions/init${rightHolderId ? '?RightHolderId=' + rightHolderId : ''}`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getOtherRestrictions = createAsyncThunk<IRestriction, IGetRestrictionsReq, { rejectValue: string }>(
    'other-restrictions/get-other-restrictions',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/RightsManagement/right-holder/restrictions/${body.rightId}${body.rightHolderId ? '?RightHolderId=' + body.rightHolderId : ''}`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const otherRestrictionsSlice = createSlice({
    name: 'other-restrictions',
    reducers: {},
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getRestrictionsInitData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getRestrictionsInitData.fulfilled, (state, action) => {
                state.rights = action.payload.rights.items;
                state.isLoading = false;
            })
            .addCase(getOtherRestrictions.fulfilled, (state, action) => {
                state.restrictions = action.payload;
            })
    }
})

export default otherRestrictionsSlice.reducer
