import {
    ICreateSatelliteIngestData,
    IFilesPayload
} from "@store/rightHolderBooking/rightHolderBookingSlice";
import {IMethodPayloads, IUploadedFiles} from "../types/types";
import {IngestFormController, IngestOptionId} from "@enums/ingest";
import {IGameChannelInput} from "../children/IGameChannels/IGameChannels";
import {FieldValues, UseFormGetValues} from "react-hook-form";
import {IIngestFormData} from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/BookFixtures";


class IngestPayload {
    uploadedFilesPayload(fixtureId: string, isBackupIngest: boolean, uploadedFiles: IUploadedFiles) {
        if ((!uploadedFiles.primaryFiles.length && !isBackupIngest) ||
            (!uploadedFiles.backUpFiles.length && isBackupIngest)) {
            return null;
        }
        const filesData = new FormData();
        filesData.append("isBackupIngest", `${isBackupIngest}`);

        if (isBackupIngest) {
            uploadedFiles.backUpFiles.forEach(file => filesData.append("files", file));
        } else {
            uploadedFiles.primaryFiles.forEach(file => filesData.append("files", file));
        }

        return {fixtureId, filesData}
    }

    createIP(formData: IIngestFormData, channelValue: string) {
        return {
            fixture: {
                fixtureId: formData.fixture?.id!,
                ingestResourceId: channelValue,
            }
        }
    }

    updateIP(formData: IIngestFormData, channelValue: string) {
        return {
            fixtureId: formData.fixture?.id!,
            fixture: {
                fixtureId: formData.fixture?.id!,
                ingestResourceId: channelValue,
            }
        }
    }

    updateSatellite(formData: IIngestFormData, files: IFilesPayload | null, fixture: ICreateSatelliteIngestData) {
        return {
            payload: {
                fixture,
                fixtureId: formData?.fixture?.id!
            },
            filesRequestBody: files
        };
    }

    createSatellite(methodPayloads: IMethodPayloads, files: IFilesPayload | null) {
        return {
            fixture: methodPayloads?.Satellite?.fixture!,
            filesRequestBody: files
        };
    }

    updateBTTower(formData: IIngestFormData, methodPayloads: IMethodPayloads, files: IFilesPayload | null, channel: IGameChannelInput) {
        if (channel?.value) {
            return {
                payload: {
                    fixtureId: formData.fixture?.id!,
                    fixture: {
                        fixtureId: formData.fixture?.id!,
                        ingestResourceId: channel.value ?? null,
                        source: methodPayloads?.BTTower?.fixture.source!
                    },
                },
                filesRequestBody: files
            };
        }
        return {
            payload: {
                fixtureId: formData.fixture?.id!,
                fixture: {
                    fixtureId: formData.fixture?.id!,
                    source: methodPayloads.BTTower?.fixture.source!
                },
            },
            filesRequestBody: files
        };
    }

    createBTTower(methodPayloads: IMethodPayloads, files: IFilesPayload | null) {
        return {
            fixture: methodPayloads?.BTTower?.fixture!,
            filesRequestBody: files
        };
    }

    createBackupBTTower(ingestFormData: IIngestFormData, methodPayloads: IMethodPayloads, files: IFilesPayload | null) {
        return {
            payload: {
                ingestType: IngestOptionId.BTTower,
                fixtureId: String(ingestFormData.fixture?.id),
                btTowerFixture: methodPayloads?.BTTowerBackup?.fixture!,
                ipFixture: null,
                satelliteFixture: null
            },
            filesRequestBody: files
        }
    }

    createBackupSatellite(ingestFormData: IIngestFormData, methodPayloads: IMethodPayloads, files: IFilesPayload | null) {
        return {
            payload: {
                ingestType: IngestOptionId.Satellite,
                fixtureId: String(ingestFormData.fixture?.id),
                satelliteFixture: methodPayloads?.SatelliteBackup?.fixture!,
                btTowerFixture: null,
                ipFixture: null,
            },
            filesRequestBody: files
        }
    }

    createBackupIP(ingestFormData: IIngestFormData, getValues: UseFormGetValues<FieldValues>) {
        return {
            ingestType: IngestOptionId.IP,
            fixtureId: String(ingestFormData.fixture?.id),
            ipFixture: {
                fixtureId: String(ingestFormData.fixture?.id),
                ingestResourceId: getValues(IngestFormController.BackupIPChannels).value
            },
            btTowerFixture: null,
            satelliteFixture: null,
        };
    }

    createContentSupplier(ingestFormData: IIngestFormData, supplierValue: string) {
        return {
            payload: {
                fixtureId: ingestFormData?.fixture?.id!,
                technicalProviderId: supplierValue
            }
        }
    }

    createIGameChannelPayload(ingestFormData: IIngestFormData, channel: {
        value: string;
        label: string
    }, backUp: boolean) {
        return {
            fixtureId: ingestFormData?.fixture?.id!,
            ingestResourceId: channel?.value,
            isBackup: backUp,
        }
    }
}

const ingestPayloadInstance = new IngestPayload();
export default ingestPayloadInstance;


