import {useState} from "react";
import {useAppDispatch} from "../../../../../hooks/hooks";
import {useDebounce} from "../../../../../helpers/useDebounce";
import {AppThunk} from "../../../../../store";
import {IAvailableContentDataReq} from "@store/contentAnalysis/types";
import {
    generateGetContentPayload,
    IAvailableContentParams
} from "@pages/Cms/OperatorView/AvailableContent/helpers/generateGetContentPayload";


export const useSorting = (operatorId: string | null, request: (payload: IAvailableContentDataReq) => AppThunk, params: IAvailableContentParams, useSorting: boolean) => {

    const [sorting, setSorting] = useState({
        property: "",
        isAscending: true
    });

    const dispatch = useAppDispatch();

    const getSortedItems = () => {
        if (!useSorting) return;

        const payload = generateGetContentPayload(params);
        payload.sortingDto = {
            property: sorting.property,
            isAscending: sorting.isAscending
        }
        dispatch(request(payload))
    }

    useDebounce(getSortedItems, [sorting], 500);

    return {setSorting, sorting}
}
