import React from 'react';
import styles from './index.module.scss'
import {handleEnterKeyDown} from "../../pages/Cms/RightsHolderView/ContentAccess/helpers/handleEnterKeyDown";

export interface IConfirmModalProps {
    title?: string;
    message: string;
    closeForm: () => void;
    action: (val?: any) => void;
    children?: any;
    wide?: boolean;
}

const ConfirmModal: React.FC<IConfirmModalProps> = React.memo(({message, closeForm, action, children, title, wide}) => {
    const onClick = (e: React.MouseEvent) => {
        e.preventDefault();
        action();
        closeForm();
    }

    return (
        <div onClick={() => closeForm()} className={styles.overlay} data-testid="confirm-modal">
            <form
                onClick={(e) => e.stopPropagation()}
                className={wide ? `${styles.form} ${styles.formWide}` : styles.form}
                onKeyDown={handleEnterKeyDown
                }>
                {title && <h2 className={styles.title}>{title}</h2>}
                <div className={styles.decor}>
                    {
                        children
                    }
                </div>
                <div className={styles.content}>
                    <p className={styles.message}>{message}</p>
                </div>
                <div className={styles.buttons}>
                    <button
                        onClick={closeForm}
                        className={styles.cancelButton}
                        data-testid="cancel-button"
                        type="button"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={(e) => onClick(e)}
                        className={styles.submitButton}>
                        Confirm
                    </button>
                </div>
            </form>
        </div>
    );
});
ConfirmModal.displayName = "ConfirmModal"
export default ConfirmModal;
