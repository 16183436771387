import React from 'react';
import styles from './index.module.scss';
import {client} from "../../../../../../services/api-servise";
import download from "../../../../../../img/download.png";
import {AxiosError} from "axios";
import {toastr} from "react-redux-toastr";


interface IDownloadCsv {
    searchValue: string | undefined,
    sorting: {
        property: string | null,
        isAscending: boolean
    }
}

const DownloadCsv: React.FC<IDownloadCsv> = React.memo(({searchValue, sorting}) => {
    const downloadSCVFile = async () => {
        try {
            const {
                data,
                headers,
            } = await client.post(`api/Reports/bt-bookings/csv/download`, {searchValue, sorting})
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            if (headers['content-disposition']) {
                const contentDisposition = headers['content-disposition'];
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    link.download = matches[1].replace(/['"]/g, '');
                }
            }
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url)

        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
            }
        }
    }

    return (
        <div className={styles.downloadContainer}>
            <button className={styles.csvBtn} onClick={downloadSCVFile}>
                BT Bookings CSV
                <img src={download} alt="csv"/>
            </button>
        </div>
    );
});

export default DownloadCsv;
