export const hasNestedObjectData = (obj: Object | null) => {
    if (obj != null) {
        return Object.values(obj).some(value => {
            if (value && typeof value === 'object') {
                return Object.values(value).some(nestedValue => nestedValue);
            }
            return value !== null && value !== undefined && value !== '';
        });
    }
    return false;
}