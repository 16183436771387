import React, {useState} from "react";
import styles from './index.module.scss'
import {FieldValues, SubmitHandler, useForm, Controller} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {verifyUser} from "../../../store/auth/authSlice";
import devicesLogo from "../../../img/devices.svg";
import close from "../../../img/close.svg";
import Input from "../../../components/Input/Input";

interface ISecondStepFormProps {
    onClose: () => void;
}

interface IFormInput {
    verificationCode: string;
}

const SecondStepForm: React.FC<ISecondStepFormProps> = ({onClose}) => {
    const {userEmail} = useAppSelector(state => state.auth)
    const [verificationCode, setVerificationCode] = useState<string>('')
    const {control, handleSubmit, formState: {errors}} = useForm<IFormInput>({
        mode: "onChange"
    });

    const dispatch = useAppDispatch()
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVerificationCode(e.target.value)
    }

    const onSubmit: SubmitHandler<FieldValues> = () => {
        const userInfo = {
            email: userEmail,
            verificationCode,
            staySignedIn: true
        }
        dispatch(verifyUser(userInfo))
    };

    const onFormClose = (e: any) => {
        e.preventDefault();
        e.stopPropagation()
        onClose();
    }

    return (
        <div className={styles.formOverlay}>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.secondStepForm}>

                <div className={styles.mainSection}>
                    <div className={styles.mainSectionDevicesLogo}>
                        <img src={devicesLogo} alt="email-logo"/>
                    </div>
                    <div className={styles.mainSectionFormItems}>
                        <button className={styles.formCloseButton}>
                            <img src={close} alt="close"
                                 onClick={(e) => onFormClose(e)}/>
                        </button>
                        <h6 className={styles.formTitle}>Two factor authentication</h6>
                        <p className={styles.formSubtitle}>
                            Enter the access code that was sent to your designated email.
                        </p>
                        <label className={styles.inputLabel} htmlFor="verificationCode">
                            Access code
                            <Controller
                                name="verificationCode"
                                control={control}
                                rules={{
                                    required: true
                                }}
                                render={({field: {value, onChange}}) => (
                                    <Input
                                        id="verificationCode"
                                        className={styles.verifyInput}
                                        type="text"
                                        name="verificationCode"
                                        placeholder="Please enter code here"
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e)
                                            handleChange(e)
                                        }}
                                    />
                                )}
                            />
                            {errors.verificationCode && errors.verificationCode.type === "required" &&
                                <div className={styles.formError}>Verification code is required field</div>}
                            {errors.verificationCode && errors.verificationCode.type === "maxLength" &&
                                <div className={styles.formError}>Max length 10 symbols</div>}
                            {errors.verificationCode && errors.verificationCode.type === "minLength" &&
                                <div className={styles.formError}>Min length 10 symbols</div>}
                        </label>
                    </div>
                </div>
                <div className={styles.buttons}>
                    <button
                        onClick={(e: any) => onFormClose(e)}
                        className={styles.cancelButton}>
                        Cancel
                    </button>
                    <button
                        className={styles.submitButton}
                        type="submit">
                        Verify
                    </button>
                </div>
            </form>
        </div>
    );
};

export default SecondStepForm;
