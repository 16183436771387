import React, {useEffect, useMemo, useState} from 'react';
import styles from './index.module.scss'
import {SingleValue} from "react-select";
import {Controller, useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "@hooks/hooks";
import Input from "../../../../../../../../../components/Input/Input";
import {formatSelectOptions} from "@helpers/formatSelectOptions";
import SelectInput from "../../../../../../../../../components/SelectInput/SelectInput";
import Checkbox from "../../../../../../../../../components/Checkbox";
import {
    addIGameUser,
    addOperatorUser,
    addRightHolderUser,
    getOperatorPages,
    getRightHolderPages, getRightHolderUsers,
    ISelectItem,
    updateUser,
} from "@store/userManagement/userManagementSlice";
import Loader from "../../../../../../../../../components/Loader/Loader";
import uuid from "react-uuid";
import {PageTabs} from "../../../../UserManagement";
import {IDropdownOption} from "@components/AnalyticsDashboard/types";
import {formatSuperAdminLabel} from "../../helpers/formatSuperAdminLabel";

interface ISettingsFormProps {
    closeForm: () => void;
    userType: string;
    clearSearchingValue?: () => void;
}

const UserForm: React.FC<ISettingsFormProps> = React.memo((
    {
        closeForm,
        userType,
        clearSearchingValue
    }) => {
    const dispatch = useAppDispatch();
    const [email, setEmail] = useState<string>('');
    const [userRole, setUserRole] = useState<{ value: string, label: string } | undefined>(undefined);
    const [selectedPages, setSelectedPages] = useState<ISelectItem[]>([]);
    const {handleSubmit, control, formState: {errors}} = useForm();
    const {
        pages,
        currentUserPages,
        clientId,
        userDetails,
        pagesLoading,
        formLoading
    } = useAppSelector(state => state.userManagement);
    const {roles} = useAppSelector(state => state.settings);

    useEffect(() => {
        if (userRole) {
            const body = {
                clientId,
                roleId: userRole.value
            }
            if (userType === PageTabs.RIGHTS_HOLDERS) {
                dispatch(getRightHolderPages(body))
            } else if (userType === PageTabs.OPERATORS) {
                dispatch(getOperatorPages(body))
            }
// eslint-disable-next-line no-mixed-operators
            if (userDetails && userRole.label === userDetails.roleName ||
                // eslint-disable-next-line no-mixed-operators
                userDetails && userDetails.roleName === 'SuperAdmin' && userRole.label === 'Super Admin') {
                setSelectedPages(currentUserPages)
            } else {
                setSelectedPages([])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userRole])


    useEffect(() => {
        setFormInputsValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDetails])

    const setFormInputsValues = () => {
        if (userDetails) {
            setEmail(userDetails.email);

            const currentRole = roles.find(item => item.id === userDetails.roleId)
            if (currentRole) {
                const formatRole = {
                    label: currentRole.name === "SuperAdmin" ? "Super Admin" : currentRole.name,
                    value: currentRole.id
                }
                setUserRole(formatRole)
            }
        }
    }

    const handleRole = (option: SingleValue<IDropdownOption>, onChange: (...event: any[]) => void) => {
        if (option) {
            setUserRole(option);
            onChange(option);
        }
    }

    const isCheckedPages = (checkedPageId: number) => {
        const currentRolePages = selectedPages.find(item => +item.id === checkedPageId)
        return !!currentRolePages;
    }

    const checkboxHandler = (pageItem: ISelectItem, onChange: (...event: any[]) => void) => {
        if (selectedPages.find(selectedPageItem => selectedPageItem.id === pageItem.id)) {
            const newData = selectedPages.filter(item => item.id !== pageItem.id);
            setSelectedPages(newData);
            onChange(newData)
        } else {
            const newData = [...selectedPages, pageItem];
            setSelectedPages(newData);
            onChange(newData)
        }
    }


    const formatSelectRoles = useMemo(() => (
        formatSuperAdminLabel(formatSelectOptions(roles))
    ), [roles]);


    const createUserData = () => {
        if (userType === PageTabs.I_GAME_USERS && userRole && userDetails) {
            const editUser = {
                payload: {
                    userId: userDetails.id,
                    roleId: userRole.value,
                },
                closeForm
            }
            dispatch(updateUser(editUser));

        } else if (userType === PageTabs.I_GAME_USERS && userRole) {
            const newUser = {
                payload: {
                    email,
                    roleId: userRole.value,
                },
                closeForm
            }
            dispatch(addIGameUser(newUser));
        }

        if (userType === PageTabs.RIGHTS_HOLDERS && userRole && userDetails) {
            const editUser = {
                payload: {
                    userId: userDetails.id,
                    roleId: userRole.value,
                    pages: selectedPages.map(page => +page.id),
                },
                closeForm
            }
            dispatch(updateUser(editUser));

        } else if (userType === PageTabs.RIGHTS_HOLDERS && userRole) {
            const newUser = {
                payload: {
                    email,
                    roleId: userRole.value,
                    pages: selectedPages.map(page => +page.id),
                    clientId,
                },
                closeForm
            }

            dispatch(addRightHolderUser(newUser))
                .then(() => {
                    if (clientId) {
                        dispatch(getRightHolderUsers(clientId));
                        if (clearSearchingValue) clearSearchingValue()
                    }
                })
        }

        if (userType === PageTabs.OPERATORS && userRole && userDetails) {
            const editUser = {
                payload: {
                    userId: userDetails.id,
                    roleId: userRole.value,
                    pages: selectedPages.map(page => +page.id),
                },
                closeForm
            }
            dispatch(updateUser(editUser));

        } else if (userType === PageTabs.OPERATORS && userRole) {
            const newUser = {
                payload: {
                    email,
                    roleId: userRole.value,
                    pages: selectedPages.map(page => +page.id),
                    clientId,
                },
                closeForm
            }
            dispatch(addOperatorUser(newUser));
        }
    }

    return (
        <div className={styles.overlay}>
            {formLoading && <Loader/>}

            {!formLoading &&
                <form onSubmit={handleSubmit(createUserData)}
                      onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                      className={styles.form}>
                    <div className={styles.contentWrapper}>
                        <div className={styles.titleWrapper}>
                            <h6>{userDetails ? `Edit User` : 'New User'}</h6>
                        </div>
                        <div className={styles.inpWrapper}>
                            <div className={styles.inputInner}>
                                <label className={styles.label}>Email
                                    <Controller
                                        control={control}
                                        name="email"
                                        rules={{
                                            required: !userDetails,
                                            maxLength: 40,
                                            minLength: 10,
                                            pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                        }}
                                        render={({field: {onChange}}) => (
                                            <Input
                                                onChange={(e) => {
                                                    onChange(e);
                                                    setEmail(e.target.value)
                                                }}
                                                value={email}
                                                type="text"
                                                placeholder={"user@domain.ext"}
                                                disabled={!!userDetails}
                                            />
                                        )}
                                    />
                                    {errors?.email?.type === "required" &&
                                        <div className={styles.formErrorBlock}>Email is required field</div>}
                                    {errors?.email?.type === "minLength" &&
                                        <div className={styles.formErrorBlock}>Email should contain at least 10
                                            characters</div>}
                                    {errors?.email?.type === "maxLength" &&
                                        <div className={styles.formErrorBlock}>Email should contain max 40
                                            characters</div>}
                                    {errors?.email?.type === "pattern" &&
                                        <div className={styles.formErrorBlock}>Email should be like
                                            example@xxx.xx</div>}
                                    {errors?.email?.type === "validate" &&
                                        <div className={styles.formErrorBlock}>User with this email already
                                            exists</div>}
                                </label>
                            </div>
                        </div>

                        <div className={styles.inpWrapper}>
                            <div className={styles.inputInner}>
                                <label className={styles.label}>Role
                                    <Controller
                                        name="role"
                                        control={control}
                                        rules={{
                                            required: !userRole
                                        }}
                                        render={({field: {onChange}}) =>
                                            <SelectInput
                                                aria-label={"Role"}
                                                value={userRole}
                                                onChange={(option) => {
                                                    handleRole(option, onChange);
                                                }}
                                                options={formatSelectRoles}
                                                placeholder={"Choose an option"}
                                            />
                                        }
                                    />
                                </label>
                                {errors.role &&
                                    <div className={styles.formErrorBlock}>Role is required field</div>}
                            </div>
                        </div>

                        {userType !== PageTabs.I_GAME_USERS && <div>
                            {pagesLoading && <Loader/>}
                            {!pagesLoading && pages && userRole &&
                                <div className={styles.inpWrapper}>
                                    <div className={styles.label}>Page Visibility
                                        {pages.length ? <Controller
                                                control={control}
                                                name="pageVisibility"
                                                render={({field: {onChange}}) => (
                                                    <div className={styles.checkboxes}>
                                                        {pages.map(page => (
                                                            <div key={page.id}>
                                                                <Checkbox
                                                                    isChecked={isCheckedPages(+page.id)}
                                                                    checkboxId={uuid()}
                                                                    onChangeHandler={() => checkboxHandler(page, onChange)}
                                                                    filled={true}
                                                                />
                                                                <span>{page.name}</span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            />
                                            :
                                            <p className={styles.pageVisibilityText}> There aren't pages to
                                                choose</p>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        }
                    </div>
                    <div className={styles.buttons}>
                        <button onClick={closeForm} className={styles.cancelButton}>Cancel</button>
                        <button
                            className={styles.submitButton}
                            type="submit">Confirm
                        </button>
                    </div>
                </form>
            }
        </div>
    );
});
UserForm.displayName = "UserForm";
export default UserForm;
