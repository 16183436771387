import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {client} from "../../services/api-servise";
import {IInputOption} from "../operatorAccess/operatorAccessSlice";
import {AxiosError} from "axios";
import {toastr} from "react-redux-toastr";
import {formClient} from "../../services/multiPartForm-service";

interface IRAssignmentInitResponse {
    rightHolders: {
        items: IInputOption[]
    };
    competitions: {
        items: IInputOption[]
    };
    competitionDataSources: {
        items: IInputOption[]
    };
    sports: {
        items: IInputOption[]
    };
}

interface IRAssignmentCompetitionInit {
    sports: {
        items: IInputOption[]
    };
    countries: {
        items: IInputOption[]
    };
    competitionTypes: {
        items: IInputOption[]
    };
    competitionDataSources: {
        items: IInputOption[]
    };
}

export interface IRightsWithTechProvidersPayload {
    rightHolderId: string;
    sportId: string
    competitionDataSource: string;
    competitionId?: string;
}

export interface ICreateAssignmentBody {
    payload: FormData
    closeForm: () => void
}

export interface IRightAssignment {
    id: number;
    competitionId: string;
    competitionName: string;
    rightHolderId: string | null;
    rightHolderName: string;
    sportId: string;
    sportName: string;
    availableFixtures: number;
    dataSource: string
    logo: string
    startDate: string
    endDate: string
    onePlatformPropertyName: string | null
}

export interface IConfirmedAssignment {
    provider: string,
    rightHolderAssignments: IRightAssignment[]
}

export interface ICreatedCompetition {
    name: string,
    dataSource: string,
    sportId: string,
    regionId: string,
    gender: number,
    type: number,
    extraTime: boolean
}

interface IRightAssignmentState {
    initOptions: {
        rightHolders: IInputOption[],
        competitions: IInputOption[],
        competitionDataSources: IInputOption[],
        sports: IInputOption[]
    }
    competitionInit: {
        sports: IInputOption[]
        countries: IInputOption[]
        competitionTypes: IInputOption[]
    }
    unconfirmedAssignments: IRightAssignment[]
    confirmedAssignment: IConfirmedAssignment[]
    loader: boolean
    isThereRightsBySource: boolean
}

const initialState: IRightAssignmentState = {
    initOptions: {
        rightHolders: [],
        competitions: [],
        competitionDataSources: [],
        sports: []
    },
    competitionInit: {
        competitionTypes: [],
        sports: [],
        countries: [],
    },
    unconfirmedAssignments: [],
    confirmedAssignment: [],
    loader: false,
    isThereRightsBySource: true
}

export const getAssignmentInit = createAsyncThunk<IRAssignmentInitResponse>(
    'right-assignment/get-init',
    async (_m, {rejectWithValue, dispatch}) => {
        try {
            const response = await client.get("api/RightsManagement/right-holder/assignment/init")
            dispatch(getCompetitionOptions())
            return response.data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getCompetitionOptions = createAsyncThunk<IRAssignmentCompetitionInit>(
    'right-assignment/get-competition-options',
    async (_m, {rejectWithValue}) => {
        try {
            const response = await client.get("api/Competitions/init")
            return response.data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getRightsWithTechProviders = createAsyncThunk<{ items: IInputOption[] }, IRightsWithTechProvidersPayload>(
    'right-assignment/get-rights-with-tech-providers-options',
    async ({rightHolderId, competitionDataSource, competitionId, sportId}, {rejectWithValue}) => {
        try {
            const response = await client.get(`api/Competitions?rightHolderId=${rightHolderId}&competitionDataSource=${competitionDataSource}${competitionId ? `&competitionId=${competitionId}` : ""}&sportId=${sportId}`);
            return response.data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const createCompetition = createAsyncThunk<IRAssignmentCompetitionInit, ICreatedCompetition>(
    'right-assignment/create-competition',
    async (competition, {rejectWithValue, dispatch}) => {
        try {
            const response = await client.post("api/Competitions", competition)
            toastr.success("IGame", "New Property was created")
            dispatch(getAssignmentInit())
            return response.data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getConfirmedAssignment = createAsyncThunk<IConfirmedAssignment[]>(
    'right-assignment/get-confirmed',
    async (_, {rejectWithValue}) => {
        try {
            const response = await client.get("api/RightsManagement/right-holder/assignment")
            return response.data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }

    }
)

export const createAssignment = createAsyncThunk<IRightAssignment[], ICreateAssignmentBody>(
    'right-assignment/create',
    async (body, {rejectWithValue}) => {
        try {
            const response = await formClient.post("api/RightsManagement/right-holder/assignment", body.payload)
            toastr.success("IGame", "New Assignment was created")
            body.closeForm()
            return response.data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const updateUnconfirmedAssignment = createAsyncThunk<IRightAssignment, FormData>(
    'right-assignment/update',
    async (body, {rejectWithValue}) => {
        try {
            const response = await client.put("api/RightsManagement/right-holder/assignment", body);
            toastr.success("IGame", "Assignment was updated")
            return response.data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const confirmAssignments = createAsyncThunk<IRightAssignment[], number[]>(
    'right-assignment/confirm',
    async (body, {rejectWithValue, dispatch}) => {
        try {
            const response = await client.put("/api/RightsManagement/right-holder/assignment/confirm", body)
            dispatch(getConfirmedAssignment())
            dispatch(getUnconfirmedAssignments())
            toastr.success("IGame", `${body.length} Assignment was confirmed`)
            return response.data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const deleteAssignment = createAsyncThunk<IRightAssignment[], number>(
    'right-assignment/delete',
    async (assignmentId, {rejectWithValue}) => {
        try {
            const response = await client.delete(`api/RightsManagement/right-holder/assignment/${assignmentId}`)
            return response.data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const deleteConfirmedAssignment = createAsyncThunk<IRightAssignment[], number>(
    'right-assignment/delete',
    async (assignmentId, {rejectWithValue, dispatch}) => {
        try {
            const response = await client.delete(`api/RightsManagement/right-holder/assignment/${assignmentId}`)
            dispatch(getConfirmedAssignment())
            return response.data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getUnconfirmedAssignments = createAsyncThunk<IRightAssignment[]>(
    'right-assignment/get-unconfirmed',
    async (_, {rejectWithValue}) => {
        try {
            const response = await client.get("api/RightsManagement/right-holder/assignment/unconfirmed")
            return response.data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const updateConfirmedAssignment = createAsyncThunk<IRightAssignment, FormData>(
    'right-assignment/update-confirmed',
    async (body, {rejectWithValue}) => {
        try {
            const response = await formClient.put("api/RightsManagement/right-holder/assignment/confirmed", body);
            toastr.success("IGame", "Assignment was updated")
            return response.data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const rightAssignmentSlice = createSlice({
    name: 'rightAssignment',
    initialState,
    reducers: {
        clearCompetitionOptions: (state, action) => {
            state.initOptions.competitions = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAssignmentInit.fulfilled, (state, action) => {
                state.initOptions.rightHolders = action.payload.rightHolders.items
                state.initOptions.sports = action.payload.sports.items
                state.initOptions.competitionDataSources = action.payload.competitionDataSources.items
            })
            .addCase(getCompetitionOptions.fulfilled, (state, action) => {
                state.competitionInit.sports = action.payload.sports.items
                state.competitionInit.countries = action.payload.countries.items
                state.competitionInit.competitionTypes = action.payload.competitionTypes.items
            })
            .addCase(getUnconfirmedAssignments.fulfilled, (state, action) => {
                state.unconfirmedAssignments = action.payload
            })
            .addCase(getConfirmedAssignment.fulfilled, (state, action) => {
                state.confirmedAssignment = action.payload
            })
            .addCase(createAssignment.fulfilled, (state, action) => {
                state.unconfirmedAssignments.push(action.payload[0])
            })
            .addCase(updateUnconfirmedAssignment.fulfilled, (state, action) => {
                state.unconfirmedAssignments = state.unconfirmedAssignments.map(asm => asm.id === action.payload.id ? action.payload : asm)
            })
            .addCase(deleteAssignment.fulfilled, (state, action) => {
                state.unconfirmedAssignments = state.unconfirmedAssignments.filter(assignment => assignment.id !== action.meta.arg)
            })
            .addCase(updateConfirmedAssignment.fulfilled, (state, action) => {
                state.confirmedAssignment = state.confirmedAssignment.map((providerData: IConfirmedAssignment) => {
                    if (providerData.provider === action.payload.rightHolderName) {
                        return {
                            provider: providerData.provider,
                            rightHolderAssignments: providerData.rightHolderAssignments.map(assignment => {
                                if (assignment.id === action.payload.id) {
                                    return action.payload;
                                } else {
                                    return assignment;
                                }
                            })
                        }
                    } else {
                        return providerData;
                    }
                })
            })
            .addCase(getRightsWithTechProviders.fulfilled, (state, action) => {
                state.isThereRightsBySource = action.payload.items.length !== 0;
                state.initOptions.competitions = action.payload.items
            })
    },
})
export const {clearCompetitionOptions} = rightAssignmentSlice.actions
export default rightAssignmentSlice.reducer
