import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import AuditTrailsTable from "../children/AuditTrailsTable/AuditTrailsTable";
import AuditTrailPageInner from "../children/AuditTrailPageInner/AuditTrailPageInner";
import UserManagementDetailsTable from "./children/UserManagementDetailsTable/UserManagementDetailsTable";
import styles from "./index.module.scss";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {
    getUsers,
    IUserManagementDetailsResponse,
    cleanState
} from "../../../store/auditTrailsUserManagement/auditTrailsUserManagementSlice";
import {IMenuStructure} from "../../../pages/Cms/AppRoutes/routes/RouteInterface";
import {toastr} from "react-redux-toastr";
import {useDebounce} from "../../../helpers/useDebounce";
import SearchByTextInput from "../../SearchByTextInput/SearchByTextInput";
import commonStyles from "../children/AuditTrailPageInner/index.module.scss";
import {RecordTypes} from "../../../enums/recordTypes";
import {getAuditTrails} from "../../../store/auditTrailsCommon/auditTrailsCommonSlice";

export interface IAuditTrailsUserManagementProps {
    menuStructure: IMenuStructure;
}

const AuditTrailsUserManagement: React.FC<IAuditTrailsUserManagementProps> = ({menuStructure}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState<string>("");
    const [userDetails, setUserDetails] = useState<IUserManagementDetailsResponse | null>(null);
    const [initial, setInitial] = useState(true);
    const [userEmail, setUserEmail] = useState<string | null>(null);
    const {
        isDeleted,
        usersList,
        itemsPerPage,
    } = useAppSelector(state => state.auditTrailsUserManagement);
    const {trailsItemsPerPage, trails} = useAppSelector(state => state.auditTrailsCommon);
    const query = new URLSearchParams(useLocation().search);
    const queryEmail = query.get("email");
    const isExternal = !!query.get("isExternal");

    useEffect(() => {
        return () => {
            dispatch(cleanState());
        };
    }, [dispatch]);

    useEffect(() => {
        if (queryEmail) {
            setUserEmail(queryEmail);
            dispatch(getUsers({
                searchValue: queryEmail,
                pageNumber: 1,
                itemsPerPage
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryEmail, dispatch]);

    useEffect(() => {
        if (userEmail) {
            dispatch(getAuditTrails({
                auditRecordType: RecordTypes.User,
                recordId: userEmail,
                pageNumber: 1,
                itemsPerPage: trailsItemsPerPage,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userEmail, dispatch]);

    useEffect(() => {
        if (!!usersList?.length && userEmail) {
            const user = usersList?.find(userItem => userItem.user === userEmail);
            if (user) {
                setUserDetails(user);
            }
        }
    }, [usersList, userEmail]);

    useEffect(() => {
        if (initial) {
            setInitial(false);
        } else {
            if (isDeleted && trails.length === 0) {
                toastr.error('Error!', "This user does not exist");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeleted, trails]);

    const handleGoBack = () => {
        navigate(-1);
    }

    const onSearch = (value: string) => {
        setUserEmail && setUserEmail(null);
        navigate({
            pathname: window.location.pathname,
            search: "",
        }, {replace: true})
        setSearchValue(value);
    }

    const fetchUsersData = () => {
        if (!searchValue) {
            return;
        }
        dispatch(getUsers({
            searchValue,
            pageNumber: 1,
            itemsPerPage
        }));
    }

    useDebounce(fetchUsersData, [searchValue], 500);

    return (
        <>
            <AuditTrailPageInner
                pageTitle={"Audit Trails"}
                pageSubTitle={"User Management"}
                pageHeader={"Audit Trails - User Management"}
                menuStructure={menuStructure}
                backButtonAction={handleGoBack}
                isExternal={isExternal}>
                {
                    userEmail &&
                    <p className={styles.userDetails} key="details">
                        <span>Group: </span>{userDetails?.userGroup}<span>User: </span>{userDetails?.user}</p>
                }
                <div className={commonStyles.searchInput} key="inputs-container">
                    <SearchByTextInput
                        value={searchValue}
                        onChange={(e) => onSearch(e)}/>
                </div>
                {
                    userEmail
                        ?
                        <AuditTrailsTable
                            height="38vh"
                            auditRecordType={RecordTypes.User}
                            recordId={userEmail}
                        />
                        :
                        <UserManagementDetailsTable
                            setUserEmail={setUserEmail}
                            searchValue={searchValue}
                        />
                }
            </AuditTrailPageInner>
        </>
    );
}

export default AuditTrailsUserManagement;
AuditTrailsUserManagement.displayName = "AuditTrailsUserManagement"
