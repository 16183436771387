import React, {useEffect, useState} from 'react';
import styles from './index.module.scss'
import Checkbox from "../../../../../../components/Checkbox";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/hooks";
import {updateOtherRestrictions} from "../../../../../../store/geoRestrictions/geoRestrictionsSlice";
import {SingleValue} from "react-select";

interface IFormatRestrictionsProps {
    right: SingleValue<{ value: string, label: string } | undefined>
}

const FormatRestrictions: React.FC<IFormatRestrictionsProps> = React.memo(({right}) => {
    const [isFullScreenDisabled, setIsFullScreenDisabled] = useState<boolean | undefined>(undefined);
    const {restriction} = useAppSelector(state => state.geoRestrictions);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (restriction) {
            setIsFullScreenDisabled(restriction.isFullScreenDisabled);
        }

        if (!right) {
            setIsFullScreenDisabled(undefined);
        }
    }, [restriction, right]);


    const handleChangeScreen = () => {
        setIsFullScreenDisabled(!isFullScreenDisabled);

        if (restriction) {
            const payload = {
                id: restriction.id,
                isFullScreenDisabled: !isFullScreenDisabled,
            }
            dispatch(updateOtherRestrictions(payload));
        }
    }

    return (
        <div className={styles.restrictionsWrapper}>
            <div className={styles.content}>
                <h5 className={styles.title}>Format Restrictions</h5>

                {isFullScreenDisabled !== undefined &&
                    <>
                        <h6 className={styles.subTitle}><span>Restrictions </span>Tick all that apply</h6>
                        <div className={styles.checkbox}>
                            <Checkbox
                                isChecked={isFullScreenDisabled}
                                onChangeHandler={handleChangeScreen}
                            />
                            <span>Full Screen Disabled</span>
                        </div>
                    </>
                }
            </div>
        </div>
    );
});

export default FormatRestrictions;
