import {IBookedContentState} from "../../../../../../store/operatorBookedContent/operatorBookedContentSlice";
import {setFilters} from "../../../../../../store/operatorRightsOverview/operatorRightsOverviewSlice";
import {useAppDispatch} from "../../../../../../hooks/hooks";

export const useSetFilters = () => {
    const dispatch = useAppDispatch();
    return (value: any, name: keyof IBookedContentState["filters"]) => {
        dispatch(setFilters({value, name} as any));
    };
}
