import React, {useEffect, useRef, useState} from "react";
import styles from "./index.module.scss";
import moment from "moment/moment";
import {Tooltip} from "react-tooltip";
import {TfiTrash} from "react-icons/tfi";
import {IoClose} from "react-icons/io5";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {RootState} from "../../../store";
import {
    bookFixtures,
    ICartFixture,
    deleteFixtureFromCart,
    getCartFixtures,
    getCartFixturesForIgameRightsHolder
} from "../../../store/rightHolderBooking/rightHolderBookingSlice";
import AppTableWhite from "../../AppTableWhite/AppTableWhite";
import {useNavigate, useParams} from "react-router-dom";
import PageHeader from "../../PageHeader/PageHeader";
import {Group} from "../../../enums/group";

export interface ICartProps {
    menuStructure?: any;
}

const Cart: React.FunctionComponent<ICartProps> = React.memo(({menuStructure}) => {
    const {id} = useParams();
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    const [rightsHolderId, setRightsHolderId] = useState<string | null>(null);
    const {rightsHolderCartFixtures} = useAppSelector((state: RootState) => state.rightHolderBooking);
    const {role} = useAppSelector((state: RootState) => state.userInfo);
    const tableRef = useRef<HTMLTableElement | null>(null);
    const tableWrapper = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (id && id !== "null") {
            setRightsHolderId(id);
        }

        if (role === Group.RightHolder) {
            dispatch(getCartFixtures(null));

        } else if (role === Group.IGame && id) {
            dispatch(getCartFixturesForIgameRightsHolder(id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = () => {

        dispatch(bookFixtures({
            rightHolderId: rightsHolderId,
            fixturesIds: rightsHolderCartFixtures.map(y => y.fixtureId)
        }))
        navigate(-1);
    }

    const handleDeleteFixtureFromCartClick = (fixture: ICartFixture) => {
        dispatch(deleteFixtureFromCart(fixture.id));
    }

    return (
        <>
            <PageHeader menuStructure={menuStructure} title={"Cart"}/>
            <div className={styles.checkoutPage}>
                <div className={styles.pageContainer}>
                    <div className={styles.upperSection}>
                        <h1 className={styles.checkoutTitle}>Cart</h1>
                        <p className={styles.checkoutDescription}>
                            {rightsHolderCartFixtures.length !== 0 ? "Here are the items you have selected for ingest." : ""}
                        </p>
                        <button
                            className={styles.closeButton}
                            onClick={() => navigate(-1)}>
                            <IoClose className={styles.closeIcon}/>
                        </button>
                    </div>
                    <div className={styles.mainContentWrapper}>
                        <div ref={tableWrapper} className={styles.tableContainer}>
                            {
                                rightsHolderCartFixtures.length === 0
                                    ?
                                    <p className={styles.cartEmptyText}>
                                        Your cart is empty at the moment. Book new fixtures or check already booked on
                                        the Upcoming Booked Fixtures page.
                                    </p>
                                    :
                                    <div className={styles.tableWrapper}>
                                        <AppTableWhite reference={tableRef}>
                                            <thead>
                                            <tr>
                                                <th>
                                                    Sport
                                                </th>
                                                <th>
                                                    Content Supplier
                                                </th>
                                                <th>
                                                    Start Time
                                                </th>
                                                <th>
                                                    End Time
                                                </th>
                                                <th>
                                                    Property
                                                </th>
                                                <th>
                                                    Title
                                                </th>
                                                <th>
                                                    Description
                                                </th>
                                                <th>
                                                    Delete
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                rightsHolderCartFixtures.map(item => (
                                                    <tr key={item.id}>
                                                        <td>
                                                            {item.sport}
                                                        </td>
                                                        <td>
                                                            {item.contentSupplier}
                                                        </td>
                                                        <td>
                                                            {moment(item.startTime).format(`DD-MM-YYYY HH:mm`)}
                                                        </td>
                                                        <td>
                                                            {moment(item.endTime).format(`DD-MM-YYYY HH:mm`)}
                                                        </td>
                                                        <td>
                                                            {item.property}
                                                        </td>
                                                        <td>
                                                            {item.title}
                                                        </td>
                                                        <td>
                                                            {item.description}
                                                        </td>
                                                        <td>
                                                            <button className={styles.deleteButton}
                                                                    onClick={() => handleDeleteFixtureFromCartClick(item)}>
                                                                <Tooltip className={styles.userTableTooltip}
                                                                         id={"delete-button"}/>
                                                                <TfiTrash className={styles.deleteIcon}/>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </AppTableWhite>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className={styles.checkoutFooter}>
                        <div className={styles.buttons}>
                            <button
                                className={rightsHolderCartFixtures.length === 0 ? styles.submitButtonDisabled : styles.submitButton}
                                disabled={rightsHolderCartFixtures.length === 0}
                                onClick={onSubmit}>Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})

export default Cart;
