import moment from "moment/moment";

export const validateIssueTimes = (issueStartTime: string, issueEndTime: string, isUnresolved: boolean) => {
    if (isUnresolved) {
        return true
    }
    const start = moment(issueStartTime);

    const end = moment(issueEndTime);

    const sameDay = start.isSame(end, 'day');

    const validTimeOrder = start.isBefore(end);

    return sameDay && validTimeOrder;
};
