import React, {useEffect, useState} from 'react';
import PageHeader from "../../../../components/PageHeader/PageHeader";
import styles from "./index.module.scss"
import HeaderFilters from "../AvailableContent/HeaderFilters/HeaderFilters";
import {useAppDispatch, useAppSelector} from "@hooks/hooks";
import {
    clearFavouriteFilter, getFavInit, onFavSearch, removeFavouriteItem,
    setFavouriteFilters, setFilters,
} from "@store/contentAnalysis/contentAnalysisSlice";
import FavoriteStar from "@components/FavoriteStar/FavoriteStar";
import Input from "@components/Input/Input";
import {IoIosSearch} from "react-icons/io";
import ConfirmModal from "@components/ConfirmModal/ConfirmModal";
import {useDebounce} from "@helpers/useDebounce";
import {getPickerDateFormat} from "@helpers/dateFormatters";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment/moment";
import ClientCard from "../components/ClientCard/ClientCard";
import {generateQueryString} from "./helpers/generateParams";
import {IContentAnalysisState, IFavouriteFixture} from "@store/contentAnalysis/types";
import uuid from "react-uuid";
import trash from "@img/trashWhite.svg";
import {useGetCartItems} from "@hooks/useGetCartItems";
import {IOperatorPageProps} from "../RightsHoldersPortfolio/RightsHolderPortfolio";


const FavouriteContent: React.FC<IOperatorPageProps> = ({users, operatorId, menuStructure}) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const {
        favouriteFixtures,
        favouriteFilters,
        favouriteContentInitOptions: {controls},
    } = useAppSelector(state => state.contentAnalysis);
    const {organization} = useAppSelector(state => state.userInfo)
    const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
    const [searchWord, setSearchWord] = useState("");
    const [currentCardInfo, setCurrentCardInfo] = useState<IFavouriteFixture | null>(null);
    const {id} = useParams();
    useGetCartItems(id);

    useEffect(() => {
        dispatch(getFavInit())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSearch = () => {
        const params = generateQueryString(favouriteFilters, searchWord);
        dispatch(onFavSearch(params))
    };

    useDebounce(onSearch, [searchWord, favouriteFilters], 500)

    const onSetFilters = (value: string, name: keyof IContentAnalysisState["favouriteFilters"]) => {
        dispatch(setFavouriteFilters({value, name}))
    }

    const resetFilters = () => {
        dispatch(clearFavouriteFilter())
        setSearchWord("")
    }

    const onDeleteCard = (event: React.MouseEvent, card: IFavouriteFixture) => {
        event.stopPropagation()
        setCurrentCardInfo(card)
        setIsShowConfirmModal(true)
    }

    const redirectToAvailableContent = () => {
        dispatch(setFilters({name: "startDate", value: getPickerDateFormat(moment().format())}))
        dispatch(setFilters({name: "endDate", value: getPickerDateFormat(moment().add(1, "months").format())}))

        if (id) {
            navigate(`/cms/igame-operators/${operatorId}/available-content`, {replace: true});
        } else {
            navigate("/cms/available-content", {replace: true});
        }
    }

    const findCurrentOperator = () => {
        if (operatorId) {
            const currentUser = users?.find(user => user.id === operatorId);
            if (currentUser) {
                return currentUser.name;
            }
        } else {
            return organization;
        }
    }

    const unfavouriteHandler = () => {
        if (currentCardInfo?.sportId) {
            dispatch(removeFavouriteItem(currentCardInfo?.sportId));
        }
    }

    return (
        <div>
            <PageHeader
                menuStructure={menuStructure}
                users={users}
                title={"Favourite Content"}
            />
            <div className={styles.page}>
                <div className={styles.pageContainer}>
                    <div className={styles.header}>
                        <h1 className={styles.title}>Favourite Content</h1>
                        <HeaderFilters
                            resetFilters={resetFilters}
                            filterControls={controls}
                            setFilters={onSetFilters}
                            filterValues={favouriteFilters}
                        />
                    </div>

                    <div className={styles.contentBox}>
                        <div className={styles.contentHeader}>
                            <div className={styles.title}>
                                <FavoriteStar isActive={true} fontSize={24}/>
                                <h6>Property</h6>
                            </div>
                            <div className={styles.searchBox}>
                                <div className={styles.searchIcon}>
                                    <IoIosSearch fontSize={16}/>
                                </div>
                                <Input
                                    value={searchWord}
                                    onChange={(e) => setSearchWord(e.target.value)}
                                    placeholder={"Search favourite properties"}
                                />
                            </div>
                        </div>
                        <div className={styles.cardInner}>
                            <div className={styles.cardContent}>
                                {favouriteFixtures.map(card => {
                                    return (
                                        <div key={uuid()}
                                             onClick={() => redirectToAvailableContent()}
                                             className={styles.cardItem}>
                                            <ClientCard>
                                                <div className={styles.cardTitle}>
                                                    <button onClick={(e) => onDeleteCard(e, card)}
                                                            className={styles.favStar}>
                                                        <FavoriteStar isActive={true} fontSize={24}/>
                                                    </button>
                                                    <h6>{card.sportName}</h6>
                                                </div>
                                                <div className={styles.imgBox}>
                                                    <img src={card.sportIcon} alt="Logo"/>
                                                </div>
                                            </ClientCard>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    {isShowConfirmModal
                        &&
                        <ConfirmModal
                            message={`Are you sure you want to remove ${currentCardInfo?.sportId} from ${findCurrentOperator()}'s favourites? `}
                            closeForm={() => setIsShowConfirmModal(false)}
                            action={unfavouriteHandler}
                            title="Remove From Favourites">
                            <img
                                className={styles.deleteIconBig}
                                src={trash}
                                alt="trash"/>
                        </ConfirmModal>
                    }
                </div>
            </div>
        </div>
    );
};

export default FavouriteContent;
