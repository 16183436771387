import React from 'react';
import {Control, FieldErrors} from "react-hook-form";
import {IStepFourFormData} from "../../../../UserManagementHooks/useRightHolderFormData";
import FileUploader
    from "@pages/Cms/IGameView/Settings/UserManagement/children/UserTypesForms/components/FileUploader/FileUploader";

interface IStepFourFormProps {
    control: Control,
    errors: FieldErrors,
    stepFourFormData: IStepFourFormData,
    handleFileSelection: (file: File) => void,
}

const StepFourForm: React.FC<IStepFourFormProps> = React.memo(({handleFileSelection,}) => {
    return (
        <FileUploader
            handleFileSelection={handleFileSelection}
        />
    );
});

export default StepFourForm;
