export const rightHoldersInnerPages = [
    {
        name: "Book Fixtures",
        path: "rightHolder-book-fixtures",
    },
    {
        name: "Upcoming Booked Fixtures",
        path: "rightHolder-upcoming-fixtures"
    },
    {
        name: "Content Access",
        path: "rightHolder-operator-access"
    },
    {
        name: "Property Restrictions",
        path: "right-holder-geo-blocked"
    },
    {
        name: "Operator Geo-Restrictions",
        path: "right-holder-geo-allowed"
    },
    {
        name: "Pricing",
        path: "rightHolder-pricing"
    },
]


export const operatorsInnerPages = [
    {
        name: "Booked Content",
        path: "operator-booked-content"
    },
    {
        name: "Operator Portfolio",
        path: "operator-portfolio"
    },
    {
        name: "Rights Holders Portfolio",
        path: "operator-rights-holders"
    },
    {
        name: "Available Content",
        path: "available-content"
    },
    {
        name: "Favourite Content",
        path: "favourite-content"
    }
]
