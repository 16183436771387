import {AnyAction, createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {toastr} from "react-redux-toastr";
import {client} from "../../services/api-servise";
import {AxiosError} from "axios";
import {IOperatorBillingTotalData} from "../iGameOperatorBilling/iGameOperatorBillingSlice";

export interface ICurrentRight {
    right: string,
    matchPrice: number,
    adHocPrice: number,
    numberOfFixtures: number,
    numberOfAdHocBookings: number,
    total: number
}

export interface IBillingOperator {
    operator: string,
    matchPrice: number,
    adHocPrice: number,
    numberOfFixtures: number,
    numberOfAdHocBookings: number,
    total: number,
    rightHolderRightBillingItems: ICurrentRight[]
}

export interface IBillingResponse {
    totalFixtures: number,
    streamDelivered: number,
    adHocBookings: number,
    activeOperators: number,
    rightHolderBillingItems: IBillingOperator[],
    logo: string | null
}

export interface IBillingReqBody {
    startDate: string,
    endDate: string
}


interface IRHBillingOperatorState {
    totalData: IOperatorBillingTotalData,
    operatorsData: IBillingOperator[],
    error: string,
    billingCSV: string,
    csvFileName: string,
    loading: boolean
}

const initialState: IRHBillingOperatorState = {
    totalData: {
        totalFixtures: 0,
        streamDelivered: 0,
        adHocBookings: 0,
        activeOperators: 0,
        logo: null
    },
    operatorsData: [],
    error: '',
    billingCSV: '',
    csvFileName: "",
    loading: false
}

export const getBillingOperatorData = createAsyncThunk<IBillingResponse, IBillingReqBody, { rejectValue: string }>(
    'billingRightHolder/getBillingOperatorData',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.post(`api/Billing/right-holder`, body)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getBillingOperatorCSV = createAsyncThunk<string, IBillingReqBody, { rejectValue: string }>(
    'billingRightHolder/getBillingOperatorCSV',
    async (body, {rejectWithValue, dispatch}) => {
        try {
            const {data, headers} = await client.post(`api/Billing/right-holder/download`, body)
            if (headers['content-disposition']) {
                const contentDisposition = headers['content-disposition'];
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(contentDisposition);
                const filename = matches ? matches[1].replace(/['"]/g, '') : null;
                if (filename) {
                    dispatch(setCSVFileName(filename))
                }
            }
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const rightHolderBillingOperatorSlice = createSlice({
    name: 'rightHolderBillingOperator',
    reducers: {
        setCSVFileName: (state, action: PayloadAction<string>) => {
            state.csvFileName = action.payload
        }
    },
    initialState,

    extraReducers: (builder) => {
        builder
            .addCase(getBillingOperatorData.pending, (state) => {
                state.loading = true;
            })
            .addCase(getBillingOperatorData.fulfilled, (state, action) => {
                state.loading = false;

                state.totalData.totalFixtures = action.payload.totalFixtures;
                state.totalData.streamDelivered = action.payload.streamDelivered;
                state.totalData.adHocBookings = action.payload.adHocBookings;
                state.totalData.activeOperators = action.payload.activeOperators;
                state.totalData.logo = action.payload.logo

                state.operatorsData = action.payload.rightHolderBillingItems;
            })
            .addCase(getBillingOperatorCSV.fulfilled, (state, action) => {
                state.billingCSV = action.payload;
            })
            .addMatcher(isError, (state, action: PayloadAction<string>) => {
                state.error = action.payload;
            })
    }
})
export const {setCSVFileName} = rightHolderBillingOperatorSlice.actions
export default rightHolderBillingOperatorSlice.reducer


function isError(action: AnyAction) {
    return action.type.endsWith("rejected");
}
