import {useMemo} from "react";
import {formatSelectOptions} from "../../../../../../helpers/formatSelectOptions";
import {useAppSelector} from "../../../../../../hooks/hooks";


export const useFormatSelectOptions = () => {
    const {
        rightHolders,
        technicalProviders,
        sports,
        competitions,
        deliveryMethods
    } = useAppSelector(state => state.ingestResources);

    const formatSelectRightsHolders = useMemo(() => (
        formatSelectOptions(rightHolders)
    ), [rightHolders]);

    const formatSelectTechnicalProviders = useMemo(() => (
        formatSelectOptions(technicalProviders)
    ), [technicalProviders]);

    const formatSelectSports = useMemo(() => (
        formatSelectOptions(sports)
    ), [sports]);

    const formatSelectCompetitions = useMemo(() => (
        formatSelectOptions(competitions)
    ), [competitions]);

    const formatSelectDeliveryMethods = useMemo(() => (
        formatSelectOptions(deliveryMethods)
    ), [deliveryMethods]);

    return {
        formatSelectRightsHolders,
        formatSelectCompetitions,
        formatSelectSports,
        formatSelectDeliveryMethods,
        formatSelectTechnicalProviders
    }
}
