import React, {useEffect, useState} from 'react';
import PageHeader from "../../../../../components/PageHeader/PageHeader";
import styles from "./index.module.scss";
import SelectInput from "../../../../../components/SelectInput/SelectInput";
import {
    clearBoard,
    getGeoInitOptions,
    getRestrictionsCSV,
} from "@store/geoRestrictions/geoRestrictionsSlice";
import {formatSelectOptions} from "@helpers/formatSelectOptions";
import {FaSearch} from "react-icons/fa";
import Input from "../../../../../components/Input/Input";
import DragBoard from "../components/DragBoard/DragBoard";
import {useAppDispatch, useAppSelector} from "@hooks/hooks";
import {IGeoBoard, IGeoRestrictionsProps} from "../OperatorGeoRestrictions/OperatorGeoRestrictions";
import FormatRestrictions from "../components/FormatRestrictions/FormatRestrictions";
import {useFiltersData} from "../hooks/useFiltersData";
import DownloadBlock from "../../../../../components/DownloadBlock/DownloadBlock";
import Loader from "../../../../../components/Loader/Loader";
import {useParams} from "react-router-dom";
import {useGetCartItems} from "@hooks/useGetCartItems";

const PropertyRestrictions: React.FC<IGeoRestrictionsProps> = React.memo(({menuStructure, users, rightHolderId}) => {
    const {
        rights,
        blockedTerritories,
        allTerritories,
        restrictionsCSV,
        csvFileName,
        isLoading
    } = useAppSelector(state => state.geoRestrictions);

    const {
        filtersValues,
        setFiltersValues,
        setFilterValue,
        filteredTerritories,
        setFilteredTerritories,
        onClearSearchInput,
        onConfirmGeoRestrictions,
        onCancelChanges,
        operator,
        right,
        onChangeRight,
        isShowConfirmBtn,
        setIsShowConfirmBtn,
        unconfirmedCountries,
        setUnconfirmedCountries
    } = useFiltersData(rightHolderId);

    const [boards, setBoards] = useState<IGeoBoard[]>(
        [
            {
                id: 1,
                boardName: "All Territories",
                items: allTerritories
            },
            {
                id: 3,
                boardName: "Blocked Countries",
                items: blockedTerritories
            }
        ]
    );
    const dispatch = useAppDispatch();
    const {id} = useParams();
    useGetCartItems(id);


    useEffect(() => {
        dispatch(clearBoard())
        dispatch(getGeoInitOptions(rightHolderId));
        dispatch(getRestrictionsCSV(rightHolderId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rightHolderId]);

    return (
        <>
            {isLoading && <Loader/>}
            {!isLoading &&
                <>
                    <PageHeader menuStructure={menuStructure} title={"Property Restrictions"} users={users}>
                        <DownloadBlock
                            fileName={csvFileName}
                            withoutPDF={true}
                            csvButtonName={'Current Restrictions'}
                            csvFile={restrictionsCSV}/>
                    </PageHeader>
                    <div className={styles.pageInner}>
                        <div className={styles.pageContainer}>
                            <div className={styles.titleWrapper}>
                                <h2 className={styles.title}>Property Restrictions</h2>
                                <div className={styles.controls}>
                                    {isShowConfirmBtn &&
                                        <div className={styles.controlBtnInner}>
                                            <button onClick={onConfirmGeoRestrictions}
                                                    className={styles.controlBtn}>
                                                Confirm
                                            </button>
                                            <button onClick={() => onCancelChanges(false)}
                                                    className={styles.controlBtn}>
                                                Cancel
                                            </button>
                                        </div>
                                    }

                                    <div className={styles.selectList}>
                                        <label className={styles.label}>Right
                                            <SelectInput
                                                defaultValue={null}
                                                isClearable
                                                value={right}
                                                onChange={(e) => {
                                                    if (e) {
                                                        onChangeRight(e, false)
                                                    } else {
                                                        onChangeRight(e, true)
                                                    }
                                                }}
                                                options={formatSelectOptions(rights)}
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.tablesInner}>
                                <div className={styles.countriesWrapper}>

                                    <div className={styles.titlesWrapper}>
                                        <div className={styles.tableTitleInner}>
                                            <h6 className={styles.searchTitle}>All Countries</h6>
                                            <div className={styles.searchControl}>
                                                <div className={styles.searchIcon}>
                                                    <FaSearch/>
                                                </div>
                                                {filtersValues.allTerr && <button
                                                    name={"allTerr"}
                                                    onClick={(e) => onClearSearchInput(e)}
                                                    className={styles.clearBtn}>
                                                    x
                                                </button>}
                                                <Input
                                                    value={filtersValues.allTerr}
                                                    onChange={(e) => setFilterValue(e)}
                                                    name={"allTerr"}
                                                    placeholder={"SEARCH"}
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.tableTitleInner}>
                                            <h6 className={styles.searchTitle}>Blocked Countries</h6>
                                            <div className={styles.searchControl}>
                                                <div className={styles.searchIcon}>
                                                    <FaSearch/>
                                                </div>
                                                {filtersValues.blockedTerr && <button
                                                    name={"blockedTerr"}
                                                    onClick={(e) => onClearSearchInput(e)}
                                                    className={styles.clearBtn}>
                                                    x
                                                </button>}
                                                <Input
                                                    value={filtersValues.blockedTerr}
                                                    onChange={(e) => setFilterValue(e)}
                                                    name={"blockedTerr"}
                                                    placeholder={"SEARCH"}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {allTerritories.length || blockedTerritories.length ?
                                        <DragBoard
                                            setFilteredTerritories={setFilteredTerritories}
                                            setFiltersValue={setFiltersValues}
                                            filteredTerritories={filteredTerritories}
                                            setIsShowConfirmBtn={setIsShowConfirmBtn}
                                            operator={operator}
                                            competition={right}
                                            boards={boards}
                                            setBoards={setBoards}
                                            unconfirmedCountries={unconfirmedCountries}
                                            setUnconfirmedCountries={setUnconfirmedCountries}
                                        />
                                        :
                                        null
                                    }
                                </div>

                                <FormatRestrictions
                                    right={right}/>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
});

export default PropertyRestrictions;
