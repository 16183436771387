import React, {useEffect, useMemo, useState} from 'react';
import styles from "./index.module.scss";
import {ActionMeta, MultiValue} from "react-select";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {Controller, useForm} from "react-hook-form";
import {
    createUnconfirmedAccess,
    getOperatorAccessInit,
    getRightsByOperator,
    IConfirmReqBody
} from "../../../../../store/operatorAccess/operatorAccessSlice";
import {formatSelectOptions} from "../../../../../helpers/formatSelectOptions";
import SelectInput from "../../../../../components/SelectInput/SelectInput";
import Input from "../../../../../components/Input/Input";
import Checkbox from "../../../../../components/Checkbox";
import {ISelectOption} from "../../../../../store/operatorBookedContent/operatorBookedContentSlice";


interface OperatorAccessFormProps {
    closeForm: () => void;
    rightHolderId: string | null;
}

interface IFormInput {
    operator: ISelectOption[];
    right: ISelectOption[];
    fixtures: number
}

const CreateAccessForm: React.FC<OperatorAccessFormProps> = React.memo(({closeForm, rightHolderId}) => {
    const [operator, setOperator] = useState<MultiValue<ISelectOption | undefined>>();
    const [rights, setRights] = useState<MultiValue<ISelectOption | undefined>>();
    const [isAllFixturesSelected, setIsAllFixturesSelected] = useState(false);
    const [isAllRightsSelected, setIsAllRightsSelected] = useState(false);
    const [fixtureCount, setFixtureCount] = useState<number | undefined>(undefined);
    const dispatch = useAppDispatch();
    const {operatorInpOptions, rightsInpOptions} = useAppSelector(state => state.operatorAccess)
    const {
        handleSubmit,
        control,
        setValue,
        formState: {errors},
        setError,
        clearErrors,
    } = useForm<IFormInput>();

    useEffect(() => {
        dispatch(getOperatorAccessInit(rightHolderId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const handleSetOperator = (option: MultiValue<ISelectOption>, onChange: (...event: any[]) => void) => {
        if (option) {
            const body = {
                operatorIds: option.map(operator => operator.value),
                rightHolderId
            }
            dispatch(getRightsByOperator(body))
            setOperator(option)
            onChange(option)
        }
    }

    const handleSetRight = (
        option: MultiValue<ISelectOption | undefined>,
        onChange: (...event: any[]) => void,
        actionMeta: ActionMeta<ISelectOption | undefined>
    ) => {
        if (option) {
            setRights(option);
            onChange(option);
        }

        switch (true) {
            case (actionMeta.action === 'select-option' && rights?.length === rightsInpOptions.length - 1):
                setIsAllRightsSelected(true);
                break;

            case (actionMeta.action === 'clear' || actionMeta.action === 'remove-value'):
                if (rights && rights.length === rightsInpOptions.length) {
                    setIsAllRightsSelected(false);
                }
                break;
        }
    }

    const toggleAssignedAllFixtures = () => {
        setFixtureCount(0)
        setValue("fixtures", 0)
        setIsAllFixturesSelected(!isAllFixturesSelected)
    }

    const createNewAccess = () => {
        if (operator && rights) {
            if (fixtureCount) {

                const newAccess = {
                    operatorIds: operator.map(operator => operator!.value),
                    rightIds: rights.map(right => right!.value),
                    fixturesCount: fixtureCount,
                    assignedAllFixtures: isAllFixturesSelected
                }

                const body = {
                    payload: {
                        rightHolderId,
                        operatorAccessCreateItems: [newAccess]
                    },
                    closeForm

                } as IConfirmReqBody

                dispatch(createUnconfirmedAccess(body))


            } else {
                const newAccess = {
                    operatorIds: operator.map(operator => operator!.value),
                    rightIds: rights.map(right => right!.value),
                    assignedAllFixtures: isAllFixturesSelected,
                }

                const body = {
                    payload: {
                        rightHolderId,
                        operatorAccessCreateItems: [newAccess]
                    },
                    closeForm

                } as IConfirmReqBody
                dispatch(createUnconfirmedAccess(body))
                closeForm()
            }
        }
    }

    const formatSelectOperators = useMemo(() => (
        formatSelectOptions(operatorInpOptions)
    ), [operatorInpOptions]);

    const formatSelectRights = useMemo(() => (
        formatSelectOptions(rightsInpOptions)
    ), [rightsInpOptions]);

    const handleSelectAllRights = () => {
        setIsAllRightsSelected(!isAllRightsSelected);

        if (!isAllRightsSelected) {
            setRights(formatSelectRights);
            setValue('right', formatSelectRights);
            clearErrors('right');
        } else {
            setValue('right', []);
            setRights(undefined);
            setError('right', {type: 'isSelected', message: "Right is required field"});
        }
    }

    return (
        <div className={styles.overlay}>
            <form
                data-testid={"form"}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                onSubmit={handleSubmit(createNewAccess)}
                className={styles.form}>
                <div className={styles.content}>
                    <div>
                        <h6 className={styles.titleInner}>
                            <span className={styles.title}>New Access</span>
                        </h6>
                    </div>
                    <div>
                        <label className={styles.label}>Operator
                            <Controller
                                name="operator"
                                control={control}
                                rules={{required: true}}
                                render={({field: {onChange, value: operator}}) =>
                                    <SelectInput
                                        closeMenuOnSelect={false}
                                        value={operator}
                                        onChange={(e) => handleSetOperator(e, onChange)}
                                        options={formatSelectOperators}
                                        placeholder={"Choose an option"}
                                        isMulti={true}
                                        itemsLength={operator ? operator.length : 0}
                                        maxItemsToShowExpander={8}
                                        showExpander={true}
                                    />
                                }
                            />
                        </label>
                        {errors.operator &&
                            <div className={styles.formErrorBlock} data-testid="operator-required-error">
                                Operator is required field
                            </div>
                        }
                    </div>
                    <div>
                        <div className={styles.label} data-testid={'rights-select'}>
                            <div className={styles.checkboxInner}>
                                <p>Right(s)</p>
                                <div className={styles.checkbox} data-testid={'rights-checkbox'}>
                                    <span>All Rights</span>
                                    <Checkbox
                                        checkboxId="rights"
                                        isChecked={isAllRightsSelected}
                                        onChangeHandler={() => handleSelectAllRights()}
                                    />
                                </div>
                            </div>
                            <Controller
                                name="right"
                                control={control}
                                rules={{
                                    validate: {
                                        isSelected: (value) => {
                                            // eslint-disable-next-line no-mixed-operators
                                            return isAllRightsSelected || !!value && value.length > 0;
                                        }
                                    }
                                }}
                                render={({field: {onChange, value}}) =>
                                    <SelectInput
                                        aria-label={"Rights"}
                                        closeMenuOnSelect={false}
                                        value={value || rights}
                                        onChange={(e, actionMeta) => handleSetRight(e, onChange, actionMeta)}
                                        options={formatSelectRights}
                                        placeholder={"Choose an option"}
                                        isMulti={true}
                                        itemsLength={rights ? rights.length : 0}
                                        maxItemsToShowExpander={8}
                                        showExpander={true}
                                    />}
                            />
                        </div>
                        {errors?.right?.type === "isSelected" &&
                            <div className={styles.formErrorBlock}>Right is required field</div>
                        }
                    </div>
                    <div>
                        <div className={styles.label}>
                            <div className={styles.checkboxInner}>
                                <p>Fixture</p>
                                <div className={styles.checkbox}>
                                    <span>All Fixtures</span>
                                    <Checkbox
                                        checkboxId="fixtures"
                                        isChecked={isAllFixturesSelected}
                                        onChangeHandler={() => toggleAssignedAllFixtures()}
                                    />
                                </div>
                            </div>
                            <Controller
                                control={control}
                                name="fixtures"
                                rules={{
                                    validate: {
                                        isPositive: value => Number(value) > 0 || Number(value) === 0,
                                        // eslint-disable-next-line no-mixed-operators
                                        isAllSelected: (value) => (isAllFixturesSelected) && Number(value) === 0 || Number(value) > 0
                                    }
                                }}
                                render={({field: {onChange}}) => (
                                    <Input
                                        data-testid={"fixtureCount"}
                                        onChange={(e) => {
                                            onChange(e);
                                            setFixtureCount(Number(e.target.value))
                                        }}
                                        disabled={isAllFixturesSelected}
                                        value={fixtureCount ? fixtureCount : ''}
                                        type="number"
                                        placeholder={"Number of Fixtures"}
                                    />
                                )}
                            />
                            {errors?.fixtures?.type === "isPositive" && !isAllFixturesSelected &&
                                <div className={styles.formErrorBlock}>
                                    Set positive number of fixtures or use "All fixtures"
                                </div>
                            }
                            {errors?.fixtures?.type === "isAllSelected" && !isAllFixturesSelected &&
                                <div className={styles.formErrorBlock}>
                                    Select "All Fixture" or set count of fixture
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.buttons}>
                    <button
                        onClick={closeForm}
                        className={styles.cancelButton}
                        type="button">Cancel
                    </button>
                    <button
                        data-testid={"submit"}
                        className={styles.submitButton}
                        type="submit">Create
                    </button>
                </div>
            </form>
        </div>
    );
});

export default CreateAccessForm;
CreateAccessForm.displayName = "CreateAccessForm"
