import React, {Dispatch, SetStateAction} from 'react';
import styles from "./index.module.scss";
import {BsCheckCircle, BsFillCircleFill} from "react-icons/bs";
import {FieldValues, UseFormHandleSubmit} from "react-hook-form";

export interface IFormRouting {
    currentTab: string,
    setCurrentTab: (arg: string) => void,
    totalSteps: number,
    completedSteps: number[]
    setCompletedSteps: Dispatch<SetStateAction<number[]>>
    handleSubmit: UseFormHandleSubmit<FieldValues>
    submitFormHandler: () => void
}

const FormRouting: React.FC<IFormRouting> = React.memo((
    {
        currentTab,
        totalSteps,
        setCurrentTab,
        completedSteps,
        setCompletedSteps,
        handleSubmit,
        submitFormHandler

    }) => {

    const currentTabStyle = (currentTab: string, tabToStyle: string) => {
        if (currentTab === tabToStyle || tabToStyle < currentTab) {
            return styles.activeTab
        } else {
            return
        }
    }

    const onChangeStep = (e: React.MouseEvent, tab: string, stepNumber: number) => {
        if (currentTab === tab) {
            e.preventDefault()
            return
        }
        const isCompletedPreviousStep = completedSteps.includes(stepNumber ? stepNumber - 1 : stepNumber)

        if (completedSteps.includes(stepNumber) && isCompletedPreviousStep) {
            setCurrentTab(tab)
            const allCompletedStep = completedSteps.filter(currentStep => currentStep !== stepNumber)
            return setCompletedSteps(allCompletedStep)
        }

        if (isCompletedPreviousStep) {
            return setCurrentTab(tab)
        }
        return handleSubmit(submitFormHandler)
    }

    return (
        <div className={styles.formLinksInner}>
            <button
                type={"submit"}
                onClick={(e) => onChangeStep(e, 'Step 1', 0)}
                className={styles.stepBtn}
            >
                <div className={`${styles.stepIndicator} ${currentTabStyle(currentTab, 'Step 1')}`}>
                    {currentTab === 'Step 1' ? <BsFillCircleFill/> : <BsCheckCircle/>}
                    <div className={styles.stepLine}></div>
                </div>
                <div className={`${styles.stepText} ${currentTabStyle(currentTab, 'Step 1')}`}>
                    <p> Step 1 <br/>
                        <span>Main</span></p>
                </div>
            </button>
            <button
                type={"submit"}
                onClick={(e) => onChangeStep(e, 'Step 2', 1)}
                className={styles.stepBtn}
            >
                <div className={`${styles.stepIndicator} ${currentTabStyle(currentTab, 'Step 2')}`}>
                    {currentTab === 'Step 1' || currentTab === 'Step 2' ? <BsFillCircleFill/> : <BsCheckCircle/>}
                    <div className={styles.stepLine}></div>
                </div>
                <div className={`${styles.stepText} ${currentTabStyle(currentTab, 'Step 2')}`}>
                    <p> Step 2 <br/>
                        <span>Access</span></p>
                </div>
            </button>
            {totalSteps === 4 &&
                <>
                    <button
                        type={"submit"}
                        onClick={(e) => onChangeStep(e, 'Step 3', 2)}
                        className={styles.stepBtn}
                    >
                        <div className={`${styles.stepIndicator} ${currentTabStyle(currentTab, 'Step 3')}`}>
                            {currentTab === 'Step 1' || currentTab === 'Step 2' || currentTab === 'Step 3' ?
                                <BsFillCircleFill/> : <BsCheckCircle/>}
                            <div className={styles.stepLine}></div>
                        </div>
                        <div className={`${styles.stepText} ${currentTabStyle(currentTab, 'Step 3')}`}>
                            <p> Step 3 <br/>
                                <span>Contract</span></p>
                        </div>
                    </button>
                    <button
                        type={"submit"}
                        onClick={(e) => onChangeStep(e, 'Step 4', 4)}
                        className={styles.stepBtn}
                    >
                        <div className={`${styles.stepIndicator} ${currentTabStyle(currentTab, 'Step 4')}`}>
                            <BsFillCircleFill/>
                        </div>
                        <div className={`${styles.stepText} ${currentTabStyle(currentTab, 'Step 4')}`}>
                            <p> Step 4 <br/>
                                <span>Logo</span></p>
                        </div>
                    </button>
                </>
            }

            {totalSteps === 3 &&
                <button
                    type={"submit"}
                    onClick={(e) => onChangeStep(e, 'Step 3', 3)}
                    className={styles.stepBtn}
                >
                    <div className={`${styles.stepIndicator} ${currentTabStyle(currentTab, 'Step 3')}`}>
                        <BsFillCircleFill/>
                    </div>
                    <div className={`${styles.stepText} ${currentTabStyle(currentTab, 'Step 3')}`}>
                        <p> Step 3 <br/>
                            <span>Logo</span></p>
                    </div>
                </button>
            }
        </div>
    );
});

FormRouting.displayName = "FormRouting"
export default FormRouting;