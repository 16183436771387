import React from 'react';
import {DtPicker} from "react-calendar-datetime-picker";
import './index.scss';
import styles from './index.module.scss';
import calendar from "../../img/calendar.svg";
import {IRangeDate} from "../../pages/Cms/OperatorView/ConcurrencyInspector/ConcurrencyTable/ConcurrencyTable";

export interface IRangePickerProps {
    onChange: (date: IRangeDate) => void;
    initValue?: IRangeDate;
    inputClass?: string;
    isCalendarModalDown?: boolean
}

const RangePicker: React.FunctionComponent<IRangePickerProps> = React.memo((props) => {

    return (
        <div className={styles.rangePicker}>Date Range
            <DtPicker
                {...props}
                type='range'
                placeholder={"Select date..."}
                calenderModalClass={props.isCalendarModalDown ? "calendar-modal-down" : "calendar-modal-up"}
                inputClass={props.inputClass ? `range-input ${props.inputClass}` : "range-input"}
                headerClass="calendar-header"
                daysClass="calendar-day"
                showWeekend
            />
            <img src={calendar} alt="time"/>
        </div>
    );
})

RangePicker.displayName = "RangePicker"
export default RangePicker;
