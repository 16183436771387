import {ChangeEvent, useState} from "react";
import {SingleValue} from "react-select";
import {ISelectItem, IUserPages} from "../../../../../../store/userManagement/userManagementSlice";
import {IDate, IDropdownOption} from "../../../../../../components/AnalyticsDashboard/types";
import {formatContractDate} from "../../../../../../helpers/dateFormatters";

export interface IStepFourFormData {
    logo: null | File;
}

export interface IStepThreeFormData {
    allowancePerOperator: number | undefined,
    overageRatePerGB: number | undefined,
    ingestRatePerHour: number | undefined,
    playerRatePerView: number | undefined,
    operatorRatePerMonth: number | undefined
    contractStartDate: string | undefined
    isBandwidthEnabled: boolean,
    isIngestEnabled: boolean,
    isPlayerUsageEnabled: boolean,
    isOperatorEnabled: boolean
}

export interface IStepTwoFormData {
    pricing: number | null,
    rolePages: IUserPages[]
}

export interface IStepOneFormData {
    name: string,
    dataProviderId: IDropdownOption | undefined,
    address: string,
    clientAdmin: string,
    adminEmail: string,
    commercialContact: string,
    commercialEmail: string,
    schedulingContact: string,
    schedulingEmail: string,
    liveIssuesContact: string,
    liveIssuesContactEmail: string
}

export interface IStepThreeCheckboxes {
    isBandwidthEnabled: false,
    isIngestEnabled: false,
    isOperatorEnabled: false,
    isPlayerUsageEnabled: false
}

export const useRightHolderFormData = () => {
    const [stepOneFormData, setStepOneFormData] = useState<IStepOneFormData>({
        name: '',
        dataProviderId: undefined,
        address: '',
        clientAdmin: '',
        adminEmail: '',
        commercialContact: '',
        commercialEmail: '',
        schedulingContact: '',
        schedulingEmail: '',
        liveIssuesContact: '',
        liveIssuesContactEmail: ''
    });
    const [stepTwoFormData, setStepTwoFormData] = useState<IStepTwoFormData>({
        pricing: 0,
        rolePages: []
    });
    const [stepThreeFormData, setStepThreeFormData] = useState<IStepThreeFormData>({
        allowancePerOperator: undefined,
        overageRatePerGB: undefined,
        ingestRatePerHour: undefined,
        playerRatePerView: undefined,
        operatorRatePerMonth: undefined,
        contractStartDate: undefined,
        isBandwidthEnabled: false,
        isIngestEnabled: false,
        isOperatorEnabled: false,
        isPlayerUsageEnabled: false
    });
    const [stepFourFormData, setStepFourFormData] = useState<IStepFourFormData>({
        logo: null
    });

    const onChangeStepOne = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        setStepOneFormData({...stepOneFormData, [event.target.name]: event.target.value});
    }

    const handlePricing = (buttonId: number, onChange: (...event: any[]) => void) => {
        setStepTwoFormData({...stepTwoFormData, pricing: buttonId});
        onChange(buttonId);
    }

    const onChangeStepThree = (event: ChangeEvent<HTMLInputElement>) => {
        setStepThreeFormData({...stepThreeFormData, [event.target.name]: +event.target.value});
    }

    const onChangeStepThreeCheckboxes = (name: keyof IStepThreeCheckboxes) => {
        setStepThreeFormData({...stepThreeFormData, [name]: !stepThreeFormData[name]});
    }

    const onChangeContractDateForStepThree = (date: IDate) => {
        if (date) {
            setStepThreeFormData({...stepThreeFormData, contractStartDate: formatContractDate(date)});
        }
    }

    const handleFileSelection = (logo: File) => {
        setStepFourFormData({logo});
    }

    const handleProviderId = (option: SingleValue<IDropdownOption>, onChange: (...event: any[]) => void) => {
        if (option) {
            setStepOneFormData({...stepOneFormData, dataProviderId: option});
            onChange(option);
        }
    }

    const selectAllPages = (pageVisibilities: ISelectItem[], roleId: string) => {
        const allRightHolderPagesIds = pageVisibilities.map(page => +page.id);
        const presentRoleIds = stepTwoFormData.rolePages.map(roleItem => roleItem.roleId);
        let rightHolderPages: IUserPages[] = [];

        if (presentRoleIds.includes(roleId)) {
            rightHolderPages = stepTwoFormData.rolePages.map(rolePagesItem => {
                if (rolePagesItem.roleId === roleId) {
                    rolePagesItem = {
                        roleId: rolePagesItem.roleId,
                        pageIds: allRightHolderPagesIds
                    }
                }
                return rolePagesItem
            })
        } else {
            rightHolderPages = [
                ...stepTwoFormData.rolePages,
                {
                    roleId,
                    pageIds: allRightHolderPagesIds
                }
            ]
        }
        setStepTwoFormData({...stepTwoFormData, rolePages: rightHolderPages});
    }

    const deselectAllPages = (roleId: string) => {
        const pages = stepTwoFormData.rolePages.map(rolePagesItem => {
            if (rolePagesItem.roleId === roleId) {
                rolePagesItem = {
                    roleId: rolePagesItem.roleId,
                    pageIds: []
                }
            }
            return rolePagesItem
        })
        setStepTwoFormData({...stepTwoFormData, rolePages: pages});
    }

    const checkboxHandler = (checkboxId: number, roleId: string) => {
        let currentRolePages = stepTwoFormData.rolePages.find(rolePagesItem => rolePagesItem.roleId === roleId)

        if (currentRolePages && currentRolePages.pageIds.includes(checkboxId)) {
            const filteredPagesIds = currentRolePages.pageIds.filter(pageId => pageId !== checkboxId);

            const pages = stepTwoFormData.rolePages.map(rolePagesItem => {
                if (rolePagesItem.roleId === roleId) {
                    rolePagesItem = {
                        roleId: rolePagesItem.roleId,
                        pageIds: filteredPagesIds
                    }
                }
                return rolePagesItem
            })
            setStepTwoFormData({...stepTwoFormData, rolePages: pages});
        } else if (currentRolePages) {
            const pages = stepTwoFormData.rolePages.map(rolePagesItem => {
                if (rolePagesItem.roleId === roleId) {
                    rolePagesItem = {
                        roleId: rolePagesItem.roleId,
                        pageIds: [...rolePagesItem.pageIds, checkboxId]
                    }
                }
                return rolePagesItem
            })
            setStepTwoFormData({...stepTwoFormData, rolePages: pages});
        } else {
            const rolePagesItem = {
                roleId,
                pageIds: [checkboxId]
            }
            const pages = [...stepTwoFormData.rolePages, rolePagesItem];
            setStepTwoFormData({...stepTwoFormData, rolePages: pages});
        }
    }

    return {
        stepOneFormData,
        stepTwoFormData,
        stepThreeFormData,
        stepFourFormData,
        setStepOneFormData,
        setStepTwoFormData,
        setStepThreeFormData,
        setStepFourFormData,
        handleProviderId,
        onChangeStepOne,
        handlePricing,
        onChangeStepThree,
        handleFileSelection,
        checkboxHandler,
        selectAllPages,
        deselectAllPages,
        onChangeContractDateForStepThree,
        onChangeStepThreeCheckboxes
    }
}
