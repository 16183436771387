export enum DateTypes {
    TimeOfDay = "byHour",
    Day = "byDay",
    Week = "byWeek",
    Month = "byMonth",
    Year = "byYear",
    Weekday = "byWeekDay",
}

export enum ViewsByOptions {
    Sport = "bySport",
    Property = "byCompetition",
    Provider = "byProvider",
    Operator = "byCustomer",
    Fixture = "byFixture",
}
