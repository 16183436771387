import React, {useEffect, useState} from "react";
import {Routes} from "react-router";
import uuid from "react-uuid";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {useParams} from "react-router-dom";
import {getAllUsers, IUserItem} from "../../../../store/iGameInit/iGameInitSlice";
import {ISidebarItem} from "../../CmsSideBarData/CmsSideBarData";
import {formatAllowedPages} from "../../AppRoutes/helpers/formatAllowedPages";
import {mapAllowedRoutes} from "../../AppRoutes/mapAllowedRoutes";
import {getAllowedPages} from "../../AppRoutes/getAllowedPages";
import {getAllowedRoutes} from "../../AppRoutes/getAllowedRoutes";
import {routeHandler} from "../../AppRoutes/routes/routeHandler";
import {operatorsInnerPages} from "../../AppRoutes/routes/helpers/usersTypesInnerPages";
import {Group} from "../../../../enums/group";
import {setCurrentIgameUserGroup} from "../../../../store/userInfo/userInfoSlice";

const OperatorPage: React.FunctionComponent = () => {
    let {id} = useParams();
    const dispatch = useAppDispatch();
    const {users} = useAppSelector(state => state.iGameInit);
    const {pages, currentIgameUserGroup} = useAppSelector(state => state.userInfo);
    const [dropdownDataSet, setDropdownDataSet] = useState<ISidebarItem[] | null>(null);
    const [operator, setOperator] = useState<IUserItem | undefined>();

    useEffect(() => {
        dispatch(getAllUsers(null));

        if (currentIgameUserGroup !== Group.Operator) {
            dispatch(setCurrentIgameUserGroup(Group.Operator))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const currentOperator = users.operators.find((x: IUserItem) => x.id === id);
        if (currentOperator !== null) {
            setOperator(currentOperator);
        }
    }, [users, id]);

    useEffect(() => {
        let menuStructure: ISidebarItem | null = null;

        if (operator) {
            menuStructure = {
                title: operator.name,
                menuItemId: uuid(),
                pageName: null,
                children: operatorsInnerPages.map((x, index) => ({
                    title: x.name,
                    menuItemId: uuid(),
                    path: `/cms/igame-operators/${operator.id}/${x.path}`,
                    pageName: x.path,
                    pageNumber: index + 1
                })),
                path: `/cms/igame-operators/${operator.id}`
            };
        }

        if (menuStructure) {
            const pageRoutes = formatAllowedPages([...pages, 31, 33, 34]);
            const allowedPages = getAllowedPages(pageRoutes, [menuStructure]);

            setDropdownDataSet(allowedPages);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operator]);

    return operator && dropdownDataSet && id
        ?
        <Routes>
            {mapAllowedRoutes(getAllowedRoutes(routeHandler({
                operatorId: id,
                operatorUsers: users.operators,
                dropdownDataSet,
            }), formatAllowedPages([...pages, 31, 33, 34])))}
        </Routes>
        :
        null
}

OperatorPage.displayName = "OperatorPage"
export default OperatorPage;
