import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

export const useBookingDetailsFromQueryString = () => {
    const [recordData, setRecordData] = useState<{
        competition: string | null,
        technicalProvider: string | null,
        title: string | null,
        startTime: string | null,
    } | null>({
        competition: null,
        technicalProvider: null,
        title: null,
        startTime: null
    });

    const query = new URLSearchParams(useLocation().search);
    const technicalProvider = query.get("technicalProvider");
    const competition = query.get("competition");
    const title = query.get("title");
    const startTime = query.get("startTime");

    useEffect(() => {
        if (technicalProvider || competition || title || startTime) {
            setRecordData({technicalProvider, competition, title, startTime})
        }
    }, [technicalProvider, competition, title, startTime]);

    return {setRecordData, recordData}
}
