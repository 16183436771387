import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import styles from "./index.module.scss";
import UserForm from "./children/UserForm/UserForm";
import {useAppDispatch, useAppSelector} from "../../../../../../../hooks/hooks";
import {getRoles} from "../../../../../../../store/settings/settingsSlice";
import {
    clearCSVFile, getOperatorUsers,
    getRightHolderUsers, searchOperatorUsers, searchRightHolderUsers
} from "../../../../../../../store/userManagement/userManagementSlice";
import Loader from "../../../../../../../components/Loader/Loader";
import {useNavigate, useParams} from "react-router-dom";
import {useDebounce} from "../../../../../../../helpers/useDebounce";
import {PageTabs} from "../../UserManagement";
import {createUsersQueriesString} from "./helpers/createUsersQueriesString";
import {InnerRole} from "../../../../../../../enums/innerRole";
import {useDisableScroll} from "../../../../../../../hooks/useDisableScroll";
import SearchByTextInput from "../../../../../../../components/SearchByTextInput/SearchByTextInput";
import {SlArrowLeft} from "react-icons/sl";
import UsersTable from "./children/UsersTable/UsersTable";
import AddUserButton from "../AddUserButton/AddUserButton";

interface IUserTypesTableProps {
    setIsIGameSubHeaderVisible: (arg: boolean) => void;
    pdfLayout: React.RefObject<HTMLDivElement>;
    setCurrentTab: Dispatch<SetStateAction<string>>;
    currentTab: string;
    isDownloadingPDF: boolean;
    setUserIdInTab: Dispatch<SetStateAction<string | undefined>>;
}

const UserTypesTable: React.FC<IUserTypesTableProps> = React.memo((
    {
        setIsIGameSubHeaderVisible,
        pdfLayout,
        setCurrentTab,
        currentTab,
        isDownloadingPDF,
        setUserIdInTab
    }) => {
    const [isOpenNewForm, setIsOpenNewForm] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>("");
    const {role, specificRole} = useAppSelector(state => state.userInfo);
    const {users, clientName, loading} = useAppSelector(state => state.userManagement);
    const {id} = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    useDisableScroll(isOpenNewForm);

    useEffect(() => {
        setIsIGameSubHeaderVisible(false);
        dispatch(getRoles(null));

        if (id) {
            setUserIdInTab(id)
        }

        if (id && currentTab === PageTabs.RIGHTS_HOLDERS) {
            dispatch(getRightHolderUsers(id));
        } else if (id && currentTab === PageTabs.OPERATORS) {
            dispatch(getOperatorUsers(id));
        }

        return () => {
            setIsIGameSubHeaderVisible(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sortUsers = (field: string, isAscending: boolean) => {
        const payload = createUsersQueriesString(
            field,
            isAscending,
            id,
            searchValue
        )

        if (currentTab === PageTabs.RIGHTS_HOLDERS) {
            dispatch(searchRightHolderUsers(payload));
        } else if (currentTab === PageTabs.OPERATORS) {
            dispatch(searchOperatorUsers(payload));
        }
    }

    const searchRequest = () => {
        if (searchValue !== undefined) {
            const payload = createUsersQueriesString(
                null,
                null,
                id,
                searchValue
            )

            if (currentTab === PageTabs.RIGHTS_HOLDERS) {
                dispatch(searchRightHolderUsers(payload));
            } else if (currentTab === PageTabs.OPERATORS) {
                dispatch(searchOperatorUsers(payload));
            }
        }
    }

    useDebounce(searchRequest, [searchValue], 500);

    return (
        <div className={styles.pageWrapper}>
            {isOpenNewForm &&
                <UserForm
                    closeForm={() => setIsOpenNewForm(false)}
                    userType={currentTab}
                    clearSearchingValue={() => setSearchValue("")}
                />
            }

            {loading && <Loader/>}

            {!loading && <>
                <div className={styles.pageContainer}>
                    <h2 className={styles.title}>User Management: {clientName}</h2>

                    {role === PageTabs.I_GAME_USERS &&
                        <button className={styles.backButton}
                                onClick={() => {
                                    setCurrentTab("")
                                    dispatch(clearCSVFile())
                                    setUserIdInTab('')
                                    navigate(-1)
                                }}>
                            <SlArrowLeft/>
                            Back
                        </button>
                    }
                </div>

                <div className={styles.tableOverlay}>
                    <div className={styles.searchInner}>
                        <div className={styles.searchInput}>
                            <SearchByTextInput
                                value={searchValue}
                                onChange={setSearchValue}
                            />
                        </div>
                    </div>
                    <div className={specificRole !== InnerRole.SuperAdmin ? styles.displayNone : styles.addBtnInner}>
                        <AddUserButton
                            buttonName={`user`}
                            onClickHandler={() => setIsOpenNewForm(true)}
                        />
                    </div>

                    <UsersTable
                        pdfLayout={pdfLayout}
                        sortUsers={sortUsers}
                        users={users}
                        userRole={currentTab}
                        isDownloadingPDF={isDownloadingPDF}
                        isShort={true}
                    />
                </div>
            </>
            }
        </div>
    );
});

UserTypesTable.displayName = "UserTypesTable"
export default UserTypesTable;
