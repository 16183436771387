import {ChangeEvent, useState} from "react";
import {SingleValue} from "react-select";
import {
    checkIsCustomerAvailable,
    ISelectItem,
    IUserPages,
    IUserRole
} from "../../../../../../store/userManagement/userManagementSlice";
import {IDropdownOption} from "../../../../../../components/AnalyticsDashboard/types";
import {useAppDispatch} from "../../../../../../hooks/hooks";

export interface IStepThreeFormData {
    logo: null | File;
}

export interface IStepTwoFormData {
    rightHolders: (ISelectItem | undefined)[] | (IUserRole | undefined)[],
    rolePages: IUserPages[]
}

export interface IStepOneFormData {
    name: string,
    customerId: SingleValue<IDropdownOption> | undefined,
    clientAdmin: string,
    adminEmail: string,
    commercialContact: string,
    commercialEmail: string,
    scheduleContact: string,
    scheduleContactEmail: string,
    liveIssuesContact: string,
    liveIussesContactEmail: string,
    territoryIdes: (ISelectItem | undefined)[] | (IUserRole | undefined)[]
}

export const useOperatorFormData = () => {
    const [stepOneFormData, setStepOneFormData] = useState<IStepOneFormData>({
        name: '',
        customerId: undefined,
        clientAdmin: '',
        adminEmail: '',
        commercialContact: '',
        commercialEmail: '',
        scheduleContact: '',
        scheduleContactEmail: '',
        liveIssuesContact: '',
        liveIussesContactEmail: '',
        territoryIdes: []
    });

    const [stepTwoFormData, setStepTwoFormData] = useState<IStepTwoFormData>({
        rightHolders: [],
        rolePages: []
    });

    const [stepThreeFormData, setStepThreeFormData] = useState<IStepThreeFormData>({
        logo: null
    });

    const dispatch = useAppDispatch();

    const onChangeStepOneHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setStepOneFormData({...stepOneFormData, [event.target.name]: event.target.value});
    }

    const handleFileSelection = (logo: File) => {
        setStepThreeFormData({logo});
    }

    const selectAllPages = (pageVisibilities: ISelectItem[], roleId: string) => {
        const allOperatorPageIds = pageVisibilities.map(page => +page.id);
        const presentRoleIds = stepTwoFormData.rolePages.map(roleItem => roleItem.roleId);
        let operatorPages: IUserPages[] = [];

        if (presentRoleIds.includes(roleId)) {
            operatorPages = stepTwoFormData.rolePages.map(rolePagesItem => {
                if (rolePagesItem.roleId === roleId) {
                    rolePagesItem = {
                        roleId: rolePagesItem.roleId,
                        pageIds: allOperatorPageIds
                    }
                }
                return rolePagesItem
            })
        } else {
            operatorPages = [
                ...stepTwoFormData.rolePages,
                {
                    roleId,
                    pageIds: allOperatorPageIds
                }
            ]
        }
        setStepTwoFormData({...stepTwoFormData, rolePages: operatorPages});
    }

    const deselectAllPages = (roleId: string) => {
        const pages = stepTwoFormData.rolePages.map(rolePagesItem => {
            if (rolePagesItem.roleId === roleId) {
                rolePagesItem = {
                    roleId: rolePagesItem.roleId,
                    pageIds: []
                }
            }
            return rolePagesItem
        })
        setStepTwoFormData({...stepTwoFormData, rolePages: pages});
    }

    const checkboxHandler = (checkboxId: number, roleId: string) => {
        const currentRolePages = stepTwoFormData.rolePages.find(rolePagesItem => rolePagesItem.roleId === roleId)

        if (currentRolePages && currentRolePages.pageIds.includes(checkboxId)) {
            const filteredPagesIds = currentRolePages.pageIds.filter(pageId => pageId !== checkboxId);

            const pages = stepTwoFormData.rolePages.map(rolePagesItem => {
                if (rolePagesItem.roleId === roleId) {
                    rolePagesItem = {
                        roleId: rolePagesItem.roleId,
                        pageIds: filteredPagesIds
                    }
                }
                return rolePagesItem
            })

            setStepTwoFormData({...stepTwoFormData, rolePages: pages});
        } else if (currentRolePages) {
            const pages = stepTwoFormData.rolePages.map(rolePagesItem => {
                if (rolePagesItem.roleId === roleId) {
                    rolePagesItem = {
                        roleId: rolePagesItem.roleId,
                        pageIds: [...rolePagesItem.pageIds, checkboxId]
                    }
                }
                return rolePagesItem
            })

            setStepTwoFormData({...stepTwoFormData, rolePages: pages});
        } else {
            const rolePagesItem = {
                roleId,
                pageIds: [checkboxId]
            }
            const newData = [...stepTwoFormData.rolePages, rolePagesItem];
            setStepTwoFormData({...stepTwoFormData, rolePages: newData});
        }
    }

    const handleSelectData = (selectName: string, onChange: any) => (selectedData: ISelectItem[]) => {
        onChange(selectedData)

        if (selectName === 'territoryIdes') {
            setStepOneFormData({...stepOneFormData, [selectName]: selectedData});
        } else if (selectName === 'rightHolders') {
            setStepTwoFormData({...stepTwoFormData, [selectName]: selectedData});
        }
    }

    const handleCustomerId = (option: SingleValue<IDropdownOption>, onChange: (...event: any[]) => void) => {
        onChange(option);

        dispatch(checkIsCustomerAvailable({
            customer: option,
            setInFormData: setStepOneFormData,
            stepOneFormData
        }))
    }

    return {
        stepOneFormData,
        stepTwoFormData,
        stepThreeFormData,
        setStepOneFormData,
        setStepTwoFormData,
        onChangeStepOneHandler,
        handleSelectData,
        handleCustomerId,
        checkboxHandler,
        selectAllPages,
        deselectAllPages,
        setStepThreeFormData,
        handleFileSelection
    }
}
