import {AnyAction, createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {toastr} from "react-redux-toastr";
import {client} from "@services/api-servise";
import {IngestOptionId} from "@enums/ingest";
import {AxiosError} from "axios";
import {IListItem} from "../init/initSlice";
import {appendDataHelper, prependDataHelper, updateIngestState} from "@helpers/reducerHelpers";

export interface ICheckedIp {
    isResourceInUse: boolean,
    concurrentFixture: {
        title: string,
        startTime: string,
        property: string
    } | null,
    selectedFixture: {
        title: string,
        startTime: string,
        property: string
    } | null
}

export interface ICheckIpPayload {
    body: {
        rightHolderId?: string,
        fixtureId: string,
        ingestResourceId: string
    },
    onSubmitIP: () => void
}

export interface IAssignContentSupplierReq {
    payload: {
        fixtureId: string;
        technicalProviderId: string;
    },
}

interface IIngestTypeDetails {
    details: string,
    ingestType: string
}

export interface IFixtureResponse {
    awayRegionId: string;
    competition: string;
    competitionId: string;
    contentSupplier: string;
    hasMultipleContentSuppliers: boolean;
    description: string;
    endDate: string;
    fixtureStatus: number;
    homeRegionId: string;
    id: string;
    ingestTimeMinutes: number;
    ingestType: number;
    backupIngestType: number
    rightHolderId: string;
    sport: string;
    sportId: string;
    startDate: string;
    title: string;
    rightHolderName: string;
    extraTime: boolean;

    [key: string]: any;

    geoBlockCountries: string[];
    ingestTypeDetails: IIngestTypeDetails | null;
    isHomeAwayNationsGeoBlocked: boolean
}

export interface IIngestBackUpResponse {
    ingestType: number;
    fixtureId: string;
    satelliteFixture: {
        fixtureId: string;
        ingestResourceId: string;
        startDate: string;
        endDate: string;
        satelliteId: string;
        bissCode: string;
        bissType: number;
        nS3_NS4: boolean;
        symbolRate: number;
        downloadFrequency: number;
        downloadPolarity: string;
        audio: string;
        fileUrl: string;
        fileName: string;
        "filesUrls": [
            "string"
        ];
    };
    btTowerFixture: {
        fixtureId: string;
        ingestResourceId: string;
        source: string;
        "filesUrls": [
            "string"
        ];
    };
    ipFixture: {
        fixtureId: string;
        ingestResourceId: string;
    };
}

export interface IFixturePayload {
    fixture: {
        title: string;
        description: string;
        competitionId: string;
        homeRegionId: string;
        awayRegionId: string;
        startDate: string;
        endDate: string;
        time?: string;
        geoBlockCountries: string[];
        extraTime: boolean;
        isHomeAwayNationsGeoBlocked: boolean;
    };
    closeForm: (() => void) | null;
    getSortedFixturesPayload?: IGetDataPayload;
}

export interface IUpdateFixturePayload extends IFixturePayload {
    id: string;
    ingestType: number;
}

export interface IGetDataPayload {
    payload: IGetFixturesPayload;
    scrollPosition?: string;
}

export interface IGetFixturesPayload {
    rightHolderId?: string | null;
    searchText: string;
    sorting: {
        property: string;
        isAscending: boolean;
    };
    sportIds: string[];
    upcoming: boolean;
    pageNumber: number;
    itemsPerPage: number;
}

export interface IGetCsvPayload {
    rightHolderId: string | null;
    searchText: string;
    sorting: {
        property: string;
        isAscending: boolean;
    };
    upcoming: boolean;
}

export interface IBookFixturesPayload {
    rightHolderId: string | null;
    fixturesIds: string[];
}

export interface ICreateBtTowerFixtureData {
    fixtureId: string | undefined;
    source: string;
    ingestResourceId?: string;
    filesUrls?: string[];
}


export interface ICreateBtTowerIngestPayload {
    fixture: ICreateBtTowerFixtureData;
    filesRequestBody?: IFilesPayload | null;
}

export interface IUpdateBtTowerIngestPayload {
    payload: {
        fixtureId: string;
        fixture: ICreateBtTowerFixtureData
    },
    filesRequestBody?: IFilesPayload | null;
}

export interface ICreateIpFixtureData {
    fixtureId: string;
    ingestResourceId: string | null;
}

export interface ICreateIpIngestPayload {
    fixture: ICreateIpFixtureData;
}

export interface IUpdateIpIngestPayload {
    fixtureId: string;
    fixture: ICreateIpFixtureData
}

export interface ICreateSatelliteIngestData {
    fixtureId: string;
    startDate?: string | null
    endDate?: string | null;
    satelliteId?: string | null;
    bissCode?: string;
    symbolRate?: number | null;
    downloadFrequency?: number | null;
    downloadPolarity?: string;
    audio?: string;
    fileUrl: string;
    bissType: number,
    nS3_NS4?: boolean | undefined,
    fileName?: string | undefined
}

export interface IFilesPayload {
    fixtureId: string;
    filesData: FormData
}

export interface ICreateSatelliteIngestPayload {
    fixture: ICreateSatelliteIngestData,
    filesRequestBody: IFilesPayload | null
}

export interface IUpdateSatelliteIngestPayload {
    payload: {
        fixture: ICreateSatelliteIngestData,
        fixtureId: string,
    }
    filesRequestBody: IFilesPayload | null
}

export interface ICancelBookingPayload {
    rightHolderId: string | null;
    fixtureId: string;
}

export interface IBookingDetailsPayload {
    id: string | undefined;
    ingestType?: number;
}

export interface IBtTowerDetailsResponse {
    fixtureId: string;
    ingestResourceId: string;
    source: string;
    filesUrls: string[];
}

export interface IIpDetailsResponse {
    fixtureId: string;
    ingestResourceId: string;
}

export interface ISatelliteDetailsResponse extends ICreateSatelliteIngestData {
    ingestResourceId: string;
    filesUrls: string[];
    fileName: string
    fixtureId: string;
    fileUrl: string;
}

export type LoadingStatus = "idle" | "loading" | "succeeded" | "failed";

export interface IIngestDetails {
    ip: IIpDetailsResponse | null;
    btTower: IBtTowerDetailsResponse | null;
    satellite: ISatelliteDetailsResponse | null;
}

export interface IFixturesState {
    source: IFixtureResponse[];
    contentSuppliers: {
        id: string;
        name: string;
        isAssigned: boolean;
    }[],
    loadingStatus: LoadingStatus;
    bookingStatus: LoadingStatus;
    details: IIngestDetails;
    backupDetails: IIngestBackUpResponse;
    ipUsage: ICheckedIp | null
    isActivateBackup: boolean;
    availableIngestTypes: null | number[]
    isShowIngestMethod: boolean;
    filterValues: {
        items: IListItem[]
    };
    rightsHolderCartFixtures: ICartFixture[];
    pageNumber: number;
    itemsPerPage: number;
    totalCount: number;
    maxItemsInTheTable: number;
    scrollLoader: boolean;
    error: string;
}

const initialState: IFixturesState = {
    source: [],
    contentSuppliers: [],
    loadingStatus: "idle",
    bookingStatus: "idle",
    details: {
        ip: null,
        btTower: null,
        satellite: null
    },
    backupDetails: {} as IIngestBackUpResponse,
    ipUsage: null,
    isActivateBackup: false,
    availableIngestTypes: null,
    isShowIngestMethod: false,
    filterValues: {
        items: []
    },
    rightsHolderCartFixtures: [],
    pageNumber: 1,
    itemsPerPage: 15,
    totalCount: 0,
    maxItemsInTheTable: 45,
    scrollLoader: false,
    error: "",
}

export interface IBTTowerBackupPayload {
    ingestType: number,
    fixtureId: string,
    btTowerFixture: ICreateBtTowerFixtureData,
    ipFixture: null
    satelliteFixture: null
}

export interface IBTTowerBackupBody {
    payload: IBTTowerBackupPayload,
    filesRequestBody: IFilesPayload | null
}


export interface IIPBackupPayload {
    ingestType: number,
    fixtureId: string,
    btTowerFixture: null,
    ipFixture: ICreateIpFixtureData
    satelliteFixture: null

}

export interface ISatelliteBackupPayload {
    ingestType: number,
    fixtureId: string,
    btTowerFixture: null,
    ipFixture: null
    satelliteFixture: ICreateSatelliteIngestData
}

export interface ISatelliteBackupBody {
    payload: ISatelliteBackupPayload,
    filesRequestBody: IFilesPayload | null
}

export interface IIngestOperationResponse {
    fixtureId: string,
    ingestType: string,
    details: string
}

export interface IContentSupplier {
    id: string;
    name: string;
    isAssigned: boolean
}

export interface IAddFixtureToCartPayload {
    rightHolderId?: string | null,
    operatorId?: string | null,
    fixtureId: string
}

export interface ICartFixture {
    id: string,
    fixtureId: string,
    sport: string,
    contentSupplier: string | null,
    startTime: string,
    endTime: string,
    property: string,
    title: string,
    description: string
}

export const getFixtures = createAsyncThunk<IFixtureResponse[], IGetDataPayload, { rejectValue: string }>(
    'right-holder-booking/search',
    async (body, {rejectWithValue, dispatch}) => {
        try {
            const {data, headers} = await client.post("/api/Fixtures/right-holder/search", body.payload);
            const totalCount = headers["x-total-count"];
            if (totalCount) {
                dispatch(setTotalCount(Number(totalCount)));
            }
            if (body.scrollPosition && body.scrollPosition === "down") {
                dispatch(appendData({data, upcoming: body.payload.upcoming}));
                return;
            }
            if (body.scrollPosition && body.scrollPosition === "up") {
                dispatch(prependData({data, upcoming: body.payload.upcoming}));
                return;
            }
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data.title}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getFilterValues = createAsyncThunk<{ items: IListItem[] }, string | null, { rejectValue: string }>(
    'right-holder-booking/filter-values',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`/api/Sports/right-holder/${id}`);
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data.title}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getBackupDetails = createAsyncThunk<IIngestBackUpResponse, string, { rejectValue: string }>(
    'right-holder-booking/get-backup-details',
    async (fixtureId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Fixtures/right-holder/ingest/backup?fixtureId=${fixtureId}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data.title}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const addFixture = createAsyncThunk<IFixtureResponse, IFixturePayload, { rejectValue: string }>(
    'right-holder-booking/create',
    async (payload, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.post("/api/Fixtures/right-holder", payload.fixture);
            payload.closeForm && dispatch(payload.closeForm);
            toastr.success('Success!', `Fixture has been added`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const updateFixture = createAsyncThunk<IFixtureResponse, IUpdateFixturePayload, { rejectValue: string }>(
    'right-holder-booking/update',
    async ({fixture, ingestType, id, closeForm}, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.put("/api/Fixtures/right-holder", {...fixture, id, ingestType});
            if (closeForm) {
                dispatch(closeForm)
                toastr.success('Success!', `Fixture has been updated`)
            } else {
                toastr.success('Success!', `End Time Extended`)
            }
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const removeFixture = createAsyncThunk<any, string, { rejectValue: string }>(
    'right-holder-booking/delete',
    async (payload, {rejectWithValue}) => {
        try {
            await client.delete(`api/Fixtures/right-holder/fixture/${payload}`);
            return payload;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const bookFixtures = createAsyncThunk<string[], IBookFixturesPayload, {
    rejectValue: string
}>(
    'right-holder-booking/book',
    async (payload, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.post("/api/Fixtures/right-holder/book", payload);
            toastr.success('Success!', `Fixtures are booked`);
            dispatch(clearCartState());
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const createBtTowerIngestBackup = createAsyncThunk<IBTTowerBackupPayload, IBTTowerBackupBody, {
    rejectValue: string
}>(
    'right-holder-booking/book-bt-tower-backup',
    async (requestBody, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.post("api/Fixtures/right-holder/ingest/backup", requestBody.payload);
            if (requestBody.filesRequestBody) {
                dispatch(sendFiles(requestBody.filesRequestBody));
            }
            toastr.success('Success!', `BTTower ingest has been set as back - up`);
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const createSatelliteIngestBackup = createAsyncThunk<ICreateSatelliteIngestPayload, ISatelliteBackupBody, {
    rejectValue: string
}>(
    'right-holder-booking/book-satellite-backup',
    async (payload, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.post("api/Fixtures/right-holder/ingest/backup", payload.payload);
            if (payload.filesRequestBody) {
                dispatch(sendFiles(payload.filesRequestBody));
            }
            toastr.success('Success!', `Satellite ingest  has been set as back - up`);
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const createIPIngestBackup = createAsyncThunk<ICreateSatelliteIngestPayload, IIPBackupPayload, {
    rejectValue: string
}>(
    'right-holder-booking/book-ip-backup',
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.post("api/Fixtures/right-holder/ingest/backup", payload);
            toastr.success('Success!', `IP ingest  has been set as back - up`);
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)
export const createBtTowerIngest = createAsyncThunk<IIngestOperationResponse[], ICreateBtTowerIngestPayload, {
    rejectValue: string
}>(
    'right-holder-booking/book-bt-tower',
    async (payload, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.post("/api/Fixtures/right-holder/ingest/bt-tower", {fixture: payload.fixture});
            if (payload.filesRequestBody) {
                dispatch(sendFiles(payload.filesRequestBody));
            }
            toastr.success('Success!', `BTTower ingest has been set`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data.errors)
            }
        }
    }
)

export const updateBtTowerIngest = createAsyncThunk<IIngestOperationResponse[], IUpdateBtTowerIngestPayload, {
    rejectValue: string
}>(
    'right-holder-booking/update-bt-tower',
    async (requestBody, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.put("/api/Fixtures/right-holder/ingest/bt-tower", requestBody.payload);
            if (requestBody.filesRequestBody) {
                dispatch(sendFiles(requestBody.filesRequestBody));
            }
            toastr.success('Success!', `BTTower ingest has been set`)
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const createIpIngest = createAsyncThunk<IIngestOperationResponse[], ICreateIpIngestPayload, {
    rejectValue: string
}>(
    'right-holder-booking/book-srt',
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.post("/api/Fixtures/right-holder/ingest/ip", payload);
            toastr.success('Success!', `IP ingest has been set`)
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const updateIpIngest = createAsyncThunk<IIngestOperationResponse[], IUpdateIpIngestPayload, {
    rejectValue: string
}>(
    'right-holder-booking/update-srt',
    async (IUpdateIpIngestPayload, {rejectWithValue}) => {
        try {
            const {data} = await client.put("/api/Fixtures/right-holder/ingest/ip", IUpdateIpIngestPayload);
            toastr.success('Success!', `IP ingest has been set`)
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const createSatelliteIngest = createAsyncThunk<IIngestOperationResponse[], ICreateSatelliteIngestPayload, {
    rejectValue: string
}>(
    'right-holder-booking/book-satellite',
    async (payload, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.post("/api/Fixtures/right-holder/ingest/satellite", {fixture: payload.fixture});
            if (payload.filesRequestBody) {
                dispatch(sendFiles(payload.filesRequestBody));
            }
            toastr.success('Success!', `Satellite ingest has been set`)
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const updateSatelliteIngest = createAsyncThunk<IIngestOperationResponse[], IUpdateSatelliteIngestPayload, {
    rejectValue: string
}>(
    'right-holder-booking/update-satellite',
    async (payload, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.put("/api/Fixtures/right-holder/ingest/satellite", payload.payload);
            if (payload.filesRequestBody) {
                dispatch(sendFiles(payload.filesRequestBody));
            }
            toastr.success('Success!', `Satellite ingest has been set`)
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const cancelBooking = createAsyncThunk<string[], ICancelBookingPayload, { rejectValue: string }>(
    'right-holder-booking/cancel',
    async (ICancelBookingPayload, {rejectWithValue}) => {
        try {
            const {data} = await client.put("/api/Fixtures/right-holder/cancel-booking", ICancelBookingPayload);
            toastr.success('Success!', `Booking fixture has been canceled`)
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getBtTowerDetails = createAsyncThunk<IBtTowerDetailsResponse, IBookingDetailsPayload, {
    rejectValue: string
}>(
    'right-holder-booking/bt-tower-details',
    async ({id}, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Fixtures/${id}/right-holder/ingest-details/bt-tower?ingestType=${IngestOptionId.BTTower}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getIpDetails = createAsyncThunk<IIpDetailsResponse, IBookingDetailsPayload, { rejectValue: string }>(
    'right-holder-booking/srt-details',
    async (payload, {rejectWithValue}) => {
        const {ingestType} = payload;
        try {
            const {data} = await client.get(`api/Fixtures/${payload.id}/right-holder/ingest-details/ip?ingestType=${ingestType}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getSatelliteDetails = createAsyncThunk<ISatelliteDetailsResponse, IBookingDetailsPayload, {
    rejectValue: string
}>(
    'right-holder-booking/satellite-details',
    async ({id}, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Fixtures/${id}/right-holder/ingest-details/satellite?ingestType=${IngestOptionId.Satellite}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const sendFiles = createAsyncThunk<any, IFilesPayload, { rejectValue: string }>(
    'right-holder-booking/send-files',
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.post(`api/Fixtures/${payload.fixtureId}/right-holder/upload-files`,
                payload.filesData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const downloadJson = createAsyncThunk<any, { rightHolderId: string | null }, { rejectValue: string }>(
    'right-holder-booking/download-json',
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.post(`api/Fixtures/right-holder/upcoming/json`, payload);
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const downloadCsv = createAsyncThunk<any, IGetCsvPayload, { rejectValue: string }>(
    'operator-booking/download-csv',
    async (IGetCsvPayload, {rejectWithValue}) => {
        try {
            const {data} = await client.post("api/Fixtures/right-holder/upcoming/download", IGetCsvPayload);
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getContentSuppliers = createAsyncThunk<IContentSupplier[], string, { rejectValue: string; }>(
    'right-holder-booking/get-content-suppliers',
    async (fixtureId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/TechnicalProviders/fixture/${fixtureId}`);
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`);
                return rejectWithValue(e.response?.data as string);
            }
        }
    }
);

export const getFixturesRightHolderIngestType = createAsyncThunk<number[], string, {
    rejectValue: string
}>(
    'right-holder-booking/get-available-ingest-types',
    async (fixtureId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Fixtures/${fixtureId}/right-holder/ingest-type`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const assignContentSupplier = createAsyncThunk<IFixtureResponse[], IAssignContentSupplierReq, {
    rejectValue: string
}>(
    'right-holder-booking/assign-content-supplier',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`api/Fixtures/${body.payload.fixtureId}/right-holder/assign-content-supplier?technicalProviderId=${body.payload.technicalProviderId}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const checkIpUsage = createAsyncThunk<ICheckedIp, ICheckIpPayload, { rejectValue: string }>(
    'right-holder-booking/check-ip-usage',
    async (payload, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.post("/api/Fixtures/right-holder/ingest/ip/check", payload.body);
            !data.isResourceInUse && dispatch(payload.onSubmitIP);
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const addFixtureToCart = createAsyncThunk<ICartFixture, IAddFixtureToCartPayload, {
    rejectValue: string
}>(
    'right-holder-booking/add-fixture-to-cart',
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.post("api/shopping-cart", payload);
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getCartFixtures = createAsyncThunk<ICartFixture[], null, {
    rejectValue: string
}>(
    'right-holder-booking/get-cart-fixtures',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/shopping-cart`);
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getCartFixturesForIgameRightsHolder = createAsyncThunk<ICartFixture[], string, {
    rejectValue: string
}>(
    'right-holder-booking/get-cart-fixtures-for-igame-rightsHolder',
    async (userId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/shopping-cart/right-holder/${userId}`);
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)


export const deleteFixtureFromCart = createAsyncThunk<ICartFixture[], string, {
    rejectValue: string
}>(
    'right-holder-booking/delete-fixtures-from-cart',
    async (cartItemId, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`api/shopping-cart/remove/${cartItemId}`);
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('Error!', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const rightHolderBookingSlice = createSlice({
    name: 'fixture',
    initialState,
    reducers: {
        setPageNumber: (state, action: PayloadAction<number>) => {
            state.pageNumber = action.payload;
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload;
        },
        setMaxItemsInTheTable: (state, action: PayloadAction<number>) => {
            state.maxItemsInTheTable = action.payload;
        },
        setItemsPerPage: (state, action: PayloadAction<number>) => {
            state.itemsPerPage = action.payload;
        },
        clearCartState: (state) => {
            state.rightsHolderCartFixtures = [];
        },
        skipLoadingStatus: (state) => {
            state.loadingStatus = "idle";
        },
        clearIngestDetails: (state) => {
            state.details = {ip: null, btTower: null, satellite: null}
        },
        clearIpUsage: (state) => {
            state.ipUsage = null
        },
        clearTable: (state) => {
            state.source = [];
        },
        setIsActivateBackup: (state, action: PayloadAction<boolean>) => {
            state.isActivateBackup = action.payload;
        },
        setIsShowIngetsMethod: (state, action: PayloadAction<boolean>) => {
            state.isShowIngestMethod = action.payload;
        },
        appendData: (state, action) => {
            const data = action.payload.data;
            appendDataHelper(state, data, 'source');
        },
        prependData(state, action) {
            const data = action.payload.data;
            prependDataHelper(state, data, 'source');
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(getFixtures.pending, (state) => {
                state.error = "";
                state.scrollLoader = true;
                state.loadingStatus = "loading";
            })
            .addCase(getFixtures.fulfilled, (state, action) => {
                if (action.payload !== undefined) {
                    state.source = action.payload
                }
                state.scrollLoader = false
                state.loadingStatus = "idle";
            })
            .addCase(getFilterValues.fulfilled, (state, action) => {
                state.filterValues = action.payload;
            })
            .addCase(getBackupDetails.fulfilled, (state, action) => {
                state.backupDetails.btTowerFixture = action.payload.btTowerFixture
                state.backupDetails.ipFixture = action.payload.ipFixture
                state.backupDetails.satelliteFixture = {
                    ...action.payload.satelliteFixture,
                    startDate: action.payload.satelliteFixture?.startDate,
                    endDate: action.payload.satelliteFixture?.endDate
                }
            })
            .addCase(getFixtures.rejected, (state) => {
                state.loadingStatus = "failed";
            })
            .addCase(addFixture.pending, (state) => {
                state.error = "";
                state.loadingStatus = "loading";
            })
            .addCase(addFixture.fulfilled, (state, action) => {
                state.loadingStatus = "idle";
                state.source.unshift(action.payload)
            })
            .addCase(addFixture.rejected, (state) => {
                state.loadingStatus = "idle";
            })
            .addCase(updateFixture.pending, (state) => {
                state.error = "";
                state.loadingStatus = "loading";
            })
            .addCase(updateFixture.fulfilled, (state, action: PayloadAction<IFixtureResponse>) => {
                state.loadingStatus = "idle";
                state.source = state.source.map(fixture => fixture.id === action.payload.id ? action.payload : fixture)
            })
            .addCase(updateFixture.rejected, (state) => {
                state.loadingStatus = "idle";
            })
            .addCase(removeFixture.fulfilled, (state, action) => {
                state.source = state.source.filter(fixture => fixture.id !== action.payload);
            })
            .addCase(bookFixtures.pending, (state) => {
                state.error = "";
                state.bookingStatus = "loading";
            })
            .addCase(bookFixtures.fulfilled, (state, action) => {
                state.bookingStatus = "succeeded";
                const bookedIds = action.payload;
                const filteredBookedIds = bookedIds ? bookedIds.filter(id => id !== undefined) : [];
                const bookedCompetitions = state.source.map(fixture => filteredBookedIds?.includes(fixture.id) ? fixture.competitionId : null)
                const filteredBookedCompetitions = bookedCompetitions.filter(competition => competition)
                state.source = state.source.filter(fixture => !filteredBookedIds?.includes(fixture.id));
                state.source = state.source.map(fixture => {
                    if (filteredBookedCompetitions.includes(fixture.competitionId)) {
                        fixture.remainingFixtureCount = (fixture.remainingFixtureCount || 0) - 1;
                        filteredBookedCompetitions.splice(filteredBookedCompetitions.indexOf(fixture.competitionId), 1);
                        return fixture
                    }
                    return fixture;
                });
            })
            .addCase(bookFixtures.rejected, (state) => {
                state.bookingStatus = "failed";
            })
            .addCase(createBtTowerIngest.pending, (state) => {
                state.error = "";
            })
            .addCase(createBtTowerIngest.fulfilled, (state, action: PayloadAction<IIngestOperationResponse[]>) => {
                updateIngestState(state, action.payload, IngestOptionId.BTTower);
            })
            .addCase(createBtTowerIngestBackup.fulfilled, (state, action) => {
                state.source = state.source.map(fixture => {
                    if (fixture.id === action.meta.arg.payload.fixtureId) {
                        fixture.backupIngestType = IngestOptionId.BTTower
                    }
                    return fixture
                })
            })
            .addCase(createBtTowerIngest.rejected, (state) => {
                state.loadingStatus = "idle";
            })
            .addCase(updateBtTowerIngest.pending, (state) => {
                state.error = "";
            })
            .addCase(updateBtTowerIngest.fulfilled, (state, action: PayloadAction<IIngestOperationResponse[]>) => {
                updateIngestState(state, action.payload, IngestOptionId.BTTower);
            })
            .addCase(updateBtTowerIngest.rejected, (state) => {
                state.loadingStatus = "idle";
            })
            .addCase(createIpIngest.pending, (state) => {
                state.error = "";
            })
            .addCase(createIpIngest.fulfilled, (state, action: PayloadAction<IIngestOperationResponse[]>) => {
                updateIngestState(state, action.payload, IngestOptionId.IP);
            })
            .addCase(createIpIngest.rejected, (state) => {
                state.loadingStatus = "idle";
            })
            .addCase(updateIpIngest.pending, (state) => {
                state.error = "";
            })
            .addCase(updateIpIngest.fulfilled, (state, action: PayloadAction<IIngestOperationResponse[]>) => {
                updateIngestState(state, action.payload, IngestOptionId.IP);
            })
            .addCase(updateIpIngest.rejected, (state,) => {
                state.loadingStatus = "idle";
            })
            .addCase(createSatelliteIngest.pending, (state) => {
                state.error = "";
            })
            .addCase(createSatelliteIngest.fulfilled, (state, action: PayloadAction<IIngestOperationResponse[]>) => {
                updateIngestState(state, action.payload, IngestOptionId.Satellite);
            })
            .addCase(createSatelliteIngestBackup.fulfilled, (state, action) => {
                state.source = state.source.map(fixture => {
                    if (fixture.id === action.meta.arg.payload.fixtureId) {
                        fixture.backupIngestType = IngestOptionId.Satellite
                    }
                    return fixture
                })
            })
            .addCase(createSatelliteIngest.rejected, (state) => {
                state.loadingStatus = "idle";
            })
            .addCase(updateSatelliteIngest.pending, (state) => {
                state.error = "";
            })
            .addCase(updateSatelliteIngest.fulfilled, (state, action: PayloadAction<IIngestOperationResponse[]>) => {
                updateIngestState(state, action.payload, IngestOptionId.Satellite);
            })
            .addCase(updateSatelliteIngest.rejected, (state) => {
                state.loadingStatus = "idle";
            })
            .addCase(cancelBooking.pending, (state) => {
                state.error = "";
            })
            .addCase(cancelBooking.fulfilled, (state, action: PayloadAction<string[]>) => {
                const idsToRemove = new Set(action.payload);
                state.source = state.source.filter(item => !idsToRemove.has(item.id));
            })
            .addCase(getBtTowerDetails.pending, (state) => {
                state.error = "";
            })
            .addCase(getBtTowerDetails.fulfilled, (state, action: PayloadAction<IBtTowerDetailsResponse>) => {
                state.details.btTower = action.payload;
            })
            .addCase(getIpDetails.pending, (state) => {
                state.error = "";
            })
            .addCase(getIpDetails.fulfilled, (state, action: PayloadAction<IIpDetailsResponse>) => {
                return {
                    ...state,
                    details: {
                        ...state.details,
                        ip: action.payload
                    }
                };
            })
            .addCase(getSatelliteDetails.pending, (state) => {
                state.error = "";
            })
            .addCase(getSatelliteDetails.fulfilled, (state, action: PayloadAction<ISatelliteDetailsResponse>) => {
                state.details.satellite = action.payload;
            })
            .addCase(sendFiles.pending, (state) => {
                state.error = "";
            })
            .addCase(sendFiles.fulfilled, (state) => {
                state.loadingStatus = "idle";
            })
            .addCase(sendFiles.rejected, (state) => {
                state.loadingStatus = "idle";
            })
            .addCase(getContentSuppliers.pending, (state) => {
                state.error = "";
            })
            .addCase(getContentSuppliers.fulfilled, (state, action) => {
                state.contentSuppliers = action.payload;
                state.isShowIngestMethod = action.payload.some((supplier: IContentSupplier) => supplier.isAssigned);
            })
            .addCase(assignContentSupplier.pending, (state) => {
                state.error = "";
            })
            .addCase(checkIpUsage.pending, (state) => {
                state.error = "";
            })
            .addCase(checkIpUsage.fulfilled, (state, action) => {
                state.ipUsage = action.payload;
            })
            .addCase(assignContentSupplier.fulfilled, (state, action) => {
                const updatedFixtures = action.payload;
                const updatedMap = new Map(updatedFixtures.map(fixture => [fixture.id, fixture]));

                state.source = state.source.map(fixture => {
                    return updatedMap.get(fixture.id) ?? fixture;
                });

                state.isShowIngestMethod = true;
            })
            .addCase(getFixturesRightHolderIngestType.fulfilled, (state, action) => {
                state.availableIngestTypes = action.payload;
            })
            .addCase(getCartFixtures.fulfilled, (state, action) => {
                state.rightsHolderCartFixtures = action.payload;
            })
            .addCase(getCartFixturesForIgameRightsHolder.fulfilled, (state, action) => {
                state.rightsHolderCartFixtures = action.payload;
            })
            .addCase(addFixtureToCart.fulfilled, (state, action) => {
                state.rightsHolderCartFixtures.push(action.payload);
            })
            .addCase(deleteFixtureFromCart.fulfilled, (state, action) => {
                state.rightsHolderCartFixtures = state.rightsHolderCartFixtures.filter(fixture => fixture.id !== action.meta.arg)
            })
            .addMatcher(isError, (state, action: PayloadAction<string>) => {
                state.error = action.payload;
            })
    },
})

export const {
    clearCartState,
    skipLoadingStatus,
    clearIngestDetails,
    clearIpUsage,
    setPageNumber,
    setTotalCount,
    clearTable,
    setIsActivateBackup,
    appendData,
    prependData,
    setIsShowIngetsMethod
} = rightHolderBookingSlice.actions

export default rightHolderBookingSlice.reducer

function isError(action: AnyAction) {
    return action.type.endsWith("rejected")
}
