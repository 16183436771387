import {IDate} from "../../AddFixtureForm/AddFixtureForm";
import {DateTime} from "luxon";

export const formatDate = (dateInPickerFormat: IDate) => {
    if (dateInPickerFormat) {
        const {year, month, day, hour, minute} = dateInPickerFormat;
        return DateTime.local(year, month, day, hour, minute).toUTC().toString();
    } else {
        return "";
    }
}