import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {client} from "../../services/api-servise";
import {AxiosError} from "axios";
import {toastr} from "react-redux-toastr";
import {setTotalCount} from "../rightHolderBooking/rightHolderBookingSlice";

interface IGetAlertsPayload {
    pageNumber: number;
    itemsPerPage: number;
}

export interface IAlert {
    id: string;
    alertType: number;
    topText: string;
    description: string;
    bottomText: string;
    date: string;
    urlIdentifier: string;
    groupId: string;
    entityId: string;
    isSeen: true;
    auditAction: number;
}

export interface IGetPageNumberPayload {
    fixtureId: string;
    itemsPerPage: number;
    rightHolderId?: string;
}

interface IAlertsState {
    records: IAlert[],
    totalCount: number,
    active: IAlert | null;
}

const initialState: IAlertsState = {
    records: [],
    totalCount: 0,
    active: null
}

export const getAlerts = createAsyncThunk<IAlert[], IGetAlertsPayload, { rejectValue: string }>(
    "alerts/get",
    async (payload, {rejectWithValue, dispatch}) => {
        try {
            const {data, headers} = await client.post("api/Alerts", payload);
            const totalCount = headers["x-total-count"];
            if (totalCount) {
                dispatch(setTotalCount(Number(totalCount)));
            }
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error("IGame", `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const markAlertAsSeen = createAsyncThunk<string, { alertId: string }, { rejectValue: string }>(
    "alerts/markAsSeen",
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.put("api/Alerts/mark-as-seen", payload);
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error("IGame", `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getFixturePageNumber = createAsyncThunk<number, IGetPageNumberPayload, { rejectValue: string }>(
    "alerts/getPageNumber",
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Alerts/fixture-page/fixture/${payload.fixtureId}/${payload.itemsPerPage}?${payload.rightHolderId ?? null}`);
            return data;
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error("IGame", `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const alertsSlice = createSlice({
    name: "alerts",
    initialState,
    reducers: {
        setActiveAlert: (state, action: PayloadAction<IAlert>) => {
            state.active = action.payload;
        },
        removeActiveAlert: (state) => {
            state.active = null;
        },
        updateAlerts: (state, action: PayloadAction<IAlert>) => {
            state.records = [action.payload, ...state.records.slice(0, -1)];
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAlerts.fulfilled, (state, action) => {
                state.records = action.payload;
            })
            .addCase(markAlertAsSeen.fulfilled, (state, action) => {
                state.records = state.records.map(item => {
                    if (item.id === action.meta.arg.alertId) {
                        return {
                            ...item,
                            isSeen: true
                        };
                    } else {
                        return item;
                    }
                })
            })
    },
})
export const {
    setActiveAlert,
    updateAlerts,
    removeActiveAlert
} = alertsSlice.actions
export default alertsSlice.reducer
