import {useState} from "react";
import {useAppDispatch} from "../../../../../hooks/hooks";
import {ThunkAction} from "@reduxjs/toolkit";
import {useDebounce} from "../../../../../helpers/useDebounce";
import {
    IGetDataPayload,
    IGetFixturesPayload,
    setPageNumber
} from "../../../../../store/rightHolderBooking/rightHolderBookingSlice";
import {MultiValue} from "react-select";

export interface ISorting {
    criteria: string | null;
    isAscending: boolean;
}

export const useSortingAndSearch = (
    rightHolderId: string | null,
    request: (payload: IGetDataPayload) => ThunkAction<any, any, any, any>,
    upcoming: boolean,
    pageSize: number,
    queryPageNumber?: string | null
) => {

    const [searchWord, setSearchWord] = useState("");
    const [sorting, setSorting] = useState<ISorting>({
        criteria: "",
        isAscending: true
    });
    const [initial, setInitial] = useState(true);
    const [sport, setSport] = useState<MultiValue<{ value: string, label: string }>>([]);

    const dispatch = useAppDispatch();

    const getSortedItems = () => {
        if (queryPageNumber) return;

        let payload: IGetFixturesPayload = {
            rightHolderId: rightHolderId || null,
            searchText: searchWord,
            sorting: {
                property: sorting.criteria ? sorting.criteria : "",
                isAscending: sorting.isAscending
            },
            sportIds: sport.map(item => item.value),
            upcoming,
            pageNumber: 1,
            itemsPerPage: pageSize
        }
        dispatch(setPageNumber(1))
        dispatch(request({payload})).then(() => setInitial(false));
    }

    useDebounce(getSortedItems, [sorting, searchWord, sport], 500);

    return {setSorting, sorting, setSearchWord, searchWord, getSortedItems, sport, setSport, initial}
}
