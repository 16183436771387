import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IInputOption} from "../operatorAccess/operatorAccessSlice";
import {client} from "../../services/api-servise";
import {toastr} from "react-redux-toastr";
import {formatSelectOptions} from "../../helpers/formatSelectOptions";
import {getPickerDateFormat} from "../../helpers/dateFormatters";
import {DateTime} from "luxon";
import {
    IAvailableContentDataReq,
    IChartDataResponse,
    IContentAnalysisState,
    IFavInitResponse,
    IFavouriteFixture,
    ISetFavouriteHandleResp
} from "./types";
import {changeFavStarStatus} from "../operatorRightsOverview/operatorRightsOverviewSlice";


const initialState: IContentAnalysisState = {
    filters: {
        startDate: getPickerDateFormat(DateTime.now().minus({days: 3}).toISO(), true),
        endDate: getPickerDateFormat(DateTime.now().toISO(), true),
        rightHolder: [],
        property: [],
        sport: [],
        timeZone: undefined
    },
    availableContentInitOptions: {
        properties: [],
        sports: [],
        rightHolders: [],
        timeZones: [],
        controls: [
            {
                datePicker: true,
                label: "Start Date",
                initValue: null,
                valueName: "startDate",
                options: null
            },
            {
                datePicker: true,
                label: "End Date",
                initValue: null,
                valueName: "endDate",
                options: null
            },
            {
                datePicker: false,
                label: "Right Holder",
                initValue: null,
                valueName: "rightHolder",
                options: []
            },
            {
                datePicker: false,
                label: "Sport",
                initValue: null,
                valueName: "sport",
                options: []
            },
            {
                datePicker: false,
                label: "Property",
                initValue: null,
                valueName: "property",
                options: []
            }
        ]
    },
    favouriteContentInitOptions: {
        rightHolder: [],
        sport: [],
        country: [],
        controls: [
            {
                datePicker: false,
                label: "Right Holder",
                initValue: null,
                valueName: "rightHolder",
                options: []
            },
            {
                datePicker: false,
                label: "Sport",
                initValue: null,
                valueName: "sport",
                options: []
            },
            {
                datePicker: false,
                label: "Country",
                initValue: null,
                valueName: "country",
                options: []
            },
        ]
    },
    favouriteFilters: {
        rightHolder: [],
        country: [],
        sport: []
    },
    contentList: [],
    concurrencyChart: [],
    favouriteFixtures: [],
    bookedContentCount: 0,
    availableContentCount: 0,
    isLoading: false,
}

interface IContentAnalysisResponse {
    sports: {
        items: IInputOption[]
    },
    rightHolders: {
        items: IInputOption[]
    },
    properties: {
        items: IInputOption[]
    },
    chartTypes: {
        items: IInputOption[]
    },
    contentTypes: {
        items: IInputOption[]
    },
    timeZones: {
        items: IInputOption[]
    }
}

export const geAvailableContentData = createAsyncThunk<IChartDataResponse, IAvailableContentDataReq, {
    rejectValue: string
}>(
    'contentAnalysis/get-available-content-data',
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.post(`api/ContentAnalysis/content-analysis/available-content`, payload)
            return data
        } catch (e: any) {
            toastr.error('IGame', `${e.response.data}`)
            return rejectWithValue(e.response.data)
        }
    }
)

export const getAvailableContentInit = createAsyncThunk<IContentAnalysisResponse, null, { rejectValue: string }>(
    'contentAnalysis/available-content-init',
    async (_, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.get("api/ContentAnalysis/content-analysis/init");
            dispatch(setFilters({
                value: {
                    label: "(UTC)",
                    value: "14"
                },
                name: 'timeZone'
            }));
            return data
        } catch (e: any) {
            toastr.error('IGame', `${e.response.data}`)
            return rejectWithValue(e.response.data)
        }
    }
)

export const addFavouriteItem = createAsyncThunk<ISetFavouriteHandleResp, string, { rejectValue: string }>(
    'contentAnalysis/add-fav',
    async (id, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.post(`api/ContentAnalysis/favourite-content?competitionId=${id}`)
            dispatch(changeFavStarStatus({id, isAdd: true}))
            toastr.success("IGame", data.message)
            return data
        } catch (e: any) {
            toastr.error('IGame', `${e.response.data}`)
            return rejectWithValue(e.response.data)
        }
    }
)

export const removeFavouriteItem = createAsyncThunk<ISetFavouriteHandleResp, string, { rejectValue: string }>(
    'contentAnalysis/remove-fav',
    async (id, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.delete(`api/ContentAnalysis/favourite-content?competitionId=${id}`)
            dispatch(changeFavStarStatus({id, isAdd: false}))
            dispatch(removeFavFromState(id))
            toastr.success("IGame", data.message)
            return data
        } catch (e: any) {
            toastr.error('IGame', `${e.response.data}`)
            return rejectWithValue(e.response.data)

        }

    }
)

export const getFavInit = createAsyncThunk<IFavInitResponse>(
    'contentAnalysis/get-fav-init-options',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await client.get("api/ContentAnalysis/favourite/init")
            return data
        } catch (e: any) {
            toastr.error('IGame', `${e.response.data}`)
            return rejectWithValue(e.response.data)

        }

    }
)


export const onFavSearch = createAsyncThunk<IFavouriteFixture[], string | null, { rejectValue: string }>(
    'contentAnalysis/favSearch',
    async (params, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`/api/ContentAnalysis/favourite-content${params ? params : ''}`)
            return data
        } catch (e: any) {
            toastr.error('IGame', `${e.response.data}`)
            return rejectWithValue(e.response.data)

        }

    }
)

export const contentAnalysisSlice = createSlice({
    name: 'contentAnalysis',
    initialState,
    reducers: {
        setRemainingSelection: (state, action) => {
            state.contentList = state.contentList.map(contentItem => {
                if (contentItem.fixtureId === action.payload) {
                    contentItem.remainingSelections = contentItem.remainingSelections - 1
                }
                return contentItem
            })
        },
        updatePropertyCartStatus: (state, action) => {
            state.contentList = state.contentList.map(contentItem => {
                if (contentItem.fixtureId === action.payload.fixtureId) {
                    contentItem.isBooked = action.payload.isBooked
                }
                return contentItem
            })
        },
        setFilters: (state, action: PayloadAction<{ value: any; name: keyof IContentAnalysisState["filters"] }>) => {
            state.filters[action.payload.name] = action.payload.value;
        },
        setFavouriteFilters: (state, action: PayloadAction<{
            value: any;
            name: keyof IContentAnalysisState["favouriteFilters"]
        }>) => {
            state.favouriteFilters[action.payload.name] = action.payload.value;
        },
        clearFilter: (state) => {
            state.filters = initialState.filters
        },
        clearFavouriteFilter: (state) => {
            state.favouriteFilters = initialState.favouriteFilters
        },
        removeFavFromState: (state, action: PayloadAction<string>) => {
            state.favouriteFixtures = state.favouriteFixtures.filter((item) => {
                if (item.sportId !== action.payload) {
                    return item;
                }
                return null
            })
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAvailableContentInit.fulfilled, (state, action) => {
                state.availableContentInitOptions.controls[2].options = formatSelectOptions(action.payload.rightHolders.items)
                state.availableContentInitOptions.controls[3].options = formatSelectOptions(action.payload.sports.items)
                state.availableContentInitOptions.controls[4].options = formatSelectOptions(action.payload.properties.items)
                state.availableContentInitOptions.timeZones = formatSelectOptions(action.payload.timeZones.items)
            })
            .addCase(geAvailableContentData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(geAvailableContentData.fulfilled, (state, action) => {
                state.availableContentCount = action.payload.availableContentCount;
                state.bookedContentCount = action.payload.bookedContentCount;

                if (action.payload.contentList) {
                    state.contentList = action.payload.contentList.items
                }
                if (!action.payload.contentList) {
                    state.contentList = []
                }
                if (action.payload.concurrencyChart) {
                    state.concurrencyChart = action.payload.concurrencyChart.data
                }
                if (!action.payload.concurrencyChart) {
                    state.concurrencyChart = []
                }
                state.isLoading = false;
            })
            .addCase(geAvailableContentData.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getFavInit.fulfilled, (state, action) => {
                state.favouriteContentInitOptions.controls[0].options = formatSelectOptions(action.payload.rightsHolders.items)
                state.favouriteContentInitOptions.controls[1].options = formatSelectOptions(action.payload.sports.items)
                state.favouriteContentInitOptions.controls[2].options = formatSelectOptions(action.payload.countries.items)
            })
            .addCase(addFavouriteItem.fulfilled, (state, action) => {
                state.contentList.forEach((item) => {
                    if (item.propertyId === action.meta.arg) {
                        item.isFavourite = true;
                    }
                    return item;
                })
            })
            .addCase(removeFavouriteItem.fulfilled, (state, action) => {
                state.favouriteFixtures = state.favouriteFixtures.filter((item) => {
                    if (item.sportId !== action.meta.arg) {
                        return item;
                    }
                    return null
                })
                state.contentList.forEach((item) => {
                    if (item.propertyId === action.meta.arg) {
                        item.isFavourite = false;
                    }
                    return item;
                })
            })
            .addCase(onFavSearch.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(onFavSearch.fulfilled, (state, action) => {
                state.favouriteFixtures = action.payload;
                state.isLoading = false;
            })
            .addCase(onFavSearch.rejected, (state) => {
                state.isLoading = false;
            })
    }
})

export const {
    setFilters,
    clearFilter,
    clearFavouriteFilter,
    setFavouriteFilters,
    removeFavFromState,
    setRemainingSelection,
    updatePropertyCartStatus
} = contentAnalysisSlice.actions

export default contentAnalysisSlice.reducer
