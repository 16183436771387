import React, {useEffect, useState} from 'react';
import styles from "./index.module.scss";
import {useForm} from "react-hook-form";
import {IoIosClose} from "react-icons/io";
import FormButtonsBlock from "../FormButtonsBlock/FormButtonsBlock";
import {useAppDispatch, useAppSelector} from "../../../../../../../../hooks/hooks";
import {addOperator, IOperatorBody, IUserPages, updateOperator}
    from "../../../../../../../../store/userManagement/userManagementSlice";
import FormRouting from "../FormRouting/FormRouting";
import Loader from "../../../../../../../../components/Loader/Loader";
import {useOperatorFormData} from "../../../UserManagementHooks/useOperatorFormData";
import {
    setCustomerIdValue, setMultiSelectValue
} from "./helpers";
import {
    createOperatorPayload
} from "../../../helpers/createOperatorPayload";
import StepOneForm from "./children/StepOneForm";
import StepTwoForm from "./children/StepTwoForm";
import ConfirmModal from "../../../../../../../../components/ConfirmModal/ConfirmModal";
import {AiOutlineExclamation} from "react-icons/ai";
import StepThreeForm from "./children/StepThreeForm";
import {toastr} from "react-redux-toastr";

export interface IUserTypeForm {
    closeForm: () => void
}

const OperatorForm: React.FC<IUserTypeForm> = React.memo(({closeForm}) => {
    const dispatch = useAppDispatch();
    const {handleSubmit, control, formState: {errors}, setValue, setError, clearErrors} = useForm();
    const [currentTab, setCurrentTab] = useState<string>('Step 1');
    const [completedSteps, setCompletedSteps] = useState<number[]>([]);
    const [isOpenExitConfirm, setIsOpenExitConfirm] = useState(false);
    const {
        operators,
        customers,
        territories,
        operatorVisibilities,
        loading,
        customersInUse,
        existingEmails,
        rightHoldersInit,
        currentOperator,
        roles
    } = useAppSelector(state => state.userManagement);

    const {
        stepOneFormData,
        stepTwoFormData,
        stepThreeFormData,
        setStepOneFormData,
        setStepTwoFormData,
        handleFileSelection,
        onChangeStepOneHandler,
        handleSelectData,
        handleCustomerId,
        checkboxHandler,
        selectAllPages,
        deselectAllPages,
    } = useOperatorFormData();

    useEffect(() => {
        if (currentOperator) {
            const stepOneNewData = {
                name: currentOperator.name,
                customerId: setCustomerIdValue(currentOperator, customers),
                clientAdmin: currentOperator.clientAdmin,
                adminEmail: currentOperator.adminEmail,
                commercialContact: currentOperator.commercialContact,
                commercialEmail: currentOperator.commercialEmail,
                scheduleContact: currentOperator.scheduleContact,
                scheduleContactEmail: currentOperator.scheduleContactEmail,
                liveIssuesContact: currentOperator.liveIssuesContact,
                liveIussesContactEmail: currentOperator.liveIussesContactEmail,
                territoryIdes: setMultiSelectValue('territories', currentOperator, territories)
            }
            setValue("onePlatformId", setCustomerIdValue(currentOperator, customers));
            setValue("name", currentOperator.name);
            setValue("clientAdmin", currentOperator.adminEmail);
            setValue("commercialContact", currentOperator.commercialContact);
            setValue("scheduleContact", currentOperator.scheduleContact);
            setValue("liveIssuesContact", currentOperator.liveIssuesContact);
            setValue('territoryIdes', stepOneNewData.territoryIdes);

            setStepOneFormData(stepOneNewData)

            const stepTwoNewData = {
                rightHolders: setMultiSelectValue('rightHolders', currentOperator, rightHoldersInit),
                rolePages: currentOperator.rolePageDTOs
            }
            setValue('rightHolders', stepTwoNewData.rightHolders);
            setStepTwoFormData(stepTwoNewData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOperator]);

    const addCompletedFormStep = (step: number) => {
        const isExistStep = completedSteps.includes(step)
        if (!isExistStep) {
            setCompletedSteps([...completedSteps, step])
        }
    }

    const submitFormHandler = () => {
        if (currentTab === 'Step 1') {
            addCompletedFormStep(0)
            setCurrentTab('Step 2');
        }

        if (currentTab === 'Step 2') {
            const somePagesSelected = stepTwoFormData.rolePages.some((role: IUserPages) => role.pageIds.length !== 0);
            if (!somePagesSelected) {
                return toastr.error("Error!", "You need to select at least one page to proceed");
            }

            const hasPageIds = stepTwoFormData.rolePages.some(role => role.pageIds.length > 0);
            if (!hasPageIds) {
                return toastr.error("Operator", "Page visibility is required field");
            }
            setCurrentTab('Step 3');
        }

        if (currentTab === 'Step 3') {
            const payload = createOperatorPayload(
                stepOneFormData,
                stepTwoFormData,
                stepThreeFormData,
            );

            if (currentOperator) {
                payload.operatorId = currentOperator.id;

                const updateUser: IOperatorBody = {
                    payload,
                    closeForm
                }
                dispatch(updateOperator(updateUser));
            } else {
                const newUser: IOperatorBody = {
                    payload,
                    closeForm
                }
                dispatch(addOperator(newUser));
            }
        }
    }

    const closeAllPopups = () => {
        setIsOpenExitConfirm(false);
        closeForm();
    }

    return (
        <div className={styles.overlay}>
            {loading && <Loader/>}
            {!loading || !currentOperator ?
                <div className={styles.formContainer}>
                    <div className={styles.closeButtonWrapper}>
                        <IoIosClose onClick={() => setIsOpenExitConfirm(true)}/>
                    </div>

                    <div className={styles.titleWrapper}>
                        <h6>{currentOperator ? `Edit Operator: ${currentOperator.name}` : 'Add Operator'}</h6>
                    </div>

                    {currentTab === 'Step 1' && <form onSubmit={handleSubmit(submitFormHandler)}>
                        <div className={styles.stepsWrapper}>
                            <FormRouting
                                submitFormHandler={submitFormHandler}
                                handleSubmit={handleSubmit}
                                setCompletedSteps={setCompletedSteps}
                                completedSteps={completedSteps}
                                currentTab={currentTab}
                                totalSteps={3}
                                setCurrentTab={setCurrentTab}
                            />
                        </div>

                        <StepOneForm
                            control={control}
                            errors={errors}
                            stepOneFormData={stepOneFormData}
                            onChangeStepOneHandler={onChangeStepOneHandler}
                            currentOperator={currentOperator}
                            operators={operators}
                            customersInUse={customersInUse}
                            handleCustomerId={handleCustomerId}
                            customers={customers}
                            existingEmails={existingEmails}
                            territories={territories}
                            handleSelectData={handleSelectData}
                            setError={setError}
                            clearErrors={clearErrors}
                        />

                        <FormButtonsBlock onClickHandler={() => setIsOpenExitConfirm(true)} text={'Next'}/>
                    </form>
                    }

                    {currentTab === 'Step 2' && <form onSubmit={handleSubmit(submitFormHandler)}>
                        <div className={styles.stepsWrapper}>
                            <FormRouting
                                submitFormHandler={submitFormHandler}
                                handleSubmit={handleSubmit}
                                setCompletedSteps={setCompletedSteps}
                                completedSteps={completedSteps}
                                currentTab={currentTab}
                                totalSteps={3}
                                setCurrentTab={setCurrentTab}
                            />
                        </div>

                        <StepTwoForm
                            control={control}
                            errors={errors}
                            operatorVisibilities={operatorVisibilities}
                            stepTwoFormData={stepTwoFormData}
                            roles={roles}
                            checkboxHandler={checkboxHandler}
                            rightHoldersInit={rightHoldersInit}
                            handleSelectData={handleSelectData}
                            selectAllPages={selectAllPages}
                            deselectAllPages={deselectAllPages}
                        />

                        <FormButtonsBlock onClickHandler={() => setIsOpenExitConfirm(true)} text={'Next'}/>
                    </form>
                    }

                    {currentTab === 'Step 3' && <form onSubmit={handleSubmit(submitFormHandler)}>
                        <div className={styles.stepsWrapper}>
                            <FormRouting
                                submitFormHandler={submitFormHandler}
                                handleSubmit={handleSubmit}
                                setCompletedSteps={setCompletedSteps}
                                completedSteps={completedSteps}
                                currentTab={currentTab}
                                totalSteps={3}
                                setCurrentTab={setCurrentTab}
                            />
                        </div>

                        <StepThreeForm
                            handleFileSelection={handleFileSelection}
                        />
                        <FormButtonsBlock onClickHandler={() => setIsOpenExitConfirm(true)} text={'Confirm'}/>
                    </form>
                    }
                </div> : null}
            {isOpenExitConfirm &&
                <ConfirmModal
                    closeForm={() => setIsOpenExitConfirm(false)}
                    action={closeAllPopups}
                    message={"Are you sure you want to exit?"}>
                    <AiOutlineExclamation color={'#FFFFFF'} size={140}/>
                </ConfirmModal>
            }
        </div>
    );
});

OperatorForm.displayName = "OperatorForm"
export default OperatorForm;
