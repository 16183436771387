import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import AuditTrailsTable from "../children/AuditTrailsTable/AuditTrailsTable";
import AuditTrailPageInner from "../children/AuditTrailPageInner/AuditTrailPageInner";
import {IMenuStructure} from "../../../pages/Cms/AppRoutes/routes/RouteInterface";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import commonStyles from "../children/AuditTrailPageInner/index.module.scss";
import {RecordTypes} from "../../../enums/recordTypes";
import {getAuditTrails} from "../../../store/auditTrailsCommon/auditTrailsCommonSlice";

export interface IAuditTrailsIssuesProps {
    menuStructure: IMenuStructure;
}

const AuditTrailsIssues: React.FC<IAuditTrailsIssuesProps> = ({menuStructure}) => {
    const {trailsItemsPerPage} = useAppSelector(state => state.auditTrailsCommon);
    const issueDetails = useAppSelector(state => state.auditTrailsIssues.issueDetails);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (issueDetails?.id) {
            dispatch(getAuditTrails({
                auditRecordType: RecordTypes.Issue,
                recordId: issueDetails?.id,
                pageNumber: 1,
                itemsPerPage: trailsItemsPerPage,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issueDetails?.id]);

    const handleGoBack = () => {
        navigate(-1);
    }

    return (
        <AuditTrailPageInner
            pageTitle={"Audit Trails"}
            pageSubTitle={"Issues"}
            pageHeader={"Audit Trails - Issues"}
            menuStructure={menuStructure}
            backButtonAction={handleGoBack}
            isExternal={true}>
            {
                issueDetails?.id &&
                <div className={commonStyles.recordInfo} key="details">
                    <p className={commonStyles.recordInfoItem}><span>Id: </span>{issueDetails?.id}</p>
                    <p className={commonStyles.recordInfoItem}><span>Rights Holder: </span>{issueDetails?.rightHolder}
                    </p>
                    <p className={commonStyles.recordInfoItem}>
                        <span>Technikal Provider: </span>{issueDetails?.technicalProvider}</p>
                    <p className={commonStyles.recordInfoItem}><span>Property: </span>{issueDetails?.competition}</p>
                </div>
            }
            {
                issueDetails?.id
                &&
                <AuditTrailsTable
                    auditRecordType={RecordTypes.Issue}
                    recordId={issueDetails?.id}
                />
            }
        </AuditTrailPageInner>
    );
}

export default AuditTrailsIssues;
AuditTrailsIssues.displayName = "AuditTrailsIssues";
