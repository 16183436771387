import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {Routes} from "react-router";
import {Group} from "../../enums/group";
import {IGameSidebarData, operatorSidebarData, rightsHolderSidebarData} from "./CmsSideBarData/CmsSideBarData";
import styles from './index.module.scss';
import Header from "./children/Header/Header";
import Sidebar from "./children/Sidebar/Sidebar";
import {getAllowedPages} from "./AppRoutes/getAllowedPages";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {formatAllowedPages} from "./AppRoutes/helpers/formatAllowedPages";
import DefaultPageSetter from "./AppRoutes/helpers/DefaultPageSetter";
import {getStartingPage} from "./helpers/getStartingPage";
import OutsideClickHandler from "react-outside-click-handler";
import {setRoutesByUserRole} from "./AppRoutes/setRoutesByUserRole";
import {getAlerts} from "../../store/alerts/alertsSlice";
import AlertPopup from "../../components/AlertSystem/children/AlertPopup/AlertPopup";

const Cms: React.FC = () => {
    const [sidebarDataset, setSidebarDataset] = useState(IGameSidebarData);
    const [menuVisible, setMenuVisible] = useState(false);
    const {role, pages} = useAppSelector(state => state.userInfo);
    const lastVisitedPage = localStorage.getItem('lastVisitedPage');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (role) {
            if (role === Group.RightHolder) {
                setSidebarDataset(getAllowedPages(formatAllowedPages([...pages, 31]), rightsHolderSidebarData));
                getStartingPage(role, lastVisitedPage, navigate, setMenuVisible);
                dispatch(getAlerts({
                    pageNumber: 1,
                    itemsPerPage: 6
                }))
            }
            if (role === Group.Operator) {
                setSidebarDataset(getAllowedPages(formatAllowedPages(pages), operatorSidebarData));
                getStartingPage(role, lastVisitedPage, navigate, setMenuVisible);
            }
            if (role === Group.IGame) {
                setSidebarDataset(getAllowedPages(formatAllowedPages(pages), IGameSidebarData));
                getStartingPage(role, lastVisitedPage, navigate, setMenuVisible);
                dispatch(getAlerts({
                    pageNumber: 1,
                    itemsPerPage: 6
                }))
            }
            if (role === Group.TechnicalProvider) {
                navigate("cms/technicalProvider-upcoming-fixtures");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [role]);

    const menuTriggerHandler = () => {
        setMenuVisible(!menuVisible);
    }
    const outSideSidebarClickHandler = (e: any) => {
        if (menuVisible) {
            const isClickOnBurger = e.target.className.includes("menuButton");
            if (!isClickOnBurger) {
                setMenuVisible(false)
            }
        }
    }

    return (
        <div className={styles.cmsContainer}>
            <Header onClickHandler={menuTriggerHandler}/>
            <AlertPopup/>
            {role !== Group.TechnicalProvider &&
                <OutsideClickHandler onOutsideClick={outSideSidebarClickHandler}>
                    <Sidebar
                        sidebarDataset={sidebarDataset}
                        menuVisible={menuVisible}
                        setMenuVisible={setMenuVisible}
                    />
                </OutsideClickHandler>
            }

            <DefaultPageSetter>
                <div className={styles.pageContainer}>
                    <Routes>
                        {setRoutesByUserRole(role, pages)}
                    </Routes>
                </div>
            </DefaultPageSetter>

            {menuVisible &&
                <div onClick={() => setMenuVisible(false)} className={styles.pageDimmer}></div>
            }
        </div>
    );
}

Cms.displayName = "Cms"
export default Cms;
