import {ICompetition} from "../../../../../../store/init/initSlice";

export const formatCompetitionsOptions = (options: ICompetition[]) => {
    if (options) {
        const dropdownOptions = options?.map(opt => {
            return {
                value: opt.id.toString(),
                label: opt.name,
                duration: opt.duration,
                extraTimeDuration: opt.extraTimeDuration,
                country: opt.country
            }
        })
        return dropdownOptions.sort((a, b) => a.label?.localeCompare(b.label));
    } else {
        return []
    }
}


