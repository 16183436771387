import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import initInfoReducer from './init/initSlice';
import rightHolderBookingReducer from './rightHolderBooking/rightHolderBookingSlice';
import operatorAccess from './operatorAccess/operatorAccessSlice';
import operatorPricing from './operatorPricing/operatorPricingSlice';
import geoRestrictions from './geoRestrictions/geoRestrictionsSlice';
import {reducer as toastrReducer} from 'react-redux-toastr';
import rightHolderBillingOperator from "./rightsHolderBilling/rightHolderBillingOperatorSlice";
import settings from "./settings/settingsSlice";
import iGameOperatorBillingSlice from './iGameOperatorBilling/iGameOperatorBillingSlice';
import rightHolderAnalyticsReducer from './rightHolderAnalytics/rightHolderAnalyticsSlice';
import operatorAnalyticsReducer from "./operatorAnalytics/operatorAnalyticsSlice";
import igameAnalyticsReducer from "./igameAnalytics/igameAnalyticsSlice";
import RHIGameBillingSlice from "./rightsHolderBilling/iGameBillingSlice";
import concurrencyInspector from "./concurrencyTools/concurrencyToolsSlice";
import publishing from './publishing/publishingSlice';
import iGameRHBilling from './iGameRHBilling/iGameRHBillingSlice';
import iGameReportingSlice from "./iGameReporting/iGameReportingSlice";
import userManagement from './userManagement/userManagementSlice';
import iGameInit from './iGameInit/iGameInitSlice';
import userInfoSlice from "./userInfo/userInfoSlice";
import rightAssignmentSlice from "./rightAssignment/rightAssignmentSlice";
import otherRestrictionsSlice from "./otherRestrictions/otherRestrictionsSlice";
import ingestResourcesSlice from './ingestResources/ingestResourcesSlice';
import auditTrailsRightsAssignmentSlice from './auditTrailsRightsAssignment/auditTrailsRightsAssignment';
import auditTrailsOperatorAccessSlice from './auditTrailsOperatorAccess/auditTrailsOperatorAccess';
import auditTrailsPricingSlice from "./auditTrailsPricing/auditTrailsPricing";
import auditTrailsIngestResourcesSlice from "./auditTrailsIngestResources/auditTrailsIngestResourcesSlice";
import auditTrailsBookingsSlice from "./auditTrailsBookings/auditTrailsBookingsSlice";
import auditTrailsRightsRestrictionsSlice from "./auditTrailsRightsRestrictions/auditTrailsRightsRestrictionsSlice";
import auditTrailsUserManagementSlice from "./auditTrailsUserManagement/auditTrailsUserManagementSlice";
import auditTrailsIssuesSlice from "./auditTrailsIssues/auditTrailsIssuesSlice";
import reportingIssuesSlice from "./reportingIssuesSlice/reportingIssuesSlice";
import reportingFixturesSlice from "./reportingFixtures/reportingFixturesSlice"
import reportingGeneralIssuesSlice from "./generalIssues/generalIssuesSlice";
import btBookingsSlice from "./btBookings/btBookingsSlice";
import operatorBookedContentSlice from "./operatorBookedContent/operatorBookedContentSlice";
import contentAnalysisSlice from "./contentAnalysis/contentAnalysisSlice";
import headerActionsSlice from "./headerActionsSlice/headerActionsSlice";
import operatorRightsOverviewSlice from "./operatorRightsOverview/operatorRightsOverviewSlice";
import auditTrailsCommonSlice from "./auditTrailsCommon/auditTrailsCommonSlice";
import operatorCart from "./carts/operatorCart/operatorCartSlice";
import alertsSlice from "./alerts/alertsSlice";
import {createSocketMiddleware} from "../socket";
import propertyDetailSlice from "./propertyDetail/propertyDetail";
import operatorPortfolio from "./operatorPortfolio/operatorPortfolioSlice";
import RhReportingSlice from "./rhReporting/rhReportingSlice";
import bookFixtureCartSlice from './carts/operatorBFCart/bookFixtureCartSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        toastr: toastrReducer,
        initInfo: initInfoReducer,
        rightHolderBooking: rightHolderBookingReducer,
        operatorAccess,
        rightHolderBillingOperator,
        operatorPricing,
        geoRestrictions,
        settings,
        operatorCart,
        iGameOperatorBilling: iGameOperatorBillingSlice,
        rightHolderAnalytics: rightHolderAnalyticsReducer,
        operatorAnalytics: operatorAnalyticsReducer,
        igameAnalytics: igameAnalyticsReducer,
        iGameBilling: RHIGameBillingSlice,
        concurrencyInspector,
        publishing,
        iGameRHBilling,
        iGameReportingSlice,
        userManagement,
        iGameInit,
        userInfo: userInfoSlice,
        rightAssignment: rightAssignmentSlice,
        otherRestrictions: otherRestrictionsSlice,
        ingestResources: ingestResourcesSlice,
        auditTrailsBookings: auditTrailsBookingsSlice,
        auditTrailsIngestResources: auditTrailsIngestResourcesSlice,
        auditTrailsRightsAssignment: auditTrailsRightsAssignmentSlice,
        auditTrailsOperatorAccess: auditTrailsOperatorAccessSlice,
        auditTrailsPricing: auditTrailsPricingSlice,
        auditTrailsRightsRestrictions: auditTrailsRightsRestrictionsSlice,
        auditTrailsIssues: auditTrailsIssuesSlice,
        reportingIssues: reportingIssuesSlice,
        reportingFixtures: reportingFixturesSlice,
        reportingGeneralIssues: reportingGeneralIssuesSlice,
        auditTrailsUserManagement: auditTrailsUserManagementSlice,
        btBookings: btBookingsSlice,
        contentAnalysis: contentAnalysisSlice,
        operatorBookedContent: operatorBookedContentSlice,
        headerActions: headerActionsSlice,
        operatorRightsOverview: operatorRightsOverviewSlice,
        auditTrailsCommon: auditTrailsCommonSlice,
        alerts: alertsSlice,
        propertyDetail: propertyDetailSlice,
        operatorPortfolio,
        rhReporting: RhReportingSlice,
        bookFixtureCart: bookFixtureCartSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(createSocketMiddleware()),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
