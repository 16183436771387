import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {Controller, useForm} from "react-hook-form";
import {
    getOperatorAccessInit,
    IInputOption,
    IUpdateAccess, updateConfirmedAccess
} from "../../../../../store/operatorAccess/operatorAccessSlice";
import styles from "../ContentAccessForm/index.module.scss";
import Checkbox from "../../../../../components/Checkbox";
import Input from "../../../../../components/Input/Input";

interface UpdateExistingAccessFormProps {
    closeForm: () => void;
    rightHolderId: string | null;
    updatingAccessId: string;
    rightName: string;
}

interface IFormInput {
    operator: { label: string; value: string }[];
    right: { label: string; value: string }[];
    fixtures: number;
}

export const UpdateExistingAccessForm: React.FC<UpdateExistingAccessFormProps> = React.memo((
    {
        closeForm,
        rightHolderId,
        updatingAccessId,
        rightName
    }) => {
    const [isAllFixturesSelected, setIsAllFixturesSelected] = useState(false);
    const [fixtureCount, setFixtureCount] = useState<number | undefined>(undefined);
    const [operatorName, setOperatorName] = useState("");
    const dispatch = useAppDispatch()
    const {operatorInpOptions, rightsInpOptions, details} = useAppSelector(state => state.operatorAccess)
    const {handleSubmit, control, setValue, formState: {errors}} = useForm<IFormInput>();

    useEffect(() => {
        dispatch(getOperatorAccessInit(rightHolderId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (details && operatorInpOptions.length && rightsInpOptions.length) {
            setIsAllFixturesSelected(details.allFixtures);
            setValue("fixtures", details.fixturesCount)
            setFixtureCount(details.fixturesCount);
            setOperatorName(findName(operatorInpOptions, details.operatorId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details, operatorInpOptions, rightsInpOptions]);

    const toggleAssignedAllFixtures = () => {
        setFixtureCount(0)
        setValue("fixtures", 0)
        setIsAllFixturesSelected(!isAllFixturesSelected)
    }

    const findName = (options: IInputOption[], id: string) => {
        const option = options.find(item => item.id === id);

        return option ? option.name : "";
    }

    const updateAccess = () => {
        if (details) {
            const body = {
                rightHolderId,
                id: updatingAccessId,
                operatorId: details.operatorId,
                rightId: details.competitionId,
                fixturesCount: fixtureCount,
                assignedAllFixtures: isAllFixturesSelected
            } as IUpdateAccess

            dispatch(updateConfirmedAccess(body));
            closeForm();
        }
    }

    return (
        <div className={styles.overlay}>
            <form
                data-testid={"form"}
                onClick={(e) => e.stopPropagation()}
                onSubmit={handleSubmit(updateAccess)}
                className={styles.form}>
                <div className={styles.content}>
                    <div>
                        <h6 className={styles.titleInner}>
                            <span className={styles.title}>Existing Access Update</span>
                        </h6>
                    </div>
                    <div>
                        <label className={styles.label}>Operator
                            <Controller
                                control={control}
                                name="fixtures"
                                render={({field: {onChange}}) => (
                                    <Input
                                        onChange={(e) => onChange(e)}
                                        disabled={true}
                                        value={operatorName}
                                    />
                                )}
                            />
                        </label>
                    </div>
                    <div>
                        <label className={styles.label}>Right(s)
                            <Controller
                                control={control}
                                name="fixtures"
                                render={({field: {onChange}}) => (
                                    <Input
                                        onChange={(e) => onChange(e)}
                                        disabled={true}
                                        value={rightName}
                                    />
                                )}
                            />
                        </label>
                    </div>

                    <div>
                        <div className={styles.label}>
                            <div className={styles.checkboxInner}>
                                <p>Fixture</p>
                                <div className={styles.checkbox}>
                                    <span>All Fixtures</span>
                                    <Checkbox isChecked={isAllFixturesSelected}
                                              onChangeHandler={() => toggleAssignedAllFixtures()}
                                              filled={true}
                                    />
                                </div>
                            </div>
                            <Controller
                                control={control}
                                name="fixtures"
                                rules={{
                                    validate: {
                                        isPositive: value => Number(value) > 0 || Number(value) === 0,
                                        // eslint-disable-next-line no-mixed-operators
                                        isAllSelected: (value) => (isAllFixturesSelected) && Number(value) === 0 || Number(value) > 0
                                    }
                                }}
                                render={({field: {onChange}}) => (
                                    <Input
                                        data-testid={"fixtureCount"}
                                        onChange={(e) => {
                                            onChange(e);
                                            setFixtureCount(Number(e.target.value))
                                        }}
                                        disabled={isAllFixturesSelected}
                                        value={fixtureCount ? fixtureCount : ''}
                                        type="number"
                                        placeholder={"Number of Fixtures"}
                                    />
                                )}
                            />
                            {errors?.fixtures?.type === "isPositive" && !isAllFixturesSelected &&
                                <div className={styles.formErrorBlock}>
                                    Set positive number of fixtures or use "All fixtures"
                                </div>
                            }
                            {errors?.fixtures?.type === "isAllSelected" && !isAllFixturesSelected &&
                                <div className={styles.formErrorBlock}>
                                    Select "All Fixture" or set count of fixture
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.buttons}>
                    <button
                        onClick={closeForm}
                        className={styles.cancelButton}
                        type="button">Cancel
                    </button>
                    <button
                        className={styles.submitButton}
                        type="submit">Submit
                    </button>
                </div>
            </form>
        </div>
    );
});

export default UpdateExistingAccessForm;
UpdateExistingAccessForm.displayName = "UpdateExistingAccessForm"
