import React, {useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import SelectInput from "../../../../../components/SelectInput/SelectInput";
import Input from "../../../../../components/Input/Input";
import {useAppDispatch, useAppSelector} from "@hooks/hooks";
import {SingleValue} from "react-select";
import {formatSelectOptions} from "@helpers/formatSelectOptions";
import styles from './styles.module.scss'
import RadioButton from "../../../../../components/RadioButton/RadioButton";
import {createCompetition, ICreatedCompetition} from "@store/rightAssignment/rightAssignmentSlice";
import Checkbox from "../../../../../components/Checkbox";
import {ISelectOption} from "@store/operatorBookedContent/operatorBookedContentSlice";

interface IFormInput {
    sport: SingleValue<ISelectOption>
    region: SingleValue<ISelectOption>
    type: SingleValue<ISelectOption>
    competitionName: string,
    competitionDataSource: SingleValue<ISelectOption>,
    extraTime: boolean
}

interface ICompetitionFormProps {
    closeForm: () => void
}

enum Gender {
    Male = 1,
    Female = 2,
    Mixed = 3
}

const CompetitionForm: React.FC<ICompetitionFormProps> = ({closeForm}) => {
    const dispatch = useAppDispatch()
    const {
        competitionInit: {
            competitionTypes,
            sports,
            countries,
        }
    } = useAppSelector(state => state.rightAssignment)
    const {handleSubmit, control, formState: {errors}} = useForm<IFormInput>();
    const [gender, setGender] = useState<Gender.Male | Gender.Female | Gender.Mixed>(Gender.Male);
    const [isExtraTime, setIsExtraTime] = useState<boolean>(false);

    const onCreateCompetition = (competition: IFormInput) => {
        const payload = {
            name: competition.competitionName,
            sportId: competition.sport!.value,
            regionId: competition.region!.value,
            type: Number(competition.type!.value),
            extraTime: isExtraTime,
            gender
        } as ICreatedCompetition
        dispatch(createCompetition(payload))
        closeForm()
    }

    return (
        <div className={styles.overlay}>
            <form
                onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                onClick={(e) => e.stopPropagation()}
                onSubmit={handleSubmit(onCreateCompetition)}
                className={styles.form}>
                <h6 className={styles.title}>New Property</h6>
                <div className={styles.content}>
                    <div className={styles.formContent}>
                        <div className={styles.inputInner}>
                            <label className={styles.label}>Property Name
                                <Controller
                                    control={control}
                                    name="competitionName"
                                    defaultValue={""}
                                    rules={{required: true,}}
                                    render={({field: {onChange, value}}) => (
                                        <Input
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            value={value}
                                            type="text"
                                            placeholder={"Enter property name"}
                                        />
                                    )}
                                />
                                {errors?.competitionName?.type === "required" &&
                                    <div className={styles.formErrorBlock}>Property Name is required field</div>
                                }
                            </label>
                        </div>
                        <div className={styles.inputInner}>
                            <label className={styles.label}>Sport
                                <Controller
                                    name="sport"
                                    defaultValue={null}
                                    control={control}
                                    rules={{required: true}}
                                    render={({field: {onChange, value}}) =>
                                        <SelectInput
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e)
                                            }}
                                            options={formatSelectOptions(sports)}
                                            placeholder={"Choose an option"}
                                        />}
                                />
                                {errors.sport && <div className={styles.formErrorBlock}>Sport is required field</div>}
                            </label>
                        </div>
                        <div className={styles.inputInner}>
                            <label className={styles.label}>Region
                                <Controller
                                    name="region"
                                    defaultValue={null}
                                    control={control}
                                    rules={{required: true}}
                                    render={({field: {onChange, value: operator}}) => <SelectInput
                                        value={operator}
                                        onChange={(e) => {
                                            onChange(e)
                                        }}
                                        options={formatSelectOptions(countries)}
                                        placeholder={"Choose an option"}
                                    />}
                                />
                                {errors.region && <div className={styles.formErrorBlock}>Region is required field</div>}
                            </label>
                        </div>
                        <div className={styles.inputInner}>
                            <label className={styles.label}>Type
                                <Controller
                                    name="type"
                                    defaultValue={null}
                                    control={control}
                                    rules={{required: true}}
                                    render={({field: {onChange, value: operator}}) => <SelectInput
                                        value={operator}
                                        onChange={(e) => {
                                            onChange(e)
                                        }}
                                        options={formatSelectOptions(competitionTypes)}
                                        placeholder={"Choose an option"}
                                    />}
                                />
                                {errors.type && <div className={styles.formErrorBlock}>Type is required field</div>}
                            </label>
                        </div>
                        <div className={styles.inputInner}>
                            <div
                                onClick={(e) => e.stopPropagation()}
                                className={styles.label}
                            >Gender
                                <div className={styles.genderBlock}>
                                    <div
                                        className={styles.radioInner}
                                        onClick={() => setGender(Gender.Male)}
                                    >
                                        <RadioButton
                                            checked={gender === Gender.Male}
                                            text={"Male"}
                                        />
                                    </div>
                                    <div
                                        className={styles.radioInner}
                                        onClick={() => setGender(Gender.Female)}
                                    >
                                        <RadioButton checked={gender === Gender.Female} text={"Female"}/>
                                    </div>
                                    <div
                                        className={styles.radioInner}
                                        onClick={() => setGender(Gender.Mixed)}
                                    >
                                        <RadioButton checked={gender === Gender.Mixed} text={"Mixed"}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.extraTime}>
                            <Controller
                                name="extraTime"
                                control={control}
                                render={() =>
                                    <Checkbox
                                        onChangeHandler={() => setIsExtraTime(!isExtraTime)}
                                        isChecked={isExtraTime}
                                        filled={true}
                                    />
                                }
                            />
                            <span>Extra Time</span>
                        </div>
                    </div>
                </div>
                <div className={styles.buttons}>
                    <button
                        onClick={closeForm}
                        className={styles.cancelButton}>Cancel
                    </button>
                    <button
                        className={styles.submitButton}
                        type="submit">Create
                    </button>
                </div>
            </form>
        </div>
    );
};

CompetitionForm.displayName = "CompetitionForm"
export default CompetitionForm;

