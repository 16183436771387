import React, { useState, useEffect } from 'react';
import {Control, Controller, FieldErrors, FieldValues, UseFormGetValues} from "react-hook-form";
import {ISelectedMethods} from "../../types/types";
import {useAppSelector} from "@hooks/hooks";
import {RootState} from "@store/index";
import {IIpChannel} from "@store/init/initSlice";
import SelectInput from "@components/SelectInput/SelectInput";
import {formatSelectOptions} from "@helpers/formatSelectOptions";
import {
    IBtTowerDetailsResponse,
    ISatelliteDetailsResponse
} from "@store/rightHolderBooking/rightHolderBookingSlice";
import IngestRender from "../../helpers/IngestRender";
import {IngestFormController, IngestTabs} from "@enums/ingest";

interface IIGameChannelFormProps {
    selectedMethods: ISelectedMethods;
    setValue: (name: string, value: IGameChannelInput) => void;
    control: Control<FieldValues, IGameChannelInput>
    errors: FieldErrors;
    currentTabState: IngestTabs;
    getValues: UseFormGetValues<FieldValues>
}

export interface IGameChannelInput {
    label: string;
    value: string;
}

export const IGameChannels = (
    {
        currentTabState,
        setValue,
        getValues,
        control,
        errors,
    }: IIGameChannelFormProps) => {
    const {ipChannels} = useAppSelector((state: RootState) => state.initInfo);
    const {details, isActivateBackup} = useAppSelector((state: RootState) => state.rightHolderBooking);
    const {satellite, btTower} = details;
    const {
        satelliteFixture,
        btTowerFixture
    } = useAppSelector((state: RootState) => state.rightHolderBooking.backupDetails);
    const [isDisabled, setIsDisabled] = useState(false);

    const findChannelAndSet = (channels: IIpChannel[], resourceId: string | undefined) => {
        const channel = formatSelectOptions(channels).find((c) => {
            return c.value === resourceId?.toString()
        });
        if (channel) {
            setValue(IngestFormController.IGameChannels, channel);
        }
    };

    const generateIngestResourceId = () => satellite?.ingestResourceId ?? btTower?.ingestResourceId ?? null;

    const setChannelHandler = (
        satellite: ISatelliteDetailsResponse | null,
        btTower: IBtTowerDetailsResponse | null
    ) => {

        if (ipChannels && !isActivateBackup && (satellite ?? btTower)) {
            const ingestResourceId = generateIngestResourceId();
            if (ingestResourceId) findChannelAndSet(ipChannels, ingestResourceId);
        }

        if (ipChannels && isActivateBackup) {
            const backupIngestResourceId = satelliteFixture?.ingestResourceId ?? btTowerFixture?.ingestResourceId;
            findChannelAndSet(ipChannels, backupIngestResourceId);
        }
    };

    useEffect(() => {
        setChannelHandler(satellite, btTower);
        if (ipChannels.length) {
            setIsDisabled(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ipChannels, satellite, btTower, btTowerFixture, satelliteFixture, isActivateBackup]);

    const handleChannelChange = (selectedChannel: IGameChannelInput | null) => {
        if (selectedChannel) {
            setValue(IngestFormController.IGameChannels, selectedChannel);
        }
    };


    const iGameChannels = (
        <label>
            {currentTabState === IngestTabs.Primary ? "Primary channel" : "Backup Channel"}
            <Controller
                control={control}
                name={IngestFormController.IGameChannels}
                rules={{required: true}}
                render={({field: {onChange, value}}) => (
                    <SelectInput
                        name={currentTabState === IngestTabs.Primary ? IngestFormController.IGameChannels : IngestFormController.BackupIGameChannels}
                        options={formatSelectOptions(ipChannels)}
                        onChange={(x) => {
                            onChange(x);
                            handleChannelChange(x);
                        }}
                        value={value}
                        disabled={isDisabled}
                    />
                )}
            />
            {errors.channelForm && (
                <span data-testid={"channelRequiredError"}>
                    This field is required
                </span>
            )}
        </label>
    );


    return (IngestRender.iGameChannels(currentTabState, iGameChannels, getValues))

};
