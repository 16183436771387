import React, {useEffect, useState} from 'react';
import styles from "../../index.module.scss";
import {FiCheck} from "react-icons/fi";
import {FaChevronRight} from "react-icons/fa";
import {IMenuStructure} from "../../../../pages/Cms/AppRoutes/routes/RouteInterface";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../../../hooks/hooks";
import {getAllowedPages} from "../../../../pages/Cms/AppRoutes/getAllowedPages";
import {formatAllowedPages} from "../../../../pages/Cms/AppRoutes/helpers/formatAllowedPages";
import uuid from "react-uuid";

interface IMenuDropdownProps {
    dropdownMenuVisible: boolean,
    menuStructure: IMenuStructure,
    title?: string,
    subTitle?: string,
    setDropdownSubMenuVisible: (arg: boolean) => void,
    dropdownSubMenuVisible: boolean
}

const MenuDropdown: React.FC<IMenuDropdownProps> = React.memo((
    {
        dropdownMenuVisible,
        menuStructure,
        title,
        subTitle,
        setDropdownSubMenuVisible,
        dropdownSubMenuVisible
    }) => {
    const [currentMenuItem, setCurrentMenuItem] = useState<IMenuStructure>();
    const [dropdownSubMenuData, setDropdownSubMenuData] = useState<IMenuStructure[]>();
    const [menuDataset, setMenuDataset] = useState<any>([]);
    const {pages} = useAppSelector(state => state.userInfo);
    const navigate = useNavigate();

    useEffect(() => {
        const allowedPages = getAllowedPages(formatAllowedPages(pages), [{
            ...menuStructure,
            children: menuStructure.children
        }])[0];
        if (allowedPages?.children?.length) {
            allowedPages.children = allowedPages.children.filter((item) => item.title !== "Cart" && item.title !== "Property Detail");
        }
        setMenuDataset(allowedPages);
    }, [menuStructure, pages]);

    useEffect(() => {
        const initialPageMenuItem = menuDataset?.children?.find((menuItem: IMenuStructure) => title === menuItem.title);
        if (initialPageMenuItem) {
            setCurrentMenuItem(initialPageMenuItem);
            if (initialPageMenuItem.children) {
                setDropdownSubMenuData(initialPageMenuItem.children)
            }
        }
    }, [title, menuDataset]);

    const handleMenuItemClick = (component: IMenuStructure) => {
        setCurrentMenuItem(component);
        if (component.children) {
            setDropdownSubMenuData(component.children)
            setDropdownSubMenuVisible(true);
        } else {
            navigate(component.path);
        }
    }

    const getDropdownSubMenuStyles = (): string => {
        if (dropdownMenuVisible) {
            return `${styles.componentsDropdown} ${styles.comboSubMenuDropdown}`;
        } else {
            return `${styles.componentsDropdown} ${styles.subMenuDropdown}`;
        }
    }

    return (
        <>
            {
                dropdownMenuVisible &&
                <ul className={styles.componentsDropdown}>
                    {
                        menuDataset?.children?.map((item: IMenuStructure) => (
                            <li
                                data-testid="menuItem"
                                key={uuid()}
                                onClick={() => handleMenuItemClick(item)}
                                className={item.title === currentMenuItem?.title
                                    ? `${styles.componentItem} ${styles.activeComponent}` : styles.componentItem}

                            >
                                {item.title}
                                {item.title === title && <FiCheck color={'#FF3466'} size={'20px'}/>}
                                {item.children && <FaChevronRight size={'16px'}/>}
                            </li>
                        ))
                    }
                </ul>
            }
            {
                dropdownSubMenuVisible && dropdownSubMenuData &&
                <ul data-testid="subMenu" className={getDropdownSubMenuStyles()}>
                    {
                        dropdownSubMenuData.map(item => (
                            <li
                                className={styles.componentItem}
                                key={uuid()}
                                onClick={() => handleMenuItemClick(item)}
                            >
                                {item.title}
                                {
                                    item.title === subTitle &&
                                    <FiCheck color={'#FF3466'} size={'20px'}/>
                                }
                            </li>
                        ))
                    }
                </ul>
            }
        </>
    );
});

export default MenuDropdown;
