import React, {useEffect} from 'react';
import {Route, Routes} from "react-router";
import Login from "./pages/Login/Login";
import Cms from "./pages/Cms/Cms";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import {useAppDispatch} from "./hooks/hooks";
import {getCurrentUserInfo, IUserInfoState} from "./store/userInfo/userInfoSlice";
import './style.scss';
import {Group} from "./enums/group";
import {connectSocket} from "./store/auth/authSlice";
import {toastr} from "react-redux-toastr";
import {useSearchParams} from "react-router-dom";

function App() {
    const dispatch = useAppDispatch();
    const token = localStorage.getItem("book-token");
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const changePasswordMode = searchParams.get("change");
        if (token && !changePasswordMode) {
            dispatch(getCurrentUserInfo(null)).then(({payload, meta}) => {
                if (meta.requestStatus === "rejected") {
                    toastr.error("Error", "Failed to fetch user info.");
                    return;
                }

                if ((payload as IUserInfoState)?.role !== Group.Operator) {
                    dispatch(connectSocket(token));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="App">
            <Routes>
                <Route path={"/*"} element={token ? <Cms/> : <Login/>}>
                    <Route path={"cms/*"} element={<Cms/>}/>
                </Route>
                <Route path={"/login"} element={<ResetPassword/>}/>
            </Routes>
        </div>
    );
}

App.displayName = "App"
export default App;
