import Multiselect from 'multiselect-react-dropdown';
import React from "react";
import './index.scss';
import CloseIcon from '../../img/closeSelectItem.svg';
import {ISelectItem, IUserRole} from "../../store/userManagement/userManagementSlice";


interface ITestSelectProps {
    options: ISelectItem[] | (IUserRole | undefined)[],
    selectedValues: (ISelectItem | undefined)[] | (IUserRole | undefined)[],
    onSelect: (selectedData: ISelectItem[]) => void,
    onRemove: (selectedData: ISelectItem[]) => void,
}


const MultiSelect: React.FC<ITestSelectProps> = ({options, selectedValues, onSelect, onRemove}) => {
    return (
        <Multiselect
            options={options}
            selectedValues={selectedValues}
            onSelect={onSelect}
            onRemove={onRemove}
            closeOnSelect={false}
            displayValue="name"
            showCheckbox={true}
            customCloseIcon={<><img src={CloseIcon} alt=""/></>}
            data-testid="test-multiselect"
        />
    );
};

MultiSelect.displayName = "MultiSelect"
export default MultiSelect;
