import React, {LegacyRef} from "react";
import styles from "./index.module.scss";

export interface IAppTableWhiteProps {
    children: React.ReactNode;
    reference?: undefined | LegacyRef<HTMLTableElement>;
}

const AppTableWhite: React.FunctionComponent<IAppTableWhiteProps> = ({children, reference}) => {
    return (
        <table className={styles.appTable} ref={reference} data-testid="app-table-white">
            {children}
        </table>
    )
}
AppTableWhite.displayName = "AppTableWhite"
export default AppTableWhite;
