import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';
import PrimaryButton from "../../../../components/PrimaryButton/PrimaryButton";
import {useAppDispatch, useAppSelector, useSearchUsers} from '../../../../hooks/hooks';
import SettingsForm from "./children/SettingsForm/SettingsForm";
import {
    getOperatorUsers,
    getRightHolderUsers,
    getRoles,
    searchOperatorUsers,
    searchRightHolderUsers
} from "../../../../store/settings/settingsSlice";
import SettingsTable from "./children/SettingsTable/SettingsTable";
import Loader from "../../../../components/Loader/Loader";
import {IMenuStructure} from "../../AppRoutes/routes/RouteInterface";
import SearchInput from "../../../../components/SearchInput/SearchInput";
import {InnerRole} from "../../../../enums/innerRole";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import {useDisableScroll} from "../../../../hooks/useDisableScroll";

export interface ISettingsProps {
    menuStructure: IMenuStructure;
    roleName: string;
}

const UserManagementRHOP: React.FC<ISettingsProps> = React.memo(({menuStructure, roleName}) => {
    const {
        searchValue,
        onSearch
    } = useSearchUsers(roleName === 'rightHolder' ? searchRightHolderUsers : searchOperatorUsers)
    const [isOpenNewForm, setIsOpenNewForm] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    useDisableScroll(isOpenNewForm);
    const {clientName, loading} = useAppSelector(state => state.settings);
    const {specificRole} = useAppSelector(state => state.userInfo);

    useEffect(() => {
        if (roleName === 'rightHolder') {
            dispatch(getRightHolderUsers(null));
        } else if (roleName === 'operator') {
            dispatch(getOperatorUsers(null));
        }
        dispatch(getRoles(null));
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return (
        <div className={styles.pageWrapper}>
            {isOpenNewForm && <SettingsForm
                closeForm={() => setIsOpenNewForm(false)}
                roleName={roleName}
            />}
            {loading && <Loader/>}
            {!loading && <>
                <PageHeader menuStructure={menuStructure} title={'User Management'}/>
                <div className={styles.pageContainer}>
                    <div className={styles.subHeader}>
                        <h1>User Management: {clientName}</h1>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.searchWrapper}>
                            <div className={styles.searchInput}>
                                <SearchInput
                                    searchValue={searchValue}
                                    onChange={onSearch}/>
                            </div>
                            {
                                specificRole === InnerRole.SuperAdmin &&
                                <div className={styles.addBtn}>
                                    <PrimaryButton onClick={() => setIsOpenNewForm(true)}>
                                        <div className={styles.addBtnContent}>
                                            {roleName === "operator" && <p>Add {roleName}</p>}
                                            {roleName !== "operator" && <p>Add Rights Holder</p>}
                                            <p>+</p>
                                        </div>
                                    </PrimaryButton>
                                </div>
                            }
                        </div>
                        <SettingsTable
                            roleName={roleName}
                        />
                    </div>
                </div>
            </>
            }
        </div>
    );
});

export default UserManagementRHOP;
