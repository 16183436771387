import {Group} from "../../../../../enums/group";
import {NavigateFunction} from "react-router-dom";

interface IHandleCardNavigation {
    id: string;
    currentUser: string | null;
    role: string;
    navigate: NavigateFunction;
}

export const handleCartNavigation = ({id, navigate, currentUser, role}: IHandleCardNavigation) => {
    if (id && id !== null) {
        if (role === Group.IGame && currentUser === Group.RightHolder) {
            navigate(`/cms/igame-rightHolders/${id}/rightHolder-cart`);
        }

        if (role === Group.IGame && currentUser === Group.Operator) {
            navigate(`/cms/igame-rightHolders/${id}/operator-cart`);
        }
    }

    if (!id) {
        switch (role) {
            case Group.RightHolder:
                return navigate(`/cms/igame-rightHolders/null/rightHolder-cart`);
            case Group.Operator:
                return navigate(`/cms/operator-cart`);
            case Group.IGame:
                return;

        }
    }
}
