import React, {useEffect, useRef, useState} from 'react';
import styles from './index.module.scss';
import {Route, Routes} from "react-router";
import UserManagementTable from "./children/UserManagementTable/UserManagementTable";
import {NavLink} from "react-router-dom";
import IGameTable from "./children/UsersPages/IGameUsers";
import DownloadBlock from "../../../../../components/DownloadBlock/DownloadBlock";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {pdfFormatter} from "../../../../../helpers/pdfFormatter";
import {
    clearCSVFile,
    getIGameCSV,
    getOperatorsCSV,
    getRightsHolderCSV, getTechnicalProviderCSV
} from "../../../../../store/userManagement/userManagementSlice";
import TechnicalProvidersUsers from "./children/UsersPages/TechnicalProvidersUsers";
import {IPageMenuProps} from "../../../AppRoutes/routes/RouteInterface";
import PageHeader from "../../../../../components/PageHeader/PageHeader";
import IGameSubHeader from "../../../../../components/IGameSubHeader/IGameSubHeader";
import UserTypesTable from "./children/UsersPages/UserTypesTable";
import SearchByTextInput from "../../../../../components/SearchByTextInput/SearchByTextInput";
import {useUserManagementSearch} from "./hooks/useUserManagementSearch";

export enum PageTabs {
    I_GAME_USERS = "iGame",
    RIGHTS_HOLDERS = "Rights Holder",
    OPERATORS = "Operator",
    TECHNICAL_PROVIDERS = "Technical Providers"
}

const UserManagement: React.FC<IPageMenuProps> = React.memo(({menuStructure}) => {
    const dispatch = useAppDispatch();
    const {
        rightHoldersCount,
        operatorsCount,
        csvFile,
        csvFileName,
    } = useAppSelector(state => state.userManagement);
    const [isIGameSubHeaderVisible, setIsIGameSubHeaderVisible] = useState(true);
    const pdfLayout = useRef<HTMLDivElement | null>(null);
    const [currentTab, setCurrentTab] = useState<string>(PageTabs.RIGHTS_HOLDERS);
    const [userIdInTab, setUserIdInTab] = useState<string | undefined>(undefined);
    const [isDownloadingPDF, setIsDownloadingPDF] = useState<boolean>(false);
    const {searchValue, onSearch, setSearchValue} = useUserManagementSearch(currentTab);

    useEffect(() => {
        getCSVFileByTab()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userIdInTab, currentTab])

    useEffect(() => {
        if (currentTab && isDownloadingPDF) {
            const layout = pdfLayout.current;

            if (layout) {
                pdfFormatter(layout);
            }
        }
        setIsDownloadingPDF(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDownloadingPDF]);

    const getCSVFileByTab = () => {
        if (userIdInTab && currentTab === PageTabs.OPERATORS) {
            dispatch(getOperatorsCSV(userIdInTab))

        } else if (userIdInTab && currentTab === PageTabs.RIGHTS_HOLDERS) {
            dispatch(getRightsHolderCSV(userIdInTab))

        } else if (currentTab === PageTabs.I_GAME_USERS) {
            dispatch(getIGameCSV(null))

        } else if (currentTab === PageTabs.TECHNICAL_PROVIDERS) {
            dispatch(getTechnicalProviderCSV(null))
        }
    }

    const pdfHandler = () => {
        setIsDownloadingPDF(true)
    }

    return (
        <>
            <PageHeader menuStructure={menuStructure} title={'User Management'}>
                <DownloadBlock
                    fileName={csvFileName}
                    pdfHandler={pdfHandler}
                    isActiveLink={!!currentTab}
                    csvFile={csvFile}
                />
            </PageHeader>
            <div className={styles.pageInner}>
                {isIGameSubHeaderVisible &&
                    <IGameSubHeader
                        rightsHoldersCount={rightHoldersCount}
                        operatorsCount={operatorsCount}
                        title={'User Management'}
                    >
                        <div className={styles.controlOverlay}>
                            <div className={styles.controlInner}>
                                <div className={styles.linksInner}>
                                    <NavLink
                                        onClick={() => {
                                            dispatch(clearCSVFile())
                                            setCurrentTab(PageTabs.RIGHTS_HOLDERS)
                                        }}
                                        to={`/cms/user-management`}
                                        className={({isActive, isPending}) =>
                                            isPending ? "pending" : isActive ? styles.activeLink : styles.notActiveLink
                                        } end>Rights Holders
                                    </NavLink>

                                    <NavLink
                                        onClick={() => {
                                            dispatch(clearCSVFile())
                                            setCurrentTab(PageTabs.OPERATORS)
                                        }}
                                        to={'operators'}
                                        className={({isActive, isPending}) =>
                                            isPending ? "pending" : isActive ? styles.activeLink : styles.notActiveLink
                                        }>Operators
                                    </NavLink>

                                    <NavLink
                                        onClick={() => setCurrentTab(PageTabs.TECHNICAL_PROVIDERS)}
                                        to={'technical_provider'}
                                        className={({isActive, isPending}) =>
                                            isPending ? "pending" : isActive ? styles.activeLink : styles.notActiveLink
                                        }>Technical Providers
                                    </NavLink>

                                    <NavLink
                                        onClick={() => setCurrentTab(PageTabs.I_GAME_USERS)}
                                        to={'igame'}
                                        className={({isActive, isPending}) =>
                                            isPending ? "pending" : isActive ? styles.activeLink : styles.notActiveLink
                                        }>iGame
                                    </NavLink>
                                </div>


                                <div className={styles.searchWrapper}>
                                    <SearchByTextInput
                                        value={searchValue}
                                        onChange={onSearch}
                                    />
                                </div>
                            </div>
                        </div>
                    </IGameSubHeader>
                }

                <Routes>
                    <Route
                        path={'/'}
                        element={
                            <UserManagementTable
                                setUserIdInTab={setUserIdInTab}
                                setCurrentTab={setCurrentTab}
                                userRole={PageTabs.RIGHTS_HOLDERS}
                            />
                        }
                    />
                    <Route
                        path={'operators'}
                        element={
                            <UserManagementTable
                                setUserIdInTab={setUserIdInTab}
                                setCurrentTab={setCurrentTab}
                                userRole={PageTabs.OPERATORS}
                            />
                        }
                    />
                    <Route
                        path={'igame'}
                        element={
                            <IGameTable
                                pdfLayout={pdfLayout}
                                isDownloadingPDF={isDownloadingPDF}
                                setCurrentTab={setCurrentTab}
                                setSearchValue={setSearchValue}
                            />
                        }
                    />
                    <Route
                        path={'technical_provider'}
                        element={
                            <TechnicalProvidersUsers
                                pdfLayout={pdfLayout}
                                isDownloadingPDF={isDownloadingPDF}
                                setCurrentTab={setCurrentTab}
                            />
                        }
                    />
                    <Route
                        path={':id'}
                        element={
                            <UserTypesTable
                                setCurrentTab={setCurrentTab}
                                pdfLayout={pdfLayout}
                                setIsIGameSubHeaderVisible={setIsIGameSubHeaderVisible}
                                currentTab={PageTabs.RIGHTS_HOLDERS}
                                isDownloadingPDF={isDownloadingPDF}
                                setUserIdInTab={setUserIdInTab}
                            />}
                    />
                    <Route path={'operators/:id'}
                           element={
                               <UserTypesTable
                                   setCurrentTab={setCurrentTab}
                                   pdfLayout={pdfLayout}
                                   setIsIGameSubHeaderVisible={setIsIGameSubHeaderVisible}
                                   currentTab={PageTabs.OPERATORS}
                                   isDownloadingPDF={isDownloadingPDF}
                                   setUserIdInTab={setUserIdInTab}
                               />
                           }
                    />
                </Routes>
            </div>
        </>
    );
});

UserManagement.displayName = "UserManagement"
export default UserManagement;
