import React, {useEffect, useState} from "react";
import styles from "../../index.module.scss";
import {IFixtureByMonth} from "../../../../../../store/propertyDetail/propertyDetail";
import NoDataFoundStub from "../../../../../../components/NoDataFoundStub/NoDataFoundStub";
import uuid from "react-uuid";

export interface IMonthTableProps {
    data: IFixtureByMonth[];
    assignCellColor: (property: string, value: number, values: number[]) => string;
}

const MonthTable: React.FunctionComponent<IMonthTableProps> = ({data, assignCellColor}) => {
    const [percentageValues, setPercentageValues] = useState<number[]>([]);
    const [totalNumberValues, setTotalNumberValues] = useState<number[]>([]);

    useEffect(() => {
        const tablePercentageValues = data.map(item => item.fixturesPercentage);
        const tableTotalNumberValues = data.map(item => item.totalEvents);
        setPercentageValues(tablePercentageValues);
        setTotalNumberValues(tableTotalNumberValues);
    }, [data]);

    return (
        <div className={styles.monthTableWrapper}>
            <h5 className={styles.tableTitle}>
                Fixtures by month: Percentage and Number of Events
            </h5>
            {
                !data.length &&
                <NoDataFoundStub textColor={"white"}/>
            }
            {
                !!data.length &&
                <table className={styles.monthTable}>
                    <thead>
                    <tr>
                        {data.map((item) => (
                            <th className={styles.headerCell} key={uuid()}>{item.month}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        {data.map((item) => (
                            <td className={styles.bodyCell}
                                style={{backgroundColor: assignCellColor("fixturesPercentage", item.fixturesPercentage, percentageValues)}}
                                key={uuid()}>{`${item.fixturesPercentage}%`}</td>
                        ))}
                    </tr>
                    <tr>
                        {data.map((item) => (
                            <td className={styles.bodyCell}
                                style={{backgroundColor: assignCellColor("totalEvents", item.totalEvents, totalNumberValues)}}
                                key={uuid()}>{item.totalEvents}</td>
                        ))}
                    </tr>
                    </tbody>
                </table>
            }
        </div>
    )
}

export default MonthTable;
