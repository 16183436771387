import {Items} from "@store/generalIssues/generalIssuesSlice";

export const formatSelectOptions = (options: Items[]) => {
    if (options) {
        const dropdownOptions = options?.map(opt => {
            return {
                value: opt?.id?.toString(),
                label: opt?.name
            }
        })
        return dropdownOptions.sort((a, b) => a.label?.localeCompare(b.label));
    } else {
        return []
    }
}
