import {AnyAction, createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {client} from "../../services/api-servise";
import {toastr} from "react-redux-toastr";
import {AxiosError} from "axios";
import {IAddUserBody, ISelectItem} from "../userManagement/userManagementSlice";


export interface IConfirmPasswordReq {
    password: string;
}

export interface IConfirmResp {
    isSucceed: boolean;
    errorMessage: string | null;
}

export interface IDeleteUserBody {
    userId: string;
    reason: string;
    role: string;
}

export interface IEditUserBody {
    payload: {
        userId: string;
        roleId: string;
        pages: number[]
    },
    closeForm: () => void
}

export interface IGetUserDetailsResp {
    pages: {
        items: ISelectItem[]
    },
    clientId: string,
    id: string,
    email: string,
    lastLoginDate: null | string,
    roleId: string,
    roleName: string,
    isAlertTicked: null | boolean,
    isDeleted: boolean
}

export interface IPagesResp {
    pages: {
        items: ISelectItem[];
    }
}

export interface IRolesResp {
    roles: {
        items: ISelectItem[];
    },
    userTypes: {
        items: ISelectItem[];
    }
}

export interface IUser {
    id: string;
    email: string;
    isAlertTicked: null | boolean;
    lastLoginDate: string | null;
    roleId: string;
    roleName: string | null;
    isDeleted: boolean;
    technicalProvider?: string
}

export interface IGetUsersResp {
    users: IUser[],
    clientName: string,
    clientId: string
}

export interface ISettingsInitialState {
    users: IUser[];
    clientName: string;
    roles: ISelectItem[];
    pages: ISelectItem[];
    userDetails: IGetUserDetailsResp;

    error: string;
    loading: boolean;
    formLoading: boolean
    pagesLoading: boolean
}

const settingsInitialState: ISettingsInitialState = {
    users: [],
    clientName: '',
    roles: [],
    pages: [],
    userDetails: {} as IGetUserDetailsResp,

    error: '',
    loading: false,
    formLoading: false,
    pagesLoading: false
}


export const getRightHolderUsers = createAsyncThunk<IGetUsersResp, null, { rejectValue: string }>(
    'settings/getRightHolderUsers',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Users/right-holder`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const searchRightHolderUsers = createAsyncThunk<IGetUsersResp, string, { rejectValue: string }>(
    'settings/searchRightHolderUsers',
    async (searchValue, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Users/right-holder?searchText=${searchValue}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getOperatorUsers = createAsyncThunk<IGetUsersResp, null, { rejectValue: string }>(
    'settings/getOperatorUsers',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Users/operator`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const searchOperatorUsers = createAsyncThunk<IGetUsersResp, string, { rejectValue: string }>(
    'settings/searchOperatorUsers',
    async (searchValue, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Users/operator?searchText=${searchValue}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getRoles = createAsyncThunk<IRolesResp, null, { rejectValue: string }>(
    'settings/getRoles',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Users/roles/init`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getRightHolderPages = createAsyncThunk<IPagesResp, string, { rejectValue: string }>(
    'settings/getRightHolderPages',
    async (roleId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Users/right-holder/init?roleId=${roleId}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getOperatorPages = createAsyncThunk<IPagesResp, string, { rejectValue: string }>(
    'settings/getOperatorPages',
    async (roleId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Users/operator/init?roleId=${roleId}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const addRightHolderUser = createAsyncThunk<IUser, IAddUserBody, { rejectValue: string, }>(
    'settings/addRightHolderUser',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.post(`api/Users/right-holder`, body.payload)
            toastr.success('IGame', `User has been added`)
            body.closeForm()
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const addOperatorUser = createAsyncThunk<IUser, IAddUserBody, { rejectValue: string, }>(
    'settings/addOperatorUser',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.post(`api/Users/operator`, body.payload)
            toastr.error('IGame', `${data.errorMessage}`)
            body.closeForm()
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getUserDetails = createAsyncThunk<IGetUserDetailsResp, string, { rejectValue: string }>(
    'settings/getUserDetails',
    async (userId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Users/details?userId=${userId}`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const updateUser = createAsyncThunk<IUser, IEditUserBody, { rejectValue: string }>(
    'settings/updateUser',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`api/Users/client`, body.payload)
            toastr.success('IGame', `User has been updated`)
            body.closeForm()
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const softDeleteUser = createAsyncThunk<IConfirmResp, IDeleteUserBody, { rejectValue: string }>(
    'settings/softDeleteUser',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`api/Users/soft-delete?userId=${body.userId}&reason=${body.reason}`)
            toastr.success('IGame', `User has been deleted`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const hardDeleteUser = createAsyncThunk<IConfirmResp, IDeleteUserBody, { rejectValue: string }>(
    'settings/hardDeleteUser',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`api/Users/hard-delete?userId=${body.userId}`)
            toastr.success('IGame', `User has been deleted`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const restoreUser = createAsyncThunk<IConfirmResp, string, { rejectValue: string }>(
    'settings/restoreUser',
    async (userId, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`api/Users/restore?userId=${userId}`)
            toastr.success('IGame', `User has been restored`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const resetUserPassword = createAsyncThunk<IConfirmResp, { email: string }, { rejectValue: string }>(
    'settings/resetPassword',
    async (body) => {
        try {
            const {data} = await client.put(`api/Users/reset-password`, body);
            toastr.success('IGame', `A password reset email has now been sent.`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
            }
        }
    }
)

export const confirmResetPassword = createAsyncThunk<IConfirmResp, IConfirmPasswordReq, { rejectValue: string }>(
    'settings/confirmResetPassword',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`/api/Users/confirm/reset-password`, body);
            toastr.success('IGame', `Password has been successful changed`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const userAlert = createAsyncThunk<IConfirmResp, string, { rejectValue: string }>(
    'settings/userAlert',
    async (userId, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`api/Users/Alert?userId=${userId}`);
            toastr.success('IGame', `The alerting mode has been changed`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)


export const settingsSlice = createSlice({
    name: 'settings',
    initialState: settingsInitialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
            .addCase(getRightHolderUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(getRightHolderUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.users = action.payload.users;
                state.clientName = action.payload.clientName
            })
            .addCase(searchRightHolderUsers.fulfilled, (state, action) => {
                state.users = action.payload.users;
            })
            .addCase(getOperatorUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(getOperatorUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.users = action.payload.users;
                state.clientName = action.payload.clientName
            })
            .addCase(searchOperatorUsers.fulfilled, (state, action) => {
                state.users = action.payload.users;
            })
            .addCase(getRoles.fulfilled, (state, action) => {
                state.roles = action.payload.roles.items;
            })
            .addCase(getRightHolderPages.pending, (state) => {
                state.pagesLoading = true;
            })
            .addCase(getRightHolderPages.fulfilled, (state, action) => {
                state.pagesLoading = false;
                state.pages = action.payload.pages.items;
            })
            .addCase(getOperatorPages.pending, (state) => {
                state.pagesLoading = true;
            })
            .addCase(getOperatorPages.fulfilled, (state, action) => {
                state.pagesLoading = false;
                state.pages = action.payload.pages.items;
            })
            .addCase(addRightHolderUser.fulfilled, (state, action) => {
                state.users.push(action.payload);
            })
            .addCase(addOperatorUser.fulfilled, (state, action) => {
                state.users.push(action.payload);
            })
            .addCase(getUserDetails.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(getUserDetails.fulfilled, (state, action) => {
                state.formLoading = false;
                state.userDetails = action.payload;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.users = state.users.map(user => {
                    if (user.id === action.payload.id) {
                        user = action.payload
                    }
                    return user
                })
            })
            .addCase(softDeleteUser.fulfilled, (state, {meta: {arg}}) => {
                state.users.forEach(user => {
                    if (user.id === arg.userId) {
                        user.isDeleted = true
                    }
                })
            })
            .addCase(hardDeleteUser.fulfilled, (state, {meta: {arg}}) => {
                state.users = state.users.filter(user => user.id !== arg.userId);
            })
            .addCase(restoreUser.fulfilled, (state, {meta: {arg}}) => {
                state.users.forEach(user => {
                    if (user.id === arg) user.isDeleted = false;
                })
            })
            .addCase(userAlert.fulfilled, (state, {meta: {arg}}) => {
                state.users.forEach(user => {
                    if (user.id === arg) user.isAlertTicked = !user.isAlertTicked;
                })
            })
            .addCase(resetUserPassword.pending, (state => {
                state.loading = true
            }))
            .addCase(resetUserPassword.fulfilled, (state => {
                state.loading = false
            }))
            .addMatcher(isError, (state, action: PayloadAction<string>) => {
                state.error = action.payload;
                state.loading = false
            })
    }
})


export default settingsSlice.reducer;


function isError(action: AnyAction) {
    return action.type.endsWith("rejected")
}
