import {encodeStringWithSymbols} from "./encodeStringWithSymbols";

export const createUsersQueriesString = (
    currentSortingField: string | null,
    sortingOrder: boolean | null,
    userTypeId?: string | undefined,
    searchValue?: string | null,
) => {
    let property = '';
    let isAscending = '';
    let clientId = '';
    let searchText = '';


    if (currentSortingField) {
        property = `Property=${currentSortingField}`
    }

    if (sortingOrder !== null) {
        isAscending = `&IsAscending=${sortingOrder}`;
    }

    if (userTypeId) {
        clientId = `&clientId=${userTypeId}`;
    }

    if (searchValue) {
        searchText = `&searchText=${encodeStringWithSymbols(searchValue)}`;
    }

    return property + isAscending + clientId + searchText;
}
