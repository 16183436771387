import React, {ChangeEvent, useEffect} from 'react';
import styles from "../index.module.scss";
import {Control, Controller, FieldErrors, FieldValues, UseFormSetError} from "react-hook-form";
import {checkIsUniqOperatorName, isRequiredSelect} from "../helpers";
import Input from "../../../../../../../../../components/Input/Input";
import {checkIsUniqueEmail, formatSelectFormOptions} from "../../helpers";
import SelectInput from "../../../../../../../../../components/SelectInput/SelectInput";
import MultiSelect from "../../../../../../../../../components/MultiSelect/MultiSelect";
import {IStepOneFormData} from "../../../../UserManagementHooks/useOperatorFormData";
import {
    IGetOperatorDetails,
    ISelectItem,
    IUserRole
} from "../../../../../../../../../store/userManagement/userManagementSlice";
import {SingleValue} from "react-select";
import {IDropdownOption} from "../../../../../../../../../components/AnalyticsDashboard/types";
import {useAppSelector} from "../../../../../../../../../hooks/hooks";
import {UseFormClearErrors} from "react-hook-form/dist/types/form";


interface IStepOneFormProps {
    control: Control,
    errors: FieldErrors,
    stepOneFormData: IStepOneFormData,
    onChangeStepOneHandler: (event: ChangeEvent<HTMLInputElement>) => void,
    currentOperator: IGetOperatorDetails | undefined,
    operators: IUserRole[],
    customersInUse: string[],
    handleCustomerId: (option: SingleValue<IDropdownOption>, onChange: (...event: any[]) => void) => void,
    customers: ISelectItem[],
    existingEmails: string[],
    territories: ISelectItem[],
    handleSelectData: (selectName: string, onChange: (...event: any[]) => void) => (selectedData: ISelectItem[]) => void,
    setError: UseFormSetError<FieldValues>,
    clearErrors: UseFormClearErrors<FieldValues>
}

const StepOneForm: React.FC<IStepOneFormProps> = React.memo((
    {
        control,
        errors,
        stepOneFormData,
        onChangeStepOneHandler,
        currentOperator,
        operators,
        handleCustomerId,
        customers,
        existingEmails,
        territories,
        handleSelectData,
        setError,
        clearErrors
    }) => {

    const {isCustomerAvailable, isCustomerStatusUpdating} = useAppSelector(state => state.userManagement);

    useEffect(() => {
        if (isCustomerStatusUpdating) return;

        if (isCustomerAvailable) {
            clearErrors('onePlatformId');
        } else {
            setError('onePlatformId', {type: 'checkProvider', message: "This customer already in use"});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCustomerStatusUpdating])

    return (
        <div className={styles.formContent}>
            <div className={styles.twoInputsContainer}>
                <label className={styles.label}>Name <span>*</span>
                    <Controller
                        control={control}
                        name="name"
                        rules={{
                            required: !stepOneFormData.name,
                            maxLength: 40,
                            minLength: 4,
                            validate: {
                                isUniqName: (name) => checkIsUniqOperatorName(name, currentOperator, operators),
                                isOnlyWhiteSpaces: (name) => {
                                    if (name) {
                                        return name.trim().length > 3
                                    }
                                }
                            }
                        }}
                        render={({field: {onChange}}) => (
                            <Input
                                onChange={(e) => {
                                    onChange(e);
                                    onChangeStepOneHandler(e)
                                }}
                                value={stepOneFormData.name}
                                type="text"
                                name="name"
                                placeholder={"Name"}
                            />
                        )}
                    />
                    {errors?.name?.type === "required" &&
                        <p className={styles.formErrorBlock}>Required Field</p>
                    }
                    {errors?.name?.type === "minLength" &&
                        <p className={styles.formErrorBlock}>
                            Name should contain at least 4 characters
                        </p>
                    }
                    {errors?.name?.type === "maxLength" &&
                        <p className={styles.formErrorBlock}>
                            Name should contain max 40 characters
                        </p>
                    }
                    {errors.name?.type === "isUniqName" &&
                        <p className={styles.formErrorBlock}>This name is already exists</p>
                    }
                    {errors?.name?.type === "isOnlyWhiteSpaces" &&
                        <p className={styles.formErrorBlock}>
                            Name should contain at least 4 characters
                        </p>
                    }
                </label>

                <label className={styles.selectLabel}>Customer Id (Name) <span>*</span>
                    <Controller
                        name="onePlatformId"
                        control={control}
                        rules={{
                            required: !stepOneFormData.customerId
                        }}
                        render={({field: {onChange}}) =>
                            <SelectInput
                                value={stepOneFormData.customerId}
                                onChange={(option) => {
                                    handleCustomerId(option, onChange);
                                }}
                                options={formatSelectFormOptions(customers)}
                                placeholder={"Select"}
                            />
                        }
                    />
                    {errors?.onePlatformId?.type === "required" &&
                        <p className={styles.formErrorBlock}>Required Field</p>
                    }
                    {
                        errors.onePlatformId && errors.onePlatformId.type === "checkProvider"
                        && <span className={styles.formError}>{String(errors.onePlatformId.message)}</span>
                    }
                </label>
            </div>

            <div className={styles.twoInputsContainer}>
                <label className={styles.label}>Client Admin
                    <Controller
                        control={control}
                        name="clientAdmin"
                        rules={{
                            maxLength: 40,
                            minLength: 4,
                            validate: {
                                isOnlyWhiteSpaces: (name) => {
                                    if (name) {
                                        return name.trim().length > 3
                                    }
                                }
                            }
                        }}
                        render={({field: {onChange}}) => (
                            <Input
                                onChange={(e) => {
                                    onChange(e);
                                    onChangeStepOneHandler(e)
                                }}
                                value={stepOneFormData.clientAdmin}
                                type="text"
                                name="clientAdmin"
                                placeholder={"Client Admin"}
                            />
                        )}
                    />
                    {errors?.clientAdmin?.type === "minLength" &&
                        <p className={styles.formErrorBlock}>
                            Client Admin should contain at least 4 characters
                        </p>
                    }
                    {errors?.clientAdmin?.type === "maxLength" &&
                        <p className={styles.formErrorBlock}>
                            Client Admin should contain max 40 characters
                        </p>
                    }
                    {errors?.clientAdmin?.type === "isOnlyWhiteSpaces" &&
                        <p className={styles.formErrorBlock}>
                            Client Admin should contain at least 4 characters
                        </p>
                    }
                </label>

                <label className={styles.label}>Admin Email <span>*</span>
                    <Controller
                        control={control}
                        name="adminEmail"
                        rules={{
                            required: !stepOneFormData.adminEmail,
                            maxLength: 40,
                            minLength: 10,
                            pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                            validate: {
                                uniqueEmail: (e) => checkIsUniqueEmail(e, existingEmails)
                            }
                        }}
                        render={({field: {onChange}}) => (
                            <Input
                                onChange={(e) => {
                                    onChange(e);
                                    onChangeStepOneHandler(e)
                                }}
                                value={stepOneFormData.adminEmail}
                                type="text"
                                name="adminEmail"
                                placeholder={"max.sheamanss@infront.com"}
                            />
                        )}
                    />
                    {errors?.adminEmail?.type === "required" &&
                        <p className={styles.formErrorBlock}>Required Field</p>
                    }
                    {errors?.adminEmail?.type === "minLength" &&
                        <p className={styles.formErrorBlock}>
                            Admin Email should contain at least 10 characters
                        </p>
                    }
                    {errors?.adminEmail?.type === "maxLength" &&
                        <p className={styles.formErrorBlock}>
                            Admin Email should contain max 40 characters
                        </p>
                    }
                    {errors?.adminEmail?.type === "pattern" &&
                        <p className={styles.formErrorBlock}>
                            Admin Email should be like example@xxx.xx
                        </p>
                    }
                    {errors?.adminEmail?.type === "validate" &&
                        <p className={styles.formErrorBlock}>
                            Admin Email with this email already exists
                        </p>
                    }
                    {errors?.adminEmail?.type === "uniqueEmail" &&
                        <p className={styles.formErrorBlock}>
                            This email is already in use
                        </p>
                    }
                </label>
            </div>

            <div className={styles.twoInputsContainer}>
                <label className={styles.label}>Commercial Contact
                    <Controller
                        control={control}
                        name="commercialContact"
                        rules={{
                            maxLength: 40,
                            minLength: 4,
                            validate: {
                                isOnlyWhiteSpaces: (name) => {
                                    if (name) {
                                        return name.trim().length > 3
                                    }
                                }
                            }
                        }}
                        render={({field: {onChange}}) => (
                            <Input
                                onChange={(e) => {
                                    onChange(e);
                                    onChangeStepOneHandler(e)
                                }}
                                value={stepOneFormData.commercialContact}
                                type="text"
                                name="commercialContact"
                                placeholder={"Commercial Contact"}
                            />
                        )}
                    />
                    {errors?.commercialContact?.type === "minLength" &&
                        <p className={styles.formErrorBlock}>
                            Commercial Contact should contain at least 4 characters
                        </p>
                    }
                    {errors?.commercialContact?.type === "isOnlyWhiteSpaces" &&
                        <p className={styles.formErrorBlock}>
                            Commercial Contact should contain at least 4 characters
                        </p>
                    }
                    {errors?.commercialContact?.type === "maxLength" &&
                        <p className={styles.formErrorBlock}>
                            Commercial Contact should contain max 40 characters
                        </p>
                    }
                </label>

                <label className={styles.label}>Commercial Email <span>*</span>
                    <Controller
                        control={control}
                        name="commercialEmail"
                        rules={{
                            required: !stepOneFormData.commercialEmail,
                            maxLength: 40,
                            minLength: 10,
                            pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                            validate: {
                                uniqueEmail: (e) => checkIsUniqueEmail(e, existingEmails)
                            }
                        }}
                        render={({field: {onChange}}) => (
                            <Input
                                onChange={(e) => {
                                    onChange(e);
                                    onChangeStepOneHandler(e)
                                }}
                                value={stepOneFormData.commercialEmail}
                                type="text"
                                name="commercialEmail"
                                placeholder={"max.sheamans@infront.com"}
                            />
                        )}
                    />
                    {errors?.commercialEmail?.type === "required" &&
                        <p className={styles.formErrorBlock}>Required Field</p>
                    }
                    {errors?.commercialEmail?.type === "minLength" &&
                        <p className={styles.formErrorBlock}>
                            Commercial Email should contain at least 10 characters
                        </p>
                    }
                    {errors?.commercialEmail?.type === "maxLength" &&
                        <p className={styles.formErrorBlock}>
                            Commercial Email should contain max 40 characters
                        </p>
                    }
                    {errors?.commercialEmail?.type === "pattern" &&
                        <p className={styles.formErrorBlock}>
                            Commercial Email should be like example@xxx.xx
                        </p>
                    }
                    {errors?.commercialEmail?.type === "uniqueEmail" &&
                        <p className={styles.formErrorBlock}>
                            Commercial Email with this email already exists
                        </p>
                    }
                </label>
            </div>

            <div className={styles.twoInputsContainer}>
                <label className={styles.label}>Scheduling Contact
                    <Controller
                        control={control}
                        name="scheduleContact"
                        rules={{
                            maxLength: 40,
                            minLength: 4,
                            validate: {
                                isOnlyWhiteSpaces: (name) => {
                                    if (name) {
                                        return name.trim().length > 3
                                    }
                                }
                            }
                        }}
                        render={({field: {onChange}}) => (
                            <Input
                                onChange={(e) => {
                                    onChange(e);
                                    onChangeStepOneHandler(e)
                                }}
                                value={stepOneFormData.scheduleContact}
                                type="text"
                                name="scheduleContact"
                                placeholder={"Scheduling Contact"}
                            />
                        )}
                    />
                    {errors?.scheduleContact?.type === "minLength" &&
                        <p className={styles.formErrorBlock}>
                            Scheduling Contact should contain at least 4 characters
                        </p>
                    }
                    {errors?.scheduleContact?.type === "isOnlyWhiteSpaces" &&
                        <p className={styles.formErrorBlock}>
                            Scheduling Contact should contain at least 4 characters
                        </p>
                    }
                    {errors?.scheduleContact?.type === "maxLength" &&
                        <p className={styles.formErrorBlock}>
                            Scheduling Contact should contain max 40 characters
                        </p>
                    }
                </label>

                <label className={styles.label}>Scheduling Email <span>*</span>
                    <Controller
                        control={control}
                        name="scheduleContactEmail"
                        rules={{
                            required: !stepOneFormData.scheduleContactEmail,
                            maxLength: 40,
                            minLength: 10,
                            pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                            validate: {
                                uniqueEmail: (e) => checkIsUniqueEmail(e, existingEmails)
                            }
                        }}
                        render={({field: {onChange}}) => (
                            <Input
                                onChange={(e) => {
                                    onChange(e);
                                    onChangeStepOneHandler(e)
                                }}
                                value={stepOneFormData.scheduleContactEmail}
                                type="text"
                                name="scheduleContactEmail"
                                placeholder={"max.sheamans@infront.com"}
                            />
                        )}
                    />
                    {errors?.scheduleContactEmail?.type === "required" &&
                        <p className={styles.formErrorBlock}>Required Field</p>
                    }
                    {errors?.scheduleContactEmail?.type === "minLength" &&
                        <p className={styles.formErrorBlock}>
                            Scheduling Email should contain at least 10 characters
                        </p>
                    }
                    {errors?.scheduleContactEmail?.type === "maxLength" &&
                        <p className={styles.formErrorBlock}>
                            Scheduling Email should contain max 40 characters
                        </p>
                    }
                    {errors?.scheduleContactEmail?.type === "pattern" &&
                        <p className={styles.formErrorBlock}>
                            Scheduling Email should be like example@xxx.xx
                        </p>
                    }
                    {errors?.scheduleContactEmail?.type === "uniqueEmail" &&
                        <p className={styles.formErrorBlock}>
                            Scheduling Email with this email already exists
                        </p>
                    }
                </label>
            </div>

            <div className={styles.twoInputsContainer}>
                <label className={styles.label}>Live Issues Contact
                    <Controller
                        control={control}
                        name="liveIssuesContact"
                        rules={{
                            maxLength: 40,
                            minLength: 4,
                            validate: {
                                isOnlyWhiteSpaces: (name) => {
                                    if (name) {
                                        return name.trim().length > 3
                                    }
                                }
                            }
                        }}
                        render={({field: {onChange}}) => (
                            <Input
                                onChange={(e) => {
                                    onChange(e);
                                    onChangeStepOneHandler(e)
                                }}
                                value={stepOneFormData.liveIssuesContact}
                                type="text"
                                name="liveIssuesContact"
                                placeholder={"Live Issues Contact"}
                            />
                        )}
                    />
                    {errors?.liveIssuesContact?.type === "minLength" &&
                        <p className={styles.formErrorBlock}>
                            Live Issues Contact should contain at least 4 characters\
                        </p>
                    }
                    {errors?.liveIssuesContact?.type === "isOnlyWhiteSpaces" &&
                        <p className={styles.formErrorBlock}>
                            Live Issues Contact should contain at least 4 characters\
                        </p>
                    }
                    {errors?.liveIssuesContact?.type === "maxLength" &&
                        <p className={styles.formErrorBlock}>Live Issues Contact should contain max
                            40
                            characters</p>}
                </label>

                <label className={styles.label}>Live Issues Email <span>*</span>
                    <Controller
                        control={control}
                        name="liveIussesContactEmail"
                        rules={{
                            required: !stepOneFormData.liveIussesContactEmail,
                            maxLength: 40,
                            minLength: 10,
                            pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                            validate: {
                                uniqueEmail: (e) => checkIsUniqueEmail(e, existingEmails)
                            }
                        }}
                        render={({field: {onChange}}) => (
                            <Input
                                onChange={(e) => {
                                    onChange(e);
                                    onChangeStepOneHandler(e)
                                }}
                                value={stepOneFormData.liveIussesContactEmail}
                                type="text"
                                name="liveIussesContactEmail"
                                placeholder={"max.sheamans@infront.com"}
                            />
                        )}
                    />
                    {errors?.liveIussesContactEmail?.type === "required" &&
                        <p className={styles.formErrorBlock}>Required Field</p>
                    }
                    {errors?.liveIussesContactEmail?.type === "minLength" &&
                        <p className={styles.formErrorBlock}>
                            Live Issues Email should contain at least 10 characters
                        </p>
                    }
                    {errors?.liveIussesContactEmail?.type === "maxLength" &&
                        <p className={styles.formErrorBlock}>
                            Live Issues Email should contain max 40 characters
                        </p>
                    }
                    {errors?.liveIussesContactEmail?.type === "pattern" &&
                        <p className={styles.formErrorBlock}>
                            Live Issues Email should be like example@xxx.xx
                        </p>
                    }
                    {errors?.liveIussesContactEmail?.type === "uniqueEmail" &&
                        <p className={styles.formErrorBlock}>
                            Live Issues Email with this email already exists
                        </p>
                    }
                </label>
            </div>


            <div className={styles.selectContainer}>
                <p className={styles.selectTitle}>Territories <span>*</span></p>
                <label>
                    <Controller
                        control={control}
                        name="territoryIdes"
                        rules={{
                            validate: {
                                isRequiredSelect: (e) => isRequiredSelect(e)
                            }
                        }}
                        render={({field: {onChange}}) => (
                            <MultiSelect
                                options={territories}
                                selectedValues={stepOneFormData.territoryIdes}
                                onSelect={handleSelectData('territoryIdes', onChange)}
                                onRemove={handleSelectData('territoryIdes', onChange)}
                            />
                        )}
                    />
                    {errors?.territoryIdes?.type === "isRequiredSelect" &&
                        <p className={styles.formErrorBlock}>Required Field</p>}
                </label>
            </div>
        </div>

    );
});

export default StepOneForm;
