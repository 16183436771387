import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {toastr} from "react-redux-toastr";
import {client} from "../../services/api-servise";
import {
    IInputOption,
    IOperatorInitResponse,
} from "../operatorAccess/operatorAccessSlice";
import {AxiosError} from "axios";

export interface IRightPricingItem {
    id: string,
    operatorId: string,
    operatorName: string,
    rightName: string,
    minPPF: number,
    maxPPF: number,
    adHoc: number,
    rightId: string,
    isConfirmed: boolean
}

export interface IPrice {
    operatorName: string,
    rightPricingDTO: {
        rightPricings: IRightPricingItem[]
    }
}

export interface IUpdatedPricing {
    payload: {
        rightHolderId: string
        id: string,
        operatorId: string,
        rightId: string,
        minPPF: number,
        maxPPF: number,
        adHoc: number,
    },
    closeForm: () => void
}

export interface IOperatorPricingState {
    prices: IPrice[]
    operatorInpOptions: IInputOption[]
    rightsInpOptions: IInputOption[]
    unconfirmedPricing: IRightPricingItem[]
    newPricing: INewPricing[]
}

export interface ICreatedPricing {
    operatorId: string,
    rightId: string,
    minPPF: number,
    maxPPF: number,
    adHoc: number
}

export interface INewPricing extends ICreatedPricing {
    id: string
    operatorName: string,
    rightName: string
}

export interface ICreateNewPricingBody {
    payload: {
        rightHolderId: string | null,
        rightPricingCreateItems: ICreatedPricing[],
    },
    closeForm: () => void
}

interface IDeletePricingBody {
    operatorId: string,
    rightId: string
    priceId: string
}

const initialState: IOperatorPricingState = {
    prices: [],
    operatorInpOptions: [],
    rightsInpOptions: [],
    unconfirmedPricing: [],
    newPricing: []
}


export const getPricing = createAsyncThunk<IPrice[], string | null, { rejectValue: string }>(
    'operator/get-pricing',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/RightsManagement/operator-pricings${id ? `?RightHolderId=${id}` : ""}`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getUnconfirmedPricing = createAsyncThunk<{ rightPricings: IRightPricingItem[] }, string | null, {
    rejectValue: string
}>(
    'operator/get-unconfirmed-pricing',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/RightsManagement/operator-pricings/unconfirmed${id ? `?RightHolderId=${id}` : ""}`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getPricingInitOptions = createAsyncThunk<IOperatorInitResponse, string | null, { rejectValue: string }>(
    'operator/get-pricing-init-options',
    async (rightHolder, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/RightsManagement/operator-access/init?${rightHolder ? `?RightHolderId=${rightHolder}` : ""}`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getRightsOptionsByOperator = createAsyncThunk<IOperatorInitResponse, {
    rightHolder: string | null,
    operator: string
}, { rejectValue: string }>(
    'operator/get-rights options-by-operator',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/RightsManagement/operator-access/init?operatorId=${body.operator}${body.rightHolder ? `&RightHolderId=${body.rightHolder}` : ""}`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const createNewPricing = createAsyncThunk<IOperatorInitResponse, ICreateNewPricingBody, { rejectValue: string }>(
    'operator/get-rights options-by-operator',
    async (body, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.post(`api/RightsManagement/operator-pricing`, body.payload)
            dispatch(getUnconfirmedPricing(body.payload.rightHolderId))
            body.closeForm()
            toastr.success('IGame', `Pricing has been added`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const confirmPricing = createAsyncThunk<null, { pricing: string[], rightHolderId: string | null }, {
    rejectValue: string
}>(
    'operator/confirm-pricing',
    async (body, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.put(`api/RightsManagement/operator-pricing/confirm`, body.pricing)
            dispatch(getUnconfirmedPricing(body.rightHolderId))
            dispatch(getPricing(body.rightHolderId))
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const updateUnconfirmedPricing = createAsyncThunk<IRightPricingItem, IUpdatedPricing, { rejectValue: string }>(
    'operator/update-unconfirmed-pricing',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`api/RightsManagement/operator-pricing`, body.payload);
            body.closeForm()
            toastr.success('IGame', `Pricing has been updated`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)


export const deletePricing = createAsyncThunk<null, IDeletePricingBody, { rejectValue: string }>(
    'operator/delete-pricing',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`api/RightsManagement/operator-pricing?operatorId=${body.operatorId}&rightId=${body.rightId}`)
            toastr.success('IGame', `Pricing has been deleted`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const deleteUnconfirmedPricing = createAsyncThunk<null, IRightPricingItem, { rejectValue: string }>(
    'operator/delete-unconfirmed-pricing',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`api/RightsManagement/operator-pricing?operatorId=${body.operatorId}&rightId=${body.rightId}`)
            toastr.success('IGame', `Pricing has been deleted`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)


export const operatorPricingSlice = createSlice({
    name: 'operator-pricing',
    reducers: {
        addNewPricing: (state, action: PayloadAction<INewPricing>) => {
            state.newPricing.push(action.payload)
        },
        updateNewPricing: (state, action: PayloadAction<INewPricing>) => {
            state.newPricing = state.newPricing.map(pricing => pricing.id === action.payload.id ? action.payload : pricing)
        },
        deleteNewPricing: (state, action: PayloadAction<string>) => {
            state.newPricing = state.newPricing.filter(price => price.id !== action.payload)
        },
        clearNewPricing: (state, action: PayloadAction<INewPricing[]>) => {
            const currentPricingIds = action.payload.map(pricing => pricing.id)
            state.newPricing = state.newPricing.filter(pricing => !currentPricingIds.includes(pricing.id));
        },
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getPricing.fulfilled, (state, action) => {
                state.prices = action.payload
            })
            .addCase(getUnconfirmedPricing.fulfilled, (state, action) => {
                state.unconfirmedPricing = action.payload.rightPricings
            })
            .addCase(getPricingInitOptions.fulfilled, (state, action) => {
                if (action.payload.operators) {
                    state.operatorInpOptions = action.payload.operators.items
                }
                if (action.payload.rights) {
                    state.rightsInpOptions = action.payload.rights.items
                }
            })
            .addCase(getRightsOptionsByOperator.fulfilled, (state, action) => {
                if (action.payload.rights) {
                    state.rightsInpOptions = action.payload.rights.items
                }
            })
            .addCase(updateUnconfirmedPricing.fulfilled, (state, action) => {
                state.unconfirmedPricing = state.unconfirmedPricing.map(pricing => pricing.id === action.payload.id ? action.payload : pricing)
            })
            .addCase(deletePricing.fulfilled, (state, action) => {
                state.prices.forEach(price => {
                    price.rightPricingDTO.rightPricings = price.rightPricingDTO.rightPricings.filter(price => price.id !== action.meta.arg.priceId)
                })
            })
            .addCase(deleteUnconfirmedPricing.fulfilled, (state, action) => {
                state.unconfirmedPricing = state.unconfirmedPricing.filter(pricing => pricing.id !== action.meta.arg.id)
            })
    }
})
export const {addNewPricing, deleteNewPricing, updateNewPricing, clearNewPricing} = operatorPricingSlice.actions
export default operatorPricingSlice.reducer
