import React from 'react';
import styles from "../HeaderFilters/index.module.scss";
import DatePicker from "../../../../../components/DatePicker/DatePicker";
import SelectInput from "../../../../../components/SelectInput/SelectInput";

export type ControlOption = {
    value: number | string;
    label: string;
};

export interface IFilterControl {
    datePicker: boolean;
    label: string;
    initValue?: any;
    valueName: string;
    options: ControlOption[] | null;
    isMulti?: boolean;
    separator?: boolean;
    withTime?: boolean;
}

interface FilterControlsProps {
    controls: IFilterControl[];
    setFilters: (value: any, name: string) => void;
    filterValues: Record<string, any>;
}

const FilterControls: React.FC<FilterControlsProps> = ({controls, setFilters, filterValues}) => {
    return (
        <>
            {controls.map((control) => {
                return (
                    <div
                        key={control.label}
                        className={`${styles.inputWrapper} ${control.separator ? styles.separator : ''}`}>
                        <label className={styles.label}
                               data-testid={`${control.valueName}-select`}>{control.label}</label>
                        {(control.datePicker ? (
                            <DatePicker
                                initValue={filterValues[control.valueName]}
                                onChange={(e) => setFilters(e, control.valueName)}
                                modalPositioningClass="calendar-modal-down-left"
                                withTime={control.withTime ?? true}
                                autoClose={true}
                            />
                        ) : (
                            <SelectInput
                                showExpander
                                itemsLength={filterValues[control.valueName]?.length || 0}
                                maxItemsToShowExpander={8}
                                onChange={(e) => setFilters(e, control.valueName)}
                                closeMenuOnSelect={false}
                                options={control.options || []}
                                placeholder={control.label}
                                value={filterValues[control.valueName]}
                                isMulti={control.isMulti ?? true}
                                aria-label={`${control.valueName}-select`}
                            />
                        ))}
                    </div>
                );
            })}
        </>
    );
};

export default FilterControls;
