import React from 'react';
import styles from './index.module.scss';
import {BsPlusLg} from 'react-icons/bs';


export interface IAddUserButton {
    buttonName: string;
    onClickHandler?: any;
}


const AddUserButton: React.FC<IAddUserButton> = React.memo(({buttonName, onClickHandler}) => {
        return (
            <button className={styles.addUserBtn} onClick={onClickHandler}
                    onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}>
                <span>Add {buttonName}</span>
                <BsPlusLg/>
            </button>
        );
    }
);

AddUserButton.displayName = "AddUserButton"
export default AddUserButton;
