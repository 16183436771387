import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {IInputOption} from "../operatorAccess/operatorAccessSlice";
import {client} from "../../services/api-servise";
import {toastr} from "react-redux-toastr";

interface IInitOptionsResp {
    items: IInputOption[]
}

interface IConcurrencyStateItem {
    time: string,
    loads: [
        {
            date: string,
            loadLevels: number[]
        }
    ]
}

export interface IConcurrencyEvent {
    id: string
    sportId: string
    isBooked: boolean
    competition: string
    startTime: string
    endTime: string
    title: string
    description: string
    sport: string
}

export interface IAddToCardBody {
    operatorId: string | null
    fixturesIds: string[]
}

export interface IAddToCardResponse {
    checkedOutWithMesssages: boolean
    message: string
}

export interface concurrencyInspectorState {
    loader: boolean
    initOptions: IInputOption[]
    concurrencyStats: IConcurrencyStateItem[]
    concurrencyEvents: IConcurrencyEvent[]
}

const initialState: concurrencyInspectorState = {
    loader: false,
    initOptions: [],
    concurrencyStats: [],
    concurrencyEvents: []
}


export const getConcurrencyInitOptions = createAsyncThunk<IInitOptionsResp, null, { rejectValue: string }>(
    'concurrency/init',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await client.get("api/Concurrency/init")
            return data
        } catch (e: any) {
            toastr.error('IGame', `${e.response.data}`)
            return rejectWithValue(e.response.data)

        }

    }
)
export const getConcurrencyStats = createAsyncThunk<any, string, { rejectValue: string }>(
    'concurrency/get-stats',
    async (query, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Concurrency?${query}`)
            return data
        } catch (e: any) {
            toastr.error('IGame', `${e.response.data}`)
            return rejectWithValue(e.response.data)

        }

    }
)

export const getConcurrencyEvents = createAsyncThunk<IConcurrencyEvent[], string, { rejectValue: string }>(
    'concurrency/get-events',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`/api/Concurrency/events?${body}`)
            return data
        } catch (e: any) {
            toastr.error('IGame', `${e.response.data}`)
            return rejectWithValue(e.response.data)

        }

    }
)

export const addEventsToCard = createAsyncThunk<IAddToCardResponse, IAddToCardBody, { rejectValue: string }>(
    'concurrency/add-to-card',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.post(`/api/Fixtures/operator/checkout`, body)
            toastr.success('IGame', `${body.fixturesIds.length} events has been added`)
            return data
        } catch (e: any) {
            toastr.error('IGame', `${e.response.data}`)
            return rejectWithValue(e.response.data)

        }

    }
)

export const concurrencyToolsSlice = createSlice({
    name: 'concurrency-inspector',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getConcurrencyInitOptions.fulfilled, (state, action) => {
                state.initOptions = action.payload.items

            })
            .addCase(getConcurrencyStats.fulfilled, (state, action) => {
                state.concurrencyStats = action.payload

            })
            .addCase(getConcurrencyEvents.fulfilled, (state, action) => {
                state.concurrencyEvents = action.payload

            })
            .addCase(addEventsToCard.fulfilled, (state, action) => {
                state.concurrencyEvents = state.concurrencyEvents.filter(event =>
                    !action.meta.arg.fixturesIds.includes(event.id)
                );
            })
    },
})

export default concurrencyToolsSlice.reducer
