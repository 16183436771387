import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {client} from "../../services/api-servise";
import {toastr} from "react-redux-toastr";
import {appendDataHelper, prependDataHelper} from "../../helpers/reducerHelpers";

export interface IAuditOperatorAccessResponse {
    records: IAuditOperatorAccess[];
    totalCount: number;
}

export interface IAuditOperatorAccess {
    id: string | null;
    operator: string | null;
    rightHolder: string | null;
    competition: string | null;
}

export interface IOperatorAccessesRequest {
    rightHolderId?: number;
    searchText: string;
    pageNumber: number;
    itemsPerPage: number;
    scrollPosition?: string;
}

interface IAuditTrailsState {
    operatorAccessAuditList: IAuditOperatorAccess[];
    isLoadingOperatorAccess: boolean;
    isLoadingOperatorAccessDetails: boolean;
    totalCount: number;
    itemsPerPage: number;
    pageNumber: number;
    scrollLoader: boolean;
    maxItemsInTheTable: number;
}

const initialState: IAuditTrailsState = {
    operatorAccessAuditList: [],
    isLoadingOperatorAccess: false,
    isLoadingOperatorAccessDetails: false,
    totalCount: 0,
    itemsPerPage: 15,
    pageNumber: 0,
    scrollLoader: false,
    maxItemsInTheTable: 45,
}

export const getOperatorAccessAudit = createAsyncThunk<IAuditOperatorAccessResponse, IOperatorAccessesRequest, {
    rejectValue: string
}>(
    'get-operator-access-audit',
    async ({searchText, itemsPerPage, pageNumber, scrollPosition}, {rejectWithValue, dispatch}) => {
        try {
            const {
                data,
                headers
            } = await client.post("api/RightsManagement/audit/operator-accesses?searchText", {
                searchText,
                itemsPerPage,
                pageNumber
            })

            const totalCount = headers['x-total-count']
            if (totalCount) {
                dispatch(setTotalCount(Number(totalCount)));
            }
            if (scrollPosition && scrollPosition === "down") {
                dispatch(appendData(data.records));
                return;
            }
            if (scrollPosition && scrollPosition === "up") {
                dispatch(prependData(data.records));
                return;
            }
            return data;
        } catch (e: any) {
            toastr.error('Error!', `${e.response.data}`)
            return rejectWithValue(e.response.data)
        }
    }
)

export const auditTrailsOperatorAccessSlice = createSlice({
    name: 'audit-trails-operator-access',
    initialState,
    reducers: {
        skipOperatorAccessSearch: (state) => {
            state.operatorAccessAuditList = [];
        },
        setPageNumber: (state, action: PayloadAction<number>) => {
            state.pageNumber = action.payload
        },
        setTotalCount: (state, action: PayloadAction<number>) => {
            state.totalCount = action.payload
        },
        appendData(state, action) {
            appendDataHelper(state, action.payload, 'operatorAccessAuditList');
        },
        prependData(state, action) {
            prependDataHelper(state, action.payload, 'operatorAccessAuditList');
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOperatorAccessAudit.pending, (state) => {
                state.isLoadingOperatorAccess = true;
            })
            .addCase(getOperatorAccessAudit.fulfilled, (state, action) => {
                if (action.payload !== undefined) {
                    state.operatorAccessAuditList = action.payload.records;
                    state.pageNumber = 1;
                }
                state.isLoadingOperatorAccess = false;
            })
    },
})

export const {
    skipOperatorAccessSearch,
    setPageNumber,
    setTotalCount,
    appendData,
    prependData
} = auditTrailsOperatorAccessSlice.actions
export default auditTrailsOperatorAccessSlice.reducer
