import {useEffect, useState} from "react";

export const useRandomColors = () => {
    const [colors, setColors] = useState<string[]>([]);

    const createRandomColors = () => {
        const firstAppearingColors = ["#FFFFFF", "#DDE383", "#29DEFC", "#FFADC2", "#B2B2B2", "#368697"];
        let generatedColors = [];
        const colorCount = 200;
        const goldenRatio = 1.618033988749895;
        const hueRandomFactor = 0.1;
        const baseSaturation = 70;
        const baseLightness = 60;
        const saturationVariance = 20;
        const lightnessVariance = 20;
        let hue = Math.random() * 360;

        const randomBetween = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

        for (let i = 0; i < colorCount; i++) {
            hue += 360 / (goldenRatio + hueRandomFactor * (Math.random() - 0.5));
            hue %= 360;
            const saturation = randomBetween(baseSaturation - saturationVariance, baseSaturation + saturationVariance);
            const lightness = randomBetween(baseLightness - lightnessVariance, baseLightness + lightnessVariance);

            const hslColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
            generatedColors.push(hslColor);
        }

        setColors([...firstAppearingColors, ...generatedColors]);
    };

    useEffect(() => {
        createRandomColors();
    }, []);

    return colors;
}