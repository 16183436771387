import {Navigate} from "react-router-dom";
import React from "react";

interface IProps {
    children: React.ReactElement;
    forGroup: string;
}

const ProtectedRoute: React.FC<IProps> = ({children}): React.ReactElement => {
    const token = localStorage.getItem("book-token")

    if (!token) {
        return <Navigate to="/"/>;
    }
    return children;
};

ProtectedRoute.displayName = 'ProtectedRoute'
export default ProtectedRoute
