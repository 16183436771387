import moment from "moment/moment";
import {IDate} from "../../../../../../../components/AnalyticsDashboard/types";

export const formatCalendarDate = (datetimeObject: IDate, isEndTime: boolean) => {
    if (datetimeObject && !isEndTime) {
        const {year, month, day, hour, minute} = datetimeObject
        const dateTime = moment({year, month: month - 1, day, hour, minute})
        return dateTime.format('DD/MM/YYYY HH:mm')
    }
    if (datetimeObject && isEndTime) {
        const {year, month, day, hour, minute} = datetimeObject
        const dateTime = moment({year, month: month - 1, day, hour, minute})
        return dateTime.format('DD/MM/YYYY HH:mm')
    }
    if (!datetimeObject && isEndTime) {
        return `${moment().format('DD/MM/YYYY')} XX:XX`;
    }
    if (!datetimeObject && !isEndTime) {
        return moment().format('DD/MM/YYYY HH:mm')
    } else {
        return moment().format('DD/MM/YYYY')
    }
}

export const convertDate = (dateTime: string) => {
    const [date] = dateTime.split(' ');
    const dateParts = date.split('/');
    return `${dateParts[1]}-${dateParts[0]}-${dateParts[2]}`;
}

export const formatDateForPostGeneralIssue = (inputDate: string | undefined) => {
    if (!inputDate || inputDate.includes("XX:XX")) {
        return null;
    }
    return moment(inputDate, 'DD/MM/YYYY HH:mm').utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
};
