import {createAsyncThunk, createSlice, PayloadAction, AnyAction} from "@reduxjs/toolkit";
import {toastr} from "react-redux-toastr";
import {client} from "../../services/api-servise";
import {AxiosError} from "axios";


interface IGetSatellitesResp {
    items: IListItem[]
}

export interface ICompetition {
    id: string;
    name: string;
    duration: number;
    extraTimeDuration: number;
    country: string;
}

export interface IInitInfoResponse {
    competitions: ICompetition[];
    sports: {
        items: IListItem[]
    };
    countries: {
        items: IListItem[]
    };
}

export interface IInitInfoPayload {
    rightHolderId: string | null;
}

export interface IListItem {
    id: string;
    name: string;
    code: string
}

export interface IIpChannel {
    id: number;
    name: string;
}

export interface IInitInfoState {
    source: IInitInfoResponse;
    loading: boolean;
    satellites: IListItem[],
    ipChannels: IIpChannel[],
    error: string,
    iGameChannelInfo: {
        isBackup: boolean
    },
    currentUserGroup: string | undefined,
}

export interface IGetChannelsBody {
    fixtureId: string
    ingestType: number
}

export interface ISetIGameChannelBody {
    fixtureId: string
    ingestResourceId: string
    isBackup: boolean
}

const initialState: IInitInfoState = {
    source: {
        competitions: [],
        sports: {
            items: []
        },
        countries: {
            items: []
        }
    },
    satellites: [],
    ipChannels: [],
    loading: false,
    error: '',
    iGameChannelInfo: {
        isBackup: false
    },
    currentUserGroup: undefined
}

export const getInitInfo = createAsyncThunk<IInitInfoResponse, IInitInfoPayload, { rejectValue: string }>(
    'rightHolderBooking/init',
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.post("/api/Fixtures/right-holder/init", payload);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getIpChannels = createAsyncThunk<IIpChannel[], IGetChannelsBody, { rejectValue: string }>(
    'rightHolderBooking/getIpChannels',
    async ({fixtureId, ingestType}, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/IngestResources/fixture/${fixtureId}?ingestType=${ingestType}`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)


export const setIGameChannel = createAsyncThunk<string[], ISetIGameChannelBody, { rejectValue: string }>(
    'rightHolderBooking/setIGameChannel',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.post(`api/Fixtures/right-holder/ingest/channel`, body);
            toastr.success("IGame", "Channel has been set")
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getSatellites = createAsyncThunk<IGetSatellitesResp, null, { rejectValue: string }>(
    'rightHolderBooking/getSatellites',
    async (fixtureId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Satellites`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const initInfoSlice = createSlice({
    name: 'init',
    initialState,
    reducers: {
        setCurrentUserGroup: (state, action) => {
            state.currentUserGroup = action.payload
        },
        clearIpChannels: (state) => {
            state.ipChannels = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getInitInfo.pending, (state) => {
                state.error = "";
                state.loading = true;
            })
            .addCase(getInitInfo.fulfilled, (state, action: PayloadAction<IInitInfoResponse>) => {
                state.loading = false;
                state.source = action.payload;
            })
            .addCase(getIpChannels.fulfilled, (state, action: PayloadAction<IIpChannel[]>) => {
                state.ipChannels = action.payload;
            })
            .addCase(getSatellites.fulfilled, (state, action) => {
                state.satellites = action.payload.items;
            })
            .addMatcher(isError, (state, action: PayloadAction<string>) => {
                state.error = action.payload;
                state.loading = false;
            })
    },
})

export const {clearIpChannels} = initInfoSlice.actions
export default initInfoSlice.reducer

function isError(action: AnyAction) {
    return action.type.endsWith("rejected")
}
