import {formatSelectOptions} from "../../../../../../helpers/formatSelectOptions";
import {IDropdownOptions} from "../../../../../../store/operatorRightsOverview/operatorRightsOverviewSlice";

export const createFilterControls = (dropdownOptions: IDropdownOptions) => {
    return [
        {
            datePicker: false,
            label: "Rights Holder",
            valueName: "rightHolders",
            options: formatSelectOptions(dropdownOptions.rightHolders),
        },
        {
            datePicker: false,
            label: "Sport",
            valueName: "sports",
            options: formatSelectOptions(dropdownOptions.sports),
        },
        {
            datePicker: false,
            label: "Country",
            valueName: "countries",
            options: formatSelectOptions(dropdownOptions.countries),
        },
    ]


};
