import React from 'react';
import styles from './index.module.scss'


export interface ISettingsSubHeaderProps {
    operatorsCount: number;
    rightsHoldersCount: number;
    title: string;
    children?: React.ReactNode;
    hasOptions?: boolean;
}

const IGameSubHeader: React.FC<ISettingsSubHeaderProps> = (
    {
        operatorsCount,
        rightsHoldersCount,
        title,
        children,
        hasOptions
    }) => {

    return (
        <div className={styles.pageContainer}>
            <div
                data-testid={"subHeaderContainer"}
                className={children ? styles.subHeaderContainer :
                    `${styles.subHeaderContainer} ${styles.subHeaderPadding}`
                }
            >

                <div className={styles.subHeaderInner}>
                    <div className={styles.subHeaderTitle}>
                        <h1 data-testid="subHeaderTitle" className={styles.title}>{title}</h1>

                        <div className={styles.totalData}>
                            <div>
                                <p data-testid="subHeaderRHCount"
                                   className={styles.numbers}>{rightsHoldersCount}</p>
                                <p>Rights Holders</p>
                            </div>

                            <div>
                                <p data-testid="subHeaderOPCount"
                                   className={styles.numbers}>{operatorsCount}</p>
                                <p>Operators</p>
                            </div>
                        </div>
                    </div>

                    {hasOptions && children}
                </div>

                {!hasOptions && children}
            </div>
        </div>
    );
};

IGameSubHeader.displayName = "IGameSubHeader"
export default IGameSubHeader;
