import React, {useEffect} from 'react';
import styles from "./index.module.scss";
import {
    getPricing,
    IRightPricingItem
} from "../../../../../store/operatorPricing/operatorPricingSlice";
import {IUserItem} from "../../../../../store/iGameInit/iGameInitSlice";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import TableAccordion from "./TableAccordion/TableAccordion";
import {PricingTablesNames} from "../Pricing";

interface IExistingPricingTableProps {
    openConfirm: (price: IRightPricingItem, tableName: string) => void;
    rightHolderId: string | null;
    users?: IUserItem[];
}

const ExistingPricingTable: React.FC<IExistingPricingTableProps> = React.memo((
    {
        openConfirm,
        rightHolderId,
        users
    }) => {
    const {prices} = useAppSelector(state => state.operatorPricing);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getPricing(rightHolderId))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rightHolderId])

    return (
        <div className={styles.tableOverlay}>
            <h3 className={styles.tableTitle}>{PricingTablesNames.EXISTING_PRICING}</h3>
            <div className={styles.tableScroll}>
                <div className={styles.tableWrapper}>

                    <table className={styles.pricingTable}>
                        <thead>
                        <tr>
                            <th>Operator</th>
                            <th>Right</th>
                            <th>Min PPF (£)</th>
                            <th>Max PPF (£)</th>
                            <th>Ad Hoc</th>
                            <th></th>
                        </tr>
                        </thead>
                        {prices?.map((price, index) => {
                            if (price.rightPricingDTO.rightPricings.length > 0) {
                                return (
                                    <TableAccordion
                                        openConfirm={openConfirm}
                                        key={index + 1}
                                        operatorName={price.operatorName}
                                        priceItems={price.rightPricingDTO.rightPricings}
                                        rightHolderId={rightHolderId}
                                        users={users}
                                    />
                                )
                            }
                            return null
                        })}
                    </table>
                </div>
            </div>
        </div>
    );
});

export default ExistingPricingTable;
ExistingPricingTable.displayName = "ExistingPricingTable"
