import React, {useEffect} from "react";
import styles from "./index.module.scss";
import {IUserItem} from "../../../../../store/iGameInit/iGameInitSlice";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {RootState} from "../../../../../store";
import NoDataFoundStub from "../../../../../components/NoDataFoundStub/NoDataFoundStub";
import Loader from "../../../../../components/Loader/Loader";
import ErrorComponent from "../../../../../components/ErrorComponent/ErrorComponent";
import PageHeader from "../../../../../components/PageHeader/PageHeader";
import ClientCard from "../../components/ClientCard/ClientCard";
import {useNavigate} from "react-router-dom";
import logoPlaceholder from "../../../../../img/logoSmall.svg";
import {
    getFiltersOptions,
    getRightHolders,
    IRightHolderResponse, resetFilters, setFilters,
    setRightHolderId
} from "../../../../../store/operatorRightsOverview/operatorRightsOverviewSlice";
import HeaderFilters from "../../AvailableContent/HeaderFilters/HeaderFilters";
import {useSetFilters} from "./helpers/onSetFilters";
import {createRequestModel} from "./helpers/createRequestModel";
import uuid from "react-uuid";
import {createFilterControls} from "./helpers/createFilterControls";
import {useGetCartItems} from "../../../../../hooks/useGetCartItems";
import {formatSelectOptions} from "../../../../../helpers/formatSelectOptions";
import {IMenuStructure} from "@pages/Cms/AppRoutes/routes/RouteInterface";

export interface IRightsHoldersProps {
    menuStructure: IMenuStructure;
    users?: IUserItem[];
    operatorId?: string | null;
}

const RightsHoldersTiles: React.FunctionComponent<IRightsHoldersProps> = ({menuStructure, users, operatorId}) => {
    const navigate = useNavigate();
    const {
        loadingStatus,
        filters,
        rightsHolders,
        dropdownOptions
    } = useAppSelector((state: RootState) => state.operatorRightsOverview);
    const dispatch = useAppDispatch();
    const setFiltersHandler = useSetFilters();
    const filtersControls = createFilterControls(dropdownOptions);
    useGetCartItems(operatorId);

    useEffect(() => {
        if (operatorId && filters) {
            dispatch(getFiltersOptions(createRequestModel(filters, operatorId)));
            return
        }
        dispatch(getFiltersOptions(createRequestModel(filters, null)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const requestModel = {
            operatorId: operatorId as string,
            rightHoldersIds: filters.rightHolders.map(item => item.value),
            sportsIds: filters.sports.map(item => item.value),
            countriesIds: filters.countries.map(item => item.value),
        }
        dispatch(getRightHolders(requestModel));
        dispatch(getFiltersOptions(createRequestModel(filters, requestModel.operatorId)));
    }, [filters, operatorId, dispatch]);

    const resetFilter = () => {
        return dispatch(resetFilters());
    };


    const getRedirectionLink = (): string => {
        if (operatorId) {
            return `/cms/igame-operators/${operatorId}/operator-rights-holders-portfolio`;
        } else {
            return `/cms/operator-rights-holders-portfolio`;
        }
    }

    const redirectToPortfolio = (card: IRightHolderResponse | null) => {
        if (card?.id) {
            const filtersValue = [{label: card.name, value: card.id}]

            dispatch(setFilters({name: "rightHolders", value: filtersValue}));
            localStorage.setItem('selectedRightsHolderId', card.id);
            dispatch(setRightHolderId(card.id));

            navigate(getRedirectionLink())
        }
    }

    const redirectToAllRightsHolder = () => {
        if (!filters.sports.length && !filters.countries.length) {
            resetFilter();
        } else if (filters.sports.length > 0 || filters.countries.length > 0) {
            dispatch(setFilters({
                name: 'rightHolders',
                value: formatSelectOptions(dropdownOptions.rightHolders)
            }))
        }
        navigate(getRedirectionLink());
    }

    return (
        <>
            <PageHeader
                menuStructure={menuStructure}
                users={users}
                title={"Rights Holders Portfolio"}>
            </PageHeader>
            <div className={styles.page}>
                <div className={styles.pageContainer}>
                    <div className={styles.upperSection}>
                        <h2 className={styles.title}>Rights Holders</h2>
                        <HeaderFilters
                            resetFilters={resetFilter}
                            filterControls={filtersControls}
                            setFilters={setFiltersHandler}
                            filterValues={filters}
                        />
                    </div>
                    <div className={styles.contentBox}>
                        {
                            loadingStatus === "loading" &&
                            <Loader/>
                        }
                        {
                            loadingStatus !== "idle" &&
                            <NoDataFoundStub textColor={"white"}/>
                        }
                        {
                            loadingStatus === "idle" &&
                            <>
                                <div className={styles.controlsInner}>
                                    <button
                                        onClick={redirectToAllRightsHolder}
                                        key={uuid()}
                                        className={styles.controlBtn}>
                                        <ClientCard>
                                            <h6 className={styles.cardTitle}>
                                                All Rights Holders
                                            </h6>
                                        </ClientCard>
                                    </button>

                                    {filters.rightHolders.length > 1 && !filters.countries.length && !filters.sports.length &&
                                        <button
                                            onClick={() => navigate(getRedirectionLink())}
                                            key={uuid()}
                                            className={styles.controlBtn}>
                                            <ClientCard>
                                                <h6 className={styles.cardTitle}>
                                                    Selected Rights Holders
                                                </h6>
                                            </ClientCard>
                                        </button>
                                    }
                                </div>

                                <div className={styles.tiles}>
                                    {rightsHolders.map(card => {
                                        return (
                                            <button
                                                onClick={() => redirectToPortfolio(card)}
                                                key={card.id}
                                                className={styles.tileInner}
                                            >
                                                <ClientCard>
                                                    <h6 className={styles.cardTitle}>
                                                        {card.name}
                                                    </h6>
                                                    <div className={styles.imgBox}>
                                                        <img src={card.logo || logoPlaceholder} alt="Logo"/>
                                                    </div>
                                                </ClientCard>
                                            </button>
                                        );
                                    })}
                                </div>
                            </>
                        }
                        {
                            loadingStatus === "failed"
                            &&
                            <ErrorComponent textColor={"white"}/>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default RightsHoldersTiles;
