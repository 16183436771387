import React from 'react';
import styles from "./index.module.scss";
import {useNavigate} from "react-router-dom";
import {IUserRole} from "../../../../../../../store/userManagement/userManagementSlice";
import {Group} from "../../../../../../../enums/group";
import uuid from 'react-uuid';
import {IRangeDate} from '../../../../../OperatorView/ConcurrencyInspector/ConcurrencyTable/ConcurrencyTable';


export interface IBillingUsersTable {
    users: IUserRole[];
    role: string;
    date?: IRangeDate;
}

const BillingUsersTable: React.FC<IBillingUsersTable> = React.memo(({users, role}) => {
    const navigate = useNavigate();

    const handleTileClick = (userId: string) => {
        if (role === Group.RightHolder) {
            navigate(`rightHolders/${userId}`);
        } else if (role === Group.Operator) {
            navigate(`${userId}`);
        }
    }

    return (
        <div className={styles.pageContainer}>
            <div className={styles.tableOverlay}>
                <div className={styles.tableInner}>
                    <div className={styles.usersContainer}>
                        {users.map(user => (
                            <button onClick={() => handleTileClick(user.id)}
                                    key={uuid()}
                                    className={styles.tileButton}>
                                <div className={styles.userItem} key={uuid()}>
                                    <p className={styles.userName}>{user.name}</p>
                                    {user.logo && <img className={styles.userLogo} src={user.logo} alt="LOGO"/>}
                                </div>
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
});

BillingUsersTable.displayName = "BillingUsersTable"
export default BillingUsersTable;
