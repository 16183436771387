import React, {useEffect, useState} from "react";
import styles from "./index.module.scss";
import {IMainChartConvertedDataObject} from "../../types";
import {Tooltip} from "react-tooltip";

export interface IMainChartLegendProps {
    data: any;
    trendLinesVisible: boolean;
}

const MainChartLegend: React.FunctionComponent<IMainChartLegendProps> = ({data, trendLinesVisible}) => {
    const [legendData, setLegendData] = useState<any>(null);

    useEffect(() => {
        if (trendLinesVisible) {
            const midIndex = data.length / 2;
            setLegendData(data.slice(0, midIndex));
        } else {
            setLegendData(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <ul className={styles.mainChartLegend}>
            {
                legendData ? legendData.map((filterOption: {
                        data: IMainChartConvertedDataObject[]
                    }, index: number) => (
                        <li key={filterOption.data[0].x + index + 1} className={styles.legendItem}
                            data-tooltip-id={filterOption.data[0].categoryItem}
                            data-tooltip-content={filterOption.data[0].categoryItem}
                            data-tooltip-position-strategy="fixed"
                        >
                        <span className={styles.legendItemLine}
                              style={{backgroundColor: `${filterOption.data[0].color}`}}></span>
                            <span className={styles.legendItemName}>
                            {
                                filterOption.data[0].categoryItem
                            }
                        </span>
                            {
                                <Tooltip id={filterOption.data[0].categoryItem} className={styles.legendTooltip}/>
                            }
                        </li>
                    ))
                    :
                    null
            }
        </ul>
    )
}

export default MainChartLegend;
