import React, {useState} from 'react';
import upload from "@img/upload.png";
import styles from "./index.module.scss"
import {checkFileIsInvalid} from "@pages/Cms/IGameView/Settings/UserManagement/helpers/checkFileIsInvalid";

interface FileUploaderProps {
    handleFileSelection: (file: File) => void
}

const FileUploader: React.FC<FileUploaderProps> = (
        {
            handleFileSelection
        }) => {
        const [dragActive, setDragActive] = useState(false);
        const [uploaded, setUploaded] = useState<boolean>(false);
        const [error, setError] = useState("");

        const handleDrag = <T extends HTMLElement>(e: React.DragEvent<T>) => {
            e.preventDefault();
            e.stopPropagation();
            if (e.type === "dragenter" || e.type === "dragover") {
                setDragActive(true);
            } else if (e.type === "dragleave") {
                setDragActive(false);
            }
        }

        const handleDrop = async (e: React.DragEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();
            setDragActive(false);

            const droppedFile = e.dataTransfer.files?.[0];

            if (droppedFile) {
                const errorMessage = await checkFileIsInvalid(droppedFile, 500, 180);
                if (errorMessage) {
                    setError(errorMessage);
                    setUploaded(false);
                    return;
                } else {
                    setError("");
                    setUploaded(true);
                    handleFileSelection(droppedFile)
                }

            }
        }


        const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            if (e.target.files?.[0]) {
                const errorMessage = await checkFileIsInvalid(e.target.files[0], 500, 180);

                if (errorMessage) {
                    setError(errorMessage);
                    setUploaded(false);
                    return;
                }
                setError("");
                setUploaded(true);
                handleFileSelection(e.target.files[0])
            }
        }

        return (
            <div className={`${styles.formContent}`} onDragEnter={handleDrag}>
                <p className={`${styles.formSubTitle} ${styles.formSubTitleBorder}`}> Logo </p>
                <p className={styles.formErrorBlock}>{error}</p>
                <button className={styles.oneInputContainer}
                     onDragEnter={handleDrag}
                     onDragLeave={handleDrag}
                     onDragOver={handleDrag}
                     onDrop={handleDrop}
                >
                    <label
                        className={uploaded
                            ? `${styles.uploadLabel} ${styles.uploaded}`
                            : dragActive
                                ? `${styles.uploadLabel} ${styles.dragActive}`
                                : styles.uploadLabel}
                        htmlFor="file"
                        data-testid={"fileLoaderLabel"}
                    >
                        <img src={upload} alt="upload"/>
                        <div className={styles.uploadTextSection}>
                            <h2 className={styles.formTitle} data-testid="uploadFile">Upload files</h2>
                            {
                                uploaded
                                    ?
                                    <p>File uploaded!</p>
                                    :
                                    <>
                                        <p>Drag and drop file here to upload or click to find it on your computer</p>
                                        <p className={styles.uploadDescription}>Supported file types are .png and .svg.
                                            The minimum size should be 500 x 180. The file can be bigger as long as it is in
                                            this ratio and has file type .svg or .png</p>
                                    </>
                            }
                        </div>
                        <input
                            onChange={handleUpload}
                            type="file"
                            id="file"
                            className={styles.uploadInput}
                        />
                    </label>
                </button>
            </div>
        );
    }
;

export default FileUploader;
