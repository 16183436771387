import React from "react";
import {BsExclamationTriangle} from "react-icons/bs";
import {IconContext} from "react-icons";

import styles from "./index.module.scss";

export interface IErrorComponentProps {
    textColor?: string;
}

const ErrorComponent: React.FunctionComponent<IErrorComponentProps> = ({textColor}) => {
    return (
        <div className={styles.errorComponent} data-testid="error-component">
            <IconContext.Provider value={{color: "#FF3466", size: "70px"}}>
                <BsExclamationTriangle/>
            </IconContext.Provider>
            <p className={styles.errorComponentText} style={{color: `${textColor}`}}>Sorry! It seems that something went
                wrong...</p>
        </div>
    )
}

ErrorComponent.displayName = "ErrorComponent"
export default ErrorComponent;
