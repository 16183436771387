import {
    ICreateOperatorPayload,
    IGetRightHolderDetails, IRHPayload,
    ISelectItem, IUserRole
} from "../../../../../../../store/userManagement/userManagementSlice";

export const formatSelectFormOptions = (options: ISelectItem[], providersInUse?: ISelectItem[], details?: IGetRightHolderDetails) => {
    if (options) {
        const dropDownOptions = options?.map(opt => {
            return {value: opt.id, label: `${opt.id} (${opt.name})`}
        })
        if (providersInUse && details) {
            const currentProvider = providersInUse.find(provider => provider.id === String(details.dataProviderId));
            if (currentProvider) {
                const currentProviderOptions = {
                    value: currentProvider.id,
                    label: `${currentProvider.id} (${currentProvider.name})`
                };
                dropDownOptions.unshift(currentProviderOptions)
            }
        }
        return dropDownOptions;
    } else {
        return []
    }
}

export const checkIsUniqueEmail = (currentEmail: string, existingEmails: string[]) => {
    const email = existingEmails.find(email => email === currentEmail)
    return !email
}

export const formatPageName = (prefix: string, pageName: string) => {
    const words = pageName.split('-');
    const formattedWords = words.map((word, index) => {
        const formattedWord =
            index === 0
                ? word.charAt(0).toUpperCase() + word.slice(1)
                : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

        return formattedWord.replace('RightHolder', '').replace('Operator', '');
    });

    const fullName = formattedWords.join(' ');

    return `${fullName}`;
};

export const findRoleId = (roleName: string, roles: IUserRole[]) => {
    const roleFound = roles.find(item => item.name === roleName);

    return roleFound ? roleFound.id : "";
}

export const createFormData = (obj: IRHPayload | ICreateOperatorPayload) => {
    const formData = new FormData();

    function appendFormData(data: any, root: string = '') {
        if (data instanceof Object && !(data instanceof File)) {
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    appendFormData(data[key], root ? `${root}[${key}]` : key);
                }
            }
        } else {
            formData.append(root, data);
        }
    }

    appendFormData(obj);

    return formData;
}
