import React, {useEffect, useState} from "react";
import {Routes} from "react-router";
import uuid from "react-uuid";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {useParams} from "react-router-dom";
import {getAllUsers, IUserItem} from "../../../../store/iGameInit/iGameInitSlice";
import {getAllowedPages} from "../../AppRoutes/getAllowedPages";
import {formatAllowedPages} from "../../AppRoutes/helpers/formatAllowedPages";
import {ISidebarItem} from "../../CmsSideBarData/CmsSideBarData";
import {mapAllowedRoutes} from "../../AppRoutes/mapAllowedRoutes";
import {getAllowedRoutes} from "../../AppRoutes/getAllowedRoutes";
import {routeHandler} from "../../AppRoutes/routes/routeHandler";
import {rightHoldersInnerPages} from "../../AppRoutes/routes/helpers/usersTypesInnerPages";
import {Group} from "../../../../enums/group";
import {setCurrentIgameUserGroup} from "../../../../store/userInfo/userInfoSlice";

const RightHolderPage: React.FunctionComponent = () => {
    let {id} = useParams();
    const dispatch = useAppDispatch();
    const {users} = useAppSelector(state => state.iGameInit);
    const {pages, currentIgameUserGroup} = useAppSelector(state => state.userInfo);
    const [dropdownDataSet, setDropdownDataSet] = useState<ISidebarItem[] | null>(null);
    const [rightHolder, setRightHolder] = useState<IUserItem | undefined>();

    useEffect(() => {
        dispatch(getAllUsers(null));

        if (currentIgameUserGroup !== Group.RightHolder) {
            dispatch(setCurrentIgameUserGroup(Group.RightHolder))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const currentRightHolder = users.rightHolders.find((x: IUserItem) => x.id === id);
        if (currentRightHolder !== null) setRightHolder(currentRightHolder);
    }, [users, id]);

    useEffect(() => {
        let menuStructure: ISidebarItem | null = null;

        if (rightHolder) {
            menuStructure = {
                title: rightHolder.name,
                menuItemId: uuid(),
                pageName: "igame-rightHolders",
                pageNumber: null,
                children: rightHoldersInnerPages.map((x, index) => ({
                    title: x.name,
                    menuItemId: uuid(),
                    path: `/cms/igame-rightHolders/${rightHolder.id}/${x.path}`,
                    pageName: x.path,
                    pageNumber: index + 1
                })),
                path: `/cms/igame-rightHolders/${rightHolder.id}`
            }
        }

        if (menuStructure) {
            setDropdownDataSet(getAllowedPages(formatAllowedPages([...pages, 31]), [menuStructure]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rightHolder]);

    return rightHolder && dropdownDataSet && id
        ?
        <Routes>
            {mapAllowedRoutes(getAllowedRoutes(routeHandler({
                rightsHolderId: id,
                rightsHolderUsers: users.rightHolders,
                dropdownDataSet,
            }), formatAllowedPages(pages)))}
        </Routes>
        :
        null
}

RightHolderPage.displayName = "RightHolderPage"
export default RightHolderPage;
