import React, {useCallback, useEffect, useRef, useState} from "react";
import styles from "./index.module.scss";
import {IMainAnalyticsDataResponse} from "../../../../store/igameAnalytics/igameAnalyticsSlice";
import {IoCaretDownSharp} from "react-icons/io5";
import GoogleGeoChart from "../../../GoogleGeoChart/GoogleGeoChart";
import {ISelectOption} from "../../../../store/operatorBookedContent/operatorBookedContentSlice";

export interface IGeoChartProps {
    geoChartData: [string, number][];
    mainData: IMainAnalyticsDataResponse | null
    dataTypes: ISelectOption;
}

const pointerHalfSize = 13;

export enum FilterType {
    UniqueUsers = 2,
    TotalViews = 3
}

const GeoChart: React.FunctionComponent<IGeoChartProps> = React.memo(({geoChartData, mainData, dataTypes}) => {
    const [legendValues, setLegendValues] = useState<{ min: number, max: number }>({
        min: 0,
        max: 0
    })
    const colors = ["#EDEDED", "#F2BDCA", "#F690A9", "#FF3566"]
    const [selectedRegion, setSelectedRegion] = useState<any>(null);
    const legendRef = useRef<HTMLDivElement | null>(null);
    const [pointerPosition, setPointerPosition] = useState<number>(0);

    useEffect(() => {
        handleLegendPointer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRegion])

    useEffect(() => {
        if (geoChartData && Number(dataTypes.value) !== 3) {
            const countries = geoChartData.slice(1);
            let minValue = 0;
            let maxValue = 0;

            countries.forEach(([, count]) => {
                minValue = Math.min(minValue, count);
                maxValue = Math.max(maxValue, count);
            });

            setLegendValues({
                min: minValue,
                max: maxValue
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geoChartData]);

    const chartOptions = {
        backgroundColor: {fill: 'transparent'},
        legend: "none",
        tooltip: {isHtml: true},
        dataMode: 'regions',
        colorAxis: {
            colors,
            minValue: legendValues.min,
            maxValue: legendValues.max
        },
    };

    const handleLegendPointer = () => {
        if (selectedRegion && legendRef.current) {
            const gradientWidth = legendRef.current.offsetWidth;
            const percentsOfEvents = selectedRegion[1] * 100 / legendValues.max;
            const pointerPosition = gradientWidth / 100 * percentsOfEvents - pointerHalfSize;
            setPointerPosition(pointerPosition)
        } else {
            return
        }
    }

    const detectFilterType = () => {
        if (Number(dataTypes.value) === 1) {
            return FilterType.TotalViews
        }
        if (Number(dataTypes.value) === 2) {
            return FilterType.UniqueUsers
        } else {
            return FilterType.UniqueUsers
        }
    }
    const setDescriptionToLegend = (filterType: number) => {
        if (filterType === FilterType.UniqueUsers) {
            return "Unique users"
        }
        if (filterType === FilterType.TotalViews) {
            return "Total views"
        }
    }
    const renderLegendIfUnknown = () => {
        const unknownCountry = geoChartData && geoChartData.find((x: (string | number)[]) => x[0] === "Unknown");
        if (unknownCountry) {
            return (
                <p className={styles.geoChartUnknown}>
                    <span>Unknown</span> - {setDescriptionToLegend(detectFilterType())}: <span>{unknownCountry[1]}</span>
                    <br/>(country was not specified and could not be displayed on the map)
                </p>
            )
        } else {
            return null;
        }
    }

    const createTooltip = (country: any, eventsCount: any, uniqueUsers: any, totalViews: any, viewPerEvent: any): string => {
        return `<div style="background-color: #F3F4F3; position: absolute; top: -90px; left: -10px; width: auto; padding: 10px; border: 2px solid #E8E8E8; border-radius: 4%">
                <p style="font-weight: bold; color: #FE3466; display: block; font-size: 16px;">${country}</p>
                <br/><span style='white-space: nowrap'>Events count: <span style="font-weight: bold;">${eventsCount.toLocaleString('en-US')}</span></span><br/>
                <br/><span style='white-space: nowrap'>Unique Users: <span style="font-weight: bold;">${uniqueUsers.toLocaleString('en-US')}</span></span><br/>
                <br/><span style='white-space: nowrap'>Total Views: <span style="font-weight: bold;">${totalViews.toLocaleString('en-US')}</span></span><br/>
                <br/><span style='white-space: nowrap'>View Per Event: <span style="font-weight: bold;">${viewPerEvent.toLocaleString('en-US')}</span></span><br/></div>`;
    };

    const convertedChartDataWithTooltip = mainData
        ? [
            ['Country', 'Events count', {role: 'tooltip', type: 'string', p: {html: true}}],
            ...mainData.mapBreakdown.geoLabelData.map(([country, eventsCount, uniqueUsers, totalViews, viewPerEvent]) =>
                [country, +eventsCount, +uniqueUsers, +totalViews, +viewPerEvent,
                    createTooltip(country, +eventsCount, +uniqueUsers, +totalViews, +viewPerEvent)]
            )
        ]
        : [];


    const formatChartDataByFilterType = (arr: any[][]): any[][] => {
        const filterType = detectFilterType()
        return arr.map((row, index) => {
            if (index === 0) {
                return row
            } else {
                return [row[0], row[filterType], row[row.length - 1]]
            }
        })
    };

    const chartData = formatChartDataByFilterType(convertedChartDataWithTooltip);

    const handleSelect = useCallback((region: any) => {
        setSelectedRegion(region)
    }, []);

    return (
        <div className={styles.geoChart}>
            <GoogleGeoChart
                width={`${window.innerWidth}px`}
                height={`${window.innerHeight}px`}
                data={chartData}
                options={chartOptions}
                onSelect={handleSelect}
            />
            <div className={styles.geoLegendInner}>
                {renderLegendIfUnknown()}
                <div className={styles.gradientInner}>
                    <p className={styles.minLegendValue}>{legendValues.min}</p>
                    <div className={styles.gradient} ref={legendRef}>
                        {selectedRegion && <IoCaretDownSharp
                            style={{left: `${pointerPosition}px`}}
                            className={styles.pointer}
                            size={`${pointerHalfSize * 2}px`}
                            color={'#FFFFFF'}
                        />
                        }
                    </div>
                    <p className={styles.maxLegendValue}>{legendValues.max}</p>
                </div>
            </div>
        </div>
    )
})
GeoChart.displayName = "GeoChart"
export default React.memo(GeoChart);

