import React from 'react';
import styles from "./index.module.scss"
import FilterControls, {IFilterControl} from "../helpers/renderFilterControls";

export interface IOperatorFilters {
    setFilters: (value: any, name: any) => void;
    filterValues: any;
    filterControls: IFilterControl[];
    resetFilters: () => void;
}

const HeaderFilters: React.FC<IOperatorFilters> = ({setFilters, filterValues, filterControls, resetFilters}) => {
    return (
        <div className={styles.filters}>
            <button className={styles.resetFiltersButton} onClick={() => resetFilters()}>Reset Filters</button>
            <FilterControls setFilters={setFilters} controls={filterControls} filterValues={filterValues}/>
        </div>
    );
};

export default HeaderFilters;
