import React, {useEffect, useMemo, useState} from 'react';
import styles from "../ContentAccessForm/index.module.scss";
import {Controller, useForm} from "react-hook-form";
import SelectInput from "../../../../../components/SelectInput/SelectInput";
import Checkbox from "../../../../../components/Checkbox";
import Input from "../../../../../components/Input/Input";
import {SingleValue} from "react-select";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {
    getOperatorAccessInit,
    getRightsByOperator,
    IUpdateAccess, updateUnconfirmedAccess
} from "../../../../../store/operatorAccess/operatorAccessSlice";
import {formatSelectOptions} from "../../../../../helpers/formatSelectOptions";

interface OperatorAccessFormProps {
    closeForm: () => void
    updatingAccessId: string | null;
    rightHolderId: string | null;
}

interface IFormInput {
    operator: { label: string; value: string };
    right: { label: string; value: string };
    fixtures: number
}

const UpdateAccessForm: React.FC<OperatorAccessFormProps> = React.memo(({
                                                                            closeForm,
                                                                            updatingAccessId,
                                                                            rightHolderId
                                                                        }) => {
    const [operator, setOperator] = useState<SingleValue<{ value: string, label: string } | undefined>>(undefined);
    const [right, setRight] = useState<SingleValue<{ value: string, label: string } | undefined>>(undefined);
    const [fixtureCount, setFixtureCount] = useState<number | undefined>(undefined);
    const [isAllFixturesSelected, setIsAllFixturesSelected] = useState(false);
    const dispatch = useAppDispatch()
    const {operatorInpOptions, rightsInpOptions, unconfirmedAccess} = useAppSelector(state => state.operatorAccess)
    const {control, handleSubmit, setValue, formState: {errors}} = useForm<IFormInput>();

    useEffect(() => {
        setDefaultValues()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSetOperator = (option: SingleValue<{ value: string, label: string }>) => {
        if (option) {
            const body = {
                operatorIds: [option.value],
                rightHolderId
            }
            dispatch(getRightsByOperator(body))
            setOperator(option)
        }
    }
    const setDefaultValues = () => {
        dispatch(getOperatorAccessInit(rightHolderId))
        const updatingAccess = unconfirmedAccess.find(access => access.id === updatingAccessId)
        if (updatingAccess) {
            dispatch(getRightsByOperator({operatorIds: [updatingAccess.operatorId], rightHolderId}))
            setOperator({value: updatingAccess.operatorId.toString(), label: updatingAccess.operatorName})
            setRight({value: updatingAccess.rightId, label: updatingAccess.rightName})
            if (updatingAccess.assignedAllFixtures) {
                setFixtureCount(0)
                setValue("fixtures", 0)
            } else {
                setFixtureCount(updatingAccess.fixturesCount)
                setValue("fixtures", updatingAccess.fixturesCount)
            }
            setIsAllFixturesSelected(updatingAccess.assignedAllFixtures)
        }
    }

    const toggleAssignedAllFixtures = () => {
        setFixtureCount(0)
        setIsAllFixturesSelected(!isAllFixturesSelected)
    }

    const onUpdateNewAccess = () => {
        const body = {
            id: updatingAccessId,
            assignedAllFixtures: isAllFixturesSelected,
            rightHolderId,
            rightId: right!.value,
            fixturesCount: fixtureCount,
            operatorId: operator!.value

        } as IUpdateAccess

        dispatch(updateUnconfirmedAccess(body))
        closeForm()
    }

    const formatSelectOperators = useMemo(() => (
        formatSelectOptions(operatorInpOptions)
    ), [operatorInpOptions]);

    const formatSelectRights = useMemo(() => (
        formatSelectOptions(rightsInpOptions)
    ), [rightsInpOptions]);

    return (
        <div className={styles.overlay}>
            <form
                data-testid={"form"}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                onSubmit={handleSubmit(onUpdateNewAccess)}
                className={styles.form}>
                <div className={styles.content}>
                    <div>
                        <h6 className={styles.titleInner}>
                            <span className={styles.title}>Update Access</span>
                        </h6>
                    </div>
                    <div>
                        <label className={styles.label}>Operator
                            <SelectInput
                                value={operator}
                                onChange={handleSetOperator}
                                options={formatSelectOperators}
                                placeholder={"Choose an option"}
                            />
                        </label>
                    </div>
                    <div>
                        <label className={styles.label}>Right(s)
                            <SelectInput
                                value={right}
                                onChange={setRight}
                                options={formatSelectRights}
                                placeholder={"Choose an option"}
                            />
                        </label>
                    </div>

                    <div>

                        <div className={styles.checkboxInner}>
                            <p className={styles.fixtureLabel}>Fixture</p>
                            <div className={styles.checkbox}>
                                <span>All Fixtures</span>
                                <Checkbox isChecked={isAllFixturesSelected}
                                          onChangeHandler={toggleAssignedAllFixtures}
                                          filled={true}
                                />
                            </div>
                        </div>
                        <Controller
                            control={control}
                            name="fixtures"
                            rules={{
                                validate: {
                                    isPositive: value => Number(value) > 0 || Number(value) === 0,
                                    // eslint-disable-next-line no-mixed-operators
                                    isAllSelected: (value) => (isAllFixturesSelected) && Number(value) === 0 || Number(value) > 0
                                }
                            }}
                            render={({field: {onChange}}) => (
                                <Input
                                    data-testid={"fixtureCount"}
                                    onChange={(e) => {
                                        onChange(e);
                                        setFixtureCount(Number(e.target.value))
                                    }}
                                    disabled={isAllFixturesSelected}
                                    value={fixtureCount ? fixtureCount : ''}
                                    type="number"
                                    placeholder={"Number of Fixtures"}
                                />
                            )}
                        />
                        {errors?.fixtures?.type === "isPositive" && !isAllFixturesSelected &&
                            <div className={styles.formErrorBlock}>
                                Set positive number of fixtures or use "All fixtures"
                            </div>
                        }
                        {errors?.fixtures?.type === "isAllSelected" && !isAllFixturesSelected &&
                            <div className={styles.formErrorBlock}>
                                Select "All Fixture" or set count of fixture
                            </div>
                        }

                    </div>
                </div>
                <div className={styles.buttons}>
                    <button
                        onClick={closeForm}
                        className={styles.cancelButton}
                        type="button">Cancel
                    </button>
                    <button
                        className={styles.submitButton}
                        type="submit">Submit
                    </button>
                </div>
            </form>
        </div>
    );
});

export default UpdateAccessForm;
UpdateAccessForm.displayName = "UpdateAccessForm"
