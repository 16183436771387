import React, {Dispatch, SetStateAction, useRef} from 'react';
import styles from "../../index.module.scss";
import AppTableWhite from "../../../../../../../components/AppTableWhite/AppTableWhite";
import SortingHeader from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/children/SortingHeader/SortingHeader";
import {Tooltip} from "react-tooltip";
import trash from "../../../../../../../img/trashWhite.svg";
import edit from "../../../../../../../img/editWhite.svg";
import cartIcon from "../../../../../../../img/cartEmpty.svg";
import {ITableRowData} from "../../BookFixtures";
import {ISorting} from "../../../SheduleIngestHooks/useSortingAndSearch";
import {AppInfiniteScroll} from "../../../../../../../components/AppInfiniteScroll/AppInfiniteScroll";
import {useAppDispatch, useAppSelector} from "../../../../../../../hooks/hooks";
import {RootState} from "../../../../../../../store";
import {setPageNumber} from "../../../../../../../store/rightHolderBooking/rightHolderBookingSlice";
import {DateTime} from "luxon";

export interface IBookFixturesTableProps {
    tableData: ITableRowData[];
    sorting: ISorting;
    setSorting: Dispatch<SetStateAction<ISorting>>;
    handleRemoveFixtureClick: (fixture: ITableRowData) => void;
    handleEditFixtureClick: (fixture: ITableRowData) => void;
    handleAddToCartClick: (fixture: ITableRowData) => void;
    fetchData: (page: number, pageSize: number, scrollPosition: string) => void;
    containerRef: React.RefObject<HTMLDivElement>;
}

const BookFixturesTable: React.FunctionComponent<IBookFixturesTableProps> = ({
                                                                                 tableData,
                                                                                 sorting,
                                                                                 setSorting,
                                                                                 handleRemoveFixtureClick,
                                                                                 handleEditFixtureClick,
                                                                                 handleAddToCartClick,
                                                                                 fetchData,
                                                                                 containerRef
                                                                             }) => {

    const dispatch = useAppDispatch();
    const {
        pageNumber,
        itemsPerPage,
        totalCount,
        scrollLoader,
        rightsHolderCartFixtures,
        maxItemsInTheTable
    } = useAppSelector((state: RootState) => state.rightHolderBooking);
    const tableRef = useRef<HTMLTableElement | null>(null);
    const headerControls = [
        {
            title: "Sport",
            criteria: "Sport",
        },
        {
            title: "Content Supplier",
            criteria: "ContentSupplier",
        },
        {
            title: "Start Time (UTC)",
            criteria: "StartDate",
        },
        {
            title: "End Time (UTC)",
            criteria: "EndDate",
        },
        {
            title: "Property",
            criteria: "Competition",
        },
        {
            title: "Title",
            criteria: "Title",
        },
        {
            title: "Description",
            criteria: "Description",
        },
        {
            title: "Actions",
            criteria: null,
        }
    ]

    const setPage = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber))
    }

    const checkIsInCart = (fixtureId: string) => {
        return rightsHolderCartFixtures.find(fixture => fixture.fixtureId === fixtureId);
    }

    return (
        <AppInfiniteScroll
            containerRef={containerRef}
            fetchData={fetchData}
            pageSize={itemsPerPage}
            totalItems={totalCount}
            currentPage={pageNumber}
            setPage={setPage}
            isLoading={scrollLoader}
            scrollingContainerHeight="95%"
            scrollBounce={200}
            maxItemsInTheTable={maxItemsInTheTable}
            tableDataLength={tableData.length}>
            <AppTableWhite reference={tableRef}>
                <SortingHeader
                    sorting={sorting}
                    setSorting={setSorting}
                    controls={headerControls}
                />
                <tbody>
                {
                    tableData.map((item) => (
                        <tr key={item.id}>
                            <td>
                                {item.sport}
                            </td>
                            <td>
                                {item.contentSupplier}
                            </td>
                            <td>
                                {DateTime.fromISO(item.startDate).toFormat(`dd-MM-yyyy HH:mm`)}
                            </td>
                            <td>
                                {DateTime.fromISO(item.endDate).toFormat(`dd-MM-yyyy HH:mm`)}
                            </td>
                            <td className={styles.paddingRightWide}>
                                {item.competition}
                                {
                                    checkIsInCart(item.id) && <div className={styles.indicator}></div>
                                }
                            </td>
                            <td>
                                {item.title}
                            </td>
                            <td>
                                {item.description}
                            </td>
                            <td>
                                <div className={styles.tableButtons}>
                                    <button className={styles.tableButton}
                                            onClick={() => handleRemoveFixtureClick(item)}>
                                        <Tooltip className={styles.userTableTooltip} id={"delete-button"}/>
                                        <img
                                            className={styles.deleteIcon}
                                            src={trash}
                                            alt={"delete"}
                                            data-tooltip-id={"delete-button"}
                                            data-tooltip-content={"Delete fixture"}
                                        />
                                    </button>
                                    <button className={styles.tableButton}
                                            onClick={() => handleEditFixtureClick(item)}>
                                        <Tooltip className={styles.userTableTooltip} id={"edit-button"}/>
                                        <img
                                            className={styles.editIcon}
                                            src={edit}
                                            alt={"edit"}
                                            data-tooltip-id={"edit-button"}
                                            data-tooltip-content={"Edit fixture"}
                                        />
                                    </button>
                                    <button className={styles.tableButton}
                                            onClick={() => handleAddToCartClick(item)}>
                                        <Tooltip className={styles.userTableTooltip} id={"cart-button"}/>
                                        <img
                                            className={checkIsInCart(item.id)
                                                ?
                                                `${styles.cartIcon} ${styles.iconDisabled}`
                                                :
                                                `${styles.cartIcon}`
                                            }
                                            src={cartIcon}
                                            alt={"cart"}
                                            data-tooltip-id={"cart-button"}
                                            data-tooltip-content={
                                                rightsHolderCartFixtures.find(fixture => fixture.fixtureId === item.id)
                                                    ?
                                                    "Remove from cart"
                                                    :
                                                    "Add to cart"
                                            }
                                        />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </AppTableWhite>
        </AppInfiniteScroll>
    )
}

export default BookFixturesTable;
