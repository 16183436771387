import {
    clearIssueDetails,
    getOneGeneralIssue,
    IOpenGeneralIssue
} from "../../../../../../../../store/generalIssues/generalIssuesSlice";
import React, {useState} from "react";
import styles from '../index.module.scss';
import generalStyles from '../../index.module.scss';
import AppTable from "../../../../../../../../components/AppTable/AppTable";
import editIcon from "../../../../../../../../img/EditIcon.svg";
import {useAppDispatch, useAppSelector} from "../../../../../../../../hooks/hooks";
import GeneralIssueForm from "../../GeneralIssueForm";
import uuid from "react-uuid";
import audit from "../../../../../../../../img/auditWhite.svg";
import {useNavigate} from "react-router-dom";
import {setIssuesDetails} from "../../../../../../../../store/auditTrailsIssues/auditTrailsIssuesSlice";

interface IOngoingGeneralIssues {
    ongoingIssues: IOpenGeneralIssue[];
    closePopup: () => void;
    clearFormData: () => void;
    setFormStyles: React.Dispatch<React.SetStateAction<string>>;
    generalIssueFormHandler: React.Dispatch<React.SetStateAction<boolean>>;
    searchText: string | undefined;
    searchEventStartTime: string;
}

const OngoingIssuesTable: React.FC<IOngoingGeneralIssues> = (
    {
        ongoingIssues,
        closePopup,
        clearFormData,
        setFormStyles,
        generalIssueFormHandler,
        searchText,
        searchEventStartTime,
    }) => {

    const [isOpenGeneralIssue, setIsOpenGeneralIssue] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {ongoingIssueDetails} = useAppSelector(state => state.reportingGeneralIssues);

    const editHandler = (id: string) => {
        dispatch(getOneGeneralIssue(id))
        dispatch(clearIssueDetails());
        setIsOpenGeneralIssue(true);
        setFormStyles(generalStyles.form);
        closePopup();

    }

    const newGeneralFormHandler = () => {
        dispatch(clearIssueDetails());
        setFormStyles(generalStyles.form);
        clearFormData();
        closePopup();
    }

    const closePopUpHandler = () => {
        generalIssueFormHandler(false);
        clearFormData();
        closePopup();
    }

    return (
        <div className={styles.wrapper}>

            <div className={styles.form}>
                <h3 className={styles.title}>Ongoing General Issues</h3>
                <div className={styles.tableBackground}>
                    <AppTable>
                        <th>Issue</th>
                        <th>Issue Description</th>
                        <th></th>
                        {
                            !ongoingIssues.length ?
                                <tr>
                                    <td>-</td>
                                    <td>-</td>
                                    <td></td>
                                </tr>
                                :
                                ongoingIssues.map((issue) => {
                                    return (
                                        <tr key={uuid()}>
                                            <td>{issue.detail}</td>
                                            <td>{issue.issueDescription}</td>
                                            <td className={styles.tableButtons}>
                                                <button className={styles.tableButton}
                                                        onClick={() => editHandler(issue.id)}>
                                                    <img
                                                        className={styles.editIcon}
                                                        src={editIcon}
                                                        alt="editIcon"
                                                    />
                                                </button>
                                                <button className={styles.tableButton}
                                                        onClick={() => {
                                                            navigate("/cms/audit-trails-issues")
                                                            dispatch(setIssuesDetails({
                                                                id: issue.id,
                                                                rightHolder: issue.rightHolders,
                                                                technicalProvider: issue.technicalProviders,
                                                                competition: issue.competitions
                                                            }))
                                                        }}>
                                                    <img
                                                        className={styles.auditIcon}
                                                        src={audit}
                                                        alt={"audit"}
                                                    />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                        }
                    </AppTable>
                </div>
                <div className={styles.buttons}>
                    <button type={'button'} onClick={closePopUpHandler}
                            className={styles.cancelButton}>Cancel
                    </button>
                    <button
                        onClick={newGeneralFormHandler}
                        className={styles.submitButton}
                        type="button">
                        New General Issue
                    </button>
                </div>
            </div>

            {isOpenGeneralIssue &&
                <GeneralIssueForm
                    editIssue={ongoingIssueDetails!}
                    formHandler={closePopup}
                    searchText={searchText}
                    searchEventStartTime={searchEventStartTime}
                />
            }
        </div>
    );
};

export default OngoingIssuesTable;
OngoingIssuesTable.displayName = "OngoingIssuesTable";
