import React from 'react';
import styles from "./index.module.scss"
import {ISorting} from "../../../../../components/OperatorDefaultTable/OperatorDefaultTable";
import OperatorSortingHeader, {
    IColumnItem
} from "../../../../../components/OperatorSortingHeader/OperatorSortingHeader";
import {ContentItem} from "../../../../../store/contentAnalysis/types";
import AppTableWhite from "../../../../../components/AppTableWhite/AppTableWhite";

interface IAvailableContentTableProps {
    data: ContentItem[]
    sorting: ISorting;
    setSorting: ((value: ISorting) => void);
    renderActionCell: (rowItem: ContentItem) => React.ReactNode
}

const AvailableContentTable: React.FC<IAvailableContentTableProps> = (
    {
        sorting,
        setSorting,
        data,
        renderActionCell
    }) => {
    const tableHeaders: IColumnItem[] = [
        {
            title: "Date",
            criteria: "Date",
            key: "date",
            textAlign: "left"
        },
        {
            title: "Day",
            criteria: "Day",
            key: "day",
            textAlign: "left"
        },
        {
            title: "Time",
            criteria: "Time",
            key: "time",
            textAlign: "left"
        },
        {
            title: "Sport",
            criteria: "Sport",
            key: "sport",
            textAlign: "left"
        },
        {
            title: "Property",
            criteria: "Property",
            key: "property",
            textAlign: "left"
        },
        {
            title: "Fixture",
            criteria: "Fixture",
            key: "fixture",
            textAlign: "left"
        },
        {
            title: "Remaining Selections",
            criteria: "КemainingSelections",
            key: "remainingSelections",
            textAlign: "center"
        },
        {
            title: "Add to Cart",
            criteria: null,
            key: "addToCart",
            textAlign: "center"
        },
    ];

    return (
        <div className={styles.tableScroll}>
            <div className={styles.tableContainer}>
                <AppTableWhite>
                    <OperatorSortingHeader
                        sorting={sorting}
                        setSorting={setSorting}
                        columnsData={tableHeaders}
                    />
                    <tbody>
                    {
                        data.map((rowItem: any, index) => (
                            <tr key={index}>
                                {
                                    tableHeaders.slice(0, -1).map((cell, index) => {
                                            return (
                                                <td
                                                    style={{textAlign: cell.textAlign}}
                                                    key={index}
                                                >
                                                    {
                                                        cell.key === 'remainingSelections' && !rowItem[cell.key] ?
                                                            ''
                                                            :
                                                            rowItem[cell.key]
                                                    }
                                                </td>
                                            )

                                        }
                                    )
                                }
                                <td
                                    style={{textAlign: "center"}}
                                    className={styles.centeredCell} key={index}>
                                    {renderActionCell(rowItem)}
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </AppTableWhite>
            </div>
        </div>
    );
};

export default AvailableContentTable;
