import {ISidebarItem} from "../CmsSideBarData/CmsSideBarData";

export const getAllowedPages = (
    allowedPages: string[],
    sidebarData: ISidebarItem[]
) => {
    const formMultiLeveledSidebar = (pagesArray: ISidebarItem[]) => {
        return pagesArray.reduce((acc: ISidebarItem[], sidebarPage: ISidebarItem) => {
            const isPageAllowed = allowedPages.includes(sidebarPage.pageName || '');
            if (sidebarPage.children) {
                const filteredChildren = formMultiLeveledSidebar(sidebarPage.children);
                const hasAllowedChildren = filteredChildren.length > 0;

                if (isPageAllowed || hasAllowedChildren) {
                    acc.push({...sidebarPage, children: filteredChildren});
                }
            } else if (isPageAllowed) {
                acc.push(sidebarPage);
            }

            return acc;
        }, []);
    };

    return formMultiLeveledSidebar(sidebarData);
};
