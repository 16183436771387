import React from 'react';
import ReactDOM from 'react-dom/client';
import {store} from './store';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr'
import App from './App';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './components/Toastr/style.scss';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Provider store={store}>
        <ReduxToastr
            className="toastr"
            timeOut={5000}
            newestOnTop={false}
            preventDuplicates
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            closeOnToastrClick
        />
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>
);


