import React from 'react';
import ProtectedRoute from "../../../../components/ProtectedRoute/ProtectedRoute";
import {Group} from "../../../../enums/group";
import {Route} from "react-router";
import {IAppRoute} from "./RouteInterface";
import UpcomingBookedFixtures from "../../TechnicalProviderView/UpcomingBookedFixtures/UpcomingBookedFixtures";

export const TechnicalProviderRoutes: IAppRoute[] = [
    {
        name: "technicalProvider-upcoming-fixtures",
        route:
            <Route
                path="cms/technicalProvider-upcoming-fixtures"
                element={
                    <ProtectedRoute forGroup={Group.TechnicalProvider}>
                        <UpcomingBookedFixtures/>
                    </ProtectedRoute>
                }>
            </Route>
    }
]


