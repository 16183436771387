import {useAppDispatch} from "@hooks/hooks";
import {removeFixtureFromCart} from "@store/carts/operatorBFCart/bookFixtureCartSlice";

export const useHandleBFCart = (operatorId: string | null) => {
    const dispatch = useAppDispatch();


    const handleDeleteBookedFixture = (cartItemId: string) => {
        dispatch(removeFixtureFromCart({
                cartItemId,
                operatorId
            }
        ))
    }

    return {
        handleDeleteBookedFixture
    }
}
