import React, {useEffect, useMemo} from "react";
import {Control, Controller, FieldErrors, FieldValues, UseFormGetValues, UseFormRegister} from "react-hook-form";
import {SingleValue} from "react-select";
import {ITableRowData} from "../../../../BookFixtures";
import {useAppDispatch, useAppSelector} from "@hooks/hooks";
import {RootState} from "@store/index";
import {clearIpChannels} from "@store/init/initSlice";
import {formatSelectOptions} from "@helpers/formatSelectOptions";
import SelectInput from "@components/SelectInput/SelectInput";
import IngestRender from "../../helpers/IngestRender";
import {IFormIngestTypeState} from "../../types/types";
import {IngestFormController, IngestTabs} from "@enums/ingest";
import {UseFormClearErrors} from "react-hook-form/dist/types/form";
import IngestDetails
    from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/children/IngestForm/helpers/IngestDetails";
import styles from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/children/IngestForm/index.module.scss";
import {
    generateRegisterValue
} from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/children/IngestForm/helpers/generateRegisterValue";

export interface IIPFormProps {
    fixtureData: ITableRowData | null;
    ingestType: IFormIngestTypeState;
    control: Control<FieldValues, IIPInput>;
    setValue: (name: string, value: IIPInput) => void;
    getValues: UseFormGetValues<FieldValues>;
    clearErrors: UseFormClearErrors<FieldValues>;
    currentTabState: IngestTabs;
    register: UseFormRegister<FieldValues>;
    errors: FieldErrors<FieldValues>;
}

export interface IIPInput {
    value: string;
    label: string;
}

export const IPChannels = (
    {
        fixtureData,
        currentTabState,
        control,
        setValue,
        getValues,
        clearErrors,
        register,
        errors
    }: IIPFormProps) => {
    const dispatch = useAppDispatch();
    const {backupDetails, details, isActivateBackup} = useAppSelector((state: RootState) => state.rightHolderBooking);
    const channels = useAppSelector((state: RootState) => state.initInfo.ipChannels);

    const changeIPChannels = (selectedChannel: SingleValue<{ label: string; value: string }>) => {
        if (fixtureData && selectedChannel) {
            setValue(IngestFormController.PrimaryIPChannels, selectedChannel);
        }
    };

    useEffect(() => {
        return () => {
            clearErrors()
            dispatch(clearIpChannels());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isActivateBackup && channels && details.ip) {
            IngestDetails.setPrimaryIpChannel(details, channels, setValue)
        }
        if (backupDetails.ipFixture && channels && isActivateBackup) {
            IngestDetails.setBackupIPChannels(backupDetails, channels, setValue)
        }
        if (!channels.length) {
            setValue(IngestFormController.PrimaryIPChannels, {value: '', label: ''});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details, backupDetails, channels]);

    const channelsOptions = useMemo(() => {
        return formatSelectOptions(channels)
    }, [channels])

    const IPChannelsJSX = (
        <label>{currentTabState === IngestTabs.Primary ? 'Primary IP channel' : 'Backup IP channel'}
            <Controller
                control={control}
                name={currentTabState === IngestTabs.Primary ? IngestFormController.PrimaryIPChannels : IngestFormController.BackupIPChannels}
                rules={{required: true}}
                render={({field: {onChange, value}}) => (
                    <SelectInput
                        {...register(generateRegisterValue(currentTabState))}
                        name={currentTabState === IngestTabs.Primary ? IngestFormController.PrimaryIPChannels : IngestFormController.BackupIPChannels}
                        options={channelsOptions}
                        value={value}
                        onChange={
                            (x) => {
                                onChange(x);
                                changeIPChannels(x)
                            }}
                    />
                )}
            />
            {errors.BackupIPChannels && errors.BackupIPChannels.type === "required" && (
                <span className={styles.errorMessage}>{errors.BackupIPChannels.message && String(errors.BackupIPChannels.message)}</span>
            )}
            {errors.PrimaryIPChannels && errors.PrimaryIPChannels.type === "required" && (
                <span className={styles.errorMessage}>{errors.PrimaryIPChannels.message && String(errors.PrimaryIPChannels.message)}</span>
            )}

        </label>
    );

    return (IngestRender.IPChannels(IPChannelsJSX, currentTabState, getValues))
};
