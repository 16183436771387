import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {client} from "../../services/api-servise";
import {toastr} from "react-redux-toastr";
import {AxiosError} from "axios";
import {pagesAllowedByFrontEnd} from "../../pages/Cms/AppRoutes/appPages/appPages";

export interface IUserInfoState {
    pages: number[],
    role: string,
    specificRole: string,
    email: string,
    organization: string,
    currentIgameUserGroup: string
}

const initialState: IUserInfoState = {
    pages: [],
    role: "",
    specificRole: "",
    email: "",
    organization: "",
    currentIgameUserGroup: ""
}

export const getCurrentUserInfo = createAsyncThunk<IUserInfoState, null, { rejectValue: string }>(
    'user-info/get-user-info',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/Auth/allowed-pages`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const userInfoSlice = createSlice({
    name: 'user-info',
    initialState,
    reducers: {
        setCurrentIgameUserGroup: (state, action: PayloadAction<string>) => {
            state.currentIgameUserGroup = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCurrentUserInfo.fulfilled, (state, action) => {
                state.pages = [...action.payload.pages, ...pagesAllowedByFrontEnd]
                state.role = action.payload.role
                state.specificRole = action.payload.specificRole
                state.email = action.payload.email
                state.organization = action.payload.organization
            })
    }
})

export const {setCurrentIgameUserGroup} = userInfoSlice.actions
export default userInfoSlice.reducer

