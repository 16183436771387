import React from 'react';
import styles from './index.module.scss';
import {HiOutlineChevronLeft, HiOutlineChevronRight} from "react-icons/hi";

interface ISwitcher {
    handlePrevButtonClick: () => void;
    handleNextButtonClick: () => void;
}

const Switcher: React.FC<ISwitcher> = React.memo(({handlePrevButtonClick, handleNextButtonClick}) => {
    return (
        <div className={styles.switcher}>
            <button onClick={handlePrevButtonClick}>
                <HiOutlineChevronLeft color={'#FF3466'}/>
            </button>
            <button onClick={handleNextButtonClick}>
                <HiOutlineChevronRight color={'#FF3466'}/>
            </button>
        </div>
    );
});

Switcher.displayName = "Switcher"
export default Switcher;
