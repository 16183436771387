import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

export const usePricingAndAccessDetailsFromQueryString = () => {
    const [recordData, setRecordData] = useState<{
        rightHolder: string | null,
        competition: string | null,
        operator: string | null
    } | null>({
        rightHolder: null,
        competition: null,
        operator: null,
    });

    const query = new URLSearchParams(useLocation().search);
    const rightHolder = query.get("rightHolder");
    const operator = query.get("operator");
    const competition = query.get("competition");

    useEffect(() => {
        if (rightHolder || operator || competition) {
            setRecordData({rightHolder, operator, competition})
        }
    }, [rightHolder, operator, competition]);

    return {setRecordData, recordData}
}
