import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import styles from './index.module.scss';
import {getRoles} from "../../../../../../../store/settings/settingsSlice";
import {useAppDispatch, useAppSelector} from "../../../../../../../hooks/hooks";
import AddUserButton from "../AddUserButton/AddUserButton";
import Loader from "../../../../../../../components/Loader/Loader";
import UserForm from "./children/UserForm/UserForm";
import {clearUsers, getIGameUsers, sortIGameUsers} from "../../../../../../../store/userManagement/userManagementSlice";
import {PageTabs} from "../../UserManagement";
import {createUsersQueriesString} from "./helpers/createUsersQueriesString";
import {InnerRole} from "../../../../../../../enums/innerRole";
import {useDisableScroll} from "../../../../../../../hooks/useDisableScroll";
import UsersTable from "./children/UsersTable/UsersTable";

interface IGameUsersTableProps {
    pdfLayout: React.RefObject<HTMLDivElement>;
    isDownloadingPDF: boolean;
    setCurrentTab: Dispatch<SetStateAction<string>>;
    setSearchValue: Dispatch<SetStateAction<string | undefined>>
}

const IGameUsers: React.FC<IGameUsersTableProps> = React.memo((
    {
        pdfLayout,
        isDownloadingPDF,
        setCurrentTab
    }) => {
    const [isOpenForm, setIsOpenForm] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const {users, loading} = useAppSelector(state => state.userManagement);
    const {specificRole} = useAppSelector(state => state.userInfo);
    useDisableScroll(isOpenForm)

    useEffect(() => {
        dispatch(getIGameUsers(""))
        dispatch(getRoles(null))
        setCurrentTab(PageTabs.I_GAME_USERS)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(clearUsers());
        };
    }, [dispatch]);

    const sortUsers = (field: string, isAscending: boolean) => {
        const payload = createUsersQueriesString(
            field,
            isAscending
        )
        dispatch(sortIGameUsers(payload))
    }

    return (
        <>
            {isOpenForm &&
                <UserForm
                    closeForm={() => setIsOpenForm(false)}
                    userType={PageTabs.I_GAME_USERS}
                />
            }

            {loading && <Loader/>}

            {!loading &&
                <div className={styles.page}>
                    <div className={styles.tableOverlay}>
                        <div className={styles.tableInner}>
                            {specificRole === InnerRole.SuperAdmin &&
                                <div className={styles.addUserBtn}>
                                    <AddUserButton
                                        buttonName={PageTabs.I_GAME_USERS}
                                        onClickHandler={() => setIsOpenForm(true)}
                                    />
                                </div>
                            }
                            <UsersTable
                                pdfLayout={pdfLayout}
                                sortUsers={sortUsers}
                                users={users}
                                userRole={PageTabs.I_GAME_USERS}
                                isDownloadingPDF={isDownloadingPDF}
                            />
                        </div>
                    </div>
                </div>
            }
        </>
    );
});

IGameUsers.displayName = "IGameUsers"
export default IGameUsers;
