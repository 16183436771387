import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {Group} from "../../../enums/group";
import {getFixturePageNumber} from "../../../store/alerts/alertsSlice";
import {unwrapResult} from "@reduxjs/toolkit";

export const useGetProperPage = () => {
    const dispatch = useAppDispatch();
    const {role} = useAppSelector(state => state.userInfo);

    const fetchFixturePageNumber = async (fixtureId: string, itemsPerPage: number, groupId?: string) => {
        let payload;

        if (role === Group.RightHolder) {
            payload = {fixtureId, itemsPerPage};
        } else {
            payload = {fixtureId, itemsPerPage, rightHolderId: groupId};
        }

        try {
            const resultAction = await dispatch(getFixturePageNumber(payload));
            return unwrapResult(resultAction);
        } catch (error) {
            console.error("Failed to get page number:", error);
        }
    };

    return {fetchFixturePageNumber};
};
