import {AnyAction, createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {toastr} from "react-redux-toastr";
import {client} from "../../services/api-servise";
import {AxiosError} from "axios";

export interface IInputOption {
    id: string | number;
    name: string;
    logo: null | string;
}

export interface IOperatorInitResponse {
    operators: {
        items: IInputOption[]
    },
    rights: {
        items: IInputOption[]
    }
}

export interface ICreatedAccess {
    operatorIds: string[],
    rightIds: string[],
    fixturesCount: number | undefined,
    assignedAllFixtures?: boolean
}

export interface IAccessDetails {
    operatorId: string;
    competitionId: string;
    allFixtures: true;
    fixturesCount: number;
}

export interface IConfirmReqBody {
    payload: {
        rightHolderId?: string | null,
        operatorAccessCreateItems: ICreatedAccess[]
    },
    closeForm: () => void

}

export interface IDeleteAccessBody {
    operatorRightId: string
    rightId: string
}

export interface IAccess {
    id: string,
    operatorId: string,
    operatorName: string,
    rightName: string,
    fixturesCount: number,
    rightId: string
}

export interface IOperatorAccessState {
    operatorInpOptions: IInputOption[]
    rightsInpOptions: IInputOption[]
    accesses: IOperatorAccess[]
    details: IAccessDetails | null
    unconfirmedAccess: ISavedAccess[]
    loading: boolean
    error: string
}

const operatorAccessState: IOperatorAccessState = {
    operatorInpOptions: [],
    rightsInpOptions: [],
    accesses: [],
    details: null,
    unconfirmedAccess: [],
    loading: false,
    error: ''

}

export interface IAccess {
    id: string,
    operatorId: string,
    operatorName: string,
    rightName: string,
    fixturesCount: number,
    rightId: string
}

export interface IOperatorAccess {
    operatorName: string,
    createdOperatorAccessDTO: {
        operatorAccesses: IAccess[]
    }
}

export interface ISavedAccess {
    id: string,
    operatorId: string,
    operatorName: string,
    rightName: string,
    fixturesCount: number,
    assignedAllFixtures: boolean,
    rightId: string,
    isConfirmed: boolean
}

export interface IUpdateAccess {
    rightHolderId: string | null,
    id: string,
    operatorId: string,
    operatorName?: string,
    rightId: string,
    fixturesCount: number,
    assignedAllFixtures: boolean
}

export interface IGetUnconfirmedAccessesResp {
    operatorAccesses: ISavedAccess[]
}

export interface IConfirmAccessesBody {
    client: string | null
    selectedAccesses: string []
}

export const getAccesses = createAsyncThunk<IOperatorAccess[], string | null, { rejectValue: string }>(
    'operator/get-accesses',
    async (rightHolderId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/RightsManagement/operator-access${rightHolderId ? `?RightHolderId=${rightHolderId}` : ""}`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getUnconfirmedAccesses = createAsyncThunk<IGetUnconfirmedAccessesResp, string | null, {
    rejectValue: string
}>(
    'operator/get-unconfirmed-accesses',
    async (rightHolderId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/RightsManagement/operator-access/unconfirmed${rightHolderId ? `?RightHolderId=${rightHolderId}` : ""}`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getOperatorAccessInit = createAsyncThunk<IOperatorInitResponse, string | null, { rejectValue: string }>(
    'operator/get-init',
    async (rightHolderId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/RightsManagement/operator-access/init${rightHolderId ? `?RightHolderId=${rightHolderId}` : ""}`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getRightsByOperator = createAsyncThunk<IOperatorInitResponse, {
    operatorIds: string[],
    rightHolderId: string | null
}, { rejectValue: string }>(
    'operator/get-rights-by-operator',
    async ({operatorIds, rightHolderId}, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/RightsManagement/operator-access/init?operatorId=${operatorIds}${rightHolderId ? `&RightHolderId=${rightHolderId}` : ""}`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const confirmAccess = createAsyncThunk<IGetUnconfirmedAccessesResp, IConfirmAccessesBody, {
    rejectValue: string
}>(
    'operator/confirm-access',
    async (body, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.put(`/api/RightsManagement/operator-access/confirm`, body.selectedAccesses)
            dispatch(getAccesses(body.client))
            dispatch(getUnconfirmedAccesses(body.client))
            toastr.success('IGame', `${body.selectedAccesses.length} access has been confirmed`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const createUnconfirmedAccess = createAsyncThunk<IGetUnconfirmedAccessesResp, IConfirmReqBody, {
    rejectValue: string
}>(
    'operator/create-unconfirmed-access',
    async ({payload, closeForm}, {rejectWithValue}) => {
        try {
            const {data} = await client.post(`api/RightsManagement/operator-access`, payload)
            closeForm()
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const deleteAccess = createAsyncThunk<IGetUnconfirmedAccessesResp, IDeleteAccessBody, { rejectValue: string }>(
    'operator/delete-access',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`api/RightsManagement/operator-access?operatorRightId=${body.operatorRightId}&rightId=${body.rightId}`)
            toastr.success('IGame', `Access has been deleted`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const updateUnconfirmedAccess = createAsyncThunk<ISavedAccess, IUpdateAccess, { rejectValue: string }>(
    'operator/update-unconfirmed-access',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`api/RightsManagement/operator-access`, body)
            toastr.success('IGame', `Access has been updated`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const deleteUnconfirmedAccess = createAsyncThunk<IGetUnconfirmedAccessesResp, IDeleteAccessBody, {
    rejectValue: string
}>(
    'operator/delete-unconfirmed-access',
    async (body, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`api/RightsManagement/operator-access?operatorRightId=${body.operatorRightId}&rightId=${body.rightId}`)
            toastr.success('IGame', `Access has been deleted`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const getDetailsForAccessUpdate = createAsyncThunk<IAccessDetails, string, { rejectValue: string }>(
    'operator/get-details',
    async (accessId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/RightsManagement/operator-access/details?operatorRight=${accessId}`)
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const updateConfirmedAccess = createAsyncThunk<ISavedAccess, IUpdateAccess, { rejectValue: string }>(
    'operator/update-confirmed-access',
    async (body, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await client.put(`api/RightsManagement/operator-access/confirmed`, body)
            toastr.success('IGame', `Access has been updated`)
            dispatch(getAccesses(body.rightHolderId))
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    }
)

export const operatorAccessSlice = createSlice({
    name: 'operator',
    reducers: {},
    initialState: operatorAccessState,

    extraReducers: (builder) => {
        builder
            .addCase(getAccesses.pending, (state) => {
                state.error = ""
                state.loading = true
            })
            .addCase(getAccesses.fulfilled, (state, action) => {
                state.error = ""
                state.loading = false
                state.accesses = action.payload
            })
            .addCase(getUnconfirmedAccesses.fulfilled, (state, action) => {
                state.unconfirmedAccess = action.payload.operatorAccesses
            })
            .addCase(createUnconfirmedAccess.fulfilled, (state, action) => {
                state.unconfirmedAccess.push(...action.payload.operatorAccesses)
            })
            .addCase(getOperatorAccessInit.fulfilled, (state, action) => {
                state.error = ""
                if (action.payload.operators) {
                    state.operatorInpOptions = action.payload.operators.items
                }
                if (action.payload.rights) {
                    state.rightsInpOptions = action.payload.rights.items
                }
            })
            .addCase(getRightsByOperator.fulfilled, (state, action) => {
                state.error = ""
                if (action.payload.rights) {
                    state.rightsInpOptions = action.payload.rights.items
                }
            })
            .addCase(deleteAccess.fulfilled, (state, action) => {
                state.loading = false
                state.accesses.forEach(access => {
                    access.createdOperatorAccessDTO.operatorAccesses = access.createdOperatorAccessDTO.operatorAccesses.filter(operator => operator.id !== action.meta.arg.operatorRightId.toString())
                })
            })
            .addCase(updateUnconfirmedAccess.fulfilled, (state, action) => {
                state.unconfirmedAccess = state.unconfirmedAccess.map(access => access.id === action.payload.id ? action.payload : access)
            })
            .addCase(deleteUnconfirmedAccess.fulfilled, (state, action) => {
                state.loading = false
                state.unconfirmedAccess = state.unconfirmedAccess.filter(access => access.id !== action.meta.arg.operatorRightId)
            })
            .addCase(getDetailsForAccessUpdate.fulfilled, (state, action) => {
                state.error = ""
                state.details = action.payload;
            })

            .addMatcher(isError, (state, action: PayloadAction<string>) => {
                state.error = action.payload;
                state.loading = false;
            })
    }
})

export default operatorAccessSlice.reducer

function isError(action: AnyAction) {
    return action.type.endsWith("rejected")
}
