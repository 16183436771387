import React from 'react';
import styles from './style.module.scss';

interface ICheckboxProps {
    isChecked?: boolean | null,
    isDisabled?: boolean,
    onChangeHandler?: any,
    checkboxId?: string | undefined,
    filled?: boolean;
    roleName?: string
}

const Checkbox: React.FC<ICheckboxProps> = React.memo((
    {
        isChecked,
        isDisabled,
        onChangeHandler,
        checkboxId,
        filled
    }) => {

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        if (onChangeHandler) {
            e.stopPropagation();
            onChangeHandler(checkboxId)
        }
    }

    return (
        <label htmlFor={checkboxId}>
            <input
                checked={isChecked ? isChecked : false}
                type="checkbox"
                className={styles.realCheckbox}
                readOnly={true}
                onChange={(e) => onChange(e)}
                disabled={isDisabled}
                id={checkboxId}
                data-testid={`checkbox-type-input-${checkboxId}`}/>
            <span className={filled ? `${styles.customCheckbox} ${styles.filled}` : styles.customCheckbox}/>
        </label>
    );
});

Checkbox.displayName = "Checkbox"
export default Checkbox;
