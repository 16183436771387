import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import AppTableWhite from "../../../../../../../components/AppTableWhite/AppTableWhite";
import SortingHeader
    from "@pages/Cms/RightsHolderView/ScheduleIngest/BookFixtures/children/SortingHeader/SortingHeader";
import styles from "../../index.module.scss";
import {Tooltip} from "react-tooltip";
import trash from "../../../../../../../img/trashWhite.svg";
import edit from "../../../../../../../img/editWhite.svg";
import timeExtending from "../../../../../../../img/timeWhite.svg";
import audit from "../../../../../../../img/auditWhite.svg";
import {ITableRowData} from "../../../BookFixtures/BookFixtures";
import {ISorting} from "../../../SheduleIngestHooks/useSortingAndSearch";
import {checkAuditPageAllowance} from "../../../../../../../helpers/checkAuditPageAllowance";
import {AppInfiniteScroll} from "../../../../../../../components/AppInfiniteScroll/AppInfiniteScroll";
import {useAppDispatch, useAppSelector} from "../../../../../../../hooks/hooks";
import {RootState} from "../../../../../../../store";
import {
    setPageNumber
} from "../../../../../../../store/rightHolderBooking/rightHolderBookingSlice";
import {useLocation, useNavigate} from "react-router-dom";
import {DateTime} from "luxon";

export interface UpcomingBookFixturesTableProps {
    tableData: ITableRowData[];
    sorting: ISorting;
    setSorting: Dispatch<SetStateAction<ISorting>>;
    handleEditFixtureClick: (fixture: ITableRowData) => void;
    startSetIngest: (fixture: ITableRowData) => void;
    setBookingForDelete: (fixture: ITableRowData) => void;
    handleExtendTimeClick: (fixture: ITableRowData) => void;
    goToAuditTrail: (fixture: ITableRowData) => void;
    fetchData: (page: number, pageSize: number, scrollPosition: string) => void;
    containerRef: React.RefObject<HTMLDivElement>;
}

const UpcomingBookFixturesTable: React.FunctionComponent<UpcomingBookFixturesTableProps> = ({
                                                                                                tableData,
                                                                                                fetchData,
                                                                                                sorting,
                                                                                                setSorting,
                                                                                                handleEditFixtureClick,
                                                                                                startSetIngest,
                                                                                                setBookingForDelete,
                                                                                                handleExtendTimeClick,
                                                                                                goToAuditTrail,
                                                                                                containerRef
                                                                                            }) => {

    const dispatch = useAppDispatch();
    const query = new URLSearchParams(useLocation().search);
    const queryFixtureId = query.get("fixtureId");
    const {
        pageNumber,
        itemsPerPage,
        totalCount,
        scrollLoader,
        maxItemsInTheTable
    } = useAppSelector((state: RootState) => state.rightHolderBooking);
    const tableRef = useRef<HTMLTableElement | null>(null);
    const [highlightedRow, setHighlightedRow] = useState<string | null>(null);
    const navigate = useNavigate();

    const rowRefs = useRef<{ [key: string]: HTMLTableRowElement | null }>({});

    const upcomingTableHeaderControls = [
        {
            title: "Sport",
            criteria: "Sport",
        },
        {
            title: "Content Supplier",
            criteria: "ContentSupplier",
        },
        {
            title: "Start Time (UTC)",
            criteria: "StartDate",
        },
        {
            title: "End Time (UTC)",
            criteria: "EndDate",
        },
        {
            title: "Property",
            criteria: "Competition",
        },
        {
            title: "Title",
            criteria: "Title",
        },
        {
            title: "Description",
            criteria: "Description",
        },
        {
            title: "Ingest",
            criteria: null
        },
        {
            title: "Ingest Detail",
            criteria: null
        },
        {
            title: "Actions",
            criteria: null,
        }
    ]

    useEffect(() => {
        if (queryFixtureId && rowRefs.current[queryFixtureId]) {
            const rowElement = rowRefs.current[queryFixtureId];
            const container = containerRef.current;

            if (rowElement && container) {
                const containerRect = container.getBoundingClientRect();
                const rowRect = rowElement.getBoundingClientRect();

                const offset = rowRect.top - containerRect.top + container.scrollTop;

                container.scrollTo({
                    top: offset - container.clientHeight / 2,
                    behavior: "smooth"
                });

                setHighlightedRow(queryFixtureId);

                setTimeout(() => {
                    setHighlightedRow(null);
                    navigate(window.location.pathname, {replace: true});
                }, 3000);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryFixtureId]);

    const setPage = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber))
    }

    return (
        <AppInfiniteScroll
            containerRef={containerRef}
            fetchData={fetchData}
            pageSize={itemsPerPage}
            totalItems={totalCount}
            currentPage={pageNumber}
            setPage={setPage}
            isLoading={scrollLoader}
            scrollingContainerHeight="95%"
            scrollBounce={200}
            maxItemsInTheTable={maxItemsInTheTable}
            tableDataLength={tableData.length}>
            <AppTableWhite reference={tableRef}>
                <SortingHeader
                    sorting={sorting}
                    setSorting={setSorting}
                    controls={upcomingTableHeaderControls}
                />
                <tbody>
                {
                    tableData.map((item) => (
                        <tr key={item.id}
                            ref={(el) => rowRefs.current[item.id] = el}
                            className={highlightedRow === item.id ? styles.highlightedRow : ""}
                        >
                            <td>
                                {item.sport}
                            </td>
                            <td>
                                {item.contentSupplier}
                            </td>
                            <td>
                                {DateTime.fromISO(item.startDate).toFormat(`dd-MM-yyyy HH:mm`)}
                            </td>
                            <td>
                                {DateTime.fromISO(item.endDate).toFormat(`dd-MM-yyyy HH:mm`)}
                            </td>
                            <td>
                                {item.competition}
                            </td>
                            <td>
                                {item.title}
                            </td>
                            <td>
                                {item.description}
                            </td>
                            <td className={styles.ingestCell}>
                                <button
                                    className={item.ingestType ? `${styles.ingestBtn} ${styles.updateMode}` : `${styles.ingestBtn}`}
                                    onClick={() => startSetIngest(item)}>
                                    {item.ingestType ? 'Update' : 'Select'}
                                </button>
                            </td>
                            <td className={styles.ingestCell}>
                                {item.ingestTypeDetails &&
                                    <span>{item.ingestTypeDetails.ingestType} : {item.ingestTypeDetails.details}</span>
                                }
                            </td>
                            <td>
                                <div className={styles.tableButtons}>
                                    <button className={styles.tableButton}
                                            onClick={() => setBookingForDelete(item)}>
                                        <Tooltip className={styles.userTableTooltip}
                                                 id={"delete-button"}/>
                                        <img
                                            className={styles.deleteIcon}
                                            src={trash}
                                            alt={"delete"}
                                            data-tooltip-id={"delete-button"}
                                            data-tooltip-content={"Delete"}
                                        />
                                    </button>
                                    <button className={styles.tableButton}
                                            onClick={() => handleEditFixtureClick(item)}>
                                        <Tooltip className={styles.userTableTooltip}
                                                 id={"edit-button"}/>
                                        <img
                                            className={styles.editIcon}
                                            src={edit}
                                            alt={"edit"}
                                            data-tooltip-id={"edit-button"}
                                            data-tooltip-content={"Edit"}
                                        />
                                    </button>
                                    <button className={styles.tableButton}
                                            onClick={() => handleExtendTimeClick(item)}>
                                        <Tooltip className={styles.userTableTooltip}
                                                 id={"extend-time-button"}/>
                                        <img
                                            className={styles.timeExtendingIcon}
                                            src={timeExtending}
                                            alt={"extend"}
                                            data-tooltip-id={"extend-time-button"}
                                            data-tooltip-content={"+15 min"}
                                        />
                                    </button>
                                    {
                                        checkAuditPageAllowance() &&
                                        <button className={styles.tableButton}
                                                onClick={() => goToAuditTrail(item)}>
                                            <Tooltip className={styles.userTableTooltip}
                                                     id={"audit-button"}/>
                                            <img
                                                className={styles.auditIcon}
                                                src={audit}
                                                alt={"audit"}
                                                data-tooltip-id={"audit-button"}
                                                data-tooltip-content={"Audit"}
                                            />
                                        </button>
                                    }
                                </div>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </AppTableWhite>
        </AppInfiniteScroll>
    )
}

export default UpcomingBookFixturesTable;
