import {AnyAction, createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {toastr} from "react-redux-toastr";
import {client} from "../../services/api-servise";
import {AxiosError} from "axios";

export interface IUserItem {
    id: string,
    name: string,
    logo: null | string
}

export interface IGetAllUsersResponse {
    rightHolders: IUserItem[],
    operators: IUserItem[],
    rightHoldersCount: 0,
    operatorsCount: 0
}

export interface IIGameInitDataState {
    users: IGetAllUsersResponse;
    error: string;
    usersStatus: string;
}

const initialState: IIGameInitDataState = {
    users: {
        rightHolders: [],
        operators: [],
        rightHoldersCount: 0,
        operatorsCount: 0
    },
    error: "",
    usersStatus: "idle"
}

export const getAllUsers = createAsyncThunk<IGetAllUsersResponse, null, { rejectValue: string }>(
    'iGameInit/get-all-users',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/IGames/users`);
            return data
        } catch (e) {
            if (e instanceof AxiosError) {
                toastr.error('IGame', `${e.response?.data}`)
                return rejectWithValue(e.response?.data)
            }
        }
    },
    {
        condition: (_, {getState}) => {
            let {iGameInit}: any = getState();
            if (iGameInit.usersStatus === 'loading') {
                return false;
            }
        }
    }
)

export const IGameInitSlice = createSlice({
    name: 'iGameInit',
    reducers: {},
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAllUsers.pending, (state) => {
                state.error = "";
                state.usersStatus = "loading";
            })
            .addCase(getAllUsers.fulfilled, (state, action) => {
                state.error = "";
                state.usersStatus = "idle";
                state.users = action.payload;
            })
            .addCase(getAllUsers.rejected, (state) => {
                state.usersStatus = "failed";
            })
            .addMatcher(isError, (state, action: PayloadAction<string>) => {
                state.error = action.payload;
            })
    }
})

export default IGameInitSlice.reducer;

function isError(action: AnyAction) {
    return action.type.endsWith("rejected");
}
