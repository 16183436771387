import {DateTime} from "luxon";
import moment from "moment";
import {IDate} from "../components/AnalyticsDashboard/types";
import {IDateData} from "../pages/Cms/OperatorView/ConcurrencyInspector/ConcurrencyTable/ConcurrencyTable";

export interface ICalendarDate {
    day: number;
    month: number;
    year: number;
    hour?: number;
    minute?: number;
}

export const formatDate = (dateInPickerFormat: any) => {
    const {year, month, day, hour, minute} = dateInPickerFormat;
    return DateTime.utc(year, month, day, hour, minute).toString();
}

export const formatContractDate = (dateInPickerFormat: any) => {
    const {year, month, day, hour, minute} = dateInPickerFormat;
    return DateTime.local(year, month, day, hour, minute).toUTC().toString();
}

export const formatDateForCSV = (startDate: IDateData, endDate: IDateData) => {
    const formattedStartDate = moment({
        year: startDate.year,
        month: startDate.month - 1,
        day: startDate.day,
    }).format('DD/MM/YYYY');

    const formattedEndDate = moment({
        year: endDate.year,
        month: endDate.month - 1,
        day: endDate.day,
    }).format('DD/MM/YYYY')
    return `${formattedStartDate}-${formattedEndDate}`;
}

export const formatDatePicker = (date: ICalendarDate) => {
    if (date) {
        const {year, month, day, hour, minute} = date
        const dateTime = moment({year, month: month - 1, day, hour, minute})
        return dateTime.format("YYYY-MM-DDTHH:mm:ss")
    }
    return ""
}

export const getPickerDateFormat = (date: string, isZeroMinutes: boolean = false): IDate => {
    const dt = DateTime.fromISO(date);
    return {
        year: dt.year,
        month: dt.month,
        day: dt.day,
        hour: dt.hour,
        minute: isZeroMinutes ? 0 : dt.minute,
    };
}

export const getMonthRange = () => {
    const today = moment();
    const previousMonth = moment(today).subtract(1, 'months');

    const currentDate = {
        year: today.year(),
        month: today.month() + 1,
        day: today.date()
    }

    const previousMonthDate = {
        year: previousMonth.year(),
        month: previousMonth.month() + 1,
        day: previousMonth.date()
    }

    return {
        from: previousMonthDate,
        to: currentDate
    };
};

export const formatRangeDate = (date: IDateData) => {
    const {year, month, day} = date;
    return DateTime.utc(year, month, day).toString();
}

export const getCurrentMonth = (): IDate => {
    const today = moment();

    return {
        year: today.year(),
        month: today.month() + 1,
        day: today.date(),
        hour: today.hour(),
        minute: today.minute()
    }
};

export const getPreviousMonth = (): IDate => {
    const previousMonth = moment().subtract(1, 'month').endOf('month');

    return {
        year: previousMonth.year(),
        month: previousMonth.month() + 1,
        day: previousMonth.date(),
        hour: previousMonth.hour(),
        minute: previousMonth.minute()
    }
};
