import {
    createGeneralIssue, editGeneralIssue,
    IFixtures,
    IGeneralIssue
} from "../../../../../../../store/generalIssues/generalIssuesSlice";
import moment from "moment";
import {toastr} from "react-redux-toastr";
import {AppDispatch} from "../../../../../../../store";
import {Dispatch, SetStateAction} from "react";

export const generalIssueSubmitHandler = (
    payload: IGeneralIssue,
    selectedFixtures: IFixtures[] | null,
    issueEndTime: string | null,
    dispatch: AppDispatch,
    formHandler: Dispatch<SetStateAction<boolean>>,
    editMode: boolean
) => {

    if (selectedFixtures?.length) {
        payload.selectedEvents = selectedFixtures.map((fixture) => fixture.fixtureId);
    }
    if (issueEndTime) {
        payload.issueEndTime = issueEndTime;

        const startTime = moment(payload.issueStartTime);
        const endTime = moment(payload.issueEndTime);


        if (endTime.isSame(startTime)) {
            return toastr.info("Incorrect dates", "Issue end time can't be same as a start time");

        } else if (endTime.isBefore(startTime)) {
            return toastr.info("Incorrect dates", "Issue end time can't be earlier than start time");
        }
    }
    editMode ? dispatch(editGeneralIssue(payload)) : dispatch(createGeneralIssue(payload));

    formHandler(false);
}
