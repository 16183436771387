import React, {useEffect, useState} from "react";
import AuditTrailPageInner from "../children/AuditTrailPageInner/AuditTrailPageInner";
import styles from "./index.module.scss";
import {formatSelectOptions} from "../../../helpers/formatSelectOptions";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import SelectInput from "../../SelectInput/SelectInput";
import {IMenuStructure} from "../../../pages/Cms/AppRoutes/routes/RouteInterface";
import {getGeoInitOptions} from "../../../store/geoRestrictions/geoRestrictionsSlice";
import {getFormInitData} from "../../../store/ingestResources/ingestResourcesSlice";
import commonStyles from "../children/AuditTrailPageInner/index.module.scss";
import AuditTrailsTable from "../children/AuditTrailsTable/AuditTrailsTable";
import {
    cleanAuditTrailsList,
    getRightsRestrictionsTrails
} from "../../../store/auditTrailsCommon/auditTrailsCommonSlice";

export interface IAuditTrailsRightsRestrictionsProps {
    menuStructure: IMenuStructure;
}

export interface IOptionState {
    value: string;
    label: string;
}

const AuditTrailsRightsRestrictions: React.FunctionComponent<IAuditTrailsRightsRestrictionsProps> = ({menuStructure}) => {

    const optionInitialState: IOptionState = {
        value: "",
        label: ""
    }

    const dispatch = useAppDispatch();
    const [rightHolder, setRightHolder] = useState<IOptionState | null>(null);
    const [competition, setCompetition] = useState<IOptionState | null>(null);
    const {trailsItemsPerPage} = useAppSelector(state => state.auditTrailsCommon);
    const {rightHolders} = useAppSelector(state => state.ingestResources);
    const competitions = useAppSelector(state => state.geoRestrictions.rights);

    useEffect(() => {
        dispatch(cleanAuditTrailsList());
        dispatch(getFormInitData(null));
    }, [dispatch]);

    useEffect(() => {
        if (rightHolder && competition) {
            dispatch(getRightsRestrictionsTrails({
                rightHolderId: rightHolder ? rightHolder.value.toString() : optionInitialState.value,
                competitionId: competition ? competition.value.toString() : optionInitialState.value,
                pageNumber: 1,
                itemsPerPage: trailsItemsPerPage
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rightHolder, competition]);

    useEffect(() => {
        if (rightHolder) {
            dispatch(getGeoInitOptions(rightHolder.value.toString()));
        }
    }, [rightHolder, dispatch]);

    const onValueChange = (value: IOptionState, setValue: (value: IOptionState) => void, name: string) => {
        if (name === "rightHolder") {
            setCompetition(null);
        }
        setValue(value);
    }

    return (
        <AuditTrailPageInner
            pageTitle={"Audit Trails"}
            pageSubTitle={"Rights Restrictions"}
            pageHeader={"Audit Trails - Rights Restrictions"}
            menuStructure={menuStructure}>
            <div className={styles.dropdowns} key="dropdowns">
                <label className={styles.label}>Rights Holder
                    <SelectInput
                        value={rightHolder}
                        onChange={(e) => {
                            e !== null && onValueChange(e, setRightHolder, "rightHolder")
                        }}
                        options={formatSelectOptions(rightHolders)}
                        placeholder={"Select..."}
                        width="200px"
                    />
                </label>
                <label className={styles.label}>Property
                    <SelectInput
                        value={competition}
                        onChange={(e) => {
                            e !== null && onValueChange(e, setCompetition, "competition")
                        }}
                        options={formatSelectOptions(competitions)}
                        placeholder={"Select..."}
                        width="200px"
                    />
                </label>
            </div>
            <div className={commonStyles.recordInfo} key="details">
                <p className={commonStyles.recordInfoItem}><span>Rights Holder: </span>{rightHolder?.label}</p>
                <p className={commonStyles.recordInfoItem}><span>Property: </span>{competition?.label}</p>
            </div>
            <AuditTrailsTable
                height="43vh"
                rightHolderId={rightHolder?.value.toString()}
                competitionId={competition?.value.toString()}
            />
        </AuditTrailPageInner>
    )
}

export default AuditTrailsRightsRestrictions;
AuditTrailsRightsRestrictions.displayName = "AuditTrailsRightsRestrictions"
