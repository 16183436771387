import React from 'react';
import styles from './index.module.scss';
import {IUserItem} from "../../../../../store/iGameInit/iGameInitSlice";
import {useAppSelector} from "../../../../../hooks/hooks";
import TableAccordion from "./TableAccordion/TableAccordion";
import {AccessTablesNames} from "../ContentAccess";

interface IExistingAccessTableProps {
    openConfirmModal: (operatorId: string, rightId: string) => void;
    setUpdatingExistingAccess: (id: string, rightName: string) => void;
    users?: IUserItem[];
    rightHolderId: string | null;
}

const ExistingAccessTable: React.FC<IExistingAccessTableProps> = React.memo((
    {
        openConfirmModal,
        setUpdatingExistingAccess,
        users,
        rightHolderId
    }) => {
    const {accesses} = useAppSelector(state => state.operatorAccess);

    return (
        <div className={styles.tableOverlay}>
            <h3 className={styles.tableTitle}>{AccessTablesNames.EXISTING_ACCESS}</h3>
            <div className={styles.tableScroll}>
                <div className={styles.tableWrapper}>

                    <table className={styles.pricingTable}>
                        <thead>
                        <tr>
                            <th>Operator</th>
                            <th>Right</th>
                            <th>Fixtures</th>
                            <th></th>
                        </tr>
                        </thead>
                        {accesses?.map((operator, index) => {
                            if (operator.createdOperatorAccessDTO.operatorAccesses.length > 0) {
                                return (
                                    <TableAccordion
                                        key={index}
                                        openConfirmModal={openConfirmModal}
                                        operatorName={operator.operatorName}
                                        accesses={operator.createdOperatorAccessDTO.operatorAccesses}
                                        setUpdatingExistingAccess={setUpdatingExistingAccess}
                                        users={users}
                                        rightHolderId={rightHolderId}
                                    />
                                )
                            }
                            return null
                        })}
                    </table>
                </div>
            </div>
        </div>
    );
});

export default ExistingAccessTable;
ExistingAccessTable.displayName = "ExistingAccessTable"
